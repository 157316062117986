import { call, put, takeEvery } from 'redux-saga/effects';

import {
  getShareIncentivePlanError,
  getShareIncentivePlansError,
  getShareIncentivePlansSuccess,
  getShareIncentivePlanSuccess,
} from 'app-state/actions/share-incentive-plan';
import {
  GET_SHARE_INCENTIVE_PLAN,
  GET_SHARE_INCENTIVE_PLANS,
} from 'app-state/constants/share-incentive-plan';

import API from 'constants/api';
import request from 'shared-parts/helpers/request';

function* getShareIncentivePlans() {
  try {
    const { data } = yield call(
      request,
      `${API.ShareIncentivePlans.Prefix()}${API.ShareIncentivePlans.GetContributions()}`,
      'GET',
    );
    yield put(getShareIncentivePlansSuccess({ data }));
  } catch (error) {
    yield put(getShareIncentivePlansError({ error }));
  }
}

function* getShareIncentivePlansWatcher() {
  yield takeEvery(GET_SHARE_INCENTIVE_PLANS, getShareIncentivePlans);
}

function* getShareIncentivePlan({ payload }) {
  try {
    const { data } = yield call(
      request,
      `${API.ShareIncentivePlans.Prefix()}${API.ShareIncentivePlans.GetShareIncentivePlanById(
        payload,
      )}`,
      'GET',
    );
    yield put(getShareIncentivePlanSuccess({ data }));
  } catch (error) {
    yield put(getShareIncentivePlanError({ error }));
  }
}

function* getShareIncentivePlanWatcher() {
  yield takeEvery(GET_SHARE_INCENTIVE_PLAN, getShareIncentivePlan);
}

export {
  getShareIncentivePlans,
  getShareIncentivePlansWatcher,
  getShareIncentivePlan,
  getShareIncentivePlanWatcher,
};
