import styled from 'styled-components';

import { TextInput } from 'shared-parts/components/text-input';

const AddressLine2Label = styled.label`
  ${({ hide }) => hide && 'visibility: hidden'};
  ${({ fullWidth }) => fullWidth && 'display: none'};
`;

const AutofillDisableInput = styled.input`
  display: none;
`;

const Manual = props => (
  <>
    <props.FormGroup>
      <AutofillDisableInput />
      <label htmlFor="addressFirstLine">{props.line1Label}</label>
      <TextInput
        name="addressFirstLine"
        disabled={props.disabled}
        disableAutocomplete={props.disableAutocomplete}
        autoComplete={props.disableAutocomplete && 'none'}
        placeholder="Enter Address"
      />
    </props.FormGroup>
    <props.FormGroup>
      <AddressLine2Label fullWidth={props.fullWidth} hide={props.line2Label === ''} htmlFor="line2">
        {props.line2Label || 'Empty label'}
      </AddressLine2Label>
      <TextInput
        name="line2"
        disabled={props.disabled}
        disableAutocomplete={props.disableAutocomplete}
        autoComplete={props.disableAutocomplete && 'none'}
        placeholder="Enter Address"
      />
    </props.FormGroup>
    <props.FormGroup>
      <label htmlFor="city">{props.cityLabel}</label>
      <TextInput
        name="city"
        disabled={props.disabled}
        disableAutocomplete={props.disableAutocomplete}
        autoComplete={props.disableAutocomplete && 'none'}
        placeholder="City"
      />
    </props.FormGroup>
    <props.FormGroup>
      <label htmlFor="state">{props.state}</label>
      <TextInput
        name="state"
        disabled={props.disabled}
        disableAutocomplete={props.disableAutocomplete}
        autoComplete={props.disableAutocomplete && 'new-password'}
        placeholder="State"
      />
    </props.FormGroup>
    <props.FormGroup>
      <label htmlFor="postCode">{props.postCodeLabel}</label>
      <TextInput
        name="postCode"
        disabled={props.disabled}
        disableAutocomplete={props.disableAutocomplete}
        autoComplete={props.disableAutocomplete && 'none'}
        placeholder="Enter Postal / Zip Code"
      />
    </props.FormGroup>
  </>
);

export default Manual;
