import styled, { css } from 'styled-components';

import { Screens } from 'shared-parts/constants/screens';

const Shared = css`
  min-height: 352px;

  @media only screen and (max-width: ${Screens.lg}) {
    min-height: auto;
  }

  @media only screen and (max-width: ${Screens.md}) {
    min-height: 278px;
  }

  @media only screen and (max-width: ${Screens.sm}) {
    min-height: auto;
  }
`;

export const Iframe = styled.iframe`
  width: 100%;
  ${Shared};
`;

export const Video = styled.video`
  ${Shared};
`;

export const VideoWrapper = styled.div`
  width: 100%;
  margin-bottom: 26px;
`;
