import { buttonClasses } from '@mui/material';
import MuiIconButton, { IconButtonProps as MuiIconButtonProps } from '@mui/material/IconButton';
import { styled } from '@mui/material/styles';

import { StripColors } from '../../utils';

export type IconButtonProps = StripColors<MuiIconButtonProps>;

export const IconButton = styled(MuiIconButton)<IconButtonProps>(
  ({ theme, color = 'neutral' }) => ({
    ...(color === 'neutral' && {
      color: theme.palette.neutral[400],

      '&:hover': {
        backgroundColor: theme.palette.neutral[50],
      },
    }),

    [`&.${buttonClasses.disabled}`]: {
      color: theme.palette.grey[400],
    },
  }),
);
