import * as constants from 'app-state/constants';

export const redirectPathReducer = (state = null, action) => {
  // eslint-disable-line
  switch (action.type) {
    case constants.SAVE_REDIRECT_PATH:
      return action.route;
    case constants.REMOVE_REDIRECT_PATH:
      return null;
    default:
      return state;
  }
};
