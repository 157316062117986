export const FETCH_INVESTMENTS_DATA = 'FETCH_INVESTMENTS_DATA';
export const FETCH_INVESTMENTS_DATA_SUCCESS = 'FETCH_INVESTMENTS_DATA_SUCCESS';
export const FETCH_INVESTMENTS_DATA_ERROR = 'FETCH_INVESTMENTS_DATA_ERROR';
export const RESET_INVESTMENTS_DATA = 'RESET_INVESTMENTS_DATA';

export const FETCH_SECONDARY_INVESTMENTS_DATA = 'FETCH_SECONDARY_INVESTMENTS_DATA';
export const FETCH_SECONDARY_INVESTMENTS_DATA_SUCCESS = 'FETCH_SECONDARY_INVESTMENTS_DATA_SUCCESS';
export const FETCH_SECONDARY_INVESTMENTS_DATA_ERROR = 'FETCH_SECONDARY_INVESTMENTS_DATA_ERROR';
export const RESET_SECONDARY_INVESTMENTS_DATA = 'RESET_SECONDARY_INVESTMENTS_DATA';

export const FETCH_SECONDARY_INVESTMENT_DATA = 'FETCH_SECONDARY_INVESTMENT_DATA';
export const UPDATE_SECONDARY_INVESTMENT_DATA = 'UPDATE_SECONDARY_INVESTMENT_DATA';
export const FETCH_SECONDARY_INVESTMENT_DATA_SUCCESS = 'FETCH_SECONDARY_INVESTMENT_DATA_SUCCESS';
export const FETCH_SECONDARY_INVESTMENT_DATA_ERROR = 'FETCH_SECONDARY_INVESTMENT_DATA_ERROR';

export const SELL_INVESTMENTS = 'SELL_INVESTMENTS';
export const SELL_INVESTMENTS_SUCCESS = 'SELL_INVESTMENTS_SUCCESS';
export const SELL_INVESTMENTS_ERROR = 'SELL_INVESTMENTS_ERROR';

export const SELL_INVESTMENTS_INFO = 'SELL_INVESTMENTS_INFO';
export const SELL_INVESTMENTS_INFO_SUCCESS = 'SELL_INVESTMENTS_INFO_SUCCESS';
export const SELL_INVESTMENTS_INFO_ERROR = 'SELL_INVESTMENTS_INFO_ERROR';

export const RESET_SELL_INVESTMENTS_FORM = 'RESET_SELL_INVESTMENTS_FORM';

export const CANCEL_SECONDARY_INVESTMENT = 'CANCEL_SECONDARY_INVESTMENT';

export const CONFIRM_SECONDARY_INVESTMENT = 'CONFIRM_SECONDARY_INVESTMENT';

export const REJECT_SECONDARY_INVESTMENT = 'REJECT_SECONDARY_INVESTMENT';
