import { call, put, takeEvery } from 'redux-saga/effects';

import { getCurrenciesError, getCurrenciesSuccess } from 'app-state/currencies/actions';
import { GET_CURRENCIES } from 'app-state/currencies/constants';
import type { GetCurrenciesAction } from 'app-state/currencies/types';

import API from 'constants/api';
import request from 'shared-parts/helpers/request';

function* getCurrencies({ payload }: GetCurrenciesAction) {
  try {
    const { data } = yield call(request, API.GetCurrencies(payload));
    yield put(getCurrenciesSuccess({ [payload]: data }));
  } catch (e: any) {
    yield put(getCurrenciesError(e.response?.details));
  }
}

function* getCurrenciesWatcher() {
  yield takeEvery(GET_CURRENCIES, getCurrencies);
}

export { getCurrenciesWatcher };
