const generateTableData = (data = [], keys, options = {}) =>
  data.map(item => ({
    cells: keys.map(
      ({
        field,
        transform,
        setRedirectURL,
        text = '-',
        multiActions,
        action,
        checkDisabledAction,
        setLink,
        styles,
      }) => ({
        ...[
          {
            condition: multiActions,
            generate: () => ({
              multiActions: multiActions
                .filter(({ checkIsHidden }) => (checkIsHidden ? !checkIsHidden(item) : true))
                .map(
                  ({
                    text: multiActionText,
                    action: multiAction,
                    checkDisabledMultiAction,
                    styles: actionStyles,
                  }) => ({
                    text: multiActionText,
                    isActionDisabled: checkDisabledMultiAction
                      ? checkDisabledMultiAction(item)
                      : false,
                    action: () => multiAction(item, data),
                    styles: () => actionStyles && actionStyles(item),
                  }),
                ),
            }),
          },
          {
            condition: action,
            generate: () => ({
              text,
              isActionDisabled: checkDisabledAction ? checkDisabledAction(item) : false,
              action: () => action(item, data),
            }),
          },
          {
            condition: setLink,
            generate: () => ({ text, link: setLink(item, data) }),
          },
          {
            condition: field,
            generate: () => {
              const column = item[field];
              const result = transform ? transform(column, item, data) : column;

              return {
                text: result || '-',
                redirectURL: setRedirectURL ? setRedirectURL(column, item, data) : null,
              };
            },
          },
          {
            condition: true,
            generate: () => ({ text }),
          },
        ]
          .find(c => c.condition)
          .generate(),
        styles: styles ? styles(item[field], item, data) : '',
      }),
    ),
    item: { ...item, ...options },
  }));

export default generateTableData;
