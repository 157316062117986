import styled from 'styled-components';

import { TableContainer } from 'shared-parts/components/table/regular-table/table.styled';
import * as Colors from 'shared-parts/constants/colors';
import * as Fonts from 'shared-parts/constants/fonts';
import { Screens } from 'shared-parts/constants/screens';

import Container from '../container';

const CompanyOverviewWrapper = styled(Container)`
  font-family: ${Fonts.mainFont};
  background: ${Colors.white};
  border: 1px solid ${Colors.neutral400};
  border-radius: 8px;
  padding: 45px 40px 40px 40px;
  margin: 0 auto 19px;
  box-shadow: 0px 3px 6px ${Colors.shadowColor};
  border-bottom: 1px solid ${Colors.neutral400};

  .image-box {
    margin-bottom: 30px;
    text-align: center;

    img {
      max-width: 100%;
    }
  }

  @media (max-width: ${Screens.sm}) {
    padding: 60px 0 35px;
  }
`;

const ComponentContainer = styled.div`
  margin-bottom: 20px;

  ${TableContainer} {
    tbody {
      td {
        min-width: 180px; // setting minimal table column width (only for deal)
      }
    }
  }
`;

const Content = styled.div`
  display: flex;
  margin-top: 50px;
  @media (max-width: ${Screens.md}) {
    margin-top: 10px;
    flex-flow: column wrap;
  }
`;

export { CompanyOverviewWrapper, ComponentContainer, Content };
