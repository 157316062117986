import { FC } from 'react';
import { connect } from 'react-redux';

import { hideModal as hideModalAction } from 'app-state/actions';

import { FinishIcon } from 'shared-parts/components/svg-icons';
import { success } from 'shared-parts/constants/colors';

import { CONTRIBUTION_UPDATED_MESSAGE } from '../constants';

import {
  ButtonsWrapper,
  CautionIconWrapper,
  ConfirmButton,
  ModalText,
  ModalTitle,
  ModalWrapper,
} from './styled';

type ContributionUpdatedModalProps = {
  hideModal: () => void;
};

const ContributionUpdatedModal: FC<React.PropsWithChildren<ContributionUpdatedModalProps>> = ({
  hideModal,
}) => {
  return (
    <ModalWrapper>
      <CautionIconWrapper>{FinishIcon(64, 64, success)}</CautionIconWrapper>
      <ModalTitle>Contribution updated</ModalTitle>
      <ModalText>{CONTRIBUTION_UPDATED_MESSAGE}</ModalText>
      <ButtonsWrapper>
        <ConfirmButton onClick={hideModal}>OK</ConfirmButton>
      </ButtonsWrapper>
    </ModalWrapper>
  );
};

const mapDispatchToProps = (dispatch: (data: { type: string; data?: any }) => any) => ({
  hideModal: () => dispatch(hideModalAction()),
});

export default connect(null, mapDispatchToProps)(ContributionUpdatedModal);
