import { Field } from 'formik';
import { decamelize } from 'humps';

import Error from 'shared-parts/components/error';
import { alternative, neutral800 } from 'shared-parts/constants/colors';

import { CheckboxInput, CheckboxLabel, CheckboxWrapper, Icon } from './checkbox.styled';

const CustomCheckboxComponent = ({
  text,
  form: { errors, touched, setFieldTouched },
  field,
  thinText,
  className,
  disabled,
  fontSize,
  baseColor = alternative,
  labelColor = neutral800,
  ...rest
}) => {
  const { name, value = '', onChange, onBlur } = field;
  const handleChange = event => {
    setFieldTouched(name, true, false);
    onChange(event);
  };
  const error = Array.isArray(errors[name]) ? errors[name][0] : errors[name];

  return (
    <CheckboxWrapper className={className}>
      <CheckboxLabel
        labelColor={labelColor}
        thinText={thinText}
        disabled={disabled}
        fontSize={fontSize}
      >
        {text}
        <CheckboxInput
          type="checkbox"
          checked={value}
          onBlur={onBlur}
          baseColor={baseColor}
          onChange={handleChange}
          name={name}
          value={value}
          disabled={disabled}
          {...rest}
          data-e2e={decamelize(name, { separator: '-' })}
        />
        <Icon />
      </CheckboxLabel>
      {error && touched[name] && <Error message={error} data-e2e={`${name}-error`} />}
    </CheckboxWrapper>
  );
};

export default props => <Field {...props} component={CustomCheckboxComponent} />;
