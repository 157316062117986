import { forwardRef } from 'react';

import { Alert as MuiAlert, AlertProps as MuiAlertProps } from '@mui/material';

import { CheckCircle, ErrorOutlined, InfoOutlined } from '../../icons';

export type AlertProps = MuiAlertProps;

export const Alert = forwardRef<HTMLDivElement, AlertProps>((props, ref) => (
  <MuiAlert
    ref={ref}
    {...props}
    iconMapping={{
      success: <CheckCircle fillColor="currentColor" />,
      info: <InfoOutlined fillColor="currentColor" />,
      warning: <ErrorOutlined fillColor="currentColor" />,
      error: <ErrorOutlined fillColor="currentColor" />,
    }}
  />
));
