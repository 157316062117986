import { CSSProperties, FC, PropsWithChildren } from 'react';

import { getTenantConfig } from 'helpers/get-tenant-config';

import Alternative1 from './styles/alternative1/auth-container';
import Alternative2 from './styles/alternative2/auth-container';
import Default from './styles/default/auth-container';

const {
  custom: {
    authScreen: { style },
  },
} = getTenantConfig();

const AuthContainer: FC<PropsWithChildren<Pick<CSSProperties, 'backgroundColor'>>> = props => {
  switch (style) {
    case 'alternative1':
      return <Alternative1 {...props} />;
    case 'alternative2':
      return <Alternative2 {...props} />;
    default:
      return <Default {...props} />;
  }
};

export default AuthContainer;
