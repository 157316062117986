const emailFormat =
  // eslint-disable-next-line no-control-regex
  /(?:[a-z0-9!#$%&'*+/=?^_`{|}~-]+(?:\.[a-z0-9!#$%&'*+/=?^_`{|}~-]+)*|"(?:[\x01-\x08\x0b\x0c\x0e-\x1f\x21\x23-\x5b\x5d-\x7f]|\\[\x01-\x09\x0b\x0c\x0e-\x7f])*")@(?:(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])?|\[(?:(?:(2(5[0-5]|[0-4][0-9])|1[0-9][0-9]|[1-9]?[0-9]))\.){3}(?:(2(5[0-5]|[0-4][0-9])|1[0-9][0-9]|[1-9]?[0-9])|[a-z0-9-]*[a-z0-9]:(?:[\x01-\x08\x0b\x0c\x0e-\x1f\x21-\x5a\x53-\x7f]|\\[\x01-\x09\x0b\x0c\x0e-\x7f])+)\])/;
const noBlankSpaceFormat = /^\S+$/;
const alphaNumeric = /^(?=.*[a-zA-Zа-яА-Я])(?=.*[0-9])[A-Za-z0-9а-яА-Я]+$/;
const includesAlphaNumeric = /^(?=.*[a-zA-Zа-яА-Я])(?=.*[0-9])/;
const numbersOrLetters = /^[A-Za-z0-9]+$/;
/**
 * @deprecated please use dayjs and iso strings instead.
 */
const dateRegex =
  /^(?!00)([0-2][0-9]|(3)[0-1])(-)(?!00)(((0)[0-9])|((1)[0-2]))(-)((1)[8-9][0-9][0-9]|(2)[0-1][0-9][0-9])$/;
/**
 * @deprecated please use dayjs and iso strings instead.
 */
const dateWithTimeRegex =
  /^(?!00)([0-2][0-9]|(3)[0-1])(-)(?!00)(((0)[0-9])|((1)[0-2]))(-)((1)[8-9][0-9][0-9]|(2)[0-1][0-9][0-9]) (((0)[0-9])|((1)[0-2])):([0-2][0-9]|([0-5])[0-9])(AM|PM)$/;
const formattedDecimalRegex = /^(\d+(,\d+)*\.?\d*)$/;

export default {
  emailFormat,
  noBlankSpaceFormat,
  alphaNumeric,
  includesAlphaNumeric,
  dateRegex,
  dateWithTimeRegex,
  formattedDecimalRegex,
  numbersOrLetters,
};
