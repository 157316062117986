import { call, put, takeEvery } from 'redux-saga/effects';

import * as actions from 'app-state/actions';
import * as constants from 'app-state/constants';

import API from 'constants/api';
import { request } from 'shared-parts/helpers';

function* getDeals() {
  try {
    const { data } = yield call(request, API.deals());

    yield put(actions.getDealsSuccess(data));
  } catch (e) {
    yield put(actions.getDealsError(e));
  }
}

function* getDealsWatcher() {
  yield takeEvery(constants.GET_DEALS, getDeals);
}

export { getDeals, getDealsWatcher };
