import {
  FETCH_INVESTMENT_PREFERENCES,
  FETCH_INVESTMENT_PREFERENCES_ERROR,
  FETCH_INVESTMENT_PREFERENCES_SUCCESS,
  RESET_INVESTMENT_PREFERENCES,
} from 'app-state/constants/institutional-onboarding';

type InvestmentPreferencesState = {
  data?: any;
  errors: {
    status: number;
    response: { details: string }[];
  } | null;
  loading: boolean;
};

type InvestmentPreferencesAction = {
  type: string;
  payload: any;
};

export const defaultInvestmentPreferencesState: InvestmentPreferencesState = {
  data: null,
  errors: null,
  loading: false,
};

// eslint-disable-next-line max-len
export const investmentPreferencesReducer = (
  state = defaultInvestmentPreferencesState,
  action: InvestmentPreferencesAction,
): InvestmentPreferencesState => {
  switch (action.type) {
    case FETCH_INVESTMENT_PREFERENCES:
      return { ...state, loading: true };
    case FETCH_INVESTMENT_PREFERENCES_SUCCESS:
      return { ...state, data: action.payload, loading: false };
    case FETCH_INVESTMENT_PREFERENCES_ERROR:
      return { ...state, errors: action.payload, loading: false };
    case RESET_INVESTMENT_PREFERENCES:
      return defaultInvestmentPreferencesState;
    default:
      return state;
  }
};
