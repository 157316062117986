import * as constants from 'app-state/constants';

import defaultState from './get-reducer-state';

export const communicationsInitialState = defaultState({
  data: {
    items: [],
    meta: { pagination: {} },
  },
});

export const communicationsReducer = (state = communicationsInitialState, action) => {
  switch (action.type) {
    case constants.GET_COMMUNICATIONS:
      return {
        data: communicationsInitialState.data,
        loading: true,
        errors: null,
      };
    case constants.GET_COMMUNICATIONS_SUCCESS:
      return {
        data: action.data,
        loading: false,
        errors: null,
      };
    case constants.GET_COMMUNICATIONS_ERROR:
      return {
        data: communicationsInitialState.data,
        loading: false,
        errors: action.error,
      };
    case constants.CLEAR_COMMUNICATIONS:
      return communicationsInitialState;
    default:
      return state;
  }
};

export const communicationReducer = (state = defaultState(), action) => {
  switch (action.type) {
    case constants.GET_COMMUNICATION_SUCCESS:
      return {
        data: action.data,
        loading: false,
        errors: null,
      };
    case constants.GET_COMMUNICATION_ERROR:
      return {
        data: {},
        loading: false,
        errors: action.error,
      };
    case constants.CLEAR_COMMUNICATION:
      return defaultState();
    default:
      return state;
  }
};

export const communicationsQuantityReducer = (state = defaultState(), action) => {
  switch (action.type) {
    case constants.GET_COMMUNICATIONS_QUANTITY_SUCCESS:
      return {
        data: action.data,
        loading: false,
        errors: null,
      };
    case constants.GET_COMMUNICATIONS_QUANTITY_ERROR:
      return {
        data: {},
        loading: false,
        errors: action.error,
      };
    default:
      return state;
  }
};
