import { forwardRef } from 'react';

import {
  FormLabel as BaseFormLabel,
  FormLabelProps,
  styled,
  Typography,
  useFormControl,
} from '@mui/material';

const FormLabelRoot = styled(BaseFormLabel)(({ theme }) => ({
  display: 'block',

  color: theme.palette.neutral[600],

  '&.Mui-focused': {
    color: theme.palette.neutral[600],
  },
  '& em': {
    color: theme.palette.neutral[400],
    fontStyle: 'normal',
  },

  marginTop: '0px',
  marginBottom: theme.spacing(1),

  // Ensure we always have 8px buffer even if no description
  // TODO: This isn't supported on Firefox atm but it gets something in
  // and form controls with no descriptions on firefox should hopefully
  // be a small enough use case to be acceptable for now
  ':not(:has(+ .MuiFormDescription-root))': {
    marginBottom: theme.spacing(2),
  },
}));

export const FormLabel = forwardRef<HTMLLabelElement, FormLabelProps>((inProps, ref) => {
  const { children, ...props } = inProps;
  const control = useFormControl();

  const required = inProps.required ?? control?.required ?? true;

  return (
    // Always disable required as we only show (optional), not a required asterisk
    // We still want to have 'required' set though to work off, and the context
    // so we disable it at the last minute: here
    <FormLabelRoot ref={ref} {...props} required={false}>
      {children}

      {!required && (
        <Typography variant="body1" component="span">
          &nbsp;(optional)
        </Typography>
      )}
    </FormLabelRoot>
  );
});
