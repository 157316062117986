import { ReactElement, ReactNode } from 'react';

import { StandardButton } from 'shared-parts/components/button';
import InfoIcon from 'shared-parts/components/svg-icons/circle-exclamation-mark';
import * as Colors from 'shared-parts/constants/colors';

import { Message, ModalTitle, ModalWrapper } from '../shared.styled';

type InfoModalProps = {
  title: ReactNode;
  additionalText?: ReactNode;
  buttonText?: ReactNode;

  modalWidth?: number;
  messageMaxWidth?: string;
  className?: string;

  icon?: ReactElement | null;
  iconSize?: number;

  customActionsBlock: () => ReactNode;
  hideModal: () => void;
};

const InfoModal = ({
  title,
  hideModal,
  additionalText = '',
  buttonText = 'OK',
  modalWidth = 800,
  messageMaxWidth = '100%',
  iconSize = 64,
  className,
  customActionsBlock,
  icon = InfoIcon(iconSize, iconSize, Colors.warning),
}: InfoModalProps) => (
  <ModalWrapper modalWidth={modalWidth} className={className}>
    {icon}
    <ModalTitle>{title}</ModalTitle>
    {additionalText && <Message maxWidth={messageMaxWidth}>{additionalText}</Message>}
    {customActionsBlock ? (
      customActionsBlock()
    ) : (
      <StandardButton onClick={hideModal}>{buttonText}</StandardButton>
    )}
  </ModalWrapper>
);

export default InfoModal;
