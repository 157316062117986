import { call, put, takeEvery } from 'redux-saga/effects';

import { showModal } from 'app-state/actions';
import {
  getContributionError,
  getContributionSuccess,
  updateContributionError,
  updateContributionSuccess,
} from 'app-state/actions/contribution';
import { GET_CONTRIBUTION, UPDATE_CONTRIBUTION } from 'app-state/constants/contribution';

import ContributionUpdatedModal from 'modules/employee-share-plan-details/components/monthly-contribution/contribution-updated-modal';
import API from 'constants/api';
import { ModalFailed } from 'shared-parts/components';
import request from 'shared-parts/helpers/request';

function* getContribution({ payload }) {
  try {
    const { data } = yield call(
      request,
      `${API.ShareIncentivePlans.Prefix()}${API.ShareIncentivePlans.Contribution(payload)}`,
      'GET',
    );
    yield put(getContributionSuccess({ data }));
  } catch (error) {
    yield put(
      showModal({
        showHeader: false,
        closable: true,
        component: ModalFailed,
      }),
    );
    yield put(getContributionError({ error }));
  }
}

function* getContributionWatcher() {
  yield takeEvery(GET_CONTRIBUTION, getContribution);
}

function* updateContribution({ payload }) {
  const { id, formValues } = payload;
  try {
    const contribution = yield call(
      request,
      `${API.ShareIncentivePlans.Prefix()}${API.ShareIncentivePlans.Contribution(id)}`,
      'PATCH',
      formValues,
      {
        snakeCaseParams: false,
      },
    );

    yield put(updateContributionSuccess(contribution));

    yield put(
      showModal({
        showHeader: false,
        closable: false,
        component: ContributionUpdatedModal,
      }),
    );
  } catch (error) {
    yield put(
      showModal({
        showHeader: false,
        closable: true,
        component: ModalFailed,
      }),
    );
    yield put(updateContributionError({ error }));
  }
}

function* updateContributionWatcher() {
  yield takeEvery(UPDATE_CONTRIBUTION, updateContribution);
}

export { getContribution, getContributionWatcher, updateContribution, updateContributionWatcher };
