const CircleArrow = ({ color = '#FFFFFF', onClick, className }) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    className={className}
    onClick={onClick}
    viewBox="0 0 60 60"
  >
    <g fill={color} fillRule="nonzero">
      <path d="M51.24 8.774c8.504 8.573 11.048 21.467 6.445 32.669-4.602 11.202-15.444 18.505-27.47 18.505-12.026 0-22.868-7.303-27.47-18.505C-1.859 30.24.685 17.347 9.19 8.773c11.615-11.698 30.435-11.698 42.05 0zm-3.868 3.9c-9.503-9.58-24.91-9.58-34.412 0-9.503 9.58-9.503 25.113 0 34.694 9.502 9.58 24.909 9.58 34.412 0a24.603 24.603 0 0 0 7.14-17.347c0-6.51-2.57-12.75-7.14-17.348z" />
      <path d="M37.851 31.372l-4.661-4.8v18.195h-5.951V26.572l-4.66 4.8-4.166-4.299 11.801-11.9L42.015 27.07z" />
    </g>
  </svg>
);

export default CircleArrow;
