import { FC, useMemo } from 'react';

import { NumberInput, NumberInputProps } from './number-input';

type CurrencyInputProps = Omit<NumberInputProps, 'startAdornment' | 'endAdornment'> & {
  /**
   * The ISO 4217 code of the currency. e.g. GBP, AUD, JPY
   * Undefined and non-valid currencies (e.g. empty string, or non-ISO 4217 currencies) adds no adornment to the final input.
   * Currency is optional it might be specified by the user or some backend entity.
   */
  currency?: string;
  /**
   * The format to render the currency in.
   *
   *
   * Symbol will display the currency symbol, e.g. £
   *
   * ISO will show the ISO code, e.g. GBP
   */
  format?: 'symbol' | 'iso';
};

const CurrencyInput: FC<React.PropsWithChildren<CurrencyInputProps>> = ({
  currency,
  format = 'symbol',
  locale,
  ...props
}) => {
  const adornment = useMemo<Partial<NumberInputProps>>(() => {
    if (!currency) {
      return {}; // empty strings & undefined add no adornment
    }
    let formatter;
    try {
      formatter = Intl.NumberFormat(locale, { style: 'currency', currency });
    } catch (err) {
      return {}; // invalid currency
    }
    const parts = formatter.formatToParts(1);

    if (format === 'iso') {
      return {
        prefix: currency.toUpperCase(),
      };
    }

    if (parts[parts.length - 1]?.type === 'currency') {
      return {
        suffix: parts[parts.length - 1]?.value,
      };
    }

    return {
      prefix: parts[0].value,
    };
  }, [locale, currency, format]);

  return <NumberInput {...props} {...adornment} locale={locale} />;
};

export { CurrencyInput, CurrencyInputProps };
