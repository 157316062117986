import styled from 'styled-components';

import FormikButton from 'shared-parts/components/button/formik-button';
import * as Colors from 'shared-parts/constants/colors';
import { primary } from 'shared-parts/constants/colors';
import * as Fonts from 'shared-parts/constants/fonts';

const ModalWrapper = styled.div`
  display: flex;
  align-items: center;
  flex-direction: column;
  width: ${({ modalWidth }) => modalWidth}px;
  min-height: 326px;
  padding: 0 40px;
`;

const ModalIconContainer = styled.div`
  margin-top: 42px;
`;

const ModalTitle = styled.h3`
  margin: 23px 0 0 0;
  font-size: 35px;
  font-weight: 200;
  font-family: ${Fonts.oswaldFont};
  letter-spacing: 0.35px;
  line-height: 45px;
  color: ${Colors.primary};
  text-align: center;
`;

const ModalText = styled.p`
  margin: 13px 0 0 0;
  font-size: 16px;
  font-family: ${Fonts.mainFont};
  line-height: 24px;
  color: ${Colors.neutral800};
  text-align: center;
  max-width: 640px;
`;

const SubmitButton = styled(FormikButton)`
  margin: 30px 0 45px 0;
  height: 40px;
`;

const CloseButton = styled.button`
  background: transparent;
  border: none;
  cursor: pointer;
  font-weight: bold;
  font-size: 14px;
  color: ${primary};
  padding: 7px;
  margin: 30px 0 45px 0;
  height: 40px;
  min-width: 120px;
}
`;

const ButtonContainer = styled.div`
  display: flex;
`;

export {
  ModalWrapper,
  ModalIconContainer,
  ModalTitle,
  ModalText,
  SubmitButton,
  CloseButton,
  ButtonContainer,
};
