import './notification.sass';

import classNames from 'classnames';

const getModifierClass = (options, propValue, propName) => {
  const prefix = propName ? `-${propName}` : '';
  return options.includes(propValue) ? `notification${prefix}-${propValue}` : null;
};

const Notification = ({ alignText, arrow, children, className, color, layout, message }) => {
  const alignClass = getModifierClass(['right', 'center'], alignText, 'text');
  const arrowClass = getModifierClass(['up', 'down'], arrow, 'arrow');
  const colorClass = getModifierClass(['blue', 'purple', 'red'], color);
  const layoutClass = getModifierClass(['large'], layout);
  const classes = classNames(
    'notification',
    alignClass,
    arrowClass,
    className,
    colorClass,
    layoutClass,
  );

  return (
    <div className={classes}>
      {message && <span className="notification-message">{message}</span>}
      {children}
    </div>
  );
};

export default Notification;
