import { Box, Container, styled } from '@frontend/design-system';

export const AuthContainer = styled(Container)(() => ({
  padding: '90px 20px 20px 20px',
  position: 'relative',
}));

export const AuthBlurBgContainer = styled(Container)(({ theme }) => ({
  width: '100%',
  height: '100%',
  borderRadius: '50%',
  opacity: '0.05',
  background: theme.palette.primary.main,
  filter: 'blur(100px)',
  position: 'absolute',
}));

export const AuthEnvelope = styled(Box)(({ theme }) => ({
  position: 'relative',
  background: theme.palette.neutral[0],
  padding: '78px 20px',
  margin: '0 auto',
  width: '100%',
  maxWidth: '572px',
  borderRadius: '9px',
  boxShadow: '0px 2px 10px 0px rgba(116, 116, 116, 0.10)',
}));
