import { call, put, takeEvery } from 'redux-saga/effects';

import { fetchOrders as fetchOrdersAction } from 'app-state/actions/orders';
import { hideModal, showModal } from 'app-state/actions/shared';
import {
  adjustInterestError,
  adjustInterestSuccess,
  cancelInterestError,
  cancelInterestSuccess,
  getIndicationOfInterestError,
  getIndicationOfInterestSuccess,
  getInvestorAnchorStatusError,
  getInvestorAnchorStatusSuccess,
  placeIndicationOfInterestError,
  placeIndicationOfInterestSuccess,
} from 'app-state/indication-of-interest/actions';
import * as constants from 'app-state/indication-of-interest/constants';
import type {
  AdjustIndicationOfInterestAction,
  CancelindicationOfInterestAction,
  GetIndicationOfInterestAction,
  GetInvestorAnchorStatusAction,
  PlaceIndicationOfInterestAction,
} from 'app-state/indication-of-interest/types';

import API from 'constants/api';
import {
  ADJUST_SUCCESS_MODAL_OPTIONS,
  SUCCESS_MODAL_OPTIONS,
} from 'shared/indication-of-interest/config';
import request from 'shared-parts/helpers/request';

import { FORM_FIELDS } from './enums';

function* placeIndicationOfInterest({ payload }: PlaceIndicationOfInterestAction) {
  // todo - send payload only when batch FORM_FIELDS.ITEMS create is allowed
  const [details] = payload[FORM_FIELDS.ITEMS];
  const data = {
    ...details,
    issuanceId: payload.issuanceId,
    [FORM_FIELDS.SPECIAL_CONDITIONS]: payload[FORM_FIELDS.SPECIAL_CONDITIONS],
  };

  try {
    yield call(request, API.Investment.placeIndicationOfInterest(), 'POST', data);
    yield put(placeIndicationOfInterestSuccess());
    yield put(showModal(SUCCESS_MODAL_OPTIONS));
  } catch (e: any) {
    yield put(placeIndicationOfInterestError(e.response.details));
  }
}

function* placeIndicationOfInterestWatcher() {
  yield takeEvery(constants.PLACE_INDICATION_OF_INTEREST, placeIndicationOfInterest);
}

// todo - move it to deal
function* getInvestorAnchorStatus({ payload }: GetInvestorAnchorStatusAction) {
  try {
    const { data } = yield call(request, API.isAnchorUser(payload.permalink));

    yield put(getInvestorAnchorStatusSuccess({ isAnchor: Boolean(data?.isAnchor) }));
  } catch (e) {
    yield put(getInvestorAnchorStatusError());
  }
}

function* getInvestorAnchorStatusWatcher() {
  yield takeEvery(constants.GET_INVESTOR_ANCHOR_STATUS, getInvestorAnchorStatus);
}

function* adjustIndicationOfInterest({ payload }: AdjustIndicationOfInterestAction) {
  try {
    yield call(
      request,
      API.Investment.adjustIndicationOfInterest(payload.indicationOfInterestId),
      'POST',
      payload,
    );
    yield put(adjustInterestSuccess());
    yield put(showModal(ADJUST_SUCCESS_MODAL_OPTIONS));
    yield put(fetchOrdersAction(payload.indicationOfInterestId));
  } catch (e: any) {
    yield put(adjustInterestError(e.response.details));
  }
}

function* adjustIndicationOfInterestWatcher() {
  yield takeEvery(constants.ADJUST_INDICATION_OF_INTEREST, adjustIndicationOfInterest);
}

function* cancelIndicationOfInterest({
  payload: { indicationOfInterestId },
}: CancelindicationOfInterestAction) {
  try {
    yield call(request, API.Investment.cancelIndicationOfInterest(indicationOfInterestId), 'PUT');
    yield put(cancelInterestSuccess());
    yield put(fetchOrdersAction(indicationOfInterestId));
    yield put(hideModal());
  } catch (e: any) {
    yield put(cancelInterestError(e.response.details));
  }
}

function* cancelIndicationOfInterestWatcher() {
  yield takeEvery(constants.CANCEL_INDICATION_OF_INTEREST, cancelIndicationOfInterest);
}

function* getIndicationOfInterest({
  payload: { indicationOfInterestId },
}: GetIndicationOfInterestAction) {
  try {
    // eslint-disable-next-line max-len
    const { data } = yield call(
      request,
      API.Investment.getIndicationOfInterest(indicationOfInterestId),
    );
    yield put(getIndicationOfInterestSuccess(data));
  } catch (e: any) {
    yield put(getIndicationOfInterestError(e.response.details));
  }
}

function* getIndicationOfInterestWatcher() {
  yield takeEvery(constants.GET_INDICATION_OF_INTEREST, getIndicationOfInterest);
}

export {
  placeIndicationOfInterestWatcher,
  getInvestorAnchorStatusWatcher,
  adjustIndicationOfInterestWatcher,
  cancelIndicationOfInterestWatcher,
  getIndicationOfInterestWatcher,
};
