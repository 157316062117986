import { Box, BoxProps, styled, Typography } from '@mui/material';

export const WidgetDescription = styled(
  (props: BoxProps) => {
    const { children, ...rest } = props;

    return (
      <Box {...rest}>
        <Typography>{children}</Typography>
      </Box>
    );
  },
  { label: 'WidgetDescription' },
)(({ theme }) => ({
  marginTop: theme.spacing(3),
  whiteSpace: 'pre-line',
}));
