// TODO: THIS IS ALREADY A THING IN LODASH
// https://lodash.com/docs/4.17.15#debounce
export function debounce(
  callback: () => any,
  wait: number,
  immediate?: boolean,
  // @ts-expect-error:next-line
  context: any = this,
) {
  let timeout: any = null;
  let callbackArgs: any = null;

  const later = () => callback.apply(context, callbackArgs);

  return function () {
    callbackArgs = arguments; // eslint-disable-line
    clearTimeout(timeout);
    timeout = immediate ? callback() : setTimeout(later, wait);

    return timeout;
  };
}
