import { call, put, takeEvery } from 'redux-saga/effects';

import API from 'constants/api';
import request from 'shared-parts/helpers/request';

import {
  fetchInvestorCompaniesError,
  fetchInvestorCompaniesSuccess,
  updateInvestorCompaniesPermissionError,
  updateInvestorCompaniesPermissionSuccess,
} from './actions';
import { FETCH_INVESTOR_COMPANIES, UPDATE_INVESTOR_COMPANIES_PERMISSION } from './constants';

export function* fetchInvestorCompanies() {
  try {
    const { data } = yield call(request, API.Investors.companies());

    yield put(fetchInvestorCompaniesSuccess(data.items));
  } catch (e) {
    yield put(fetchInvestorCompaniesError(e));
  }
}

export function* fetchInvestorCompaniesWatcher() {
  yield takeEvery(FETCH_INVESTOR_COMPANIES, fetchInvestorCompanies);
}

export function* updateInvestorCompaniesPermission({ data }) {
  try {
    const response = yield call(request, API.Investors.companiesPermission(), 'POST', data);

    yield put(updateInvestorCompaniesPermissionSuccess(response.data));
  } catch (e) {
    yield put(updateInvestorCompaniesPermissionError(e));
  }
}

export function* updateInvestorCompaniesPermissionWatcher() {
  yield takeEvery(UPDATE_INVESTOR_COMPANIES_PERMISSION, updateInvestorCompaniesPermission);
}
