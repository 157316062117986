import dayjs from '@frontend/datetime';

import { Deal } from '../deal-tile.types';

import { DealStats } from './deal-stats';
import { CommonsStats } from './shared.styled';

export type PendingIssuanceStatsProps = Deal & {
  oswaldFont: string;
  neutral300Color: string;
};

export const PendingIssuanceStats = ({
  subscriptionStart,
  oswaldFont,
  neutral300Color,
}: PendingIssuanceStatsProps) => (
  <CommonsStats neutral300Color={neutral300Color}>
    <DealStats oswaldFont={oswaldFont} text="Launching soon">
      {dayjs(subscriptionStart).format('L')}
    </DealStats>
  </CommonsStats>
);
