import styled from 'styled-components';

import * as Colors from 'shared-parts/constants/colors';
import * as Fonts from 'shared-parts/constants/fonts';

const ModalWrapper = styled.div`
  padding: 51px 168px;
  text-align: center;
  width: 800px;
  min-height: 370px;
`;

const Header = styled.h2`
  margin: 32px 0 14px 0;
  font-weight: 100;
  font-size: 35px;
  color: ${Colors.primary};
  font-family: ${Fonts.oswaldFont};
  font-style: normal;
  line-height: 42px;
`;

const Message = styled.div`
  color: ${Colors.neutral800};
  font-size: 16px;
  line-height: 24px;
  font-family: ${Fonts.mainFont};
`;

const Note = styled.p`
  font-weight: 600;
  margin: 0;
`;

const ButtonsPart = styled.div`
  margin-top: 28px;
`;

export { ModalWrapper, Header, Message, Note, ButtonsPart };
