import { Select, styled } from '@mui/material';

const StyledSelect = styled(Select)(({ theme }) => ({
  width: '320px',
  height: '40px',
  borderRadius: '6px',
  padding: '0',
  '& *': {
    fontWeight: theme.typography.fontWeightMedium,
  },

  '& .MuiSelect-select': {
    padding: '0 12px',
  },

  '& fieldset': {
    border: `1.5px solid ${theme.palette.neutral[300]}`,
  },
  // override MUI default hover color until design team decides on hover color
  '&:hover': {
    '& fieldset': {
      border: `1.5px solid ${theme.palette.neutral[300]} !important`,
    },
  },
  '&.Mui-error fieldset': {
    border: `1.5px solid ${theme.palette.error.main} !important`,
  },
  '&.Mui-focused fieldset': {
    border: `1.5px solid ${theme.palette.highlight.main} !important`,
  },

  '& .placeholder': {
    color: theme.palette.neutral[400],
    opacity: 1,
  },

  '&.Mui-disabled': {
    backgroundColor: theme.palette.neutral[50],
    '.MuiSelect-select': {
      color: theme.palette.neutral[500],
      WebkitTextFillColor: theme.palette.neutral[500],
    },
  },
}));

export { StyledSelect };
