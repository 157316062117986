import styled from 'styled-components';

import * as Colors from 'shared-parts/constants/colors';
import * as Fonts from 'shared-parts/constants/fonts';

const ModalWrapper = styled.div`
  display: flex;
  align-items: center;
  flex-direction: column;
  width: 800px;
  max-width: 95vw;
  height: 377px;
`;

const ModalIconContainer = styled.div`
  margin-top: 16px;
`;

const ModalTitle = styled.p`
  margin: 23px 0 0 0;
  font-size: 35px;
  font-weight: 200;
  font-family: ${Fonts.oswaldFont};
  letter-spacing: 0.35px;
  line-height: 45px;
  color: ${Colors.primary};
  text-align: center;
`;

const ModalText = styled.p`
  margin: 13px 0 0 0;
  max-width: 600px;
  font-size: 16px;
  font-family: ${Fonts.mainFont};
  line-height: 27px;
  color: ${Colors.neutral800};
  text-align: center;
`;

const ViewTransactionButton = styled.button`
  display: flex;
  align-items: center;
  background: none;
  border: none;
  padding: 0;
  margin: 24px 0 40px 0;
`;

const ButtonText = styled.span`
  color: ${Colors.alternative};
  font-size: 14px;
  font-weight: bold;
  text-transform: uppercase;
  margin-right: 5px;
`;

export {
  ModalWrapper,
  ModalIconContainer,
  ModalTitle,
  ModalText,
  ViewTransactionButton,
  ButtonText,
};
