import * as constants from 'app-state/constants';

export const saveAccountInfo = (params, setSubmitting, setErrors, redirectRoute) => ({
  type: constants.SAVE_ACCOUNT_INFO,
  params,
  setSubmitting,
  setErrors,
  redirectRoute,
});
export const saveAccountInfoSuccess = data => ({ type: constants.SAVE_ACCOUNT_INFO_SUCCESS, data });
export const saveAccountInfoError = error => ({ type: constants.SAVE_ACCOUNT_INFO_ERROR, error });

export const updateOnboardingInvestmentPreferences = payload => ({
  payload,
  type: constants.UPDATE_ONBOARDING_INVESTMENT_PREFERENCES,
});

export const fetchInvestmentPreferences = () => ({ type: constants.FETCH_INVESTMENT_PREFERENCES });
export const fetchInvestmentPreferencesSuccess = payload => ({
  type: constants.FETCH_INVESTMENT_PREFERENCES_SUCCESS,
  payload,
});
export const fetchInvestmentPreferencesError = error => ({
  type: constants.FETCH_INVESTMENT_PREFERENCES_ERROR,
  error,
});
export const resetInvestmentPreferences = () => ({ type: constants.RESET_INVESTMENT_PREFERENCES });

export const updateInvestmentPreferences = payload => ({
  payload,
  type: constants.UPDATE_INVESTMENT_PREFERENCES,
});
