import { styled } from '@mui/material';
import MuiDivider, { DividerProps as MuiDividerProps } from '@mui/material/Divider';

export type DividerProps = MuiDividerProps;

export const Divider = styled(MuiDivider)<DividerProps>(({ theme, orientation }) => ({
  ...(orientation === 'vertical'
    ? { borderColor: theme.palette.neutral[300], borderWidth: '1px', height: '14px' }
    : { borderColor: theme.palette.neutral[50], borderBottomWidth: '2px' }),
}));
