export const SHOW_MODAL = 'SHOW_MODAL';
export const HIDE_MODAL = 'HIDE_MODAL';

export const SHOW_PREVIEW = 'SHOW_PREVIEW';
export const HIDE_PREVIEW = 'HIDE_PREVIEW';

export const SAVE_ADDRESS = 'SAVE_ADDRESS';

export const SAVE_REDIRECT_PATH = 'SAVE_REDIRECT_PATH';
export const REMOVE_REDIRECT_PATH = 'REMOVE_REDIRECT_PATH';

export const UPDATE_NICKELLED_USER = 'UPDATE_NICKELLED_USER';
