import type { ReactNode, RefObject, SyntheticEvent } from 'react';
import ClickOutside from 'react-click-outside';

import styled from 'styled-components';

import AddNewButton from 'shared-parts/components/add-new-button';
import Loader from 'shared-parts/components/loader/loader';
import { Spinner } from 'shared-parts/components/loader/loader.styled';
import Progress from 'shared-parts/components/loader/progress';
import * as Colors from 'shared-parts/constants/colors';
import * as Fonts from 'shared-parts/constants/fonts';
import { Screens } from 'shared-parts/constants/screens';

const ClickOutsideWrapper = styled(ClickOutside)<{
  children: ReactNode;
  className?: string;
  onClickOutside: () => void;
}>`
  display: inline-block;
  position: relative;
  width: 100%;
`;

const SelectedOptionText = styled.span<{
  disabled?: boolean;
}>`
  background: none transparent;
  border: 0 none;
  box-sizing: border-box;
  -moz-box-sizing: border-box;
  -ms-box-sizing: border-box;
  -webkit-box-sizing: border-box;
  -khtml-box-sizing: border-box;
  display: inline-block;
  font-family: ${Fonts.mainFont};
  font-size: 16px;
  font-weight: 100;
  padding-left: 21px;
  line-height: 49px;
  text-overflow: ellipsis;
  overflow: hidden;
  height: 48px;
  position: absolute;
  text-align: left;
  white-space: nowrap;
  width: calc(100% - 40px);
  -webkit-appearance: none;
  z-index: 1;
  cursor: text;
  color: inherit;
  left: 0;

  ${({ disabled }) =>
    disabled
      ? `
    color: ${Colors.neutral800};
    cursor: default;
  `
      : ''}

  @media only screen and (max-width: ${Screens.sm}) {
    padding-left: 10px;
  }
`;

const Input = styled.input<{
  isInvalid?: boolean;
  disableAutocomplete?: boolean;
}>`
  background: none transparent;
  border: 0 none;
  border-radius: 6px;
  box-sizing: border-box;
  -moz-box-sizing: border-box;
  -ms-box-sizing: border-box;
  -webkit-box-sizing: border-box;
  -khtml-box-sizing: border-box;
  color: inherit;
  display: inline-block;
  font-family: ${Fonts.mainFont};
  font-size: 16px;
  font-weight: bold;
  padding-left: 21px;
  height: 48px;
  padding-right: 35px;
  position: absolute;
  width: 100%;
  -webkit-appearance: none;
  left: 0;

  &:-webkit-autofill,
  &:-webkit-autofill:hover,
  &:-webkit-autofill:focus {
    box-shadow: 0 0 0px 1000px
      ${({ isInvalid }) => (isInvalid ? Colors.error100 : Colors.success100)} inset !important;
    -webkit-box-shadow: 0 0 0px 1000px
      ${({ isInvalid }) => (isInvalid ? Colors.error100 : Colors.success100)} inset !important;
  }

  ${({ disableAutocomplete }) =>
    disableAutocomplete &&
    `
    &::-webkit-contacts-auto-fill-button,
    &::-webkit-credentials-auto-fill-button {
      visibility: hidden;
      pointer-events: none;
      position: absolute;
      right: 0;
    }
 `}

  &::-webkit-input-placeholder {
    color: ${Colors.neutral500};
    display: inline-block;
    font-family: ${Fonts.mainFont};
    font-size: 16px;
    font-weight: 500;
  }

  &::-moz-placeholder {
    color: ${Colors.neutral500};
    display: inline-block;
    font-family: ${Fonts.mainFont};
    font-size: 16px;
    font-weight: 500;
  }

  &:-ms-input-placeholder {
    color: ${Colors.neutral500};
    display: inline-block;
    font-family: ${Fonts.mainFont};
    font-size: 16px;
    font-weight: 500;
  }

  @media only screen and (max-width: ${Screens.sm}) {
    padding-left: 10px;
  }
`;

const ArrowWrapper = styled.button<{
  disabled?: boolean;
  dropdownVisible?: boolean;
}>`
  background-color: Transparent;
  background-repeat: no-repeat;
  border: none;
  display: table-cell;
  ${({ disabled }) => (disabled ? '' : 'cursor: pointer;')}
  float: right;
  height: ${({ dropdownVisible }) => (dropdownVisible ? '100%' : 'auto')};
  line-height: 42px;
  position: relative;
  text-align: center;
  vertical-align: middle;
  width: 40px;

  :focus {
    outline: none;
  }

  @media only screen and (max-width: ${Screens.sm}) {
    width: 30px;
  }
`;

const SelectContainer = styled.div<{
  onClick: () => void;
  disabled?: boolean;
  touched: boolean;
  isInvalid: boolean;
  height: number;
}>`
  background-color: ${({ disabled }) => (disabled ? Colors.neutral300 : Colors.white)};
  border: 1px solid
    ${({ touched, isInvalid }) => (isInvalid && touched && Colors.error) || Colors.neutral500};
  border-radius: 6px;
  position: relative;
  ${({ height }) => `
    height: ${height}px;
    
    ${SelectedOptionText} {
      line-height: ${height - 1}px;
      height: ${height - 2}px;
    }
    
    ${Input} {
      height: ${height - 2}px;
    }
    
    ${ArrowWrapper} {
      line-height: ${height - 8}px;
    }
  `}

  :focus-within {
    border: 1px solid
      ${({ isInvalid, disabled }) => (!isInvalid && !disabled ? Colors.secondary : 'inherit')};
  }
`;

const DownArrow = styled.i`
  border: solid ${Colors.neutral800};
  border-width: 0 3px 3px 0;
  display: inline-block;
  padding: 3px;
  transform: rotate(45deg);
  -webkit-transform: rotate(45deg);
  position: relative;
`;

const UpArrow = styled(DownArrow)`
  transform: rotate(-135deg);
  -webkit-transform: rotate(-135deg);
`;

type DropdownProps = {
  dropdownHeight?: string;
  dropdownVisible: boolean;
  length: number;
};

const getDropdownHeight: (props: DropdownProps) => string = ({ dropdownHeight, length }) => {
  if (length >= 5) {
    return dropdownHeight || '190px';
  }

  return 'auto';
};

const Dropdown = styled.div<DropdownProps>`
  background: ${Colors.white};
  box-shadow: 0 3px 6px ${Colors.shadowColor};
  display: ${props => (props.dropdownVisible ? 'block' : 'none')};
  font-size: 16px;
  max-height: ${getDropdownHeight};
  overflow-y: ${props => (props.length > 5 ? 'scroll' : 'hidden')};
  position: absolute;
  top: 58px;
  border-radius: 6px;
  width: 100%;
  z-index: 4;
`;

const Option = styled.button<{
  type?: string;
  key?: string;
  ref?: RefObject<HTMLButtonElement> | null;
  disabled?: boolean;
  selected?: boolean;
  active?: boolean;
  onMouseDown?: (e?: SyntheticEvent) => void;
}>`
  background-color: ${Colors.white};
  background-repeat: no-repeat;
  border: none;
  color: ${({ disabled, selected }) =>
    (disabled && 'gray') || (selected ? Colors.secondary : 'inherit')};
  cursor: ${({ disabled }) => (disabled ? '' : 'pointer')};
  font-family: ${Fonts.mainFont};
  font-size: 16px;
  ${({ selected }) => (selected ? 'font-weight: bold;' : '')}
  min-height: 38px;
  padding-left: 21px;
  text-align: left;
  width: 100%;

  &:hover {
    background-color: ${({ disabled }) => (disabled ? Colors.white : Colors.neutral200)};
  }

  ${({ active }) => active && `background-color: ${Colors.neutral200}`}
`;

const AddNewSelectButton = styled(AddNewButton)`
  border-top: 1px solid ${Colors.neutral300};
  padding: 10px 20px;
  width: 100%;
  position: sticky;
  bottom: 0;
  left: 0;
  background: ${Colors.white};
  z-index: 1;
`;

const OptionsLoader = styled(Loader)`
  background-color: ${Colors.accent};
  height: 35px;

  ${Spinner} {
    height: 30px;
    left: calc(50% - 15px);
    top: calc(50% - 15px);
    width: 30px;
  }
`;

const ProgressLine = styled(Progress)`
  height: 3px;
  position: relative;
`;

export {
  ClickOutsideWrapper,
  SelectContainer,
  SelectedOptionText,
  Input,
  ArrowWrapper,
  UpArrow,
  DownArrow,
  Dropdown,
  Option,
  AddNewSelectButton,
  OptionsLoader,
  ProgressLine,
};
