import type { ForkEffect } from '@redux-saga/core/effects';
import { call, put, takeEvery } from 'redux-saga/effects';

import {
  fetchIssuersError,
  fetchIssuersSuccess,
  fetchShareholdingIssuersError,
  fetchShareholdingIssuersSuccess,
} from 'app-state/actions/issuers';
import { GET_ISSUERS, GET_SHAREHOLDING_ISSUERS } from 'app-state/constants/issuers';

import API from 'constants/api';
import request from 'shared-parts/helpers/request';

function* fetchIssuers() {
  try {
    const { data } = yield call(request, API.Investment.equityIssuers());

    yield put(fetchIssuersSuccess({ data }));
  } catch (error) {
    yield put(fetchIssuersError({ error }));
  }
}

function* fetchIssuersWatcher(): Generator<ForkEffect> {
  yield takeEvery(GET_ISSUERS, fetchIssuers);
}

function* fetchShareholdingIssuers() {
  try {
    const { data } = yield call(request, API.Investment.shareholdingIssuers());

    yield put(fetchShareholdingIssuersSuccess({ data }));
  } catch (error) {
    yield put(fetchShareholdingIssuersError({ error }));
  }
}

function* fetchShareholdingIssuersWatcher(): Generator<ForkEffect> {
  yield takeEvery(GET_SHAREHOLDING_ISSUERS, fetchShareholdingIssuers);
}

export { fetchIssuersWatcher, fetchShareholdingIssuersWatcher };
