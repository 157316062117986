import type { FC, MouseEvent } from 'react';

import classNames from 'classnames';

import { AdditionalText, Wrapper } from './link.styled';
import NavLinkOrAnchor from './linkOrAnchor';

const renderAdditionalText = (text: string) => text && <AdditionalText>{text}</AdditionalText>;

type Props = {
  url: string;
  title?: string;
  additionalText?: string;
  noUnderline?: boolean;
  secondaryColor: string;
  bold?: boolean;
  fontSize?: string;
  hoverColor?: string;
  className?: string;
  onClick?: (e?: MouseEvent<HTMLAnchorElement>) => void;
  target?: string;
  rel?: string;
  id?: string;
};

export const Link: FC<React.PropsWithChildren<Props>> = ({
  url,
  title = '',
  additionalText = '',
  noUnderline,
  secondaryColor,
  bold,
  fontSize,
  hoverColor,
  className = '',
  onClick,
  target,
  rel,
  id,
  children,
}) => {
  const isMailTo = url.substring(0, 6) === 'mailTo';
  const classes = classNames('link', className);

  return (
    <Wrapper
      fontSize={fontSize}
      className={classes}
      secondaryColor={secondaryColor}
      bold={bold}
      hoverColor={hoverColor}
      noUnderline={noUnderline}
    >
      {renderAdditionalText(additionalText)}
      <NavLinkOrAnchor
        id={id}
        target={target}
        isMailTo={isMailTo}
        url={url}
        onClick={onClick}
        title={title}
        rel={rel}
      >
        {children}
      </NavLinkOrAnchor>
    </Wrapper>
  );
};
