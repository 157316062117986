import { GroupRowStyles, totalRowStyles } from '../regular-table/table.styled';

import generateEmptyRow from './generate-empty-row';
import generateTableData from './generate-table-data';
import generateTotalRow from './generate-total-row';
import getSortedData from './get-sorted-data';

const generateGroupRow = (groups, group, keys, expandable = false, loading = false) => ({
  cells: keys.map(({ field, transform, styles }) => {
    const preparedStyles = styles ? styles(group[field], group, groups) : '';

    if (field) {
      const column = group[field];

      if (!column) {
        return { text: ' ', styles: () => `${GroupRowStyles}${preparedStyles}` };
      }

      const result = transform ? transform(column, group, groups) : column;

      return { text: result, styles: () => `${GroupRowStyles}${preparedStyles}` };
    }
    return { text: ' ', styles: () => `${GroupRowStyles}${preparedStyles}` };
  }),
  item: { ...group, groupExpander: expandable, loading },
});

const generateGroupTableData = (props, sortingData, expandedGroups) => {
  const {
    groups,
    groupsItems,
    getGroupTotalRowData,
    keys,
    uniqueGroupKey,
    isRowClickDisabled,
    groupErrorMessage,
    groupRetryAction,
  } = props;
  const tableData = [];

  groups.forEach(group => {
    const groupKey = group[uniqueGroupKey];
    const { loading, data, error } = groupsItems.get(groupKey) || {};

    const groupExpanderRow = { ...generateGroupRow(groups, group, keys, true, loading) };

    tableData.push(groupExpanderRow);
    if (expandedGroups.includes(groupKey) && !loading) {
      if (error) {
        tableData.push(generateEmptyRow(keys, groupErrorMessage, groupRetryAction(groupKey)));
        return tableData;
      }

      const sortedData = sortingData ? getSortedData(data.subItems, sortingData) : data.subItems;

      tableData.push(...generateTableData(sortedData, keys, { isRowClickDisabled }));

      const groupTotalRowData = getGroupTotalRowData ? getGroupTotalRowData(data) : null;

      if (groupTotalRowData) {
        const totalKeys = keys.map(k => ({ ...k, styles: `${k.styles()}${totalRowStyles}` }));

        tableData.push({
          cells: generateTotalRow(totalKeys, groupTotalRowData),
          item: { isRowClickDisabled: true },
        });
      }
    }
  });
  return tableData;
};

export { generateGroupTableData, generateGroupRow };
