import type { ReactChild } from 'react';
import { createContext, FC, useContext, useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { useHistory } from 'react-router-dom';

import { hideModal, showModal } from 'app-state/actions';
import { getUser } from 'app-state/selectors';

import { LiquidityRound, RequestError } from 'modules/liquidity-round/types';
import API from 'constants/api';
import Routes from 'constants/routes';
import useTypedSelector from 'hooks/use-typed-selector';
import { getURLParameters, request } from 'helpers';
import { LockFilled } from 'svg-icons';
import ConfirmationModal from 'shared-parts/components/confirmation-modal';
import { alternative } from 'shared-parts/constants/colors';

import { ILiquidityContext } from './types';

type Props = {
  children: ReactChild;
};

const initialContext: ILiquidityContext = {
  state: { round: null },
  actions: { setRound: () => null, clearRound: () => null },
};

const LiquidityContext = createContext<ILiquidityContext>(initialContext);

LiquidityContext.displayName = 'LiquidityContext';

const LiquidityProvider: FC<React.PropsWithChildren<Props>> = ({ children }) => {
  const dispatch = useDispatch();
  const user = useTypedSelector(getUser);
  const { investor } = user;
  const [liquidityRound, setLiquidityRound] = useState<LiquidityRound | null>(null);
  const history = useHistory();

  const showOnboardingErrorModal = () => {
    dispatch(
      showModal({
        component: ConfirmationModal,
        confirmButtonText: 'ONBOARD',
        showCloseButton: false,
        title: 'Onboarding not completed',
        text: 'To view all liquidity rounds, you must complete the onboarding process.',
        handleConfirmation: () => {
          history.push(Routes.Onboarding.Individual.AccountInformationSecondPart());
          dispatch(hideModal());
        },
        icon: LockFilled({ color: alternative, height: 60, width: 60 }),
        buttonsMarginTop: '24px',
        iconMarginBottom: '15px',
      }),
    );
  };

  const setRound = (round: LiquidityRound | null) => {
    setLiquidityRound(round);
  };

  const clearRound = () => {
    setLiquidityRound(null);
  };

  const redeemToken = async (inviteTokenFromStorage: string, isAccessingInviteLink: boolean) => {
    try {
      await request(
        API.LiquidityService.LiquidityRoundRedeem(),
        'POST',
        {
          token: inviteTokenFromStorage,
        },
        { to: 'text' },
      );
      localStorage.removeItem('inviteToken');
    } catch (err) {
      const errorMessage = (err as RequestError).response.message;
      /*
       * If isAccessingInviteLink is true, it means the user is trying to access a liquidity round
       * through the invite link, which is the only case where we want to show the onboarding error
       * modal to the user if they are not onboarded
       */
      if (isAccessingInviteLink && errorMessage === 'Investor is not onboarded') {
        showOnboardingErrorModal();
      } else if (errorMessage === 'Token already redeemed by user') {
        localStorage.removeItem('inviteToken');
      }
    }
  };

  useEffect(() => {
    const [inviteTokenFromUrl]: [string | null] = getURLParameters(['inviteToken']);
    if (inviteTokenFromUrl) localStorage.setItem('inviteToken', inviteTokenFromUrl);

    if (JSON.stringify(user) !== '{}' && investor) {
      const inviteTokenFromStorage = localStorage.getItem('inviteToken');
      if (inviteTokenFromStorage) {
        redeemToken(inviteTokenFromStorage, !!inviteTokenFromUrl);
      }
    }
  }, [user]);

  return (
    <LiquidityContext.Provider
      value={{
        state: { round: liquidityRound },
        actions: { setRound, clearRound },
      }}
    >
      {children}
    </LiquidityContext.Provider>
  );
};

const useLiquidity = () => useContext(LiquidityContext);

export { LiquidityProvider, useLiquidity };
