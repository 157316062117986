import { getTenantConfig } from 'helpers/get-tenant-config';

const { fonts } = getTenantConfig();

// TODO: yes, this is pretty ugly, but rewriting all of the code
// that relies on actual ESM exports is a lot of changes and I'll
// do that later... or never...

const { mainFont, oswaldFont } = fonts;
export { mainFont, oswaldFont };
