import * as constants from 'app-state/constants';

export const getIssuanceState = (params = {}) => ({
  loading: true,
  data: {},
  errors: null,
  ...params,
});

export const issuanceReducer = (state = getIssuanceState(), action) => {
  // eslint-disable-line
  switch (action.type) {
    case constants.FETCH_ISSUANCE:
      return { ...state, loading: true };
    case constants.FETCH_ISSUANCE_SUCCESS:
      return getIssuanceState({ loading: false, data: action.issuance });
    case constants.FETCH_ISSUANCE_ERROR:
      return getIssuanceState({ loading: false, errors: action.error });
    default:
      return state;
  }
};
