import styled from 'styled-components';

import { FormikButton } from 'shared';
import { Colors, Fonts } from 'shared-parts/constants';

const ModalWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  width: 580px;
  height: 350px;
`;

const ModalText = styled.p`
  text-align: center;
  margin: 20px 0;
  font-weight: 600;
  color: ${Colors.neutral800};
  font-family: ${Fonts.mainFont};
`;

const CancelButton = styled.button`
  background: transparent;
  border: none;
  cursor: pointer;
  color: ${Colors.primary};
  font-family: ${Fonts.mainFont};
`;

const CancelActionButton = styled(FormikButton)`
  height: 40px;
  margin-left: 20px;
  background: ${Colors.alternative};
  font-weight: 300;

  :hover {
    background: ${Colors.alternative};
  }
`;

export { ModalWrapper, ModalText, CancelButton, CancelActionButton };
