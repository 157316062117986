import humps from 'humps';

export default (apiParams, encodeParamsNames = []) => {
  const keys = Object.keys(apiParams);

  return keys.length
    ? keys.reduce((prev, current, index) => {
        const paramValue = encodeParamsNames.includes(current)
          ? encodeURIComponent(
              typeof apiParams[current] === 'string'
                ? apiParams[current]
                : JSON.stringify(apiParams[current]),
            )
          : apiParams[current];
        const decamelizedKey = humps.decamelize(current);

        return apiParams[current]
          ? `${prev}${index === 0 ? '' : '&'}${decamelizedKey}=${paramValue}`
          : `${prev}`;
      }, '?')
    : '';
};
