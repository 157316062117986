import { Field } from 'formik';
import { decamelize } from 'humps';

import { Description, Icon, RadioInput, RadioLabel } from './radio.styled';

const CustomRadioComponent = ({ field, form, ...props }) => {
  const handleChange = e => {
    if (props.onChange) {
      props.onChange(e, form);
    }
    field.onChange(e);
  };
  const checked = field.value === props.value;

  return (
    <RadioLabel {...props} checked={checked} onChange={handleChange}>
      {props.text}
      <RadioInput
        type="radio"
        {...field}
        {...props}
        data-e2e={`${decamelize(field.name, { separator: '-' })}-${decamelize(props.value, {
          separator: '-',
        })}`}
      />
      <Icon {...props} checked={checked} />
      <Description>{props.description}</Description>
    </RadioLabel>
  );
};

export default props => <Field {...props} component={CustomRadioComponent} />;

export { RadioInput, RadioLabel, Icon };
