import * as constants from 'app-state/constants';

export const getCurrentUser = shouldRedirectOnLogout => ({
  type: constants.GET_CURRENT_USER,
  shouldRedirectOnLogout,
});

export const updateCurrentUser = (formData, form, handleSuccess) => ({
  type: constants.UPDATE_CURRENT_USER,
  formData,
  handleSuccess,
  form,
});

export const resendEmailConfirmation = userId => ({
  type: constants.RESEND_EMAIL_CONFIRMATION,
  userId,
});
export const resendEmailConfirmationError = error => ({
  type: constants.RESEND_EMAIL_CONFIRMATION_ERROR,
  error,
});
