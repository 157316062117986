import { formatDescriptionListData } from './box-description-list.helpers';
import { WrappedTable } from './box-description-list.styled';

export interface ItemProps {
  text: string;
  styles: string;
}

interface BoxDescriptionListProps {
  data: ItemProps[][];
}

export default ({ data }: BoxDescriptionListProps): JSX.Element => (
  <WrappedTable isHTMLAllowed data={formatDescriptionListData(data)} loading={false} />
);

export { WrappedTable };
