import styled, { css } from 'styled-components';

import { Colors, Fonts, Screens } from 'shared-parts/constants';

export const getBaseStyle = props => css`
  background: ${Colors.alternative};
  border-color: ${Colors.alternative};
  color: ${Colors.alternativeContrastText};
  cursor: pointer;

  font-family: ${Fonts.oswaldFont};
  font-size: 14px;
  letter-spacing: 1px;
  padding: 10px 30px;
  text-transform: uppercase;
  text-align: center;
  min-width: 122px;
  user-select: none;
  border-radius: 0.25rem;
  // For high contrast mode
  border: 1px solid transparent;

  ${props.marginLeft && 'margin-left: 8px;'}

  &:enabled:hover, &:enabled:focus {
    background-color: ${Colors.alternative600};
    border-color: ${Colors.alternative600};
    box-shadow: none;
  }

  &:focus {
    border: 1px solid ${Colors.alternative600};
  }

  &:disabled {
    cursor: not-allowed;
    background: ${Colors.neutral400};
    color: ${Colors.neutral700};
  }

  @media (max-width: ${Screens.md}) {
    padding: 10px 20px;
  }
  z-index: 1001;
`;

const InvestButton = styled.button`
  position: relative;
  ${getBaseStyle}
`;

export const InvestButtonWrapper = styled.div`
  display: flex;
  align-items: center;
`;
export default InvestButton;
