import PropTypes from 'prop-types';

import { decodeHtml } from 'shared-parts/helpers/html/encode';
import CompanyLinkIcon from 'shared-parts/images/company-link-icon.svg';
import CompanySizeIcon from 'shared-parts/images/company-size-icon.svg';
import CompanyTypeIcon from 'shared-parts/images/company-type-icon.svg';
import LocationIcon from 'shared-parts/images/location-icon-company.svg';

import { Icon, ItemName, LocationItem, LocationSectionWrapper } from './section-location.styled';

const SectionOverviewLocation = props => {
  const { location, domain, size, url } = props;
  return (
    <LocationSectionWrapper>
      <LocationItem>
        <Icon src={LocationIcon} alt="Globacap location icon" />
        <ItemName>{decodeHtml(location)}</ItemName>
      </LocationItem>
      <LocationItem>
        <Icon src={CompanyTypeIcon} alt="Globacap location icon" />
        <ItemName>{decodeHtml(domain)}</ItemName>
      </LocationItem>
      <LocationItem>
        <Icon src={CompanySizeIcon} alt="Globacap location icon" />
        <ItemName>{decodeHtml(size)}</ItemName>
      </LocationItem>
      <LocationItem>
        <Icon src={CompanyLinkIcon} alt="Globacap location icon" />
        <ItemName>{decodeHtml(url)}</ItemName>
      </LocationItem>
    </LocationSectionWrapper>
  );
};

SectionOverviewLocation.propTypes = {
  location: PropTypes.string.isRequired,
  domain: PropTypes.string.isRequired,
  size: PropTypes.string.isRequired,
  url: PropTypes.string.isRequired,
};

export default SectionOverviewLocation;
