import styled from 'styled-components';

import { Colors } from 'shared-parts/constants';
import { mainFont, oswaldFont } from 'shared-parts/constants/fonts';

export const TileContainer = styled.div`
  border: 1px solid ${Colors.neutral500};
  border-radius: 7px;
  box-sizing: content-box;
  min-height: 480px;
  padding-bottom: 32px;
  width: 350px;
  &:hover {
    box-shadow: 1px 1px 5px ${Colors.neutral700};
  }
`;

export const TileWrapper = styled.div`
  width: inherit;
`;

export const Head = styled.div`
  position: relative;
  width: 100%;
`;

export const SecurityLabel = styled.div`
  align-items: center;
  background-color: ${Colors.alternative};
  border-radius: 7px 0 0 7px;
  display: flex;
  height: 38px;
  justify-content: center;
  margin-top: 45px;
  margin-left: 196px;
  position: absolute;
  width: 154px;
`;

export const SecurityLabelText = styled.div`
  color: ${Colors.alternativeContrastText};
  font: normal normal bold 14px/22px ${mainFont};
  letter-spacing: 0.14px;
  text-transform: capitalize;
`;

export const LogoContainer = styled.div`
  position: absolute;
  margin: auto;
  left: calc(25% - 50px);
  top: calc(50% - 16px);
  height: 72px;
  width: 71px;

  padding: 2px;
  border-radius: 50%;
  border: 1px solid ${Colors.white};
  background: ${Colors.white};
`;

export const Logo = styled.img`
  align-items: center;
  border-radius: 50%;
  display: flex;
  height: 68px;
  justify-content: center;
  width: 68px;
  object-fit: cover;
  height: 100%;
  width: 100%;
`;

export const LogoPlaceholder = styled.div`
  align-items: center;
  background-color: ${Colors.white};
  border: 1px dashed ${Colors.neutral300};

  background-size: cover;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  height: 100%;
  width: 100%;
`;

export const BannerContainer = styled.div`
  height: 64px;
`;

export const Banner = styled.img`
  background-color: ${Colors.highlight};
  border-radius: 7px 7px 0 0;
  height: 100%;
  width: 100%;
  object-fit: cover;
`;

export const BannerPlaceholder = styled.div`
  background-color: ${Colors.neutral200};
  border-radius: 7px 7px 0 0;
  height: 100%;
  width: 100%;
`;

export const Body = styled.div`
  padding: 0 40px;
`;

export const Name = styled.div`
  color: ${Colors.primary};
  display: flex;
  font: normal normal 300 30px/45px ${oswaldFont};
  margin-top: 49px;
  text-transform: uppercase;
`;

export const DaysToGoButton = styled.div`
  align-items: center;
  background-color: ${Colors.alternative100};
  border: none;
  border-radius: 6px;
  display: flex;
  font-weight: bold;
  height: 32px;
  justify-content: center;
  margin-top: 10px;
  width: 100px;
`;

export const DaysToGoText = styled.div`
  color: ${Colors.primary};
  font: normal normal bold 12px/22px ${mainFont};
  letter-spacing: 0.12px;
`;

export const Description = styled.span`
  color: ${Colors.neutral800};
  display: flex;
  font: normal normal normal 14px/22px ${mainFont};
  letter-spacing: 0.12px;
  margin-top: 15px;
  white-space: pre-wrap;
  word-break: break-word;
`;
