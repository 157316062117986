import { getGridSingleSelectOperators, getGridStringOperators } from '../datagrid';

import { hasAllOperator } from './hasAll';
import { hasAnyOperator } from './hasAny';

export const hasAllOnly = [hasAllOperator];
export const hasAnyOnly = [hasAnyOperator];

export const containsOnly = getGridStringOperators().filter(item => item.value === 'contains');
export const isOnly = getGridSingleSelectOperators().filter(item => item.value === 'is');
export const isAnyOfOnly = getGridSingleSelectOperators().filter(item => item.value === 'isAnyOf');
