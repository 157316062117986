import { accent } from 'shared-parts/constants/colors';

const BankTransferIcon = ({ width = '22', height = '30', color = accent }) => (
  <svg xmlns="http://www.w3.org/2000/svg" width={width} height={height} viewBox="0 0 29.358 23.444">
    <g id="BANK_TRANSFER" data-name="BANK TRANSFER" transform="translate(-266.455 -70.903)">
      <path
        id="Polygon_2"
        data-name="Polygon 2"
        d="M13.838,0,27.676,7.629H0Z"
        transform="translate(267.296 71.653)"
        fill="none"
        stroke={color}
        strokeLinejoin="round"
        strokeWidth="1.5"
      />
      <rect
        id="Rectangle_694"
        data-name="Rectangle 694"
        width="25.57"
        height="1.421"
        rx="0.71"
        transform="translate(268.349 91.054)"
        fill={color}
      />
      <rect
        id="Rectangle_695"
        data-name="Rectangle 695"
        width="29.358"
        height="1.421"
        rx="0.71"
        transform="translate(266.455 92.926)"
        fill={color}
      />
      <rect
        id="Rectangle_693"
        data-name="Rectangle 693"
        width="2.368"
        height="12.311"
        rx="0.5"
        transform="translate(276.636 79.453)"
        fill={color}
      />
      <rect
        id="Rectangle_696"
        data-name="Rectangle 696"
        width="2.368"
        height="12.311"
        rx="0.5"
        transform="translate(283.265 79.453)"
        fill={color}
      />
      <rect
        id="Rectangle_697"
        data-name="Rectangle 697"
        width="2.368"
        height="12.311"
        rx="0.5"
        transform="translate(288.947 79.453)"
        fill={color}
      />
      <rect
        id="Rectangle_692"
        data-name="Rectangle 692"
        width="2.368"
        height="12.311"
        rx="0.5"
        transform="translate(270.953 79.453)"
        fill={color}
      />
    </g>
  </svg>
);

export default BankTransferIcon;
