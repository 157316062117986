import { Formik } from 'formik';

import { IndicationOfInterest } from 'app-state/indication-of-interest/types';

import { FeatureFlag, useFeatureFlag } from 'providers';

import { getInitialFormValues } from '../constants';

import renderForm from './index';
import { getValidation } from './validation';

const PlaceFormWrapper = ({
  handleFormSubmit,
  currency,
  isLoading,
}: {
  handleFormSubmit: (values: IndicationOfInterest) => void;
  currency: string;
  isLoading: boolean;
}): JSX.Element => {
  const disableValuationField = useFeatureFlag(FeatureFlag.DISABLED_VALUATION_FIELD);
  return (
    <Formik
      enableReinitialize
      validateOnMount
      onSubmit={handleFormSubmit}
      validationSchema={getValidation(disableValuationField)}
      initialValues={getInitialFormValues(disableValuationField)}
    >
      {renderForm({ currency, isLoading, disableValuationField })}
    </Formik>
  );
};

export default PlaceFormWrapper;
