import { forwardRef } from 'react';

import { styled } from '@mui/material';
import MuiCheckbox, { CheckboxProps as MuiCheckboxProps } from '@mui/material/Checkbox';

export type CheckboxProps = MuiCheckboxProps;

const CheckboxBase = forwardRef<HTMLButtonElement, CheckboxProps>((props, ref) => {
  return (
    <MuiCheckbox
      ref={ref} // Following properties are set by default. Can be overwritten by props.
      color="secondary"
      icon={
        <svg width="19" height="20" viewBox="0 0 19 20" fill="none">
          <rect x="0.5" y="0.832031" width="17.6667" height="18.3333" rx="3.5" />
        </svg>
      }
      checkedIcon={
        <svg width="19" height="20" viewBox="0 0 19 20" fill="none">
          <path d="M4.99935 11.3333L7.66602 13.3333L13.666 6" strokeWidth="2" />
          <rect x="0.5" y="0.832031" width="17.6667" height="18.3333" rx="3.5" />
        </svg>
      }
      indeterminateIcon={
        <svg width="19" height="20" viewBox="0 0 19 20" fill="none">
          <rect x="0.5" y="0.832031" width="17.6667" height="18.3333" rx="3.5" />
          <line x1="3.83333" y1="10" x2="14.8334" y2="10" />
        </svg>
      }
      {...props}
    />
  );
});

export const Checkbox = styled(CheckboxBase)(({ theme }) => ({
  // Styles labels (FormControlLabel)
  '& + span': {
    fontWeight: theme.typography.fontWeightMedium,
    color: theme.palette.neutral[600],
  },
  // Not Checked
  '&:not(.Mui-checked), &:not(.MuiCheckbox-indeterminate)': {
    '& path, & rect, & line': {
      stroke: theme.palette.neutral[400],
    },
  },
  // Checked or Indeterminate
  '&.Mui-checked, &.MuiCheckbox-indeterminate': {
    '& path, & rect, & line': {
      stroke: theme.palette.neutral[600],
    },
  },
  '& svg': {
    fill: 'none',
  },
  '&.Mui-disabled': {
    '& path, & rect, & line': {
      stroke: theme.palette.neutral[200],
    },
  },
}));
