/* eslint-disable max-len */
import { FC, useEffect, useState } from 'react';
import { connect } from 'react-redux';

import { Form, Formik } from 'formik';
import { Dispatch } from 'redux';
import styled from 'styled-components';
import * as Yup from 'yup';

import { fetchWallets, showModal } from 'app-state/actions';
import { getWallets as getWalletsSelector } from 'app-state/selectors/wallets';
import { RootState } from 'app-state/store';

import { useBankAccounts, WithdrawStatus } from 'modules/plaid/bank-accounts/use-bank-accounts';
import { PLAID_WITHDRAW_LIMIT } from 'modules/plaid/constants';
import { Wallet } from 'modules/wallet-balance/models';
import { toFloat, toFloatCurrency } from 'helpers';
import { Dialog, DialogActions } from 'shared/dialogs';
import { FormControl, FormHint, FormLabel } from 'shared/forms';
import { Select, TextInput } from 'shared-parts/components';
import { ActionButton, SecondaryActionButton } from 'shared-parts/components/button/action-buttons';
import { Spinner } from 'shared-parts/components/loader/loader.styled';
import InfoModal from 'shared-parts/components/modal/info-modal';
import SuccessModal from 'shared-parts/components/modal/success-modal';
import { OptionType } from 'shared-parts/components/select/select.types';
import { Colors, defaultErrorMessage } from 'shared-parts/constants';

type SelectBankAccountProps = ReturnType<typeof mapDispatchToProps> &
  ReturnType<typeof mapStateToProps>;

const AlertDialog = styled(Dialog)`
  text-align: center;
  position: relative;
`;

const CurrencyPrefix = styled.span`
  padding: 14px 16px;
  color: ${Colors.neutral700};
`;

const AdditionalHint = styled(FormHint)`
  display: inline-block;
`;

const CreateWithdrawDialog: FC<React.PropsWithChildren<SelectBankAccountProps>> = ({
  wallets,
  notifyWithdrawalComplete,
  cancelWithdrawal,
}) => {
  const bankAccounts = useBankAccounts();
  const [bankAccountSelectOptions, setBankAccountSelectOptions] = useState<OptionType[]>([]);
  const [wallet, setWallet] = useState<Wallet>();
  const [availableAmount, setAvailableAmount] = useState(0);
  const [error, setError] = useState(false);

  useEffect(() => {
    const selectedWallet = wallets.find((w: Wallet) => w.currency === 'GBP');
    setWallet(selectedWallet);

    if (!selectedWallet) {
      setError(true);
      console.warn('Wallet missing', wallets);

      return;
    }
    setAvailableAmount(Number(selectedWallet.available));
  }, [wallets]);

  useEffect(() => {
    if (!bankAccounts) {
      return;
    }

    const options: OptionType[] = bankAccounts.map(account => ({
      text: `${account.name} (${account.account} ${account.sortCode})`,
      value: account.accountId,
    }));

    setBankAccountSelectOptions(options);
  }, [bankAccounts]);

  if (!wallet || !bankAccounts) {
    return (
      <AlertDialog>
        <Spinner isLocal localSpinnerSize={40} withMessage={false} isDone={false} />
      </AlertDialog>
    );
  }

  return (
    <Formik
      initialValues={{
        selectedAccountId: bankAccounts[0]?.accountId || '',
        withdrawAmount: '',
      }}
      validationSchema={Yup.object({
        selectedAccountId: Yup.string().required('A bank account must be selected'),
        withdrawAmount: Yup.string().test('test-withdrawAmount', '', function (value) {
          const { path, createError } = this;
          let errorMsg;
          if (!value) {
            errorMsg = 'Please enter an amount';
          } else if (Number(value) < 1) {
            errorMsg = 'Minimum amount is 1£';
          } else if (Number(value) > availableAmount) {
            errorMsg = `The amount you are trying to withdraw exceeds your available cash balance, please try again with a lower amount`;
          } else if (Number(value) > PLAID_WITHDRAW_LIMIT) {
            errorMsg = `Cannot withdraw more than £${toFloat(PLAID_WITHDRAW_LIMIT)}.`;
          }
          return errorMsg ? createError({ path, message: errorMsg }) : true;
        }),
      })}
      onSubmit={async values => {
        setError(false);

        const selectedAccount = bankAccounts.find(
          account => account.accountId === values.selectedAccountId,
        );

        if (!selectedAccount) {
          throw new Error('Cannot find selected bank account in account list.');
        }

        try {
          const response = await selectedAccount.withdraw(wallet.id, values.withdrawAmount);

          if (response.status === WithdrawStatus.Pending) {
            notifyWithdrawalComplete(Number(values.withdrawAmount), 'GBP', selectedAccount.name);
          } else if (response.rejectionReason) {
            console.error(response.rejectionReason);
            setError(true);
          } else {
            setError(true);
          }
        } catch (e) {
          console.error('Something went wrong submitting withdraw request.', e);
          setError(true);
        }
      }}
    >
      {({ isValid, dirty, isSubmitting }) => (
        <Dialog>
          <Form>
            {error && defaultErrorMessage}

            <FormControl>
              <FormLabel htmlFor="withdrawAmount">
                How much of your available cash balance would you like to withdraw?
              </FormLabel>

              <TextInput
                name="withdrawAmount"
                hideValidationIcon
                Prefix={<CurrencyPrefix>£</CurrencyPrefix>}
                isAmount
                dpAmount={2}
              />
              <FormHint>£{toFloat(availableAmount)} available to withdraw.</FormHint>
              <br />
              <AdditionalHint>
                Only withdraws of up to £{toFloat(PLAID_WITHDRAW_LIMIT)} are supported. If you wish
                to exceed this limit please contact support.
              </AdditionalHint>
            </FormControl>

            <FormControl>
              <FormLabel htmlFor="country">
                Which of your authorised bank accounts would you like to make this withdrawal into?
              </FormLabel>

              <Select
                name="selectedAccountId"
                options={bankAccountSelectOptions}
                disableAutocomplete
                placeholder="Select account"
              />
            </FormControl>

            <DialogActions>
              <SecondaryActionButton type="button" onClick={cancelWithdrawal}>
                Cancel
              </SecondaryActionButton>
              <ActionButton disabled={!isValid || !dirty || isSubmitting} type="submit">
                Withdraw
              </ActionButton>
            </DialogActions>
          </Form>
        </Dialog>
      )}
    </Formik>
  );
};

const mapStateToProps = (state: RootState) => ({
  wallets: getWalletsSelector(state),
});

const mapDispatchToProps = (dispatch: Dispatch) => ({
  cancelWithdrawal: () =>
    dispatch(
      showModal({
        closable: true,
        component: InfoModal,
        title: 'Withdrawal Cancelled',
        additionalText: 'No funds will be withdrawn from your cash balance.',
        buttonText: 'OK',
        modalWidth: 600,
        iconSize: 40,
      }),
    ),
  notifyWithdrawalComplete: (amount: number, currency: string, accountName: string) => {
    dispatch(
      showModal({
        closable: true,
        component: SuccessModal,
        title: 'Withdrawal Request Sent',
        additionalText: (
          <>
            <span>Your withdrawal of </span>
            <span>
              <strong>{toFloatCurrency(amount, currency)}</strong> to your
              <strong> {accountName}</strong> account is being processed.
            </span>
            <br />
            <br />
            <span>You will receive an email notification once the transaction is complete.</span>
          </>
        ),
        buttonText: 'OK',
        modalWidth: 600,
        iconSize: 40,
        messageMaxWidth: '366px',
      }),
    );
    dispatch(fetchWallets());
  },
});

export default connect(mapStateToProps, mapDispatchToProps)(CreateWithdrawDialog);
