import { push } from 'connected-react-router';

import { call, debounce, put, select, takeEvery } from 'redux-saga/effects';

import {
  fetchInvestmentsData as fetchInvestmentsDataAction,
  fetchInvestmentsDataError,
  fetchInvestmentsDataSuccess,
  fetchSecondaryInvestmentDataError,
  fetchSecondaryInvestmentDataSuccess,
  fetchSecondaryInvestmentsDataError,
  fetchSecondaryInvestmentsDataSuccess,
  getSellInvestmentsInfoError,
  getSellInvestmentsInfoSuccess,
  resetSellInvestmentsForm,
  sellInvestmentsError,
  updateSecondaryInvestmentData,
} from 'app-state/actions/investments';
import * as ordersActions from 'app-state/actions/orders';
import { hidePreview, showModal } from 'app-state/actions/shared';
import * as walletsActions from 'app-state/actions/wallets';
import * as constants from 'app-state/constants';
import { hideLoader, showLoader } from 'app-state/loader/actions';
import * as secondaryInvestmentSelector from 'app-state/selectors/investments';

import SellInvestmentsSuccessModal from 'modules/investments/components/sell-investments-success-modal';
import API from 'constants/api';
import Routes from 'constants/routes';
import SuccessModal from 'shared-parts/components/modal/success-modal';
import ModalFailed from 'shared-parts/components/modal-failed';
import { defaultErrorMessage } from 'shared-parts/constants/error-messages';
import STATUSES from 'shared-parts/constants/transaction-statuses';
import request from 'shared-parts/helpers/request';

function* fetchInvestmentsData() {
  try {
    const { data } = yield call(request, API.Investment.investments());

    yield put(fetchInvestmentsDataSuccess(data));
  } catch (e) {
    yield put(fetchInvestmentsDataError(e));
  }
}

function* fetchInvestmentsDataWatcher() {
  yield takeEvery(constants.FETCH_INVESTMENTS_DATA, fetchInvestmentsData);
}

function* fetchSecondaryInvestmentsData({ params }) {
  try {
    const { data } = yield call(request, API.Investment.secondary(params));

    yield put(fetchSecondaryInvestmentsDataSuccess(data));
  } catch (e) {
    yield put(fetchSecondaryInvestmentsDataError(e));
  }
}

function* fetchSecondaryInvestmentsDataWatcher() {
  yield takeEvery(constants.FETCH_SECONDARY_INVESTMENTS_DATA, fetchSecondaryInvestmentsData);
}

function* fetchSecondaryInvestmentData({ uuid }) {
  yield put(showLoader());

  try {
    const { data } = yield call(request, API.Investment.secondaryInvestment(uuid));

    yield put(fetchSecondaryInvestmentDataSuccess(data));
  } catch (e) {
    yield put(fetchSecondaryInvestmentDataError(e));

    if (e.status === 403) {
      yield put(push(Routes.Transactions.SecondaryTransactions()));
    }
  }

  yield put(hideLoader());
}

function* fetchSecondaryInvestmentDataWatcher() {
  yield takeEvery(constants.FETCH_SECONDARY_INVESTMENT_DATA, fetchSecondaryInvestmentData);
}

function* sellInvestments({ params }) {
  try {
    const { data } = yield call(request, API.Investment.sellInvestments(), 'POST', params);
    yield put(
      showModal({
        component: SellInvestmentsSuccessModal,
        closable: true,
        showHeader: true,
        purpleCloseButton: true,
        transactionUuid: data.uuid,
      }),
    );
    yield put(fetchInvestmentsDataAction());
  } catch (e) {
    if (e.status === 400 && e.response.details) {
      yield put(hidePreview());
      yield put(sellInvestmentsError(e.response.details));
    } else {
      yield put(
        showModal({
          component: ModalFailed,
          closable: true,
          showHeader: false,
          title: defaultErrorMessage,
        }),
      );
    }
  }
}

function* sellInvestmentsWatcher() {
  yield takeEvery(constants.SELL_INVESTMENTS, sellInvestments);
}

function* getSellInvestmentsInfo({ params }) {
  try {
    if (params.quantity && params.pricePerUnit) {
      const { data } = yield call(request, API.Investment.getSellInvestmentsInfo(), 'POST', params);
      yield put(getSellInvestmentsInfoSuccess(data));
    } else {
      yield put(resetSellInvestmentsForm());
    }
  } catch (e) {
    if (e.status === 400 && e.response.details) {
      yield put(hidePreview());
      yield put(getSellInvestmentsInfoError(e.response.details));
    } else {
      yield put(
        showModal({
          component: ModalFailed,
          title: defaultErrorMessage,
          closable: true,
          showHeader: false,
        }),
      );
    }
  }
}

function* getSellInvestmentsInfoWatcher() {
  yield debounce(1000, constants.SELL_INVESTMENTS_INFO, getSellInvestmentsInfo);
}

function* cancelSecondaryInvestment({ uuid }) {
  yield put(showLoader());
  try {
    yield call(request, API.Investment.cancelSecondaryInvestment(uuid), 'POST');

    yield put(
      showModal({
        closable: true,
        component: SuccessModal,
        title: 'Transaction Cancelled',
        buttonText: 'CONTINUE',
        hideGeneralNotion: true,
      }),
    );
    const data = yield select(secondaryInvestmentSelector.getSecondaryInvestment);
    yield put(updateSecondaryInvestmentData({ ...data, state: 'canceled' }));
  } catch (e) {
    yield put(
      showModal({
        component: ModalFailed,
        title: defaultErrorMessage,
        closable: true,
        showHeader: false,
      }),
    );
  }
  yield put(hideLoader());
}

function* cancelSecondaryInvestmentWatcher() {
  yield takeEvery(constants.CANCEL_SECONDARY_INVESTMENT, cancelSecondaryInvestment);
}

function* confirmSecondaryInvestment({ uuid }) {
  yield put(showLoader());
  try {
    yield call(request, API.Investment.confirmSecondaryInvestment(uuid), 'POST');

    const { data } = yield select(secondaryInvestmentSelector.getSecondaryInvestment);
    yield put(updateSecondaryInvestmentData({ ...data, state: STATUSES.fundsTransfer.status }));

    yield put(ordersActions.getBankAccount(data.equityCurrency));
    yield put(walletsActions.createWalletDeposit(data.equityCurrency));
  } catch (e) {
    yield put(
      showModal({
        component: ModalFailed,
        title: defaultErrorMessage,
        closable: true,
        showHeader: false,
      }),
    );
  }
  yield put(hideLoader());
}

function* confirmSecondaryInvestmentWatcher() {
  yield takeEvery(constants.CONFIRM_SECONDARY_INVESTMENT, confirmSecondaryInvestment);
}

function* rejectSecondaryInvestment({ uuid, rejectionReason }) {
  yield put(showLoader());
  try {
    const { data } = yield call(request, API.Investment.rejectSecondaryInvestment(uuid), 'POST', {
      rejectionReason,
    });

    yield put(updateSecondaryInvestmentData(data));
    yield put(
      showModal({
        closable: true,
        component: SuccessModal,
        title: 'Transaction Rejected',
        buttonText: 'CONTINUE',
        hideGeneralNotion: true,
      }),
    );
  } catch (e) {
    yield put(
      showModal({
        component: ModalFailed,
        title: defaultErrorMessage,
        closable: true,
        showHeader: false,
      }),
    );
  }
  yield put(hideLoader());
}

function* rejectSecondaryInvestmentWatcher() {
  yield takeEvery(constants.REJECT_SECONDARY_INVESTMENT, rejectSecondaryInvestment);
}

export {
  fetchInvestmentsData,
  fetchInvestmentsDataWatcher,
  fetchSecondaryInvestmentsData,
  fetchSecondaryInvestmentsDataWatcher,
  fetchSecondaryInvestmentData,
  fetchSecondaryInvestmentDataWatcher,
  sellInvestments,
  sellInvestmentsWatcher,
  getSellInvestmentsInfo,
  getSellInvestmentsInfoWatcher,
  cancelSecondaryInvestment,
  cancelSecondaryInvestmentWatcher,
  confirmSecondaryInvestment,
  confirmSecondaryInvestmentWatcher,
  rejectSecondaryInvestment,
  rejectSecondaryInvestmentWatcher,
};
