import { Action } from 'redux';

import {
  GET_ISSUERS,
  GET_ISSUERS_ERROR,
  GET_ISSUERS_SUCCESS,
  GET_SHAREHOLDING_ISSUERS,
  GET_SHAREHOLDING_ISSUERS_ERROR,
  GET_SHAREHOLDING_ISSUERS_SUCCESS,
} from 'app-state/constants/issuers';

import { ActionWithPayload } from './types';

export const fetchIssuers = (): Action => ({ type: GET_ISSUERS });

export const fetchIssuersSuccess: ActionWithPayload = payload => ({
  type: GET_ISSUERS_SUCCESS,
  payload,
});

export const fetchIssuersError: ActionWithPayload = payload => ({
  type: GET_ISSUERS_ERROR,
  payload,
});

export const fetchShareholdingIssuers = (): Action => ({ type: GET_SHAREHOLDING_ISSUERS });

export const fetchShareholdingIssuersSuccess: ActionWithPayload = payload => ({
  type: GET_SHAREHOLDING_ISSUERS_SUCCESS,
  payload,
});

export const fetchShareholdingIssuersError: ActionWithPayload = payload => ({
  type: GET_SHAREHOLDING_ISSUERS_ERROR,
  payload,
});
