import { useEffect } from 'react';

import { getURLParameters } from 'shared-parts/helpers';
import gtmTrack from 'shared-parts/helpers/gtm-track';
import memorizePath from 'shared-parts/helpers/memorize-path';

window.handleRequestErrors = (params, user) => {
  gtmTrack(params, 'GCApiFailure', user);

  if (params.status === 401) {
    memorizePath();
  }
};

const Root = () => {
  useEffect(() => {
    const [referrer, actionToken] = getURLParameters(['referral', 'actionToken']);

    /*
      If we remove if conditions the items will be lost on page refresh
      if the referrer and token are not present in the URL anymore
    */

    if (referrer && referrer !== 'null') {
      localStorage.setItem('referrer', referrer);
    }

    if (actionToken && actionToken !== 'null') {
      localStorage.setItem('actionToken', actionToken);
    }
  }, []);

  return null;
};

export default Root;
