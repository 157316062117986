import * as constants from 'app-state/constants';

const getCompanyState = (params = {}) => ({
  loading: true,
  data: null,
  errors: null,
  ...params,
});

export const companyReducer = (state = getCompanyState(), { type, data, error }) => {
  switch (type) {
    case constants.FETCH_COMPANY:
      return getCompanyState();
    case constants.FETCH_COMPANY_SUCCESS:
      return getCompanyState({ loading: false, data });
    case constants.FETCH_COMPANY_ERROR:
      return getCompanyState({ loading: false, errors: error });
    case constants.RESET_COMPANY:
      return getCompanyState();
    default:
      return state;
  }
};

const companiesInitialState = {
  loading: true,
  data: [],
  error: null,
};

export const companiesReducer = (state = companiesInitialState, { type, data, error }) => {
  switch (type) {
    case constants.FETCH_COMPANIES:
      return companiesInitialState;
    case constants.FETCH_COMPANIES_SUCCESS:
      return { loading: false, data, error: null };
    case constants.FETCH_COMPANIES_ERROR:
      return { loading: false, data: [], error };
    case constants.RESET_COMPANIES:
      return companiesInitialState;
    default:
      return state;
  }
};

const companyGroupsInitialState = {
  loading: true,
  data: [],
  error: null,
};

export const companyGroupsReducer = (state = companyGroupsInitialState, { type, data, error }) => {
  switch (type) {
    case constants.FETCH_COMPANY_GROUPS:
      return companiesInitialState;
    case constants.FETCH_COMPANY_GROUPS_SUCCESS:
      return { loading: false, data, error: null };
    case constants.FETCH_COMPANY_GROUPS_ERROR:
      return { loading: false, data: [], error };
    case constants.RESET_COMPANY_GROUPS:
      return companiesInitialState;
    default:
      return state;
  }
};

export const companySummaryState = {
  loading: false,
  data: {},
  error: null,
};

export const companySummaryReducer = (state = companySummaryState, { type, data, error }) => {
  switch (type) {
    case constants.GET_COMPANY_SUMMARY:
      return { ...companySummaryState, loading: true };
    case constants.GET_COMPANY_SUMMARY_SUCCESS:
      return { loading: false, data, error: null };
    case constants.GET_COMPANY_SUMMARY_ERROR:
      return { loading: false, data: null, error };

    default:
      return state;
  }
};
