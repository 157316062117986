import styled from 'styled-components';

import { AddIcon } from 'svg-icons';
import * as Colors from 'shared-parts/constants/colors';
import * as Fonts from 'shared-parts/constants/fonts';

const disabledButtonStyles = `
  background: ${Colors.neutral800};
  color: ${Colors.white};
  border: none;

  &:hover, &:focus {
    background: ${Colors.neutral800};
  }
`;
const darkButtonStyles = `
  background: ${Colors.neutral300};
  color: ${Colors.primary};
  border: none;

  &:hover, &:focus {
    box-shadow: 0 3px 6px 0 rgba(0, 0, 0, 0.2);
  }
`;
const lightButtonStyles = `
  background: ${Colors.white};
  color: ${Colors.primary};
  border: 1px dashed ${Colors.neutral500};

  &:hover, &:focus {
    box-shadow: 0 3px 6px 0 rgba(0, 0, 0, 0.2);
  }
`;
const purpleButtonStyles = `
  background: ${Colors.white};
  color: ${Colors.alternative};
  border: 1px solid ${Colors.alternative};
  margin: 0 5px;

  &:hover, &:focus {
    background: ${Colors.alternative};
    color: ${Colors.white};
    box-shadow: 0 3px 6px 0 rgba(0, 0, 0, 0.2);
  }
`;
const getButtonStyles = (disabled, color) => {
  if (disabled) return disabledButtonStyles;
  if (color === 'dark') return darkButtonStyles;
  if (color === 'light') return lightButtonStyles;
  if (color === 'purple') return purpleButtonStyles;
};

const ButtonWrapper = styled.button`
  cursor: ${({ disabled }) => (disabled ? 'auto' : 'pointer')};
  font-family: ${Fonts.mainFont};
  border-radius: 4px;
  font-size: 14px;
  font-weight: bold;
  line-height: 14px;
  outline: 0;
  padding: 15px 41px;
  height: 50px;
  min-width: 200px;
  width: fit-content;
  display: flex;
  align-items: center;
  margin: 0 auto;
  ${({ disabled, color }) => getButtonStyles(disabled, color)}
`;

const AddIconContainer = styled.div`
  margin-right: 9px;
`;

const LargeButton = ({
  className,
  onClick,
  disabled,
  title,
  color = 'dark',
  showAddIcon = true,
}) => (
  <ButtonWrapper className={className} onClick={onClick} disabled={disabled} color={color}>
    {showAddIcon && (
      <AddIconContainer>
        <AddIcon />
      </AddIconContainer>
    )}
    <div>{title}</div>
  </ButtonWrapper>
);

export default LargeButton;
