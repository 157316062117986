import React, { FC } from 'react';

import styled from 'styled-components';

import { getTenantConfig } from 'helpers/get-tenant-config';
import { FormikButton as Button } from 'shared-parts/components/button';
import { Container as InputContainer } from 'shared-parts/components/text-input/underlined-text-input.styled.js';
import { alternative, neutral300, neutral800, primary } from 'shared-parts/constants/white/colors';
import { mainFont } from 'shared-parts/constants/white/fonts';

const {
  custom: { authScreen },
} = getTenantConfig();

const Container = styled.div`
  font-family: ${mainFont};
  height: ${authScreen.height ?? '655px'};
  text-align: ${authScreen.textAlign ?? 'center'};
  width: ${authScreen.width ?? '492px'};

  border: 1px solid ${neutral300};
  box-shadow: 5px 5px 10px #9a9a9a26;
  border-radius: 6px;
  padding: 48px 44px;

  display: flex;
  flex-direction: column;
  justify-content: space-between;

  > :first-child::after {
    content: '';
    display: block;
    height: 1px;
    background-color: ${neutral300};
    width: 100%;
  }

  ${InputContainer} {
    background-position: 380px;
  }
`;

const ActionButton = styled(Button)`
  height: 40px;
  width: 120px;
  user-select: none;
  color: ${({ disabled }) => (disabled ? '#999' : primary)};
  background-color: ${({ disabled }) => (disabled ? neutral300 : alternative)};
`;

const palette: Record<string, any> = {
  accentColorUntouched: neutral300,
  textColor: neutral800,
  placeholderColor: neutral800,
  showPasswordColor: '#999999',
  showPasswordFontWeight: 300,
  showPasswordIconPosition: '381px',
  LoginButtonComponent: ActionButton,
  SignUpButtonComponent: ActionButton,
  ResetPasswordButtonComponent: styled(ActionButton)`
    margin-top: 32px;
    width: auto;
  `,
  SubmitButtonComponent: styled(ActionButton)`
    margin-top: 32px;
  `,
};

const AuthFormContainer: FC<React.PropsWithChildren<unknown>> = ({ children }) => (
  <Container>
    {React.Children.map(children, child => {
      if (React.isValidElement(child)) {
        return React.cloneElement(child, palette);
      }

      return child;
    })}
  </Container>
);

export default AuthFormContainer;
