import { call, put, select, takeEvery } from 'redux-saga/effects';

import * as actions from 'app-state/actions';
import * as constants from 'app-state/constants';
import { getUser } from 'app-state/selectors';

import { getKYBKYCVerificationBody } from 'modules/account-details/components/kyb-details';
import API from 'constants/api';
import Routes from 'constants/routes';
import SuccessModal from 'shared-parts/components/modal/success-modal';
import ErrorModal from 'shared-parts/components/modal-failed';
import { request } from 'shared-parts/helpers';

import { getCurrentUser } from './current-user';
import ignoreUpperCaseKeys from './onboarding.helpers';

function* selectInvestorType({ formData }) {
  yield put(actions.showLoader());

  try {
    const { data } = yield call(request, API.Onboarding.selectInvestorType(), 'POST', formData);
    const possibleRoutes = {
      individual: Routes.Onboarding.Individual.AccountInformationFirstPart(),
      institutional: Routes.Onboarding.Institutional.AccountInformation(),
    };

    yield put(actions.saveUserData(data, possibleRoutes[formData.investorType]));
  } catch (e) {
    console.log(e);
  }

  yield put(actions.hideLoader());
}

function* selectInvestorTypeWatcher() {
  yield takeEvery(constants.SELECT_INVESTOR_TYPE, selectInvestorType);
}

function* setupSumSub() {
  try {
    const { data } = yield call(
      request,
      API.Onboarding.verifyApplicant(),
      'POST',
      {},
      {
        camelCaseConversionHandler: ignoreUpperCaseKeys,
      },
    );

    yield put(actions.setupSumSubConfigSuccess(data));
  } catch (e) {
    yield put(actions.setupSumSubConfigError(e));
  }
}

function* setupSumSubWatcher() {
  yield takeEvery(constants.SETUP_SUMSUB_CONFIG, setupSumSub);
}

function* customVerify({ alternativeText }) {
  yield put(actions.showLoader());
  const user = yield select(getUser);

  try {
    yield call(request, API.Onboarding.verifyApplicant(), 'POST', {});

    yield put(
      actions.showModal({
        closable: true,
        component: SuccessModal,
        title: 'Verification check submitted',
        additionalText: getKYBKYCVerificationBody(alternativeText, user),
        modalWidth: 600,
        iconSize: 40,
      }),
    );
    yield getCurrentUser();
  } catch (e) {
    yield put(
      actions.showModal({
        closable: true,
        showHeader: false,
        component: ErrorModal,
      }),
    );
  }

  yield put(actions.hideLoader());
}

function* customVerifyWatcher() {
  yield takeEvery(constants.CUSTOM_VERIFY, customVerify);
}

function* saveApplicantId({ uuid, applicantId }) {
  try {
    yield call(request, API.Onboarding.saveApplicationId(uuid), 'PUT', {
      externalApplicantId: applicantId,
    });
  } catch (e) {
    yield put(actions.saveApplicantIdError(e));
  }
}

function* saveApplicantIdWatcher() {
  yield takeEvery(constants.SAVE_APPLICANT_ID, saveApplicantId);
}

function* completeKyc({ uuid }) {
  try {
    yield call(request, API.Onboarding.completeKyc(uuid), 'POST');
  } catch (e) {
    yield put(actions.completeKycError(e));
  }
}

function* completeKycWatcher() {
  yield takeEvery(constants.COMPLETE_KYC, completeKyc);
}

function* acceptTermsAndConditions({ data: newUserState }) {
  yield put(actions.showLoader());

  try {
    const existingUserState = yield select(getUser);
    yield put(
      actions.saveUserData({
        ...existingUserState,
        ...newUserState,
      }),
    );
  } catch (e) {
    yield put(actions.acceptTermsAndConditionsError(e));
  }

  yield put(actions.hideLoader());
}

function* acceptTermsAndConditionsWatcher() {
  yield takeEvery(constants.ACCEPT_TERMS_AND_CONDITIONS, acceptTermsAndConditions);
}

export {
  selectInvestorType,
  selectInvestorTypeWatcher,
  setupSumSub,
  setupSumSubWatcher,
  saveApplicantId,
  saveApplicantIdWatcher,
  completeKyc,
  completeKycWatcher,
  customVerifyWatcher,
  acceptTermsAndConditions,
  acceptTermsAndConditionsWatcher,
};
