import styled from 'styled-components';

import FormikButton from 'shared-parts/components/button/formik-button';
import { Colors, Fonts } from 'shared-parts/constants';

const ModalWrapper = styled.div`
  display: flex;
  align-items: center;
  flex-direction: column;
  width: 580px;
  height: 326px;
`;

const ModalIconContainer = styled.div`
  margin-top: 42px;
`;

const ModalTitle = styled.h3`
  margin: 23px 0 0 0;
  font-size: 35px;
  font-weight: 200;
  font-family: ${Fonts.oswaldFont};
  letter-spacing: 0.35px;
  line-height: 45px;
  color: ${Colors.primary};
  text-align: center;
`;

const ModalText = styled.p`
  margin: 13px 0 0 0;
  font-size: 16px;
  font-family: ${Fonts.mainFont};
  line-height: 24px;
  color: ${Colors.neutral800};
`;

const SubmitButton = styled(FormikButton)`
  margin-top: 29px;
  height: 40px;
`;

export { ModalWrapper, ModalIconContainer, ModalTitle, ModalText, SubmitButton };
