import { push } from 'connected-react-router';

import { call, put, select, takeEvery } from 'redux-saga/effects';

import { isClosedEndFundDeal, isNomineeFundDeal } from '@frontend/opportunities';

import * as actions from 'app-state/actions';
import { hideModal, showModal } from 'app-state/actions';
import * as constants from 'app-state/constants';
import * as selectors from 'app-state/selectors';
import store from 'app-state/store';

import API from 'constants/api';
import Routes from 'constants/routes';
import { ModalFailed } from 'shared-parts/components';
import { StandardButton } from 'shared-parts/components/button';
import SuccessModal from 'shared-parts/components/modal/success-modal';
import gtmTrack from 'shared-parts/helpers/gtm-track';
import request from 'shared-parts/helpers/request';

function* fetchOrders({ ordersType }) {
  try {
    const { data } = yield call(request, API.Investment.orders({ ordersType }));

    yield put(actions.fetchOrdersSuccess(data));
    return data;
  } catch (e) {
    store.dispatch(
      showModal({
        component: ModalFailed,
      }),
    );
    yield put(actions.fetchOrdersError(e));
  }
}

function* fetchOrdersWatcher() {
  yield takeEvery(constants.FETCH_ORDERS, fetchOrders);
}

function* fetchOrdersPagesData({ ordersType }) {
  yield put(actions.showLoader());

  try {
    yield call(fetchOrders, actions.fetchOrders(ordersType));
  } catch (e) {
    yield put(actions.fetchOrdersPagesDataError(e));
  }

  yield put(actions.hideLoader());
}

function* fetchOrdersPagesDataWatcher() {
  yield takeEvery(constants.FETCH_ORDERS_PAGE_DATA, fetchOrdersPagesData);
}

function* addOrder({
  formData,
  setSubmitting,
  setErrors,
  setFieldValue,
  autoApproveOrders,
  allowedCurrencies,
  deal,
  redirectToForbidden,
  regulatoryEnvironment,
}) {
  try {
    // eslint-disable-next-line no-inner-declarations
    function* sendOrder() {
      if (isClosedEndFundDeal(deal)) {
        return yield call(request, API.Investment.addFundOrder(), 'POST', {
          issuanceId: formData.issuanceId,
          currency: formData.currency,
          intendedAmount: formData.intendedAmount,
          paymentMethod: 'drawdown',
        });
      }

      return yield call(request, API.Investment.addOrder(), 'POST', formData);
    }

    const { data: placedOrder } = yield sendOrder();

    const isFINRA = regulatoryEnvironment?.value === 'FINRA';

    if (!formData.ioiId) {
      const {
        data: { permalink },
      } = yield select(selectors.getDeal);

      yield put(actions.fetchDeal({ permalink, redirectToForbidden }));
    }

    const getDialogContent = () => {
      if (isClosedEndFundDeal(deal)) {
        return {
          title: 'Your subscription has been submitted',
          additionalText:
            'You will receive your subscription agreement via email from DocuSign. Please complete and sign it before returning it.',
          buttonText: 'View subscription',
        };
      }

      return autoApproveOrders
        ? {
            title: isFINRA ? 'Investment request confirmed' : 'Order confirmed',
            additionalText: `Your ${isFINRA ? 'investment request' : 'order'} has been confirmed. You will receive updates by email, including a notification when it's time to transfer funds.`,
            buttonText: 'View Transaction',
          }
        : {
            title: isFINRA ? 'Investment request received' : 'Order received',
            additionalText: `We've received your ${isFINRA ? 'investment request' : 'order'} and will update you by email as soon as it's been reviewed. You will receive another notification when it's time to transfer funds.`,
            buttonText: 'View Transaction',
          };
    };

    const { title, additionalText, buttonText } = getDialogContent();

    const handleViewTransactionClick = () => {
      const transactionsTab =
        isClosedEndFundDeal(deal) || isNomineeFundDeal(deal) ? 'alternative' : '';

      store.dispatch(push(Routes.Transactions.Order(placedOrder.id, transactionsTab)));
      store.dispatch(hideModal());
    };
    const customActionsBlock = () => (
      <StandardButton onClick={handleViewTransactionClick}>{buttonText}</StandardButton>
    );

    yield put(
      actions.showModal({
        closable: true,
        component: SuccessModal,
        title,
        additionalText,
        customActionsBlock,
        modalWidth: 600,
        iconSize: 40,
      }),
    );

    if (placedOrder?.id) {
      const { data } = yield call(request, API.Investment.order(placedOrder.id));

      yield setFieldValue('intendedAmount', data.intendedAmount);
      yield put(actions.addOrderSuccess(data));
      const gtmEventData = {
        order: {
          intendedAmount: data.intendedAmount,
          currency: data.currency,
        },
      };
      yield gtmTrack(gtmEventData, 'GCOrderPlaced');
      yield put(actions.resetOrderData());
    }
    yield put(actions.fetchOrders());
  } catch ({ response = {}, status }) {
    if (status === 401) {
      yield put(actions.hideModal());
    }
    yield put(actions.addOrderError(response));
    yield setSubmitting(false);

    const { details: { intendedNumberOfTokens, conversionRate, ...errors } = {} } = response;
    // show the error below intended amount input
    const formErrors = {
      ...errors,
      // eslint-disable-next-line no-nested-ternary
      currency: !allowedCurrencies
        ? formData.currency
        : !allowedCurrencies.includes(formData.currency)
          ? ['Currency must be selected']
          : null,
      intendedAmount: intendedNumberOfTokens || null,
      conversionRate: conversionRate || null,
    };

    yield setErrors(formErrors);
  }
}

function* addOrderWatcher() {
  yield takeEvery(constants.ADD_ORDER_REQUEST, addOrder);
}

function* cancelOrder({ id }) {
  try {
    const { data } = yield call(request, API.Investment.order(id), 'PATCH');
    yield put(actions.updateOrder(data));
  } catch (e) {
    yield put(actions.fetchOrdersError(e));
  }
}

function* cancelOrderWatcher() {
  yield takeEvery(constants.CANCEL_ORDER, cancelOrder);
}

export {
  fetchOrders,
  fetchOrdersWatcher,
  fetchOrdersPagesData,
  fetchOrdersPagesDataWatcher,
  addOrder,
  addOrderWatcher,
  cancelOrder,
  cancelOrderWatcher,
};
