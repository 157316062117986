import * as constants from 'app-state/constants';

export const selectInvestorType = formData => ({ type: constants.SELECT_INVESTOR_TYPE, formData });

export const setupSumSubConfig = () => ({ type: constants.SETUP_SUMSUB_CONFIG });
export const setupSumSubConfigSuccess = data => ({
  type: constants.SETUP_SUMSUB_CONFIG_SUCCESS,
  data,
});
export const setupSumSubConfigError = error => ({
  type: constants.SETUP_SUMSUB_CONFIG_ERROR,
  error,
});
export const resetSumSubConfig = () => ({ type: constants.RESET_SUMSUB_CONFIG });

export const saveApplicantId = (uuid, applicantId) => ({
  type: constants.SAVE_APPLICANT_ID,
  uuid,
  applicantId,
});
export const saveApplicantIdError = error => ({ type: constants.SAVE_APPLICANT_ID_ERROR, error });

export const completeKyc = uuid => ({ type: constants.COMPLETE_KYC, uuid });
export const completeKycError = error => ({ type: constants.COMPLETE_KYC_ERROR, error });

export const customVerify = () => ({ type: constants.CUSTOM_VERIFY });

export const acceptTermsAndConditions = data => ({
  type: constants.ACCEPT_TERMS_AND_CONDITIONS,
  data,
});
export const acceptTermsAndConditionsError = error => ({
  type: constants.COMPLETE_KYC_ERROR,
  error,
});
