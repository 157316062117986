import { decamelize } from 'humps';

import { StandardButton } from 'shared-parts/components/button';
import ExclamationMark from 'shared-parts/components/svg-icons/circle-exclamation-mark';
import * as Colors from 'shared-parts/constants/colors';

import { ButtonsPart, Message, ModalTitle, ModalWrapper, StyledTextButton } from '../shared.styled';

const ConfirmModal = ({
  title,
  hideModal,
  confirmAction,
  isConfirmDanger,
  additionalText1 = '',
  additionalText2 = '',
  cancelButtonText = 'Cancel',
  confirmButtonText = 'Confirm',
  modalWidth,
  icon = ExclamationMark(64, 64, Colors.error),
  children,
}) => {
  const e2eAttr = title ? decamelize(title, { separator: '-' }) : 'unknown-confirmation-modal';
  return (
    <ModalWrapper data-e2e={e2eAttr} modalWidth={modalWidth}>
      <div>{icon}</div>
      <ModalTitle data-e2e={`${e2eAttr}-title`}>{title}</ModalTitle>
      {children && <Message>{children}</Message>}
      {additionalText1 && <Message>{additionalText1}</Message>}
      {additionalText2 && <Message>{additionalText2}</Message>}
      <ButtonsPart>
        <StyledTextButton onClick={hideModal}>{cancelButtonText}</StyledTextButton>
        <StandardButton onClick={confirmAction} danger={isConfirmDanger}>
          {confirmButtonText}
        </StandardButton>
      </ButtonsPart>
    </ModalWrapper>
  );
};

export default ConfirmModal;
