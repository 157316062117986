import styled from 'styled-components';

import { getTenantConfig } from 'helpers/get-tenant-config';
import Link from 'shared-parts/components/link';
import { Colors, Screens } from 'shared-parts/constants';

const {
  custom: {
    footer: { navbarAlignItems, footerMainBlockBackgroundColor, footerWrapperHeight },
  },
} = getTenantConfig();

const FooterWrapper = styled.div`
  bottom: 0;
  height: ${footerWrapperHeight ?? '181px'};
  position: absolute;
  width: 100%;
`;

const FooterMainBlock = styled.div`
  background: ${footerMainBlockBackgroundColor ?? Colors.primary};
  color: ${Colors.white};
  font-size: 14px;
  line-height: 14px;

  ${({ withPaddings }) =>
    withPaddings &&
    `
    @media only screen and (min-width: 768px) and (max-width: 992px) {
      padding-bottom: 70px;
     }
    @media only screen and (min-width: 577px) and (max-width: 767px) {
      padding-bottom: 60px;
    }
    @media only screen and (min-width: 479px) and (max-width: 576px) {
      padding-bottom: 54px;
    }
    @media only screen and (min-width: 280px) and (max-width: 478px) {
      padding-bottom: 74px;
    }
  `};
`;

const Navbar = styled.nav`
  padding: 23px 0;
  display: flex;
  align-items: ${navbarAlignItems ?? 'center'};
  justify-content: space-around;
`;

const NavFooter = styled.ul`
  display: flex;
  flex-direction: row;
  padding-left: 0;
  margin-bottom: 0;
  list-style: none;

  @media only screen and (max-width: ${Screens.md}) {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }
`;

const NavItem = styled.li`
  margin-left: 50px;

  &:first-child {
    margin-left: 0;
  }

  @media only screen and (max-width: ${Screens.md}) {
    margin-left: 0;
    min-width: 100vw;
    text-align: center;

    &:last-child {
      margin-bottom: 18px;
    }
  }
`;

const FooterLink = styled(props => <Link {...props} />)`
  color: ${Colors.white};
  text-decoration: none;

  @media only screen and (max-width: ${Screens.md}) {
    margin-top: 6px;
  }
`;

const TenantWebsiteContainer = styled.div`
  text-transform: uppercase;
  // We don't want to increase the size of the footer that is set via padding,
  // yet we still want to increase the line height to allow for the left border to
  // be larger than the text. So we sneak around this with some negative margin
  margin: -2px 0px;
  padding: 4px 8px 2px;
  border-left: 2px solid ${Colors.alternative};
  width: 25%;

  @media only screen and (max-width: 768px) {
    display: none;
  }

  a {
    color: ${Colors.white};
    text-decoration: none;

    &:active,
    &:focus,
    &:hover {
      color: ${Colors.white};
      text-decoration: none;
    }
  }
`;

export {
  FooterWrapper,
  FooterMainBlock,
  Navbar,
  NavFooter,
  NavItem,
  FooterLink,
  TenantWebsiteContainer,
};
