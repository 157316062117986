import { updateObjectInArray as getUpdatedArrayWithObject } from '@frontend/utils';

import * as constants from 'app-state/constants';

export const defaultOrder = { data: null, isLoading: false, errors: null };

export const orderReducer = (state = defaultOrder, action) => {
  switch (action.type) {
    case constants.RESET_ORDER_DATA:
      return { data: null, isLoading: false, errors: null };
    case constants.ADD_ORDER_REQUEST:
      return { data: null, isLoading: true, errors: null };
    case constants.ADD_ORDER_SUCCESS:
      return { data: action.data, isLoading: false, errors: null };
    case constants.ADD_ORDER_ERROR:
      return { data: null, isLoading: false, errors: action.error };
    default:
      return state;
  }
};

export const defaultOrdersState = { data: [], loading: true, error: {} };

export const orders = (state = defaultOrdersState, { type, data, error, updatedOrderData }) => {
  switch (type) {
    case constants.FETCH_ORDERS:
    case constants.RESET_ORDERS:
      return defaultOrdersState;
    case constants.FETCH_ORDERS_SUCCESS:
      return { data, loading: false, error: {} };
    case constants.FETCH_ORDERS_ERROR:
      return { data: [], loading: false, error };
    case constants.UPDATE_ORDER: {
      const updatedData = getUpdatedArrayWithObject(state.data, updatedOrderData, 'id');
      return { data: updatedData, loading: false, error: {} };
    }
    default:
      return state;
  }
};

export const defaultOrdersFilterState =
  JSON.parse(localStorage.getItem('ordersFilterParam')) || 'ALL';

export const ordersFilterReducer = (state = defaultOrdersFilterState, action) => {
  switch (action.type) {
    case constants.CHANGE_ORDERS_FILTER_PARAM: {
      localStorage.setItem('ordersFilterParam', JSON.stringify(action.filterParam));
      return action.filterParam;
    }
    default:
      return state;
  }
};

export const defaultBankAccount = { data: {}, errors: null };

export const bankAccountReducer = (state = defaultBankAccount, action) => {
  switch (action.type) {
    case constants.RESET_ORDER_DATA:
      return { data: {}, errors: null };
    case constants.GET_BANK_ACCOUNT:
      return { data: { ...state.data, [action.currency]: null }, errors: null };
    case constants.GET_BANK_ACCOUNT_SUCCESS:
      return { data: { ...state.data, [action.data.currency]: action.data.data }, errors: null };
    case constants.GET_BANK_ACCOUNT_ERROR:
      return { data: state.data, errors: action.error };
    default:
      return state;
  }
};
