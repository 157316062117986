import styled from 'styled-components';

import { useEnvVar } from '@frontend/config';

import { getTenantConfig } from 'helpers/get-tenant-config';
import { Container } from 'shared-parts/components';
import { neutral300, white } from 'shared-parts/constants/colors';
import { Screens } from 'shared-parts/constants/screens';

const FooterLogoBlock = styled.div`
  background: ${white};
  border-top: 1px solid ${neutral300};
  padding: 35px 0;
  text-align: center;
`;

const {
  custom: {
    footerLogo: { height, margin, marginTop, mediumScreenOverride, padding, width },
  },
} = getTenantConfig();

const FooterLogoImage = styled.img`
  ${height && `height: ${height};`}
  ${margin && `margin: ${margin};`}
  ${marginTop && `margin-top: ${marginTop};`}
  ${padding && `padding: ${padding};`}
  ${width && `width: ${width};`}

  ${mediumScreenOverride &&
  `@media only screen and (max-width: ${Screens.md}) {
    padding-right: 15px;
    padding-left: 15px;
    width: 100%;
  }`}
`;

const FooterLogo = () => {
  const platformName = useEnvVar('PLATFORM_NAME', 'Globacap');

  return (
    <FooterLogoBlock>
      <Container>
        <FooterLogoImage src="/logos/footer.svg" alt={`${platformName} logo`} loading="lazy" />
      </Container>
    </FooterLogoBlock>
  );
};

export default FooterLogo;
