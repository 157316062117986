import { CREATE_ORDER, CREATE_ORDER_ERROR, CREATE_ORDER_SUCCESS } from 'app-state/constants/order';

type OrderState = {
  data?: any;
  errors: {
    status: number;
    response: { details: string }[];
  } | null;
  loading: boolean;
};

type OrderAction = {
  type: string;
  payload: {
    data: any;
    error?: any;
  };
};

export const defaultOrderState: OrderState = {
  data: {},
  errors: null,
  loading: false,
};

export const orderReducer = (state = defaultOrderState, action: OrderAction): OrderState => {
  switch (action.type) {
    case CREATE_ORDER:
      return { ...state, loading: true };
    case CREATE_ORDER_SUCCESS:
      return { ...state, data: action.payload.data, loading: false };
    case CREATE_ORDER_ERROR:
      return { ...state, errors: action.payload.error, loading: false };
    default:
      return state;
  }
};
