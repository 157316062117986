import { useEffect, useState } from 'react';
import { connect } from 'react-redux';

import { checkPathSuitability } from '@frontend/utils';

import * as actions from 'app-state/actions';
import { getRiskBannerVisible, getUser } from 'app-state/selectors';

import Routes from 'constants/routes';
import { FeatureFlag, useFeatureFlag } from 'providers';
import { useLiquidity } from 'providers/liquidity';
import useTypedSelector from 'hooks/use-typed-selector';
import isUkRetailInvestor from 'helpers/is-uk-individual-investor';
import { CloseIcon } from 'svg-icons';
import { Link } from 'shared-parts/components';
import * as Colors from 'shared-parts/constants/colors';

import {
  BannerLabel,
  CloseButton,
  HeaderBannerInner,
  HeaderBannerOuter,
} from './risk-disclaimer-banner.styled';

const RiskDisclaimerBanner = ({ hideRiskBanner, riskBannerVisible }) => {
  const enabled = !useFeatureFlag(FeatureFlag.DISABLE_RISK_DISCLAIMER_BANNER);

  const {
    state: { round },
  } = useLiquidity();

  const [isRoundBannerVisible, setIsRoundBannerVisible] = useState(false);
  const { investor, investorType } = useTypedSelector(getUser);

  const isLiquidityRoundPage = checkPathSuitability(
    { path: Routes.LiquidityRound(), exact: true },
    window.location.pathname,
  );

  const hideBanner = () => {
    setIsRoundBannerVisible(false);
    hideRiskBanner();
  };

  useEffect(() => {
    setIsRoundBannerVisible(
      round?.regulated && isUkRetailInvestor(investor, investorType) && isLiquidityRoundPage,
    );
  }, [isLiquidityRoundPage, round]);

  return (
    <>
      {enabled && (riskBannerVisible || isRoundBannerVisible) && (
        <HeaderBannerOuter>
          <HeaderBannerInner>
            <BannerLabel>
              <span>
                Don&#39;t invest unless you&#39;re prepared to lose all the money you invest. This
                is a high-risk investment and you are unlikely to be protected if something goes
                wrong.
                <Link
                  bold
                  fontSize="14px"
                  url="https://globacap.com/risk-of-loss/"
                  target="_blank"
                  rel="noopener noreferrer"
                  noUnderline
                >
                  Take two minutes to learn more
                </Link>
              </span>
            </BannerLabel>
            <CloseButton>{CloseIcon(14, 14, Colors.primary, hideBanner)}</CloseButton>
          </HeaderBannerInner>
        </HeaderBannerOuter>
      )}
    </>
  );
};

const mapStateToProps = state => ({
  riskBannerVisible: getRiskBannerVisible(state),
});

const mapDispatchToProps = dispatch => ({
  hideRiskBanner: () => dispatch(actions.hideRiskBanner()),
});

export default connect(mapStateToProps, mapDispatchToProps)(RiskDisclaimerBanner);
