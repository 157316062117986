import styled from 'styled-components';

import * as Colors from 'shared-parts/constants/colors';
import * as Fonts from 'shared-parts/constants/fonts';
import { Screens } from 'shared-parts/constants/screens';

const SectionTitle = styled.div`
  display: inline-flex;
  border-bottom: 1px solid ${Colors.neutral300};
  color: ${props => props.titleColor || Colors.primary};
  font-family: ${Fonts.oswaldFont};
  font-size: 25px;
  font-weight: 300;
  justify-content: space-between;
  line-height: 31px;
  padding-bottom: 27px;
  text-transform: uppercase;
  position: relative;
  padding-left: 5px;
  padding-right: 5px;
  width: calc(100% - 10px);

  @media (max-width: ${Screens.sm}) {
    margin: 0 10px;
  }

  ${({ styles }) => styles};
`;

export default SectionTitle;
