import { array, object, string } from 'yup';

import { CAPITAL_FIELDS, FORM_FIELDS } from 'app-state/indication-of-interest/enums';

export function getValidation(disableValuationField: boolean) {
  return object({
    [FORM_FIELDS.ITEMS]: array()
      .of(
        object()
          .shape({
            [CAPITAL_FIELDS.INTENDED_AMOUNT]: string().required('Field is required.'),
            ...(disableValuationField
              ? {}
              : { [CAPITAL_FIELDS.VALUATION]: string().required('Field is required.') }),
          })
          .defined(),
      )
      .defined(),
    // todo - revert when PLAPI-1614 is resolved
    // [FORM_FIELDS.TERM_SHEET]: mixed().required('Field is required.'),
    [FORM_FIELDS.SPECIAL_CONDITIONS]: string().max(350),
  }).defined();
}
