import { getTenantConfig } from 'helpers/get-tenant-config';

import allCountries from './all-countries';
import * as Fonts from './fonts';
import tableColumnStyles from './table-column-styles';
import validation from './validation';

const { colors: Colors } = getTenantConfig();

export * from './screens';
export * from './dates';
export * from './error-messages';
export * from './internal';
export { default as currencies } from './currencies';

export { allCountries, Colors, Fonts, tableColumnStyles, validation };
