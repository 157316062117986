import { FC, KeyboardEventHandler, useEffect, useState } from 'react';

import { styled } from '@mui/material';

import { ToggleButton, ToggleButtonGroup, ToggleButtonGroupProps } from './toggle';

export interface BooleanToggleProps extends ToggleButtonGroupProps {
  trueText?: string;
  falseText?: string;
}

const BooleanToggle: FC<BooleanToggleProps> = ({
  trueText = 'Yes',
  falseText = 'No',
  value: valueProp,
  onChange: onChangeProp,
  disabled,
  ...toggleButtonGroupProps
}) => {
  const isControlled = valueProp !== undefined;
  const [valueState, setValueState] = useState(Boolean(valueProp));

  // Sync internal state with external prop changes
  useEffect(() => {
    if (isControlled) {
      setValueState(Boolean(valueProp));
    }
  }, [valueProp, isControlled]);

  const handleToggle = (e: any) => {
    if (disabled) {
      return;
    }

    const newValue = !valueState;
    if (isControlled && onChangeProp) {
      // For controlled component, call onChange prop to notify parent
      onChangeProp(e, newValue);
    } else {
      // For uncontrolled component, update internal state
      setValueState(newValue);
    }
  };

  const value = isControlled ? Boolean(valueProp) : valueState;
  /**
   *  Handle keydown so whole group acts as a button
   */
  const handleKeyDown: KeyboardEventHandler = event => {
    if ((event.key === ' ' || event.key === 'Enter') && !disabled) {
      event.preventDefault();
      handleToggle(event);
    }
  };

  return (
    <ToggleButtonGroup
      onKeyDown={handleKeyDown}
      role="switch"
      aria-checked={value}
      tabIndex={0}
      onClick={handleToggle}
      value={value}
      onChange={(e, changeValue) => {
        e.stopPropagation();
        if (onChangeProp) onChangeProp(e, changeValue);
      }}
      exclusive
      disabled={disabled}
      {...toggleButtonGroupProps}
    >
      <ToggleButton
        color="success"
        tabIndex={-1}
        value
        aria-label={trueText}
        disabled={disabled}
        selected={value}
      >
        {trueText}
      </ToggleButton>
      <ToggleButton
        color="error"
        tabIndex={-1}
        value={false}
        aria-label={falseText}
        disabled={disabled}
        selected={!value}
      >
        {falseText}
      </ToggleButton>
    </ToggleButtonGroup>
  );
};

/**
 * Boolean Toggle (Switch) component. Default is `[ Yes |  No ]`.
 */
const StyledBooleanToggle = styled(BooleanToggle)(({ theme }) => ({
  '&': {
    border: 'none',
    outline: `1px solid ${theme.palette.neutral[25]}`,
    backgroundColor: theme.palette.neutral[100],
    padding: '4px',
    gap: '1px',
    '&:focus-visible': {
      outline: `1px solid ${theme.palette.info.dark}`,
    },
    cursor: 'pointer',
    '&.Mui-disabled': {
      border: 'none',
    },
  },
  '& .MuiToggleButton-root': {
    border: 'none !important',
    textTransform: 'none !important',
    borderRadius: '6px !important',
    padding: '6px 12px',
    margin: '0 1px !important',
    fontSize: theme.typography.fontSize,
    color: theme.palette.neutral[400],
    '&:hover': {
      backgroundColor: `transparent`,
    },
    '&.Mui-selected': {
      backgroundColor: theme.palette.neutral[25],
      color: theme.palette.neutral[600],
      '&:hover': {
        backgroundColor: theme.palette.neutral[25],
      },
    },
    '&.Mui-disabled': {
      backgroundColor: theme.palette.neutral[100],
      color: theme.palette.neutral[300],
      '&.Mui-selected': {
        color: theme.palette.neutral[400],
      },
    },
  },
}));

export { StyledBooleanToggle as BooleanToggle };
