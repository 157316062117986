import { useDispatch } from 'react-redux';

import { Button } from '@frontend/design-system';

import { logout, oauth2Logout } from 'app-state/actions';

import { FeatureFlag, useFeatureFlag } from 'providers';
import { AuthLogo } from 'shared/logos';
import { Colors } from 'shared-parts/constants';
import { AuthContainer, AuthFormContainer } from 'shared-parts/modules/auth/shared';

import { Title } from './unauthorized.styled';

export function Unauthorized() {
  const dispatch = useDispatch();
  const enableOauth2Login = useFeatureFlag(FeatureFlag.OAUTH2_LOGIN_ENABLE);

  const handleLogout = () => {
    dispatch(enableOauth2Login ? oauth2Logout() : logout());
  };

  return (
    <AuthContainer>
      <AuthFormContainer>
        <AuthLogo />
        <Title titleColor={Colors.primary}>Unauthorized</Title>
        <p>
          You are not authorized to access this page. Please contact your administrator for more
          information.
        </p>
        <Button color="primary" variant="contained" type="button" onClick={handleLogout}>
          Log Out
        </Button>
      </AuthFormContainer>
    </AuthContainer>
  );
}
