// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore: suppress implicit any errors
import { styled } from '@mui/material';
import MuiButton, { ButtonProps as MuiButtonProps } from '@mui/material/Button';

import { StripColors } from '../../utils';

export type ButtonProps = StripColors<MuiButtonProps>;

export const Button = styled(MuiButton)<ButtonProps>(({ theme, color = 'secondary' }) => ({
  textTransform: 'none',
  borderRadius: '6px',
  minWidth: '60px',

  '&.MuiButton-sizeSmall': {
    padding: '8px 16px',
  },
  '&.MuiButton-sizeMedium': {
    padding: '10px 24px',
  },
  '&.MuiButton-sizeLarge': {
    padding: '12px 32px',
    fontSize: '18px',
  },

  // Base sizes (& fonts). Classes of buttons with borders will remove 1px to compensate.
  // Some properties (like font) are variably set by the theme. Others, like fontSize, we want them consistent across themes,
  // so we define them here.
  '&.MuiButton-secondary': {
    color: theme.palette.neutral[700],
    border: '1px solid',
    borderColor: theme.palette.neutral[25],
    backgroundColor: theme.palette.neutral[50],

    // Remove 1px to compensate border
    '&.MuiButton-sizeSmall': {
      padding: '7px 15px',
    },
    '&.MuiButton-sizeMedium': {
      padding: '9px 23px',
    },
    '&.MuiButton-sizeLarge': {
      padding: '11px 25px',
    },

    '&:hover, &:focus, .Mui-focusVisible': {
      color: theme.palette[color].dark,

      // TODO: Remove? Only necessary for our old icons
      // for secondary buttons.. kinda weird
      '& .MuiButton-startIcon': {
        color: theme.palette[color].dark,
      },
    },
  },

  '&.MuiButton-text': {
    color: theme.palette.neutral[600],

    '&:hover, &:focus, .Mui-focusVisible': {
      backgroundColor: 'initial',
      color: theme.palette[color].dark,
    },
  },

  // Contrary to MUI, our design system has the same disabled style for any variant except text variant
  '&.Mui-disabled': {
    color: theme.palette.neutral[300],
    '&:not(.MuiButton-text)': {
      border: '1px solid',
      borderColor: theme.palette.neutral[25],
      backgroundColor: theme.palette.neutral[50],
    },
  },
}));
