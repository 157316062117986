import React, { FC } from 'react';

import styled from 'styled-components';

import { FormikButton as Button } from 'shared-parts/components/button';
import { Container as InputContainer } from 'shared-parts/components/text-input/underlined-text-input.styled.js';
import {
  alternative,
  alternativeContrastText,
  neutral300,
  neutral800,
} from 'shared-parts/constants/white/colors';
import { mainFont } from 'shared-parts/constants/white/fonts';

const Container = styled.div`
  font-family: ${mainFont};
  height: 750px;
  text-align: left;
  width: 600px;

  border: 1px solid ${neutral300};
  box-shadow: 1px 1px 1px #9a9a9a26;
  border-radius: 6px;
  padding: 48px 44px;

  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
  justify-items: baseline;

  position: relative;

  ${InputContainer} {
    background-position: 380px;
    width: 60%;
  }
`;

const ActionButton = styled(Button)`
  height: 40px;
  width: 180px;
  user-select: none;
  color: ${({ disabled }) => (disabled ? '#b3b5ba' : alternativeContrastText)};
  background-color: ${({ disabled }) => (disabled ? neutral300 : alternative)};
`;

const palette: Record<string, any> = {
  accentColorUntouched: neutral300,
  textColor: neutral800,
  placeholderColor: neutral800,
  showPasswordColor: '#999999',
  showPasswordFontWeight: 300,
  showPasswordIconPosition: '381px',
  LoginButtonComponent: styled(ActionButton)`
    margin-left: auto;
    margin-right: 0;
  `,
  SignUpButtonComponent: ActionButton,
  ResetPasswordButtonComponent: styled(ActionButton)`
    margin-top: 32px;
    width: auto;
  `,
  SubmitButtonComponent: styled(ActionButton)`
    margin-top: 32px;
  `,
};

const AuthFormContainer: FC<React.PropsWithChildren<unknown>> = ({ children }) => (
  <Container>
    {React.Children.map(children, child => {
      if (React.isValidElement(child)) {
        return React.cloneElement(child, palette);
      }

      return child;
    })}
  </Container>
);

export default AuthFormContainer;
