import { all, spawn } from 'redux-saga/effects';

import { getCurrenciesWatcher } from 'app-state/currencies/sagas';
import {
  checkJobProgressWatcher,
  sendDownloadRequestWatcher,
} from 'app-state/download-csv/download-csv.saga';
import { getDividendOrdersWatcher } from 'app-state/sagas/sip-orders';
import {
  sendSellOrderRequestWatcher,
  sendTransferOrderRequestWatcher,
} from 'app-state/trade-shares/trade-shares.saga';

import {
  adjustIndicationOfInterestWatcher,
  cancelIndicationOfInterestWatcher,
  getIndicationOfInterestWatcher,
  getInvestorAnchorStatusWatcher,
  placeIndicationOfInterestWatcher,
} from '../indication-of-interest/sagas';
import {
  fetchInvestorCompaniesWatcher,
  updateInvestorCompaniesPermissionWatcher,
} from '../investors/sagas';
import { getSharePlanHoldersWatcher } from '../share-plan-holders/share-plan-holders.saga';

import { sendActivityLogsWatcher } from './activity-logs';
import {
  changePasswordWatcher,
  forgotPasswordWatcher,
  loginWatcher,
  logoutWatcher,
  oauth2LoginWatcher,
  oauth2LogoutWatcher,
  resetPasswordWatcher,
  saveUserDataWatcher,
  signUpWatcher,
  twoFactorAuthConfirmWatcher,
  twoFactorAuthDisableWatcher,
  twoFactorAuthGetCodeWatcher,
} from './authentication';
import {
  addCommunicationResponseWatcher,
  getCommunicationsWatcher,
  getCommunicationWatcher,
} from './communications';
import {
  fetchCompaniesWatcher,
  fetchCompanyGroupsWatcher,
  fetchCompanyWatcher,
  getCompanySummaryWatcher,
} from './companies';
import { confirmEmailWatcher } from './confirm-email';
import { getContributionWatcher, updateContributionWatcher } from './contribution';
import { getCountriesWatcher } from './countries';
import { getCurrencyRateWatcher } from './currency-rate';
import {
  getCurrentUserWatcher,
  resendEmailConfirmationWatcher,
  updateCurrentUserWatcher,
} from './current-user';
import {
  agreeNDADealDocumentWatcher,
  fetchDealDocumentsWatcher,
  fetchDealPagesDataWatcher,
  fetchIssuanceWatcher,
  fetchPageWatcher,
  fetchTheDealWatcher,
  requestDealNDAESignatureWatcher,
  showOrderDetailsFormWatcher,
} from './deal';
import { getDealsWatcher } from './deals';
import {
  getIndividualInvestorTypesWatcher,
  getSupportedCountriesWatcher,
  getUnsupportedCountriesWatcher,
  saveInvestorCountryWatcher,
  saveInvestorTypeWatcher,
  savePersonalDataWatcher,
} from './individual-onboarding';
import {
  fetchInvestmentPreferencesWatcher,
  saveAccountInfoWatcher,
  updateInvestmentPreferencesWatcher,
  updateOnboardingInvestmentPreferencesWatcher,
} from './institutional-onboarding';
import {
  cancelSecondaryInvestmentWatcher,
  confirmSecondaryInvestmentWatcher,
  fetchInvestmentsDataWatcher,
  fetchSecondaryInvestmentDataWatcher,
  fetchSecondaryInvestmentsDataWatcher,
  getSellInvestmentsInfoWatcher,
  rejectSecondaryInvestmentWatcher,
  sellInvestmentsWatcher,
} from './investments';
import { getIssuerWatcher } from './issuer';
import { fetchIssuersWatcher, fetchShareholdingIssuersWatcher } from './issuers';
import { fetchNavigationWatcher, fetchSubNavigationWatcher } from './navigation';
import { updateNickelledUserWatcher } from './nickelled';
import {
  acceptTermsAndConditionsWatcher,
  completeKycWatcher,
  customVerifyWatcher,
  saveApplicantIdWatcher,
  selectInvestorTypeWatcher,
  setupSumSubWatcher,
} from './onboarding';
import { createBonusContributionOrderWatcher, createOrderWatcher } from './order';
import {
  addOrderWatcher,
  cancelOrderWatcher,
  fetchOrdersPagesDataWatcher,
  fetchOrdersWatcher,
} from './orders';
import {
  getShareIncentivePlansWatcher,
  getShareIncentivePlanWatcher,
} from './share-incentive-plan';
import {
  getShareOptionsDisplayStateWatcher,
  getShareOptionsWatcher,
  getShareOptionWatcher,
} from './share-options';
import {
  fetchSuitabilityTestQuestionsWatcher,
  suitabilityTestSubmitWatcher,
} from './suitability-test';
import {
  createWalletDepositWatcher,
  createWalletWithdrawalWatcher,
  downloadShareholderCertWatcher,
  fetchWalletsPagesDataWatcher,
  fetchWalletsWatcher,
  getBankAccountWatcher,
  resetWalletsPagesDataWatcher,
} from './wallets';

function* rootSaga() {
  yield all([
    spawn(sendActivityLogsWatcher),
    spawn(signUpWatcher),
    spawn(loginWatcher),
    spawn(logoutWatcher),
    spawn(fetchDealDocumentsWatcher),
    spawn(agreeNDADealDocumentWatcher),
    spawn(requestDealNDAESignatureWatcher),
    spawn(resetPasswordWatcher),
    spawn(forgotPasswordWatcher),
    spawn(changePasswordWatcher),
    spawn(saveUserDataWatcher),
    spawn(twoFactorAuthConfirmWatcher),
    spawn(twoFactorAuthDisableWatcher),
    spawn(twoFactorAuthGetCodeWatcher),
    spawn(getCommunicationsWatcher),
    spawn(getCountriesWatcher),
    spawn(getCommunicationWatcher),
    spawn(updateInvestmentPreferencesWatcher),
    spawn(addCommunicationResponseWatcher),
    spawn(fetchCompanyWatcher),
    spawn(fetchCompaniesWatcher),
    spawn(fetchCompanyGroupsWatcher),
    spawn(getCompanySummaryWatcher),
    spawn(confirmEmailWatcher),
    spawn(getCurrencyRateWatcher),
    spawn(getCurrentUserWatcher),
    spawn(updateCurrentUserWatcher),
    spawn(resendEmailConfirmationWatcher),
    spawn(fetchTheDealWatcher),
    spawn(fetchPageWatcher),
    spawn(fetchIssuanceWatcher),
    spawn(fetchDealPagesDataWatcher),
    spawn(showOrderDetailsFormWatcher),
    spawn(getDealsWatcher),
    spawn(savePersonalDataWatcher),
    spawn(saveInvestorTypeWatcher),
    spawn(saveInvestorCountryWatcher),
    spawn(getIndividualInvestorTypesWatcher),
    spawn(getSupportedCountriesWatcher),
    spawn(getUnsupportedCountriesWatcher),
    spawn(saveAccountInfoWatcher),
    spawn(updateOnboardingInvestmentPreferencesWatcher),
    spawn(fetchInvestmentPreferencesWatcher),
    spawn(fetchInvestmentsDataWatcher),
    spawn(fetchSecondaryInvestmentsDataWatcher),
    spawn(fetchSecondaryInvestmentDataWatcher),
    spawn(cancelSecondaryInvestmentWatcher),
    spawn(confirmSecondaryInvestmentWatcher),
    spawn(rejectSecondaryInvestmentWatcher),
    spawn(fetchNavigationWatcher),
    spawn(fetchSubNavigationWatcher),
    spawn(selectInvestorTypeWatcher),
    spawn(setupSumSubWatcher),
    spawn(saveApplicantIdWatcher),
    spawn(completeKycWatcher),
    spawn(customVerifyWatcher),
    spawn(acceptTermsAndConditionsWatcher),
    spawn(fetchOrdersWatcher),
    spawn(fetchOrdersPagesDataWatcher),
    spawn(addOrderWatcher),
    spawn(cancelOrderWatcher),
    spawn(fetchSuitabilityTestQuestionsWatcher),
    spawn(suitabilityTestSubmitWatcher),
    spawn(fetchWalletsWatcher),
    spawn(fetchWalletsPagesDataWatcher),
    spawn(getBankAccountWatcher),
    spawn(createWalletDepositWatcher),
    spawn(createWalletWithdrawalWatcher),
    spawn(downloadShareholderCertWatcher),
    spawn(getShareOptionsWatcher),
    spawn(getShareOptionWatcher),
    spawn(getCurrenciesWatcher),
    spawn(getShareOptionsDisplayStateWatcher),
    spawn(sellInvestmentsWatcher),
    spawn(getSellInvestmentsInfoWatcher),
    spawn(resetWalletsPagesDataWatcher),
    spawn(fetchIssuersWatcher),
    spawn(fetchShareholdingIssuersWatcher),
    spawn(updateNickelledUserWatcher),
    spawn(placeIndicationOfInterestWatcher),
    spawn(getInvestorAnchorStatusWatcher),
    spawn(adjustIndicationOfInterestWatcher),
    spawn(cancelIndicationOfInterestWatcher),
    spawn(getIndicationOfInterestWatcher),
    spawn(fetchInvestorCompaniesWatcher),
    spawn(updateInvestorCompaniesPermissionWatcher),
    spawn(getShareIncentivePlansWatcher),
    spawn(getShareIncentivePlanWatcher),
    spawn(getIssuerWatcher),
    spawn(getContributionWatcher),
    spawn(updateContributionWatcher),
    spawn(createOrderWatcher),
    spawn(createBonusContributionOrderWatcher),
    spawn(sendDownloadRequestWatcher),
    spawn(checkJobProgressWatcher),
    spawn(sendSellOrderRequestWatcher),
    spawn(sendTransferOrderRequestWatcher),
    spawn(getSharePlanHoldersWatcher),
    spawn(getDividendOrdersWatcher),
    spawn(oauth2LoginWatcher),
    spawn(oauth2LogoutWatcher),
  ]);
}

export default rootSaga;
