export function memoize(fn: any) {
  const cache: any = {};

  return function (...args: any) {
    const key = JSON.stringify(args);

    if (cache[key]) {
      return cache[key];
    }

    const result = fn(key);

    cache[key] = result;

    return result;
  };
}
