import { saveRedirectPath } from 'app-state/actions';
import store from 'app-state/store';

import Routes from 'constants/routes';

const getPathName = state => state.router.location.pathname;

export default (url = getPathName(store.getState())) => {
  const canSavePath = url !== Routes.Login();

  if (canSavePath) {
    store.dispatch(saveRedirectPath(url));
  }
};
