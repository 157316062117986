import React, { ComponentProps, ReactNode } from 'react';

import * as constants from 'app-state/constants';

type ComponentAndProps<T extends React.ComponentType<React.PropsWithChildren<any>>> = {
  component: T;
} & Partial<ComponentProps<T>>;

type ShowModalActionProps<T extends React.ComponentType<React.PropsWithChildren<any>>> =
  ComponentAndProps<T> & {
    showHeader?: boolean;
    header?: ReactNode;

    purpleCloseButton?: boolean;
    danger?: boolean;
    minHeight?: number;
    showOverflow?: boolean;

    closable?: boolean;
    customHideModal?: () => void;
  };

export const showModal = <T extends React.ComponentType<React.PropsWithChildren<any>>>(
  data: ShowModalActionProps<T>,
) => ({
  type: constants.SHOW_MODAL,
  data,
});

export const hideModal = () => ({ type: constants.HIDE_MODAL });

type ShowModalPreviewActionProps<T extends React.ComponentType<React.PropsWithChildren<any>>> = {
  component: T;
} & Partial<ComponentProps<T>>;

export const showPreview = <T extends React.ComponentType<React.PropsWithChildren<any>>>(
  data: ShowModalPreviewActionProps<T>,
) => ({ type: constants.SHOW_PREVIEW, data });
export const hidePreview = () => ({ type: constants.HIDE_PREVIEW });

export const saveRedirectPath = (route: string) => ({ type: constants.SAVE_REDIRECT_PATH, route });
export const removeRedirectPath = () => ({ type: constants.REMOVE_REDIRECT_PATH });

export const updateNickelledUser = () => ({ type: constants.UPDATE_NICKELLED_USER });
