export const FETCH_SUITABILITY_TEST_QUESTIONS = 'FETCH_SUITABILITY_TEST_QUESTIONS';
export const FETCH_SUITABILITY_TEST_QUESTIONS_SUCCESS = 'FETCH_SUITABILITY_TEST_QUESTIONS_SUCCESS';
export const FETCH_SUITABILITY_TEST_QUESTIONS_ERROR = 'FETCH_SUITABILITY_TEST_QUESTIONS_ERROR';

export const SUITABILITY_TEST_PASSED = 'SUITABILITY_TEST_PASSED';
export const SUITABILITY_TEST_FAILED = 'SUITABILITY_TEST_FAILED';
export const SUITABILITY_TEST_SUBMIT = 'SUITABILITY_TEST_SUBMIT';
export const RESET_SUITABILITY_TEST_RESULT = 'RESET_SUITABILITY_TEST_RESULT';

export const SET_SUITABILITY_TEST_ATTEMPTS_ERROR = 'SET_SUITABILITY_TEST_ATTEMPTS_ERROR';
export const RESET_SUITABILITY_TEST_ATTEMPTS_ERROR = 'RESET_SUITABILITY_TEST_ATTEMPTS_ERROR';
