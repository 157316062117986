import { useEffect, useState } from 'react';

import { ApiResponse } from 'app-state/types';

import { request } from 'helpers';

import { usePlaidAccessToken } from '../hooks/use-plaid-access-token';

type ApiBankAccountDetails = {
  account: string;
  accountId: string;
  bic: string;
  iban: string;
  name: string;
  sortCode: string;
};

type BankAccountDetails = ApiBankAccountDetails & {
  withdraw: (walletId: string, amount: string) => Promise<CreateWithdrawResult>;
};

export enum WithdrawStatus {
  Pending = 'pending',
  Approved = 'approved',
  Rejected = 'rejected',
  Completed = 'completed',
  Failed = 'failed',
}

type CreateWithdrawResult = {
  amount: string;
  currency: string;
  id: number;
  investorUserId: string;
  plaidPaymentId: string;
  rejectionReason: string;
  status: WithdrawStatus;
};

export function useBankAccounts(): BankAccountDetails[] | undefined {
  const [bankAccounts, setBankAccounts] = useState<BankAccountDetails[]>();
  const { token } = usePlaidAccessToken();

  useEffect(() => {
    request('/api/v1/money_box/bank_accounts', 'GET', undefined, {
      headers: { 'Plaid-Access-Token': String(token) },
    }).then((response: ApiResponse<{ items: ApiBankAccountDetails[] }>) => {
      const result = response.data.items;

      const accounts: BankAccountDetails[] = result.map(bankAccount => ({
        ...bankAccount,
        withdraw: (walletId, amount) => {
          if (!token) {
            throw new Error('Somehow got no plaid access token.');
          }

          const payload = {
            currency: 'GBP',
            walletId,
            amount,
            ...bankAccount,
          };

          return request('/api/v1/investors/withdraws', 'POST', payload, {
            headers: { 'Plaid-Access-Token': token },
          }).then((res: ApiResponse<CreateWithdrawResult>) => res.data);
        },
      }));

      setBankAccounts(accounts);
    });
  }, []);

  return bankAccounts;
}
