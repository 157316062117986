import { connect } from 'react-redux';

import { hideModal } from 'app-state/actions';

import {
  CancelActionButton,
  CancelButton,
  ModalText,
  ModalWrapper,
} from './onboarding-cancel-modal.styled';

const OnboardingCancelModal = ({ close, cancelAction }) => (
  <ModalWrapper>
    <ModalText>Are you sure you want to exit?</ModalText>
    <div>
      <CancelButton onClick={close}>CANCEL</CancelButton>
      <CancelActionButton onClick={cancelAction}>YES</CancelActionButton>
    </div>
  </ModalWrapper>
);

const mapDispatchToProps = dispatch => ({
  close: () => dispatch(hideModal()),
});

export default connect(null, mapDispatchToProps)(OnboardingCancelModal);
