import * as constants from 'app-state/constants';

export const defaultWalletsDeposit = { data: {}, errors: null };

export const walletsDepositsReducer = (state = defaultWalletsDeposit, action) => {
  switch (action.type) {
    case constants.CREATE_WALLETS_DEPOSIT:
      return { data: { ...state.data, [action.currency]: {} }, errors: null };
    case constants.CREATE_WALLETS_DEPOSIT_SUCCESS:
      return { data: { ...state.data, [action.data.currency]: action.data }, errors: null };
    case constants.CREATE_WALLETS_DEPOSIT_ERROR:
      return { data: state.data, errors: action.error };
    case constants.CREATE_WALLETS_WITHDRAWAL:
      return { data: { ...state.data, [action.currency]: {} }, errors: null };
    case constants.CREATE_WALLETS_WITHDRAWAL_SUCCESS:
      return { data: { ...state.data, [action.data.currency]: action.data }, errors: null };
    case constants.CREATE_WALLETS_WITHDRAWAL_ERROR:
      return { data: state.data, errors: action.error };
    default:
      return state;
  }
};

export const defaultWalletsState = {
  loading: true,
  data: [],
  error: null,
};

export const walletsReducer = (state = defaultWalletsState, action) => {
  switch (action.type) {
    case constants.FETCH_WALLETS:
      return defaultWalletsState;
    case constants.FETCH_WALLETS_SUCCESS:
      return { loading: false, data: action.wallets, error: null };
    case constants.FETCH_WALLETS_ERROR:
      return { loading: false, data: [], error: action.error };
    case constants.RESET_WALLETS:
      return defaultWalletsState;
    default:
      return state;
  }
};
