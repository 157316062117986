import { createTheme as _createTheme, ThemeOptions } from '@mui/material';
import { deepmerge } from '@mui/utils';

import { defaultThemeOptions } from './theme';

type RecursivePartial<T> = {
  [P in keyof T]?: RecursivePartial<T[P]>;
};

/**
 * Creates a complete MUI theme from defaultThemeOptions and optional theme extensions.
 * The theme extensions are deeply merged into the defaultThemeOptions (left to right).
 * The final merged options are fed into MUI's createTheme, which augments the properties, giving a ready-to-use theme.
 * @param themeExtensions partial versions of ThemeOptions that are deeply merged into defaultThemeOptions. Not passing any themeExtensions simply returns the core (default) theme
 */
const createTheme = (...themeExtensions: RecursivePartial<ThemeOptions>[]) => {
  const mergedThemeOptions = themeExtensions.reduce((prev: ThemeOptions, next) => {
    return deepmerge(prev, next);
  }, defaultThemeOptions);
  return _createTheme(mergedThemeOptions);
};

export default createTheme;
