export const FETCH_INVESTOR_COMPANIES = 'FETCH_INVESTOR_COMPANIES';
export const FETCH_INVESTOR_COMPANIES_SUCCESS = 'FETCH_INVESTOR_COMPANIES_SUCCESS';
export const FETCH_INVESTOR_COMPANIES_ERROR = 'FETCH_INVESTOR_COMPANIES_ERROR';
export const RESET_INVESTOR_COMPANIES = 'RESET_INVESTOR_COMPANIES';

export const UPDATE_INVESTOR_COMPANIES_PERMISSION = 'UPDATE_INVESTOR_COMPANIES_PERMISSION';
export const UPDATE_INVESTOR_COMPANIES_PERMISSION_SUCCESS =
  'UPDATE_INVESTOR_COMPANIES_PERMISSION_SUCCESS';
export const UPDATE_INVESTOR_COMPANIES_PERMISSION_ERROR =
  'UPDATE_INVESTOR_COMPANIES_PERMISSION_ERROR';
