import { FeatureFlag, useFeatureFlag } from 'providers';

import MuinmosVerificationPage from './muinmos-verification-page';
import SumsubVerificationPage from './sumsub-verification-page';

export default function AmlVerificationPage() {
  const useMuinmos = useFeatureFlag(FeatureFlag.USE_MUIMOUS);
  if (useMuinmos) {
    return <MuinmosVerificationPage />;
  }
  return <SumsubVerificationPage />;
}
