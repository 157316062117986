import dayjs from '.';

/** Localised Date Format */
enum LocalisedDateFormat {
  /** `14-02-2024` *(en-GB)* `02/14/2024` *(en-US)* */
  DATE_NUMERIC = 'L',
  /** `14 Feb 2024` *(en-GB)* `Feb 14, 2024` *(en-US)* */
  DATE_TEXTUAL = 'll',
  /** `14 February 2024` *(en-GB)* `February 14, 2024` *(en-US)* */
  DATE_TEXTUAL_FULL = 'LL',
  /** `14 February 2024 20:30` *(en-GB)* `February 14, 2024 8:30 PM` *(en-US)* */
  DATE_TIME_FULL = 'LLL',
  /** `20:30` *(en-GB)* `8:30 PM` *(en-US)* */
  TIME = 'LT',
  /** `20:30:45` *(en-GB)* `8:30:45 PM` *(en-US)* */
  TIME_SECONDS = 'LTS',
}

/**
 * Localise a date using dayjs.
 *
 * Intended for UI.
 * ___
 * #### examples:
 * ```typescript
 * formatDate('14-02-2024'); // 2024-02-14T00:00:00+00:00 (ISO 8601 incl. offset)
 * formatDate('14-02-2024', 'MMM-YYYY'); // 'Aug-2024'
 * // Use dayjs' locale functionality to localise the output.
 * // It parses xx-xx-xxxx as DD-MM-YYYY unless inputFormat is specified.
 * formatDate('14-02-2024', 'L'); // '14-02-2024' (en-GB) or '02/14/2024' (en-US)
 * formatDate('14-02-2024', 'L', 'DD-MM-YYYY'); // same as above
 * formatDate('02-14-2024', 'L', 'MM-DD-YYYY'); // same as above
 * ```
 * The global dayjs object stores the current locale. It is set with `dayjs.locale()`.
 * https://day.js.org/docs/en/i18n/changing-locale
 *
 * ```typescript
 * // always parses ISO dates:
 * formatDate('2024-02-14T00:00:00.000Z', 'L'); // '14-02-2024' (localised)
 *
 * ```
 * ___
 *
 * #### example usage with `LocalisedDateFormat` enum:
 *
 * ```typescript
 * import dayjs, { formatDate, LocalisedDateFormat as LDF } from '@frontend/datetime';
 * formatDate(new Date(), LDF.DATE_TEXTUAL) // '14th Feb 2024' (localised)
 * formatDate(new Date(), `${LDF.DATE_TEXTUAL_FULL} ${LDF.TIME}`) // '14th February 2024 20:30' (localised)
 * ```
 */
const formatDate = (
  /** The date to localise. */
  date: dayjs.ConfigType,
  /** The template format for the output. e.g. `'DD-MM-YYYY'` or `'L'` (localised) */
  outputTemplate?: string | LocalisedDateFormat,
  /** The input format to expect. `dayjs.OptionType`. */
  inputFormat?: dayjs.OptionType,
  /** The fallback value to use if the date is invalid. */
  fallback = '',
): string => {
  if (!date) return fallback;

  // special case: unless inputFormat specified, ingest short dates in en-GB format.
  // this is known behaviour across the site.
  // automagically ingesting all european date formats isn't possible with dayjs.
  if (!inputFormat && typeof date === 'string' && date.match(/^\d{2}[-/]\d{2}[-/]\d{4}$/)) {
    return dayjs(date, ['DD-MM-YYYY', 'DD/MM/YYYY'], undefined, true).format(outputTemplate);
  }

  const dateObj = dayjs(date, inputFormat, undefined, true);

  if (!dateObj.isValid()) return fallback;
  return dateObj.format(outputTemplate);
};

export { formatDate, LocalisedDateFormat, LocalisedDateFormat as LDF };
