import { GridApi, GridFilterItem, GridFilterModel } from '@mui/x-data-grid-premium';

import { GridColDefExtended } from './use-http-datagrid';

export type HttpFilterKeyMap = { key: string; value: string };

export const arrayQueryTransformer = (
  value: unknown,
  queryParamKey: string,
): HttpFilterKeyMap[] | undefined => {
  if (value === undefined) {
    return undefined;
  }

  if (Array.isArray(value)) {
    return value.map(item => ({ key: queryParamKey, value: item }) as HttpFilterKeyMap);
  }

  return [{ key: queryParamKey, value: (value as string)?.toString() }];
};

export const toHttpFilter = (
  model: GridFilterModel | undefined,
  gridApi: GridApi,
  quickFilterMap?: (quickFilterValues: any[]) => any[][],
): URLSearchParams => {
  const urlSearchParams = new URLSearchParams();

  (model ? model.items : ([] as GridFilterItem[])).forEach(item => {
    const column = gridApi?.getColumn?.(item.field) as GridColDefExtended<any>;

    if (column?.queryParamTransformer) {
      const transformedResult = column.queryParamTransformer?.(item.value, column);
      transformedResult?.forEach(resultItem => {
        if (resultItem?.value) {
          urlSearchParams.append(resultItem.key, resultItem.value);
        }
      });

      return;
    }

    if (item.value) {
      urlSearchParams.append(item.field, item.value);
    }
  });

  if (model?.quickFilterValues && quickFilterMap) {
    const quickFilterMappedValues = quickFilterMap(model?.quickFilterValues);
    quickFilterMappedValues?.forEach(([quickFilterKey, quickFilterValue]) => {
      if (quickFilterValue) {
        urlSearchParams.append(quickFilterKey, quickFilterValue);
      }
    });
  }

  return urlSearchParams;
};
