import { HTMLProps } from 'react';

import Hamburger from './hamburger';

export default (props: HTMLProps<HTMLButtonElement>) => (
  <button
    // eslint-disable-next-line react/jsx-props-no-spreading
    {...props}
    aria-label="Toggle navigation"
    aria-haspopup="true"
    aria-controls="mobile-nav-menu"
    className="navbar-toggler"
    type="button"
  >
    <Hamburger className="navbar-toggler-icon" />
  </button>
);
