import { FC } from 'react';

import { alternative } from 'shared-parts/constants/colors';

type Props = {
  width?: number;
  height?: number;
  color?: string;
  className?: string;
};

const EmailIcon: FC<React.PropsWithChildren<Props>> = ({
  width = 40,
  height = 32,
  color = alternative,
  className,
}) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={width}
    height={height}
    viewBox="0 0 20 16"
    className={className}
  >
    <path
      fill={color}
      d="M20,4H4A2,2,0,0,0,2.01,6L2,18a2.006,2.006,0,0,0,2,2H20a2.006,2.006,0,0,0,2-2V6A2.006,2.006,0,0,0,20,4Zm0,4-8,5L4,8V6l8,5,8-5Z"
      transform="translate(-2 -4)"
    />
  </svg>
);

export default EmailIcon;
