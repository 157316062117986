import { camelizeKeys, decamelizeKeys, OptionOrProcessor } from 'humps';

import { getToken, Token } from '../utils/token';

export interface User {
  companyIds: unknown;
  operationalAdmin?: boolean;
  isMulticompanyAdmin?: boolean;
  authToken: string;
}

const removeEmpty = <T>(obj: Record<string, T>): Record<string, NonNullable<T>> =>
  Object.entries(obj)
    .filter(([_, v]) => v != null)
    .reduce((acc, [k, v]) => ({ ...acc, [k]: v }), {});

export interface RequestOptions {
  to: 'json' | 'text' | 'blob';
  mode: RequestInit['mode'];
  abortController?: AbortController;
  signal?: AbortSignal;
  headers?: Record<string, string>;
  timeout: number;
  camelCase: boolean;
  contentType: string;
  credentials: RequestInit['credentials'];
  includeInternalHeaders: boolean;
  ignoredErrorCodes: number[];
  camelCaseConversionHandler?: OptionOrProcessor;
  snakeCaseParams: boolean;
  requestConfigOverrides?: Partial<RequestFactoryConfig>;
}

export type RequestFactoryConfig = {
  token: Token;
  onBlockchainError?: () => void; // No idea what this is!
  onSessionExpired?: () => void;
  onForbid?: () => void;
  onError?: () => void;
};

const defaultOptions: RequestOptions = {
  to: 'json',
  mode: 'same-origin',
  timeout: 30000,
  camelCase: true,
  contentType: 'application/json;charset=UTF-8',
  credentials: 'same-origin',
  includeInternalHeaders: true,
  ignoredErrorCodes: [],
  camelCaseConversionHandler: undefined,
  snakeCaseParams: true,
};

const getCamelizedKeysObject =
  (optionsOrProcessor?: OptionOrProcessor) =>
  <T = unknown>(result: Record<string, T>) => {
    return camelizeKeys(result, optionsOrProcessor);
  };

export type RequestResult<TData> = { data: TData; status: number };

export const createRequestClient = (config: RequestFactoryConfig) => {
  return async <TData = any>(
    url: string,
    method = 'GET',
    body: FormData | null | undefined | any = undefined,
    options: Partial<RequestOptions> = {},
  ): Promise<RequestResult<TData>> => {
    const {
      to,
      mode,
      abortController = new AbortController(),
      signal,
      headers,
      timeout,
      camelCase,
      contentType,
      credentials,
      ignoredErrorCodes,
      includeInternalHeaders,
      camelCaseConversionHandler,
      snakeCaseParams,
      requestConfigOverrides = {},
    }: RequestOptions = { ...defaultOptions, ...options };

    const token = await getToken(config.token);

    // used to COMPLETELY exclude 'Content-Type' header (otherwise FormData requests will not work)
    const formDataAsTheBody = body instanceof FormData;
    const internalData = includeInternalHeaders
      ? removeEmpty({
          'X-Auth-Token': token,
        })
      : undefined;
    const contentTypeParameter = formDataAsTheBody ? undefined : { 'Content-Type': contentType };

    const requestBody =
      body == null || body instanceof FormData
        ? body
        : // decamelizeKeys(body, { split: /(?=[A-Z\d])/ }): regular expression used to convert keys with numbers like address_line_1
          JSON.stringify(snakeCaseParams ? decamelizeKeys(body, { split: /(?=[A-Z\d])/ }) : body);

    return Promise.race([
      fetch(url, {
        method,
        body: requestBody,
        headers: {
          ...contentTypeParameter,
          ...internalData,
          ...headers,
        },
        credentials,
        mode,
        signal: signal || abortController.signal,
      })
        .then(res => {
          if (!res.ok) {
            const isErrorHandlerIgnored = ignoredErrorCodes.includes(res.status);

            if (!isErrorHandlerIgnored) {
              const isSessionExpired = res.status === 401;
              const isAccessForbidden = res.status === 403;
              const isServiceUnavailable = res.status > 499;

              if (isAccessForbidden) {
                if (requestConfigOverrides.onForbid) {
                  requestConfigOverrides.onForbid();
                } else {
                  config.onForbid?.();
                }
              }
              if (isSessionExpired) {
                if (requestConfigOverrides.onSessionExpired) {
                  requestConfigOverrides.onSessionExpired();
                } else {
                  config.onSessionExpired?.();
                }
              }

              if (isServiceUnavailable) {
                if (requestConfigOverrides.onError) {
                  requestConfigOverrides.onError();
                } else {
                  config.onError?.();
                }
              }
            }

            return res
              .json()
              .then(getCamelizedKeysObject(camelCaseConversionHandler))
              .then((error: { errorCode?: number }) => {
                const blockchainError = res.status === 422 && error.errorCode === 3000;

                if (blockchainError) {
                  config.onBlockchainError?.();
                }

                return Promise.reject({ response: error, status: res.status });
              });
          }

          const noContent = res.status === 204;

          if (noContent) {
            return { data: {}, status: res.status };
          }

          return res[to]().then(data => ({ data, status: res.status }));
        })
        .then(
          to === 'json' && camelCase
            ? getCamelizedKeysObject(camelCaseConversionHandler)
            : result => result,
        )
        .then(result => result as { data: TData; status: number }),
      new Promise<never>((resolve, reject) => {
        setTimeout(() => {
          reject(new Error(`Request timeout ${timeout}ms.`));
          abortController.abort(); // cancel pending request if timeout passed
        }, timeout);
      }),
    ]);
  };
};

export type RequestClient = ReturnType<typeof createRequestClient>;
