import TopMenu from 'modules/top-menu';
import { getTenantConfig } from 'helpers/get-tenant-config';
import { NavigationLogo } from 'shared/logos';
import { Container } from 'shared-parts/components';

import { HeaderWrapper, Row, StyledColumn } from './header.styled';

const {
  custom: {
    header: { secondaryLogo },
  },
} = getTenantConfig();

const twoColumnsSizesPerScreen = {
  first: {
    xs: '10',
    md: '3',
  },
  second: {
    xs: '2',
    md: '9',
  },
};

const threeColumnsSizesPerScreen = {
  first: {
    xs: '5',
    md: '2',
  },
  second: {
    xs: '2',
    md: '8',
  },
  third: {
    xs: '5',
    md: '2',
  },
};

const columnsSizesPerScreen = secondaryLogo ? threeColumnsSizesPerScreen : twoColumnsSizesPerScreen;

const firstLogoAlignment = 'flex-start';
const menuAlignment = secondaryLogo ? 'center' : 'flex-end';
const secondLogoAlignment = secondaryLogo ? 'center' : 'flex-start';

const Header = () => {
  return (
    <HeaderWrapper>
      <Container>
        <Row>
          <StyledColumn
            xs={columnsSizesPerScreen.first.xs}
            md={columnsSizesPerScreen.first.md}
            alignment={firstLogoAlignment}
          >
            <NavigationLogo />
          </StyledColumn>
          <StyledColumn
            xs={columnsSizesPerScreen.second.xs}
            md={columnsSizesPerScreen.second.md}
            alignment={menuAlignment}
          >
            <TopMenu />
          </StyledColumn>
          {secondaryLogo && (
            <StyledColumn
              xs={columnsSizesPerScreen.third.xs}
              md={columnsSizesPerScreen.third.md}
              alignment={secondLogoAlignment}
              alignItems="center"
            >
              <img src={secondaryLogo.url} height="40" width="142" alt="Secondary logo" />
            </StyledColumn>
          )}
        </Row>
      </Container>
    </HeaderWrapper>
  );
};

export default Header;
