const TickIcon = ({ width = 14, height = 11.5, fillColor = 'currentColor' }) => {
  return (
    <svg
      width={width}
      height={height}
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 14 11.5"
      fill="none"
    >
      <title>Tick Icon</title>
      <path
        d="M14 1.6566L4.4 11.5L0 6.98844L1.128 5.83184L4.4 9.1786L12.872 0.5L14 1.6566Z"
        fill={fillColor}
      />
    </svg>
  );
};

export default TickIcon;
