interface CheckCircleProps {
  width?: number;
  height?: number;
  fillColor?: string;
}

const CheckCircle = ({ width = 18, height = 18, fillColor }: CheckCircleProps) => (
  <svg
    width={width}
    height={height}
    viewBox="0 0 18 18"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <circle cx="9" cy="9" r="6.75" stroke={fillColor} />
    <path d="M6 9L8.25 11.25L12 6.75" stroke={fillColor} />
  </svg>
);

export default CheckCircle;
