import type {
  ComponentsOverrides,
  ComponentsVariants,
  PaletteColor,
  Theme as MuiTheme,
} from '@mui/material/styles';
// Not sure why this isn't exposed, but we have to use the internal here
// otherwise when we spread typography into styles, it complains..
import type { CSSProperties } from '@mui/material/styles/createTypography';
import createStyled from '@mui/system/createStyled';

import type { FormDescriptionProps } from './lib/components/form-description';
import type { FormGroupProps } from './lib/components/form-group';
import { StatProps } from './lib/components/stat';
import { theme } from './theme';

export { ThemeProvider } from '@mui/material/styles';
export type { Theme } from '@mui/material/styles';

export { Skeleton } from '@mui/material';

export { Alert, AlertTitle } from './lib/components/alert';
export type { AlertProps, AlertTitleProps } from './lib/components/alert';

export { Paper } from '@mui/material';

export { Button, IconButton } from './lib/components/buttons';
export type { ButtonProps, IconButtonProps } from './lib/components/buttons';

export { Card, CardActions, CardContent, CardHeader, CardMedia } from './lib/components/card';
export type {
  CardProps,
  CardActionsProps,
  CardContentProps,
  CardHeaderProps,
} from './lib/components/card';

export { Checkbox } from './lib/components/checkbox';
export type { CheckboxProps } from './lib/components/checkbox';

export * from './lib/components/datagrid';

export { Divider } from './lib/components/divider';
export type { DividerProps } from './lib/components/divider';

export { DatePicker, DateTimePicker } from './lib/components/date-picker';
export type { DatePickerProps, DateTimePickerProps } from './lib/components/date-picker';

export { FormGroup } from './lib/components/form-group';
export type { FormGroupProps } from './lib/components/form-group';
export { FormControl } from './lib/components/form-control';
export { FormLabel } from './lib/components/form-label';
export { FormDescription, FormDescriptionRoot } from './lib/components/form-description';
export { FormRow } from './lib/components/form-row';
export { FormControlLabel } from './lib/components/form-control-label';
export { FormGroupExpanded } from './lib/components/form-group-expanded';
export { FormControlGroup } from './lib/components/form-control-group';

export { Link } from './lib/components/link';
export type { LinkProps } from './lib/components/link';

export { Radio, RadioGroup } from './lib/components/radio';
export type { RadioProps, RadioGroupProps } from './lib/components/radio';

export {
  Select,
  MenuItem,
  MenuItemButton,
  SelectAll,
  CheckboxItem,
  TimezoneItem,
} from './lib/components/select';
export type {
  SelectProps,
  SelectComponentOption,
  MenuItemProps,
  TimezoneItemProps,
} from './lib/components/select';

export { CurrencyStat, DateStat, PercentageStat, Stat, StatList } from './lib/components/stat';
export type { StatListProps, StatProps } from './lib/components/stat';

export { Switch } from './lib/components/switch';
export type { SwitchProps } from './lib/components/switch';

export { Chip } from './lib/components/chip';
export type { ChipProps } from './lib/components/chip';

export { ToggleButton, ToggleButtonGroup, BooleanToggle } from './lib/components/toggle';
export type {
  ToggleButtonGroupProps,
  ToggleButtonProps,
  BooleanToggleProps,
} from './lib/components/toggle';

export { Input, NumberInput, CurrencyInput } from './lib/components/input';
export type { InputProps, NumberInputProps, CurrencyInputProps } from './lib/components/input';

export { Accordion, AccordionSummary, AccordionDetails } from './lib/components/accordion';
export type { AccordionProps } from './lib/components/accordion';

export { ProgressBar } from './lib/components/progress-bar';
export type { ProgressBarProps } from './lib/components/progress-bar';

export {
  Step,
  StepButton,
  StepConnector,
  StepContent,
  StepContext,
  StepLabel,
  Stepper,
  StepperContext,
  useStepContext,
  useStepperContext,
} from './lib/components/stepper';

export type {
  StepButtonProps,
  StepConnectorProps,
  StepContentProps,
  StepContextType,
  StepLabelProps,
  StepProps,
  StepperContextType,
  StepperProps,
} from './lib/components/stepper';

export {
  Stepper as LegacyStepper,
  StepLabel as LegacyStepLabel,
  StepStatus as LegacyStepStatus,
} from './lib/components/stepper-legacy';
export type {
  StepperProps as LegacyStepperProps,
  StepLabelProps as LegacyStepLabelProps,
  Step as LegacyStep,
} from './lib/components/stepper-legacy';

export { DocumentUpload } from './lib/components/document-upload';
export type { DocumentUploadProps } from './lib/components/document-upload';

export { Dialog, DialogTitle, DialogContent, DialogActions } from './lib/components/dialog';
export type {
  DialogProps,
  DialogTitleProps,
  DialogContentProps,
  DialogActionsProps,
} from './lib/components/dialog';

export type { WidgetStatusProps, WidgetProps } from './lib/components/widget';
export {
  Widget,
  WidgetContent,
  WidgetTitle,
  WidgetDescription,
  WidgetStatus,
  WidgetActions,
  WidgetRow,
} from './lib/components/widget';

export { Tabs, Tab, TabPanel } from './lib/components/tab';
export type { TabPanelProps, TabProps, TabsProps } from './lib/components/tab';

export { theme as defaultTheme };
export {
  useTheme,
  useMediaQuery,
  Box,
  Typography,
  Container,
  InputAdornment,
  Menu,
  Collapse,
  Popover,
  Stack,
  FormHelperText,
  List,
  ListItem,
  ListItemText,
  CircularProgress,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableRow,
  Backdrop,
  DialogContentText,
  Tooltip,
  Snackbar,
} from '@mui/material';
export type {
  BoxProps,
  TypographyProps,
  ContainerProps,
  InputAdornmentProps,
  PopoverOrigin,
  PopoverProps,
  MenuProps,
} from '@mui/material';

export { LicenseInfo } from '@mui/x-data-grid-premium';

export { LocalizationProvider } from '@mui/x-date-pickers-pro';
export { AdapterDayjs } from '@mui/x-date-pickers-pro/AdapterDayjs';

export { ChevronLeft } from '@mui/icons-material';

export { default as createTheme } from './create-theme';

const styled = createStyled<typeof theme>();
export { styled };

interface NeutralColor {
  0: string;
  25: string;
  50: string;
  100: string;
  200: string;
  300: string;
  400: string;
  500: string;
  600: string;
  700: string;
  800: string;
  900: string;
}

declare module '@mui/material/styles' {
  interface Palette {
    highlight: PaletteColor;
    neutral: NeutralColor;

    brown: PaletteColor;
    orange: PaletteColor;
    yellow: PaletteColor;
    green: PaletteColor;
    blue: PaletteColor;
    darkblue: PaletteColor;
    purple: PaletteColor;
    red: PaletteColor;
  }

  // PaletteOptions are the tokens given to createTheme
  interface PaletteOptions {
    highlight: PaletteColor; // these are the same as in Palette's interface because MUI's doesn't touch them when feeding createTheme.
    neutral: NeutralColor;

    brown: PaletteColor;
    orange: PaletteColor;
    yellow: PaletteColor;
    green: PaletteColor;
    blue: PaletteColor;
    darkblue: PaletteColor;
    purple: PaletteColor;
    red: PaletteColor;
  }

  interface TypographyVariants {
    bodyHighlight: CSSProperties;
    bodyAllCaps: CSSProperties;
    label: CSSProperties;
    tableBody: CSSProperties;
  }

  interface TypographyVariantsOptions {
    bodyHighlight?: CSSProperties;
    bodyAllCaps?: CSSProperties;
    label?: CSSProperties;
    tableBody?: CSSProperties;
  }
}

declare module '@mui/material/Button' {
  interface ButtonPropsVariantOverrides {
    secondary: true;
  }

  interface ButtonPropsColorOverrides {
    highlight: true;
  }
}
declare module '@mui/material/IconButton' {
  interface IconButtonPropsColorOverrides {
    highlight: true;
    neutral: true;
    outlined: false;
  }
}

declare module '@mui/material/Typography' {
  interface TypographyPropsVariantOverrides {
    bodyHighlight?: true;
    bodyAllCaps?: true;
    label?: true;
    tableBody: true;
    h6: false;
  }
}

declare module '@mui/material/ToggleButtonGroup' {
  interface ToggleButtonGroupPropsColorOverrides {
    highlight: true;
  }
}
declare module '@mui/material/ToggleButton' {
  interface ToggleButtonPropsColorOverrides {
    highlight: true;
  }
}

declare module '@mui/material/Badge' {
  interface BadgePropsColorOverrides {
    highlight: true;
  }
}

declare module '@mui/material/Checkbox' {
  interface CheckboxPropsColorOverrides {
    highlight: true;
  }
}

declare module '@mui/material/Radio' {
  interface RadioPropsColorOverrides {
    highlight: true;
  }
}

declare module '@mui/material/Switch' {
  interface SwitchPropsColorOverrides {
    highlight: true;
  }
}

declare module '@mui/material/Chip' {
  interface ChipPropsColorOverrides {
    highlight: true;

    brown: true;
    orange: true;
    yellow: true;
    green: true;
    blue: true;
    darkblue: true;
    purple: true;
    red: true;
  }
}

// https://mui.com/material-ui/guides/themeable-component/#typescript
declare module '@mui/material/styles' {
  interface ComponentNameToClassKey {
    FormGroup: 'root' | 'title' | 'content';
    MuiFormDescription: 'root';
    MuiStat: 'root' | 'value' | 'label' | 'subtitle';
  }

  interface ComponentsPropsList {
    FormGroup: Partial<FormGroupProps>;
    MuiFormDescription: Partial<FormDescriptionProps>;
    MuiStat: Partial<StatProps>;
  }

  interface Components {
    FormGroup?: {
      defaultProps?: ComponentsPropsList['FormGroup'];
      styleOverrides?: ComponentsOverrides<MuiTheme>['FormGroup'];
      variants?: ComponentsVariants['FormGroup'];
    };
    MuiFormDescription?: {
      defaultProps?: ComponentsPropsList['MuiFormDescription'];
      styleOverrides?: ComponentsOverrides<MuiTheme>['MuiFormDescription'];
      variants?: ComponentsVariants['MuiFormDescription'];
    };
    MuiStat?: {
      defaultProps?: ComponentsPropsList['MuiStat'];
      styleOverrides?: ComponentsOverrides<MuiTheme>['MuiStat'];
      variants?: ComponentsVariants['MuiStat'];
    };
  }
}
