import { useEffect, useState } from 'react';

import { DefaultContainer, DefaultItem, StyledClickOutside } from '../autosuggest-search.styled';
import type { WrapperProps } from '../types';

type SimpleFunctionComponent<P> = (props: P) => JSX.Element;

const withAutoSuggest =
  <P extends WrapperProps>(Component: SimpleFunctionComponent<P>) =>
  ({
    value,
    onSuggest,
    onSuggestionClick,
    suggestionOptions = [],
    itemComponent: ItemComponent = DefaultItem,
    containerComponent: ContainerComponent = DefaultContainer,
    ...restProps
  }: WrapperProps): JSX.Element => {
    const [localValue, setLocalValue] = useState<string>(value);
    const [isSelected, setIsSelected] = useState<boolean>(false);
    const shouldSuggest = Boolean(value && value !== localValue);

    const handleClear = () => {
      setLocalValue('');
      setIsSelected(true);
    };

    useEffect(() => handleClear, []);

    useEffect(() => {
      if (shouldSuggest && onSuggest) {
        onSuggest(value);
      }

      if (isSelected) {
        setIsSelected(false);
      }
    }, [value, localValue]);

    const handleSuggestionItemClick = (selectedValue: string) => {
      setLocalValue(selectedValue);
      setIsSelected(true);
      onSuggestionClick(selectedValue);
    };

    const renderSuggestionOptions = (options: string[]) =>
      options.map(item => (
        <ItemComponent key={item} onClick={() => handleSuggestionItemClick(item)}>
          {item}
        </ItemComponent>
      ));

    const options =
      !isSelected && shouldSuggest && suggestionOptions.length ? (
        <ContainerComponent>{renderSuggestionOptions(suggestionOptions)}</ContainerComponent>
      ) : null;

    return (
      <StyledClickOutside onClickOutside={handleClear}>
        <Component {...(restProps as P)} value={value}>
          {options}
        </Component>
      </StyledClickOutside>
    );
  };

export default withAutoSuggest;
