import { InMemoryProvider, OpenFeature, Provider } from '@openfeature/react-sdk';

/**
 * Get the OpenFeature provider. Currently this only returns the
 * in-memory provider with no feature flags, but in future
 * we will switch the provider based on configuration.
 */
function getOpenFeatureProvider(): Provider {
  return new InMemoryProvider({});
}

/**
 * Initialize the OpenFeature SDK by creating and setting the
 * provider.
 */
export function initializeOpenFeature() {
  const provider = getOpenFeatureProvider();
  OpenFeature.setProvider(provider);
}

/**
 * Called when a user logs in. Needs to set the context based
 * on the OpenFeature provider being used and the users attributes.
 */
export function setOpenFeatureUser(_userData: unknown) {
  // TODO: the legacy config provider does not support context.
  OpenFeature.setContext({});
}

/**
 * Called when a user logs out, to clear any context.
 */
export function clearOpenFeatureUser() {
  OpenFeature.setContext({});
}

/**
 * Called in tests to create a new in-memory OpenFeature provider
 * with the specified flags and values set.
 */
export async function setTestOpenFeatureFlags(flags: Record<string, string | number | boolean>) {
  const flagConfiguration = Object.fromEntries(
    Object.entries(flags).map(([key, value]) => [
      key,
      {
        disabled: false,
        variants: {
          off: null,
          on: value,
        },
        defaultVariant: 'off',
        contextEvaluator: () => 'on',
      },
    ]),
  );
  const provider = new InMemoryProvider(flagConfiguration);
  await OpenFeature.setProviderAndWait(provider);
}

export {
  useFlag,
  useSuspenseFlag,
  useBooleanFlagDetails,
  useBooleanFlagValue,
  useNumberFlagDetails,
  useNumberFlagValue,
  useObjectFlagDetails,
  useObjectFlagValue,
  useStringFlagDetails,
  useStringFlagValue,
  OpenFeatureProvider,
} from '@openfeature/react-sdk';
