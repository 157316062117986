import { call, put, takeEvery } from 'redux-saga/effects';

import {
  createBonusContributionOrderError,
  createBonusContributionOrderSuccess,
  createOrderError,
  createOrderSuccess,
} from 'app-state/actions/order';
import { CREATE_BONUS_CONTRIBUTION_ORDER, CREATE_ORDER } from 'app-state/constants/order';

import API from 'constants/api';
import request from 'shared-parts/helpers/request';

function* createOrder({ payload }) {
  const orderPayload = {
    contributionAmount: parseFloat(payload.contributionAmount),
    planId: payload.planId,
  };
  try {
    const data = yield call(
      request,
      `${API.ShareIncentivePlans.Prefix()}${API.ShareIncentivePlans.CreatePurchaseOrder()}`,
      'POST',
      orderPayload,
      {
        snakeCaseParams: false,
      },
    );
    yield put(createOrderSuccess({ data }));
    payload?.onSuccess();
  } catch (error) {
    yield put(createOrderError({ error }));
  }
}

function* createOrderWatcher() {
  yield takeEvery(CREATE_ORDER, createOrder);
}

function* createBonusContributionOrder({ payload }) {
  const orderPayload = {
    contributionAmount: parseFloat(payload.contributionAmount),
    planId: payload.planId,
  };
  try {
    const data = yield call(
      request,
      `${API.ShareIncentivePlans.Prefix()}${API.ShareIncentivePlans.CreateBonusContributionOrder()}`,
      'POST',
      orderPayload,
      {
        snakeCaseParams: false,
      },
    );
    yield put(createBonusContributionOrderSuccess({ data }));
    payload?.onSuccess();
  } catch (error) {
    yield put(createBonusContributionOrderError({ error }));
  }
}

function* createBonusContributionOrderWatcher() {
  yield takeEvery(CREATE_BONUS_CONTRIBUTION_ORDER, createBonusContributionOrder);
}

export { createOrder, createOrderWatcher, createBonusContributionOrderWatcher };
