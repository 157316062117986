import { useQuery, UseQueryOptions } from '@tanstack/react-query';

import { useRequestClient } from './clients/providers/http-request-client';
import type { RequestOptions } from './clients/request';

// Passing in options allows developers to slowly
// use more and more of the features of tanstack, without
// having to demand it upfront
export const useRequest = <
  TData = any,
  TMapped = TData,
  TResult extends { data: TData; status: number } = { data: TData; status: number },
>(
  url: string,
  opts?: Omit<UseQueryOptions<TResult, unknown, TMapped>, 'select'> & {
    select?: (result: TData) => TMapped;
  },
  requestOptions?: Partial<RequestOptions>,
) => {
  const request = useRequestClient();

  return useQuery<TResult, unknown, TMapped>({
    queryKey: [url],
    ...opts,
    queryFn: ({ signal }) => {
      return request(url, 'GET', undefined, { ...requestOptions, signal }) as Promise<TResult>;
    },
    select: result => {
      const { data } = result;

      if (opts?.select) {
        return opts.select(data);
      }

      return data as unknown as TMapped; // 'trust me bro'
    },
  });
};
