import React from 'react';

const EditIcon = ({ width = 18, height = 18, fillColor = 'currentColor' }) => (
  <svg
    width={width}
    height={height}
    viewBox="0 0 18 18"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M15.3756 5.48844C15.6519 5.21219 15.6519 4.75177 15.3756 4.48969L13.7181 2.83219C13.4561 2.55594 12.9956 2.55594 12.7194 2.83219L11.4161 4.12844L14.0723 6.78469M2.83105 12.7205V15.3768H5.4873L13.3215 7.53552L10.6652 4.87927L2.83105 12.7205Z"
      fill={fillColor}
    />
  </svg>
);

export default EditIcon;
