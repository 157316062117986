import { push as pushAction } from 'connected-react-router';
import { Fragment, useEffect, useState } from 'react';
import { connect } from 'react-redux';

import snsWebSdk from '@sumsub/websdk';

import {
  completeKyc as completeKycAction,
  resetSumSubConfig as resetSumSubConfigAction,
  saveApplicantId as saveApplicantIdAction,
  setupSumSubConfig as setupSumSubConfigAction,
} from 'app-state/actions/onboarding';
import {
  hideModal as hideModalAction,
  showModal as showModalAction,
} from 'app-state/actions/shared';
import { getOnboardingConfig } from 'app-state/selectors/onboarding';

import Routes from 'constants/routes';
import CloseButton from 'images/arrow-close.svg';

import OnboardingCancelModal from './onboarding-cancel-modal';
import { CancelButton, CancelIcon, SumSubContainer } from './sumsub-verification-page.styled';

const SumSubVerificationPage = ({
  setupSumSubConfig,
  resetSumSubConfig,
  onboardingConfig: { data: SumSubConfig = {} },
  saveApplicantId,
  completeKyc,
  push,
  showModal,
  hideModal,
}) => {
  const [isOnboardingCompleted, setIsOnboardingCompleted] = useState(false);

  useEffect(() => {
    setupSumSubConfig();

    return () => resetSumSubConfig();
  }, []);

  const getNewAccessToken = () => Promise.resolve(SumSubConfig.accessToken);

  const initSumSub = () => {
    const snsWebSdkInstance = snsWebSdk
      .init(SumSubConfig.accessToken, () => getNewAccessToken())
      .withBaseUrl(SumSubConfig.rootApiUrl)
      .withConf({
        lang: 'en',
        email: SumSubConfig.email,
        country: SumSubConfig.country,
      })
      .onMessage((type, payload) => {
        if (payload.applicantId) {
          saveApplicantId(SumSubConfig.externalUserId, payload.applicantId);
        }

        if (payload.reviewStatus === 'pending' || payload.reviewStatus === 'queued') {
          completeKyc(SumSubConfig.externalUserId);
          setIsOnboardingCompleted(true);
        }

        if (payload.reviewStatus === 'completed') {
          const { reviewAnswer, reviewRejectType } = payload.reviewResult;

          const isApplicantIdSaved =
            reviewAnswer === 'GREEN' || (reviewAnswer === 'RED' && reviewRejectType !== 'RETRY');

          if (isApplicantIdSaved) {
            const actionPath = localStorage.getItem('actionPath');

            push(actionPath || Routes.Root());
          }
        }
      })
      .on('onError', error => {
        console.log('onError', error);
      })
      .build();

    snsWebSdkInstance.launch('#sumsub-websdk-container');
  };

  useEffect(() => {
    if (SumSubConfig.accessToken) {
      initSumSub();
    }
  }, [SumSubConfig]);

  const cancelOnboarding = () => {
    if (isOnboardingCompleted) {
      push(Routes.Root());
    } else {
      showModal({
        showHeader: false,
        closable: true,
        component: OnboardingCancelModal,
        cancelAction: () => {
          push(Routes.Root());
          hideModal();
        },
      });
    }
  };

  return (
    <Fragment>
      <CancelButton onClick={cancelOnboarding}>
        <CancelIcon src={CloseButton} />
      </CancelButton>
      <SumSubContainer id="sumsub-websdk-container" />
    </Fragment>
  );
};

const mapStateToProps = state => ({
  onboardingConfig: getOnboardingConfig(state),
});

const mapDispatchToProps = dispatch => ({
  setupSumSubConfig: () => dispatch(setupSumSubConfigAction()),
  saveApplicantId: (uuid, applicantId) => dispatch(saveApplicantIdAction(uuid, applicantId)),
  completeKyc: uuid => dispatch(completeKycAction(uuid)),
  resetSumSubConfig: () => dispatch(resetSumSubConfigAction()),
  push: url => dispatch(pushAction(url)),
  showModal: data => dispatch(showModalAction(data)),
  hideModal: () => dispatch(hideModalAction()),
});

export default connect(mapStateToProps, mapDispatchToProps)(SumSubVerificationPage);
