export const NOT_APPLICABLE_OPTION = { value: '', text: 'N/A', fields: [] };

export const DEFAULT_SEARCH_CONFIGURATION = {
  // 'fields' property contains all option keys, which must be indexed for further search.
  fields: ['text'],
  idField: 'value',
  searchOptions: {
    prefix: true,
  },
};
