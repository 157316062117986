export type AsyncState<T> = {
  data: T;
  loading: boolean;
  error?: string;
};

type AsyncStates = 'FAILURE' | 'SUCCESS' | 'REQUEST';

export const createAsyncActionType = <TPrefix extends string, TActionName extends string>(
  prefix: TPrefix,
  actionName: TActionName,
): Record<AsyncStates, `${TPrefix}:${TActionName}_${AsyncStates}`> => ({
  FAILURE: `${prefix}:${actionName}_FAILURE`,
  REQUEST: `${prefix}:${actionName}_REQUEST`,
  SUCCESS: `${prefix}:${actionName}_SUCCESS`,
});

export const createAction =
  <T>(type: string) =>
  (payload?: T) => ({ type, payload });
