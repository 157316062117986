import { getRange } from '@frontend/utils';

import currencies from 'shared-parts/constants/currencies';

const parseNumber = number => (isNaN(number) ? 0 : Number(number));
const addCommasToNumber = number => {
  const intPart = parseInt(number, 10);
  const indexOfFractionalPart = number.toString().indexOf('.');
  const fractionalPart =
    indexOfFractionalPart > 0 ? number.toString().slice(indexOfFractionalPart) : '';
  const intPartWithCommas = intPart.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',');

  return `${intPartWithCommas}${fractionalPart}`;
};

const toInt = number => {
  const parsedNumber = parseNumber(number);

  return addCommasToNumber(parseInt(parsedNumber, 10));
};

// string is returned when a string with decimal is passed in other case number is returned
function cutZeros(number = 0) {
  const asANumber = +number;

  if (Number.isInteger(asANumber)) {
    return asANumber;
  }

  const formatted = String(number);
  const lastCharacter = formatted[formatted.length - 1];

  return Number(lastCharacter) === 0 ? cutZeros(formatted.slice(0, -1)) : number;
}

const toDefinedFractionSize = (number, fraction) =>
  parseFloat(parseNumber(number)).toFixed(fraction);

const toDefinedFractionSizeAndCutZeros = (number, fraction) =>
  cutZeros(toDefinedFractionSize(number, fraction));

const toDefinedFractionSizeNoRound = (number, fractionalPartLength = 2) => {
  const convertedNumber = Number(number);
  const removeDecimalPlaces = fractionalPartLength === 0;

  if (Number.isInteger(convertedNumber) && !removeDecimalPlaces) {
    return addCommasToNumber(convertedNumber.toFixed(2));
  }

  const numberAsString = String(number);
  const [wholeNumber, dp = ''] = numberAsString.split('.');
  const formattedWholePart = toInt(wholeNumber);
  const dot = removeDecimalPlaces ? '' : '.';
  const truncatedFractionalPart = dp.slice(0, fractionalPartLength);
  const truncatedFractionalPartWithZeros = getRange(0, fractionalPartLength - 1)
    .map((elem, i) => truncatedFractionalPart[i] || 0)
    .join('');

  return `${formattedWholePart}${dot}${truncatedFractionalPartWithZeros}`;
};

const toFloat = number => addCommasToNumber(toDefinedFractionSize(number, 2));
const toFloat4 = number => addCommasToNumber(toDefinedFractionSize(number, 4));

const toSemiFloat4 = number => {
  if (number === 0) return '0';
  if (Math.trunc(toDefinedFractionSize(number, 4)) / toDefinedFractionSize(number, 4) === 1) {
    return addCommasToNumber(Number(toDefinedFractionSize(number, 4).toString()));
  }
  if (Number(toDefinedFractionSize(number, 4)) === Number(toDefinedFractionSize(number, 2))) {
    return addCommasToNumber(toDefinedFractionSize(number, 2));
  }
  return addCommasToNumber(toDefinedFractionSize(number, 4));
};
const toSemiFloat2 = number => {
  if (number === 0) return '0';

  return Math.trunc(toDefinedFractionSize(number, 2)) / toDefinedFractionSize(number, 2) === 1
    ? addCommasToNumber(Number(toDefinedFractionSize(number, 2).toString()))
    : addCommasToNumber(toDefinedFractionSize(number, 2));
};

const divideSignAndNumber = value => {
  if (typeof value === 'string') {
    const sign = value[0] === '-' ? '-' : '';
    const num = sign ? value.slice(1) : value;
    return [sign, num];
  }

  const number = Number(value);
  return number > 0 ? ['', number] : ['-', number * -1];
};

const addCurrencySign = (amount, currency) => {
  const { symbol, position } = currencies[currency] || { symbol: currency || '', position: 'left' };
  const [sign, number] = divideSignAndNumber(amount);
  return position === 'left' ? `${sign}${symbol}${number}` : `${sign}${number} ${symbol}`;
};

const formatCurrencySign = currency => {
  if (currencies[currency]) {
    return currencies[currency].symbol;
  }
  return currency;
};

const toIntCurrency = (number, currency) => addCurrencySign(toInt(number), currency);
const toFloatCurrency = (number, currency) => addCurrencySign(toFloat(number), currency);
const toFloat4Currency = (number, currency) => addCurrencySign(toFloat4(number), currency);

const toIntPercent = number => `${toInt(number)}%`;
const toFloatPercent = number => `${toFloat(number)}%`;
const fractionToPercent = number => `${parseNumber(number) * 100}%`;

const toAbbrInt = (number, fraction = 2) => {
  if (isNaN(Number(number))) return number;

  if (number >= 1000000000000)
    return `${addCommasToNumber(toDefinedFractionSizeNoRound(number / 1000000000000, fraction))}T`;
  if (number >= 1000000000)
    return `${addCommasToNumber(toDefinedFractionSizeNoRound(number / 1000000000, fraction))}B`;
  if (number >= 1000000)
    return `${addCommasToNumber(toDefinedFractionSizeNoRound(number / 1000000, fraction))}m`;
  if (number >= 1000)
    return `${addCommasToNumber(toDefinedFractionSizeNoRound(number / 1000, fraction))}k`;
  return addCommasToNumber(toDefinedFractionSize(number, fraction));
};

const removeNumberCommas = value => String(value).replace(/,/g, '');

const formatInputNumber = (value, dpAmount = 2) => {
  const stringValue = `${value}`;
  const dotIndex = stringValue.indexOf('.');
  const wholePartEndIndex = dotIndex > 0 ? dotIndex : stringValue.length;
  const intPart = stringValue.substring(0, wholePartEndIndex);
  const rest =
    dpAmount > 0 ? stringValue.substring(wholePartEndIndex, wholePartEndIndex + dpAmount + 1) : '';
  const formattedIntPart = toInt(+removeNumberCommas(intPart));

  return removeNumberCommas(stringValue) > 0 ? `${formattedIntPart}${rest}` : stringValue;
};

const toDefinedFractionSizeRoundUp = (number, fractionSize) =>
  Math.ceil(number * 10 ** fractionSize) / 10 ** fractionSize;

const valueOrHyphen = (value, formattedValue) =>
  typeof parseNumber(value) === 'number' ? formattedValue || value : '-';

export {
  cutZeros,
  parseNumber,
  addCurrencySign,
  formatCurrencySign,
  toFloatCurrency,
  toFloat4Currency,
  toFloatPercent,
  toFloat,
  toFloat4,
  toSemiFloat2,
  toSemiFloat4,
  toAbbrInt,
  toInt,
  toIntCurrency,
  toIntPercent,
  toDefinedFractionSize,
  toDefinedFractionSizeAndCutZeros,
  toDefinedFractionSizeNoRound,
  formatInputNumber,
  removeNumberCommas,
  addCommasToNumber,
  toDefinedFractionSizeRoundUp,
  valueOrHyphen,
  fractionToPercent,
};
