import { forwardRef, PropsWithChildren } from 'react';

import {
  alpha,
  Box,
  IconProps as MuiIconProps,
  Palette,
  PaletteColor,
  styled,
} from '@mui/material';

export type DialogIconProps = Pick<MuiIconProps, 'color'>;

const DialogIconRoot = styled(Box, {
  label: 'MuiDialogIcon',
  name: 'MuiDialogIcon',
  slot: 'root',
})<{ ownerState: DialogIconProps }>(({ theme }) => ({
  display: 'flex',
  padding: theme.spacing(0, 6, 3),
}));

const DialogIconContainer = styled(Box, {
  label: 'MuiDialogIcon',
  name: 'MuiDialogIcon',
  slot: 'container',
})<{ ownerState: DialogIconProps }>(({ theme }) => ({
  padding: '14px',
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',

  variants: [
    ...Object.entries(theme.palette)
      .filter(([, value]) => value && value.main) // check all the used fields in the style below
      .map(([color]) => {
        const palette = theme.palette[color as keyof Palette] as PaletteColor;
        return {
          props: { color },
          style: {
            color: palette.main,

            borderRadius: '50%',
            backgroundColor: alpha(palette.light, 0.4),
          },
        };
      }),
  ],
}));

export const DialogIcon = forwardRef<HTMLDivElement, PropsWithChildren<DialogIconProps>>(
  (props, ref) => {
    const { children, ...ownerState } = props;

    return (
      <DialogIconRoot className="MuiDialogIcon-root" ref={ref} ownerState={ownerState}>
        <DialogIconContainer className="MuiDialogIcon-container" ownerState={ownerState}>
          {children}
        </DialogIconContainer>
      </DialogIconRoot>
    );
  },
);
