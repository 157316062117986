interface ErrorOutlinedProps {
  width?: number;
  height?: number;
  fillColor?: string;
}

const ErrorOutlined = ({ width = 17, height = 17, fillColor }: ErrorOutlinedProps) => (
  <svg
    width={width}
    height={height}
    viewBox="0 0 17 17"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <circle cx="8.5" cy="8.5" r="6.375" transform="rotate(-180 8.5 8.5)" stroke={fillColor} />
    <path
      d="M8.14616 11.6888C8.14616 11.4932 8.30472 11.3346 8.50033 11.3346C8.69593 11.3346 8.85449 11.4932 8.85449 11.6888C8.85449 11.8844 8.69593 12.043 8.50033 12.043C8.30472 12.043 8.14616 11.8844 8.14616 11.6888Z"
      fill={fillColor}
      stroke={fillColor}
    />
    <path d="M8.5 4.95964L8.5 9.91797" stroke={fillColor} />
  </svg>
);

export default ErrorOutlined;
