export const allowNumbersOnly = e => {
  if (e.shiftKey) {
    e.preventDefault();
  }

  const charCode = e.which || e.keyCode;

  if (
    (e.ctrlKey || e.metaKey) &&
    (charCode === 65 || charCode === 67 || charCode === 86 || charCode === 88 || charCode === 90)
  ) {
    return;
  }

  const numbersAreNotUsed =
    (charCode > 31 && charCode < 37) || (charCode > 40 && charCode < 48) || charCode > 57;
  const numlockNumbersAreNotUsed = !(charCode >= 96 && charCode <= 105);

  if (numbersAreNotUsed && numlockNumbersAreNotUsed) {
    e.preventDefault();
  }
};

export const allowNumbersOnlyOnPaste = e =>
  !Number.isInteger(+e.clipboardData.getData('text')) && e.preventDefault();
