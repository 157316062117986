import { Deal } from '../deal-tile.types';

import { FundedBar } from './active-issuance-stats.styled';
import { CompanyStats } from './company-stats';
import { CommonsStats, DaysToGo, Funded, FundedValue } from './shared.styled';
import { pluralize, toFloatPercent, twentyFourHours } from './trade-offs';

export type ActiveIssuanceStatsProps = Deal & {
  oswaldFont: string;
  secondaryColor: string;
  neutral500Color: string;
  neutral300Color: string;
};

export const ActiveIssuanceStats = ({
  totalTokensSold,
  subscriptionEnd,
  pricePerToken,
  softTargetAmount,
  fundingAmountVisible,
  targetAmountVisible,
  subscriptionEndDaysVisible,
  baseCurrency,
  requireCloseDate,
  oswaldFont,
  secondaryColor,
  neutral500Color,
  neutral300Color,
}: ActiveIssuanceStatsProps) => {
  const funded = ((totalTokensSold * pricePerToken) / softTargetAmount) * 100;
  const diff = new Date(subscriptionEnd).getTime() - new Date().getTime();
  const daysToGo = Number.parseInt((diff / twentyFourHours).toFixed(), 10);

  return (
    <CommonsStats neutral300Color={neutral300Color}>
      {targetAmountVisible && (
        <CompanyStats
          oswaldFont={oswaldFont}
          text="Target"
          currency={baseCurrency}
          amount={softTargetAmount}
        />
      )}
      {fundingAmountVisible && (
        <Funded neutral500Color={neutral500Color}>
          <FundedValue>{toFloatPercent(funded)} Funded</FundedValue>
          <FundedBar funded={funded} secondaryColor={secondaryColor} />
        </Funded>
      )}
      {subscriptionEndDaysVisible && (requireCloseDate ?? true) && (
        <DaysToGo>{`${pluralize('Day', daysToGo <= 0 ? 0 : daysToGo)} To Go`}</DaysToGo>
      )}
    </CommonsStats>
  );
};
