import {
  GET_CURRENCY_RATE,
  GET_CURRENCY_RATE_ERROR,
  GET_CURRENCY_RATE_SUCCESS,
} from 'app-state/constants/currency-rate';

export const getCurrencyRate = (currencyFrom, currencyTo, calcContext, setFieldTouched) => ({
  type: GET_CURRENCY_RATE,
  currencies: { currencyFrom, currencyTo },
  calcContext,
  setFieldTouched,
});
export const getCurrencyRateSuccess = (currencyPairCode, exchangeRate) => ({
  type: GET_CURRENCY_RATE_SUCCESS,
  currencyPairCode,
  exchangeRate,
});
export const getCurrencyRateError = error => ({
  type: GET_CURRENCY_RATE_ERROR,
  error,
});
