import { Form } from 'formik';
import styled from 'styled-components';

import InvestButton from 'shared/invest-button/invest-button.styled';
import FormikButton from 'shared-parts/components/button/formik-button';
import TextArea from 'shared-parts/components/text-area/text-area';
import { StyledTextArea } from 'shared-parts/components/text-area/text-area.styled';
import TextInput from 'shared-parts/components/text-input/regular-text-input';
import { Container, Input } from 'shared-parts/components/text-input/regular-text-input.styled';
import * as Colors from 'shared-parts/constants/colors';
import * as Fonts from 'shared-parts/constants/fonts';
import { Screens } from 'shared-parts/constants/screens';

export const StyledInput = styled(TextInput)`
  ${Container} {
    height: 40px;
    font-size: 14px;
    padding-right: 0;
    background-image: none;
  }
  ${Input} {
    height: 38px;
    padding-right: 0;
  }
`;

export const CurrencySign = styled.span`
  position: absolute;
  top: 10px;
  font-size: 14px;
  color: #002656;
  font-weight: normal;
  width: 15px;
  text-align: right;
  z-index: 1;
  cursor: text;
`;

export const CurrencyInput = styled.div`
  position: relative;
  width: 50%;
  margin-left: 5px;

  @media (max-width: ${Screens.sm}) {
    width: 100%;
    margin: 10px 0 0;
  }
`;

export const AmountInput = styled(StyledInput)`
  width: 180px;

  @media (max-width: ${Screens.sm}) {
    width: 100%;
  }
`;

export const Button = styled(InvestButton)`
  width: 130px;
  padding: 10px 0;
  margin-left: 10px;
  letter-spacing: 0.28px;
  font-weight: normal;
`;

export const SubmitButton = styled(FormikButton)`
  width: 290px;
  text-transform: uppercase;
`;

export const ModalContainer = styled.div`
  width: 800px;
  background: ${Colors.neutral200};
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 35px 108px;
  font-family: ${Fonts.mainFont};

  @media (max-width: ${Screens.md}) {
    width: 700px;
  }

  @media (max-width: ${Screens.sm}) {
    width: 96vw;
    padding: 20px 10px;
  }
`;

export const Section = styled.div`
  width: 100%;
  margin-bottom: 30px;
  padding-bottom: 30px;
  border-bottom: 1px solid ${Colors.neutral300};

  @media (max-width: ${Screens.sm}) {
    margin-bottom: 15px;
  }
`;

export const Title = styled.h2`
  color: ${Colors.primary};
  font-size: 16px;
  line-height: 24px;
  font-weight: bold;
  text-align: center;
  font-style: normal;
  margin-bottom: 0;
`;

export const Label = styled.label<{
  htmlFor: string;
}>`
  color: ${Colors.primary};
  font-size: 14px;
  font-weight: bold;
  margin-bottom: 0;
  min-width: max-content;
`;

const ArrayFieldLabel = styled(Label)`
  margin-right: 10px;
`;

export const AmountLabel = styled(ArrayFieldLabel)`
  margin-top: 10px;
  width: 50%;
  margin-right: 5px;
  text-align: end;

  @media (max-width: ${Screens.sm}) {
    width: 100%;
    text-align: start;
  }
`;

export const ArrayContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  flex-wrap: wrap;
  justify-content: space-between;
  position: relative;
`;

export const AddIcon = styled.div<{
  disabled?: boolean;
}>`
  width: 18px;
  height: 18px;
  font-size: 13px;
  background-color: ${Colors.systemGreen};
  border-radius: 3px;
  color: ${Colors.white};
  font-weight: bold;
  display: flex;
  justify-content: center;
  margin: 0 10px 0 0;
  float: left;

  &:after {
    content: '+';
  }
`;

export const RemoveIcon = styled(AddIcon)`
  background-color: ${Colors.systemRed};
  position: absolute;
  right: -35px;
  top: 11px;
  cursor: pointer;

  &:after {
    content: '-';
  }
`;

export const StyledForm = styled(Form)`
  width: 100%;
`;

export const AddButtonContainer = styled.span<{ disabled: boolean }>`
  margin-top: 25px;
  align-items: baseline;
  font-size: 14px;
  display: inline-block;
  color: ${Colors.primary};
  cursor: pointer;
  ${({ disabled }) =>
    disabled &&
    `
    background-color: ${Colors.neutral800};
    cursor: default;
    pointer-events: none;
  `}
`;

export const ButtonContainer = styled(Section)`
  text-align: center;
  margin-bottom: 25px;
  padding-bottom: 0;
  border-bottom: none;
`;

export const ConditionsInput = styled(TextArea)`
  width: 100%;

  ${StyledTextArea} {
    background-color: ${Colors.white};
  }
`;

export const TextareaSection = styled(Section)`
  justify-content: stretch;
  align-items: flex-start;
  display: flex;
  flex-direction: row;

  ${Label} {
    margin: 10px 10px 10px 0;
  }

  @media (max-width: ${Screens.sm}) {
    flex-wrap: wrap;
  }
`;

export const AmountContainer = styled.div`
  display: flex;
  align-items: flex-start;
  width: 100%;
  :not(:last-child) {
    margin-bottom: 30px;
  }

  @media (max-width: ${Screens.sm}) {
    flex-wrap: wrap;
  }
`;
