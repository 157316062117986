import { forwardRef, PropsWithChildren } from 'react';

import { styled, useThemeProps } from '@mui/material';

const FORM_GROUP_NAME = 'FormGroup' as const;

export type FormGroupProps = PropsWithChildren<{
  title?: string;
  direction?: 'vertical' | 'horizontal';
}>;

// TODO: Should be a fieldset element but couldnt get the layout working
const FormGroupRoot = styled('div', {
  name: FORM_GROUP_NAME,
  slot: 'Root',
  overridesResolver: (_props, styles) => [styles.root],
})<{ ownerState: FormGroupProps }>(({ theme, ownerState }) => ({
  display: 'flex',
  gap: theme.spacing(ownerState.direction === 'vertical' ? 5 : 10),
  flexDirection: ownerState.direction === 'vertical' ? 'column' : 'row',

  '&:not(:last-child)': {
    marginBottom: theme.spacing(12),
  },
}));

const FormGroupTitle = styled('div', {
  name: FORM_GROUP_NAME,
  slot: 'Title',
  overridesResolver: (_props, styles) => [styles.title],
})<{ ownerState: FormGroupProps }>(({ theme, ownerState }) => ({
  ...(ownerState.direction !== 'vertical' && {
    flexGrow: 0,
    flexShrink: 1,
    flexDirection: 'column',
    flexBasis: '176px',
    wordBreak: 'break-word',
  }),

  ...theme.typography.bodyAllCaps,
}));

const FormGroupContent = styled('div', {
  name: FORM_GROUP_NAME,
  slot: 'Content',
  overridesResolver: (_props, styles) => [styles.content],
})<{ ownerState: FormGroupProps }>(({ ownerState }) => ({
  ...(ownerState.direction !== 'vertical' && {
    display: 'flex',
    flexGrow: 0,
    flexShrink: 1,
    flexBasis: '500px',
    flexDirection: 'column',
  }),
}));

/**
 * A presentational grouping of form controls with a group title.
 */
export const FormGroup = forwardRef<HTMLDivElement, FormGroupProps>((inProps, ref) => {
  const props = useThemeProps({ props: inProps, name: FORM_GROUP_NAME });

  const { title, children, ...rest } = props;

  return (
    <FormGroupRoot ref={ref} ownerState={props} {...rest}>
      {title && <FormGroupTitle ownerState={props}>{title}</FormGroupTitle>}
      <FormGroupContent ownerState={props}>{children}</FormGroupContent>
    </FormGroupRoot>
  );
});

FormGroup.displayName = FORM_GROUP_NAME;
