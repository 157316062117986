import styled from 'styled-components';

import { useEnvVar } from '@frontend/config';

import { getTenantConfig } from 'helpers/get-tenant-config';

const {
  custom: {
    navigationLogo: { height, margin, marginTop, minWidth, width },
  },
} = getTenantConfig();

const LogoImage = styled.img`
  ${height && `height: ${height};`}
  ${margin && `margin: ${margin};`}
  ${marginTop && `margin-top: ${marginTop};`}
  ${minWidth && `min-width: ${minWidth};`}
  ${width && `width: ${width};`}
`;

const MainLogo = () => {
  const platformName = useEnvVar('PLATFORM_NAME', 'Globacap');

  return <LogoImage src="/logos/white.svg" alt={`${platformName} logo`} />;
};

export default MainLogo;
