import sortByParamAlphabetically from 'shared-parts/helpers/sorting';

const getSortedData = (data = [], sortingData) => {
  if (!data) return [];

  if (!sortingData) return data;

  const { param, type, asc } = sortingData;

  return data.sort(sortByParamAlphabetically(param, type, asc));
};

export default getSortedData;
