import * as constants from 'app-state/constants';

export const getNavigationState = (params = {}) => ({
  loading: true,
  items: {},
  errors: null,
  ...params,
});

export const navigationReducer = (
  state = getNavigationState(),
  { type, error, navigationList },
) => {
  switch (type) {
    case constants.RESET_NAVIGATION:
      return getNavigationState();
    case constants.FETCH_NAVIGATION:
      return getNavigationState();
    case constants.FETCH_NAVIGATION_SUCCESS: {
      return getNavigationState({
        loading: false,
        items: navigationList.reduce(
          (prev, item) => ({ ...prev, [decodeURI(item.url)]: item }),
          {},
        ),
      });
    }
    case constants.FETCH_NAVIGATION_ERROR:
      return getNavigationState({
        loading: false,
        errors: error,
      });
    default:
      return state;
  }
};

const formatSubNavigationItems = items =>
  Object.entries(items).reduce((prev, [itemURL, subItems]) => {
    if (!subItems) {
      return prev;
    }

    const byURL = subItems.reduce(
      (previous, currItem) => ({ ...previous, [decodeURI(currItem.url)]: currItem }),
      {},
    );
    const investmentTermsPageName = 'investment-terms';
    const investmentTerms = byURL[investmentTermsPageName];
    const importantNoticePageName = 'important-notice';
    const importantNotice = byURL[importantNoticePageName];
    const rootObject = {
      ...prev,
      [itemURL]: byURL,
    };

    if (investmentTerms && importantNotice) {
      return {
        ...rootObject,
        [investmentTermsPageName]: { ...investmentTerms, parent: itemURL },
        [importantNoticePageName]: { ...importantNotice, parent: itemURL },
      };
    }

    return rootObject;
  }, {});

export const getSubNavigationState = (params = {}) => ({
  loading: true,
  items: {},
  errors: null,
  ...params,
});

export const subNavigationReducer = (state = getSubNavigationState(), { type, error, items }) => {
  switch (type) {
    case constants.RESET_SUB_NAVIGATION:
      return getSubNavigationState();
    case constants.FETCH_SUB_NAVIGATION:
      return getSubNavigationState();
    case constants.FETCH_SUB_NAVIGATION_SUCCESS:
      return getSubNavigationState({ loading: false, items: formatSubNavigationItems(items) });
    case constants.FETCH_SUB_NAVIGATION_ERROR:
      return getSubNavigationState({
        loading: false,
        items: state.items,
        errors: error,
      });
    default:
      return state;
  }
};
