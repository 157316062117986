import { createRef } from 'react';

import styled from 'styled-components';

import { Button } from 'shared/copy-field/copy-field.styled';

import { handleCopyToClipboard } from './helpers';

const CopyAllButton = styled(Button)`
  position: relative;
  border-radius: 0.4rem;
`;

const withFormClipboard = Component => props => {
  const form = createRef();

  const handleFormPaste = e => {
    e.preventDefault();

    const [clipboardValues] = e.clipboardData.items;

    // todo - Clipboard.read() will be preferred when it is supported in FF
    clipboardValues.getAsString(string => {
      const data = JSON.parse(string);

      Object.keys(data).forEach(key => {
        if (form.current[key]) {
          form.current[key].value = data[key];
        }
      });
    });
  };

  return (
    <form onSubmit={handleCopyToClipboard} ref={form} onPaste={handleFormPaste}>
      <Component {...props}>
        <CopyAllButton type="submit">Copy all</CopyAllButton>
      </Component>
    </form>
  );
};

export default withFormClipboard;
