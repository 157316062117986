import { connect } from 'react-redux';

import { hideModal } from 'app-state/actions';

import { ErrorOutline } from 'svg-icons';

import {
  ModalIconContainer,
  ModalText,
  ModalTitle,
  ModalWrapper,
  SubmitButton,
} from './modal-response-failed.styled';

const ModalResponseFailed = ({ close }) => (
  <ModalWrapper>
    <ModalIconContainer>{ErrorOutline()}</ModalIconContainer>
    <ModalTitle>RESPONSE FAILED</ModalTitle>
    <ModalText>Your response failed to send. Please try again</ModalText>
    <SubmitButton onClick={close}>OK</SubmitButton>
  </ModalWrapper>
);

const mapDispatchToProps = dispatch => ({
  close: () => dispatch(hideModal()),
});

export default connect(null, mapDispatchToProps)(ModalResponseFailed);
