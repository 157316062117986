interface InfoOutlinedProps {
  width?: number;
  height?: number;
  fillColor?: string;
}

const InfoOutlined = ({ width = 17, height = 17, fillColor }: InfoOutlinedProps) => (
  <svg
    width={width}
    height={height}
    viewBox="0 0 17 17"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <circle cx="8.5" cy="8.5" r="6.375" stroke={fillColor} />
    <path
      d="M8.85384 5.3112C8.85384 5.5068 8.69528 5.66536 8.49967 5.66536C8.30407 5.66536 8.14551 5.5068 8.14551 5.3112C8.14551 5.1156 8.30407 4.95703 8.49967 4.95703C8.69528 4.95703 8.85384 5.1156 8.85384 5.3112Z"
      fill={fillColor}
      stroke={fillColor}
    />
    <path d="M8.5 12.0404V7.08203" stroke={fillColor} />
  </svg>
);

export default InfoOutlined;
