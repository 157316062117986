import { FC, forwardRef, ReactNode, useId } from 'react';

import { Box } from '@mui/material';
import { styled, useThemeProps } from '@mui/material/styles';

import { Chip } from '../chip';

// TODO: Tighten up these types so that number props aren't available if value is not number
type StatValue = string | number | bigint | Date;
export type StatProps<TValue extends StatValue = StatValue> = {
  /**
   * The underlying value of the stat.
   */
  value: TValue;
  /**
   * The label that describes what the value represents.
   */
  label: string;
  /**
   * An additional description for the value, if you wish to provide additional information.
   */
  subtitle?: string;
  /**
   * An optional tag to display next to the value. Useful for augmenting the value, such as % increase.
   */
  tag?: string | ReactNode;
};

type StatOwnerState = StatProps;

const StatRoot = styled('div', {
  name: 'MuiStat',
  slot: 'root',
})<{ ownerState: StatOwnerState }>(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  gap: theme.spacing(2),
  minWidth: '200px',
}));

const StatValue = styled('div', {
  name: 'MuiStat',
  slot: 'value',
})<{ ownerState: StatOwnerState }>(({ theme }) => ({
  ...theme.typography.h2,
}));

const StatLabel = styled('div', {
  name: 'MuiStat',
  slot: 'label',
})<{ ownerState: StatOwnerState }>(({ theme }) => ({
  ...theme.typography.bodyAllCaps,
}));

const StatSubtitle = styled('div', {
  name: 'MuiStat',
  slot: 'subtitle',
})<{ ownerState: StatOwnerState }>(({ theme }) => ({
  ...theme.typography.body2,
  color: theme.palette.neutral[500],
}));

const numberFormatter = new Intl.NumberFormat(undefined, {});

export const Stat = forwardRef<HTMLDivElement, StatProps<string | number>>((inProps, ref) => {
  const id = useId();
  const props = useThemeProps({ props: inProps, name: 'MuiStat' });
  const { value, label, subtitle, tag } = props;

  const formattedValue = typeof value === 'number' ? numberFormatter.format(value) : value;

  return (
    <StatRoot ref={ref} ownerState={props}>
      {/* Should probably have this composable to avoid these assumptions but eh */}
      <StatLabel id={`stat-${id}`} ownerState={props}>
        {label}
      </StatLabel>

      <Box aria-labelledby={`stat-${id}`} display="flex" alignItems="center" gap={2}>
        <StatValue ownerState={props}>{formattedValue}</StatValue>
        {tag && (typeof tag === 'string' ? <Chip label={tag} color="blue" /> : tag)}
      </Box>

      {subtitle && <StatSubtitle ownerState={props}>{subtitle}</StatSubtitle>}
    </StatRoot>
  );
});

type CurrencyStatProps = { currency: string; formatOptions?: Intl.NumberFormatOptions } & StatProps<
  string | number
>;
export const CurrencyStat: FC<CurrencyStatProps> = ({
  formatOptions,
  currency,
  value,
  ...props
}) => {
  const formatter = new Intl.NumberFormat(undefined, {
    style: 'currency',
    currency,
    ...formatOptions,
  });

  return (
    <Stat {...props} value={formatter.format(typeof value === 'string' ? Number(value) : value)} />
  );
};

type DateStatProps = StatProps<number | Date | string> & {
  formatOptions?: Intl.DateTimeFormatOptions;
};
export const DateStat: FC<DateStatProps> = ({ formatOptions, value, ...props }) => {
  const formatter = new Intl.DateTimeFormat(undefined, { dateStyle: 'short', ...formatOptions });

  const date = typeof value === 'string' ? new Date(value) : value;

  return <Stat {...props} value={formatter.format(date)} />;
};

type PercentageStatProps = { formatOptions?: Intl.NumberFormatOptions } & StatProps<number>;
export const PercentageStat: FC<PercentageStatProps> = ({ value, formatOptions, ...props }) => {
  const formatter = new Intl.NumberFormat(undefined, { ...formatOptions, style: 'percent' });

  return <Stat {...props} value={formatter.format(value)} />;
};
