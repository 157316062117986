import { CSSProperties, FC, PropsWithChildren } from 'react';

import styled from 'styled-components';

import { FeatureFlag, useFeatureFlag } from 'providers';
import { getTenantConfig } from 'helpers/get-tenant-config';
import GFIPoweredByGlobacap from 'shared-parts/components/gfi-powered-by-globacap';

const {
  custom: { authScreen },
} = getTenantConfig();

const OuterContainer = styled.div<Pick<CSSProperties, 'backgroundColor'>>`
  align-items: center;
  background-color: ${({ backgroundColor }) => backgroundColor};
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding: ${authScreen.containerPadding ?? '20px 0'};
  min-height: 100vh;
`;

const FooterContainer = styled.div`
  position: relative;
  width: 100%;
`;

const AuthContainer: FC<PropsWithChildren<Pick<CSSProperties, 'backgroundColor'>>> = ({
  children,
}) => {
  const poweredByImageHidden = useFeatureFlag(FeatureFlag.IS_GFI_POWERED_BY_GLOBACAP_IMAGE_HIDDEN);

  return (
    <OuterContainer>
      {children}
      <FooterContainer>{!poweredByImageHidden && <GFIPoweredByGlobacap />}</FooterContainer>
    </OuterContainer>
  );
};

export default AuthContainer;
