import { FC } from 'react';
import { useSelector } from 'react-redux';

import { ApolloClient, ApolloProvider, InMemoryCache } from '@apollo/client';
import { GraphQLClient } from 'graphql-request';

import { useEnvVar } from '@frontend/config';

import { RootState } from 'app-state/store';

export const useGraphQLClient = (url = String(useEnvVar('GRAPHQL_ENDPOINT'))) => {
  const authToken = useSelector<RootState>(
    state => (state.session as { user: { authToken: string } }).user?.authToken,
  );

  return new GraphQLClient(url, {
    headers: {
      'x-auth-token': String(authToken),
    },
  });
};

const AuthenticatedGqlProvider: FC<React.PropsWithChildren<unknown>> = ({ children }) => {
  const authToken = useSelector<RootState>(
    state => (state.session as { user: { authToken: string } }).user?.authToken,
  );

  const client = new ApolloClient({
    uri: window.config.GRAPHQL_ENDPOINT as string,
    cache: new InMemoryCache(),
    headers: {
      // This could be undefined, but we just let it roll so that the provider still exists
      // even if a user is unauthenticated.
      'x-auth-token': authToken as string,
    },
  });

  return <ApolloProvider client={client}>{children}</ApolloProvider>;
};

export default AuthenticatedGqlProvider;
