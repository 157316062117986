import { CSSProperties, FC } from 'react';

import styled from 'styled-components';

import { FooterLinks } from 'shared/footer/footer';

const OuterContainer = styled.div<Pick<React.CSSProperties, 'backgroundColor'>>`
  align-items: center;
  background-color: #fff;
  display: flex;
  flex-direction: column;
  justify-content: center;
  min-height: 100vh;
`;

const AuthContainer: FC<React.PropsWithChildren<Pick<CSSProperties, 'backgroundColor'>>> = ({
  children,
}) => (
  <OuterContainer>
    {children}
    <FooterLinks withPaddings={false} isAuth />
  </OuterContainer>
);

export default AuthContainer;
