import { useContext, useState } from 'react';

import { ComponentsContext } from 'shared-parts/modules/deal/deal.context';

import { Box, ComponentContainer, Container, ContentColumn } from './section-regular.styled';

const renderComponent = ({
  box,
  item,
  isImageExpanded,
  setExpandedBoxImageId,
  dealUuid,
  companyUuid,
}) => {
  const {
    BoxText,
    RegularTeamComponent,
    BoxVideo,
    BoxTable,
    BoxDescriptionList,
    BoxImage,
    BoxFile,
  } = useContext(ComponentsContext);

  switch (box.type) {
    case 'TextBox':
      return <BoxText {...box} />;
    case 'TeamBox':
      return <RegularTeamComponent {...box} dealUuid={dealUuid} companyUuid={companyUuid} />;
    case 'VideoBox':
      return <BoxVideo {...box} section={item} />;
    case 'TabularDataBox':
      return <BoxTable {...box} />;
    case 'DescriptionListBox':
      return <BoxDescriptionList {...box} />;
    case 'ImageBox':
      return (
        <BoxImage
          {...box}
          isImageExpanded={isImageExpanded}
          handleImageClick={setExpandedBoxImageId}
          sectionId={item.id}
        />
      );
    case 'DocumentsBox':
      return <BoxFile {...box} />;
    default:
      return <div>Unknown box</div>;
  }
};

const SectionRegular = ({ item = {}, dealUuid, companyUuid }) => {
  const [expandedBoxImageId, setExpandedBoxImageId] = useState();
  const { SectionTitle, RegularSectionWrapper, Tagline } = useContext(ComponentsContext);

  const { boxes, title } = item;
  const forceDisplayTagline = boxes.some(box => box.tagline?.content);
  const vDomBoxes = boxes.map(box => {
    const key = `${box.id}${box.type}`;
    const isImageExpanded = expandedBoxImageId === box.id;

    const componentOptions = {
      box,
      item,
      setExpandedBoxImageId,
      isImageExpanded,
      dealUuid,
      companyUuid,
    };

    return (
      <ComponentContainer key={key} isImageExpanded={isImageExpanded}>
        {box.sectionBoxId !== 100500 && ( // remove after updating createEmptyVideoComponent
          <Tagline
            tagline={box.tagline || {}}
            sectionBoxId={box.sectionBoxId}
            isImageExpanded={isImageExpanded}
            issuanceUuid={dealUuid}
            forceDisplayTagline={forceDisplayTagline}
          />
        )}
        <Box>{renderComponent(componentOptions)}</Box>
      </ComponentContainer>
    );
  });

  return (
    <Container>
      <RegularSectionWrapper item={item} dealUuid={dealUuid} companyUuid={companyUuid}>
        <SectionTitle item={item}>
          <span dangerouslySetInnerHTML={{ __html: title }} />
        </SectionTitle>
        <ContentColumn>{vDomBoxes}</ContentColumn>
      </RegularSectionWrapper>
    </Container>
  );
};

export default SectionRegular;
