import {
  FETCH_INVESTOR_COMPANIES,
  FETCH_INVESTOR_COMPANIES_ERROR,
  FETCH_INVESTOR_COMPANIES_SUCCESS,
  RESET_INVESTOR_COMPANIES,
} from './constants';

const investorCompaniesInitialState = {
  loading: true,
  data: [],
  error: null,
};

// eslint-disable-next-line import/prefer-default-export
export const investorCompaniesReducer = (
  state = investorCompaniesInitialState,
  { type, data, error },
) => {
  switch (type) {
    case FETCH_INVESTOR_COMPANIES:
      return investorCompaniesInitialState;
    case FETCH_INVESTOR_COMPANIES_SUCCESS:
      return { loading: false, data, error: null };
    case FETCH_INVESTOR_COMPANIES_ERROR:
      return { loading: false, data: [], error };
    case RESET_INVESTOR_COMPANIES:
      return investorCompaniesInitialState;
    default:
      return state;
  }
};
