import MuiLink, { LinkProps as MuiLinkProps } from '@mui/material/Link';
import { styled } from '@mui/material/styles';

export type LinkProps = MuiLinkProps & { disabled?: boolean };

export const Link = styled(MuiLink)<LinkProps>(({ theme, ...props }) => ({
  textTransform: 'uppercase',

  ...(props.disabled && {
    color: theme.palette.grey[400],
    pointerEvents: 'none',
  }),

  '&:hover': {
    color: theme.palette.info.main,
  },

  '&:visited': {
    color: theme.palette.purple.main,
  },
}));
