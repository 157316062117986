import * as Colors from 'shared-parts/constants/colors';

const AttachmentIcon = (width = '18', height = '11', fillColor = Colors.primary) => (
  <svg xmlns="http://www.w3.org/2000/svg" width={width} height={height} viewBox="0 0 22 12">
    <g fill={fillColor} fillRule="nonzero" transform="translate(-626 -2228)">
      <path d="M642.252 2238h-10.58c-2.09 0-3.95-1.53-4.15-3.61a3.998 3.998 0 0 1 3.98-4.39h12.36c1.31 0 2.5.94 2.63 2.24a2.5 2.5 0 0 1-2.49 2.76h-10.5c-.55 0-1-.45-1-1s.45-1 1-1h8.75c.41 0 .75-.34.75-.75s-.34-.75-.75-.75h-8.61c-1.31 0-2.5.94-2.63 2.24a2.5 2.5 0 0 0 2.49 2.76h10.33c2.09 0 3.95-1.53 4.15-3.61a3.993 3.993 0 0 0-3.98-4.39h-12.27c-2.87 0-5.44 2.1-5.71 4.96a5.505 5.505 0 0 0 5.48 6.04h10.75c.41 0 .75-.34.75-.75s-.34-.75-.75-.75z" />
    </g>
  </svg>
);

export default AttachmentIcon;
