import styled from 'styled-components';

import { neutral200, neutral500, primary } from 'shared-parts/constants/colors';

export default styled.button`
  display: inline-flex;
  align-items: center;
  justify-content: center;
  padding: 12px 20px;
  background-color: ${neutral200};
  border: none;
  cursor: ${({ disabled }) => (disabled ? 'auto' : 'pointer')};
  font-size: 14px;
  color: ${({ disabled }) => (disabled ? neutral500 : primary)};
  border-radius: 4px;
  height: 40px;
`;
