import { forwardRef } from 'react';

import InfoIcon from 'shared-parts/components/svg-icons/info-icon';
import { Colors } from 'shared-parts/constants';
import { alternative } from 'shared-parts/constants/colors';

import {
  StyledTooltip,
  TableHeader,
  TableHeaderCell,
  TableHeaderText,
  TableRow,
  TooltipMessage,
} from '../table.styled';

import Arrow from './sorting-arrow';

const Header = forwardRef(
  ({ header = [], fixedHeader, sortingData, updateSortingData, title }, refTableHeader) =>
    header.length ? (
      <TableHeader ref={refTableHeader}>
        <TableRow header>
          {header.map(({ styles = '', name, colspan, sorting, description, tooltipText }, i) => (
            <TableHeaderCell
              key={i}
              colSpan={colspan}
              styles={styles}
              fixedHeader={fixedHeader}
              title={title}
            >
              <TableHeaderText onClick={sorting && updateSortingData(sorting)}>
                <Arrow
                  color={title ? Colors.primary : Colors.white}
                  sorting={sorting}
                  sortingData={sortingData}
                />
                {name}
                {description ? (
                  <>
                    <br />
                    <small>{description}</small>
                  </>
                ) : null}
                {tooltipText && (
                  <StyledTooltip
                    content={() => <TooltipMessage>{tooltipText}</TooltipMessage>}
                    arrowColor={alternative}
                    position="bottom-middle"
                    autohide={false}
                  >
                    {() => <InfoIcon />}
                  </StyledTooltip>
                )}
              </TableHeaderText>
            </TableHeaderCell>
          ))}
        </TableRow>
      </TableHeader>
    ) : null,
);

export default Header;
