import type { FC, MouseEvent } from 'react';

import classNames from 'classnames';

import { AdditionalText, Wrapper } from './link.styled';
import NavLinkOrAnchor from './linkOrAnchor';

const renderAdditionalText = (text: string) => text && <AdditionalText>{text}</AdditionalText>;

export type LinkProps = {
  url: string;
  title?: string;
  additionalText?: string;
  noUnderline?: boolean;
  color?: string;
  bold?: boolean;
  fontSize?: string;
  hoverColor?: string;
  className?: string;
  onClick?: (e?: MouseEvent<HTMLAnchorElement>) => void;
  target?: string;
  rel?: string;
  id?: string;
  disabled?: boolean;
};

const Link: FC<React.PropsWithChildren<LinkProps>> = ({
  url = '',
  title = '',
  additionalText = '',
  noUnderline,
  color,
  bold,
  fontSize,
  hoverColor,
  className = '',
  onClick,
  target,
  rel,
  id,
  children,
  disabled,
}) => {
  const isMailTo = url.substring(0, 6) === 'mailTo';
  const classes = classNames('link', className);

  return (
    <Wrapper
      fontSize={fontSize}
      className={classes}
      color={color}
      bold={bold}
      hoverColor={hoverColor}
      noUnderline={noUnderline}
      disabled={disabled}
    >
      {renderAdditionalText(additionalText)}
      <NavLinkOrAnchor
        id={id}
        target={target}
        isMailTo={isMailTo}
        url={url}
        onClick={onClick}
        title={title}
        rel={rel}
      >
        {children}
      </NavLinkOrAnchor>
    </Wrapper>
  );
};

export default Link;
