import { sessionService } from 'redux-react-session';

const pushEvent = (data, event = 'GCJavascriptError', user = {}) => {
  dataLayer.push({
    navigator: {
      appCodeName: navigator.appCodeName,
      appName: navigator.appName,
      userAgent: navigator.userAgent,
      language: navigator.language,
      platform: navigator.platform,
      doNotTrack: navigator.doNotTrack,
      vendor: navigator.vendor,
      cookieEnabled: navigator.cookieEnabled,
    },
    user: {
      email: user.email,
      id: user.id,
    },
    pathname: location.pathname,
    event,
    ...data,
  });
};

export default (data, event, user) => {
  if (user) {
    return pushEvent(data, event, user);
  }
  sessionService
    .loadUser()
    .then(sessionUser => pushEvent(data, event, sessionUser))
    .catch(error => {
      if (error === 'User not found') {
        return pushEvent(data, event);
      }

      return Promise.reject(error);
    })
    .catch(error => console.log(error));
};
