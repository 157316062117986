import { styled } from '@mui/material';

import { Screens } from './trade-offs';

export const Text = styled('span')`
  @media only screen and (min-width: ${Screens.sm}) {
    font-size: 14px;
  }
  @media only screen and (max-width: ${Screens.sm}) {
    font-size: 13px;
  }
`;

export const Value = styled('span')<{ oswaldFont: string }>`
  display: block;
  font-family: ${({ oswaldFont }) => oswaldFont};
  height: 50%;
  @media only screen and (min-width: ${Screens.sm}) {
    font-size: 17px;
  }
  @media only screen and (max-width: ${Screens.sm}) {
    font-size: 14px;
    display: inline-block;
    padding-left: 10px;
  }
`;
export const Target = styled('div')`
  width: 100%;
`;
