import { styled, TextField as MuiTextField } from '@mui/material';
import {
  gridPreferencePanelStateSelector,
  GridPreferencePanelsValue,
  GridTypeFilterInputValueProps,
  useGridApiContext,
  useGridSelector,
} from '@mui/x-data-grid-premium';

import { Input } from '../../input';

export const PaginationSelectInput = styled(Input)(({ theme }) => ({
  width: 'initial',
  height: '40px',
  '.MuiSelect-select': {
    color: theme.palette.neutral[600],
    display: 'flex',
    alignItems: 'center',
  },
}));

// Mega hacks alert!!
// This lets us use our own input for columns, but it doesn't quite work out for
// filters, as we don't really support standard variants. Therefore, for column panels,
// we give the custom input. Anything else gets the standard textfield.

// TODO: Support standard variant either on the design system input, or here
export const DataGridInput = (props: GridTypeFilterInputValueProps) => {
  const apiRef = useGridApiContext();
  const panelState = useGridSelector(apiRef, gridPreferencePanelStateSelector);

  if (panelState.openedPanelValue === GridPreferencePanelsValue.columns) {
    // Pick only relevant props. Kinda bad as we don't pass in
    // additional props via the slot props. Could be the wrong props type
    const { inputRef, id, onChange, placeholder, type, value, fullWidth, InputProps } = props;
    return (
      <Input
        id={id}
        inputRef={inputRef}
        onChange={onChange}
        placeholder={placeholder}
        type={type}
        value={value}
        fullWidth={fullWidth}
        {...InputProps}
      />
    );
  }

  return <MuiTextField {...props} variant="standard" />;
};
