import { GET_ISSUER, GET_ISSUER_ERROR, GET_ISSUER_SUCCESS } from 'app-state/constants/issuer';

type IssuerState = {
  data?: any;
  errors: {
    status: number;
    response: { details: string }[];
  } | null;
  loading: boolean;
};

type IssuerAction = {
  type: string;
  payload: {
    data: any;
    error?: any;
  };
};

export const defaultIssuerState: IssuerState = {
  data: {},
  errors: null,
  loading: false,
};

export const issuerReducer = (state = defaultIssuerState, action: IssuerAction): IssuerState => {
  switch (action.type) {
    case GET_ISSUER:
      return { ...state, loading: true };
    case GET_ISSUER_SUCCESS:
      return { ...state, data: action.payload.data, loading: false };
    case GET_ISSUER_ERROR:
      return { ...state, errors: action.payload.error, loading: false };
    default:
      return state;
  }
};
