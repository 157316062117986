import { FC, ReactNode } from 'react';

import {
  StepLabel as MuiStepLabel,
  StepLabelProps as MuiStepLabelProps,
  styled,
  useTheme,
} from '@mui/material';

import { StepIconComponent, StepIconComponentProps } from './step-icon-component';

export interface Step {
  label: string;
  status: StepStatus;
}

export enum StepStatus {
  COMPLETED = 'completed',
  IN_PROGRESS = 'inProgress',
  NOT_STARTED = 'notStarted',
}

export interface StepLabelProps {
  index: number;
  isActive: boolean;
  status: StepStatus;
  children: ReactNode;
  stepIconProps?: Partial<StepIconComponentProps>;
}

interface StyledStepLabelProps extends MuiStepLabelProps {
  stepcolor: string;
}

const StyledStepLabel = styled(MuiStepLabel)<StyledStepLabelProps>(({ theme, stepcolor }) => ({
  '&.MuiStepLabel-vertical': {
    paddingTop: '0',
    paddingBottom: '16px',
  },
  '&.MuiStepLabel-vertical:last-child': {
    paddingBottom: 0,
  },
  '& .MuiStepLabel-labelContainer .MuiStepLabel-label': {
    color: stepcolor,
    fontSize: '16px',
    fontWeight: '500',
  },
  '& .MuiStepLabel-iconContainer': {
    paddingRight: '12px',
    '& .MuiStepIcon-root': {
      stroke: stepcolor,
      color: theme.palette.neutral[0],
      overflow: 'visible',
    },
    '& .MuiStepIcon-text': {
      fill: stepcolor,
      fontWeight: '500',
    },
  },
}));

export const StepLabel: FC<StepLabelProps> = ({
  index,
  isActive,
  status,
  children,
  stepIconProps,
}) => {
  const theme = useTheme();
  const colorMap = {
    [StepStatus.COMPLETED]: theme.palette.success.dark,
    [StepStatus.IN_PROGRESS]: theme.palette.warning.dark,
    [StepStatus.NOT_STARTED]: theme.palette.neutral[500],
  };
  const stepcolor = colorMap[status];
  return (
    <StyledStepLabel
      stepcolor={stepcolor}
      StepIconComponent={() =>
        StepIconComponent({
          label: String(index + 1),
          isActive,
          color: stepcolor,
          fillColor: theme.palette.neutral[0],
          icon: String(index + 1),
          ...stepIconProps,
        })
      }
    >
      {children}
    </StyledStepLabel>
  );
};
