import styled from 'styled-components';

import { getTenantConfig } from 'helpers/get-tenant-config';
import { Column } from 'shared-parts/components';
import { primary } from 'shared-parts/constants/colors';

const {
  custom: {
    header: { backgroundColor },
  },
} = getTenantConfig();

const HeaderWrapper = styled.div`
  background: ${backgroundColor ?? primary};
  user-select: none;
`;

const Row = styled.div`
  display: flex;
  flex-wrap: wrap;
`;

const StyledColumn = styled(Column)`
  ${({ alignItems }) => alignItems && `align-items: ${alignItems};`}
  display: flex;
  min-height: 63px;
  justify-content: ${({ alignment }) => alignment};
`;

export { HeaderWrapper, Row, StyledColumn };
