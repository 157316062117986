import { AsyncState } from 'helpers/redux.helpers';

export type Formatter = {
  field: string;
  options: Record<string, any>;
};

export type GraphXlPayload = {
  query: string;
  variables?: { [index: string]: any };
  rename?: { [index: string]: any };
  ignore?: string[];
  formatter?: Formatter[];
};

export enum JobStatus {
  Queued = 'Queued',
  Processing = 'Processing',
  Completed = 'Completed',
  Failed = 'Failed',
  Initial = 'Initial',
}

export type DownloadCSVStateType = {
  job: AsyncState<{
    id: string | undefined;
    status?: JobStatus;
    url: string | undefined;
  }>;
};

const initialState: DownloadCSVStateType = {
  job: {
    loading: false,
    error: undefined,
    data: {
      id: undefined,
      status: undefined,
      url: undefined,
    },
  },
};

export default initialState;
