import { useCallback } from 'react';

import request from 'shared-parts/helpers/request';

import { PLAID_ACCESS_TOKEN_STORAGE_KEY } from '../constants';

import { CachedFetchState, useCachedFetch } from './use-cached-fetch';

export type AccessToken = { accessToken: string; itemId: string; expires: string };
export type FetchPlaidAccessTokenState = Omit<CachedFetchState, 'value'> & {
  token: CachedFetchState['value'];
};

export function usePlaidAccessToken(): FetchPlaidAccessTokenState {
  const fetchAccessToken = useCallback(
    (publicToken: string) =>
      request(`/api/v1/money_box/access_token?public_token=${publicToken}`, 'POST'),
    [],
  );

  const { value, ...rest } = useCachedFetch<AccessToken>(
    PLAID_ACCESS_TOKEN_STORAGE_KEY,
    token => token.expires,
    token => token.accessToken,
    fetchAccessToken,
  );

  return { token: value, ...rest };
}
