import styled from 'styled-components';

import * as Colors from 'shared-parts/constants/colors';
import BackIcon from 'shared-parts/images/back-arrow.svg';
import BackIconWhite from 'shared-parts/images/back-arrow-white.svg';
import NextIcon from 'shared-parts/images/next-arrow.svg';

import Button from './formik-button';

const commonStyles = `
  content: '';
  display: inline-block;
  height: 12px;
  vertical-align: top;
  width: 7.5px;
`;

const FormButton = styled(Button)`
  height: 40px;
  margin: 52px 0 12px 0;
`;

const NextFormButton = styled(FormButton)`
  &:after {
    ${commonStyles}
    margin-left: 6px;
    background: url(${NextIcon}) no-repeat;
  }
`;

const BackFormButton = styled(FormButton)`
  background: ${Colors.white};
  color: ${Colors.alternative};
  border: 1px solid ${Colors.alternative};

  &:before {
    ${commonStyles}
    margin-right: 6px;
    background: url(${BackIcon}) no-repeat;
  }
  &:hover {
    background: ${Colors.alternative};
    color: ${Colors.alternativeContrastText};

    &:before {
      ${commonStyles}
      background: url(${BackIconWhite}) no-repeat;
    }
  }
  &:disabled {
    background: ${Colors.neutral800};
    border: 1px solid transparent;
    color: ${Colors.white};
    &:before {
      ${commonStyles}
      background: url(${BackIconWhite}) no-repeat;
    }
    &:hover {
      background: ${Colors.neutral800};
      border: 1px solid transparent;
      color: ${Colors.white};
      &:before {
        ${commonStyles}
        background: url(${BackIconWhite}) no-repeat;
      }
    }
  }
`;

export { FormButton, NextFormButton, BackFormButton };
