import styled from 'styled-components';

import * as Colors from 'shared-parts/constants/colors';
import * as Fonts from 'shared-parts/constants/fonts';
import { Screens } from 'shared-parts/constants/screens';
/* eslint-disable */
const StyledBubble = styled.div`
  width: ${({ size = '20px' }) => size};
  height: ${({ size = '20px' }) => size};
  border-radius: 15px;
  background-color: ${({ backgroundColor = Colors.systemRed }) => backgroundColor};
  color: ${Colors.white};
  font-family: ${Fonts.mainFont};
  font-size: 14px;
  position: absolute;
  top: 4px;
  right: 2px;
  text-align: center;

  @media (max-width: ${Screens.xl}) {
    top: ${({ center }) =>
      center
        ? `50%;
         transform: translateY(-50%);
        `
        : '4px;'};
  }
`;

export { StyledBubble };
/* eslint-enable */
