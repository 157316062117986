import { usePlatformSettings } from '@frontend/config';
import { OrderStatus } from '@frontend/orders';

import { getTimezoneLocalDateString, toDefinedFractionSizeNoRound } from 'helpers';

import { hasPendingOrderAdjustment } from '../../modules/orders/order/has-pending-order-adjustment';

import withFormClipboard from './hocs/with-form-clipboard';
import {
  AMOUNT_DEFAULT_FIELD_PROPS,
  BANK_FIELD_LABELS,
  ORDERED_FIELDS,
  WALLET_DEPOSIT_DEFAULT_FIELD_PROPS,
} from './constants';
import {
  OrderTransfer,
  OrderTransferBankDetails,
  OrderTransferCopyField,
  OrderTransferGeneral,
  OrderTransferGeneralRow,
  OrderTransferLabel,
  OrderTransferNotion,
  OrderTransferProp,
  OrderTransferSpecific,
  OrderTransferTopBank,
  OrderTransferTopMessage,
} from './transfer-details.styled';

const shouldRemoveSpaces = fieldName =>
  fieldName === BANK_FIELD_LABELS.accountNumber || fieldName === AMOUNT_DEFAULT_FIELD_PROPS.field;

const TransferDetails = ({
  bank = {},
  walletsDeposit = {},
  hideGeneralNotion = false,
  shouldBankAccountsBeShown = true,
  order,
  message,
  children,
}) => {
  const { agentBank } = bank;
  const referenceCode = { reference: walletsDeposit.reference };
  const { regulatoryEnvironment } = usePlatformSettings();
  const isFINRA = regulatoryEnvironment.value === 'FINRA';

  const OrderTransferInstructions = () => {
    const getTransferDetailsMsg = ({
      openAmount,
      currency,
      filledAmount,
      status,
      mustBeFilledBy,
      timezone,
    }) => {
      const mustBeFilledByDate =
        mustBeFilledBy &&
        getTimezoneLocalDateString({
          date: mustBeFilledBy,
          timezone,
          withTime: true,
        });
      const FilledBy = () =>
        mustBeFilledBy ? (
          <>
            {' '}
            before <b>{mustBeFilledByDate}</b>
          </>
        ) : null;
      return status === OrderStatus.PLACED ? (
        <span>
          To complete your {isFINRA ? 'investment' : 'order'}, please transfer&nbsp;
          <b>
            {currency}{' '}
            {toDefinedFractionSizeNoRound(
              parseFloat(
                hasPendingOrderAdjustment(order)
                  ? order.latestOrderAdjustment?.totalDue
                  : order.totalDue,
              ),
              2,
            )}
          </b>
          <br />
          to the following account
          <FilledBy />:
        </span>
      ) : (
        <span>
          We have received{' '}
          <b>
            {currency} {toDefinedFractionSizeNoRound(filledAmount, 2)}
          </b>
          .
          <br />
          To complete your {isFINRA ? 'investment' : 'order'}, please transfer the remaining{' '}
          <b>
            {currency} {toDefinedFractionSizeNoRound(openAmount, 2)}
          </b>
          &nbsp; to the following account
          <FilledBy />:
        </span>
      );
    };

    if (message) {
      return <OrderTransferTopMessage>{message}</OrderTransferTopMessage>;
    }

    if (!order) {
      return null;
    }

    return <OrderTransferTopMessage>{getTransferDetailsMsg(order)}</OrderTransferTopMessage>;
  };

  const OrderGeneralNotion = () =>
    !hideGeneralNotion && (
      <div>
        <OrderTransferNotion>
          You will receive a confirmation by email once we receive the funds. Your{' '}
          {isFINRA ? 'investment' : 'order'} is confirmed when payment is received. If payment is
          received after the capital raise target has been reached, then your payment will be
          returned. If payment is only partially received, then your{' '}
          {isFINRA ? 'investment' : 'order'} will be confirmed proportionally to the amount of
          payment received.
        </OrderTransferNotion>
      </div>
    );

  const getBankFields = bankProps => {
    const bankFields = Object.keys(bankProps || {})
      .filter(key => bankProps[key] && !ORDERED_FIELDS.includes(key))
      .map(key => ({ name: key, field: BANK_FIELD_LABELS[key], value: bankProps[key] }));

    const result = [bankFields[0], ...bankFields.slice(1)];
    const remainingAmount = hasPendingOrderAdjustment(order)
      ? order.latestOrderAdjustment?.totalDue
      : order.totalDue;
    if (remainingAmount) {
      result.push({ ...AMOUNT_DEFAULT_FIELD_PROPS, value: remainingAmount });
    }

    return result;
  };

  const renderBankField = ({ field, value, name }) => (
    <OrderTransferProp key={field}>
      <OrderTransferLabel>{field}:</OrderTransferLabel>
      <OrderTransferCopyField
        field={field}
        name={name}
        value={value}
        removeSpaces={shouldRemoveSpaces(name)}
        bank={bank}
      />
    </OrderTransferProp>
  );

  const renderBankDetails = (bankProps, agent) => (
    <OrderTransferTopBank>
      <OrderTransferGeneral>
        <OrderTransferGeneralRow>
          <b>Account type:</b> Client Money
        </OrderTransferGeneralRow>

        <OrderTransferGeneralRow>
          <b>{agent && 'Agent '}Bank name:</b> {bankProps.name}
        </OrderTransferGeneralRow>

        {bankProps.country && (
          <OrderTransferGeneralRow>
            <b>Country:</b> {bankProps.country}
          </OrderTransferGeneralRow>
        )}
      </OrderTransferGeneral>
      <OrderTransferSpecific>{getBankFields(bankProps).map(renderBankField)}</OrderTransferSpecific>
    </OrderTransferTopBank>
  );

  const renderCryptoAddress = () => (
    <OrderTransferProp>
      <OrderTransferCopyField
        value={walletsDeposit.reference}
        name={WALLET_DEPOSIT_DEFAULT_FIELD_PROPS.name}
      />
    </OrderTransferProp>
  );

  const renderCryptoDetails = () => <>{shouldBankAccountsBeShown && renderCryptoAddress()}</>;

  const renderFiatDetails = () => (
    <>
      {shouldBankAccountsBeShown && bank && renderBankDetails({ ...referenceCode, ...bank })}
      {shouldBankAccountsBeShown && agentBank && renderBankDetails(agentBank, true)}
    </>
  );

  const BankTransfer = () => (
    <>
      {bank.name ? renderFiatDetails() : renderCryptoDetails()}
      {children}
    </>
  );

  return (
    <OrderTransfer>
      <OrderTransferBankDetails>
        <OrderTransferInstructions />
        <BankTransfer />
      </OrderTransferBankDetails>

      <OrderGeneralNotion />
    </OrderTransfer>
  );
};

export default withFormClipboard(TransferDetails);
