import { Component, createElement, createRef } from 'react';
import ClickOutside from 'react-click-outside';
import { connect } from 'react-redux';

import { hideModal } from 'app-state/actions';

import * as Colors from 'shared-parts/constants/colors';
import CloseButton from 'shared-parts/images/modal-close-button.svg';
import PurpleCloseButton from 'shared-parts/images/purple-modal-close-button.svg';

import {
  CloseButtonWrapper,
  ModalBody,
  ModalFrame,
  ModalHeader,
  ModalPreview,
  ModalWrapper,
} from './modal.styled';

class Modal extends Component {
  constructor(props) {
    super(props);
    this.modalFrameRef = createRef();
  }

  componentWillUnmount() {
    this.handleHideModal();
  }

  get closeOnClickOutside() {
    const { content } = this.props.modal || {};
    const { closeOnClickOutside = true } = content;
    return closeOnClickOutside;
  }

  handleHideModal = () => {
    const { content } = this.props.modal;

    if ((content || {}).closable) {
      if (content.customHideModal) return content.customHideModal();
      this.props.hideModal();
    }
  };

  handleClickOutside = () => {
    if (this.closeOnClickOutside) {
      this.handleHideModal();
    }
  };

  renderHeader = (header, purpleCloseButton, danger) => {
    const { white, error, accent: blue5 } = Colors;
    const backgroundColor = (purpleCloseButton && white) || (danger && error) || blue5;

    return (
      <ModalHeader backgroundColor={backgroundColor}>
        {header ? <div>{header}</div> : null}
        <CloseButtonWrapper onClick={this.handleHideModal}>
          <img src={purpleCloseButton ? PurpleCloseButton : CloseButton} alt="'Close' button" />
        </CloseButtonWrapper>
      </ModalHeader>
    );
  };

  renderBody = (component, content, showOverflow) => (
    <ModalBody showOverflow={showOverflow}>
      {createElement(component, {
        hideModal: this.props.hideModal,
        modalFrameRef: this.modalFrameRef,
        ...content,
      })}
    </ModalBody>
  );

  renderPreview = preview => (
    <ModalPreview>{createElement(preview.component, preview)}</ModalPreview>
  );

  render() {
    const {
      modal: { show, content, preview },
      showLoader,
    } = this.props;
    const { component, showHeader, header, purpleCloseButton, danger, minHeight, showOverflow } =
      content || {};

    return (
      show &&
      !showLoader && (
        <ModalWrapper role="dialog" closeOnClickOutside={this.closeOnClickOutside}>
          <ClickOutside onClickOutside={this.handleClickOutside}>
            <ModalFrame minHeight={minHeight} ref={this.modalFrameRef} showOverflow={showOverflow}>
              {preview && this.renderPreview(preview)}
              <div hidden={preview}>
                {showHeader ? this.renderHeader(header, purpleCloseButton, danger) : null}
                {component ? this.renderBody(component, content, showOverflow) : null}
              </div>
            </ModalFrame>
          </ClickOutside>
        </ModalWrapper>
      )
    );
  }
}

const mapStateToProps = state => ({
  modal: state.modal,
  showLoader: state.showLoader,
});

const mapDispatchToProps = {
  hideModal,
};

export default connect(mapStateToProps, mapDispatchToProps)(Modal);
