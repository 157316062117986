import styled from 'styled-components';

import Tooltip from 'shared-parts/components/tooltip';
import * as Colors from 'shared-parts/constants/colors';
import * as Fonts from 'shared-parts/constants/fonts';
import { Screens } from 'shared-parts/constants/screens';

const StyledTooltip = styled(Tooltip)`
  display: block;
`;

const Container = styled.div`
  position: relative;
  font-family: ${Fonts.mainFont};
  font-weight: 600;
`;

const Input = styled.input`
  height: 40px;
  width: 100%;
  background-color: transparent;
  padding: 12px 90px 12px 20px;
  border-radius: 0.4rem;
  border: 1px solid ${Colors.neutral500};
  color: ${Colors.primary};
  font-weight: 600;
  cursor: pointer;

  @media (max-width: ${Screens.md}) {
    padding-right: 60px;
  }

  @media (max-width: ${Screens.sm}) {
    padding-right: 50px;
  }
`;

const Button = styled.button`
  position: absolute;
  right: 0;
  top: 0;
  height: 100%;
  width: 90px;
  background-color: ${Colors.alternative};
  color: ${Colors.alternativeContrastText};
  font-size: 14px;
  border-radius: 0 0.4rem 0.4rem 0;
  cursor: pointer;
  text-align: center;
  vertical-align: middle;
  line-height: 40px;
  border: none;

  @media (max-width: ${Screens.md}) {
    width: 60px;
  }

  @media (max-width: ${Screens.sm}) {
    width: 50px;
  }
`;

const ToolTipMessage = styled.div`
  width: 300px;
  background-color: ${Colors.black};
  color: ${Colors.white};
  text-align: center;
  border-radius: 6px;
  padding: 5px 0;
  font-family: ${Fonts.mainFont};
  box-shadow: 0 3px 6px ${Colors.shadowColor};
`;

export { Container, Input, Button, ToolTipMessage, StyledTooltip };
