import { styled } from '@mui/material';

export const Wrapper = styled('div')<{
  fontSize?: string;
  bold?: boolean;
  noUnderline?: boolean;
  secondaryColor: string;
  hoverColor?: string;
  className: string;
}>`
  font-size: ${({ fontSize }) => fontSize || 'inherit'};
  color: ${({ theme }) => theme.palette.primary.main};
  display: inline-block;

  a {
    color: ${({ secondaryColor }) => secondaryColor};
    font-weight: ${({ bold }) => (bold ? 600 : 100)};
    text-decoration: ${({ noUnderline }) => (noUnderline ? 'none' : 'underline')};

    &:active,
    &:focus,
    &:hover {
      color: ${({ hoverColor }) => hoverColor};
      text-decoration: ${({ noUnderline }) => (noUnderline ? 'none' : 'underline')};
    }
  }
`;

export const AdditionalText = styled('span')`
  margin-right: 5px;
`;
