import { push } from 'connected-react-router';
import { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';

import { IconButton } from '@frontend/design-system';
import { CrossIcon } from '@frontend/design-system/icons';
import { useSubmit } from '@frontend/http';

import api from 'constants/api';
import Routes from 'constants/routes';
import Loader from 'shared-parts/components/loader/loader';

const MuinmosVerificationPage = () => {
  //
  const [iFrameLoaded, setIFrameLoaded] = useState(false);
  const { submit, data, isLoading } = useSubmit(api.Onboarding.verifyApplicant());
  const dispatch = useDispatch();
  const onIFrameClose = () => {
    dispatch(push(Routes.Root()));
  };

  useEffect(() => submit(), []);

  // In the event the backend returns a 204 with a blank object we see a blank white screen
  // This may happen if 2FA is enabled on the account used by the API
  if (!isLoading && data && !data?.rootApiUrl) {
    throw new Error('Unable to retrieve assessment');
  }

  return (
    <>
      <IconButton onClick={onIFrameClose} sx={{ position: 'absolute', right: '30px', top: '30px' }}>
        <CrossIcon />
      </IconButton>
      {!iFrameLoaded && !isLoading && <Loader alwaysVisible />}

      <iframe
        allow="camera"
        title="Muinmos Page"
        src={data?.rootApiUrl}
        style={{
          width: '100%',
          height: '100vh',
          border: 0,
        }}
        allowFullScreen
        onLoad={() => setIFrameLoaded(true)}
      />
    </>
  );
};

export default MuinmosVerificationPage;
