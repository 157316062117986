import { FC } from 'react';

import { pluralize } from '@frontend/utils';

import { twentyFourHours } from 'shared-parts/constants/dates';

import {
  Banner,
  BannerContainer,
  BannerPlaceholder,
  Body,
  DaysToGoButton,
  DaysToGoText,
  Description,
  Head,
  Logo,
  LogoContainer,
  LogoPlaceholder,
  Name,
  SecurityLabel,
  SecurityLabelText,
  TileContainer,
  TileWrapper,
} from './round-tile.styled';
import { RoundTileProps } from './types';

export const RoundTile: FC<React.PropsWithChildren<RoundTileProps>> = ({
  name,
  description,
  endDate,
  daysToGoShown,
  logoUrl,
  bannerUrl,
  securityLabelText,
}) => {
  const daysToGo = Math.trunc(
    (new Date(endDate).getTime() - new Date().getTime()) / twentyFourHours,
  );

  return (
    <TileContainer>
      <TileWrapper>
        <Head>
          <SecurityLabel>
            <SecurityLabelText>{securityLabelText}</SecurityLabelText>
          </SecurityLabel>
          <LogoContainer>
            {logoUrl ? <Logo src={logoUrl} alt="Round logo" /> : <LogoPlaceholder />}
          </LogoContainer>
          <BannerContainer>
            {bannerUrl ? <Banner src={bannerUrl} alt="Round banner" /> : <BannerPlaceholder />}
          </BannerContainer>
        </Head>
        <Body>
          <Name>{name}</Name>
          {daysToGoShown && (
            <DaysToGoButton>
              <DaysToGoText>{pluralize('Day', daysToGo <= 0 ? 0 : daysToGo)} To Go</DaysToGoText>
            </DaysToGoButton>
          )}
          <Description>{description}</Description>
        </Body>
      </TileWrapper>
    </TileContainer>
  );
};
