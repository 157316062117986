import styled from 'styled-components';

import { Screens } from 'shared-parts/constants/screens';

const Container = styled.div`
  ${({ xxlWidth, xlWidth, lgWidth, mdWidth, smWidth }) => `
    margin: 0 auto;
    max-width: ${xxlWidth || 1100}px;

    @media(max-width: ${Screens.xxl}) {
      max-width: ${xxlWidth || 1100}px;
    }

    @media(max-width: ${Screens.xl}) {
      max-width: ${xlWidth || 930}px;
    }
  
    @media(max-width: ${Screens.lg}) {
      max-width: ${lgWidth || 690}px;
    }
  
    @media(max-width: ${Screens.md}) {
      max-width: ${mdWidth || 510}px;
    }
  
    @media(max-width: ${Screens.sm}) {
      max-width: ${smWidth || 450}px;
    }
  `}
`;

export default Container;
