import { Link } from 'react-router-dom';

import styled from 'styled-components';

import { Message, Spinner, SpinnerContainer } from 'shared-parts/components/loader/loader.styled';
import * as Colors from 'shared-parts/constants/colors';
import * as Fonts from 'shared-parts/constants/fonts';

const AddButtonWrapper = styled.div`
  ${({ disabled }) => !disabled && 'cursor: pointer'};
  display: inline-flex;
  flex-flow: row nowrap;
  justify-content: flex-start;
  align-items: center;
  width: max-content;
  user-select: none;
  position: relative;

  ${SpinnerContainer} {
    width: 21px;
    ${Message} {
      height: 20px;
      width: 20px;
      left: calc(50% - 10px);
      top: calc(50% - 10px);
    }
    ${Spinner} {
      border: 4px solid ${Colors.neutral500};
      border-top: 4px solid ${Colors.secondary};
      height: 20px;
      width: 20px;
      left: calc(50% - 10px);
      top: calc(50% - 10px);
    }
  }
`;

const AddButton = styled.div`
  background-color: transparent;
  border-radius: 50%;
  ${({ isDisabled }) =>
    isDisabled
      ? `
    border: 2px solid ${Colors.neutral500};
    color: ${Colors.neutral500};
  `
      : `
    border: 2px solid ${Colors.systemGreen};
    color: ${Colors.systemGreen};
  `};
  font-family: ${Fonts.mainFont};
  font-weight: bold;
  font-size: 20px;
  line-height: 17px;
  width: 21px;
  height: 21px;
  text-align: center;
  margin-right: 5px;
`;

const AddButtonText = styled.div`
  color: ${({ isDisabled }) => (isDisabled ? Colors.neutral500 : Colors.primary)};
  font-family: ${Fonts.mainFont};
  font-weight: bold;
  font-size: 14px;
`;

const AddLinkWrapper = styled(Link)`
  align-items: center;
  color: ${Colors.primary};
  cursor: pointer;
  display: flex;
  font-family: ${Fonts.mainFont};
  font-weight: bold;
  font-size: 14px;
  text-decoration: none;
  width: max-content;

  &:hover {
    color: ${Colors.primary};
    text-decoration: none;
  }
`;

export { AddButtonWrapper, AddButton, AddButtonText, AddLinkWrapper };
