import * as Colors from 'shared-parts/constants/colors';

import { SortingArrow, SortingLines } from '../table.styled';

const Arrow = ({ sorting, sortingData, color }) => {
  if (!sorting) return null;

  const { param, asc } = sortingData || {};
  const isSorted = sorting.param === param;

  return sorting.isSortingLinesIcon ? (
    <SortingLines
      isSorted={isSorted}
      rotateDown={isSorted && asc}
      color={isSorted ? Colors.primary : Colors.neutral500}
    />
  ) : (
    <SortingArrow color={color} isSorted={isSorted} rotateDown={isSorted && !asc} />
  );
};

export default Arrow;
