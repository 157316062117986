import { ReactNode } from 'react';

import { StandardButton } from 'shared-parts/components/button';
import FinishIcon from 'shared-parts/components/svg-icons/finish-icon';
import * as Colors from 'shared-parts/constants/colors';

import { Message, ModalTitle, ModalWrapper } from '../shared.styled';

type SuccessModalProps = {
  title: ReactNode;
  additionalText?: ReactNode;
  buttonText?: ReactNode;

  modalWidth?: number;
  messageMaxWidth?: string;
  className?: string;

  iconSize?: number;

  customActionsBlock: () => ReactNode;
  hideModal: () => void;
};

const SuccessModal = ({
  title,
  hideModal,
  additionalText = '',
  buttonText = 'OK',
  modalWidth = 800,
  messageMaxWidth = '100%',
  iconSize = 64,
  className,
  customActionsBlock,
}: SuccessModalProps): JSX.Element => (
  <ModalWrapper modalWidth={modalWidth} className={className}>
    {FinishIcon(iconSize, iconSize, Colors.success)}
    <ModalTitle>{title}</ModalTitle>
    {additionalText && <Message maxWidth={messageMaxWidth}>{additionalText}</Message>}
    {customActionsBlock ? (
      customActionsBlock()
    ) : (
      <StandardButton onClick={hideModal}>{buttonText}</StandardButton>
    )}
  </ModalWrapper>
);

export default SuccessModal;
