import { CAPITAL_FIELDS, FORM_FIELDS } from 'app-state/indication-of-interest/enums';
import { IndicationOfInterest } from 'app-state/indication-of-interest/types';

export const MAIN_BUTTON_TITLE = 'Indicate interest';
export const FORM_MODAL_HEADER = 'Enter the following details to indicate interest';
export const SUCCESS_MODAL_TITLE = 'We’ve sent your indication of interest';
export const SUCCESS_MODAL_TEXT =
  'We’ve sent your indication of interest and will notify you on progress.';
export const ADJUST_SUCCESS_MODAL_TITLE = 'Your indication of interest has been updated';
export const ADJUST_SUCCESS_MODAL_TEXT =
  'We have submitted your updated IOI details, we will notify you on progress.';
export const SUCCESS_MODAL_BUTTON_TEXT = 'Ok';
export const ADD_INTEREST_BUTTON_TEXT = 'Add additional Interest';
export const SUBMIT_BUTTON_TEXT = 'Confirm indication of interest';

export const INTENDED_AMOUNT_FIELD_LABEL = 'Investment Amount:';
// eslint-disable-next-line import/no-unused-modules
export const VALUATION_FIELD_LABEL = 'Estimated Company Valuation:';
export const SPECIAL_CONDITIONS_FIELD_LABEL = 'Special conditions';

export function getDefaultCapitalItem(disableValuationField: boolean) {
  return {
    [CAPITAL_FIELDS.INTENDED_AMOUNT]: '',
    ...(disableValuationField ? {} : { [CAPITAL_FIELDS.VALUATION]: '' }),
  };
}
export function getInitialFormValues(disableValuationField: boolean): IndicationOfInterest {
  return {
    [FORM_FIELDS.ITEMS]: [{ ...getDefaultCapitalItem(disableValuationField) }],
    // [FORM_FIELDS.TERM_SHEET]: null,
    [FORM_FIELDS.SPECIAL_CONDITIONS]: '',
  };
}
