import { createGlobalStyle } from 'styled-components';

import { mainFont, oswaldFont } from 'shared-parts/constants/fonts';

export default createGlobalStyle`
  html, body {
    font-family: ${mainFont}
  }

  h1 {
    font-family: ${oswaldFont};
  }

  h3 {
    font-family: ${mainFont};
  }
`;
