const XIcon = (width = 20, height = 20, color = '#636EB3', handleClick) => (
  <svg
    width={width}
    height={height}
    onClick={handleClick}
    xmlns="http://www.w3.org/2000/svg"
    viewBox="-7196.11 -23073.109 23.22 23.719"
  >
    <g id="Group_1031" data-name="Group 1031" transform="translate(-7195.8 -23072.801)">
      <line
        stroke={color}
        strokeLinecap="round"
        strokeWidth="1.57px"
        x2="21"
        y2="21.5"
        transform="translate(0.8 0.8)"
      />
      <line
        stroke={color}
        strokeLinecap="round"
        strokeWidth="1.57px"
        x1="21"
        y2="21.5"
        transform="translate(0.8 0.8)"
      />
    </g>
  </svg>
);

export default XIcon;
