import * as Colors from 'shared-parts/constants/colors';

const BulkFunctions = ({
  fillColor = Colors.secondary,
  width = '21',
  height = '21',
  isDisabled,
}) => (
  <svg xmlns="http://www.w3.org/2000/svg" width={width} height={height} viewBox="0 0 21 21">
    <path
      fill={isDisabled ? Colors.neutral500 : fillColor}
      d="M12,2A10,10,0,1,0,22,12,10,10,0,0,0,12,2ZM8,17.5A2.5,2.5,0,1,1,10.5,15,2.5,2.5,0,0,1,8,17.5ZM9.5,8A2.5,2.5,0,1,1,12,10.5,2.5,2.5,0,0,1,9.5,8ZM16,17.5A2.5,2.5,0,1,1,18.5,15,2.5,2.5,0,0,1,16,17.5Z"
      transform="translate(-2 -2)"
    />
  </svg>
);

export default BulkFunctions;
