import {
  getGridDateOperators,
  getGridNumericOperators,
  GridValidRowModel,
  GridValueFormatterParams,
} from '@mui/x-data-grid-premium';
import get from 'lodash/fp/get';

import dayjs from '@frontend/datetime';

import { toGraphQLOperator } from './utils/filter-map';
import { GridQueryColDef } from './utils/use-gql-datagrid';

export const createCurrencyColDef = <TRow extends GridValidRowModel = any>(
  currency: string | ((params: GridValueFormatterParams & { row: TRow }) => string) = 'gbp',
  options?: Intl.NumberFormatOptions,
): Partial<GridQueryColDef<TRow>> => {
  return {
    type: 'number',
    filterOperators: getGridNumericOperators().filter(
      op => !['isEmpty', 'isNotEmpty', 'isAnyOf'].includes(op.value),
    ),
    valueFormatter: params => {
      const { value, api, id } = params;

      if (value == null) {
        return value;
      }

      if (id == null) {
        return value;
      }

      let selectedCurrency;

      if (typeof currency === 'function') {
        const row = api.getRow(id);
        selectedCurrency = currency({ ...params, row });
      } else {
        selectedCurrency = currency;
      }

      const formatter = Intl.NumberFormat(undefined, {
        style: 'currency',
        currency: selectedCurrency,
        ...options,
      });

      return formatter.format(params.value);
    },
  };
};

export const createPercentColDef = <TRow extends GridValidRowModel = any>(
  options?: Intl.NumberFormatOptions,
): Partial<GridQueryColDef<TRow>> => {
  return {
    type: 'number',
    filterOperators: getGridNumericOperators().filter(
      op => !['isEmpty', 'isNotEmpty', 'isAnyOf'].includes(op.value),
    ),
    valueFormatter: ({ value }) => {
      if (value == null || value === '') {
        return value;
      }

      const formatter = Intl.NumberFormat(undefined, {
        style: 'percent',
        minimumFractionDigits: 2,
        maximumFractionDigits: 2,
        ...options,
      });

      return formatter.format(value / 100);
    },
  };
};

/**
 * Creates a column definition to display a date value from an ISO string. Can provide
 * custom formats to display time, etc. Locale-aware.
 *
 * See docs for more information and examples: https://day.js.org/docs/en/display/format
 *
 * @param format The format to display the cell value.
 *
 * @example
 * createDateColDef() // 1 Jan 2020 (Obeys browser localisation)
 *
 * @example
 * createDateColDef('DD/MM/YYYY') / 01/01/2020
 *
 * @default
 * 'LL'
 */
export const createDateColDef = <TRow extends GridValidRowModel = any>(
  format = 'll',
): Partial<GridQueryColDef<TRow>> => {
  return {
    type: 'date',
    align: 'right',
    headerAlign: 'right',
    filterOperators: getGridDateOperators().filter(
      op => !['isEmpty', 'isNotEmpty', 'isAnyOf'].includes(op.value),
    ),
    valueGetter: ({ row, field }) => {
      const value = get(field, row);
      if (!value) {
        return value;
      }

      return new Date(value);
    },
    valueFormatter: ({ value }) =>
      value
        ? dayjs(value).format(format ?? undefined) // default: 'll'. if null: ISO format
        : value,
  };
};

export const createNumericStringColDef = <TRow extends GridValidRowModel = any>(
  field: string,
): Partial<GridQueryColDef<TRow>> => {
  return {
    type: 'number',
    filterOperators: getGridNumericOperators().filter(
      op => !['isEmpty', 'isNotEmpty', 'isAnyOf'].includes(op.value),
    ),
    valueGetter: ({ value }) => {
      if (!value) {
        return value;
      }

      return Number(value);
    },
    filterTransformer: (op, value) => {
      return {
        [field]: {
          [toGraphQLOperator(op)]: value == null ? value : value.toString(),
        },
      };
    },
  };
};
