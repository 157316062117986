import styled from 'styled-components';

import * as Colors from 'shared-parts/constants/colors';
import * as Fonts from 'shared-parts/constants/fonts';

const TextButton = styled.span`
  color: ${({ textColor }) => textColor || Colors.primary};
  font-weight: bold;
  cursor: pointer;
  text-decoration: ${({ noUnderline }) => (noUnderline ? '' : 'underline')};
  font-size: 14px;
  line-height: 18px;
  font-family: ${Fonts.mainFont};
`;

export default TextButton;
