import styled from 'styled-components';

import * as COLORS from 'shared-parts/constants/colors';

export const Wrapper = styled.div<{
  fontSize?: string;
  bold?: boolean;
  noUnderline?: boolean;
  color?: string;
  hoverColor?: string;
  className: string;
  disabled?: boolean;
}>`
  font-size: ${({ fontSize }) => fontSize || 'inherit'};
  color: ${COLORS.primary};
  display: inline-block;

  a {
    color: ${({ color }) => color || COLORS.secondary};
    font-weight: ${({ bold }) => (bold ? 600 : 100)};
    text-decoration: ${({ noUnderline }) => (noUnderline ? 'none' : 'underline')};
    text-decoration-color: ${COLORS.secondary};
    ${({ disabled }) =>
      disabled
        ? `
    color: ${COLORS.white};
    pointer-events: none;
    cursor: not-allowed !important;
    text-decoration: none;
  `
        : ''}
    &:active,
    &:focus,
    &:hover {
      color: ${({ hoverColor }) => hoverColor || COLORS.accent};
      text-decoration: ${({ noUnderline }) => (noUnderline ? 'none' : 'underline')};
      text-decoration-color: ${COLORS.secondaryContrastText};
    }
  }
`;

export const AdditionalText = styled.span`
  margin-right: 5px;
`;
