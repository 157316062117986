import { DealStats } from './deal-stats';
import { addCurrencySign, toAbbrInt } from './trade-offs';

interface CompanyStatsProps {
  text: string;
  currency: string;
  amount: number;
  oswaldFont: string;
}

export const CompanyStats = ({ text, currency, amount, oswaldFont }: CompanyStatsProps) => (
  <DealStats oswaldFont={oswaldFont} text={text}>
    {addCurrencySign(toAbbrInt(amount), currency)}
  </DealStats>
);
