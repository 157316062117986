import styled from 'styled-components';

import { Fonts } from 'shared-parts/constants';

export const Title = styled.div<{ titleColor: React.CSSProperties['color'] }>`
  color: ${props => props.titleColor || ''};
  font-family: ${Fonts.oswaldFont};
  font-size: 35px;
  font-weight: 300;
  letter-spacing: 0.1px;
  line-height: 35px;
  margin-bottom: 55px;
`;
