import { select, takeEvery } from 'redux-saga/effects';

import { UPDATE_NICKELLED_USER } from 'app-state/constants/shared';
import { getUser } from 'app-state/selectors/authentication';

function* updateNickelledUser() {
  const user = yield select(getUser);

  window.NickelledLaunchers.setUser({
    appId: window.config.NICKELLED_ID,
    userId: user.uuid,
    email: user.email,
    investorType: user.investorType,
    onboardingStatus: user.investor && user.investor.onboardingStatus,
    isInvestorVerified: user.investor && user.investor.verified,
  });
}

function* updateNickelledUserWatcher() {
  yield takeEvery(UPDATE_NICKELLED_USER, updateNickelledUser);
}

export { updateNickelledUser, updateNickelledUserWatcher };
