import * as constants from 'app-state/constants';

import getReducerState from './get-reducer-state';

export const defaultInvestmentsState = { loading: true, data: [], error: {} };

export const investmentsReducer = (state = defaultInvestmentsState, { type, data, error }) => {
  // eslint-disable-line
  switch (type) {
    case constants.FETCH_INVESTMENTS_DATA:
      return defaultInvestmentsState;
    case constants.FETCH_INVESTMENTS_DATA_SUCCESS:
      return { loading: false, data, error: {} };
    case constants.FETCH_INVESTMENTS_DATA_ERROR:
      return { loading: false, data: [], error };
    case constants.RESET_INVESTMENTS_DATA:
      return defaultInvestmentsState;
    default:
      return state;
  }
};

export const defaultSecondaryInvestmentsState = getReducerState({
  data: {
    items: [],
    meta: { pagination: {} },
  },
});

export const secondaryInvestmentsReducer = (
  state = defaultSecondaryInvestmentsState,
  { type, data, error },
) => {
  // eslint-disable-line
  switch (type) {
    case constants.FETCH_SECONDARY_INVESTMENTS_DATA:
    case constants.RESET_SECONDARY_INVESTMENTS_DATA:
      return defaultSecondaryInvestmentsState;
    case constants.FETCH_SECONDARY_INVESTMENTS_DATA_SUCCESS:
      return getReducerState({ loading: false, data });
    case constants.FETCH_SECONDARY_INVESTMENTS_DATA_ERROR:
      return getReducerState({ loading: false, data: [], error });
    default:
      return state;
  }
};

export const defaultSecondaryInvestmentState = getReducerState({ data: {} });

export const secondaryInvestmentReducer = (
  state = defaultSecondaryInvestmentState,
  { type, data, error },
) => {
  // eslint-disable-line
  switch (type) {
    case constants.FETCH_SECONDARY_INVESTMENT_DATA:
      return defaultSecondaryInvestmentState;
    case constants.FETCH_SECONDARY_INVESTMENT_DATA_SUCCESS:
    case constants.UPDATE_SECONDARY_INVESTMENT_DATA:
      return getReducerState({ loading: false, data });
    case constants.FETCH_SECONDARY_INVESTMENT_DATA_ERROR:
      return getReducerState({ loading: false, data: {}, error });
    default:
      return state;
  }
};

export const defaultSellInvestmentsFormState = { loading: false, data: null, errors: {} };

export const sellInvestmentsFormReducer = (
  state = defaultSellInvestmentsFormState,
  { type, data, errors },
) => {
  switch (type) {
    case constants.SELL_INVESTMENTS:
    case constants.SELL_INVESTMENTS_INFO:
      return { ...state, loading: true };
    case constants.SELL_INVESTMENTS_SUCCESS:
    case constants.SELL_INVESTMENTS_INFO_SUCCESS:
      return { loading: false, data, errors: {} };
    case constants.SELL_INVESTMENTS_ERROR:
    case constants.SELL_INVESTMENTS_INFO_ERROR:
      return { loading: false, data: null, errors };
    case constants.RESET_SELL_INVESTMENTS_FORM:
      return defaultSellInvestmentsFormState;
    default:
      return state;
  }
};
