import styled from 'styled-components';

import { neutral300, neutral800, primary } from 'shared-parts/constants/colors';
import { mainFont, oswaldFont } from 'shared-parts/constants/fonts';
import BiggerRedInfo from 'shared-parts/images/bigger-red-info.svg';

const Wrapper = styled.div`
  display: flex;
  justify-content: center;
`;

const MessageArea = styled.div`
  align-items: center;
  border: 1px solid ${neutral300};
  box-shadow: 0 6px 6px ${neutral300};
  border-radius: 6px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  min-height: 350px;
  margin-top: 14vh;
  padding: 30px;
  width: 600px;
`;

const RedInfoIcon = styled.div`
  background: transparent url('${BiggerRedInfo}') 0 0 no-repeat padding-box;
  height: 50px;
  width: 50px;
`;

const Heading = styled.h1`
  color: ${primary};
  font-family: ${oswaldFont};
  font-size: 35px;
  font-weight: 100;
  margin-top: 25px;
`;

const ErrorMessage = styled.samp`
  color: ${primary};
  font-family: ${mainFont};
  font-weight: bold;
  margin-top: 20px;
  width: 500px;
  word-break: break-word;
`;

const PleaseRefreshMessage = styled.span`
  color: ${neutral800};
  font-family: ${mainFont};
  margin-top: 10px;
`;

const ButtonWrapper = styled.div`
  border-top: 1px solid ${neutral300};
  margin-top: 25px;
  padding-top: 30px;
  text-align: center;
  width: 500px;
`;

export {
  Wrapper,
  MessageArea,
  RedInfoIcon,
  Heading,
  ErrorMessage,
  PleaseRefreshMessage,
  ButtonWrapper,
};
