import { FC } from 'react';

import { useEnvVar } from '@frontend/config';

import { MailLinkWrapper } from './mail-link.styled';

type PropType = {
  text?: string | undefined;
  mail?: string | undefined;
  color?: string;
  hoverColor?: string;
  subject?: string;
  body?: string;
};

const buildEmail = ({ subject, body }: { subject?: string; body?: string }) => {
  let query = '';
  if (subject) {
    query = query.concat(`subject=${subject}`);
  }
  if (body) {
    query = query.concat(`&body=${body}`);
  }
  return query;
};

const MailLink: FC<React.PropsWithChildren<PropType>> = ({
  mail,
  text,
  color,
  hoverColor,
  subject,
  body,
}) => {
  const supportEmail = useEnvVar('SMTP_SUPPORT_EMAIL');
  const email = mail || supportEmail || 'support@globacap.com';
  const displayText = text || supportEmail || 'support@globacap.com';

  return (
    <MailLinkWrapper
      color={color}
      hoverColor={hoverColor}
      href={`mailto:${email}?${buildEmail({ subject, body })}`}
    >
      {displayText}
    </MailLinkWrapper>
  );
};

export default MailLink;
