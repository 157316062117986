import { createContext, PropsWithChildren, useContext } from 'react';

import { createRequestClient, RequestClient } from '../request';

export const HttpRequestClientContext = createContext<RequestClient>(
  createRequestClient({ token: '' }),
);

export type HttpRequestClientProviderOptions = {
  client: RequestClient;
};

export const HttpRequestClientProvider = ({
  client,
  children,
}: PropsWithChildren<HttpRequestClientProviderOptions>) => {
  return (
    <HttpRequestClientContext.Provider value={client}>{children}</HttpRequestClientContext.Provider>
  );
};

export const useRequestClient = () => useContext(HttpRequestClientContext);
