import {
  GET_DIVIDEND_ORDERS,
  GET_DIVIDEND_ORDERS_ERROR,
  GET_DIVIDEND_ORDERS_SUCCESS,
} from 'app-state/constants/sip-orders';

import { ActionWithPayload } from './types';

export const getDividendOrders: ActionWithPayload = payload => ({
  type: GET_DIVIDEND_ORDERS,
  payload,
});

export const getDividendOrdersSuccess: ActionWithPayload = payload => ({
  type: GET_DIVIDEND_ORDERS_SUCCESS,
  payload,
});

export const getDividendOrdersError: ActionWithPayload = payload => ({
  type: GET_DIVIDEND_ORDERS_ERROR,
  payload,
});
