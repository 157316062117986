import * as constants from 'app-state/indication-of-interest/constants';
import type {
  AdjustIndicationOfInterest,
  IndicationOfInterest,
  IndicationOfInterestActionTypes,
  IndicationOfInterestIdField,
  IndicationOfInterestItem,
  InvestorAnchorStatus,
  InvestorAnchorStatusPayload,
  IssuanceId,
} from 'app-state/indication-of-interest/types';
import type { Errors } from 'app-state/types';

// eslint-disable-next-line max-len
export const placeIndicationOfInterest = (
  payload: IndicationOfInterest & IssuanceId,
): IndicationOfInterestActionTypes => ({ type: constants.PLACE_INDICATION_OF_INTEREST, payload });
export const placeIndicationOfInterestSuccess = (): IndicationOfInterestActionTypes => ({
  type: constants.PLACE_INDICATION_OF_INTEREST_SUCCESS,
});
export const placeIndicationOfInterestError = (
  payload: Errors,
): IndicationOfInterestActionTypes => ({
  type: constants.PLACE_INDICATION_OF_INTEREST_ERROR,
  payload,
});

export const getInvestorAnchorStatus = (
  payload: InvestorAnchorStatusPayload,
): IndicationOfInterestActionTypes => ({ type: constants.GET_INVESTOR_ANCHOR_STATUS, payload });
export const getInvestorAnchorStatusSuccess = (
  payload: InvestorAnchorStatus,
): IndicationOfInterestActionTypes => ({
  type: constants.GET_INVESTOR_ANCHOR_STATUS_SUCCESS,
  payload,
});
export const getInvestorAnchorStatusError = (): IndicationOfInterestActionTypes => ({
  type: constants.GET_INVESTOR_ANCHOR_STATUS_ERROR,
});
export const resetInvestorAnchorStatus = (): IndicationOfInterestActionTypes => ({
  type: constants.RESET_INVESTOR_ANCHOR_STATUS,
});

// eslint-disable-next-line max-len
export const adjustInterest = (
  payload: AdjustIndicationOfInterest,
): IndicationOfInterestActionTypes => ({ type: constants.ADJUST_INDICATION_OF_INTEREST, payload });
export const adjustInterestSuccess = (): IndicationOfInterestActionTypes => ({
  type: constants.ADJUST_INDICATION_OF_INTEREST_SUCCESS,
});
export const adjustInterestError = (payload: Errors): IndicationOfInterestActionTypes => ({
  type: constants.ADJUST_INDICATION_OF_INTEREST_ERROR,
  payload,
});

// eslint-disable-next-line max-len
export const cancelInterest = (
  payload: IndicationOfInterestIdField,
): IndicationOfInterestActionTypes => ({ type: constants.CANCEL_INDICATION_OF_INTEREST, payload });
export const cancelInterestSuccess = (): IndicationOfInterestActionTypes => ({
  type: constants.CANCEL_INDICATION_OF_INTEREST_SUCCESS,
});
export const cancelInterestError = (payload: Errors): IndicationOfInterestActionTypes => ({
  type: constants.CANCEL_INDICATION_OF_INTEREST_ERROR,
  payload,
});

// eslint-disable-next-line max-len
export const getIndicationOfInterest = (
  payload: IndicationOfInterestIdField,
): IndicationOfInterestActionTypes => ({ type: constants.GET_INDICATION_OF_INTEREST, payload });
export const getIndicationOfInterestSuccess = (
  payload: IndicationOfInterestItem,
): IndicationOfInterestActionTypes => ({
  type: constants.GET_INDICATION_OF_INTEREST_SUCCESS,
  payload,
});
export const getIndicationOfInterestError = (payload: Errors): IndicationOfInterestActionTypes => ({
  type: constants.GET_INDICATION_OF_INTEREST_ERROR,
  payload,
});
export const resetIndicationOfInterest = (): IndicationOfInterestActionTypes => ({
  type: constants.RESET_INDICATION_OF_INTEREST,
});
