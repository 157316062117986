export const GET_SHARE_OPTIONS_DISPLAY_STATE = 'GET_SHARE_OPTIONS_DISPLAY_STATE';
export const GET_SHARE_OPTIONS_DISPLAY_STATE_SUCCESS = 'GET_SHARE_OPTIONS_DISPLAY_STATE_SUCCESS';
export const GET_SHARE_OPTIONS_DISPLAY_STATE_ERROR = 'GET_SHARE_OPTIONS_DISPLAY_STATE_ERROR';

export const GET_SHARE_OPTIONS = 'GET_SHARE_OPTIONS';
export const GET_SHARE_OPTIONS_SUCCESS = 'GET_SHARE_OPTIONS_SUCCESS';
export const GET_SHARE_OPTIONS_ERROR = 'GET_SHARE_OPTIONS_ERROR';
export const CLEAR_SHARE_OPTIONS = 'CLEAR_SHARE_OPTIONS';

export const GET_SHARE_OPTION = 'GET_SHARE_OPTION';
export const GET_SHARE_OPTION_SUCCESS = 'GET_SHARE_OPTION_SUCCESS';
export const GET_SHARE_OPTION_ERROR = 'GET_SHARE_OPTION_ERROR';
export const CLEAR_SHARE_OPTION = 'CLEAR_SHARE_OPTION';
