import { push } from 'connected-react-router';
import { useDispatch } from 'react-redux';
import { useLocation } from 'react-router-dom';

import Routes from 'constants/routes';
import memorizePath from 'shared-parts/helpers/memorize-path';

import {
  LoginLink,
  LoginMessage,
  RestrictedMessage,
  RestrictedSignUpButton,
  Wrapper,
} from './restricted-access.styled';

const RestrictedAccess = () => {
  const dispatch = useDispatch();
  const { pathname } = useLocation();

  const redirectUser = () => {
    memorizePath(pathname);
    dispatch(push(Routes.Login()));
  };

  return (
    <Wrapper>
      <RestrictedMessage>
        In order to view the full deal please sign up or log in.
      </RestrictedMessage>

      <RestrictedSignUpButton title="Sign up" beALink url={Routes.SignUp()} />

      <LoginMessage>
        Already have an account? <LoginLink onClick={redirectUser}>Login</LoginLink>
      </LoginMessage>
    </Wrapper>
  );
};

export default RestrictedAccess;
