import 'intersection-observer';

import { ConnectedRouter } from 'connected-react-router';
import { lazy, Suspense, useEffect } from 'react';
import { hot } from 'react-hot-loader/root';
import { Provider, useDispatch } from 'react-redux';
import { Redirect, Route, Switch, useLocation } from 'react-router-dom';

import { LoggerProvider, ProductAnalyticsProvider } from '@frontend/analytics';
import {
  clearOpenFeatureUser,
  PlatformSettingsProvider,
  setOpenFeatureUser,
} from '@frontend/config';
import { QueryDevTools, RequestClientProvider } from '@frontend/http';
import { isEmptyObject } from '@frontend/utils';

import { getCurrentUser } from 'app-state/actions/current-user';
import { updateNickelledUser } from 'app-state/actions/shared';
import {
  getAuthenticationStatus,
  getLoginError,
  getUser as getUserSelector,
} from 'app-state/selectors/authentication';
import store, { history } from 'app-state/store';

import AmlVerificationPage from 'modules/onboard/shared/aml-verification';
import RiskDisclaimerBanner from 'modules/risk-disclaimer-banner';
import Root from 'modules/root';
import { Unauthorized } from 'modules/unauthorized';
import Routes from 'constants/routes';
import { AppConfigProvider, FeatureFlag, useFeatureFlag } from 'providers/app-config';
import { DeviceProvider } from 'providers/device';
import AuthenticatedGqlProvider, { useGraphQLClient } from 'providers/graphql';
import { LiquidityProvider } from 'providers/liquidity';
import useTypedSelector from 'hooks/use-typed-selector';
import Redirection from 'helpers/redirection';
import Footer from 'shared/footer/footer';
import Header from 'shared/header/header';
import ErrorBoundary from 'shared-parts/components/error-boundary';
import Loader from 'shared-parts/components/loader/loader';
import Modal from 'shared-parts/components/modal';
import { request } from 'shared-parts/helpers/request';

import { oauth2Login } from './app-state/actions';
import UserAlerts from './user-alerts';

const Deals = lazy(
  () => import(/* webpackPrefetch: true */ /* webpackChunkName: "Deals" */ 'modules/deals/deals'),
);
const Deal = lazy(
  () => import(/* webpackPrefetch: true */ /* webpackChunkName: "Deal" */ 'modules/deal/deal'),
);
const LiquidityRound = lazy(
  () =>
    import(
      /* webpackPrefetch: true */ /* webpackChunkName: "LiquidityRound" */ 'modules/liquidity-round'
    ),
);
const ResetPassword = lazy(
  () =>
    import(
      /* webpackPrefetch: true */ /* webpackChunkName: "ResetPassword" */ 'modules/reset-password/reset-password'
    ),
);
const ForgotPassword = lazy(
  () =>
    import(
      /* webpackPrefetch: true */ /* webpackChunkName: "ForgotPassword" */ 'modules/forgot-password/forgot-password'
    ),
);
const AccountInformationFirstPart = lazy(
  () =>
    import(
      /* webpackPrefetch: true */ /* webpackChunkName: "AccountInformationFirstPart" */ 'modules/onboard/individual/account-information-first-part'
    ),
);
const AccountInformationSecondPart = lazy(
  () =>
    import(
      /* webpackPrefetch: true */ /* webpackChunkName: "AccountInformationSecondPart" */ 'modules/onboard/individual/account-information-second-part'
    ),
);
const SuitabilityTest = lazy(
  () =>
    import(
      /* webpackPrefetch: true */ /* webpackChunkName: "SuitabilityTests" */ 'modules/onboard/individual/suitability-tests'
    ),
);
const SelectInvestorType = lazy(
  () =>
    import(
      /* webpackPrefetch: true */ /* webpackChunkName: "SelectInvestorType" */ 'modules/onboard/select-investor-type/select-investor-type'
    ),
);
const AccountInformation = lazy(
  () =>
    import(
      /* webpackPrefetch: true */ /* webpackChunkName: "AccountInformation" */ 'modules/onboard/institutional/components/account-information-page'
    ),
);
const PreferencesForm = lazy(
  () =>
    import(
      /* webpackPrefetch: true */ /* webpackChunkName: "InvestmentPreferences" */ 'modules/preferences/investment-preferences'
    ),
);
const TermsForm = lazy(
  () =>
    import(
      /* webpackPrefetch: true */ /* webpackChunkName: "InvestmentPreferences" */ 'modules/onboard/shared/terms-form'
    ),
);
const LandingPage = lazy(
  () =>
    import(
      /* webpackPrefetch: true */ /* webpackChunkName: "LandingPage" */ 'modules/landing-page/landing-page'
    ),
);
const Transactions = lazy(
  () => import(/* webpackPrefetch: true */ /* webpackChunkName: "Orders" */ 'modules/transactions'),
);
const NotFound = lazy(
  () =>
    import(
      /* webpackPrefetch: true */ /* webpackChunkName: "NotFound" */ 'shared-parts/modules/not-found'
    ),
);
const Holdings = lazy(
  () => import(/* webpackPrefetch: true */ /* webpackChunkName: "account" */ 'modules/holdings'),
);
const Account = lazy(
  () =>
    import(/* webpackPrefetch: true */ /* webpackChunkName: "account" */ 'modules/account/account'),
);
const Login = lazy(
  () => import(/* webpackPrefetch: true */ /* webpackChunkName: "Login" */ 'modules/login/login'),
);
const ConfirmEmail = lazy(
  () =>
    import(
      /* webpackPrefetch: true */ /* webpackChunkName: "ConfirmEmail" */ 'modules/confirm-email/confirm-email'
    ),
);
const CreatePassword = lazy(
  () =>
    import(
      /* webpackPrefetch: true */ /* webpackChunkName: "CreatePassword" */ 'modules/create-password'
    ),
);
const AccountInformationPreview = lazy(
  () =>
    import(
      /* webpackPrefetch: true */ /* webpackChunkName: "AccountInformationPreview" */ 'modules/onboard/institutional/components/account-information-preview'
    ),
);
const UserAgreements = lazy(
  () =>
    import(
      /* webpackPrefetch: true */ /* webpackChunkName: "UserAgreements" */ 'modules/onboard/institutional/components/user-agreements'
    ),
);
const AgentPermissions = lazy(
  () => import('modules/onboard/institutional/components/agent-permissions'),
);

const Opportunities = lazy(
  () =>
    import(
      /* webpackPrefetch: true */ /* webpackChunkName: "Opportunities" */ 'modules/opportunities'
    ),
);
const SignUp = lazy(
  () => import(/* webpackPrefetch: true */ /* webpackChunkName: "Deals" */ 'modules/sign-up'),
);

interface PageInfo {
  currentPage: string;
}

// TODO: Remove UserRoute component after enabling SSE
// UserRoute should be used only in the scope of the main router file
const UserRoute = <P extends Record<string, unknown>>(props: P) => {
  const dispatch = useDispatch();
  const user = useTypedSelector(getUserSelector);
  const unauthenticated = isEmptyObject(user);
  const { pathname } = useLocation();

  const enableOauth2Login = useFeatureFlag(FeatureFlag.OAUTH2_LOGIN_ENABLE);

  useEffect(() => {
    // Do nothing for oauth2, as redirects are handled by oauth proxy
    if (enableOauth2Login) {
      return;
    }

    if (!unauthenticated) {
      // We do not want to redirect user to sign-up page after router changes
      // when user goes between deal page tabs
      const shouldRedirectOnLogout = !pathname.startsWith(Routes.Deal.Root());
      dispatch(getCurrentUser(shouldRedirectOnLogout));
    }
  }, [pathname]);

  return <Route {...props} />;
};

const WithHeaderAndFooter = ({ currentPage }: PageInfo) => {
  const isLiquidityEnabled = useFeatureFlag(FeatureFlag.LIQUIDITY_ROUND_FEATURE);
  const isPlacementEnabled = !useFeatureFlag(FeatureFlag.PLACEMENT_DISABLED);
  const isTransactionsEnabled = !useFeatureFlag(FeatureFlag.HIDE_TRANSACTIONS);

  return (
    <>
      <RiskDisclaimerBanner />
      <Header />
      <div className={`page-content ${currentPage}-page-wrapper`}>
        <Switch>
          {isPlacementEnabled && (
            <UserRoute
              path={
                isLiquidityEnabled ? [Routes.Deals(), Routes.LiquidityRounds()] : Routes.Deals()
              }
              exact
              render={() => (isLiquidityEnabled ? <Opportunities /> : <Deals />)}
            />
          )}
          {isPlacementEnabled && <UserRoute path={Routes.Deal.Page()} component={Deal} />}
          {isLiquidityEnabled && (
            <UserRoute path={Routes.LiquidityRound()} component={LiquidityRound} />
          )}
          {isTransactionsEnabled && (
            <UserRoute path={Routes.Transactions.Root()} component={Transactions} />
          )}
          <UserRoute path={Routes.Account.Root()} component={Account} />
          <UserRoute path={Routes.Holdings.Root()} component={Holdings} />
          <Route path={Routes.Onboarding.Institutional.Completed()} component={LandingPage} />
          <Route path={Routes.ConfirmEmail()} component={ConfirmEmail} />
          <Route path={Routes.Onboarding.SelectInvestorType()} component={SelectInvestorType} />
          <Route
            path={Routes.Onboarding.Individual.AccountInformationFirstPart()}
            component={AccountInformationFirstPart}
          />
          <Route
            path={Routes.Onboarding.Individual.AccountInformationSecondPart()}
            component={AccountInformationSecondPart}
          />
          <Route
            path={Routes.Onboarding.Individual.SuitabilityTest()}
            component={SuitabilityTest}
          />
          <Route
            path={Routes.Onboarding.Institutional.AccountInformation()}
            component={AccountInformation}
          />
          <Route path={Routes.Onboarding.TermsAndConditions()} component={TermsForm} />
          <Route path={Routes.Onboarding.InvestmentPreferences()} component={PreferencesForm} />
          <Route
            path={Routes.Onboarding.Institutional.AgentPermissions()}
            component={AgentPermissions}
          />
          <Route
            path={Routes.Onboarding.Institutional.AccountInformationPreview()}
            component={AccountInformationPreview}
          />
          <Route
            path={Routes.Onboarding.Institutional.UserAgreements()}
            component={UserAgreements}
          />
          <Route path={Routes.NotFound()} component={NotFound} />
          <Route component={NotFound} />
        </Switch>
      </div>
      <Footer />
    </>
  );
};

const Router = () => {
  const dispatch = useDispatch();

  const enableOauth2Login = useFeatureFlag(FeatureFlag.OAUTH2_LOGIN_ENABLE);

  const user = useTypedSelector(getUserSelector);
  const authenticated = useTypedSelector(getAuthenticationStatus);
  const { pathname } = useLocation();
  const isSignUpEnabled = !useFeatureFlag(FeatureFlag.DISABLE_SIGN_UP);
  const isPlacementEnabled = !useFeatureFlag(FeatureFlag.PLACEMENT_DISABLED);
  const loginError = useTypedSelector(getLoginError);

  useEffect(() => {
    if (enableOauth2Login) {
      dispatch(oauth2Login());
    }
  }, [enableOauth2Login]);

  useEffect(() => {
    if (authenticated) {
      dispatch(updateNickelledUser());
    }
  }, [authenticated]);

  useEffect(() => {
    if (!authenticated || !user || isEmptyObject(user)) {
      clearOpenFeatureUser();
    } else {
      setOpenFeatureUser(user);
    }
  }, [user, authenticated]);

  const gqlClient = useGraphQLClient();

  const { to } = (() => {
    // If oauth2 is enabled, and the user is not authenticated, don't do a thing
    // The oauth proxy will handle this scenario
    if (!authenticated && enableOauth2Login) {
      return { to: null };
    }

    return new Redirection({
      user,
      browserPath: pathname,
      isPlacementEnabled,
    });
  })();

  if (to) {
    return <Redirect to={to} />;
  }

  const currentPage = pathname.substring(pathname.lastIndexOf('/') + 1);

  if (enableOauth2Login) {
    // This only works as we can guarantee no unauthed pages on the application
    // so we can assume that if theyre unauthenticated, proxy will be handling something
    // Without this, there will be nowhere to redirect to until the user is loaded,
    // resulting in a 404 page flash
    if (!authenticated && !loginError) {
      return <Loader alwaysVisible />;
    }

    // If the user is unauthenticated and there is a login error, this means that they
    // have a valid token but are not allowed to access the application.
    if (loginError) {
      return <Unauthorized />;
    }
  }

  return (
    <LoggerProvider appName="investor-platform" user={user} getId={u => u.id}>
      <ProductAnalyticsProvider user={user}>
        <Loader />
        <ErrorBoundary>
          <Suspense fallback={<Loader alwaysVisible />}>
            <RequestClientProvider
              request={request}
              graphql={gqlClient}
              defaultOptions={{
                export: {
                  url: window.config.GRAPHXL_ENDPOINT as string,
                },
              }}
            >
              <QueryDevTools />
              <PlatformSettingsProvider loader={<Loader alwaysVisible />}>
                <UserAlerts />
                <Modal />
                {/* /invest route handling IS SUPPOSED TO BE REMOVED IN THE NEAR FUTURE */}
                <Route path="/invest" render={() => <Redirect to={pathname.substring(7)} />} />
                {/* /invest route handling IS SUPPOSED TO BE REMOVED IN THE NEAR FUTURE */}
                <Route path={Routes.Root()} component={Root} />
                <Switch>
                  <Route
                    path={Routes.Onboarding.AmlVerification()}
                    component={AmlVerificationPage}
                  />
                  <Route path={Routes.Login()} component={Login} />
                  {isSignUpEnabled && <Route path={Routes.SignUp()} component={SignUp} />}
                  <Route path={Routes.ResetPassword()} component={ResetPassword} />
                  <Route path={Routes.ForgotPassword()} component={ForgotPassword} />
                  <Route path={Routes.CreatePassword()} component={CreatePassword} />
                  <Route path="/signout" render={() => <Loader alwaysVisible />} />
                  <WithHeaderAndFooter currentPage={currentPage} />
                </Switch>
              </PlatformSettingsProvider>
            </RequestClientProvider>
          </Suspense>
        </ErrorBoundary>
      </ProductAnalyticsProvider>
    </LoggerProvider>
  );
};

const HotApp = hot(Router);

export default (): JSX.Element => (
  <AppConfigProvider>
    <Provider store={store}>
      <ConnectedRouter history={history}>
        <DeviceProvider>
          <LiquidityProvider>
            <AuthenticatedGqlProvider>
              <HotApp />
            </AuthenticatedGqlProvider>
          </LiquidityProvider>
        </DeviceProvider>
      </ConnectedRouter>
    </Provider>
  </AppConfigProvider>
);
