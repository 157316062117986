import styled from 'styled-components';

import * as Colors from 'shared-parts/constants/colors';

import Tabs from './tabs';

const getTabStyles = selected => `
    text-transform: initial;
    color: ${Colors.neutral800};
    line-height: 19px;

    ${
      selected
        ? `
      font-weight: bold;
      color: ${Colors.highlight};
      border-bottom-color: ${Colors.highlight};
  `
        : ''
    }
`;

const StyledFormTabs = styled(props => <Tabs {...props} getTabStyles={getTabStyles} />)`
  margin: 0;
  border-bottom: 1px solid ${Colors.neutral500};
`;

export { StyledFormTabs }; //eslint-disable-line
