import * as constants from 'app-state/constants';

import { allCountries } from 'shared-parts/constants';

export const defaultOnboardingState = {
  isAddressValid: null,
  isStepComplete: false,
  isTypeSupported: true,
  showTypeAndTermsForm: true,
};

export const onboardingStateReducer = (state = defaultOnboardingState, action) => {
  switch (action.type) {
    case constants.CHANGE_ONBOARDING_STATE:
      return { ...state, ...action.newState };
    default:
      return state;
  }
};

export const defaultIndividualInvestorTypesState = {
  loading: true,
  data: [],
};

export const individualInvestorTypesReducer = (
  state = defaultIndividualInvestorTypesState,
  action,
) => {
  switch (action.type) {
    case constants.GET_INDIVIDUAL_INVESTOR_TYPES:
      return defaultIndividualInvestorTypesState;
    case constants.GET_INDIVIDUAL_INVESTOR_TYPES_SUCCESS:
      return { loading: false, data: action.data };
    default:
      return state;
  }
};

export const investorAddressReducer = (state = {}, action) => {
  switch (action.type) {
    case constants.SAVE_ADDRESS:
      return action.address;
    default:
      return state;
  }
};

export const defaultSupportedCountriesState = { countries: [], error: null };

export const supportedCountriesReducer = (state = defaultSupportedCountriesState, action) => {
  switch (action.type) {
    case constants.GET_SUPPORTED_COUNTRIES_SUCCESS:
      return { countries: action.supportedCountries, error: null };
    case constants.GET_SUPPORTED_COUNTRIES_ERROR:
      return { countries: [], error: action.error };
    default:
      return state;
  }
};

export const defaultUnsupportedCountriesState = { countries: [], error: null };

export const unsupportedCountriesReducer = (state = defaultUnsupportedCountriesState, action) => {
  switch (action.type) {
    case constants.GET_UNSUPPORTED_COUNTRIES_SUCCESS:
      return { countries: action.unsupportedCountries, error: null };
    case constants.GET_UNSUPPORTED_COUNTRIES_ERROR:
      return { countries: [], error: action.error };
    default:
      return state;
  }
};

export const allCountriesReducer = () => allCountries;
