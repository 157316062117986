import styled from 'styled-components';

import * as Colors from 'shared-parts/constants/colors';

const LoqateWrapper = styled.div`
  position: relative;
  min-height: 115px;
  ${props => props.blockStyles || ''}
`;

const AddressInfoWrapper = styled.div`
  color: ${Colors.primary};
  font-size: 14px;
  font-weight: bold;
`;

const EnterAddressManuallyButton = styled.button`
  background-color: Transparent;
  background-repeat: no-repeat;
  border: 0;
  color: ${Colors.alternative};
  cursor: pointer;
  font-size: 14px;
  font-weight: bold;
  height: 20px;
  text-align: right;
  margin-left: auto;

  :hover {
    text-decoration: underline;
  }

  &:focus {
    outline: none;
  }
`;

const AreaBelowTheInput = styled.div`
  display: flex;
`;

const AutofillDisableInput = styled.input`
  display: none;
`;

export {
  LoqateWrapper,
  AddressInfoWrapper,
  EnterAddressManuallyButton,
  AreaBelowTheInput,
  AutofillDisableInput,
};
