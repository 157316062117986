import styled from 'styled-components';

import * as Colors from 'shared-parts/constants/colors';

const Container = styled.div<{ paddingRight?: string }>`
  color: ${Colors.neutral800};
  margin-bottom: 31px;
  padding-right: ${({ paddingRight = '' }) => paddingRight};
`;

export { Container }; // eslint-disable-line
