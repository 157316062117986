import { styled } from '@mui/material';

import { Link, Screens } from './stats/trade-offs';

const bigTileWidth = '348px';
const smallTileWidth = '300px';
const extraSmallTileWidth = '250px';

export const TileComponent = styled('div')<{ neutral500Color: string }>`
  border: 1px solid ${({ neutral500Color }) => neutral500Color};
  border-radius: 7px;
  box-sizing: content-box;
  height: 531px;
  margin-bottom: 30px;
  margin-right: 10px;
  position: relative;
  overflow: hidden;

  &:hover {
    box-shadow: 0 3px 6px 0 ${({ neutral500Color }) => neutral500Color};
  }

  .link {
    font-size: initial;
    line-height: initial;
    margin: 0;

    a,
    a:hover {
      text-decoration: none;
      color: initial;
    }
  }

  @media (max-width: ${Screens.xl}) {
    margin-right: 0;
  }

  @media only screen and (min-width: ${Screens.sm}) {
    min-width: ${bigTileWidth};
    max-width: ${bigTileWidth};
  }

  @media only screen and (max-width: ${Screens.md}) {
    height: 535px;
    min-width: ${smallTileWidth};
    max-width: ${smallTileWidth};
  }

  @media only screen and (max-width: ${Screens.sm}) {
    height: 400px;
    min-width: ${extraSmallTileWidth};
    max-width: ${extraSmallTileWidth};
  }
`;

export const Photos = styled('div')`
  position: relative;
  height: 50%;
  width: 100%;
`;

export const Background = styled('img')<{ highlightColor: string }>(({ highlightColor }) => ({
  backgroundColor: highlightColor,
  borderRadius: '6px 6px 0 0',
  position: 'relative',
  width: '100%',
}));

export const LogoContainer = styled('div')`
  position: absolute;
  margin: auto;
  left: calc(25% - 50px);
  top: calc(50% - 16px);
  z-index: 2;
  height: 72px;
  width: 71px;

  padding: 2px;
  border-radius: 50%;
  border: 1px solid ${({ theme }) => theme.palette.neutral[0]};
  background: ${({ theme }) => theme.palette.neutral[0]};
`;

export const Logo = styled('img')`
  display: flex;
  justify-content: center;
  align-items: center;
  background-size: cover;
  border-radius: 50%;
`;

export const LogoPlaceholder = styled('div')<{ alternativeColor: string }>(
  ({ alternativeColor }) => ({
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    height: '100%',
    width: '100%',
    backgroundColor: alternativeColor,
    backgroundSize: 'cover',
    borderRadius: '50%',
  }),
);

export const HeaderContent = styled('div')<{ oswaldFont: string; neutral300Color: string }>`
  align-items: center;
  border-bottom: 1px solid ${({ neutral300Color }) => neutral300Color};
  display: flex;
  font-family: ${({ oswaldFont }) => oswaldFont};
  font-weight: 300;
  height: 50%;
  text-transform: uppercase;
  width: 100%;
  color: ${({ theme }) => theme.palette.primary.main};
  padding: 0 35px;

  @media only screen and (min-width: ${Screens.sm}) {
    font-size: 20px;
  }

  @media only screen and (max-width: ${Screens.sm}) {
    font-size: 15px;
  }
`;

export const Description = styled('div')<{ neutral800Color: string }>`
  color: ${({ neutral800Color }) => neutral800Color};
  display: flex;
  font-size: 16px;
  padding: 15px 25px;
  height: 43%;
  width: 100%;
  overflow: hidden;
  overflow-y: auto;
  white-space: pre-wrap;
  flex-grow: 1;

  @media only screen and (min-width: ${Screens.sm}) {
    font-size: 14px;
  }

  @media only screen and (max-width: ${Screens.sm}) {
    font-size: 12px;
  }
`;

export const getCursorType = ({ url }: { url: string }) => (url !== '#' ? 'pointer' : 'default');

export const TileLink = styled(Link)`
  height: 100%;
  width: 100%;
  & > a {
    position: relative;
    height: 100%;
    display: flex;
    flex-direction: column;
    color: ${({ theme }) => theme.palette.primary.main};
    padding-bottom: 12px;

    &:hover {
      color: ${({ theme }) => theme.palette.primary.main};
    }
  }

  a {
    cursor: ${getCursorType};
  }
`;

export const DealTypeTag = styled('span')<{ mainFont: string }>`
  text-transform: capitalize;
  position: absolute;
  right: 20px;
  top: 20px;
  color: ${({ theme }) => theme.palette.primary.main};
  font-family: ${({ mainFont }) => mainFont};
  font-size: 12px;
  font-style: normal;
  font-weight: 700;
  line-height: 20px;
  padding: 4px 8px;
  background: ${({ theme }) => theme.palette.neutral[0]};
  display: inline-block;
  border-radius: 6px;
`;

export const Header = styled('div')`
  min-height: 34%;
  display: flex;
  flex-direction: column;
`;
