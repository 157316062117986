import { ReactNode } from 'react';

import { InputAdornment, OutlinedInput, OutlinedInputProps } from '@mui/material';

type InputProps = OutlinedInputProps & {
  prefix?: ReactNode;
  suffix?: ReactNode;
  validationIcon?: ReactNode;
  isValid?: boolean;
};

/**
 * Base input component. Extends MUI's TextField (outlined).
 * MUI's label does not fit our design system.
 */
const Input = ({ prefix, suffix, validationIcon, isValid, ...inputProps }: InputProps) => {
  const suffixes = [isValid && validationIcon, suffix].filter(Boolean);
  return (
    <OutlinedInput
      startAdornment={!!prefix && <InputAdornment position="start">{prefix}</InputAdornment>}
      endAdornment={
        !!suffixes.length && (
          <InputAdornment position="end">
            {suffixes.map((s, i) => {
              return <span key={i}>{s}</span>;
            })}
          </InputAdornment>
        )
      }
      {...inputProps}
      // We don't want the fancy label stuff
      label={undefined}
    />
  );
};

export { Input, InputProps };
