// eslint-disable-next-line
// @ts-nocheck
import { currencies } from './currencies';

const getRange = (from: number, to: number) => {
  const result = [];

  for (let i = from; i <= to; i++) {
    result.push(i);
  }

  return [...result];
};

const parseNumber = number => (isNaN(number) ? 0 : Number(number));
const addCommasToNumber = number => {
  const intPart = parseInt(number, 10);
  const indexOfFractionalPart = number.toString().indexOf('.');
  const fractionalPart =
    indexOfFractionalPart > 0 ? number.toString().slice(indexOfFractionalPart) : '';
  const intPartWithCommas = intPart.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',');

  return `${intPartWithCommas}${fractionalPart}`;
};

const toInt = number => {
  const parsedNumber = parseNumber(number);

  return addCommasToNumber(parseInt(parsedNumber, 10));
};

const toDefinedFractionSize = (number, fraction) =>
  parseFloat(parseNumber(number)).toFixed(fraction);

const toDefinedFractionSizeNoRound = (number, fractionalPartLength = 2) => {
  const convertedNumber = Number(number);
  const removeDecimalPlaces = fractionalPartLength === 0;

  if (Number.isInteger(convertedNumber) && !removeDecimalPlaces) {
    return addCommasToNumber(convertedNumber.toFixed(2));
  }

  const numberAsString = String(number);
  const [wholeNumber, dp = ''] = numberAsString.split('.');
  const formattedWholePart = toInt(wholeNumber);
  const dot = removeDecimalPlaces ? '' : '.';
  const truncatedFractionalPart = dp.slice(0, fractionalPartLength);
  const truncatedFractionalPartWithZeros = getRange(0, fractionalPartLength - 1)
    .map((elem, i) => truncatedFractionalPart[i] || 0)
    .join('');

  return `${formattedWholePart}${dot}${truncatedFractionalPartWithZeros}`;
};

const toFloat = number => addCommasToNumber(toDefinedFractionSize(number, 2));

const divideSignAndNumber = value => {
  if (typeof value === 'string') {
    const sign = value[0] === '-' ? '-' : '';
    const num = sign ? value.slice(1) : value;
    return [sign, num];
  }

  const number = Number(value);
  return number > 0 ? ['', number] : ['-', number * -1];
};

export const addCurrencySign = (amount, currency) => {
  const { symbol, position } = currencies[currency] || { symbol: currency || '', position: 'left' };
  const [sign, number] = divideSignAndNumber(amount);
  return position === 'left' ? `${sign}${symbol}${number}` : `${sign}${number} ${symbol}`;
};

export const toFloatPercent = number => `${toFloat(number)}%`;

export const toAbbrInt = (number, fraction = 2) => {
  if (isNaN(Number(number))) return number;

  if (number >= 1000000000000)
    return `${addCommasToNumber(toDefinedFractionSizeNoRound(number / 1000000000000, fraction))}T`;
  if (number >= 1000000000)
    return `${addCommasToNumber(toDefinedFractionSizeNoRound(number / 1000000000, fraction))}B`;
  if (number >= 1000000)
    return `${addCommasToNumber(toDefinedFractionSizeNoRound(number / 1000000, fraction))}m`;
  if (number >= 1000)
    return `${addCommasToNumber(toDefinedFractionSizeNoRound(number / 1000, fraction))}k`;
  return addCommasToNumber(toDefinedFractionSize(number, fraction));
};
