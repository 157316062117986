import Big from 'big.js';
import _ from 'lodash';

import { addCommasToNumber } from '@frontend/utils';

type TokenCalculation = {
  intendedAmount: string;
  currentCurrency: string;
  baseCurrency: string;
  tokenPrice: string;
  rates: { [Key: string]: number };
  unitTruncateScale: number;
};

export type TokenCalculationResult = {
  numberOfTokensString: string;
  rawNumberOfTokens: Big;
  roundedNumberOfTokensString: string | null;
  amountString: string;
  rawAmount: string | null;
  exchangeRate: number | null;
};

// TODO Can we assume 2 decimal places here?
const currencyDecimalPrecision = 2;

export const calculateIntendedNumberOfTokens = ({
  intendedAmount,
  currentCurrency,
  baseCurrency,
  tokenPrice,
  rates,
  unitTruncateScale = 0,
}: TokenCalculation): TokenCalculationResult => {
  const currentCurrencyPair = `${baseCurrency}${currentCurrency}`;
  const rate = rates[currentCurrencyPair];
  const rateIsValid = !isNaN(rate);

  if (!rateIsValid || !intendedAmount) {
    return {
      numberOfTokensString: '-',
      rawNumberOfTokens: new Big(0),
      roundedNumberOfTokensString: null,
      amountString: '-',
      rawAmount: null,
      exchangeRate: null,
    };
  }

  const tokens = new Big(intendedAmount)
    .div(rate)
    .div(tokenPrice)
    .toFixed(unitTruncateScale, Big.roundDown)
    .valueOf();

  const definedFractionalSize = new Big(tokens);

  const amount = definedFractionalSize.mul(tokenPrice).mul(rate);

  return {
    numberOfTokensString: addCommasToNumber(tokens.valueOf()),
    rawNumberOfTokens: new Big(tokens),
    roundedNumberOfTokensString: addCommasToNumber(definedFractionalSize.valueOf()),
    amountString: addCommasToNumber(
      amount.toFixed(currencyDecimalPrecision, Big.roundUp).valueOf(),
    ),
    rawAmount: amount.valueOf(),
    exchangeRate: rate,
  };
};
