import {
  GET_SHARE_INCENTIVE_PLAN,
  GET_SHARE_INCENTIVE_PLAN_ERROR,
  GET_SHARE_INCENTIVE_PLAN_SUCCESS,
  GET_SHARE_INCENTIVE_PLANS,
  GET_SHARE_INCENTIVE_PLANS_ERROR,
  GET_SHARE_INCENTIVE_PLANS_SUCCESS,
} from 'app-state/constants/share-incentive-plan';

import { ActionWithPayload } from './types';

export const getShareIncentivePlans = () => ({
  type: GET_SHARE_INCENTIVE_PLANS,
});

export const getShareIncentivePlansSuccess: ActionWithPayload = payload => ({
  type: GET_SHARE_INCENTIVE_PLANS_SUCCESS,
  payload,
});

export const getShareIncentivePlansError: ActionWithPayload = payload => ({
  type: GET_SHARE_INCENTIVE_PLANS_ERROR,
  payload,
});

export const getShareIncentivePlan: ActionWithPayload = payload => ({
  type: GET_SHARE_INCENTIVE_PLAN,
  payload,
});

export const getShareIncentivePlanSuccess: ActionWithPayload = payload => ({
  type: GET_SHARE_INCENTIVE_PLAN_SUCCESS,
  payload,
});

export const getShareIncentivePlanError: ActionWithPayload = payload => ({
  type: GET_SHARE_INCENTIVE_PLAN_ERROR,
  payload,
});
