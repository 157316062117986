const compareStrings = (param, asc) => (a, b) => {
  if (!a[param]) {
    return 1;
  }
  if (!b[param]) {
    return -1;
  }

  return asc
    ? a[param].toString().localeCompare(b[param].toString())
    : b[param].toString().localeCompare(a[param].toString());
};
const compareNumbers = (param, asc) => (a, b) =>
  asc ? Number(a[param]) - Number(b[param]) : Number(b[param]) - Number(a[param]);

const compareDates = (param, asc) => (a, b) =>
  asc ? new Date(a[param]) - new Date(b[param]) : new Date(b[param]) - new Date(a[param]);

const sortByParamAlphabetically = (param, type = 'string', asc = true) => {
  if (type === 'number') return compareNumbers(param, asc);
  if (type === 'date') return compareDates(param, asc);
  return compareStrings(param, asc);
};

export default sortByParamAlphabetically;
