export const FETCH_DEAL = 'FETCH_DEAL';
export const FETCH_DEAL_SUCCESS = 'FETCH_DEAL_SUCCESS';
export const FETCH_DEAL_ERROR = 'FETCH_DEAL_ERROR';
export const RESET_DEAL = 'RESET_DEAL';

export const FETCH_DEAL_DOCUMENTS = 'FETCH_DEAL_DOCUMENTS';
export const FETCH_DEAL_DOCUMENTS_SUCCESS = 'FETCH_DEAL_DOCUMENTS_SUCCESS';
export const RESET_DEAL_DOCUMENTS = 'RESET_DEAL_DOCUMENTS';

export const AGREE_NDA_DEAL_DOCUMENT = 'AGREE_NDA_DEAL_DOCUMENT';
export const UPDATE_AGREED_NDA_DEAL_DOCUMENT = 'UPDATE_AGREED_NDA_DEAL_DOCUMENT';

export const REQUEST_DEAL_NDA_E_SIGNATURE = 'REQUEST_DEAL_NDA_E_SIGNATURE';

export const SHOW_ORDER_DETAILS_FORM = 'SHOW_ORDER_DETAILS_FORM';
export const SHOW_ORDER_DETAILS_FORM_ERROR = 'SHOW_ORDER_DETAILS_FORM_ERROR';

export const FETCH_DEAL_PAGES_DATA = 'FETCH_DEAL_PAGES_DATA';
export const FETCH_DEAL_PAGES_DATA_SUCCESS = 'FETCH_DEAL_PAGES_DATA_SUCCESS';
export const FETCH_DEAL_PAGES_DATA_ERROR = 'FETCH_DEAL_PAGES_DATA_ERROR';

export const FETCH_PAGE = 'FETCH_PAGE';
export const UPDATE_PAGE = 'UPDATE_PAGE';
export const FETCH_PAGE_SUCCESS = 'FETCH_PAGE_SUCCESS';
export const FETCH_PAGE_ERROR = 'FETCH_PAGE_ERROR';
export const FETCH_PAGE_RESTRICTED = 'FETCH_PAGE_RESTRICTED';
export const RESET_PAGE = 'RESET_PAGE';

export const FETCH_ISSUANCE = 'FETCH_ISSUANCE';
export const FETCH_ISSUANCE_SUCCESS = 'FETCH_ISSUANCE_SUCCESS';
export const FETCH_ISSUANCE_ERROR = 'FETCH_ISSUANCE_ERROR';

export const SHOW_RISK_BANNER = 'SHOW_RISK_BANNER';
export const HIDE_RISK_BANNER = 'HIDE_RISK_BANNER';
