import * as Colors from 'shared-parts/constants/colors';

const NextArrow = ({ height = 22, width = 12, color = Colors.primary, className }) => (
  <svg
    version="1.1"
    id="Layer_1"
    xmlns="http://www.w3.org/2000/svg"
    x="0px"
    y="0px"
    viewBox="0 0 7.2 11.2"
    height={height}
    width={width}
    className={className}
  >
    <g id="Path_873">
      <path
        fill={color}
        d="M0.5,11.2c-0.1,0-0.3-0.1-0.4-0.2c-0.2-0.2-0.1-0.5,0.1-0.7l5.7-4.6L0.2,0.9C0,0.7-0.1,0.4,0.1,0.2
        C0.3,0,0.6-0.1,0.8,0.1L7,5.4c0.1,0.1,0.2,0.2,0.2,0.4S7.1,6.1,7,6.2l-6.2,5C0.7,11.2,0.6,11.2,0.5,11.2z"
      />
    </g>
  </svg>
);

export default NextArrow;
