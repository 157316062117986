import styled from 'styled-components';

import * as Colors from 'shared-parts/constants/colors';
import * as Fonts from 'shared-parts/constants/fonts';
import FormInvalidIcon from 'shared-parts/images/form-invalid.svg';
import FormValidIcon from 'shared-parts/images/form-valid.svg';

const Container = styled.div`
  background: ${({ isValueValid, touched, withIcon, hideValidationIcon }) =>
    `${Colors.white} ${
      touched && !hideValidationIcon
        ? `url(${isValueValid ? FormValidIcon : FormInvalidIcon}) no-repeat scroll calc(100% - ${
            withIcon ? '60px' : '15px'
          }) 50%`
        : ''
    };`};
  background-size: 20px 20px;
  border: ${({ isValueValid, touched }) =>
    (!isValueValid && touched && `1px solid ${Colors.error}`) || `1px solid ${Colors.neutral500}`};
  border-radius: 6px;
  height: 50px;
  position: relative;
  ${({ backgroundColor }) => (backgroundColor ? `background-color: ${backgroundColor};` : '')}

  :focus-within {
    ${({ isValueValid }) => isValueValid && `border: 1px solid ${Colors.secondary};`}
  }

  ${({ withIcon }) => withIcon && 'padding-right: 46px;'}

  ${({ hasAdditional }) =>
    hasAdditional &&
    `
    display: flex;
    align-items: center;
  `}
`;

const getRightPadding = password => (password ? 140 : 45);

const Input = styled.input`
  background: none transparent;
  border: 0 none;
  border-radius: 6px;
  box-sizing: border-box;
  -moz-box-sizing: border-box;
  -ms-box-sizing: border-box;
  -webkit-box-sizing: border-box;
  -khtml-box-sizing: border-box;
  color: ${Colors.primary};
  display: inline-block;
  font-family: ${Fonts.mainFont};
  font-size: 16px;
  height: 48px;
  padding-top: 0;
  padding-bottom: 0;
  padding-right: ${({ touched, password }) => (touched ? getRightPadding(password) : 21)}px;
  padding-left: ${({ hasPrefix }) => (hasPrefix ? 0 : 21)}px;
  width: 100%;
  -webkit-appearance: none;

  /* Chrome, Safari, Edge, Opera hide arrows for type number */
  ::-webkit-outer-spin-button,
  ::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }
  /* Firefox hide arrows for type number */
  [type='number'] {
    -moz-appearance: textfield;
  }

  &:-webkit-autofill,
  &:-webkit-autofill:hover,
  &:-webkit-autofill:focus {
    box-shadow: 0 0 0 1000px ${Colors.success100} inset !important;
    -webkit-box-shadow: 0 0 0 1000px ${Colors.success100} inset !important;
  }

  &:disabled {
    background: ${Colors.neutral300};
    border-radius: 6px;
    height: 100%;
    left: 0;
    padding: 7px 21px;
    width: 100%;
    color: ${Colors.neutral800};
    cursor: default;

    &::-webkit-input-placeholder {
      color: ${Colors.neutral500};
    }

    &::-moz-placeholder {
      color: ${Colors.neutral500};
    }

    &:-ms-input-placeholder {
      color: ${Colors.neutral500};
    }
  }

  &::-webkit-input-placeholder {
    color: ${Colors.neutral500};
    display: inline-block;
    font-family: ${Fonts.mainFont};
    font-size: 16px;
  }

  &::-moz-placeholder {
    color: ${Colors.neutral500};
    display: inline-block;
    font-family: ${Fonts.mainFont};
    font-size: 16px;
  }

  &:-ms-input-placeholder {
    color: ${Colors.neutral500};
    display: inline-block;
    font-family: ${Fonts.mainFont};
    font-size: 16px;
  }

  ${({ disableAutocomplete }) =>
    disableAutocomplete &&
    `
    &::-webkit-contacts-auto-fill-button, 
    &::-webkit-credentials-auto-fill-button {
      visibility: hidden;
      pointer-events: none;
      position: absolute;
      right: 0;
    }
  `}
`;

const ShowPassword = styled.span`
  color: ${({ color }) => color || ''};
  cursor: pointer;
  font-size: 12px;
  position: absolute;
  right: ${({ touched }) => (touched ? '45px' : '20px')};
  top: 15px;
  user-select: none;
`;

const HintText = styled.span`
  color: ${Colors.neutral800};
  font-size: 14px;
  position: absolute;
  right: ${({ touched }) => (touched ? '45px' : '12px')};
  top: 50%;
  transform: translateY(-50%);
  user-select: none;
`;

const IconContainer = styled.div`
  position: absolute;
  right: 0;
  width: 48px;
  top: 50%;
  transform: translate(0, -50%);
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  height: 100%;
  border-left: 1px solid ${Colors.neutral500};
`;

const DisableAutocompleteFakeInput = styled.input`
  display: none;
`;

export { Container, Input, ShowPassword, IconContainer, DisableAutocompleteFakeInput, HintText };
