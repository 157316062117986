import { useState } from 'react';

import {
  DataGridPremium,
  DataGridPremiumProps,
  GRID_AGGREGATION_ROOT_FOOTER_ROW_ID,
  gridClasses,
  GridLogicOperator,
  GridValidRowModel,
} from '@mui/x-data-grid-premium';

import { ExpandLess as ExpandLessIcon, ExpandMore as ExpandMoreIcon } from '../../icons';
import { Button, IconButton } from '../buttons';
import { Checkbox } from '../checkbox';
import { Switch } from '../switch';

import { DataGridInput, PaginationSelectInput } from './components/input';
import { GridToolbar } from './components/toolbar';

export type DataGridProps<R extends GridValidRowModel> = DataGridPremiumProps<R>;

export const DataGrid = <R extends GridValidRowModel = any>(props: DataGridProps<R>) => {
  const [panelAnchor, setPanelAnchor] = useState<HTMLButtonElement | null>(null);

  return (
    <DataGridPremium
      {...props}
      sx={{
        '--DataGrid-cellOffsetMultiplier': 9,

        // Text Colors
        color: 'neutral.600',
        [`.${gridClasses.columnHeaders}`]: {
          backgroundColor: 'neutral.25',
          color: 'neutral.700',
        },

        // Row highlighting
        [`.${gridClasses.row}`]: {
          // Show a pointer cursor if we have a row action
          ...(props.onRowClick && {
            cursor: 'pointer',
          }),

          '&:hover': {
            backgroundColor: 'neutral.25',
          },

          '&.Mui-selected': {
            backgroundColor: 'highlight.light',

            '&:hover': {
              backgroundColor: 'neutral.50',
            },
          },
        },

        // Borders
        borderColor: 'neutral.50',
        [`.${gridClasses.withBorderColor}`]: {
          borderColor: 'neutral.50',
        },
        [`.${gridClasses.columnHeader}`]: {
          borderTop: '1px solid',
          borderColor: 'neutral.50',
        },

        // Cell selection
        [`.${gridClasses.cell}, .${gridClasses.columnHeader}`]: {
          '&:focus': {
            // This is bugged currently and we cannot pass in outlineColor to sx
            // and have it obey our
            outlineColor: theme => theme.palette.info.main,
          },
        },

        // Display dash on empty cells
        [`.${gridClasses.row}:not([data-id=${GRID_AGGREGATION_ROOT_FOOTER_ROW_ID}]) .${gridClasses.cellContent}`]:
          {
            ':empty::after': {
              content: "'-'",
            },
          },
        // Data grid hard-codes the badge color so we hijack it here
        '& .MuiBadge-badge': {
          backgroundColor: 'secondary.main',
          color: 'secondary.light',
        },
        // aggregation column
        '.MuiDataGrid-pinnedRowsRenderZone > .MuiDataGrid-row': {
          fontWeight: '700',
        },
        ...props.sx,
      }}
      localeText={{
        columnsPanelTextFieldPlaceholder: 'Search',
        toolbarFilters: 'Filter',
        ...props.localeText,
      }}
      slots={{
        toolbar: GridToolbar,

        // Icons
        columnSortedAscendingIcon: ExpandLessIcon,
        columnSortedDescendingIcon: ExpandMoreIcon,

        // Pass our styled components back into MUI
        baseButton: Button,
        baseIconButton: IconButton,
        baseTextField: DataGridInput,
        baseSwitch: Switch,
        baseCheckbox: Checkbox,
        ...props.slots,
      }}
      slotProps={{
        ...props.slotProps,
        // Arrange column menu slots
        columnMenu: {
          slots: {
            columnMenuGroupingItem: null,
          },
        },
        footerRowCount: {
          sx: {
            paddingX: 4,
          },
        },

        toolbar: {
          sx: {
            justifyContent: 'end',
            backgroundColor: 'neutral.25',
          },
          printOptions: {
            disableToolbarButton: true,
          },
          excelOptions: {
            disableToolbarButton: true,
          },
          csvOptions: {
            disableToolbarButton: true,
          },
          ...props.slotProps?.toolbar,
          setPanelAnchor,
        },
        columnsPanel: {
          sx: {
            [`.${gridClasses.panelHeader}`]: {
              padding: 4,
            },
            [`.${gridClasses.panelContent}`]: {
              paddingX: 4,
              paddingBottom: 4,

              // Fix the spacing on the switches.
              // TODO: Should these reflect on the actual form controls
              '& .MuiSwitch-root': {
                marginRight: 1.5,
              },
            },
            [`.${gridClasses.panelFooter}`]: {
              paddingTop: 2,
              paddingX: 4,
              paddingBottom: 4,
            },

            // Column menu
            [`.${gridClasses.columnsPanel}`]: {
              display: 'flex',
              flexDirection: 'column',
              padding: 0,

              [`.${gridClasses.columnsPanelRow}`]: {
                paddingY: 0,
              },
            },
          },
          ...props.slotProps?.columnsPanel,
        },
        filterPanel: {
          sx: {
            padding: 4,

            [`.${gridClasses.filterFormDeleteIcon}`]: {
              justifyContent: 'center',
              paddingRight: 4,

              '& .MuiButtonBase-root': {
                paddingX: 0,
              },
            },

            // Stick to our standard text inputs, don't let datagrid override
            [`.${gridClasses.filterFormColumnInput},
            .${gridClasses.filterFormOperatorInput},
            .${gridClasses.filterFormValueInput}`]: {
              width: 'auto',
            },

            // NOW we override! This specific input should be.. specific
            [`.${gridClasses.filterFormLogicOperatorInput}`]: {
              '&, & .MuiInputBase-root': {
                width: '108px',
              },
            },

            // Spread out the inputs and rows
            [`.${gridClasses.panelContent}`]: {
              overflowX: 'hidden',
              gap: 7,

              [`.${gridClasses.filterForm}`]: {
                gap: 4,
                padding: 0,
              },
            },

            // Create more separation for the actions
            [`.${gridClasses.panelFooter}`]: {
              pt: 6,
            },
          },
          logicOperators: [GridLogicOperator.And],
          ...props.slotProps?.filterPanel,
        },
        panel: {
          anchorEl: panelAnchor,
          placement: 'bottom-end',

          sx: {
            // Align the buttons to the end of the row
            [`.${gridClasses.panelFooter}`]: {
              '& .MuiButtonBase-root': {
                paddingLeft: 0.5,
                paddingRight: 0,
                paddingY: 0,
              },
            },
          },
        },
        baseFormControl: {
          sx: {
            label: {
              color: 'neutral.500',
              fontWeight: 700,

              '&.Mui-focused': {
                color: 'neutral.500',
              },
            },
            '&.MuiInputBase-root': {
              '&.Mui-focused': {
                '&::before': {
                  borderColor: 'currentcolor',
                },
                '&::after': {
                  borderColor: 'currentcolor',
                },
              },
            },
          },
        },
        baseSwitch: {
          color: 'success',
        },
        baseSelect: {
          native: false,
          sx: {
            height: '40px',

            // Fix the underline color for standard inputs
            // TODO: Combine this and clean it up somehow?
            '&::before': {
              borderColor: 'neutral.300',
            },
            '&::after': {
              borderColor: 'info.dark',
            },
            '&:hover:not(.Mui-disabled, .Mui-error)::before': {
              borderColor: 'neutral.300',
            },

            '& .MuiInputBase-input': {
              paddingY: 0,
            },
          },
        },
        pagination: {
          labelDisplayedRows: ({ from, to, count }) => {
            return `${from}–${to} of ${count !== -1 ? count : `more than ${to}`} items`;
          },
          SelectProps: {
            input: <PaginationSelectInput />,
          },
          sx: {
            height: '60px',
            marginY: 'auto',

            // This is just laziness but in the interest of time..
            '.MuiToolbar-root': {
              height: '100%',
            },
            '.MuiTablePagination-selectLabel': {
              fontWeight: 600,
              color: 'neutral.600',
              margin: 0,
              padding: 4,
            },
            '.MuiTablePagination-input': {
              margin: 0,
            },
            '.MuiTablePagination-displayedRows': {
              color: 'neutral.600',
              marginX: 6,
              marginY: 0,
              padding: 4,
            },
            '.MuiTablePagination-actions': {
              margin: 0,

              '& .MuiButtonBase-root': {
                '&:not(:disabled)': {
                  color: 'neutral.700',
                },
              },
            },
          },
        },
      }}
    />
  );
};

export * from '@mui/x-data-grid-premium';
