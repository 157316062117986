import * as constants from 'app-state/constants';

import defaultState from './get-reducer-state';

export const shareOptionsInitialState = defaultState({
  data: {
    items: [],
    meta: { pagination: {} },
  },
  active: false,
});

export const shareOptionsReducer = (state = shareOptionsInitialState, action) => {
  switch (action.type) {
    case constants.GET_SHARE_OPTIONS:
    case constants.CLEAR_SHARE_OPTIONS:
      return {
        ...shareOptionsInitialState,
        active: state.active,
      };
    case constants.GET_SHARE_OPTIONS_SUCCESS:
      return {
        ...state,
        data: action.data,
        loading: false,
        error: null,
      };
    case constants.GET_SHARE_OPTIONS_ERROR:
      return {
        ...state,
        data: shareOptionsInitialState.data,
        loading: false,
        error: action.error,
      };
    case constants.GET_SHARE_OPTIONS_DISPLAY_STATE:
      return {
        ...state,
        loading: true,
      };
    case constants.GET_SHARE_OPTIONS_DISPLAY_STATE_SUCCESS:
      return {
        ...state,
        active: action.active,
        loading: false,
        error: null,
      };
    case constants.GET_SHARE_OPTIONS_DISPLAY_STATE_ERROR:
      return {
        ...state,
        active: false,
        loading: false,
        error: action.error,
      };
    default:
      return state;
  }
};

export const shareOptionInitialState = defaultState();

export const shareOptionReducer = (state = shareOptionInitialState, action) => {
  switch (action.type) {
    case constants.GET_SHARE_OPTION:
    case constants.CLEAR_SHARE_OPTION:
      return shareOptionInitialState;
    case constants.GET_SHARE_OPTION_SUCCESS:
      return {
        data: action.data,
        loading: false,
        error: null,
      };
    case constants.GET_SHARE_OPTION_ERROR:
      return {
        data: shareOptionInitialState.data,
        loading: false,
        error: action.error,
      };
    default:
      return state;
  }
};
