const DEALS = 'Deals';
const TRANSACTIONS = 'Transactions';
const HOLDINGS = 'Holdings';
const ACCOUNT = 'Account';
const SIGN_OUT = 'Sign Out';
const OPPORTUNITIES = 'Opportunities';

export * from 'shared/user-menu/constants/authenticated-menu-configs';

// eslint-disable-next-line import/no-unused-modules
export { DEALS, TRANSACTIONS, HOLDINGS, ACCOUNT, SIGN_OUT, OPPORTUNITIES };
