import sanitize from 'sanitize-html';

export default (...args: Parameters<typeof sanitize>) => {
  const defaultArgs = {
    allowedTags: sanitize.defaults.allowedTags.concat(['img']),
    allowedAttributes: {
      '*': ['class', 'style', 'data-e2e'],
      a: ['href', 'target', 'rel'],
      img: ['*'],
    },
  };
  return sanitize(args[0], { ...defaultArgs, ...args[1] });
};
