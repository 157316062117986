import Routes from 'constants/routes';
import {
  ACCOUNT,
  DEALS,
  HOLDINGS,
  OPPORTUNITIES,
  SIGN_OUT,
  TRANSACTIONS,
} from 'shared/user-menu/constants';
import { MenuButton } from 'shared/user-menu/styled';

const getMenuItems = (
  handleLogout: (e: unknown) => void,
  unviewedCommunicationsCount = 0,
  unreadSendbirdMessagesCount = 0,
  isLiquidityEnabled = false,
  isDealsTabEnabled = false,
  isTransactionsTabEnabled = false,
  isCapTableManagementDisabled = false,
) => {
  if (isCapTableManagementDisabled) {
    return [
      { name: DEALS, to: Routes.Deals(), exact: true },
      { name: TRANSACTIONS, to: Routes.Transactions.Root() },
      {
        name: ACCOUNT,
        to: Routes.Account.Root(),
        bubbleContent: unviewedCommunicationsCount + unreadSendbirdMessagesCount,
      },
      { name: SIGN_OUT, onClick: handleLogout, as: MenuButton },
    ];
  }

  return [
    ...(isDealsTabEnabled
      ? [{ name: isLiquidityEnabled ? OPPORTUNITIES : DEALS, to: Routes.Deals(), exact: true }]
      : []),
    ...(isTransactionsTabEnabled ? [{ name: TRANSACTIONS, to: Routes.Transactions.Root() }] : []),
    { name: HOLDINGS, to: Routes.Holdings.Root() },
    {
      name: ACCOUNT,
      to: Routes.Account.Root(),
      bubbleContent: unviewedCommunicationsCount + unreadSendbirdMessagesCount,
    },
    { name: SIGN_OUT, onClick: handleLogout, as: MenuButton },
  ];
};

export {
  getMenuItems, // eslint-disable-line
};
