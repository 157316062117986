import { HIDE_MODAL, HIDE_PREVIEW, SHOW_MODAL, SHOW_PREVIEW } from 'app-state/constants';

export const defaultShowModal = { show: false, content: null, preview: null };

export const modalReducer = (state = defaultShowModal, action) => {
  // eslint-disable-line
  switch (action.type) {
    case SHOW_MODAL:
      return { show: true, content: action.data, preview: null };
    case HIDE_MODAL:
      return { show: false, content: null, preview: null };
    case SHOW_PREVIEW:
      return { ...state, preview: action.data };
    case HIDE_PREVIEW:
      return { ...state, preview: null };
    default:
      return state;
  }
};
