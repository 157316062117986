import { useCallback, useState } from 'react';

import { ApiResponse } from 'app-state/types';

type FetchState = { value?: string; loading: boolean; error: boolean };

export type CachedFetchState = FetchState & { load: (...args: any[]) => void };

// Attempts to load an item from local storage cache, and offers a function for (re)populating
// the cache.
export function useCachedFetch<T>(
  localStorageKey: string,
  getExpiry: (value: T) => Date | string,
  getValue: (value: T) => string,
  fetch: (...args: any[]) => Promise<ApiResponse<T>>,
): CachedFetchState {
  const [query, setQuery] = useState<FetchState>(() => {
    const storedValue = localStorage.getItem(localStorageKey);

    if (storedValue) {
      const parsedValue: T = JSON.parse(storedValue);

      if (new Date(getExpiry(parsedValue)).getTime() > Date.now()) {
        return { value: getValue(parsedValue), loading: false, error: false };
      }
    }
    return { loading: false, error: false };
  });

  const load = useCallback((...args: any[]) => {
    if (query.value) {
      return setQuery({ value: query.value, loading: false, error: false });
    }

    setQuery({ loading: true, error: false });

    fetch(args)
      .then((result: ApiResponse<T>) => {
        setQuery({ value: getValue(result.data), loading: false, error: false });
        localStorage.setItem(localStorageKey, JSON.stringify(result.data));
      })
      .catch((err: any) => {
        console.error('An error occurred while fetching new data', localStorageKey, err);
        setQuery({ loading: false, error: true });
      });
  }, []);

  return { ...query, load };
}
