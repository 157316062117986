import { FC } from 'react';

import {
  DatePicker as MuiDatePicker,
  DatePickerProps,
  DateTimePicker as MuiDateTimePicker,
  DateTimePickerProps,
} from '@mui/x-date-pickers-pro';

import { CalendarIcon } from '../../icons';

/**
 * Necessary for automation testing. Headless will be pointer: none
 * in which case MUI will show mobile. We automate on desktop only
 * atm, so we will have unexpected behaviour.
 */
const desktopModeMediaQuery = '@media not (pointer: coarse)';

const DatePicker: FC<DatePickerProps<any>> = props => {
  return (
    <MuiDatePicker
      slots={{ openPickerIcon: CalendarIcon }}
      desktopModeMediaQuery={desktopModeMediaQuery}
      {...props}
    />
  );
};

export { DatePicker };
export type { DatePickerProps };

const DateTimePicker: FC<DateTimePickerProps<any>> = props => {
  return (
    <MuiDateTimePicker
      slots={{ openPickerIcon: CalendarIcon }}
      desktopModeMediaQuery={desktopModeMediaQuery}
      {...props}
    />
  );
};

export { DateTimePicker };
export type { DateTimePickerProps };
