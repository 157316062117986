import { connect } from 'react-redux';

import { hideModal } from 'app-state/actions';

import TestIcon from 'images/test.svg';

import { Heading1, Modal, OkButton, Paragraph } from './take-test-modal.styled';

const TakeTestModal = ({ close }) => (
  <Modal>
    <img src={TestIcon} width="65" height="65" alt="Take a test" />
    <Heading1>TAKE A SHORT TEST</Heading1>
    <Paragraph>
      We want to make sure you understand the risks of investing in early stage businesses.
    </Paragraph>
    <OkButton className="form-button" title="OK" onClick={close} />
  </Modal>
);

const mapDispatchToProps = dispatch => ({
  close: () => dispatch(hideModal()),
});

export default connect(null, mapDispatchToProps)(TakeTestModal);
