import { push } from 'connected-react-router';

import { call, put, takeEvery } from 'redux-saga/effects';

import * as actions from 'app-state/actions';
import * as constants from 'app-state/constants';

import ModalResponseFailed from 'modules/modal-response-failed';
import ModalResponseSuccess from 'modules/modal-response-success';
import API from 'constants/api';
import Routes from 'constants/routes';
import { request } from 'helpers';

import { getCurrentUser } from './current-user';

const getQueryParams = ({ page, perPage, issuerId, companyName, investorIds }) => {
  let queryParams = `?page=${page}&per_page=${perPage}`;
  if (issuerId) {
    queryParams = queryParams.concat(`&issuer_id=${issuerId}`);
  }
  if (companyName) {
    queryParams = queryParams.concat(`&company_name=${companyName}`);
  }
  if (investorIds) {
    investorIds.forEach(id => {
      queryParams = queryParams.concat(`&investor_ids[]=${id}`);
    });
  }
  return queryParams;
};

function* getCommunications({
  params: { page = 1, perPage = 10, issuerId, companyName, investorIds, signal },
}) {
  try {
    const queryParams = getQueryParams({
      page,
      perPage,
      issuerId,
      companyName,
      investorIds,
    });
    const url =
      !issuerId && !companyName && !investorIds
        ? API.Investment.communications(page, perPage)
        : API.Investment.investmentCommunications(queryParams);

    const { data } = yield call(request, url, 'GET', null, { signal });

    const { pagination = {} } = data.meta || {};

    const redirectToFirstPage = pagination.totalPages && pagination.page > pagination.totalPages;
    if (redirectToFirstPage) {
      return yield getCommunications({ params: { page: 1, perPage } });
    }
    yield put(actions.getCommunicationsSuccess(data));
    yield put(push(Routes.Account.Notifications(`/${page}`, `/${perPage}`)));
  } catch (e) {
    yield put(actions.getCommunicationsError(e));
  }
}

function* getCommunicationsWatcher() {
  yield takeEvery(constants.GET_COMMUNICATIONS, getCommunications);
}

function* getCommunication({ communicationUuid }) {
  try {
    const { data } = yield call(request, API.Investment.communication(communicationUuid), 'GET');

    yield put(actions.getCommunicationSuccess(data));
    yield getCurrentUser();
  } catch (e) {
    yield put(actions.getCommunicationError(e));
  }
}

function* getCommunicationWatcher() {
  yield takeEvery(constants.GET_COMMUNICATION, getCommunication);
}

function* addCommunicationResponse({ communicationUuid, responseValue }) {
  try {
    yield call(request, API.Investment.communicationsResponse(communicationUuid), 'POST', {
      responseTypeId: 0,
      respondValue: responseValue,
    });

    yield getCurrentUser();

    yield put(
      actions.showModal({
        closable: true,
        showHeader: false,
        component: ModalResponseSuccess,
      }),
    );

    yield put(actions.getCommunication({ communicationUuid }));
  } catch (e) {
    yield put(
      actions.showModal({
        closable: true,
        showHeader: false,
        component: ModalResponseFailed,
      }),
    );
  }
}

function* addCommunicationResponseWatcher() {
  yield takeEvery(constants.ADD_COMMUNICATION_RESPONSE, addCommunicationResponse);
}

export {
  getCommunications,
  getCommunicationsWatcher,
  getCommunication,
  getCommunicationWatcher,
  addCommunicationResponse,
  addCommunicationResponseWatcher,
};
