import { Form } from 'formik';
import styled from 'styled-components';

import { TextButton } from 'shared-parts/components/button';
import * as Colors from 'shared-parts/constants/colors';
import * as Fonts from 'shared-parts/constants/fonts';

const FormWrapper = styled(Form)`
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  width: 580px;
  max-width: 90vw;
  height: fit-content;
  padding: 50px 98px;
`;

const ButtonWrapper = styled.div`
  margin-top: 53px;
`;

const Title = styled.div`
  font-size: 35px;
  line-height: 45px;
  margin: 22px 0 24px;
  font-weight: 300;
  color: ${Colors.primary};
  font-family: ${Fonts.oswaldFont};
  text-align: center;
`;

const Description = styled.p`
  color: ${Colors.neutral800};
  font-size: 16px;
  line-height: 24px;
  font-family: ${Fonts.mainFont};
  text-align: center;
`;

const ManualAddressFormGroup = styled.div`
  margin: 15px 0 0 0;
  width: 100%;
`;

const StyledTextButton = styled(TextButton)`
  margin-right: 20px;
`;

export { Title, Description, FormWrapper, ButtonWrapper, StyledTextButton, ManualAddressFormGroup };
