import { FC } from 'react';

import { alternative, neutral300 } from 'shared-parts/constants/colors';

interface CopyIconProps {
  width?: number;
  height?: number;
  color?: string;
  secondaryColor?: string;
}

const CopyIcon: FC<React.PropsWithChildren<CopyIconProps>> = ({
  width = 16.715,
  height = 20,
  color = alternative,
  secondaryColor = neutral300,
}) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={width.toString()}
    height={height.toString()}
    viewBox="0 0 16.715 20"
  >
    <g id="Group_8479" data-name="Group 8479" transform="translate(0 0)">
      <g id="Group_8446" data-name="Group 8446" transform="translate(0 0)">
        <path
          id="Path_1889"
          data-name="Path 1889"
          d="M25.335,4.38a1.423,1.423,0,0,0-1.045-.43H13.475A1.469,1.469,0,0,0,12,5.425V19.19a1.469,1.469,0,0,0,1.475,1.475H24.29a1.469,1.469,0,0,0,1.475-1.475V5.425A1.423,1.423,0,0,0,25.335,4.38ZM24.29,19.19H13.475V5.425H24.29Z"
          transform="translate(-9.05 -3.95)"
          fill={color}
        />
        <path
          id="Path_1890"
          data-name="Path 1890"
          d="M7.475,27.1A1.469,1.469,0,0,1,6,25.622V11.537a.738.738,0,1,1,1.475,0V25.622H18.389a.738.738,0,1,1,0,1.475Z"
          transform="translate(-6 -7.097)"
          fill={secondaryColor}
        />
      </g>
    </g>
  </svg>
);

export default CopyIcon;
