import { useEffect, useState } from 'react';

import useSearchParams from 'shared-parts/hooks/use-search-params';

/**
 * Retrieve a specific query string parameter from the current url.
 * @example
 * // Assuming current URL is www.example.com/page?userId=abc
 * const userId = useQueryStringPram('userId') // abc
 * @param key They key of the query string parameter.
 * @returns {String} The value stored in the query string with the provided key.
 */
// eslint-disable-next-line import/prefer-default-export
export function useQueryStringParam(key: string): string | undefined {
  const { search } = useSearchParams();
  const [value, setValue] = useState<string>(search && search[key]);

  useEffect(() => {
    const queryValue = search && search[key];

    if (value !== queryValue) {
      setValue(queryValue);
    }
  }, [search]);

  return value;
}
