import { FC, useEffect } from 'react';
import { PlaidLinkOptions, usePlaidLink } from 'react-plaid-link';

export type PlaidReadyChangeHandler = (ready: boolean, open: () => void) => void;

type PlaidLinkProps = PlaidLinkOptions & {
  onReadyChange: PlaidReadyChangeHandler;
};

/**
 * react-plaid-link offers two entry points: A hook (which cannot be conditional due to
 * rule of hooks) or a component, which opens the Plaid Link window the second it can.
 * This gives us a meet-in-the-middle of a conditional entry point with a lazily fired open fn
 */
export const PlaidLink: FC<React.PropsWithChildren<PlaidLinkProps>> = ({
  onReadyChange,
  children,
  ...plaidLinkProps
}) => {
  const { open, ready } = usePlaidLink(plaidLinkProps);

  useEffect(() => {
    // `open` is a `Function` type which is one of our banned types (and rightfully so!)
    onReadyChange(ready, open as () => void);
  }, [ready]);

  return <>{children}</>;
};
