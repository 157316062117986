export const GET_COMMUNICATIONS = 'GET_COMMUNICATIONS';
export const GET_COMMUNICATIONS_SUCCESS = 'GET_COMMUNICATIONS_SUCCESS';
export const GET_COMMUNICATIONS_ERROR = 'GET_COMMUNICATIONS_ERROR';

export const CLEAR_COMMUNICATIONS = 'CLEAR_COMMUNICATIONS';

export const GET_COMMUNICATIONS_QUANTITY = 'GET_COMMUNICATIONS_QUANTITY';
export const GET_COMMUNICATIONS_QUANTITY_SUCCESS = 'GET_COMMUNICATIONS_QUANTITY_SUCCESS';
export const GET_COMMUNICATIONS_QUANTITY_ERROR = 'GET_COMMUNICATIONS_QUANTITY_ERROR';

export const GET_COMMUNICATION = 'GET_COMMUNICATION';
export const GET_COMMUNICATION_SUCCESS = 'GET_COMMUNICATION_SUCCESS';
export const GET_COMMUNICATION_ERROR = 'GET_COMMUNICATION_ERROR';
export const CLEAR_COMMUNICATION = 'CLEAR_COMMUNICATION';

export const ADD_COMMUNICATION_RESPONSE = 'ADD_COMMUNICATION_RESPONSE';
export const ADD_COMMUNICATION_RESPONSE_ERROR = 'ADD_COMMUNICATION_RESPONSE_ERROR';
