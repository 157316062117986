import { connectRouter } from 'connected-react-router';
import { sessionReducer } from 'redux-react-session';

import { History, LocationState } from 'history';
import { combineReducers } from 'redux';

import { currenciesReducer } from 'app-state/currencies/reducers';
import { downloadCSVReducer } from 'app-state/download-csv/download-csv.reducer';
import { issuerReducer } from 'app-state/reducers/issuer';
import { tradeSharesReducer } from 'app-state/trade-shares/trade-shares.reducer';

import {
  indicationOfInterestItemReducer,
  indicationOfInterestReducer,
  investorAnchorStatusReducer,
} from '../indication-of-interest/reducers';
import { investorCompaniesReducer } from '../investors/reducers';
import { loaderReducer } from '../loader/reducers';
import { sharePlanHoldersReducer } from '../share-plan-holders/share-plan-holders.reducer';

import { loginReducer, signUpReducer, twoFactorAuthCodesReducer } from './authentication';
import {
  communicationReducer,
  communicationsQuantityReducer,
  communicationsReducer,
} from './communications';
import {
  companiesReducer,
  companyGroupsReducer,
  companyReducer,
  companySummaryReducer,
} from './companies';
import { contributionReducer } from './contribution';
import { countriesReducer } from './countries';
import { currencyRatesReducer } from './currency-rate';
import { dealDocumentsReducer, dealReducer, pageReducer, riskBannerReducer } from './deal';
import { dealsReducer } from './deals';
import {
  allCountriesReducer,
  individualInvestorTypesReducer,
  investorAddressReducer,
  onboardingStateReducer,
  supportedCountriesReducer,
  unsupportedCountriesReducer,
} from './individual-onboarding';
import { investmentPreferencesReducer } from './institutional-onboarding';
import {
  investmentsReducer,
  secondaryInvestmentReducer,
  secondaryInvestmentsReducer,
  sellInvestmentsFormReducer,
} from './investments';
import { issuanceReducer } from './issuance';
import { issuersReducer, shareholdingIssuersReducer } from './issuers';
import { modalReducer } from './modal';
import { navigationReducer, subNavigationReducer } from './navigation';
import { onboardingReducer } from './onboarding';
import { orderReducer as shareOptionsOrderReducer } from './order';
import { bankAccountReducer, orderReducer, orders, ordersFilterReducer } from './orders';
import { redirectPathReducer } from './redirection';
import { shareIncentivePlanReducer, shareIncentivePlansReducer } from './share-incentive-plan';
import { shareOptionReducer, shareOptionsReducer } from './share-options';
import { dividendOrdersReducer } from './sip-orders';
import {
  suitabilityTestAttemptsErrorReducer,
  suitabilityTestQuestionsReducer,
  suitabilityTestResultReducer,
} from './suitability-test';
import { rowScrollDataReducer } from './table';
import { walletsDepositsReducer, walletsReducer } from './wallets';

const createRootReducer = <S = LocationState>(history: History<S>) =>
  combineReducers({
    router: connectRouter(history),
    ordersFilter: ordersFilterReducer,
    order: orderReducer,
    orders,
    investorAddress: investorAddressReducer,
    companies: companiesReducer,
    company: companyReducer,
    companyGroups: companyGroupsReducer,
    companySummary: companySummaryReducer,
    deals: dealsReducer,
    loader: loaderReducer,
    navigation: navigationReducer,
    subNavigation: subNavigationReducer,
    suitabilityTestQuestions: suitabilityTestQuestionsReducer,
    suitabilityTestResult: suitabilityTestResultReducer,
    suitabilityTestAttemptsError: suitabilityTestAttemptsErrorReducer,
    page: pageReducer,
    session: sessionReducer,
    currencyRates: currencyRatesReducer,
    wallets: walletsReducer,
    investments: investmentsReducer,
    secondaryInvestments: secondaryInvestmentsReducer,
    secondaryInvestment: secondaryInvestmentReducer,
    sellInvestmentsForm: sellInvestmentsFormReducer,
    supportedCountries: supportedCountriesReducer,
    unsupportedCountries: unsupportedCountriesReducer,
    allCountries: allCountriesReducer,
    issuance: issuanceReducer,
    dealDocuments: dealDocumentsReducer,
    bankAccount: bankAccountReducer,
    modal: modalReducer,
    currencies: currenciesReducer,
    countries: countriesReducer,
    walletsDeposits: walletsDepositsReducer,
    onboardingState: onboardingStateReducer,
    individualInvestorTypes: individualInvestorTypesReducer,
    redirectPath: redirectPathReducer,
    deal: dealReducer,
    riskBanner: riskBannerReducer,
    communication: communicationReducer,
    communications: communicationsReducer,
    communicationsQuantity: communicationsQuantityReducer,
    rowScrollData: rowScrollDataReducer,
    onboardingConfig: onboardingReducer,
    shareOptions: shareOptionsReducer,
    dividendOrders: dividendOrdersReducer,
    shareOption: shareOptionReducer,
    signUp: signUpReducer,
    login: loginReducer,
    issuers: issuersReducer,
    shareholdingIssuers: shareholdingIssuersReducer,
    indicationOfInterest: indicationOfInterestReducer,
    investorAnchorStatus: investorAnchorStatusReducer,
    indicationOfInterestItem: indicationOfInterestItemReducer,
    investorCompanies: investorCompaniesReducer,
    preferences: investmentPreferencesReducer,
    twoFactorAuthCodes: twoFactorAuthCodesReducer,
    shareIncentivePlans: shareIncentivePlansReducer,
    shareIncentivePlan: shareIncentivePlanReducer,
    contribution: contributionReducer,
    issuer: issuerReducer,
    shareOptionsOrder: shareOptionsOrderReducer,
    downloadCSV: downloadCSVReducer,
    tradeShares: tradeSharesReducer,
    sharePlanHolders: sharePlanHoldersReducer,
  });

export default createRootReducer;
