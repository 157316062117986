import { forwardRef, ReactNode } from 'react';

import {
  CheckCircleOutline as CheckCircleOutlineIcon,
  CloseOutlined,
  ErrorOutline as ErrorOutlineIcon,
  InfoOutlined as InfoOutlinedIcon,
} from '@mui/icons-material';
import { Dialog as MuiDialog, DialogProps as MuiDialogProps, styled } from '@mui/material';

import { IconButton } from '../buttons';

import { DialogIcon } from './dialog-icon';

export type DialogProps = MuiDialogProps & {
  severity?: 'success' | 'info' | 'warning' | 'error';
  Icon?: ReactNode;
};

const defaultIconMapping: Record<NonNullable<DialogProps['severity']>, ReactNode> = {
  success: <CheckCircleOutlineIcon />,
  warning: <ErrorOutlineIcon />,
  error: <ErrorOutlineIcon />,
  info: <InfoOutlinedIcon />,
};

// TODO: Ideally this would be an IconButton but the extra padding gets weird
// const DeleteButton = styled('button')({
//   position: 'absolute',
//   padding: 0,
//   border: 'none',
//   backgroundColor: 'transparent',
//   color: 'inherit',
//   top: 28,
//   right: 28,
//   cursor: 'pointer',
// });

const DeleteButton = styled(IconButton)({
  position: 'absolute',
  // We want this anchored at the top right - padding (28px)
  // And IconButton has 5px padding each way, so offset by 5px
  top: 23,
  right: 23,
});

const DialogBase = forwardRef<HTMLDivElement, DialogProps>((props, ref) => {
  const { severity, Icon, children, ...dialogProps } = props;

  return (
    <MuiDialog {...dialogProps} ref={ref}>
      {props.onClose && (
        <DeleteButton onClick={() => props.onClose?.({}, 'backdropClick')}>
          <CloseOutlined />
        </DeleteButton>
      )}
      {Icon}
      {!Icon && severity && (
        <DialogIcon color={severity}>{defaultIconMapping[severity]}</DialogIcon>
      )}
      {children}
    </MuiDialog>
  );
});

export const Dialog = styled(DialogBase)(({ theme }) => ({
  '& .MuiDialog-paper': {
    backgroundColor: theme.palette.neutral[25],
    borderRadius: '12px',
    boxShadow: '0px 20px 32px 0px rgba(96, 97, 112, 0.24), 0px 2px 8px 0px rgba(40, 41, 61, 0.08)',
    padding: theme.spacing(6, 0),
  },
}));
