import { call, put, takeEvery } from 'redux-saga/effects';

import { getCountriesError, getCountriesSuccess } from 'app-state/actions';
import { GET_COUNTRIES } from 'app-state/constants';

import API from 'constants/api';
import request from 'shared-parts/helpers/request';

function* getCountries() {
  try {
    const { data } = yield call(request, API.GetCountries());
    let countryList = data.topList ? data.topList : [];
    countryList = data.bottomList ? [...countryList, ...data.bottomList] : countryList;

    yield put(getCountriesSuccess(countryList));
  } catch (e) {
    yield put(getCountriesError(e));
  }
}

function* getCountriesWatcher() {
  yield takeEvery(GET_COUNTRIES, getCountries);
}

export { getCountries, getCountriesWatcher };
