import { createContext, PropsWithChildren, useContext } from 'react';

import { GraphQLClient } from 'graphql-request';

const GraphQlClientContext = createContext<GraphQLClient>(new GraphQLClient(''));

export type GraphQLClientProviderOptions = {
  client: GraphQLClient;
};

export const GraphQLClientProvider = ({
  client,
  children,
}: PropsWithChildren<GraphQLClientProviderOptions>) => {
  return <GraphQlClientContext.Provider value={client}>{children}</GraphQlClientContext.Provider>;
};

export const useGraphQLClient = () => useContext(GraphQlClientContext);
