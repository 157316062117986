import styled from 'styled-components';

import * as Colors from 'shared-parts/constants/colors';

import FormikButton from './formik-button';

export const ActionButton = styled(FormikButton)`
  min-height: 40px;
  height: auto;
`;

export const SecondaryActionButton = styled(ActionButton)`
  background-color: transparent;
  color: ${Colors.alternative};
  border: 1px solid ${Colors.alternative};
`;
