import 'react-toastify/dist/ReactToastify.css';
import type { FC } from 'react';
import { toast, ToastContainer } from 'react-toastify';

import { ToasterWrapper } from './toaster.styled';

const Toaster: FC<React.PropsWithChildren<unknown>> = () => (
  <ToasterWrapper>
    <ToastContainer
      position={toast.POSITION.BOTTOM_CENTER}
      autoClose={3000}
      hideProgressBar
      newestOnTop
      closeOnClick
      rtl={false}
      pauseOnFocusLoss
      draggable
      pauseOnHover
      closeButton={false}
    />
  </ToasterWrapper>
);

export default Toaster;
