import { OrderWorkflowState } from './types';

export const ClosedEndFundOrderStatusMap = {
  [OrderWorkflowState.InProgress]: 'In Progress',
  [OrderWorkflowState.SubscriptionReview]: 'Subscription Review',
  [OrderWorkflowState.ReIssued]: 'Re-issued',
  [OrderWorkflowState.CountersignRequired]: 'Countersign Required',
  [OrderWorkflowState.Approved]: 'Approved',
  [OrderWorkflowState.AwaitingClosing]: 'Awaiting Closing',
  [OrderWorkflowState.Cancelled]: 'Cancelled',
  [OrderWorkflowState.Completed]: 'Completed',
} as const satisfies Record<OrderWorkflowState, string>;
