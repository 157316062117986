import { Box, BoxProps } from '@mui/material';

export interface TabPanelProps extends BoxProps {
  index: number | string;
  value: number | string;
}

/**
 * Component that puts accesibility properties to tabs that are rendered conditionally.
 * See references for usage examples.
 */
export const TabPanel: React.FC<TabPanelProps> = ({ children, value, index, ...boxProps }) => {
  return (
    <Box
      role="tabpanel"
      hidden={value !== index}
      id={`tabpanel-${index}`}
      aria-labelledby={`tab-${index}`}
      {...boxProps}
    >
      {value === index && children}
    </Box>
  );
};
