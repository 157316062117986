import { Palette } from '@mui/material';
import MuiChip, { ChipProps as MuiChipProps } from '@mui/material/Chip';
import { PaletteColor, styled } from '@mui/material/styles';

import { Clear as ClearIcon } from '../../icons';

export type ChipProps = MuiChipProps;

export const Chip = styled((props: ChipProps) => <MuiChip deleteIcon={<ClearIcon />} {...props} />)(
  ({ theme }) => {
    return {
      variants: Object.entries(theme.palette)
        .filter(([, value]) => value && value.main)
        .map(([color]) => {
          const palette = theme.palette[color as keyof Palette] as PaletteColor;

          return {
            props: { color },
            style: {
              backgroundColor: palette.light,
            },
          };
        }),
    };
  },
);
