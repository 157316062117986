export function checkPathSuitability(
  urlParams: { path: string; exact?: boolean },
  browserPath: string,
) {
  const { path, exact } = urlParams;

  if (path === undefined) {
    return false;
  }

  const splittedPath = path.split('/');
  const splittedBrowserPathname = browserPath.split('/');
  const exactModeCanBeUsed = splittedPath.length === splittedBrowserPathname.length;

  if (exact && !exactModeCanBeUsed) {
    return false;
  }

  const browserPathIsLonger = splittedBrowserPathname.length > splittedPath.length;
  const longer = (browserPathIsLonger && splittedBrowserPathname) || splittedPath;
  const shorter = (browserPathIsLonger && splittedPath) || splittedBrowserPathname;

  return longer.every((curr, id) => {
    const partDoesntExist = browserPathIsLonger && !shorter[id];
    const stringToCheckParams = browserPathIsLonger ? shorter[id] : curr;
    const urlParameterFound = stringToCheckParams && stringToCheckParams.includes(':');
    const partsAreEqual = shorter[id] === curr;

    return partDoesntExist || urlParameterFound || partsAreEqual;
  });
}
