import styled from 'styled-components';

import * as Colors from 'shared-parts/constants/colors';
import * as Fonts from 'shared-parts/constants/fonts';

const MultiActionsContainer = styled.div`
  position: relative;
  height: 100%;

  & > div {
    height: 100%;
  }
`;

const MultiActionsArrowButton = styled.button`
  border: none;
  background: transparent;
  min-height: 40px;
  height: 100%;
  width: 100%;
  padding: 0;
  display: flex;
  justify-content: center;
  align-items: center;
`;

const MultiActionsArrow = styled.div`
  cursor: pointer;
  width: 0;
  height: 0;
  border-left: 5px solid transparent;
  border-right: 5px solid transparent;

  ${({ isSelectOpen }) =>
    isSelectOpen
      ? `
    border-bottom: 5px solid ${Colors.neutral500};
  `
      : `
    border-top: 5px solid ${Colors.neutral500};
  `}
`;

const MultiActionsSelect = styled.div`
  z-index: 1;
  border-radius: 6px;
  position: absolute;
  width: max-content;
  top: 30px;
  right: 14px;
  background: white;
  border: 1px solid ${Colors.neutral300};
`;

const MultiActionOption = styled.div`
  cursor: pointer;
  height: 38px;
  font-weight: bold;
  font-family: ${Fonts.mainFont};
  padding: 10px;
  border-bottom: 1px solid ${Colors.neutral300};
  white-space: nowrap;

  :first-child {
    border-top-left-radius: 5px;
    border-top-right-radius: 5px;
  }

  :last-child {
    border-bottom-left-radius: 5px;
    border-bottom-right-radius: 5px;
    border-bottom: none;
  }

  :disabled {
    cursor: default;
  }

  ${({ isActionDisabled }) =>
    isActionDisabled
      ? `
    color: ${Colors.neutral500};
    cursor: default;
    pointer-events: none;
  `
      : `
    color: ${Colors.primary};
    :hover {
      background: ${Colors.neutral200};
    }
  `}
`;

export {
  MultiActionsContainer,
  MultiActionsArrowButton,
  MultiActionsArrow,
  MultiActionsSelect,
  MultiActionOption,
};
