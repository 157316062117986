import { Iframe, Video, VideoWrapper } from './box-video.styled';

interface BoxVideo {
  directVideoURL: string;
  url: string;
}

const BoxVideo = ({ directVideoURL, url }: BoxVideo): JSX.Element => {
  /* eslint-disable */
  const renderVideo = () => (
    <Video controls>
      <source type="video/mp4" src={directVideoURL} />
    </Video>
  );
  /* eslint-enable */

  const renderIframe = () => (
    <Iframe
      title={url}
      src={`https://player.vimeo.com/video/${url}`}
      frameBorder="0"
      allowFullScreen
      allow="fullscreen"
      loading="lazy"
    />
  );

  return <VideoWrapper>{directVideoURL ? renderVideo() : renderIframe()}</VideoWrapper>;
};

export default BoxVideo;
