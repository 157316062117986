import { styled } from '@mui/material';

const getFundedBarWidth = ({ funded }: { funded: number }) =>
  funded > 100 ? '100%' : `${funded}%`;

export const FundedBar = styled('div')<{ funded: number; secondaryColor: string }>`
  background: ${({ secondaryColor }) => secondaryColor};
  height: 100%;
  position: absolute;
  top: 0;
  width: ${getFundedBarWidth};
`;
