import { push } from 'connected-react-router';

import { all, call, cancel, fork, join, put, select, spawn, takeEvery } from 'redux-saga/effects';

import { IssuanceStatus } from '@frontend/opportunities';

import {
  fetchDealDocumentsSuccess,
  fetchDealError,
  fetchDealPagesDataError,
  fetchDealSuccess,
  fetchIssuanceError,
  fetchIssuanceSuccess,
  fetchPageError,
  fetchPageRestricted,
  fetchPageSuccess,
  showOrderDetailsFormError,
  updateAgreedNDADealDocument,
} from 'app-state/actions/deal';
import { fetchSubNavigationError, fetchSubNavigationSuccess } from 'app-state/actions/navigation';
import { hideModal, showModal } from 'app-state/actions/shared';
import * as constants from 'app-state/constants';
import { getInvestorAnchorStatus } from 'app-state/indication-of-interest/actions';
import { hideLoader, showLoader } from 'app-state/loader/actions';
import { handleRestriction, isRestrictedDeal } from 'app-state/sagas/deal.helpers';
import { getAuthenticationStatus } from 'app-state/selectors/authentication';

import API from 'constants/api';
import Routes from 'constants/routes';
import ErrorModal from 'shared-parts/components/modal-failed';
import { defaultErrorMessage } from 'shared-parts/constants';
import gtmTrack from 'shared-parts/helpers/gtm-track';
import memorizePath from 'shared-parts/helpers/memorize-path';
import request from 'shared-parts/helpers/request';
import { getURLParameters } from 'shared-parts/helpers/url-parameters';

import { fetchCompany } from './companies';
import { fetchNavigation, fetchSubNavigation } from './navigation';

function* fetchIssuance({ companyUuid, modelUuid }) {
  try {
    const response = yield call(request, API.Companies.issuance(companyUuid, modelUuid));

    yield put(fetchIssuanceSuccess(response.data));
    return yield response;
  } catch (e) {
    yield put(fetchIssuanceError(e));
    return yield e;
  }
}

function* fetchIssuanceWatcher() {
  yield takeEvery(constants.FETCH_ISSUANCE, fetchIssuance);
}

function* trackDealVisitAndGetNavigationItems({ deal }) {
  yield put(fetchDealSuccess(deal));

  const { uuid, company, permalink } = deal;
  const gtmEventData = {
    issuance: {
      id: deal.id,
      issuer: company.name,
    },
  };

  yield gtmTrack(gtmEventData, 'GCDealOpened');

  const items = yield call(fetchNavigation, { modelUuid: uuid, permalink });
  const effects = items
    .filter(({ hasSubnavigationItem }) => hasSubnavigationItem)
    .reduce(
      (prev, { id, url }) => ({
        ...prev,
        [url]: call(fetchSubNavigation, {
          permalink,
          navigationItemId: id,
          navigationItemURL: url,
        }),
      }),
      {},
    );
  const subItems = yield all(effects);
  const failed = Object.values(subItems).every(item => !item);

  if (failed) {
    yield put(fetchSubNavigationError({}));
  } else {
    yield put(fetchSubNavigationSuccess(subItems));
  }
}

function* fetchDealDocuments({ payload: { dealUuid } }) {
  try {
    const { data } = yield call(request, API.Investment.dealDocuments(dealUuid));
    yield put(fetchDealDocumentsSuccess(data));
  } catch (e) {
    yield put(
      showModal({
        closable: true,
        showHeader: false,
        component: ErrorModal,
        title: defaultErrorMessage,
      }),
    );
  }
}

function* fetchDealDocumentsWatcher() {
  yield takeEvery(constants.FETCH_DEAL_DOCUMENTS, fetchDealDocuments);
}

function* agreeNDADealDocument({ payload: { dealUuid, documentId, redirectUrl } }) {
  try {
    yield call(request, API.Investment.agreeNDADealDocument(dealUuid, documentId), 'PUT');
    yield put(updateAgreedNDADealDocument(documentId));
    yield put(push(redirectUrl));
    yield put(hideModal());
  } catch (e) {
    yield put(
      showModal({
        closable: true,
        showHeader: false,
        component: ErrorModal,
        title: defaultErrorMessage,
      }),
    );
  }
}

function* agreeNDADealDocumentWatcher() {
  yield takeEvery(constants.AGREE_NDA_DEAL_DOCUMENT, agreeNDADealDocument);
}

function* requestDealNDAESignature({ payload: { dealUuid, documentId, hideModalOnSuccess } }) {
  try {
    yield call(request, API.Investment.DealNDAESignatureRequest(dealUuid, documentId), 'POST');
    if (hideModalOnSuccess) {
      yield put(hideModal());
    }
  } catch (e) {
    yield put(
      showModal({
        closable: true,
        showHeader: false,
        component: ErrorModal,
        title: defaultErrorMessage,
      }),
    );
  }
}

function* requestDealNDAESignatureWatcher() {
  yield takeEvery(constants.REQUEST_DEAL_NDA_E_SIGNATURE, requestDealNDAESignature);
}

function* fetchDeal({
  permalink,
  navigationURL,
  shouldRedirectOnLogout = true,
  redirectToForbidden,
}) {
  try {
    const [actionToken] = getURLParameters(['actionToken']);
    const { data: deal } = yield call(request, API.deal(permalink, actionToken), 'GET', null, {
      shouldRedirectOnLogout,
    });
    const authenticated = yield select(getAuthenticationStatus);

    yield put(getInvestorAnchorStatus({ permalink }));
    // checking if authenticated, otherwise tokenised/reusable link will not work
    if (deal.status !== IssuanceStatus.PENDING && deal.ndaRequired && authenticated) {
      yield call(fetchDealDocuments, { payload: { dealUuid: deal.uuid } });
    }

    yield put(fetchDealSuccess(deal));

    yield spawn(trackDealVisitAndGetNavigationItems, { deal });
  } catch (e) {
    yield put(fetchDealError(e));

    if (isRestrictedDeal(e)) {
      return handleRestriction(permalink);
    }

    if (e.status === 403 && shouldRedirectOnLogout) {
      const authenticated = yield select(getAuthenticationStatus);

      yield put(hideLoader());

      if (authenticated) {
        return yield put(push(Routes.Root()));
      }

      memorizePath();
      return redirectToForbidden();
    }

    if (e.status === 404) {
      const [companyUuid, modelUuid] = [permalink, navigationURL];
      const companyTask = yield fork(fetchCompany, { companyUuid, modelUuid });
      const { data: issuance, status } = yield call(fetchIssuance, { companyUuid, modelUuid });

      if (status === 403) {
        yield cancel(companyTask);
        yield put(hideLoader());
        return yield put(push(Routes.Root()));
      }

      const { data: company } = yield join(companyTask);

      yield put(push(Routes.Deal.Page(issuance.permalink, '/deal-summary', '')));
      yield put(hideLoader());
      yield spawn(trackDealVisitAndGetNavigationItems, { deal: { ...issuance, company } });
    }
  }
}

function* fetchTheDealWatcher() {
  yield takeEvery(constants.FETCH_DEAL, fetchDeal);
}

function* fetchThePage({ id, restricted }) {
  if (restricted) {
    return yield put(fetchPageRestricted());
  }

  try {
    const { data } = yield call(request, API.Pages.retrieve(id), 'GET', null, {
      shouldRedirectOnLogout: false,
    });
    yield put(fetchPageSuccess(data));
  } catch (e) {
    yield put(fetchPageError(e));
  }
}

function* fetchPageWatcher() {
  yield takeEvery(constants.FETCH_PAGE, fetchThePage);
}

function* fetchTheDealPagesData({
  permalink,
  navigationURL,
  redirectToNotFound,
  redirectToForbidden,
}) {
  try {
    yield put(showLoader());
    yield call(fetchDeal, {
      permalink,
      navigationURL,
      shouldRedirectOnLogout: false,
      redirectToForbidden,
    });
  } catch (e) {
    yield put(fetchDealPagesDataError(e));
    redirectToNotFound();
  }
  yield put(hideLoader());
}

function* fetchDealPagesDataWatcher() {
  yield takeEvery(constants.FETCH_DEAL_PAGES_DATA, fetchTheDealPagesData);
}

function* showOrderDetailsForm({ modalOptions }) {
  try {
    yield put(showModal(modalOptions));
  } catch (e) {
    yield put(showOrderDetailsFormError(e));
    return yield e;
  }
}

function* showOrderDetailsFormWatcher() {
  yield takeEvery(constants.SHOW_ORDER_DETAILS_FORM, showOrderDetailsForm);
}

export {
  fetchDeal,
  fetchTheDealWatcher,
  fetchDealDocumentsWatcher,
  agreeNDADealDocumentWatcher,
  requestDealNDAESignature,
  requestDealNDAESignatureWatcher,
  fetchThePage,
  fetchPageWatcher,
  trackDealVisitAndGetNavigationItems,
  fetchIssuance,
  fetchIssuanceWatcher,
  fetchTheDealPagesData,
  fetchDealPagesDataWatcher,
  showOrderDetailsForm,
  showOrderDetailsFormWatcher,
};
