import { Box, Paper, PaperProps, styled, Theme, Typography } from '@mui/material';

import {
  ClickableAnchorProps,
  DashedBoxProps,
  DocumentNameTextProps,
  UploadDocumentTextProps,
} from './types';

const getBorderColor = (
  theme: Theme,
  isDragOver: boolean,
  isDocumentAdded: boolean,
  isDisabled: boolean,
) => {
  if (isDisabled) return theme.palette.neutral[300];
  if (isDragOver || isDocumentAdded) return theme.palette.info.dark;
  return theme.palette.neutral[500];
};

const getDocumentNameColor = (theme: Theme, isDocumentAdded: boolean, isDisabled: boolean) => {
  if (isDisabled) return theme.palette.neutral[300];
  if (isDocumentAdded) return theme.palette.info.main;
  return theme.palette.neutral[500];
};

export const StyledPaper = styled(Paper)<PaperProps>(({ theme }) => ({
  padding: theme.spacing(2.5),
  backgroundColor: theme.palette.neutral[25],
}));

export const DashedBox = styled(Box)<DashedBoxProps>(
  ({ theme, isDragOver, isDocumentAdded, isDisabled }) => ({
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    gap: '9px',
    width: '100%',
    padding: theme.spacing(2),
    textDecoration: 'none',
    color: 'inherit',
    border: `1px dashed ${getBorderColor(theme, !!isDragOver, !!isDocumentAdded, !!isDisabled)}`,
    borderRadius: theme.shape.borderRadius,
  }),
);

export const ClickableAnchor = styled('a')<ClickableAnchorProps>(({ isDisabled }) => ({
  width: '100%',
  textDecoration: 'none',
  color: 'inherit',
  pointerEvents: !isDisabled ? 'auto' : 'none',
}));

export const DocumentUploadRow = styled(Box)(() => ({
  height: '35px',
  width: '100%',
  display: 'flex',
  flexDirection: 'row',
  alignItems: 'center',
  justifyContent: 'center',
  gap: '8px',
}));

export const DocumentNameText = styled(Typography)<DocumentNameTextProps>(
  ({ theme, isDocumentAdded, hasUrl, isDisabled }) => ({
    fontWeight: isDocumentAdded ? 700 : 400,
    color: getDocumentNameColor(theme, !!isDocumentAdded, !!isDisabled),
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    cursor: hasUrl ? 'pointer' : 'default',

    '&:hover': {
      textDecoration: hasUrl ? 'underline' : 'none',
    },
  }),
);

export const UploadDocumentText = styled(Typography)<UploadDocumentTextProps>(
  ({ theme, isDisabled }) => ({
    fontWeight: 700,
    color: !isDisabled ? theme.palette.neutral[600] : theme.palette.neutral[300],
    cursor: !isDisabled ? 'pointer' : 'default',
  }),
);

export const ChildrenBox = styled(Box)(() => ({
  display: 'flex',
  flexDirection: 'column',
  gap: '8px',
  marginTop: '8px',
}));
