import { useState } from 'react';
// This works fine for us! React18 has this stable
// and it's way better than merging state tbh
import { unstable_batchedUpdates as batchUpdates } from 'react-dom';

import {
  DataGridPremiumProps,
  GridApi,
  GridColDef,
  GridFilterModel,
  GridGroupingColDefOverride,
  GridLogicOperator,
  GridPaginationModel,
  GridSortModel,
  GridValidRowModel,
  useGridApiRef,
} from '@mui/x-data-grid-premium';
import set from 'lodash/set';

import { FilterTransformer, toGraphQLFilter } from './filter-map';

export type GridQueryColDef<R extends GridValidRowModel = any, V = any, F = V> = GridColDef<
  R,
  V,
  F
> & {
  filterTransformer?: FilterTransformer;
  sortTransformer?: (sort: 'ASC' | 'DESC') => GraphQLSort[];
};

export type GridQueryGroupingColDefOverride<R extends GridValidRowModel = any> =
  GridGroupingColDefOverride<R> & {
    filterTransformer?: FilterTransformer;
    sortTransformer?: (sort: 'ASC' | 'DESC') => GraphQLSort[];
  };

const DEFAULT_PAGINATION_MODEL: GridPaginationModel = {
  page: 0,
  pageSize: 25,
};

type GraphQLFilterOptions = Parameters<typeof toGraphQLFilter>[2];

type UseGraphQLDataGridProps = {
  initialState: {
    sort?: GridSortModel;
    filter?: GridFilterModel;
    pagination?: GridPaginationModel | Partial<GridPaginationModel>;
  };
  apiRef?: React.MutableRefObject<GridApi>;
  additionalSorts?: GraphQLSort[];
} & GraphQLFilterOptions;

export type GraphQLSort = { [key: string]: string | undefined | GraphQLSort };

type UseGraphQLDataGrid<R extends GridValidRowModel> = {
  apiRef: GridApi;
  gridProps: Partial<DataGridPremiumProps<R>>;
  queryProps: {
    filter: {
      orderBy?: GraphQLSort[];
      filters?: Record<string, any>;
      limit: number;
      offset: number;
    };
    key: [GridPaginationModel, Record<string, any>, GraphQLSort[]];
  };
};

export const useGraphQLDataGrid = <R extends GridValidRowModel = any>({
  initialState,
  additionalFilters,
  flattenQueries,
  additionalSorts = [],
  apiRef,
}: UseGraphQLDataGridProps): UseGraphQLDataGrid<R> => {
  const managedApiRef = useGridApiRef();
  const gridApiRef = apiRef ?? managedApiRef;

  const [sortModel, setSortModel] = useState<GridSortModel>(initialState?.sort ?? []);

  const [filterModel, setFilterModel] = useState<GridFilterModel>(
    initialState?.filter ?? { logicOperator: GridLogicOperator.And, items: [] },
  );

  const [paginationModel, setPaginationModel] = useState<GridPaginationModel>({
    pageSize: initialState?.pagination?.pageSize || DEFAULT_PAGINATION_MODEL.pageSize,
    page: initialState?.pagination?.page || DEFAULT_PAGINATION_MODEL.page,
  });

  const filters = toGraphQLFilter(filterModel, gridApiRef.current, {
    additionalFilters,
    flattenQueries,
  });

  const orderBy: GraphQLSort[] = sortModel
    .filter(param => param.sort != null)
    .map(param => {
      if (gridApiRef.current.getColumn) {
        const col = gridApiRef.current.getColumn(param.field) as GridQueryColDef;
        if (col.sortTransformer) {
          // Already asserted above this exists
          // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
          return col.sortTransformer(param.sort!.toUpperCase() as 'ASC' | 'DESC');
        }
      }

      const sort = {};
      set(sort, param.field, param.sort?.toUpperCase());

      return sort;
    })
    .flat()
    .concat(additionalSorts);

  return {
    apiRef: gridApiRef.current,
    gridProps: {
      apiRef: gridApiRef,
      sortingMode: 'server',
      sortModel,
      onSortModelChange: model => setSortModel(model),

      pagination: true,
      paginationMode: 'server',
      paginationModel,
      onPaginationModelChange: model => setPaginationModel(model),

      disableColumnFilter: false,
      filterMode: 'server',
      filterModel,
      onFilterModelChange: model => {
        batchUpdates(() => {
          setPaginationModel(
            initialState?.pagination
              ? {
                  page: 0,
                  pageSize: initialState.pagination.pageSize ?? DEFAULT_PAGINATION_MODEL.pageSize,
                }
              : DEFAULT_PAGINATION_MODEL,
          );
          setFilterModel(model);
        });
      },
    },
    queryProps: {
      key: [paginationModel, filters, orderBy],
      filter: {
        filters,
        orderBy,
        limit: paginationModel.pageSize,
        offset: paginationModel.page + 1,
      },
    },
  };
};
