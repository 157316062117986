import * as constants from 'app-state/constants';

export const fetchWallets = () => ({ type: constants.FETCH_WALLETS });
export const fetchWalletsSuccess = wallets => ({ type: constants.FETCH_WALLETS_SUCCESS, wallets });
export const fetchWalletsError = error => ({ type: constants.FETCH_WALLETS_ERROR, error });
export const resetWallets = () => ({ type: constants.RESET_WALLETS });

export const createWalletDeposit = (currency, showModal) => ({
  type: constants.CREATE_WALLETS_DEPOSIT,
  currency,
  showModal,
});
export const createWalletDepositSuccess = data => ({
  type: constants.CREATE_WALLETS_DEPOSIT_SUCCESS,
  data,
});
export const createWalletDepositError = error => ({
  type: constants.CREATE_WALLETS_DEPOSIT_ERROR,
  error,
});

export const createWalletWithdrawal = (currency, availableAmount, showModal) => ({
  type: constants.CREATE_WALLETS_WITHDRAWAL,
  currency,
  availableAmount,
  showModal,
});
export const createWalletWithdrawalSuccess = data => ({
  type: constants.CREATE_WALLETS_WITHDRAWAL_SUCCESS,
  data,
});
export const createWalletWithdrawalError = error => ({
  type: constants.CREATE_WALLETS_WITHDRAWAL_ERROR,
  error,
});

export const fetchWalletsPagesData = () => ({ type: constants.FETCH_WALLETS_PAGES_DATA });
export const fetchWalletsPagesDataSuccess = () => ({
  type: constants.FETCH_WALLETS_PAGES_DATA_SUCCESS,
});
export const fetchWalletsPagesDataError = error => ({
  type: constants.FETCH_WALLETS_PAGES_DATA_ERROR,
  error,
});
export const resetWalletsPagesData = () => ({ type: constants.RESET_WALLETS_PAGES_DATA });

export const downloadShareholderCert = payload => ({
  type: constants.DOWNLOAD_SHAREHOLDER_CERT,
  payload,
});
