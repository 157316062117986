import { push } from 'connected-react-router';

import { call, put, select, takeEvery } from 'redux-saga/effects';

import { saveUserData } from 'app-state/actions/authentication';
import {
  fetchInvestmentPreferencesError,
  fetchInvestmentPreferencesSuccess,
  saveAccountInfoError,
  saveAccountInfoSuccess,
} from 'app-state/actions/institutional-onboarding';
import { hideModal, showModal } from 'app-state/actions/shared';
import {
  FETCH_INVESTMENT_PREFERENCES,
  SAVE_ACCOUNT_INFO,
  UPDATE_INVESTMENT_PREFERENCES,
  UPDATE_ONBOARDING_INVESTMENT_PREFERENCES,
} from 'app-state/constants';
import { hideLoader, showLoader } from 'app-state/loader/actions';
import { getUser } from 'app-state/selectors/authentication';

import API from 'constants/api';
import Routes from 'constants/routes';
import ModalFailed from 'shared-parts/components/modal-failed';
import { defaultErrorMessage } from 'shared-parts/constants/error-messages';
import request from 'shared-parts/helpers/request';

import { saveUserDataSaga } from './authentication';

function* updateOnboardingInvestmentPreferences({ payload }) {
  yield put(showLoader());
  try {
    const user = yield select(getUser);
    const route =
      user.investorType === 'IndividualInvestor'
        ? API.Onboarding.Individual.investmentPreferences()
        : API.Onboarding.Institution.investmentPreferences();

    yield call(request, route, 'POST', payload);
    const updatedUser = { ...user, investor: { ...user.investor, preferenceSet: true } };
    const saveUserDataAction = saveUserData(
      updatedUser,
      Routes.Onboarding.Institutional.Completed(),
    );
    yield call(saveUserDataSaga, saveUserDataAction);
  } catch (e) {
    yield put(
      showModal({
        component: ModalFailed,
        closable: true,
        showHeader: false,
        title: defaultErrorMessage,
      }),
    );
  }
  yield put(hideLoader());
}

function* updateOnboardingInvestmentPreferencesWatcher() {
  yield takeEvery(UPDATE_ONBOARDING_INVESTMENT_PREFERENCES, updateOnboardingInvestmentPreferences);
}

function* fetchInvestmentPreferences() {
  yield put(showLoader());
  try {
    const { data } = yield call(request, API.Preferences.preferences(), 'GET');

    yield put(fetchInvestmentPreferencesSuccess(data));
  } catch (e) {
    yield put(
      showModal({
        component: ModalFailed,
        closable: true,
        showHeader: false,
        title: defaultErrorMessage,
      }),
    );
    yield put(fetchInvestmentPreferencesError(e));
  }
  yield put(hideLoader());
}

function* fetchInvestmentPreferencesWatcher() {
  yield takeEvery(FETCH_INVESTMENT_PREFERENCES, fetchInvestmentPreferences);
}

function* updateInvestmentPreferences({ payload }) {
  yield put(showLoader());
  try {
    yield call(request, API.Preferences.preferences(), 'PUT', payload);

    yield put(hideModal());
    yield put(push(Routes.Account.Root()));
  } catch (e) {
    yield put(
      showModal({
        component: ModalFailed,
        closable: true,
        showHeader: false,
        title: defaultErrorMessage,
      }),
    );
  }
  yield put(hideLoader());
}

function* updateInvestmentPreferencesWatcher() {
  yield takeEvery(UPDATE_INVESTMENT_PREFERENCES, updateInvestmentPreferences);
}

function* saveAccountInfo({ params, setSubmitting, setErrors, redirectRoute }) {
  try {
    yield put(showLoader());
    const { data } = yield call(
      request,
      API.Onboarding.Institution.accountInformation(),
      'PUT',
      params,
    );
    yield put(hideLoader());
    setSubmitting(false);

    const actionPath = localStorage.getItem('actionPath');

    yield put(saveAccountInfoSuccess(data));
    const saveUserDataAction = saveUserData(data, redirectRoute);
    yield call(saveUserDataSaga, saveUserDataAction);

    if (actionPath) {
      yield put(push(actionPath));
    }
  } catch (e) {
    yield put(hideLoader());
    yield put(saveAccountInfoError(e));
    setSubmitting(false);
    setErrors(e.response.details);
  }
}

function* saveAccountInfoWatcher() {
  yield takeEvery(SAVE_ACCOUNT_INFO, saveAccountInfo);
}

export {
  saveAccountInfo,
  saveAccountInfoWatcher,
  updateOnboardingInvestmentPreferencesWatcher,
  fetchInvestmentPreferencesWatcher,
  updateInvestmentPreferencesWatcher,
};
