import styled from 'styled-components';

import { TextButton } from 'shared-parts/components/button';
import * as Colors from 'shared-parts/constants/colors';
import * as Fonts from 'shared-parts/constants/fonts';

const ModalWrapper = styled.div<{ modalWidth: number }>`
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  width: ${({ modalWidth }) => modalWidth}px;
  height: fit-content;
  padding: 40px 37px;
`;

const ModalTitle = styled.div`
  font-size: 35px;
  line-height: 45px;
  margin: 22px 0 24px;
  font-weight: 300;
  color: ${Colors.primary};
  font-family: ${Fonts.oswaldFont};
  text-align: center;
`;

const Message = styled.p<{ maxWidth: string }>`
  color: ${Colors.neutral800};
  font-size: 16px;
  line-height: 24px;
  font-family: ${Fonts.mainFont};
  max-width: ${({ maxWidth }) => maxWidth};
  text-align: center;
`;

const ButtonsPart = styled.div`
  margin-top: 24px;
`;

const StyledTextButton = styled(TextButton)`
  margin-right: 20px;
`;

export { ModalWrapper, ModalTitle, ButtonsPart, StyledTextButton, Message };
