import { call, put, takeEvery } from 'redux-saga/effects';

import * as actions from 'app-state/actions';
import * as constants from 'app-state/constants';

import API from 'constants/api';
import Routes from 'constants/routes';
import { request } from 'shared-parts/helpers';

function* fetchNavigation({ permalink, modelUuid }) {
  try {
    const { data } = yield call(request, API.Navigation.retrieve(modelUuid));
    const result = data.map(item => ({
      ...item,
      fullURL: Routes.Deal.Page(permalink, `/${item.url}`, ''),
    }));

    yield put(actions.fetchNavigationSuccess(result));

    return yield result;
  } catch (e) {
    yield put(actions.fetchNavigationError(e));
  }
}

function* fetchNavigationWatcher() {
  yield takeEvery(constants.FETCH_NAVIGATION, fetchNavigation);
}

function* fetchSubNavigation({ permalink, navigationItemURL, navigationItemId }) {
  try {
    const { data } = yield call(request, API.SubNavigation.retrieve(navigationItemId));
    const result = data.map(item => ({
      ...item,
      fullURL: Routes.Deal.Page(permalink, `/${navigationItemURL}`, `/${item.url}`),
    }));

    return yield result;
  } catch (e) {
    // empty catch block is required
  }
}

function* fetchSubNavigationWatcher() {
  yield takeEvery(constants.FETCH_SUB_NAVIGATION, fetchSubNavigation);
}

export { fetchNavigation, fetchNavigationWatcher, fetchSubNavigation, fetchSubNavigationWatcher };
