import { useMemo, useState } from 'react';
// This works fine for us! React18 has this stable
// and it's way better than merging state tbh
import { unstable_batchedUpdates as batchUpdates } from 'react-dom';

import {
  DataGridPremiumProps,
  FilterColumnsArgs,
  GetColumnForNewFilterArgs,
  GridColDef,
  GridFilterModel,
  GridLoadIcon,
  GridLogicOperator,
  GridNoRowsOverlay,
  GridPaginationModel,
  GridSortModel,
  GridValidRowModel,
  useGridApiRef,
} from '@mui/x-data-grid-premium';
import { GridApiPremium } from '@mui/x-data-grid-premium/models/gridApiPremium';

import { arrayQueryTransformer, HttpFilterKeyMap, toHttpFilter } from './http-filter-map';

const DEFAULT_PAGINATION_MODEL: GridPaginationModel = {
  page: 0,
  pageSize: 50,
};

type UseDataGridProps = {
  initialState: {
    sort?: GridSortModel;
    filter?: GridFilterModel;
    pagination?: GridPaginationModel;
  };
  pageQueryProps?: {
    pageQueryParam: string;
    limitQueryParam: string;
  };
  additionalFilters?: { key: string; value: string }[];
  quickFilterMap?: (quickFilterValues: any[]) => any[][];
  sortTransforms?: (sort: HttpSort[]) => HttpSort[];
};

export type HttpSort = { [key: string]: string | undefined | HttpSort };

type UseHttpDataGrid = {
  gridProps: Partial<DataGridPremiumProps>;
  queryProps: URLSearchParams;
  currentApiRef?: GridApiPremium;
};

export type GridColDefExtended<T extends GridValidRowModel> = GridColDef<T> & {
  apiQueryParamKey?: string;
  apiQueryParamKeyForSort?: string;
  getFilterValueLabel?: (value: string | number) => string;
  queryParamTransformer?: (
    value: unknown,
    column: GridColDefExtended<T>,
  ) => HttpFilterKeyMap[] | undefined;
};

export const defaultQueryParamTransformer = (value: unknown, column: GridColDefExtended<any>) => {
  return [{ key: column.apiQueryParamKey ?? column.field, value } as HttpFilterKeyMap];
};

export const defaultArrayQueryParamTransformer = (
  value: unknown,
  column: GridColDefExtended<any>,
) => arrayQueryTransformer(value, `${column.apiQueryParamKey ?? column.field}[]`);

export const defaultSortQueryParamTransformer = (
  defaultSortModel: GridSortModel | undefined,
  sortModel: GridSortModel,
  apiRef: React.MutableRefObject<GridApiPremium>,
) => {
  const baseOrderBy = sortModel[0] ?? defaultSortModel?.[0];
  const orderByField = baseOrderBy?.field;

  if (!apiRef?.current?.getColumn) {
    return;
  }

  if (orderByField) {
    const column = apiRef.current.getColumn(orderByField) as GridColDefExtended<any>;
    const orderByFieldDirection = baseOrderBy?.sort === 'desc' ? '-' : '';

    const sortQueryParamKey =
      column?.apiQueryParamKeyForSort ?? column?.apiQueryParamKey ?? orderByField;

    return { orderByUrlParam: 'order', value: `${orderByFieldDirection}${sortQueryParamKey}` };
  }
};

export const getTogglableColumns = (
  columns: GridColDefExtended<any>[],
  hiddenColumns: string[],
) => {
  const visibleColumns = columns
    .filter(column => !hiddenColumns.some(hiddenColumn => hiddenColumn === column.field))
    .map(column => column.field);

  return visibleColumns;
};

export const useHttpDataGrid = ({
  initialState,
  quickFilterMap,
  pageQueryProps,
  additionalFilters,
}: UseDataGridProps): UseHttpDataGrid => {
  const apiRef = useGridApiRef();

  const [sortModel, setSortModel] = useState<GridSortModel>(initialState?.sort ?? []);

  const [filterModel, setFilterModel] = useState<GridFilterModel>(
    initialState?.filter ?? { logicOperator: GridLogicOperator.And, items: [] },
  );

  const [paginationModel, setPaginationModel] = useState<GridPaginationModel>(
    initialState?.pagination || DEFAULT_PAGINATION_MODEL,
  );

  const queryProps = useMemo(() => {
    const urlQueryParams = toHttpFilter(filterModel, apiRef.current, quickFilterMap);

    const currentPage = paginationModel.page + 1;
    urlQueryParams.set(
      pageQueryProps?.limitQueryParam ?? 'limit',
      paginationModel.pageSize.toString(),
    );
    urlQueryParams.set(pageQueryProps?.pageQueryParam ?? 'page', currentPage.toString());

    const orderByQueryParam = defaultSortQueryParamTransformer(
      initialState.sort,
      sortModel,
      apiRef,
    );
    if (orderByQueryParam) {
      urlQueryParams.set(orderByQueryParam.orderByUrlParam, orderByQueryParam.value);
    }

    additionalFilters?.forEach(queryProp => {
      urlQueryParams.append(queryProp.key, queryProp.value);
    });

    return urlQueryParams;
  }, [
    filterModel,
    quickFilterMap,
    paginationModel.page,
    paginationModel.pageSize,
    pageQueryProps?.limitQueryParam,
    pageQueryProps?.pageQueryParam,
    sortModel,
    apiRef,
    additionalFilters,
    initialState.sort,
  ]);

  const filterColumns = ({ field, columns, currentFilters }: FilterColumnsArgs) => {
    // remove already filtered fields from list of columns
    const filteredFields = currentFilters?.map(item => item.field);
    return columns
      .filter(
        colDef =>
          colDef.filterable && (colDef.field === field || !filteredFields.includes(colDef.field)),
      )
      .map(column => column.field);
  };

  const getColumnForNewFilter = ({ currentFilters, columns }: GetColumnForNewFilterArgs) => {
    const filteredFields = currentFilters?.map(({ field }) => field);
    const columnForNewFilter = columns
      .filter(colDef => colDef.filterable && !filteredFields.includes(colDef.field))
      .find(colDef => colDef.filterOperators?.length);
    return columnForNewFilter?.field ?? null;
  };

  return {
    currentApiRef: apiRef.current,
    gridProps: {
      apiRef,
      sortingMode: 'server',
      sortModel,
      onSortModelChange: model => setSortModel(model),
      slots: {
        noRowsOverlay: () => <GridNoRowsOverlay />,
        loadIcon: () => <GridLoadIcon data-e2e="filter-load-icon" />,
      },
      slotProps: {
        filterPanel: {
          filterFormProps: {
            filterColumns,
          },
          getColumnForNewFilter,
        },
      },
      pagination: true,
      paginationMode: 'server',
      paginationModel,
      onPaginationModelChange: model => setPaginationModel(model),

      disableColumnFilter: false,
      filterMode: 'server',
      filterModel,
      onFilterModelChange: model => {
        batchUpdates(() => {
          const pageModel = initialState?.pagination ?? DEFAULT_PAGINATION_MODEL;
          setPaginationModel(pageModel);
          setFilterModel(model);
        });
      },
    },
    queryProps,
  };
};
