import { push } from 'connected-react-router';
import { sessionService } from 'redux-react-session';

import { call, put, takeEvery } from 'redux-saga/effects';

import * as actions from 'app-state/actions';
import * as constants from 'app-state/constants';

import API from 'constants/api';
import Routes from 'constants/routes';
import gtmTrack from 'shared-parts/helpers/gtm-track';
import request from 'shared-parts/helpers/request';

function* confirmEmail({ emailToken }) {
  try {
    yield call(sessionService.deleteUser);
    yield call(sessionService.deleteSession);
    const { data } = yield call(request, API.confirmEmail(emailToken), 'POST');
    const redirectRoute = data.investorType
      ? Routes.Root()
      : Routes.Onboarding.SelectInvestorType();
    yield put(actions.saveUserData(data, redirectRoute));
    yield gtmTrack({}, 'GCEmailConfirmed', data);
  } catch (e) {
    yield put(push(Routes.Login()));
  }
}

function* confirmEmailWatcher() {
  yield takeEvery(constants.CONFIRM_EMAIL, confirmEmail);
}

export { confirmEmail, confirmEmailWatcher };
