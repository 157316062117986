import { QueryClient, QueryClientProvider } from '@tanstack/react-query';
import { createContext, FC, useContext, useMemo } from 'react';

import { UseGraphQLExportOptions } from '../use-graphql-export';

import {
  GraphQLClientProvider,
  GraphQLClientProviderOptions,
  HttpRequestClientProvider,
  HttpRequestClientProviderOptions,
} from './providers';

type RequestClientProviderOptions = {
  queryClient?: QueryClient;

  request: HttpRequestClientProviderOptions['client'];
  graphql: GraphQLClientProviderOptions['client'];
  defaultOptions?: {
    export?: Partial<UseGraphQLExportOptions>;
  };
};

type HttpOptionsContextOptions = NonNullable<RequestClientProviderOptions['defaultOptions']>;

const HttpOptionsContext = createContext<HttpOptionsContextOptions>({});

export const RequestClientProvider: FC<React.PropsWithChildren<RequestClientProviderOptions>> = ({
  children,
  request,
  graphql,
  queryClient,
  defaultOptions = {},
}) => {
  // Use memo here as we always want a brand new client on ever instance of this
  // provider, but obviously don't want it to provide a new client every render
  const defaultQueryClient = useMemo(() => {
    return new QueryClient({
      defaultOptions: {
        queries: {
          retry: false,
          cacheTime: 30000,
          refetchOnWindowFocus: false,
        },
      },
    });
  }, []);

  return (
    <HttpOptionsContext.Provider value={defaultOptions}>
      <HttpRequestClientProvider client={request}>
        <GraphQLClientProvider client={graphql}>
          <QueryClientProvider client={queryClient || defaultQueryClient}>
            {children}
          </QueryClientProvider>
        </GraphQLClientProvider>
      </HttpRequestClientProvider>
    </HttpOptionsContext.Provider>
  );
};

export const useHttpDefaultOptions = () => useContext(HttpOptionsContext);
