import * as constants from 'app-state/constants';

export const fetchCompany = (companyUuid, issuanceUuid) => ({
  type: constants.FETCH_COMPANY,
  companyUuid,
  issuanceUuid,
});
export const fetchCompanySuccess = data => ({ type: constants.FETCH_COMPANY_SUCCESS, data });
export const fetchCompanyError = error => ({ type: constants.FETCH_COMPANY_ERROR, error });
export const resetCompany = () => ({ type: constants.RESET_COMPANY });

export const fetchCompanies = () => ({ type: constants.FETCH_COMPANIES });
export const fetchCompaniesSuccess = data => ({ type: constants.FETCH_COMPANIES_SUCCESS, data });
export const fetchCompaniesError = error => ({ type: constants.FETCH_COMPANIES_ERROR, error });
export const resetCompanies = () => ({ type: constants.RESET_COMPANIES });

export const fetchCompanyGroups = () => ({ type: constants.FETCH_COMPANY_GROUPS });
export const fetchCompanyGroupsSuccess = data => ({
  type: constants.FETCH_COMPANY_GROUPS_SUCCESS,
  data,
});
export const fetchCompanyGroupsError = error => ({
  type: constants.FETCH_COMPANY_GROUPS_ERROR,
  error,
});
export const resetCompanyGroups = () => ({ type: constants.RESET_COMPANY_GROUPS });

export const getCompanySummary = companyIssuerId => ({
  type: constants.GET_COMPANY_SUMMARY,
  companyIssuerId,
});
export const getCompanySummarySuccess = data => ({
  type: constants.GET_COMPANY_SUMMARY_SUCCESS,
  data,
});
export const getCompanySummaryError = error => ({
  type: constants.GET_COMPANY_SUMMARY_ERROR,
  error,
});
