const CloseIcon = (width = 20, height = 20, color = '#C9CCD8', handleClick) => (
  <svg
    onClick={handleClick}
    xmlns="http://www.w3.org/2000/svg"
    width={width}
    height={height}
    viewBox="0 0 20 20"
    data-e2e="close-icon"
  >
    <defs>
      <path
        id="a"
        d="M329.89 1458.7l-1.89 1.89-1.89-1.89a.996.996 0 1 0-1.41 1.41l1.89 1.89-1.89 1.89a.996.996 0 1 0 1.41 1.41l1.89-1.89 1.89 1.89a.996.996 0 1 0 1.41-1.41l-1.89-1.89 1.89-1.89a.996.996 0 0 0 0-1.41c-.39-.38-1.03-.38-1.41 0zM328 1452c-5.53 0-10 4.47-10 10s4.47 10 10 10 10-4.47 10-10-4.47-10-10-10zm0 18c-4.41 0-8-3.59-8-8s3.59-8 8-8 8 3.59 8 8-3.59 8-8 8z"
      />
    </defs>
    <use fill={color} fillRule="nonzero" transform="translate(-318 -1452)" xlinkHref="#a" />
  </svg>
);

export default CloseIcon;
