import { call, put, takeEvery } from 'redux-saga/effects';

import { getIssuerError, getIssuerSuccess } from 'app-state/actions/issuer';
import { GET_ISSUER } from 'app-state/constants/issuer';

import API from 'constants/api';
import request from 'shared-parts/helpers/request';

function* getIssuer({ payload }) {
  try {
    const { data } = yield call(request, API.CompanyByIssuerId(payload), 'GET');
    yield put(getIssuerSuccess({ data }));
  } catch (error) {
    yield put(getIssuerError({ error }));
  }
}

function* getIssuerWatcher() {
  yield takeEvery(GET_ISSUER, getIssuer);
}

export { getIssuer, getIssuerWatcher };
