export type EnvVar = string | number | boolean | undefined;
export type EnvVarSet<T extends string> = { [k in T]: EnvVar };

export const getEnvVar = (varKey: string, defaultValue?: EnvVar): EnvVar => {
  const config = (window as any).config || {};
  const variable = config[varKey as string];

  if (typeof variable === 'undefined' || variable === null) {
    // Actual undefined or null.
    return defaultValue;
  }
  if (variable === 'undefined') {
    // The string literal "undefined".
    return defaultValue;
  }

  if (typeof defaultValue === 'boolean') {
    // Explicit boolean type.
    return Boolean(variable);
  }
  if (typeof defaultValue === 'number') {
    // Explicit number type.
    return Number(variable);
  }
  if (typeof defaultValue === 'string') {
    // Explicit string type - no automatic boolean casting.
    return String(variable);
  }

  // No explicit type specified, handle booleans automatically.
  if (variable === 'true') {
    return true;
  }
  if (variable === 'false') {
    return false;
  }

  return variable;
};

export function useEnvVar(key: string): EnvVar;
export function useEnvVar(key: string, defaultValue: string): string;
export function useEnvVar(key: string, defaultValue: boolean): boolean;
export function useEnvVar(key: string, defaultValue: number): number;
export function useEnvVar<T extends string>(key: T[]): EnvVarSet<T>;
export function useEnvVar<T extends string>(
  key: string | T[],
  defaultValue?: EnvVar,
): EnvVar | EnvVarSet<T> {
  if (Array.isArray(key)) {
    return key.reduce((o, k) => {
      // eslint-disable-next-line no-param-reassign
      o[k] = getEnvVar(k);
      return o;
    }, {} as EnvVarSet<T>);
  }

  return getEnvVar(key, defaultValue);
}
