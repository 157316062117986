import { useEnvVar } from '@frontend/config';
import { checkPathSuitability } from '@frontend/utils';

import { getDeal } from 'app-state/selectors';

import Routes from 'constants/routes';
import { FeatureFlag, useFeatureFlag } from 'providers';
import { useLiquidity } from 'providers/liquidity';
import useTypedSelector from 'hooks/use-typed-selector';
import { getTenantConfig } from 'helpers/get-tenant-config';
import { FooterLogo } from 'shared/logos';
import { Container } from 'shared-parts/components';
import { Colors } from 'shared-parts/constants';

import {
  FooterLink,
  FooterMainBlock,
  FooterWrapper,
  Navbar,
  NavFooter,
  NavItem,
  TenantWebsiteContainer,
} from './footer.styled';

const {
  custom: {
    footer: { footerMainBlockDisablePadding },
  },
} = getTenantConfig();

const getLinks = () => {
  const enableRiskLink = !useFeatureFlag(FeatureFlag.DISABLE_FOOTER_RISK_LINK);

  const riskLinkUrl = useEnvVar('RISK_LINK', 'https://globacap.com/risk-warnings/');
  const riskLinkText = useEnvVar('RISK_TEXT', 'Risk Warnings');
  const termsLinkUrl = useEnvVar('TERMS_LINK', 'https://globacap.com/terms-conditions/');
  const termsLinkText = useEnvVar('TERMS_TEXT', 'Terms & Conditions');
  const privacyLinkUrl = useEnvVar('POLICY_LINK', 'https://globacap.com/privacy-policy/');
  const privacyLinkText = useEnvVar('POLICY_TEXT', 'Privacy Policy');

  const result = enableRiskLink
    ? [
        {
          linkUrl: riskLinkUrl,
          linkText: riskLinkText,
        },
      ]
    : [];
  result.push(
    {
      linkUrl: termsLinkUrl,
      linkText: termsLinkText,
    },
    {
      linkUrl: privacyLinkUrl,
      linkText: privacyLinkText,
    },
  );
  return result;
};

const renderNavItems = () => {
  const deal = useTypedSelector(getDeal);
  const {
    state: { round },
  } = useLiquidity();

  const isLiquidityRoundRegulated = round?.regulated;
  const isLiquidityRoundPage = checkPathSuitability(
    { path: Routes.LiquidityRound(), exact: true },
    window.location.pathname,
  );

  const filterRiskCTA = isLiquidityRoundPage ? isLiquidityRoundRegulated : deal.data.regulated;

  return getLinks()
    .filter(link => !(!filterRiskCTA && link.linkText === 'Risk Warnings'))
    .map(({ linkUrl, linkText }) => (
      <NavItem key={linkUrl}>
        <FooterLink
          url={linkUrl}
          target="_blank"
          rel="noopener noreferrer"
          color={Colors.primaryContrastText}
          hoverColor={Colors.primaryContrastText}
          noUnderline
        >
          {linkText}
        </FooterLink>
      </NavItem>
    ));
};

// eslint-disable-next-line import/no-unused-modules
export const Footer = () => {
  const enableFooterLogo = !useFeatureFlag(FeatureFlag.DISABLE_FOOTER_LOGO);
  const withPaddings = checkPathSuitability(
    { path: Routes.Deal.Page(), exact: false },
    window.location.pathname,
  )
    ? !checkPathSuitability({ path: Routes.Deals(), exact: true }, window.location.pathname)
    : false;

  return (
    <FooterWrapper>
      {enableFooterLogo && <FooterLogo />}
      <FooterLinks withPaddings={withPaddings} />
    </FooterWrapper>
  );
};

const TenantWebsiteLink = ({ isAuth = false }) => {
  const url = useEnvVar('TENANT_WEBSITE_URL', 'https://globacap.com/');
  const text = useEnvVar('TENANT_WEBSITE_TEXT', 'Return to Globacap site');
  return (
    <TenantWebsiteContainer>
      {isAuth && <a href={url}>{text}</a>}
      {!isAuth && <span>Investor Platform</span>}
    </TenantWebsiteContainer>
  );
};

// eslint-disable-next-line import/no-unused-modules
export const FooterLinks = ({ withPaddings, isAuth = false }) => {
  const enableFooterLinks = !useFeatureFlag(FeatureFlag.DISABLE_FOOTER_LINKS);
  const enableFooterTenantWebsiteLink = useFeatureFlag(
    FeatureFlag.ENABLE_FOOTER_TENANT_WEBSITE_LINK,
  );

  return (
    <FooterMainBlock withPaddings={!footerMainBlockDisablePadding && withPaddings}>
      <Container>
        <Navbar>
          {enableFooterTenantWebsiteLink && <TenantWebsiteLink isAuth={isAuth} />}
          <NavFooter>{enableFooterLinks && renderNavItems()}</NavFooter>
        </Navbar>
      </Container>
    </FooterMainBlock>
  );
};

export default Footer;
