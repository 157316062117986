import { IssuanceStatus } from '../../types';

import { ActiveIssuanceStats, ActiveIssuanceStatsProps } from './active-issuance-stats';
import { ClosedIssuanceStats, ClosedIssuanceStatsProps } from './closed-issuance-stats';
import { PendingIssuanceStats, PendingIssuanceStatsProps } from './pending-issuance-stats';
import { PreLaunchIssuanceStats, PreLaunchIssuanceStatsProps } from './prelaunch-issuance-stats';

export type IssuanceStatsProps = ActiveIssuanceStatsProps &
  ClosedIssuanceStatsProps &
  PendingIssuanceStatsProps &
  PreLaunchIssuanceStatsProps;

export const IssuanceStats = (props: IssuanceStatsProps) => {
  switch (props.status) {
    case IssuanceStatus.LIVE:
      return <ActiveIssuanceStats {...props} />;
    case IssuanceStatus.COMPLETED:
      return <ClosedIssuanceStats {...props} />;
    case IssuanceStatus.PENDING:
      return <PendingIssuanceStats {...props} />;
    case IssuanceStatus.PRELAUNCH:
      return <PreLaunchIssuanceStats {...props} />;
    default:
      return null;
  }
};
