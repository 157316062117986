import styled from 'styled-components';

import { Screens } from 'shared-parts/constants';
import * as Colors from 'shared-parts/constants/colors';
import * as Fonts from 'shared-parts/constants/fonts';

export const SectionHeader = styled.section`
  font-family: ${Fonts.oswaldFont};
  margin: 0 auto 19px;
`;

export const Row = styled.div`
  flex: 1 1 auto;
  align-items: stretch;
  padding: 0;
  margin: 0 auto;
  display: flex;
  flex-wrap: wrap;

  @media (max-width: ${Screens.md}) {
    flex-direction: column;
  }
`;

export const Column = styled.div`
  display: flex;
  padding: 0;
  flex: 1 1 auto;
  padding: 0 4px;
  @media (max-width: ${Screens.md}) {
    padding: 0;
  }
`;

export const FirstColumn = styled(Column)`
  padding-left: 0;
`;
export const LastColumn = styled(Column)`
  padding-right: 0;
`;

export const ColumnContent = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1 1 auto;
  height: 135px;
  justify-content: center;
  background: ${Colors.white};
  border: 1px solid ${Colors.neutral400};
  border-radius: 8px;
  padding: 25px 30px 30px;
  box-shadow: 0px 3px 6px ${Colors.shadowColor};
  border-bottom: 1px solid ${Colors.neutral400};

  @media (max-width: ${Screens.lg}) {
    padding: 20px;
  }

  @media (max-width: ${Screens.sm}) {
    padding: 15px;
  }

  @media (max-width: ${Screens.xs}) {
    padding: 25px;
  }
`;

export const ColumnTitle = styled.div`
  font-size: 28px;
  line-height: 1em;
  margin-top: 4px;
  margin-bottom: 2px;
  color: ${Colors.primary};
`;

export const ColumnInfo = styled.div`
  color: ${Colors.neutral800};
  font-family: ${Fonts.mainFont};
  font-size: 18px;
  font-weight: 700;
  line-height: 1.5em;
`;

export const FirstColumnInner = styled(ColumnContent)`
  padding-left: 40px;
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;

  @media (max-width: ${Screens.lg}) {
    padding-left: 25px;
  }

  @media (max-width: ${Screens.md}) {
    padding-top: 30px;
    border-radius: 8px;
    border-bottom-left-radius: 0;
    border-bottom-right-radius: 0;
  }
`;

export const FirstColumnTitle = styled(ColumnTitle)`
  @media (max-width: ${Screens.md}) {
    font-size: 22px;
  }
`;

export const FirstColumnInfo = styled(ColumnInfo)`
  @media (max-width: ${Screens.md}) {
    font-size: 14px;
  }
`;

export const SecondColumnInner = styled(ColumnContent)`
  justify-content: flex-start;
  border-radius: 0;
  padding: 20px;
`;

export const SecondColumnTitle = styled(ColumnTitle)`
  font-size: 22px;
  line-height: 24px;
`;

export const SecondColumnInfo = styled(ColumnInfo)`
  color: ${Colors.primary};
  font-family: ${Fonts.oswaldFont};
  font-size: 50px;
  font-weight: 400;
  line-height: 1em;

  @media (max-width: ${Screens.xl}) {
    font-size: 45px;
  }

  @media (max-width: ${Screens.lg}) {
    font-size: 30px;
  }
`;

export const ThirdColumnInner = styled(ColumnContent)`
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
  padding-right: 40px;

  @media (max-width: ${Screens.lg}) {
    padding-right: 25px;
  }

  @media (max-width: ${Screens.md}) {
    padding-bottom: 30px;
    border-radius: 8px;
    border-top-left-radius: 0;
    border-top-right-radius: 0;
  }
`;

export const ThirdColumnTitle = styled(ColumnTitle)`
  font-size: 22px;
  line-height: 24px;
  margin-bottom: 20px;
`;

export const Progress = styled.div`
  background: ${Colors.neutral800};
  color: ${Colors.alternativeContrastText};
  font-size: 14px;
  font-weight: 600;
  line-height: 18px;
  padding: 8px 0;
  position: relative;
`;

export const ProgressValue = styled.div`
  position: relative;
  text-align: center;
  z-index: 2;
`;

export const ProgressBar = styled.div<{ percentage: string }>`
  background: ${Colors.alternative};
  height: 100%;
  position: absolute;
  top: 0;
  width: ${props => props.percentage};
`;
