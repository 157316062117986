export const transformToMap = allCountries =>
  allCountries.map(country => ({
    text: country.name,
    value: country.code,
  }));

export const transformToCountryTypeMap = allCountries =>
  allCountries.map(country => ({
    countryName: country.name,
    countryCode: country.code,
  }));
