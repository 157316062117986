import { useEffect, useState } from 'react';

import { generateGroupTableData } from '../helpers/index';
import Table from '../regular-table/table';

export const getGroupedTableControls = (props, { expandedGroups, setExpandedGroups }) => {
  const handleGroupClick = group => {
    const groupKey = group[props.uniqueGroupKey];

    if (expandedGroups.includes(groupKey)) {
      setExpandedGroups(expandedGroups.filter(item => item !== groupKey));
    } else {
      setExpandedGroups([...expandedGroups, groupKey]);
      props.expandGroupCallBack(groupKey);
    }
  };

  return { handleGroupClick };
};

const GroupedTable = props => {
  const {
    keys,
    error,
    header,
    onSort,
    groups,
    loading,
    className,
    onRowClick,
    fixedHeader,
    totalRowData,
    onCloseError,
    createControls,
    onInfiniteScroll,
    noRecordsMessage,
    resetRowScrollData,
    isScrollbarVisible,
    createTableControls,
    initialSorting,
  } = props;
  const [sortingData, setSortingData] = useState(initialSorting);
  const [expandedGroups, setExpandedGroups] = useState([]);
  const stateControls = { expandedGroups, setExpandedGroups };
  const groupedTableControls = createControls(props, stateControls);

  useEffect(() => {
    setExpandedGroups([]);
  }, [loading]);

  return (
    <Table
      error={error}
      header={header}
      onSort={onSort}
      groups={groups}
      loading={loading}
      className={className}
      onRowClick={onRowClick}
      fixedHeader={fixedHeader}
      totalRowData={totalRowData}
      onCloseError={onCloseError}
      createControls={createControls}
      keys={groups.length ? [] : keys}
      onInfiniteScroll={onInfiniteScroll}
      noRecordsMessage={noRecordsMessage}
      setGroupSortingData={setSortingData}
      resetRowScrollData={resetRowScrollData}
      isScrollbarVisible={isScrollbarVisible}
      createTableControls={createTableControls}
      onGroupClick={groupedTableControls.handleGroupClick}
      data={generateGroupTableData(props, sortingData, expandedGroups)}
      initialSorting={initialSorting}
    />
  );
};

GroupedTable.defaultProps = {
  keys: [],
  groups: [],
  expandGroupCallBack: () => {},
  createControls: getGroupedTableControls,
};

export default GroupedTable;
