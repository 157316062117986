import { FC } from 'react';

import { Table, TableBody, TableCell, TableContainer, TableRow } from '@frontend/design-system';
import { Currency, renderCurrencySymbolString } from '@frontend/utils';

import { TokenCalculationResult } from '../calculate-order-number-of-tokens';

export const OrderBreakdown: FC<
  React.PropsWithChildren<{
    tokenResult: TokenCalculationResult;
    pricePerToken: string;
    ownershipUnit: string;
    currency: Currency;
  }>
> = ({ tokenResult, ownershipUnit, currency, pricePerToken }) => {
  const { rawAmount, amountString, roundedNumberOfTokensString } = tokenResult;

  return (
    <TableContainer>
      <Table>
        <colgroup>
          <col style={{ width: '40%' }} />
          <col style={{ width: '60%' }} />
        </colgroup>
        <TableBody>
          <TableRow>
            <TableCell>Price per {ownershipUnit}: </TableCell>
            <TableCell>{pricePerToken}</TableCell>
          </TableRow>
          <TableRow>
            <TableCell>Number of {`${ownershipUnit}s`}: </TableCell>
            <TableCell>{roundedNumberOfTokensString}</TableCell>
          </TableRow>
          <TableRow>
            <TableCell>Rounded amount: </TableCell>
            <TableCell>{rawAmount && renderCurrencySymbolString(currency, amountString)}</TableCell>
          </TableRow>
        </TableBody>
      </Table>
    </TableContainer>
  );
};
