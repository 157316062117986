import { FormikProps } from 'formik';

import {
  AddressForm,
  AddressFormValues,
  AddressType,
} from 'modules/account-details/components/types';
import CautionIcon from 'svg-icons/caution-icon';
import Address from 'shared-parts/components/address';
import { StandardButton } from 'shared-parts/components/button';

import {
  ButtonWrapper,
  Description,
  FormWrapper,
  ManualAddressFormGroup,
  StyledTextButton,
  Title,
} from './update-address-form.styled';

const loqateParams = {
  loqateLabel: <strong>Registered address *</strong>,
  FormGroup: ({ children }: { children: JSX.Element }) => <div>{children}</div>,
  blockStyles: `
    width: 100%;
  `,
};

const manualAddressParams = {
  FormGroup: ManualAddressFormGroup,
  fullWidth: true,
  line1Label: 'Address *',
  line2Label: '',
  postCodeLabel: 'Postal / Zip Code *',
  cityLabel: 'City *',
  state: 'State',
};

const onAddressChange =
  (values: AddressFormValues, setValues: (values: AddressFormValues) => void) =>
  (address: AddressType) => {
    if (!Object.keys(address).length) return;

    setValues({
      ...values,
      addressFirstLine: address.line1,
      line2: address.line2,
      postCode: address.postCode,
      city: address.city,
      state: address.state,
    });
  };

const renderAddressForm =
  ({ hideModal, user }: AddressForm) =>
  ({
    values,
    initialValues,
    isValid,
    isSubmitting,
    dirty,
    setValues,
    setFieldValue,
    setFieldError,
    setFieldTouched,
  }: FormikProps<AddressFormValues>): JSX.Element => (
    <FormWrapper data-e2e="enter-registered-address-form" autoComplete="off">
      {CautionIcon(60, 78)}
      <Title>Enter Registered Address</Title>
      <Description>
        Before starting the KYB verification check, please enter the registered address for&nbsp;
        {user.investor.name}.
      </Description>
      <Address
        country={initialValues.country}
        formValues={values}
        loqateParams={loqateParams}
        onAddressChange={onAddressChange(values, setValues)}
        manualAddressParams={manualAddressParams}
        setManualFormValues={setValues}
        setFieldValue={setFieldValue}
        setFieldError={setFieldError}
        setFieldTouched={setFieldTouched}
      />
      <ButtonWrapper>
        <StyledTextButton onClick={hideModal}>Cancel</StyledTextButton>
        <StandardButton disabled={!dirty || !isValid || isSubmitting} type="submit">
          Save
        </StandardButton>
      </ButtonWrapper>
    </FormWrapper>
  );

export default renderAddressForm;
