import { getTenantConfig } from 'helpers/get-tenant-config';

const { colors } = getTenantConfig();

// TODO: yes, this is pretty ugly, but rewriting all of the code
// that relies on actual ESM exports is a lot of changes and I'll
// do that later... or never...

const {
  primary,
  primaryContrastText,
  accent,
  accentContrastText,
  highlight,
  highlightContrastText,
  secondary,
  secondary100,
  secondaryContrastText,
  alternative600,
  alternative,
  alternative100,
  alternativeContrastText,
  neutral900,
  neutral800,
  neutral700,
  neutral500,
  neutral400,
  neutral300,
  neutral200,
  neutral100,
  black,
  white,
  systemRed,
  systemRedBg,
  systemGreen,
  systemGreenBg,
  systemOrange,
  systemYellow,
  systemCyan,
  systemPink,
  systemPurple,
  systemPurpleBg,
  success,
  success100,
  warning,
  error,
  error100,
  shadowColor,
} = colors;

export {
  primary,
  primaryContrastText,
  accent,
  accentContrastText,
  highlight,
  highlightContrastText,
  secondary,
  secondary100,
  secondaryContrastText,
  alternative600,
  alternative,
  alternative100,
  alternativeContrastText,
  neutral900,
  neutral800,
  neutral700,
  neutral500,
  neutral400,
  neutral300,
  neutral200,
  neutral100,
  black,
  white,
  systemRed,
  systemRedBg,
  systemGreen,
  systemGreenBg,
  systemOrange,
  systemYellow,
  systemCyan,
  systemPink,
  systemPurple,
  systemPurpleBg,
  success,
  success100,
  warning,
  error,
  error100,
  shadowColor,
};
