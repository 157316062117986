import styled, { css } from 'styled-components';

import { FormHint } from './hint';
import { FormLabel } from './label';

// eslint-disable-next-line import/prefer-default-export
export const FormControl = styled.div<{ dense?: boolean }>`
  margin-top: 15px;
  ${FormHint} {
    padding-top: 8px;
    padding-left: 16px;
  }
  &:not(:first-child) {
    margin-top: ${props => (props.dense ? 30 : 36)}px;
  }
  ${props =>
    props.dense &&
    css`
      display: flex;
      flex-wrap: wrap;
      align-items: center;
      ${FormHint} {
        width: 100%;
        margin-left: 25%;
      }

      ${FormLabel} {
        width: 125px;
        text-align: right;
        margin-bottom: 0;
        margin-right: 20px;
      }
    `}
`;
