import sanitize from 'shared-parts/helpers/html/sanitize';

import { Container } from './box-text.styled';

interface BoxText {
  description: string;
  content: string;
  paddingRight: string;
}

const BoxText = ({ description, content, paddingRight }: BoxText): JSX.Element => (
  <Container paddingRight={paddingRight}>
    {/* eslint-disable react/no-danger */}
    <div className="description" dangerouslySetInnerHTML={{ __html: sanitize(description) }} />
    <div
      className="content"
      dangerouslySetInnerHTML={{
        __html: sanitize(content),
      }}
    />
    {/* eslint-enable react/no-danger */}
  </Container>
);

export default BoxText;
