import { Field } from 'formik';
import { decamelize } from 'humps';

import {
  Container,
  DisableAutocompleteFakeInput,
  HintText,
  IconContainer,
  Input,
  ShowPassword,
} from './regular-text-input.styled';
import InputWrapper from './text-input';

const renderDisableAutocompleteFakeInput = () => (
  <DisableAutocompleteFakeInput name="fake" id="fake" type="text" data-e2e="fake" />
);

const InputComponent = ({ className, onIconClick, hint, Prefix, ...rest }) => {
  return (
    <Container
      className={className}
      isValueValid={rest.isValueValid}
      touched={rest.touched}
      backgroundColor={rest.backgroundColor}
      withIcon={rest.icon}
      hideValidationIcon={rest.hideValidationIcon}
      hasAdditional={!!Prefix}
    >
      {rest.disableAutocomplete && renderDisableAutocompleteFakeInput()}
      {Prefix && Prefix}
      <Input
        {...rest}
        ref={rest.inputRef}
        onClick={rest.onClick}
        autoComplete={rest.disableAutocomplete ? 'nope' : rest.autoComplete}
        data-e2e={rest.name ? decamelize(rest.name, { separator: '-' }) : ''}
        onWheel={e => e.target.blur()}
        hasPrefix={!!Prefix}
      />
      {!rest.disabled && rest.password && (
        <ShowPassword
          color={rest.placeholderColor}
          touched={rest.touched}
          onClick={rest.handleShowPasswordChange}
        >
          Show Password
        </ShowPassword>
      )}
      {hint && <HintText touched={rest.touched}>{hint}</HintText>}
      {rest.icon && <IconContainer onClick={onIconClick}>{rest.icon}</IconContainer>}
    </Container>
  );
};

export default props => <Field {...props} component={InputWrapper} styledInput={InputComponent} />;
