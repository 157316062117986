import classNames from 'classnames';
import PropTypes from 'prop-types';

import { Wrapper } from './button.styled';

const Button = ({
  title,
  className = '',
  inactive,
  beALink = false,
  url = '',
  onClick,
  type = 'button',
}) => {
  const classes = classNames('button', className);
  const conditionUrl = inactive ? undefined : url;

  if (beALink) {
    return (
      <Wrapper>
        <a href={conditionUrl} className={`${classes}${inactive ? ' inactive' : ''}`}>
          {title}
        </a>
      </Wrapper>
    );
  }

  return (
    <Wrapper>
      <button
        type={type} /* eslint-disable-line */
        disabled={inactive}
        className={classes}
        onClick={onClick}
      >
        {title}
      </button>
    </Wrapper>
  );
};

/* eslint-disable */
Button.propTypes = {
  title: PropTypes.string.isRequired,
  className: PropTypes.string,
  url: PropTypes.string,
  beALink: PropTypes.bool,
  type: PropTypes.string,
};
/* eslint-enable */

export default Button;
