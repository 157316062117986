import * as constants from 'app-state/constants';

import getReducerState from './get-reducer-state';

export const onboardingReducer = (state = getReducerState(), { type, data, error }) => {
  // eslint-disable-line
  switch (type) {
    case constants.SETUP_SUMSUB_CONFIG:
      return getReducerState();
    case constants.SETUP_SUMSUB_CONFIG_SUCCESS:
      return getReducerState({ loading: false, data });
    case constants.SETUP_SUMSUB_CONFIG_ERROR:
      return getReducerState({ loading: false, error });
    case constants.RESET_SUMSUB_CONFIG:
      return getReducerState();
    default:
      return state;
  }
};
