import { styled } from '@mui/material';
import MuiSwitch, { SwitchProps as MuiSwitchProps } from '@mui/material/Switch';

import { StripColors } from '../../utils';

export type SwitchProps = StripColors<MuiSwitchProps>;

export const Switch = styled((props: SwitchProps) => (
  <MuiSwitch {...props} focusVisibleClassName=".Mui-focusVisible" />
))(({ theme, color = 'secondary' }) => ({
  '&.MuiSwitch-sizeMedium': {
    padding: '9px',
    width: 60,
    height: 40,

    '& .MuiSwitch-switchBase': {
      padding: 7,
      margin: 4,
    },
  },

  '& .MuiSwitch-switchBase': {
    transitionDuration: '300ms',

    '&.Mui-checked': {
      transform: 'translateX(20px)',
      color: '#fff',

      '& + .MuiSwitch-track': {
        backgroundColor: theme.palette[color].main,
        border: 0,
        opacity: 1,
      },

      '&.Mui-focusVisible .MuiSwitch-thumb': {
        color: theme.palette[color].main,
      },
    },

    '&.Mui-focusVisible .MuiSwitch-thumb': {
      color: theme.palette.grey[300],
      border: '6px solid #fff',
    },

    '&.Mui-disabled + .MuiSwitch-track': {
      opacity: 1,
      backgroundColor: theme.palette.grey[100],
      border: '1px solid',
      borderColor: theme.palette.grey[200],
    },
    '&.Mui-disabled .MuiSwitch-thumb': {
      color: theme.palette.grey[300],
      boxShadow: 'none',
    },
  },

  '& .MuiSwitch-thumb': {
    boxSizing: 'border-box',
    boxShadow: '0px 2px 2px -1px rgb(0 0 0 / 20%)',
    width: 18,
    height: 18,
    top: 2,
  },

  '& .MuiSwitch-track': {
    opacity: 1,
    borderRadius: 26 / 2,
    backgroundColor: theme.palette.neutral[300],
    transition: theme.transitions.create(['background-color'], {
      duration: 300,
    }),
  },

  '&.MuiSwitch-sizeSmall': {
    padding: '10px',

    width: 48,
    height: 36,

    '& .MuiSwitch-switchBase': {
      margin: 2,
      padding: '10px',

      '&.Mui-checked': {
        transform: 'translateX(12px)',
        color: '#fff',
      },
    },

    '& .MuiSwitch-thumb': {
      boxSizing: 'border-box',
      boxShadow: '0px 1px 1px -1px rgb(0 0 0 / 20%)',
      width: 12,
      height: 12,
      top: 2,
    },
  },
}));
