import {
  GET_CURRENCIES,
  GET_CURRENCIES_ERROR,
  GET_CURRENCIES_SUCCESS,
  RESET_CURRENCIES,
} from 'app-state/currencies/constants';
import type { CurrenciesActionTypes, CurrenciesData } from 'app-state/currencies/types';

export const getCurrencies = (payload: string): CurrenciesActionTypes => ({
  type: GET_CURRENCIES,
  payload,
});

export const getCurrenciesSuccess = (payload: CurrenciesData): CurrenciesActionTypes => ({
  type: GET_CURRENCIES_SUCCESS,
  payload,
});

export const getCurrenciesError = (payload: string): CurrenciesActionTypes => ({
  type: GET_CURRENCIES_ERROR,
  payload,
});

export const resetCurrencies = (): CurrenciesActionTypes => ({
  type: RESET_CURRENCIES,
});
