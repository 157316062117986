import React from 'react';
import ClickOutside from 'react-click-outside';

import { getRange } from '@frontend/utils';

import { TextInput } from 'shared-parts/components/text-input';
import { daysOfWeek, namedMonths } from 'shared-parts/constants/dates';
import { allowNumbersOnly, allowNumbersOnlyOnPaste } from 'shared-parts/helpers/allow-numbers-only';
import { parseDate } from 'shared-parts/helpers/date-time-format';
import { dashed } from 'shared-parts/helpers/format-string';

import {
  AMPM,
  Arrow,
  ArrowDown,
  ArrowLeft,
  ArrowOnTheTop,
  ArrowRight,
  ArrowUp,
  Buttons,
  DatepickerContainer,
  DatepickerWrapper,
  Day,
  DayOfWeek,
  Days,
  DaysOfWeek,
  HoursAndMinutesBlock,
  HoursAndMinutesInput,
  InputWrapper,
  MonthsDropdown,
  SetButton,
  Time,
  TimeLabel,
  Today,
  Wrapper,
  YearDropdown,
} from './datepicker.styled.jsx';

const getCurrentHours = () => {
  const currentDate = new Date().getHours();

  return ((currentDate + 11) % 12) + 1;
};

export default class Datepicker extends React.Component {
  state = {
    visible: false,
    day: new Date().getDate(),
    month: new Date().getMonth(),
    year: new Date().getFullYear(),
    hours: getCurrentHours(),
    minutes: new Date().getMinutes(),
    am: new Date().getHours() < 12,
  };

  to = this.props.to || new Date().getFullYear() + 100;

  from = this.props.from || this.to - 200;

  onHoursMinutesEdit = entity => e => {
    this.setState({ [entity]: +e.target.value.slice(-2) });
  };

  onInputChange = e => {
    this.updateSelectedDate(e.target.value);
  };

  getNumberOfDaysInMonth = (month, year) => new Date(year, month + 1, 0).getDate();

  getPreviousMonth = (month, year) => {
    const previousMonth = month - 1;

    if (previousMonth < 0) {
      return { month: 11, year: year - 1 };
    }
    return { month: previousMonth, year };
  };

  getNextMonth = (month, year) => {
    const nextMonth = month + 1;

    if (nextMonth > 11) {
      return { month: 0, year: year + 1 };
    }
    return { month: nextMonth, year };
  };

  getDaysCurrent = (month, year) => {
    const numberOfDays = this.getNumberOfDaysInMonth(month, year);
    const isSelectedMonth = true;

    return getRange(1, numberOfDays).map(this.createDayObj(isSelectedMonth, { month, year }));
  };

  getDaysBefore = (month, year) => {
    const previousMonthDate = this.getPreviousMonth(month, year);
    const numberOfDays = this.getNumberOfDaysInMonth(
      previousMonthDate.month,
      previousMonthDate.year,
    );
    const firstDayOfMonth = new Date(year, month, 1).getDay();
    const isSelectedMonth = false;

    return getRange(numberOfDays - firstDayOfMonth + 1, numberOfDays).map(
      this.createDayObj(isSelectedMonth, previousMonthDate),
    );
  };

  getDaysAfter = (month, year) => {
    const nextMonthDate = this.getNextMonth(month, year);
    const lastDayOfMonth = new Date(year, month + 1, 0).getDay();
    const isSelectedMonth = false;

    return getRange(1, 6 - lastDayOfMonth).map(this.createDayObj(isSelectedMonth, nextMonthDate));
  };

  getMonthDropdownOptions = (month, index) => ({
    text: month,
    value: index,
  });

  getYearDropdownOptions = year => ({
    text: String(year),
    value: year,
  });

  getValue = () => {
    const { isTimeSelectionAllowed } = this.props;
    const { day, month, year, hours, minutes, am } = this.state;
    const currentMonth = month + 1;
    const formattedDay = day < 10 ? `0${day}` : day;
    const formattedMonth = currentMonth < 10 ? `0${currentMonth}` : currentMonth;
    const formattedHours = this.addZeroBeforeNumber(hours);
    const formattedMinutes = this.addZeroBeforeNumber(minutes);
    const ampm = am ? 'AM' : 'PM';
    const time = ` ${formattedHours}:${formattedMinutes}${ampm}`;
    const formattedTime = isTimeSelectionAllowed ? time : '';

    return `${formattedDay}-${formattedMonth}-${year}${formattedTime}`;
  };

  setDatepickerInputValue = async () => {
    const { form, name, onChange } = this.props;
    const { setFieldValue } = form;
    const { visible } = this.state;
    const value = this.getValue();

    if (onChange) {
      onChange(value, form);
    }

    await setFieldValue(name, value);

    if (visible) {
      this.updateTouchedFlag(false);
    }
  };

  setMonthAndYearSelectValues = () => {
    const { setFieldValue } = this.props.form;
    const { month, year } = this.state;

    setFieldValue('datepicker-year-dropdown', year);
    setFieldValue('datepicker-month-dropdown', month);
  };

  updateTouchedFlag = (shouldValidate = true) => {
    this.props.form.setFieldTouched(this.props.name, true, shouldValidate);
  };

  updateSelectedDate = date => {
    const newDate = parseDate(date, this.props.isTimeSelectionAllowed);

    if (date && newDate) {
      this.setState(newDate, () => {
        this.setMonthAndYearSelectValues();
        this.setDatepickerInputValue();
      });
    }
  };

  initializeDefaultDate = () => {
    const { form, name } = this.props;
    const {
      values: { [name]: date },
      errors: { [name]: error },
    } = form;

    if (error || !date) return;

    this.updateSelectedDate(date);
  };

  showDatePicker = () => {
    if (!this.state.visible) this.initializeDefaultDate();

    this.setState({ visible: true }, this.setMonthAndYearSelectValues);
  };

  closeDatePicker = () => {
    if (this.state.visible) {
      this.updateTouchedFlag();

      const { form, name, onClose } = this.props;

      if (onClose) {
        const {
          values: { [name]: date },
          errors: { [name]: error },
        } = form;

        if (date && !error) {
          const value = this.getValue();

          onClose({ value }, form);
        }
      }
      this.setState({ visible: false });
    }
  };

  changeMonth =
    next =>
    ({ month, year }) =>
      next ? this.getNextMonth(month, year) : this.getPreviousMonth(month, year);

  handleArrowClick = next => () => {
    this.setState(this.changeMonth(next), this.setMonthAndYearSelectValues);
  };

  handleYearSelect = value => {
    this.setState({ year: +value, day: 1 });
  };

  handleMonthSelect = value => {
    this.setState({ month: +value, day: 1 });
  };

  handleDayClick = (year, month, day) => () => {
    this.setState({ year, month, day }, async () => {
      this.setMonthAndYearSelectValues();
      await this.setDatepickerInputValue();

      if (!this.props.isTimeSelectionAllowed) {
        this.closeDatePicker();
      }
    });
  };

  handleKeyDown = e => {
    if (e.key === 'Enter') {
      this.closeDatePicker();
    }
  };

  addZeroBeforeNumber = number => {
    const lastTwoDigits = String(number).slice(-2);
    const formattedNumber = Number(lastTwoDigits);

    if (formattedNumber > 9 || formattedNumber < -9) {
      return formattedNumber;
    }

    return `0${formattedNumber}`;
  };

  handleTodayButtonClick = () => {
    const date = new Date();

    this.setState(
      {
        day: date.getDate(),
        month: date.getMonth(),
        year: date.getFullYear(),
      },
      async () => {
        this.setMonthAndYearSelectValues();
        await this.setDatepickerInputValue();
        this.closeDatePicker();
      },
    );
  };

  handleSETButtonClick = async () => {
    await this.setDatepickerInputValue();
    this.closeDatePicker();
  };

  handleAMPMClick = am => () => {
    this.setState({ am }, this.setDatepickerInputValue);
  };

  adjustNumber = (operand, number, min, max) => {
    if ((operand > 0 && number < max) || (operand < 0 && number > min)) {
      return number + operand;
    }

    if (operand > 0 && number >= max) {
      return min;
    }

    if (operand < 0 && number <= min) {
      return max;
    }
  };

  correctTheValue = (entity, valueToSet, min, max) => () => {
    const { [entity]: field } = this.state;

    this.setState(
      {
        [entity]: field < min || field > max ? valueToSet : field,
      },
      this.setDatepickerInputValue,
    );
  };

  changeHoursByArrowClick = operand => () => {
    this.setState(
      prevState => ({
        hours: this.adjustNumber(operand, prevState.hours, 1, 12),
      }),
      this.setDatepickerInputValue,
    );
  };

  changeMinutesByArrowClick = operand => () => {
    this.setState(
      prevState => ({
        minutes: this.adjustNumber(operand, prevState.minutes, 0, 59),
      }),
      this.setDatepickerInputValue,
    );
  };

  createDayObj =
    (isSelectedMonth, { year, month }) =>
    day => {
      const disabled =
        (this.props.areFutureDatesDisabled && this.checkIfDateIsFuture(year, month, day)) ||
        (!!this.props.minDate && this.checkIfDateISBeforeMin(year, month, day, this.props.minDate));
      const highlighted = isSelectedMonth && !disabled;
      return {
        highlighted,
        disabled,
        year,
        month,
        day,
      };
    };

  checkIfDateIsFuture = (year, month, day) => new Date(year, month, day) > new Date();

  checkIfDateISBeforeMin = (year, month, day, minDate) =>
    new Date(Date.UTC(year, month, day)) < minDate;

  checkIfDateIsSelected = (year, month, day) => {
    const { form, name, isAutoselectDisabled } = this.props;
    const {
      values: { [name]: date },
    } = form;

    const parsedDate = isAutoselectDisabled
      ? parseDate(date)
      : {
          day: this.state.day,
          month: this.state.month,
          year: this.state.year,
        };

    return parsedDate.year === year && parsedDate.month === month && parsedDate.day === day;
  };

  transformTodayValue = date => {
    if (!this.props.transformToday) return date;

    const { year, month, day } = parseDate(date);
    return year === new Date().getFullYear() &&
      month === new Date().getMonth() &&
      day === new Date().getDate()
      ? 'Today'
      : date;
  };

  renderYearDropdown = () => {
    const years = getRange(this.from, this.to).sort((a, b) => b - a);

    return (
      <YearDropdown
        name="datepicker-year-dropdown"
        placeholder="year"
        value={this.state.year}
        options={years.map(this.getYearDropdownOptions)}
        onOptionSelect={this.handleYearSelect}
      />
    );
  };

  renderMonthsDropdown = () => (
    <MonthsDropdown
      name="datepicker-month-dropdown"
      placeholder="month"
      value={this.state.month}
      options={namedMonths.map(this.getMonthDropdownOptions)}
      onOptionSelect={this.handleMonthSelect}
    />
  );

  renderChangeMonthButton = direction => {
    const { month, year } = this.state;
    const next = direction === '>';
    const disabled = next ? month === 11 && year === this.to : month === 0 && year === this.from;

    return (
      <Arrow
        type="button"
        disabled={disabled}
        onClick={this.handleArrowClick(next)}
        data-e2e={`${next ? 'next' : 'previous'}-month-button`}
      >
        {next ? <ArrowRight /> : <ArrowLeft />}
      </Arrow>
    );
  };

  renderDayOfWeek = day => <DayOfWeek key={day}>{day}</DayOfWeek>;

  renderDaysOfWeek = () => (
    <DaysOfWeek>{Object.values(daysOfWeek).map(this.renderDayOfWeek)}</DaysOfWeek>
  );

  renderDay = ({ highlighted, disabled, year, month, day }) => (
    <Day
      key={`${day}-${month}-${year}`}
      data-e2e={`${day}-${month}-${year}`}
      type="button"
      disabled={disabled}
      highlighted={highlighted}
      selected={this.checkIfDateIsSelected(year, month, day)}
      onClick={this.handleDayClick(year, month, day)}
    >
      {day < 10 ? `0${day}` : day}
    </Day>
  );

  renderDays = () => {
    const { month, year } = this.state;
    const daysForCurrentMonth = this.getDaysCurrent(month, year);
    const daysBefore = this.getDaysBefore(month, year);
    const daysAfter = this.getDaysAfter(month, year);
    const days = [...daysBefore, ...daysForCurrentMonth, ...daysAfter];

    return <Days>{days.map(this.renderDay)}</Days>;
  };

  renderTime = () => {
    const { am, hours, minutes } = this.state;

    return (
      <Time>
        <TimeLabel>Time:</TimeLabel>
        <HoursAndMinutesBlock>
          <InputWrapper>
            <ArrowUp onClick={this.changeHoursByArrowClick(1)} />
            <HoursAndMinutesInput
              isRightBorderHidden
              onChange={this.onHoursMinutesEdit('hours')}
              value={this.addZeroBeforeNumber(hours)}
              onBlur={this.correctTheValue('hours', 1, 1, 12)}
              onKeyDown={allowNumbersOnly}
              onPaste={allowNumbersOnlyOnPaste}
              data-e2e={`${this.props.name}-hours-input`}
            />
            <ArrowDown onClick={this.changeHoursByArrowClick(-1)} />
          </InputWrapper>
          <InputWrapper>
            <ArrowUp onClick={this.changeMinutesByArrowClick(1)} />
            <HoursAndMinutesInput
              onChange={this.onHoursMinutesEdit('minutes')}
              value={this.addZeroBeforeNumber(minutes)}
              onBlur={this.correctTheValue('minutes', 0, 0, 59)}
              onKeyDown={allowNumbersOnly}
              onPaste={allowNumbersOnlyOnPaste}
              data-e2e={`${this.props.name}-minutes-input`}
            />
            <ArrowDown onClick={this.changeMinutesByArrowClick(-1)} />
          </InputWrapper>
        </HoursAndMinutesBlock>
        <AMPM type="button" highlighted={am} onClick={this.handleAMPMClick(true)}>
          AM
        </AMPM>
        <AMPM type="button" highlighted={!am} onClick={this.handleAMPMClick(false)}>
          PM
        </AMPM>
      </Time>
    );
  };

  renderButtons = () => {
    const { isTimeSelectionAllowed, isTodaySet } = this.props;
    const setButtonData = { handler: this.handleSETButtonClick, text: 'SET', Component: SetButton };
    const todayButtonData = {
      handler: this.handleTodayButtonClick,
      text: 'Today',
      Component: Today,
      params: { isTodaySet },
    };
    const {
      handler,
      text,
      Component,
      params = {},
    } = isTimeSelectionAllowed ? setButtonData : todayButtonData;

    return (
      <Buttons>
        <Component type="button" onClick={handler} {...params} data-e2e={`${dashed(text)}-button`}>
          {text}
        </Component>
      </Buttons>
    );
  };

  renderDatePicker = () =>
    this.state.visible && (
      <DatepickerWrapper>
        <ArrowOnTheTop arrowPosition={this.props.arrowPosition} />
        <DatepickerContainer>
          {this.renderChangeMonthButton('<')}
          {this.renderMonthsDropdown()}
          {this.renderYearDropdown()}
          {this.renderChangeMonthButton('>')}
          {this.renderDaysOfWeek()}
          {this.renderDays()}
          {this.props.isTimeSelectionAllowed && this.renderTime()}
          {this.renderButtons()}
        </DatepickerContainer>
      </DatepickerWrapper>
    );

  render = () => (
    <ClickOutside onClickOutside={this.closeDatePicker} className={this.props.className}>
      <Wrapper>
        <TextInput
          disabled={this.props.disabled}
          name={this.props.name}
          disableOnBlur
          onKeyDown={this.handleKeyDown}
          placeholder={this.props.placeholder}
          onInputClick={this.props.onClick || this.showDatePicker}
          handleChange={this.onInputChange}
          transformValue={this.transformTodayValue}
          disableAutocomplete
        />
        {this.renderDatePicker()}
      </Wrapper>
    </ClickOutside>
  );
}

Datepicker.defaultProps = {
  isTimeSelectionAllowed: false,
  areFutureDatesDisabled: false,
  isAutoselectDisabled: false,
  minDate: null,
  transformToday: true,
};
