import ClickOutside from 'react-click-outside';

import styled from 'styled-components';

import * as Colors from 'shared-parts/constants/colors';

const TooltipWrapper = styled(ClickOutside)`
  display: inline-block;
  position: relative;
`;

const Arrow = styled.div`
  position: absolute;
  ${({ arrowPosition: { container } }) => container};

  &:after,
  &:before {
    border-style: solid;
    content: '';
    display: inline-block;
    height: 0;
    position: absolute;
    z-index: 1;
    width: 0;

    ${({ arrowPosition: { arrow } }) => arrow}
  }

  &:after {
    border-color: ${({ arrowColor }) => `transparent transparent ${arrowColor} transparent`};
    border-width: 10px;

    ${({ arrowPosition: { after } }) => after}
  }

  &:before {
    border-color: transparent transparent ${Colors.neutral500} transparent;
    border-width: 9px;

    ${({ arrowPosition: { before } }) => before}
  }
`;

const AppearingPart = styled.div`
  background-color: ${Colors.white};
  border: 1px solid ${Colors.neutral500};
  border-radius: 6px;
  box-shadow: 0 2px 5px ${Colors.neutral800};
  position: fixed;
  z-index: 1010;
`;

export { TooltipWrapper, Arrow, AppearingPart };
