import { useEffect } from 'react';

import { Field } from 'formik';

import Error from 'shared-parts/components/error';

import { Container, StyledTextArea } from './text-area.styled';

const TextArea = props => {
  const {
    field,
    form,
    disabled,
    placeholder,
    onInputClick,
    type = 'text',
    backgroundColor,
    placeholderColor,
    textColor,
    className,
    autoComplete,
    handleChange,
    defaultVisibleLines,
    resize,
    disableAutocomplete,
  } = props;
  const { name, onBlur, value } = field;
  const { errors, touched } = form;
  const error = Array.isArray(errors[name]) ? errors[name][0] : errors[name];

  useEffect(() => {
    if (value) {
      form.setFieldTouched(name, true);
    }
  }, []);

  const onChange = e => {
    field.onChange(e);

    if (handleChange) {
      handleChange(e, form);
    }
  };

  return (
    <Container className={className} isValueValid={!error} touched={touched[name]}>
      <StyledTextArea
        resize={resize}
        defaultVisibleLines={defaultVisibleLines}
        value={field.value || ''}
        onClick={onInputClick}
        onChange={onChange}
        onBlur={onBlur}
        name={name}
        autoComplete={autoComplete}
        disableAutocomplete={disableAutocomplete}
        placeholder={placeholder}
        disabled={disabled}
        touched={touched[name]}
        isValueValid={!error}
        placeholderColor={placeholderColor}
        backgroundColor={backgroundColor}
        textColor={textColor}
        type={type}
      />
      {error && touched[name] && <Error message={error} />}
    </Container>
  );
};

export default props => <Field {...props} component={TextArea} />;
