import { styled } from '@mui/material';

import { Screens } from './trade-offs';

export const DaysToGo = styled('div')`
  font-weight: bold;
  text-align: center;
  @media only screen and (min-width: ${Screens.sm}) {
    font-size: 14px;
  }
  @media only screen and (max-width: ${Screens.sm}) {
    font-size: 12px;
  }
`;

export const Funded = styled('div')<{ neutral500Color: string }>`
  width: 100%;
  background: ${({ neutral500Color }) => neutral500Color};
  color: ${({ theme }) => theme.palette.neutral[0]};
  font-weight: bold;
  padding: 8px 0;
  position: relative;
  @media only screen and (min-width: ${Screens.sm}) {
    font-size: 14px;
    line-height: 18px;
  }
  @media only screen and (max-width: ${Screens.sm}) {
    font-size: 12px;
    line-height: 14px;
  }
`;

export const FundedValue = styled('div')`
  position: relative;
  text-align: center;
  z-index: 2;
`;

export const CommonsStats = styled('div')<{ neutral300Color: string }>`
  border-top: 1px solid ${({ neutral300Color }) => neutral300Color};
  padding-top: 12px;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 8px;
  text-align: center;

  &:empty {
    border: none;
    padding: 0;
  }
`;
