import { call, put, takeEvery } from 'redux-saga/effects';

import * as actions from 'app-state/actions';
import * as constants from 'app-state/constants';

import TakeTestModal from 'modules/onboard/individual/components/take-test-modal';
import API from 'constants/api';
import { request } from 'shared-parts/helpers';

function* fetchSuitabilityTestQuestions() {
  yield put(actions.showLoader());

  try {
    yield put(actions.resetSuitabilityTestAttemptsError());
    const { data } = yield call(
      request,
      API.Onboarding.Individual.quizQuestions('UK investors suitability tests'),
    );

    yield put(actions.fetchSuitabilityTestQuestionsSuccess(data));
    yield put(
      actions.showModal({
        closable: true,
        showHeader: false,
        component: TakeTestModal,
      }),
    );
  } catch (e) {
    if (e.status === 403 && e.response.errorMessage) {
      return yield put(actions.setSuitabilityTestAttemptsError(e.response.errorMessage));
    }
    yield put(actions.fetchSuitabilityTestQuestionsError(e));
  }

  yield put(actions.hideLoader());
}

function* fetchSuitabilityTestQuestionsWatcher() {
  yield takeEvery(constants.FETCH_SUITABILITY_TEST_QUESTIONS, fetchSuitabilityTestQuestions);
}

function* suitabilityTestSubmit(action) {
  yield put(actions.showLoader());

  try {
    const { data } = yield call(
      request,
      API.Onboarding.Individual.submitQuiz(),
      'POST',
      action.results,
    );

    yield put(actions.suitabilityTestPassed(data));
  } catch (e) {
    if (e.status === 422) {
      if (e.response.errors) {
        yield put(actions.setSuitabilityTestAttemptsError(e.response.errors.answers[0]));
      }
      yield put(actions.suitabilityTestFailed(e.response));
    }
  }

  yield put(actions.hideLoader());
}

function* suitabilityTestSubmitWatcher() {
  yield takeEvery(constants.SUITABILITY_TEST_SUBMIT, suitabilityTestSubmit);
}

export {
  fetchSuitabilityTestQuestions,
  fetchSuitabilityTestQuestionsWatcher,
  suitabilityTestSubmit,
  suitabilityTestSubmitWatcher,
};
