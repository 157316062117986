import { FC } from 'react';

import * as Colors from 'shared-parts/constants/colors';

interface ArrowDownProps {
  width?: number;
  height?: number;
  color?: string;
  flipVertically?: boolean;
}

const ArrowDownIcon: FC<React.PropsWithChildren<ArrowDownProps>> = ({
  width = 8,
  height = 8,
  color = Colors.primary,
  flipVertically = false,
}) => (
  <svg
    width={width.toString()}
    height={height.toString()}
    xmlns="http://www.w3.org/2000/svg"
    viewBox="-11577.721 -15665.967 11.012 6.989"
    transform={`scale(1,${flipVertically ? '-1' : '1'})`}
  >
    <g transform="translate(-11577.671 -15674)">
      <g transform="translate(-0.05 8.033)">
        <path
          fill={color}
          d="M5.434,32.64a1.493,1.493,0,0,1-1.075-.43l-3.957-4A1.455,1.455,0,0,1,.4,26.1a1.455,1.455,0,0,1,2.108,0l2.925,2.925L8.359,26.1a1.521,1.521,0,0,1,2.151,2.151L6.553,32.21A1.913,1.913,0,0,1,5.434,32.64Z"
          transform="translate(0.05 -25.65)"
        />
      </g>
    </g>
  </svg>
);

export default ArrowDownIcon;
