import { push as pushRoute } from 'connected-react-router';
import { connect } from 'react-redux';

import Routes from 'constants/routes';
import CircleCheckIcon from 'shared-parts/components/svg-icons/circle-check';
import NextArrow from 'shared-parts/components/svg-icons/next-arrow';
import * as Colors from 'shared-parts/constants/colors';

import {
  ButtonText,
  ModalIconContainer,
  ModalText,
  ModalTitle,
  ModalWrapper,
  ViewTransactionButton,
} from './sell-investments-success-modal.styled';

const SellInvestmentsSuccessModal = ({ transactionUuid, push }) => (
  <ModalWrapper>
    <ModalIconContainer>{CircleCheckIcon(64, 64)}</ModalIconContainer>
    <ModalTitle>Transaction Initiated</ModalTitle>
    <ModalText>
      <strong>A transaction request has been sent</strong>
    </ModalText>
    <ModalText>
      <strong>We will keep you updated on its progress</strong>
    </ModalText>
    <ViewTransactionButton
      onClick={() => push(Routes.Transactions.SecondaryTransaction(transactionUuid))}
    >
      <ButtonText>View Transaction</ButtonText>
      <NextArrow color={Colors.alternative} width={6} height={10} />
    </ViewTransactionButton>
  </ModalWrapper>
);

const mapDispatchToProps = dispatch => ({
  push: url => dispatch(pushRoute(url)),
});

export default connect(undefined, mapDispatchToProps)(SellInvestmentsSuccessModal);
