import { Component } from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';

import * as actions from 'app-state/actions';
import * as selectors from 'app-state/selectors';

import { FeatureFlag, useFeatureFlag } from 'providers';

import AuthenticatedUserMenu from './components/authenticated-user-menu';

class UserHeaderContainer extends Component {
  handleLogout = e => {
    e.preventDefault();

    this.props.logout();
  };

  render() {
    return <AuthenticatedUserMenu {...this.props} handleLogout={this.handleLogout} />;
  }
}

const mapStateToProps = state => ({
  user: selectors.getUser(state),
});

const enableOauth2Login = useFeatureFlag(FeatureFlag.OAUTH2_LOGIN_ENABLE);

const mapDispatchToProps = dispatch => ({
  logout: () => dispatch(enableOauth2Login ? actions.oauth2Logout() : actions.logout()),
});

export default connect(mapStateToProps, mapDispatchToProps, null, { pure: false })(
  withRouter(UserHeaderContainer),
);
