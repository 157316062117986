import styled from 'styled-components';

import { ModalWrapper } from 'shared-parts/components/modal/shared.styled';
import { Colors } from 'shared-parts/constants';

export const Dialog = styled.div`
  padding: 50px 125px;
  width: 800px;
`;

export const AlertDialog = styled(ModalWrapper)`
  & p {
    color: ${Colors.neutral800};
    opacity: 0.6;
    width: 75%;
    text-align: center;
  }
`;

export const DialogActions = styled.div`
  display: flex;
  justify-content: center;
  margin-top: 50px;
  > * {
    margin: 0px 6px;
  }
`;
