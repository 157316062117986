import { useEnvVar } from '@frontend/config';

import { capitalizeFirstLetter } from 'shared-parts/helpers/format-string';

const getDealAlias = (options?: { plural?: boolean; capitalize?: boolean }): string => {
  const alias = String(useEnvVar('DEAL_ALIAS') || 'placement');
  const message = options?.plural ? `${alias}s` : alias;
  return options?.capitalize ? capitalizeFirstLetter(message) : message;
};

export { getDealAlias };
