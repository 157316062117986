import styled from 'styled-components';

import { useEnvVar } from '@frontend/config';

import MailLink from 'shared/mail-link';

export const invalidHTMLErrorMessage = 'Invalid HTML markup';

const smtpSupportEmail = useEnvVar('SMTP_SUPPORT_EMAIL');

export const ErrorWrapper = styled.div`
  text-align: center;
`;

export const defaultErrorMessage = (
  <ErrorWrapper>
    Please try again. If the issue persists please contact
    <MailLink mail={smtpSupportEmail} text={smtpSupportEmail} />.
  </ErrorWrapper>
);
