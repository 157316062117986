import * as constants from 'app-state/constants';

export const defaultDealsState = { loading: true, data: [], error: {} };

export const dealsReducer = (state = defaultDealsState, { type, data, error }) => {
  switch (type) {
    case constants.GET_DEALS_SUCCESS:
      return { loading: false, data, error: {} };
    case constants.GET_DEALS_ERROR:
      return { loading: false, data: [], error };
    case constants.RESET_DEALS:
      return defaultDealsState;
    default:
      return state;
  }
};
