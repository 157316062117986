import { Deal } from '../deal-tile.types';

import { FundedBar } from './closed-issuance-stats.styled';
import { CompanyStats } from './company-stats';
import { CommonsStats, DaysToGo, Funded, FundedValue } from './shared.styled';
import { namedMonths } from './trade-offs';

export type ClosedIssuanceStatsProps = Deal & {
  closedForInvestmentBlockBackground: string;
  oswaldFont: string;
  neutral500Color: string;
  neutral300Color: string;
};

export const ClosedIssuanceStats = ({
  subscriptionEnd,
  baseCurrency,
  raisedAmount,
  closedForInvestmentBlockBackground,
  oswaldFont,
  neutral500Color,
  neutral300Color,
}: ClosedIssuanceStatsProps) => {
  const endDate = new Date(subscriptionEnd);
  const monthName = namedMonths[endDate.getMonth()];
  const year = endDate.getFullYear();

  return (
    <CommonsStats neutral300Color={neutral300Color}>
      <CompanyStats
        oswaldFont={oswaldFont}
        text="Completed"
        currency={baseCurrency}
        amount={raisedAmount}
      />
      <Funded neutral500Color={neutral500Color}>
        <FundedValue>Closed For Investment</FundedValue>
        <FundedBar background={closedForInvestmentBlockBackground} />
      </Funded>
      <DaysToGo>
        Closed {monthName} {year}
      </DaysToGo>
    </CommonsStats>
  );
};
