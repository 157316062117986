import React, { FC } from 'react';

import { StepIcon, StepIconProps, useTheme } from '@mui/material';

interface StepIconComponentProps extends StepIconProps {
  label: string;
  isActive: boolean;
  color: string;
  fillColor: string;
  height?: number;
  width?: number;
}

const StepIconComponent: FC<StepIconComponentProps> = ({
  label,
  isActive,
  color,
  fillColor,
  height = 32,
  width = 32,
  ...args
}) => {
  const theme = useTheme();
  return isActive ? (
    <StepIcon
      {...args}
      icon={
        <svg viewBox="0 0 24 24" width={width} height={height} overflow="visible">
          <circle cx="12" cy="12" r="12" stroke={color} fill={fillColor} />
          <circle cx="12" cy="12" r="3" fill={color} stroke={color} />
        </svg>
      }
    />
  ) : (
    <StepIcon
      {...args}
      sx={{
        width: `${width}px`,
        height: `${height}px`,
        '*': {
          // smaller font size for step icon text - adjust for scaling
          fontFamily: theme.typography.fontFamily,
          fontSize: '12px !important',
          fontWeight: '300 !important',
        },
      }}
      icon={label}
    />
  );
};

export { StepIconComponent };
export type { StepIconComponentProps };
