export enum FORM_FIELDS {
  ITEMS = 'items',
  // todo - revert when PLAPI-1614 is resolved
  // TERM_SHEET = 'termSheet',
  SPECIAL_CONDITIONS = 'specialConditions',
}

export enum CAPITAL_FIELDS {
  VALUATION = 'valuation',
  INTENDED_AMOUNT = 'intendedAmount',
}

// eslint-disable-next-line no-shadow
export enum ADJUST_FORM_FIELDS {
  AMOUNT = 'amount',
}
