export const currencies = {
  GBP: {
    symbol: '£',
    position: 'left',
  },
  EUR: {
    symbol: '€',
    position: 'left',
  },
  USD: {
    symbol: '$',
    position: 'left',
  },
  BRL: {
    symbol: 'R$',
    position: 'left',
  },
  CAD: {
    symbol: '$',
    position: 'left',
  },
  CHF: {
    symbol: 'CHF',
    position: 'left',
  },
  DKK: {
    symbol: 'kr.',
    position: 'right',
  },
  HKD: {
    symbol: '$',
    position: 'left',
  },
  JPY: {
    symbol: '¥',
    position: 'left',
  },
  CNY: {
    symbol: '¥',
    position: 'left',
  },
  NOK: {
    symbol: 'kr',
    position: 'right',
  },
  SEK: {
    symbol: 'kr',
    position: 'right',
  },
  SGD: {
    symbol: '$',
    position: 'left',
  },
  ZAR: {
    symbol: 'R',
    position: 'left',
  },
  AUD: {
    symbol: 'A$',
    position: 'left',
  },
} as const;

export type Currency = keyof typeof currencies;

export type CurrencyInfo = {
  label: Currency;
  symbol: (typeof currencies)[Currency]['symbol'];
  symbolPosition: (typeof currencies)[Currency]['position'];
};

export function renderCurrencySymbolString(currency: Currency, value: number | string): string {
  const currencyDetails = currencies[currency];

  const currencyInfo = {
    label: currency,
    symbol: currencyDetails?.symbol,
    symbolPosition: currencyDetails?.position,
  };

  if (currencyInfo.symbolPosition === 'right') {
    return `${value}${currencyInfo.symbol}`;
  }

  return `${currencyInfo.symbol}${value}`;
}
