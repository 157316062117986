import * as constants from 'app-state/constants';

export const fetchOrders = ordersType => ({ type: constants.FETCH_ORDERS, ordersType });
export const fetchOrdersSuccess = data => ({ type: constants.FETCH_ORDERS_SUCCESS, data });
export const fetchOrdersError = error => ({ type: constants.FETCH_ORDERS_ERROR, error });
export const resetOrders = () => ({ type: constants.RESET_ORDERS });

export const fetchOrdersPagesData = ordersType => ({
  type: constants.FETCH_ORDERS_PAGE_DATA,
  ordersType,
});
export const fetchOrdersPagesDataSuccess = () => ({
  type: constants.FETCH_ORDERS_PAGE_DATA_SUCCESS,
});
export const fetchOrdersPagesDataError = error => ({
  type: constants.FETCH_ORDERS_PAGE_DATA_ERROR,
  error,
});

export const changeOrdersFilterParam = filterParam => ({
  type: constants.CHANGE_ORDERS_FILTER_PARAM,
  filterParam,
});

export const resetOrderData = () => ({ type: constants.RESET_ORDER_DATA });

export const addOrderRequest = params => ({ type: constants.ADD_ORDER_REQUEST, ...params });
export const addOrderSuccess = data => ({ type: constants.ADD_ORDER_SUCCESS, data });
export const addOrderError = error => ({ type: constants.ADD_ORDER_ERROR, error });

export const cancelOrder = id => ({ type: constants.CANCEL_ORDER, id });

export const updateOrder = updatedOrderData => ({ type: constants.UPDATE_ORDER, updatedOrderData });

export const getBankAccount = currency => ({
  type: constants.GET_BANK_ACCOUNT,
  currency,
});
export const getBankAccountSuccess = data => ({ type: constants.GET_BANK_ACCOUNT_SUCCESS, data });
export const getBankAccountError = error => ({ type: constants.GET_BANK_ACCOUNT_ERROR, error });
