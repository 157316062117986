import { connect } from 'react-redux';

import { getAuthenticationStatus } from 'app-state/selectors';

import { AuthenticatedUserMenu, UnauthenticatedUserMenu } from 'shared';

const TopMenu = props =>
  props.authenticated ? <AuthenticatedUserMenu /> : <UnauthenticatedUserMenu />;

const mapStateToProps = state => ({
  authenticated: getAuthenticationStatus(state),
});

export default connect(mapStateToProps, {}, null, { pure: false })(TopMenu);
