import * as constants from 'app-state/constants';

import getReducerState from './get-reducer-state';

export const dealReducer = (state = getReducerState(), { type, data, error }) => {
  switch (type) {
    case constants.FETCH_DEAL:
      return getReducerState();
    case constants.FETCH_DEAL_SUCCESS:
      return getReducerState({ loading: false, data });
    case constants.FETCH_DEAL_ERROR:
      return getReducerState({ loading: false, error });
    case constants.RESET_DEAL:
      return getReducerState();
    default:
      return state;
  }
};

const defaultDealDocumentsState = {
  loading: false,
  data: [],
};

export const dealDocumentsReducer = (state = defaultDealDocumentsState, { type, payload }) => {
  switch (type) {
    case constants.FETCH_DEAL_DOCUMENTS:
      return defaultDealDocumentsState;
    case constants.FETCH_DEAL_DOCUMENTS_SUCCESS:
      return { loading: false, data: payload };
    case constants.UPDATE_AGREED_NDA_DEAL_DOCUMENT:
      return {
        loading: false,
        data: state.data.map(item => (item.id === payload ? { ...item, agreed: true } : item)),
      };
    case constants.RESET_DEAL_DOCUMENTS:
      return defaultDealDocumentsState;
    default:
      return state;
  }
};

export const getPageState = (params = {}) => ({
  loading: true,
  content: null,
  error: null,
  ...params,
});

export const pageReducer = (state = getPageState(), action) => {
  switch (action.type) {
    case constants.RESET_PAGE:
      return getPageState();
    case constants.FETCH_PAGE:
      return getPageState({
        loading: true,
        content: state.content,
        error: null,
      });
    case constants.FETCH_PAGE_SUCCESS:
      return getPageState({ loading: false, content: action.data });
    case constants.FETCH_PAGE_ERROR:
      return getPageState({ loading: false, error: action.error });
    case constants.FETCH_PAGE_RESTRICTED:
      return getPageState({ ...state, loading: false });
    default:
      return state;
  }
};

const defaultRiskBannerState = {
  riskBannerVisible: false,
};

export const riskBannerReducer = (state = defaultRiskBannerState, action) => {
  switch (action.type) {
    case constants.HIDE_RISK_BANNER:
      return {
        riskBannerVisible: false,
      };
    case constants.SHOW_RISK_BANNER:
      return {
        riskBannerVisible: true,
      };
    default:
      return state;
  }
};
