import { FC } from 'react';

import {
  createCurrencyColDef,
  createDateColDef,
  DataGrid,
  GridColDefExtended,
  GridLoadIcon,
  GridNoRowsOverlay,
} from '@frontend/design-system';
import { Currency } from '@frontend/utils';

import { AdjustmentHistoryItem } from './adjustment-history-item';

export const AdjustmentHistory: FC<
  React.PropsWithChildren<{
    order: { uuid: string; currency: Currency };
    orderAdjustmentHistory: AdjustmentHistoryItem[];
    loadingAdjustmentHistory: boolean;
  }>
> = ({ order, orderAdjustmentHistory, loadingAdjustmentHistory }) => {
  const disabledColFeatures = {
    sortable: false,
    aggregable: false,
    filterable: false,
  };

  const columns: GridColDefExtended<AdjustmentHistoryItem>[] = [
    {
      ...disabledColFeatures,
      ...createCurrencyColDef(order.currency),
      align: 'left',
      headerAlign: 'left',
      field: 'amount' as keyof AdjustmentHistoryItem,
      headerName: 'Amount',
      flex: 0.5,
    },
    {
      ...disabledColFeatures,
      field: 'submittedBy' as keyof AdjustmentHistoryItem,
      headerName: 'Submitted by',
      flex: 1,
    },
    {
      ...disabledColFeatures,
      field: 'reason' as keyof AdjustmentHistoryItem,
      headerName: 'Reason',
      flex: 1,
    },
    {
      ...disabledColFeatures,
      ...createDateColDef('L LT'),
      align: 'left',
      headerAlign: 'left',
      field: 'createdAt' as keyof AdjustmentHistoryItem,
      headerName: 'Date',
      flex: 1,
    },
  ];

  const rows = orderAdjustmentHistory as AdjustmentHistoryItem[];

  const totals = rows?.length ?? 0;

  return (
    <DataGrid
      autoHeight
      loading={loadingAdjustmentHistory}
      columns={columns}
      rows={rows ?? []}
      getRowId={(row: AdjustmentHistoryItem) => row.id}
      rowCount={totals}
      disableRowSelectionOnClick
      disableMultipleRowSelection
      disableRowGrouping
      disableAggregation
      disableColumnMenu
      disableColumnPinning
      disableColumnSelector
      disableDensitySelector
      disableMultipleColumnsSorting
      disableColumnFilter
      disableColumnReorder
      hideFooter
      filterMode="client"
      sortingMode="client"
      paginationMode="client"
      localeText={{
        noRowsLabel: 'No adjustments',
      }}
      slots={{
        noRowsOverlay: () => <GridNoRowsOverlay />,
        loadIcon: () => <GridLoadIcon data-e2e="filter-load-icon" />,
      }}
    />
  );
};
