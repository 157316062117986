import { ReactNode } from 'react';

import { Target, Text, Value } from './deal-stats.styled';

export interface DealStatsProps {
  text: string;
  children: ReactNode;
  oswaldFont: string;
}

export const DealStats = ({ text, children, oswaldFont }: DealStatsProps) => (
  <Target>
    <Text>{text}</Text>
    <Value oswaldFont={oswaldFont}>{children}</Value>
  </Target>
);
