import { useState } from 'react';

import styled from 'styled-components';

import Routes from 'constants/routes';
import { FeatureFlag, useFeatureFlag } from 'providers';
import useMediaQuery from 'providers/device/use-media-query';
import XIcon from 'shared-parts/components/svg-icons/x-icon';
import { Screens } from 'shared-parts/constants';
import { primary } from 'shared-parts/constants/colors';

import {
  CloseButton,
  MenuLink,
  Modal,
  ModalHeader,
  ModalWrapper,
  NavigationItem,
  Wrapper,
} from '../styled';

import NavMenuToggle from './nav-menu-toggle';

const NavbarLinkList = styled.ul`
  margin: 0;
`;

const MobileNavigationMenu = ({ isOpen, onClose, children }) => (
  <Modal aria-hidden={!isOpen} id="mobile-nav-menu" isOpen={isOpen}>
    <ModalHeader>
      <CloseButton onClick={onClose}>{XIcon(15, 15, primary)}</CloseButton>
    </ModalHeader>
    {children}
  </Modal>
);

const UnauthenticatedUserMenu = () => {
  const [isOpen, setIsOpen] = useState(false);
  const isOauth2Enabled = useFeatureFlag(FeatureFlag.OAUTH2_LOGIN_ENABLE);

  const isMobile = useMediaQuery(`(max-width: ${Screens.xl})`);

  const onClickNavItem = isDevice => () => (isDevice ? setIsOpen(!isOpen) : undefined);

  const renderMainMenu = isDevice => (
    <span>
      <NavigationItem onClick={onClickNavItem(isDevice)}>
        <MenuLink to={Routes.Login()}>Log In</MenuLink>
      </NavigationItem>

      {!isOauth2Enabled && (
        <NavigationItem onClick={onClickNavItem(isDevice)}>
          <MenuLink to={Routes.SignUp()}>Sign up</MenuLink>
        </NavigationItem>
      )}
    </span>
  );

  return (
    <Wrapper>
      {isMobile ? (
        <nav>
          <NavMenuToggle aria-expanded={isOpen} onClick={() => setIsOpen(true)} />

          <MobileNavigationMenu isOpen={isOpen} onClose={() => setIsOpen(false)}>
            {renderMainMenu(true)}
          </MobileNavigationMenu>

          {isOpen && <ModalWrapper />}
        </nav>
      ) : (
        <nav>
          <NavbarLinkList>{renderMainMenu(false)}</NavbarLinkList>
        </nav>
      )}
    </Wrapper>
  );
};

export default UnauthenticatedUserMenu;
