import { checkPathSuitability } from '@frontend/utils';

import Routes from 'constants/routes';
import { FeatureFlag, useFeatureFlag } from 'providers/app-config';
import memorizePath from 'shared-parts/helpers/memorize-path';
import { getURLParameters } from 'shared-parts/helpers/url-parameters';

const onboardingRoutes = {
  select_investor_type: {
    white: [{ path: Routes.Onboarding.SelectInvestorType() }],
    black: [],
  },
  IndividualInvestor: {
    personal_information_step: {
      white: [{ path: Routes.Onboarding.Individual.AccountInformationFirstPart() }],
      black: [],
    },
    country_code_step: {
      white: [{ path: Routes.Onboarding.Individual.AccountInformationFirstPart() }],
      black: [],
    },
    terms_and_conditions_step: {
      white: [{ path: Routes.Onboarding.TermsAndConditions() }],
      black: [],
    },
    type_step: {
      white: [],
      black: [
        { path: Routes.Onboarding.Individual.SuitabilityTest() },
        { path: Routes.Onboarding.SelectInvestorType() },
        { path: Routes.Onboarding.Institutional.Root() },
        { path: Routes.SignUp() },
        { path: Routes.Login() },
        { path: Routes.Account.Preferences() },
        { path: Routes.Account.AgentPermissions() },
        { path: Routes.Onboarding.SelectInvestorType() },
        { path: Routes.Onboarding.TermsAndConditions() },
      ],
    },
    quiz_step: {
      white: [{ path: Routes.Onboarding.Individual.SuitabilityTest() }],
      black: [
        /* DO NOT CHANGE THIS ARRAY, OTHERWISE I CALL THE POLICE */
      ],
    },
  },
  InstitutionalInvestor: {
    account_information_step: {
      white: [{ path: Routes.Onboarding.Institutional.AccountInformation() }],
      black: [],
    },
    terms_and_conditions_step: {
      white: [{ path: Routes.Onboarding.TermsAndConditions() }],
      black: [],
    },
  },
};

export default class {
  constructor({ browserPath, user, isPlacementEnabled }) {
    this.browserPath = browserPath;
    this.user = user;
    this.isPlacementEnabled = isPlacementEnabled;
  }

  checkIfUserShouldBeRedirected(urls) {
    if (!urls) return false;
    const someUrlsBlacklisted = urls.black.length;
    const whitelisted = urls.white.some(url => checkPathSuitability(url, this.browserPath));
    const blacklisted = someUrlsBlacklisted
      ? urls.black.some(url => checkPathSuitability(url, this.browserPath))
      : !whitelisted;

    return blacklisted && !whitelisted;
  }

  getRedirectURL(urls) {
    const [lastUrlFromWhitelist] = urls.white.slice(-1) || [];
    const lastUrlPath = lastUrlFromWhitelist && lastUrlFromWhitelist.path;
    const url = (urls.redirectUrl && urls.redirectUrl.path) || lastUrlPath || Routes.Root();

    return getURLParameters(['actionPath'])[0] || url;
  }

  getUrlByOnboardingState({ investorType, investor }) {
    const { onboardingStatus, onboardingPhase } = investor || {};
    const isIndividualInvestor = investorType === 'IndividualInvestor';

    if (onboardingStatus === 'in_progress' || onboardingStatus === 'completed') {
      // Redirection for Institutional Investors
      return {
        redirectUrl: {
          path: this.getDefaultAuthenticatedRoute(),
        },
        white: [
          ...(!isIndividualInvestor
            ? [
                ...(investor.preferenceSet
                  ? [
                      {
                        path: Routes.Onboarding.Institutional.Completed(),
                      },
                    ]
                  : [
                      { path: Routes.Onboarding.InvestmentPreferences() },
                      { path: Routes.Account.Details() },
                      { path: Routes.Onboarding.Institutional.AccountInformationPreview() },
                      { path: Routes.Onboarding.Institutional.UserAgreements() },
                      { path: Routes.Onboarding.Institutional.AgentPermissions() },
                    ]),
              ]
            : [{ path: Routes.Onboarding.AmlVerification() }]),
        ],
        black: [
          { path: Routes.Onboarding.Root() },
          { path: Routes.SignUp() },
          { path: Routes.ResetPassword() },
          { path: Routes.Login() },
          ...(isIndividualInvestor
            ? [
                { path: Routes.Account.AgentPermissions() },
                { path: Routes.Onboarding.Institutional.Root() },
              ]
            : []),
        ],
      };
    }

    if (investor && investorType && onboardingPhase !== 'upload_document_step') {
      return onboardingRoutes[investorType][onboardingPhase];
    }

    if (investor && investorType && onboardingPhase === 'upload_document_step') {
      return {
        white: [],
        black: [
          { path: Routes.Onboarding.Individual.AccountInformationFirstPart() },
          { path: Routes.Onboarding.Individual.SuitabilityTest() },
          { path: Routes.Onboarding.SelectInvestorType() },
          { path: Routes.Onboarding.TermsAndConditions() },
          { path: Routes.Onboarding.Institutional.Root() },
          { path: Routes.SignUp() },
          { path: Routes.Login() },
          ...(isIndividualInvestor ? [{ path: Routes.Account.AgentPermissions() }] : []),
        ],
      };
    }

    if (!investor || !investorType) {
      return onboardingRoutes.select_investor_type;
    }
  }

  getUnauthenticatedUrls = ({ investorType }) => ({
    redirectUrl: { path: Routes.Login() },
    white: [],
    black: [
      { path: Routes.Deals(), exact: true },
      { path: Routes.Transactions.Root() },
      { path: Routes.Account.Root() },
      { path: Routes.Holdings.Root() },
      {
        path:
          investorType === 'IndividualInvestor'
            ? Routes.Onboarding.AmlVerification()
            : Routes.Account.Details(),
      },
      { path: Routes.Onboarding.Root() },
    ],
  });

  handleRedirects(urls, unauthenticated) {
    if (this.checkIfUserShouldBeRedirected(urls)) {
      if (unauthenticated) {
        memorizePath();
      }
      return this.getRedirectURL(urls);
    }

    return null;
  }

  getDefaultAuthenticatedRoute = () =>
    this.isPlacementEnabled ? Routes.Deals() : Routes.Holdings.Root();

  preventRootOpening = unauthenticated => {
    if (unauthenticated) {
      return Routes.Login();
    }
    return this.getDefaultAuthenticatedRoute();
  };

  get to() {
    const isOauth2Login = useFeatureFlag(FeatureFlag.OAUTH2_LOGIN_ENABLE);

    const unauthenticated = JSON.stringify(this.user) === '{}';
    const rootOpened = checkPathSuitability({ path: Routes.Root(), exact: true }, this.browserPath);

    if (rootOpened) {
      return this.preventRootOpening(unauthenticated && !isOauth2Login);
    }

    if (unauthenticated) {
      // Business as usual for non-oauth2
      // For oauth2, the user will be unauthenticated for a brief moment
      // and oauth-proxy handles unauthenticated redirects anyway
      if (!isOauth2Login) {
        return this.handleRedirects(this.getUnauthenticatedUrls(this.user), true);
      }

      return null;
    }

    return this.handleRedirects(this.getUrlByOnboardingState(this.user), false);
  }
}
