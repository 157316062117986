import styled from 'styled-components';

import PencilIcon from './pencil-icon';

const Circle = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  ${({ diameter = '21px' }) => `width:${diameter};height:${diameter};`};
  ${({ circleColor }) => (circleColor ? `background: ${circleColor};` : '')};
  border-radius: 50%;
  cursor: pointer;
`;

const CirclePencil = ({ circleColor, circleDiameter, iconWidth, iconColor, onClick }) => (
  <Circle circleColor={circleColor} diameter={circleDiameter} onClick={onClick}>
    <PencilIcon width={iconWidth} color={iconColor} />
  </Circle>
);

export default CirclePencil;
