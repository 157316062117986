import * as Colors from 'shared-parts/constants/colors';

const ArrowBack = ({ width = 43, height = 31, color = Colors.primary }) => (
  <svg xmlns="http://www.w3.org/2000/svg" width={width} height={height} viewBox="0 0 43 31">
    <g fill="none" fillRule="evenodd">
      <path
        fill={color}
        fillRule="nonzero"
        d="M17.181 1.236a4.097 4.097 0 0 1 0 5.768l-3.817 3.974h25.53a4.107 4.107 0 1 1 0 8.214H13.508l3.672 3.827a4.243 4.243 0 0 1 0 5.889 3.85 3.85 0 0 1-5.653 0L1.132 18.076a5.37 5.37 0 0 1-1.131-3.059 4.172 4.172 0 0 1 1.13-2.943L11.64 1.236A3.897 3.897 0 0 1 14.455 0a3.63 3.63 0 0 1 2.727 1.236z"
      />
    </g>
  </svg>
);

export default ArrowBack;
