import {
  QueryKey,
  useInfiniteQuery,
  UseInfiniteQueryOptions,
  useQuery,
  UseQueryOptions,
} from '@tanstack/react-query';

import { RequestDocument, Variables } from 'graphql-request';

import { useGraphQLClient } from './clients/providers/graphql-client';
import { useGraphQLExport } from './use-graphql-export';

export { gql } from 'graphql-request';

export type GraphQLFilter = {
  [fieldKey: string]: unknown;
};

export const useGraphQL = <TData = any, TMapped = TData>(
  queryKey: NonNullable<QueryKey>,
  document: RequestDocument,
  variables?: Variables,
  opts?: UseQueryOptions<TData, unknown, TMapped>,
) => {
  const graphQLClient = useGraphQLClient();

  return useQuery<TData, unknown, TMapped>({
    queryKey,
    queryFn: ({ signal }) => {
      return graphQLClient.request({ document, variables, signal });
    },
    ...opts,
  });
};

export type GraphQLQueryResult<
  TKey extends string = string,
  TNode extends Record<string, any> = any,
> = {
  [key in TKey]: {
    nodes: TNode[];
    totalCount?: number;
    pageInfo?: {
      offset?: number;
      hasNextPage?: boolean;
    };
  };
};

export type GraphQLQueryResultNode<TResult> =
  TResult extends GraphQLQueryResult<string, infer TNode> ? TNode : unknown;

type UseGraphQLListOptions<TResult, TNode, TMapped = TNode> = Omit<
  UseQueryOptions<TResult, unknown, TMapped[]>,
  'select'
> & {
  select?: (node: TNode) => TMapped;
};

export const useGraphQLList = <
  TNode extends Record<string, any>,
  TMapped extends Record<string, any> = TNode,
  TResult extends GraphQLQueryResult<string, TNode> = GraphQLQueryResult<string, TNode>,
>(
  queryKey: QueryKey,
  document: RequestDocument,
  variables: Variables & { limit: number; offset: number },
  opts?: UseGraphQLListOptions<TResult, TNode, TMapped>,
) => {
  const graphQLClient = useGraphQLClient();
  const exporter = useGraphQLExport(document, variables);

  let _totalCount = 0;

  const result = useQuery<TResult, unknown, TMapped[]>({
    queryKey,
    queryFn: async () => {
      return graphQLClient.request(document, {
        ...variables,
        offset: variables.offset,
        limit: variables.limit,
      });
    },
    ...opts,
    select: data => {
      const keys = Object.keys(data);

      if (!keys.length) {
        throw new Error('No queries were found on the result.');
      }

      if (keys.length > 1) {
        throw new Error('Multiple queries were found on the result. Only one query is supported.');
      }

      const queryResult = data[keys[0]];

      const newTotalCount = queryResult.totalCount;

      if (newTotalCount == null) {
        throw new Error(
          'Total count was not provided. Ensure totalCount is present on your query.',
        );
      }

      if (newTotalCount && newTotalCount > 0 && _totalCount !== newTotalCount) {
        _totalCount = newTotalCount;
      }

      if (opts?.select) {
        const { select } = opts;
        return queryResult.nodes.map(node => select(node));
      }

      // Not the safest assumption to make but it'll do for now
      // TODO: Tighten up the types to not require this or possibly infer?
      return queryResult.nodes as unknown as TMapped[];
    },
  });

  return {
    ...result,
    totalCount: _totalCount,
    exporter,
  };
};

type UseGraphQLInfiniteOptions<TQueryResult, TData> = Omit<
  UseInfiniteQueryOptions<TQueryResult, unknown, TData>,
  'select'
> & {
  select?: (nodes: GraphQLQueryResultNode<TQueryResult>) => TData;
};

export const useGraphQLInfinite = <
  TQueryResult extends GraphQLQueryResult = GraphQLQueryResult,
  TData = GraphQLQueryResultNode<TQueryResult>,
>(
  queryKey: NonNullable<UseInfiniteQueryOptions<TQueryResult>['queryKey']>,
  document: RequestDocument,
  variables: Variables & { limit: number },
  opts?: UseGraphQLInfiniteOptions<TQueryResult, TData>,
) => {
  const graphQLClient = useGraphQLClient();
  const exporter = useGraphQLExport(document, variables);

  let _totalCount = 0;

  const result = useInfiniteQuery<TQueryResult, unknown, TData>({
    ...opts,
    queryKey,
    queryFn: async ({ pageParam = 1 }) => {
      // TODO: Allow overrides to limit key?
      return graphQLClient.request(document, {
        ...variables,
        offset: pageParam,
        limit: variables.limit,
      });
    },
    getNextPageParam: lastPage => {
      if (!lastPage) {
        return undefined;
      }

      const keys = Object.keys(lastPage);

      if (keys.length !== 1) {
        console.error('Multiple queries were provided to the hook. Only one query is supported.');

        return undefined;
      }

      const { totalCount, pageInfo } = lastPage[keys[0]];

      if (pageInfo?.offset == null) {
        console.error('No offset was retrieved from the query.');

        return undefined;
      }

      if (totalCount == null) {
        console.error('Total count was missing from the graphql result.');

        return undefined;
      }

      if (pageInfo.hasNextPage != null) {
        return pageInfo.hasNextPage ? pageInfo.offset + 1 : undefined;
      }

      return pageInfo.offset * variables.limit < totalCount ? pageInfo.offset + 1 : undefined;
    },
    select: data => {
      // A touch weird but we lose our totalCount context by doing this flat map
      // So we just sorta sneak it out to a state. Might cause double rendering
      // but I guess we'll worry about that if it becomes a problem
      if (data.pages.length > 0) {
        const initialKey = Object.keys(data.pages[0])[0];
        const newTotalCount = data.pages[0][initialKey].totalCount;

        if (newTotalCount && newTotalCount > 0 && _totalCount !== newTotalCount) {
          _totalCount = newTotalCount;
        }
      }

      return {
        pages: data.pages.flatMap(page => {
          const key = Object.keys(page)[0];
          const { nodes } = page[key];

          if (opts?.select) {
            return nodes.map(node => opts.select?.(node));
          }

          return nodes;
        }),
        pageParams: data.pageParams,
      };
    },
  });

  return { ...result, data: result.data?.pages, totalCount: _totalCount, exporter };
};
