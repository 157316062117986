import { FC } from 'react';

import { CAPITAL_FIELDS } from 'app-state/indication-of-interest/enums';

import { VALUATION_FIELD_LABEL } from 'shared/indication-of-interest/constants';
import {
  AmountContainer,
  AmountInput,
  AmountLabel,
  CurrencyInput,
  CurrencySign,
} from 'shared/indication-of-interest/styled';
import { formatCurrencySign } from 'shared-parts/helpers';

interface ValuationFieldProps {
  fieldPrefix?: string;
  currency?: string;
}

const ValuationField: FC<React.PropsWithChildren<ValuationFieldProps>> = ({
  fieldPrefix,
  currency,
}) => (
  <AmountContainer>
    <AmountLabel htmlFor={`${fieldPrefix}.${CAPITAL_FIELDS.VALUATION}`}>
      {VALUATION_FIELD_LABEL}
    </AmountLabel>
    <CurrencyInput>
      <CurrencySign>{formatCurrencySign(currency)}</CurrencySign>
      <AmountInput name={`${fieldPrefix}${CAPITAL_FIELDS.VALUATION}`} isAmount dpAmount={2} />
    </CurrencyInput>
  </AmountContainer>
);

export default ValuationField;
