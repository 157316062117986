import styled from 'styled-components';

import { alternative, neutral200, neutral500, primary } from 'shared-parts/constants/colors';
import { mainFont } from 'shared-parts/constants/fonts';
import CheckboxIconWhite from 'shared-parts/images/checkbox-icon-white.svg';

const CheckboxWrapper = styled.div`
  margin-bottom: 28px;
`;

const CheckboxInput = styled.input`
  position: absolute;
  opacity: 0;
  height: 0;
  width: 0;
  &:checked ~ span {
    background: ${({ baseColor }) => baseColor || `${alternative}`};
    border-color: ${primary};
    &:before {
      background: url(${CheckboxIconWhite}) no-repeat;
      color: ${neutral200};
      content: '';
      display: inline-block;
      height: 11px;
      left: 3px;
      position: absolute;
      top: 1px;
      width: 11px;
    }
  }
`;

const CheckboxLabel = styled.label`
  color: ${({ labelColor }) => labelColor};
  font-family: ${mainFont};
  font-weight: ${props => (props.thinText ? 100 : 600)};
  font-size: ${({ fontSize }) => fontSize || '16px'};
  position: relative;
  ${({ disabled }) =>
    disabled
      ? `
    cursor: default;
    color: ${neutral500}`
      : 'cursor: pointer'};
  margin-bottom: 0;
  padding-left: 27px;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;

  &:last-child {
    margin-bottom: 0;
  }
`;

const Icon = styled.span`
  border: 2px solid ${neutral500};
  display: inline-block;
  position: absolute;
  top: 2px;
  left: 0;
  height: 18px;
  width: 18px;
`;

export { CheckboxWrapper, CheckboxInput, CheckboxLabel, Icon };
