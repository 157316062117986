import {
  formLabelClasses,
  stepConnectorClasses,
  stepIconClasses,
  stepLabelClasses,
} from '@mui/material';
import type { Shadows, ThemeOptions } from '@mui/material/styles';
import { createTheme } from '@mui/material/styles';
import type {} from '@mui/x-data-grid-premium/themeAugmentation';

/* TODO:
  - Default line height
  - Table label
  - Shadows
  - Is body2 the default?
*/

const {
  shadows: defaultShadows,
  typography: { pxToRem },
} = createTheme({});

const shadows = defaultShadows.slice() as Shadows;
shadows[1] = '0px 2px 4px 0px rgba(96, 97, 112, 0.16), 0px 0px 1px 0px rgba(40, 41, 61, 0.04)';
shadows[2] = '0px 2px 10px 0px rgba(96, 97, 112, 0.20)';
shadows[3] = '0px 20px 32px 0px rgba(96, 97, 112, 0.24), 0px 2px 8px 0px rgba(40, 41, 61, 0.08)';

// Used for text. Should be in its own theme and combine but quick fix
const neutral700 = '#404040';
const neutral600 = '#595959';
const neutral500 = '#737373';
const neutral400 = '#8C8C8C';

/**
 * Core theme properties. They are raw, which means they haven't been "augmented" by createTheme.
 */
const defaultThemeOptions: ThemeOptions = {
  palette: {
    primary: {
      main: '#002656',
      dark: '#001836',
      light: '#DFEBFF',
    },
    secondary: {
      main: '#D42369',
      dark: '#BE1F5E',
      light: '# ',
      contrastText: '#FFF',
    },
    highlight: {
      main: '#0286F3',
      dark: '#0270CA',
      light: '#E6F4FF',
      contrastText: '#FFF',
    },
    neutral: {
      '900': '#0C0C0C',
      '800': '#333333',
      '700': neutral700,
      '600': neutral600,
      '500': neutral500,
      '400': neutral400,
      '300': '#AFAFAF',
      '200': '#CCCCCC',
      '100': '#E1E1E1',
      '50': '#F0F0F0',
      '25': '#FAFAFA',
      '0': '#FFFFFF',
    },
    info: {
      dark: '#0270CA',
      main: '#0286F3',
      light: '#E6F3FF',
      contrastText: '#fff',
    },
    success: {
      dark: '#277C33',
      main: '#30973E',
      light: '#E8F8EA',
      contrastText: '#fff',
    },
    warning: {
      dark: '#B26200',
      main: '#E57E00',
      light: '#FFF1E0',
    },
    error: {
      dark: '#BF0000',
      main: '#CC0000',
      light: '#FFE0E0',
      contrastText: '#fff',
    },
    brown: {
      dark: '#4D3B03',
      main: '#614900',
      light: '#E4E2D9 ',
      contrastText: '#fff',
    },
    orange: {
      dark: '#9F5047',
      main: '#D46A5E',
      light: '#FDF1F0',
      contrastText: '#fff',
    },
    yellow: {
      dark: '#9D7800',
      main: '#D1A000',
      light: '#F8F2DC',
      contrastText: '#fff',
    },
    green: {
      dark: '#004A34',
      main: '#006345',
      light: '#D9ECE6',
      contrastText: '#fff',
    },
    blue: {
      dark: '#005074',
      main: '#006A9A',
      light: '#D9E9F0',
      contrastText: '#fff',
    },
    darkblue: {
      dark: '#293860',
      main: '#364B80',
      light: '#E1E4EC',
      contrastText: '#fff',
    },
    purple: {
      dark: '#4B3353',
      main: '#64446E',
      light: '#F1E9F3',
      contrastText: '#fff',
    },
    red: {
      dark: '#75324C',
      main: '#9C4265',
      light: '#F4E4EA',
      contrastText: '#fff',
    },
    text: {
      primary: neutral600,
      secondary: neutral500,
    },

    // // TODO: Check this, saves a lot of overriding
    // action: {
    //   disabled: '#AFAFAF'
    // }
  },
  shadows,
  typography: {
    fontFamily: ['Nunito Sans', 'sans-serif'].join(','),
    fontSize: 14,
    fontWeightRegular: 400,
    fontWeightMedium: 600,
    fontWeightBold: 700,

    h1: {
      fontWeight: 700,
      fontSize: pxToRem(32),
      lineHeight: pxToRem(40),
      letterSpacing: -0.5,
      color: neutral700,
    },
    h2: {
      fontWeight: 700,
      fontSize: pxToRem(24),
      lineHeight: pxToRem(32),
      letterSpacing: -0.5,
      color: neutral700,
    },
    h3: {
      fontWeight: 700,
      fontSize: pxToRem(20),
      lineHeight: pxToRem(28),
      color: neutral700,
    },
    h4: {
      fontWeight: 700,
      fontSize: pxToRem(16),
      lineHeight: pxToRem(24),
      color: neutral700,
    },
    h5: {
      fontWeight: 700,
      fontSize: pxToRem(14),
      lineHeight: pxToRem(20),
      color: neutral700,
    },
    h6: undefined,
    subtitle1: {
      fontWeight: 700,
      fontSize: pxToRem(16),
      lineHeight: pxToRem(24),
      color: neutral500,
    },
    subtitle2: {
      fontWeight: 700,
      fontSize: pxToRem(14),
      lineHeight: pxToRem(20),
      color: neutral400,
    },
    bodyAllCaps: {
      fontWeight: 700,
      fontSize: pxToRem(14),
      lineHeight: pxToRem(20),
      color: neutral400,
      textTransform: 'uppercase',
    },
    body1: {
      fontWeight: 400,
      fontSize: pxToRem(14),
      lineHeight: pxToRem(20),
      color: neutral600,
    },
    body2: {
      fontWeight: 400,
      fontSize: pxToRem(14),
      lineHeight: pxToRem(20),
      color: neutral600,
    },
    bodyHighlight: {
      fontWeight: 700,
      fontSize: pxToRem(14),
      lineHeight: pxToRem(20),
      color: neutral600,
    },
    caption: {
      fontWeight: 400,
      fontSize: pxToRem(12),
      lineHeight: pxToRem(16),
      color: neutral600,
    },
    button: {
      fontWeight: 700,
      fontSize: pxToRem(14),
      lineHeight: pxToRem(20),
      color: neutral600,
    },
    label: {
      fontWeight: 700,
      fontSize: pxToRem(14),
      lineHeight: pxToRem(20),
      color: neutral700,
    },
    tableBody: {
      fontWeight: 400,
      fontSize: pxToRem(14),
      lineHeight: pxToRem(20),
      color: neutral600,
    },
  },
  spacing: 4,
  shape: {
    borderRadius: 8,
  },
  components: {
    MuiTypography: {
      defaultProps: {
        variantMapping: {
          bodyAllCaps: 'p',
          bodyHighlight: 'span',
          label: 'span',
        },
      },
    },
    MuiAlert: {
      defaultProps: {
        severity: 'success',
      },
      styleOverrides: {
        root: ({ theme, ownerState }) => ({
          fontWeight: 400,
          lineHeight: '20px',
          color: theme.palette.neutral[500],

          backgroundColor:
            theme.palette[ownerState.color || ownerState.severity || 'success'].light,

          padding: '10px',
          '&:has(.MuiAlertTitle-root)': {
            padding: '16px',
          },

          '&:has(.MuiAlert-action)': {
            alignItems: 'center',
          },

          '& .MuiAlert-icon': {
            color: theme.palette[ownerState.color || ownerState.severity || 'success'].dark,
          },
        }),
        icon: {
          marginRight: '8px',
          padding: 0,

          svg: {
            height: '20px',
          },
        },
        message: {
          padding: 0,
        },
      },
    },
    MuiAlertTitle: {
      styleOverrides: {
        root: ({ theme }) => ({
          lineHeight: '24px',
          fontWeight: theme.typography.fontWeightMedium,
          color: theme.palette.neutral[500],
          marginBottom: '5px',
        }),
      },
    },
    MuiAutocomplete: {
      styleOverrides: {
        root: {
          width: '350px',
        },
        option: ({ theme }) => ({
          borderRadius: '6px',
          '&:hover': {
            backgroundColor: theme.palette.neutral[50],
          },
          margin: '8px 0',
          gap: '10px',
        }),
        listbox: ({ theme }) => ({
          padding: theme.spacing(0, 2),

          // Use this instead of the slot as for some reason the slot doesn't have priority
          '& .MuiAutocomplete-option': {
            padding: '12px',
          },
        }),
      },
    },
    MuiBadge: {
      defaultProps: {
        color: 'info',
      },
      styleOverrides: {
        badge: ({ theme, ownerState }) => {
          const color =
            !ownerState.color || ownerState.color === 'default' ? 'info' : ownerState.color;

          return {
            backgroundColor: theme.palette[color].main,
            color: theme.palette[color].light,
          };
        },
      },
    },
    MuiButtonBase: {
      defaultProps: {
        disableRipple: true,
        disableTouchRipple: true,
      },
    },
    MuiButton: {
      defaultProps: {
        color: 'secondary',
        disableFocusRipple: true,
        disableElevation: true,
      },
      // if I'm an <a>, don't use link behaviour on hover
      styleOverrides: {
        contained: ({ theme }) => ({
          '&:hover': {
            color: theme.palette.primary.contrastText,
          },
        }),
      },
    },
    MuiCardMedia: {
      styleOverrides: {
        root: ({ theme }) => ({
          padding: theme.spacing(1),
          borderRadius: theme.shape.borderRadius,
        }),
      },
    },
    MuiCardHeader: {
      styleOverrides: {
        root: ({ theme }) => ({
          padding: theme.spacing(7, 8),
          paddingBottom: theme.spacing(0),
        }),
      },
      defaultProps: {
        titleTypographyProps: {
          variant: 'h3',
        },
        subheaderTypographyProps: {
          variant: 'subtitle2',
        },
      },
    },
    MuiCardContent: {
      styleOverrides: {
        root: ({ theme }) => ({
          padding: theme.spacing(7, 8),
        }),
      },
    },
    MuiCardActions: {
      styleOverrides: {
        root: ({ theme }) => ({
          padding: theme.spacing(7, 8),
          paddingTop: theme.spacing(0),
          justifyContent: 'flex-end',
        }),
        spacing: ({ theme }) => ({
          '& >:not(:first-of-type)': {
            marginLeft: theme.spacing(4),
          },
        }),
      },
    },
    MuiDialog: {
      defaultProps: {
        maxWidth: 'sm',
        fullWidth: true,
      },
    },
    MuiDialogTitle: {
      defaultProps: {
        variant: 'h3',
      },
    },
    MuiIconButton: {
      defaultProps: {
        color: 'secondary',
        size: 'small',
        disableFocusRipple: true,
      },
    },
    MuiCheckbox: {
      defaultProps: {
        disableRipple: true,
        disableFocusRipple: true,
        disableTouchRipple: true,
      },
    },
    MuiChip: {
      defaultProps: {
        size: 'small',
      },
      styleOverrides: {
        root: ({ theme }) => ({
          borderRadius: theme.spacing(1),
          color: theme.palette.neutral[700],
          gap: theme.spacing(2),
        }),
        label: ({ theme }) => ({
          padding: 0,
          ...theme.typography.bodyHighlight,
        }),
        sizeSmall: ({ theme }) => ({
          height: '28px',
          minWidth: '72px',
          padding: theme.spacing(1, 3),
        }),
        sizeMedium: ({ theme }) => ({
          height: '36px',
          minWidth: '80px',
          padding: theme.spacing(2, 4),
        }),
        deleteIcon: {
          color: 'currentColor',
          fontSize: 'inherit',
          margin: 0,
        },
      },
    },
    MuiRadio: {
      defaultProps: {
        disableRipple: true,
      },
    },
    MuiLink: {
      defaultProps: {
        color: 'info.dark',
        variant: 'body2',
        underline: 'none',
      },
    },
    MuiSwitch: {
      defaultProps: {
        disableRipple: true,
        disableFocusRipple: true,
        disableTouchRipple: true,
      },
    },
    MuiDataGrid: {
      styleOverrides: {
        columnHeader: ({ theme }) => ({
          ...theme.typography.label,
        }),
        root: ({ theme }) => ({
          ...theme.typography.tableBody,
        }),
      },
      defaultProps: {
        showColumnVerticalBorder: true,
        autosizeOptions: {
          expand: true,
          includeHeaders: true,
        },

        // Require some of the fancier features to be explicitly enabled
        disableColumnFilter: true,
        disableAggregation: true,
        disableRowSelectionOnClick: true,

        // Set everything to server mode
        filterMode: 'server',
        paginationMode: 'server',
        sortingMode: 'server',
        filterDebounceMs: 1000,
      },
    },
    MuiIcon: {
      styleOverrides: {
        root: ({ ownerState }) => ({
          ...(ownerState.fontSize === 'small' && {
            fontSize: '16px',
          }),
        }),
      },
    },
    MuiList: {
      styleOverrides: {
        root: {
          minWidth: '180px',

          // This is for columns menus for datagrid!
          '&.MuiDataGrid-menuList': {
            // This is as column menus on datagrid have collapsing margins
            // even though no other instances do.. Not sure why
            display: 'inline-block',
          },
        },
        padding: ({ theme }) => ({
          padding: theme.spacing(0, 2),
        }),
      },
    },
    MuiListItemIcon: {
      styleOverrides: {
        root: ({ theme }) => ({
          padding: theme.spacing(0, 2),
          minWidth: 0,

          '& .MuiSvgIcon-fontSizeSmall': {
            fontSize: '20px',
          },
        }),
      },
    },
    MuiListItemText: {
      styleOverrides: {
        // TODO: Use proper typography? primaryTypographyProps?
        primary: ({ theme }) => ({
          color: theme.palette.neutral[600],
          fontWeight: theme.typography.fontWeightMedium,
        }),
      },
    },
    MuiMenu: {
      defaultProps: {
        slotProps: {
          paper: {
            elevation: 1,
          },
        },
      },
      styleOverrides: {
        paper: {
          color: neutral600,
        },
      },
    },
    MuiMenuItem: {
      styleOverrides: {
        root: ({ theme }) => ({
          borderRadius: '6px',
          '&:hover': {
            backgroundColor: theme.palette.neutral[50],
          },
          margin: '8px 0',
          padding: '12px',
          gap: '10px',
        }),
      },
    },
    MuiFormLabel: {
      styleOverrides: {
        root: ({ theme }) => ({
          ...theme.typography.label,

          [`&.${formLabelClasses.disabled}`]: {
            ...theme.typography.label,
          },
        }),
      },
    },

    MuiInputAdornment: {
      styleOverrides: {
        root: ({ theme }) => ({
          gap: '6px',

          // Target itself and any typography
          '&, & .MuiTypography-root': {
            ...theme.typography.label,
            color: theme.palette.neutral[400],
          },
        }),
        positionStart: {
          marginRight: 0,
        },
        positionEnd: {
          marginLeft: 0,
        },
      },
    },

    MuiInputBase: {
      styleOverrides: {
        root: ({ theme, ownerState }) => ({
          minHeight: '40px',
          color: theme.palette.neutral[600],
          fontWeight: theme.typography.fontWeightMedium,

          width: ownerState?.fullWidth ? '100%' : '222px',

          paddingLeft: '4px',
          paddingRight: '4px',
        }),

        sizeSmall: {
          width: '120px',
        },

        input: ({ theme }) => ({
          '&.Mui-disabled': {
            color: theme.palette.neutral[500],
            WebkitTextFillColor: theme.palette.neutral[500],
          },
        }),
      },
    },

    MuiOutlinedInput: {
      styleOverrides: {
        root: ({ theme }) => ({
          borderRadius: '6px',

          '&.Mui-disabled': {
            background: theme.palette.neutral[50],
          },

          // Outlines for different circumstances
          // We do it here instead the slot for css specificity
          '& .MuiOutlinedInput-notchedOutline': {
            borderWidth: '1.5px',
            borderColor: theme.palette.neutral[300],
          },

          '&:hover': {
            '& .MuiOutlinedInput-notchedOutline': {
              borderColor: theme.palette.neutral[300],
            },
          },

          '&.Mui-focused': {
            '& .MuiOutlinedInput-notchedOutline': {
              // MUI tries to set this to 2px so we revert back
              // https://github.com/mui/material-ui/blob/b62d5757e6dc218c57cf6db77dea78c7b2396a5a/packages/mui-material/src/OutlinedInput/OutlinedInput.js#L58
              borderWidth: '1.5px',
              borderColor: theme.palette.highlight.main,
            },
          },

          '&.Mui-error': {
            '& .MuiOutlinedInput-notchedOutline': {
              borderColor: theme.palette.error.main,
            },
          },
        }),

        input: {
          padding: '4px 8px 4px 8px',
        },

        adornedStart: {
          paddingLeft: '12px',
        },
        adornedEnd: {
          paddingRight: '12px',
        },
      },
    },

    MuiInput: {
      styleOverrides: {
        underline: ({ theme }) => ({
          '&::before, &:hover:not(.Mui-disabled, .Mui-error)::before': {
            borderColor: theme.palette.neutral[400],
          },
          '&::after': {
            borderColor: theme.palette.info.dark,
          },
        }),
      },
    },
    MuiPopover: {
      defaultProps: {
        elevation: 2,
      },
    },
    MuiStep: {
      styleOverrides: {
        root: ({ ownerState }) => ({
          // For active and completed steps, make the connectors stand out more
          ...((ownerState.active || ownerState.completed) && {
            [`&+.${stepConnectorClasses.root}`]: {
              [`& .${stepConnectorClasses.lineVertical}`]: {
                height: '52px',
              },
              [`& .${stepConnectorClasses.line}`]: {
                borderColor: theme.palette.neutral[400],
              },
            },
          }),
        }),
      },
    },
    MuiStepConnector: {
      styleOverrides: {
        lineVertical: {
          height: '32px',
        },
        line: ({ theme }) => ({
          borderLeftWidth: '2px',
          borderColor: theme.palette.neutral[200],
        }),
      },
    },
    MuiStepIcon: {
      styleOverrides: {
        root: ({ theme }) => ({
          overflow: 'visible',
          fill: 'transparent',
          stroke: theme.palette.neutral[200],

          '& > circle': {
            strokeWidth: '1.4px',
          },

          [`&.${stepIconClasses.active}`]: {
            stroke: theme.palette.warning.main,
          },

          [`&.${stepIconClasses.completed}`]: {
            stroke: theme.palette.success.main,
            fill: theme.palette.success.main,
          },

          [`&.${stepIconClasses.error}`]: {
            stroke: theme.palette.error.main,
          },
        }),
      },
    },
    MuiStepLabel: {
      styleOverrides: {
        root: ({ theme }) => ({
          padding: theme.spacing(1, 0),
        }),
        label: ({ theme }) => ({
          ...theme.typography.body1,
          color: theme.palette.neutral[500],

          [`&.${stepLabelClasses.active}, &.${stepLabelClasses.completed}`]: {
            ...theme.typography.h4,
            color: theme.palette.neutral[600],
          },
        }),
      },
    },
    MuiStepButton: {
      styleOverrides: {
        root: {
          textAlign: 'start',
        },
      },
    },

    MuiTab: {
      styleOverrides: {
        root: ({ theme }) => ({
          margin: '.2em',
          minHeight: '24px',
          padding: '4px 12px',

          ...theme.typography.subtitle2,
          textTransform: 'none',

          '&.Mui-selected': {
            color: theme.palette.neutral[600],
            backgroundColor: theme.palette.neutral[25],
            borderRadius: '6px',
          },
        }),
      },
    },
    MuiTabs: {
      styleOverrides: {
        root: ({ theme }) => ({
          minHeight: 0,
          backgroundColor: theme.palette.neutral[50],
          maxWidth: 'max-content',
          borderRadius: '6px',
        }),
        indicator: {
          display: 'none',
        },
      },
    },
  },
};

/**
 * Core full theme
 */
const theme = createTheme(defaultThemeOptions);

export { theme, defaultThemeOptions };

export type SystemColor =
  | 'brown'
  | 'orange'
  | 'yellow'
  | 'green'
  | 'blue'
  | 'darkblue'
  | 'purple'
  | 'red';
