export const FETCH_ORDERS = 'FETCH_ORDERS';
export const FETCH_ORDERS_SUCCESS = 'FETCH_ORDERS_SUCCESS';
export const FETCH_ORDERS_ERROR = 'FETCH_ORDERS_ERROR';
export const RESET_ORDERS = 'RESET_ORDERS';

export const FETCH_ORDERS_PAGE_DATA = 'FETCH_ORDERS_PAGE_DATA';
export const FETCH_ORDERS_PAGE_DATA_SUCCESS = 'FETCH_ORDERS_PAGE_DATA_SUCCESS';
export const FETCH_ORDERS_PAGE_DATA_ERROR = 'FETCH_ORDERS_PAGE_DATA_ERROR';

export const CHANGE_ORDERS_FILTER_PARAM = 'CHANGE_ORDERS_FILTER_PARAM';

export const ADD_ORDER_REQUEST = 'ADD_ORDER_REQUEST';
export const ADD_ORDER_SUCCESS = 'ADD_ORDER_SUCCESS';
export const ADD_ORDER_ERROR = 'ADD_ORDER_ERROR';
export const RESET_ORDER_DATA = 'RESET_ORDER_DATA';

export const CANCEL_ORDER = 'CANCEL_ORDER';
export const UPDATE_ORDER = 'UPDATE_ORDER';

export const GET_BANK_ACCOUNT = 'GET_BANK_ACCOUNT';
export const GET_BANK_ACCOUNT_SUCCESS = 'GET_BANK_ACCOUNT_SUCCESS';
export const GET_BANK_ACCOUNT_ERROR = 'GET_BANK_ACCOUNT_ERROR';
