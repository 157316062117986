import * as constants from 'app-state/indication-of-interest/constants';
import type {
  IndicationOfInterestActionTypes,
  IndicationOfInterestItem,
  InvestorAnchorStatusState,
} from 'app-state/indication-of-interest/types';
import type { BaseState } from 'app-state/types';

export const initialState: BaseState = {
  loading: false,
  errors: null,
};

export const indicationOfInterestReducer = (
  state = initialState,
  action: IndicationOfInterestActionTypes,
): BaseState => {
  switch (action.type) {
    case constants.PLACE_INDICATION_OF_INTEREST:
    case constants.ADJUST_INDICATION_OF_INTEREST:
    case constants.CANCEL_INDICATION_OF_INTEREST:
      return { ...state, loading: true };
    case constants.PLACE_INDICATION_OF_INTEREST_ERROR:
    case constants.ADJUST_INDICATION_OF_INTEREST_ERROR:
    case constants.CANCEL_INDICATION_OF_INTEREST_ERROR:
      return { errors: action.payload, loading: false };
    case constants.PLACE_INDICATION_OF_INTEREST_SUCCESS:
    case constants.ADJUST_INDICATION_OF_INTEREST_SUCCESS:
    case constants.CANCEL_INDICATION_OF_INTEREST_SUCCESS:
      return initialState;
    default:
      return state;
  }
};

export const initialInvestorAnchorStatusState: InvestorAnchorStatusState = {
  isAnchor: false,
  loading: true,
};

export const investorAnchorStatusReducer = (
  state = initialInvestorAnchorStatusState,
  action: IndicationOfInterestActionTypes,
): InvestorAnchorStatusState => {
  switch (action.type) {
    case constants.GET_INVESTOR_ANCHOR_STATUS_SUCCESS:
      return { isAnchor: action.payload.isAnchor, loading: false };
    case constants.GET_INVESTOR_ANCHOR_STATUS_ERROR:
      return { ...state, loading: false };
    case constants.GET_INVESTOR_ANCHOR_STATUS:
    case constants.RESET_INVESTOR_ANCHOR_STATUS:
      return initialInvestorAnchorStatusState;
    default:
      return state;
  }
};

export const initialItemState: BaseState & { data: IndicationOfInterestItem | null } = {
  loading: false,
  errors: null,
  data: null,
};

export const indicationOfInterestItemReducer = (
  state = initialItemState,
  action: IndicationOfInterestActionTypes,
): BaseState & { data: IndicationOfInterestItem | null } => {
  switch (action.type) {
    case constants.GET_INDICATION_OF_INTEREST:
      return { ...state, loading: true, errors: null };
    case constants.GET_INDICATION_OF_INTEREST_SUCCESS:
      return { ...state, data: action.payload, loading: false };
    case constants.GET_INDICATION_OF_INTEREST_ERROR:
      return { ...state, errors: action.payload, loading: false };
    case constants.RESET_INDICATION_OF_INTEREST:
      return initialItemState;
    default:
      return state;
  }
};
