import { createSelector } from 'reselect';

import { SharePlan } from 'app-state/types/share-options.types';

export type ShareIncentivePlansProps = { data: []; errors: any; loading: boolean };
type StateProps = { shareIncentivePlans: ShareIncentivePlansProps };

export const getShareIncentivePlansState = (state: StateProps): ShareIncentivePlansProps =>
  state.shareIncentivePlans;

export const getShareIncentivePlansData = createSelector(
  getShareIncentivePlansState,
  state => state,
);

export type ShareIncentivePlanProps = { data: SharePlan; errors: any; loading: boolean };
type ShareIncentivePlanDetails = { shareIncentivePlan: ShareIncentivePlanProps };

export const getShareIncentivePlanState = (
  state: ShareIncentivePlanDetails,
): ShareIncentivePlanProps => state.shareIncentivePlan;

export const getShareIncentivePlanData = createSelector(getShareIncentivePlanState, state => state);
