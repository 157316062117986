import { Formik } from 'formik';
import { object, string } from 'yup';

import { prepareString } from '@frontend/utils';

import { AddressForm, AddressFormValues, User } from 'modules/account-details/components/types';

import renderAddressForm from './update-address-form';

const validationSchema = object({
  addressFirstLine: string().trim().required('Address is required'),
  line2: string().trim(),
  postCode: string().trim().required('Postal / Zip Code is required'),
  city: string().trim().required('City is required'),
  state: string().trim(),
});

const getInitialValues = ({ investor: { address } }: User): AddressFormValues => ({
  country: prepareString(address?.countryCode),
  addressFirstLine: prepareString(address.addressLine1).trim(),
  line2: prepareString(address?.addressLine2).trim(),
  postCode: prepareString(address.postCode).trim(),
  city: prepareString(address.city).trim(),
  state: prepareString(address.state).trim(),
});

const UpdateAddressForm = (props: AddressForm) => (
  <Formik
    enableReinitialize
    initialValues={getInitialValues(props.user)}
    validationSchema={validationSchema}
    onSubmit={props.handleSubmit}
  >
    {renderAddressForm(props)}
  </Formik>
);

export default UpdateAddressForm;
