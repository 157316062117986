import styled from 'styled-components';

import { Button } from 'shared-parts/components/button';
import {
  alternative,
  alternativeContrastText,
  neutral500,
  neutral800,
  primary,
  secondary,
  shadowColor,
} from 'shared-parts/constants/colors';

export const Wrapper = styled.div`
  border-bottom: 1px solid ${neutral500};
  border-top: 1px solid ${neutral500};
  margin: 45px auto;
  padding: 50px 20px;
  text-align: center;

  @media (min-width: 768px) {
    margin: 97.5px auto 100.5px;
    padding: 104.5px 0;
    width: 724px;
  }
`;

export const RestrictedMessage = styled.div`
  color: ${neutral800};
  font-size: 16px;
  line-height: 20px;
  margin-bottom: 28px;

  @media (min-width: 768px) {
    line-height: 16px;
  }
`;

export const RestrictedSignUpButton = styled(Button)`
  background: ${alternative};
  border-radius: 4px;
  color: ${alternativeContrastText};
  display: inline-block;
  font-size: 14px;
  font-weight: 700;
  line-height: 22px;
  margin-bottom: 5px;
  padding: 10px 0;
  width: 200px;

  &:hover {
    text-decoration: none;
    box-shadow: 0 3px 6px ${shadowColor};
  }
`;

export const LoginMessage = styled.p`
  color: ${primary};
  font-size: 14px;
  margin-bottom: 0;
`;

export const LoginLink = styled.span`
  color: ${secondary};
  font-weight: 600;
  text-decoration: underline;

  &:hover {
    cursor: pointer;
  }
`;
