import { call, takeEvery } from 'redux-saga/effects';

import * as constants from 'app-state/constants';

import API from 'constants/api';
import { request } from 'helpers';

function* sendActivityLogs({ params }) {
  try {
    yield call(request, API.Activity.logs(params.logsType), 'POST', params);
  } catch (e) {
    // eslint-disable-line
  }
}

function* sendActivityLogsWatcher() {
  yield takeEvery(constants.SEND_ACTIVITY_LOGS, sendActivityLogs);
}

export { sendActivityLogs, sendActivityLogsWatcher };
