import { Link } from 'react-router-dom';

import styled, { css } from 'styled-components';

import Progress from 'shared-parts/components/loader/progress';
import CircleArrow from 'shared-parts/components/svg-icons/circle-arrow';
import FilterListIcon from 'shared-parts/components/svg-icons/filter-list-icon';
import Tooltip from 'shared-parts/components/tooltip';
import * as Colors from 'shared-parts/constants/colors';
import * as Fonts from 'shared-parts/constants/fonts';

const TableWrapper = styled.div`
  border-radius: 6px;
  border: 1px solid;
  border-color: ${Colors.neutral300};
  overflow-x: auto;
  width: 100%;
  font-family: ${Fonts.mainFont};
  position: relative;
  font-size: 14px;
  overflow-y: auto;
  ${({ maxHeight }) => maxHeight && `max-height: ${maxHeight};`}
  ${({ isScrollbarVisible }) =>
    !isScrollbarVisible &&
    `
    scrollbar-width: none;
    
    ::-webkit-scrollbar {
      display: none;
    }
  `}
`;

const TableContainer = styled.table`
  border-collapse: collapse;
  border-color: inherit;
  width: 100%;
`;

const LinkButton = styled(Link)`
  background-color: transparent;
  border: none;
  color: ${Colors.neutral800};
  cursor: pointer;
  font-weight: 700;
  text-decoration: none;

  :hover {
    color: ${Colors.secondary};
  }

  ${({ disabled }) =>
    disabled
      ? `
    color: ${Colors.neutral500} !important;
    cursor: default;
  `
      : ''}
`;

const Button = styled.button`
  background-color: transparent;
  border: none;
  color: ${Colors.neutral800};
  cursor: pointer;
  font-weight: 700;

  ${({ disabled }) =>
    disabled
      ? `
    color: ${Colors.neutral500} !important;
    cursor: default;
  `
      : ''}
`;

const TableRow = styled.tr`
  border-bottom: 1px solid;
  border-color: inherit;
  height: 40px;

  ${({ header, expandedRow }) =>
    !header &&
    `${
      expandedRow
        ? `background-color: ${Colors.neutral200};`
        : `:hover {
        background-color: ${Colors.neutral200};
        box-shadow: 0 3px 6px 0 ${Colors.neutral500};
        ${LinkButton}, ${Button} {
          color: ${Colors.secondary};
          text-decoration: none;
        }
      }}
    `
    }
  `}

  td:first-child, th:first-child {
    padding-left: 20px;
    border-left: none;
  }

  ${({ clickable }) => clickable && 'cursor: pointer;'}

  ${({ rowStyles }) => rowStyles}
`;

const TableHeader = styled.thead`
  ${TableRow} {
    border-bottom: none;
  }
  font-weight: 700;
  color: ${Colors.white};
`;

const TableBody = styled.tbody`
  border-color: inherit;

  ${TableRow} {
    :last-child {
      border-bottom: 0;
    }
  }
`;

const TextWithLink = styled(Link)`
  color: initial;

  :hover {
    color: initial;
    text-decoration: none;
  }
`;

const cell = css`
  padding: 0 10px;
  :first-child {
    padding-left: 20px;
    border-left: none;
  }
  ${({ styles = '' }) => styles}
`;

const TableCell = styled.td`
  color: ${Colors.primary};
  border-left: 1px solid;
  border-color: inherit;
  ${cell}
`;

const TableHeaderCell = styled.th`
  text-align: center;
  height: 50px;
  background-color: ${Colors.accent};
  ${({ fixedHeader }) =>
    fixedHeader
      ? `
    position: sticky;
    z-index: 5;
    top: 0;
  `
      : ''}

  :first-child {
    padding-left: 20px;
    border-left: none;
  }
  ${cell}

  small {
    font-size: 12px;
  }
  ${({ title }) =>
    title
      ? `background-color: ${Colors.neutral200};
         color:${Colors.primary} ;   
         border-left: 0.5px solid #E1E1E2;
         border-right: 0.5px solid #E1E1E2;
         border-bottom: 0.5px solid #E1E1E2;
        }`
      : ''}
`;

const TableError = styled.td`
  padding: 10px 20px;
  font-weight: 600;
  color: ${Colors.white};
  background: ${Colors.error};
  position: relative;
`;

const CloseError = styled.span`
  position: absolute;
  right: 20px;
  cursor: pointer;
`;

const GroupRowStyles = `
  background-color: ${Colors.neutral200};
  border-color: ${Colors.white};
  border-bottom: 1px solid ${Colors.white};
  font-weight: 600;
`;

const SortingArrow = styled(CircleArrow)`
  width: 14px;
  height: 14px;
  position: relative;
  bottom: 2px;
  margin: 0 8px;
  ${({ rotateDown }) => rotateDown && 'transform: rotate(180deg);'}
  opacity: ${({ isSorted }) => (isSorted ? '1' : '0.5')};
`;

const SortingLines = styled(FilterListIcon)`
  margin: 0 8px;
  ${({ rotateDown }) => rotateDown && 'transform: rotate(180deg);'}
`;

const TableHeaderText = styled.span`
  ${({ onClick }) => onClick && 'cursor: pointer;'}

  :hover {
    ${SortingArrow} {
      opacity: 1;
    }
  }
`;

const ElementContainer = styled.div`
  height: 100%;

  p {
    margin: 0;
  }
`;

const totalRowStyles = `
  background-color: ${Colors.neutral200};
  font-weight: 700;

  :hover {
    background-color: ${Colors.neutral200};
  }
`;

const getEmptyRowStyles = retryAction => `
  ${retryAction ? '' : 'pointer-events: none;'}
  text-align: center;
  font-weight: 600;
  color: ${Colors.neutral800};
`;

const ProgressLine = styled(Progress)`
  ${({ fixedHeader }) => (fixedHeader ? 'position: relative;' : 'bottom: 0;')}
`;

const IntersectTableRow = styled.tr`
  height: 1px;
`;
const StyledTooltip = styled(Tooltip)`
  margin-left: 6px;
`;

const TooltipMessage = styled.div`
  width: 400px;
  text-align: left;
  border-radius: 6px;
  line-height: 1.5;
  padding: 10px;
  box-shadow: 0 3px 6px ${Colors.shadowColor};
  z-index: 100;
  background-color: ${Colors.alternative};
  color: ${Colors.white};
`;

const Title = styled.caption`
  background: ${Colors.accent};
  border-top-left-radius: 6px;
  border-top-right-radius: 6px;
  color: ${Colors.accentContrastText};
  font-family: ${Fonts.oswaldFont};
  font-size: 25px;
  font-weight: lighter;
  letter-spacing: 0.4px;
  line-height: 37px;
  padding: 12px 0 11px 20px;
  caption-side: unset;
`;

export {
  Title,
  TableWrapper,
  TableContainer,
  TableHeader,
  TableRow,
  TableBody,
  Button,
  LinkButton,
  TextWithLink,
  TableCell,
  TableHeaderCell,
  TableError,
  CloseError,
  GroupRowStyles,
  SortingArrow,
  SortingLines,
  ProgressLine,
  TableHeaderText,
  ElementContainer,
  IntersectTableRow,
  totalRowStyles,
  getEmptyRowStyles,
  StyledTooltip,
  TooltipMessage,
};
