import { forwardRef } from 'react';

import { styled, Typography, TypographyProps, useThemeProps } from '@mui/material';

export type FormDescriptionProps = TypographyProps;

export const FormDescriptionRoot = styled(Typography, {
  name: 'MuiFormDescription',
  slot: 'Root',
  overridesResolver: (_props, styles) => [styles.root],
})(({ theme }) => ({
  display: 'block',

  marginTop: '0px',
  marginBottom: theme.spacing(2),

  ...theme.typography.body1,
  color: theme.palette.neutral[500],

  whiteSpace: 'pre-wrap',
}));

export const FormDescription = forwardRef<HTMLSpanElement, FormDescriptionProps>((inProps, ref) => {
  const props = useThemeProps({ props: inProps, name: 'MuiFormDescription' });

  return (
    <FormDescriptionRoot
      ref={ref}
      className="MuiFormDescription-root"
      component="span"
      {...props}
    />
  );
});
