import { FC, SVGAttributes } from 'react';

import { neutral800, white } from 'shared-parts/constants/colors';

type IconProps = {
  width?: SVGAttributes<SVGElement>['width'];
  height?: SVGAttributes<SVGElement>['height'];
  centerColor?: string;
  baseColor?: string;
};

const InfoIcon: FC<React.PropsWithChildren<IconProps>> = ({
  centerColor,
  baseColor,
  width = 16,
  height = 16,
}) => (
  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 13 13" width={width} height={height}>
    <path
      id="Path_1937"
      data-name="Path 1937"
      d="M6.5,0A6.5,6.5,0,1,1,0,6.5,6.5,6.5,0,0,1,6.5,0Z"
      fill={baseColor ?? neutral800}
    />

    <path
      id="Path_1888"
      data-name="Path 1888"
      d="M.812-7.848H2.926V-3H.812Zm2.2-2.33v1.392H.742v-1.392Z"
      transform="translate(4.624 13.089)"
      fill={centerColor ?? white}
    />
  </svg>
);

export default InfoIcon;
