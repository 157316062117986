import { forwardRef, useContext } from 'react';

import {
  createSvgIcon,
  StepContext,
  StepContextType,
  StepLabel as MuiStepLabel,
  StepLabelProps as MuiStepLabelProps,
} from '@mui/material';

import { StepOptionalLabel } from './step-optional-label';

export type StepLabelProps = MuiStepLabelProps;

const NonLinearActiveStepIcon = createSvgIcon(
  <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
    <circle cx="12" cy="12" r="11" fill="white" stroke="currentColor" strokeWidth="2" />
    <circle cx="12" cy="12" r="6.85714" fill="currentColor" />
  </svg>,
  'NonLinearActiveStep',
);

const getStepIcon = (stepState: StepContextType) => {
  if (stepState.active) {
    return <NonLinearActiveStepIcon color="warning" />;
  }
};

export const StepLabel = forwardRef<unknown, StepLabelProps>((props, ref) => {
  const stepState = useContext(StepContext) as StepContextType;

  return (
    <MuiStepLabel
      ref={ref}
      {...props}
      icon={props.icon ?? getStepIcon(stepState)}
      optional={
        typeof props.optional === 'string' ? (
          <StepOptionalLabel>{props.optional}</StepOptionalLabel>
        ) : (
          props.optional
        )
      }
    />
  );
});
