export const getRedirectPath = state => state.redirectPath;
export const getUser = state => state.session.user;
export const getAuthenticationStatus = state => state.session.authenticated;
export const getTwoFactorAuthCodes = state => state.twoFactorAuthCodes;

export const getSignUpEmail = state => state.signUp.data.email;
export const getSignUpStatus = state => state.signUp.data.loginStatus;
export const getSignUpError = state => state.signUp.error;

export const getLoginData = state => state.login.data;
export const getLoginError = state => state.login.error;
