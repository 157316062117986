import { call, put, select, takeEvery } from 'redux-saga/effects';

import * as actions from 'app-state/actions';
import * as constants from 'app-state/constants';
import * as selectors from 'app-state/selectors';

import API from 'constants/api';
import Routes from 'constants/routes';
import { request } from 'shared-parts/helpers';

function* savePersonalData({ params }) {
  yield put(actions.showLoader());

  try {
    const user = yield select(selectors.getUser);
    const { data: investor } = yield call(
      request,
      API.Onboarding.Individual.personalInformation(),
      'PUT',
      params,
    );
    const formattedUser = { ...user, investor };

    yield put(actions.saveUserData(formattedUser));
    yield put(actions.savePersonalDataSuccess(investor));
  } catch (e) {
    yield put(actions.savePersonalDataError(e));
  }

  yield put(actions.hideLoader());
}

function* savePersonalDataWatcher() {
  yield takeEvery(constants.SAVE_PERSONAL_DATA, savePersonalData);
}

function* saveInvestorCountry({ countryCode, isFirstStep }) {
  yield put(actions.showLoader());

  try {
    const requestMethod = isFirstStep ? 'POST' : 'PUT';
    const user = yield select(selectors.getUser);
    const { data } = yield call(
      request,
      API.Onboarding.Individual.residenceCountry(),
      requestMethod,
      { countryCode },
    );

    yield put(actions.saveUserData({ ...user, investor: data }));
  } catch (e) {
    yield console.log(e);
  }

  yield put(actions.hideLoader());
}

function* saveInvestorCountryWatcher() {
  yield takeEvery(constants.SAVE_INVESTOR_COUNTRY, saveInvestorCountry);
}

function* saveInvestorType({ formData, redirectUrl }) {
  try {
    const user = yield select(selectors.getUser);
    const { data: investor } = yield call(
      request,
      API.Onboarding.Individual.investorType(),
      'PUT',
      formData,
    );
    const investorType = investor.individualInvestorType || investor.investorType;
    const redirectionRoute =
      redirectUrl ||
      (investor.address.countryCode === 'GB' && investorType.name === 'Regular Investor'
        ? Routes.Onboarding.Individual.SuitabilityTest()
        : Routes.Root());

    yield put(actions.saveUserData({ ...user, investor }, redirectionRoute));

    yield put(actions.saveInvestorTypeSuccess(investor));
  } catch (e) {
    yield put(actions.saveInvestorTypeError(e));
  }
}

function* saveInvestorTypeWatcher() {
  yield takeEvery(constants.SAVE_INVESTOR_TYPE, saveInvestorType);
}

function* getIndividualInvestorTypes({ countryCode }) {
  try {
    const { data } = yield call(
      request,
      API.Onboarding.Individual.supportedInvestorTypes(countryCode),
      'GET',
    );

    yield put(actions.getIndividualInvestorTypesSuccess(data));
  } catch (e) {
    yield put(actions.getIndividualInvestorTypesError(e));
  }
}

function* getIndividualInvestorTypesWatcher() {
  yield takeEvery(constants.GET_INDIVIDUAL_INVESTOR_TYPES, getIndividualInvestorTypes);
}

function* getSupportedCountries() {
  try {
    const { data } = yield call(request, API.Onboarding.supportedCountries());

    yield put(actions.getSupportedCountriesSuccess(data));
  } catch (e) {
    yield put(actions.getSupportedCountriesError(e));
  }
}

function* getSupportedCountriesWatcher() {
  yield takeEvery(constants.GET_SUPPORTED_COUNTRIES, getSupportedCountries);
}

function* getUnsupportedCountries() {
  try {
    const { data } = yield call(request, API.Onboarding.unsupportedCountries());

    yield put(actions.getUnsupportedCountriesSuccess(data));
  } catch (e) {
    yield put(actions.getUnsupportedCountriesError(e));
  }
}

function* getUnsupportedCountriesWatcher() {
  yield takeEvery(constants.GET_UNSUPPORTED_COUNTRIES, getUnsupportedCountries);
}

export {
  savePersonalData,
  savePersonalDataWatcher,
  saveInvestorCountry,
  saveInvestorCountryWatcher,
  saveInvestorType,
  saveInvestorTypeWatcher,
  getIndividualInvestorTypes,
  getIndividualInvestorTypesWatcher,
  getSupportedCountries,
  getSupportedCountriesWatcher,
  getUnsupportedCountries,
  getUnsupportedCountriesWatcher,
};
