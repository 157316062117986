import { useContext } from 'react';

import { usePlatformSettings } from '@frontend/config';

import { FeatureFlag, useFeatureFlag } from 'providers';
import Capitalized from 'shared-parts/components/capitalized';
import {
  addCommasToNumber,
  formatCurrencySign,
  toAbbrInt,
  toDefinedFractionSizeAndCutZeros,
  toFloatPercent,
} from 'shared-parts/helpers/format-number';
import getIssuerName from 'shared-parts/helpers/get-issuer-name';
import IssuanceContext, { ComponentsContext } from 'shared-parts/modules/deal/deal.context';

import { SectionHeaderActivePropType } from '../shared/type';

import {
  Column,
  ColumnInfo,
  FirstColumn,
  FirstColumnInfo,
  FirstColumnInner,
  FirstColumnTitle,
  LastColumn,
  Progress,
  ProgressBar,
  ProgressValue,
  Row,
  SecondColumnInfo,
  SecondColumnInner,
  SecondColumnTitle,
  SectionHeader,
  ThirdColumnInner,
  ThirdColumnTitle,
} from './section-header-active.styled';

const SectionHeaderActive: React.FC<React.PropsWithChildren<SectionHeaderActivePropType>> = ({
  deal,
  item,
}) => {
  const { ownershipUnit } = useContext(IssuanceContext);
  const { HeaderDetailedSection } = useContext(ComponentsContext);
  const manualDealIssuer = useFeatureFlag(FeatureFlag.ENABLED_MANUAL_DEAL_ISSUER);
  const { regulatoryEnvironment } = usePlatformSettings();
  const isFinra = regulatoryEnvironment?.value === 'FINRA';
  const orderedAlias = isFinra ? 'requested' : 'ordered';

  const percentage = ((deal.totalTokensSold * deal.pricePerToken) / deal.softTargetAmount) * 100;
  const softTokensEmited = parseInt(
    (deal.softTargetAmount / deal.pricePerToken) as number | any,
    10,
  );
  const progressWidth = toFloatPercent(percentage > 100 ? 100 : percentage);

  return (
    <HeaderDetailedSection item={item}>
      <SectionHeader>
        <Row>
          <FirstColumn>
            <FirstColumnInner>
              <FirstColumnTitle>
                {toAbbrInt(softTokensEmited)}&nbsp;
                <Capitalized>{`${ownershipUnit}s`}</Capitalized>
              </FirstColumnTitle>
              <FirstColumnInfo>
                Issuer: {getIssuerName(deal, manualDealIssuer)}
                <br />
                <Capitalized>{ownershipUnit}</Capitalized> Price :{' '}
                {`${formatCurrencySign(deal.baseCurrency)}${addCommasToNumber(
                  toDefinedFractionSizeAndCutZeros(deal.pricePerToken, 6),
                )}`}
              </FirstColumnInfo>
            </FirstColumnInner>
          </FirstColumn>
          <Column>
            <SecondColumnInner>
              <SecondColumnTitle>Target:</SecondColumnTitle>
              <SecondColumnInfo>
                {deal.baseCurrency} {toAbbrInt(deal.softTargetAmount)}
              </SecondColumnInfo>
            </SecondColumnInner>
          </Column>
          <LastColumn>
            <ThirdColumnInner>
              <ThirdColumnTitle>
                <Capitalized>{`${ownershipUnit}s ${orderedAlias}:`}</Capitalized>
                &nbsp;{toAbbrInt(deal.totalTokensSold)}
              </ThirdColumnTitle>
              <ColumnInfo>
                <Progress>
                  <ProgressValue>{toFloatPercent(percentage)}</ProgressValue>
                  <ProgressBar percentage={progressWidth} />
                </Progress>
              </ColumnInfo>
            </ThirdColumnInner>
          </LastColumn>
        </Row>
      </SectionHeader>
    </HeaderDetailedSection>
  );
};

export default SectionHeaderActive;
