/**
 * Taken from https://github.com/mui/mui-x/blob/master/packages/grid/x-data-grid/src/components/toolbar/GridToolbar.tsx
 * Our goal here is to simply remove the Box with flex to allow us to align these items right
 * As well as align some refs/buttons for the menus
 */
import * as React from 'react';

import {
  GridSlotsComponentsProps,
  GridToolbarColumnsButton,
  GridToolbarContainer,
  GridToolbarDensitySelector,
  GridToolbarExport,
  GridToolbarFilterButton,
  GridToolbarQuickFilter,
  useGridRootProps,
} from '@mui/x-data-grid-premium';

import { Button, ButtonProps } from '../../buttons/button';

export type GridToolbarProps = GridSlotsComponentsProps['toolbar'] & {
  setPanelAnchor?: React.Ref<any>;
};

export const GridToolbar = React.forwardRef<HTMLDivElement, GridToolbarProps>(
  function GridToolbar(props, ref) {
    const {
      className,
      csvOptions,
      printOptions,
      excelOptions,
      showQuickFilter = false,
      quickFilterProps = {},
      setPanelAnchor,
      additionalActions,
      ...other
    } = props;

    const rootProps = useGridRootProps();

    if (
      rootProps.disableColumnFilter &&
      rootProps.disableColumnSelector &&
      rootProps.disableDensitySelector &&
      !showQuickFilter
    ) {
      return null;
    }

    return (
      <GridToolbarContainer ref={ref} {...other}>
        <GridToolbarColumnsButton ref={setPanelAnchor} />
        <GridToolbarFilterButton />
        <GridToolbarDensitySelector />
        <GridToolbarExport
          csvOptions={csvOptions}
          printOptions={printOptions}
          // TODO: remove the reference to excelOptions in community package
          excelOptions={excelOptions}
        />
        {additionalActions}
        {showQuickFilter && <GridToolbarQuickFilter {...quickFilterProps} />}
      </GridToolbarContainer>
    );
  },
);

export type GridToolbarButtonProps = Omit<
  ButtonProps,
  'variant' | 'size' | 'startIcon' | 'endIcon'
> & {
  icon: ButtonProps['startIcon'];
};

export const GridToolbarButton = React.forwardRef(
  (props: GridToolbarButtonProps, ref: React.Ref<HTMLButtonElement>) => {
    return (
      <Button size="small" variant="text" startIcon={props.icon} {...props} ref={ref}>
        {props.children}
      </Button>
    );
  },
);
