import { push } from 'connected-react-router';

import store from 'app-state/store';

import handleGetStarted from 'modules/account-details/components/get-started-handler';
import { User } from 'modules/account-details/components/types';
import Routes from 'constants/routes';
import { FeatureFlag, useFeatureFlag } from 'providers';
import { InvestorType } from 'shared/invest-button/verification-tooltip/verification-tooltip.types';
import memorizePath from 'shared-parts/helpers/memorize-path';

export const getKYBDescription = (
  alternativeText: boolean,
  user: User,
  isOfflineVerificationEnabled: boolean,
) => {
  if (alternativeText) {
    return isOfflineVerificationEnabled
      ? `
      As an authorised financial institution, ${window.config.PLATFORM_NAME} requires
      ${user.firstName} ${user.lastName} to pass a KYB check before performing any transactions on the platform.
      This process is not a credit check and will be carried out securely by a 3rd party.
      `
      : `
      As an authorized financial institution, we require ${user.investor.name} to pass a KYB
      check before performing any transactions on the platform.
      `;
  }
  return `As an authorized financial institution, we require ${user.investor.name} to pass a KYB
check before performing any transactions on the platform.`;
};

export const getKYBKYCVerificationBody = (alternativeText: boolean, user: User) => {
  if (alternativeText) {
    return `
    You will receive an email from ${window.config.PLATFORM_NAME} with guidelines for KYC/KYB submission.
    Once you pass the verification check, you will be able to place an order.
    `;
  }
  const process = user.investorType === InvestorType.InstitutionalInvestor ? 'KYB' : 'KYC';
  return `
  You will receive an email from the Issuer with guidelines for ${process} submission.
  Once you pass the verification check, you will be able to place an order.
  `;
};

export const getKYBInProgressCustomMessage = (alternativeText: boolean) => {
  if (alternativeText) {
    return `Verification process has been initiated. You should receive an update from ${window.config.PLATFORM_NAME} once this check has passed. Please contact ${window.config.SMTP_SUPPORT_EMAIL} if you require assistance.`;
  }
  return `Verification process has been initiated. You will receive an update from the Issuer once this check has passed. Please contact the Issuer if you require assistance.`;
};
export const getKYBUserDetailsMessage = (alternativeText: boolean) => {
  if (alternativeText) {
    return `If you wish to change any of these details, please contact ${window.config.SMTP_SUPPORT_EMAIL}.`;
  }
  return 'If you wish to change any of these details, please contact the issuer.';
};

export const verify = (pathname: string, user: User) => {
  memorizePath(pathname);
  if (useFeatureFlag(FeatureFlag.CUSTOM_KYC_KYB)) {
    return handleGetStarted(user, true)();
  }

  store.dispatch(push(Routes.Onboarding.AmlVerification()));
};
