import {
  AccordionDetails as MuiAccordionDetails,
  AccordionDetailsProps,
  styled,
} from '@mui/material';

export const AccordionDetails = styled((props: AccordionDetailsProps) => {
  return <MuiAccordionDetails id="panel-content" {...props} />;
})(() => ({
  padding: '0',
}));
