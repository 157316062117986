import { useEffect } from 'react';
import { connect } from 'react-redux';

import { getCountries as getCountriesAction } from 'app-state/actions';
import { getCountriesSelector } from 'app-state/selectors/countries';

import { Notification } from 'shared';
import { Select } from 'shared-parts/components';
import { transformToCountryTypeMap } from 'shared-parts/components/country/helper';

const renderNonSupportedCountryNotification = () => (
  <Notification
    alignText="center"
    arrow="up"
    color="purple"
    layout="large"
    message="Unfortunately, our platform is not currently open to investors in your country."
  />
);

const CountrySelect = ({
  isIndividual,
  supportedCountries = [],
  unsupportedCountries,
  name,
  value,
  onOptionSelect = () => {},
  placeholder = 'Select Country',
  notSupportedCountry,
  isFirstAccountInfoPart,
  getCountries,
  countries,
  id,
}) => {
  const paramsForIndividualInvestor = {
    isOther: isFirstAccountInfoPart,
    countries: isFirstAccountInfoPart ? unsupportedCountries : supportedCountries,
  };

  useEffect(() => {
    getCountries();
  }, []);
  const allCountries = transformToCountryTypeMap(countries.data ?? []);

  const getFilteredCountries = (isOther, countriesData = []) => {
    const filterCountryCodes = countriesData.map(country => country.countryCode);

    return allCountries.filter(country =>
      isOther
        ? !filterCountryCodes.includes(country.countryCode)
        : filterCountryCodes.includes(country.countryCode),
    );
  };

  const getOptions = (countriesData = []) =>
    countriesData.map(country => ({ text: country.countryName, value: country.countryCode }));

  const getOptionsForIndividualInvestor = ({ isOther, countriesData = [] }) =>
    countries.loading
      ? []
      : [
          ...getOptions(getFilteredCountries(isOther, countriesData)),
          ...getOptions(getFilteredCountries(!isOther, countriesData)),
        ];
  const countriesOptions = isIndividual
    ? getOptionsForIndividualInvestor(paramsForIndividualInvestor)
    : getOptions(allCountries);

  return (
    <div>
      <Select
        id={id}
        loading={countries.loading}
        options={countriesOptions}
        name={name}
        autoComplete="country-name"
        placeholder={placeholder}
        value={value}
        onOptionSelect={onOptionSelect}
      />
      {notSupportedCountry && renderNonSupportedCountryNotification()}
    </div>
  );
};

const mapStateToProps = state => ({
  countries: getCountriesSelector(state),
});

const mapDispatchToProps = dispatch => ({
  getCountries: () => dispatch(getCountriesAction()),
});

export default connect(mapStateToProps, mapDispatchToProps)(CountrySelect);
