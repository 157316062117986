import styled from 'styled-components';

import Table from 'shared-parts/components/table/regular-table/table';
import { neutral200, primary, secondary100, white } from 'shared-parts/constants/colors';

const WrappedTable = styled(Table)`
  background: ${neutral200};
  border-color: ${white};
  margin-bottom: 30px;

  td {
    width: 50%;
  }

  tr:hover {
    background-color: inherit;
    box-shadow: none;
  }
`;

const cellStyles = `
  color: ${primary};
  font-size: 18px;

  :first-child {
    font-weight: normal;
    background: ${secondary100};
  }
`;

export { WrappedTable, cellStyles };
