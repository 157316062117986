import { Box } from '@mui/material';

import AlternativeInvestmentVehicleFooter from './alternative-investment-vehicle-footer';
import {
  Background,
  DealTypeTag,
  Description,
  Header,
  HeaderContent,
  Logo,
  LogoContainer,
  LogoPlaceholder,
  Photos,
  TileComponent,
  TileLink,
} from './deal-tile.styled';
import { Deal } from './deal-tile.types';
import { IssuanceStats } from './stats';

export interface DealTileProps {
  deal: Deal;
  closedForInvestmentBlockBackground: string;
  mainFont: string;
  oswaldFont: string;
  alternativeColor: string;
  highlightColor: string;
  secondaryColor: string;
  neutral300Color: string;
  neutral500Color: string;
  neutral800Color: string;
  url: string;
}

const DealTypesLabels: Record<string, string> = {
  equity: 'Equity',
  debt: 'Debt',
  fund: 'Fund',
  alternative_investment_vehicle: 'alternative',
};

export const DealTile = ({
  deal,
  closedForInvestmentBlockBackground,
  mainFont,
  oswaldFont,
  alternativeColor,
  highlightColor,
  secondaryColor,
  neutral300Color,
  neutral500Color,
  neutral800Color,
  url,
}: DealTileProps) => {
  const { description, name, companyName, images, dealType, showAssetClass, fundName } = deal;

  const logo = images.logo?.small;

  const isAlternativeInvestmentVehicle = dealType === 'alternative_investment_vehicle';

  return (
    <TileComponent role="listitem" neutral500Color={neutral500Color}>
      <TileLink url={url} secondaryColor={secondaryColor}>
        <Header>
          <Photos>
            <LogoContainer>
              {logo ? (
                <Logo src={logo} alt="" loading="lazy" />
              ) : (
                <LogoPlaceholder alternativeColor={alternativeColor} />
              )}
            </LogoContainer>
            <Background
              src={images.background?.cover}
              alt=""
              loading="lazy"
              highlightColor={highlightColor}
            />
            {showAssetClass !== false && (
              <DealTypeTag mainFont={mainFont}>{DealTypesLabels[dealType]}</DealTypeTag>
            )}
          </Photos>
          <HeaderContent oswaldFont={oswaldFont} neutral300Color={neutral300Color}>
            <Box>
              <Box>{name || companyName}</Box>
              {isAlternativeInvestmentVehicle && (
                <Box fontSize="14px" color={neutral800Color}>
                  {fundName}
                </Box>
              )}
            </Box>
          </HeaderContent>
        </Header>
        <Description neutral800Color={neutral800Color}>{description}</Description>

        {isAlternativeInvestmentVehicle ? (
          <AlternativeInvestmentVehicleFooter deal={deal} />
        ) : (
          <IssuanceStats
            {...deal}
            closedForInvestmentBlockBackground={closedForInvestmentBlockBackground}
            oswaldFont={oswaldFont}
            secondaryColor={secondaryColor}
            neutral500Color={neutral500Color}
            neutral300Color={neutral300Color}
          />
        )}
      </TileLink>
    </TileComponent>
  );
};
