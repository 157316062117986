import produce from 'immer';
import { AnyAction } from 'redux';

import {
  GET_CONTRIBUTION,
  GET_CONTRIBUTION_ERROR,
  GET_CONTRIBUTION_SUCCESS,
  UPDATE_CONTRIBUTION,
  UPDATE_CONTRIBUTION_ERROR,
  UPDATE_CONTRIBUTION_SUCCESS,
} from 'app-state/constants/contribution';
import { Contribution } from 'app-state/types/share-options.types';

import { AsyncState } from 'helpers/redux.helpers';

export type ContributionStateType = {
  contribution: AsyncState<Contribution | Record<string, never>>;
  updateContribution: {
    loading: boolean;
    error?: string;
  };
};

export const defaultContributionState: ContributionStateType = {
  contribution: {
    data: {},
    error: undefined,
    loading: false,
  },
  updateContribution: {
    loading: false,
    error: undefined,
  },
};

export const contributionReducer = (
  state = defaultContributionState,
  action: AnyAction,
): ContributionStateType =>
  produce(state, draft => {
    switch (action.type) {
      case GET_CONTRIBUTION:
        draft.contribution.loading = true;
        break;
      case GET_CONTRIBUTION_SUCCESS:
        draft.contribution.loading = false;
        draft.contribution.data = action.payload.data;
        break;
      case GET_CONTRIBUTION_ERROR:
        draft.contribution.loading = false;
        draft.contribution.error = action.payload.error;
        break;
      case UPDATE_CONTRIBUTION:
        draft.updateContribution.loading = true;
        break;
      case UPDATE_CONTRIBUTION_SUCCESS:
        draft.updateContribution.loading = false;
        draft.contribution.data = action.payload.data;
        break;
      case UPDATE_CONTRIBUTION_ERROR:
        draft.updateContribution.loading = false;
        draft.updateContribution.error = action.payload.error;
        break;
      default:
        break;
    }
  });
