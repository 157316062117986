/* eslint-disable no-param-reassign */
import { FC, useCallback, useEffect, useState } from 'react';
import {
  PlaidLinkError,
  PlaidLinkOnExit,
  PlaidLinkOnExitMetadata,
  PlaidLinkOnSuccess,
  PlaidLinkOnSuccessMetadata,
} from 'react-plaid-link';
import { connect } from 'react-redux';

import { Dispatch } from 'redux';

import { showModal } from 'app-state/actions';

import { ModalFailed } from 'shared-parts/components';
import InfoModal from 'shared-parts/components/modal/info-modal';
import { defaultErrorMessage } from 'shared-parts/constants/error-messages';

import { usePlaidAccessToken, usePlaidAuthStateToken, usePlaidLinkToken } from './hooks';
import { PlaidLink, PlaidReadyChangeHandler } from './plaid-link';

type ShowPlaidWithdrawWindowDialogProps = ReturnType<typeof mapDispatchToProps> & {
  onReady: () => void;
  onError: () => void;
  onAbort: () => void;
  modalFrameRef: React.RefObject<HTMLInputElement>;
};

const ShowPlaidWindowDialog: FC<React.PropsWithChildren<ShowPlaidWithdrawWindowDialogProps>> = ({
  onReady,
  onError,
  onAbort,
  modalFrameRef,
}) => {
  const {
    token: linkToken,
    loading: loadingLinkToken,
    error: linkTokenError,
    load: loadLinkToken,
  } = usePlaidLinkToken();

  const {
    token: accessToken,
    loading: loadingAccessToken,
    error: accessTokenError,
    load: loadAccessToken,
  } = usePlaidAccessToken();

  const displayBoxBackground = useCallback((instance: any) => {
    if (instance.current) {
      instance.current.style.display = 'block';
    }
  }, []);

  const hideBoxBackground = useCallback((instance: any) => {
    if (instance.current) {
      instance.current.style.display = 'none';
    }
  }, []);

  const authStateToken = usePlaidAuthStateToken();

  // Manage the internal state of plaid-link
  const [plaidReady, setPlaidReady] = useState<{ ready: boolean; open: () => void }>({
    ready: false,
    open: () => undefined,
  });

  const handleReadyChange = useCallback<PlaidReadyChangeHandler>(
    (ready, open) => setPlaidReady({ ready, open }),
    [],
  );

  // Load link token if missing or no access token.
  // We can survive with no link token if our access token is still valid!
  useEffect(() => {
    if (accessToken) {
      return;
    }

    if (!linkToken) {
      loadLinkToken();
    }
  }, [linkToken, accessToken]);

  // If link token is present and no access token, open up plaid-link to begin the process
  useEffect(() => {
    if (accessToken || !linkToken) {
      return;
    }

    if (plaidReady.ready) {
      plaidReady.open();
    }
  }, [plaidReady, linkToken, accessToken]);

  // Once the access token becomes available, fire off the callback
  useEffect(() => {
    if (accessToken) {
      displayBoxBackground(modalFrameRef);
      onReady();
    }
  }, [accessToken]);

  // Ensure that we never leave the modal transparent
  useEffect(() => {
    return () => displayBoxBackground(modalFrameRef);
  }, []);

  // Feed back any errors
  useEffect(() => {
    if (linkTokenError || accessTokenError) {
      onError();
    }
  }, [linkTokenError, accessTokenError]);

  const onSuccess = useCallback<PlaidLinkOnSuccess>(
    (publicToken: string, _metadata: PlaidLinkOnSuccessMetadata) => {
      displayBoxBackground(modalFrameRef);
      loadAccessToken(publicToken);
    },
    [],
  );

  const onExit = useCallback<PlaidLinkOnExit>(
    (error: PlaidLinkError | null, metadata: PlaidLinkOnExitMetadata) => {
      displayBoxBackground(modalFrameRef);
      if (error) {
        console.error('An error occurred while connecting to plaid.', error);
        onError();
      } else if (metadata.status !== 'connected') {
        onAbort();
      }
    },
    [],
  );

  // If link token is missing, the initial state would be not loading
  // and no link token. We immediately begin loading it if missing (if
  // there's no access token), so we can assume that we're in a loading state
  const isPlaidLoading = loadingLinkToken || loadingAccessToken || !linkToken;

  useEffect(() => {
    if (!isPlaidLoading && accessToken) {
      displayBoxBackground(modalFrameRef);
    } else {
      hideBoxBackground(modalFrameRef);
    }
  }, [isPlaidLoading, accessToken]);

  if (isPlaidLoading) {
    return null;
  }

  if (linkTokenError || accessTokenError || !linkToken) {
    console.error(linkTokenError || accessTokenError);

    return defaultErrorMessage;
  }

  return (
    <PlaidLink
      onReadyChange={handleReadyChange}
      token={linkToken}
      onSuccess={onSuccess}
      onExit={onExit}
      receivedRedirectUri={authStateToken && window.location.href}
    />
  );
};

const mapDispatchToProps = (dispatch: Dispatch) => ({
  onAbort: () =>
    dispatch(
      showModal({
        closable: true,
        component: InfoModal,
        title: 'Withdrawal Cancelled',
        additionalText: 'No funds will be withdrawn from your cash balance.',
        buttonText: 'OK',
        modalWidth: 600,
        iconSize: 40,
      }),
    ),
  onError: () =>
    dispatch(
      showModal({
        closable: true,
        showHeader: false,
        component: ModalFailed,
      }),
    ),
});

export default connect(null, mapDispatchToProps)(ShowPlaidWindowDialog);
