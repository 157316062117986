import { Deal } from '../deal-tile.types';

import { DealStats } from './deal-stats';
import { CommonsStats } from './shared.styled';
import { getQuarterNumber } from './trade-offs';

export type PreLaunchIssuanceStatsProps = Deal & {
  oswaldFont: string;
  neutral300Color: string;
};

export const PreLaunchIssuanceStats = ({
  subscriptionStart,
  oswaldFont,
  neutral300Color,
}: PreLaunchIssuanceStatsProps) => {
  const quarter = getQuarterNumber(subscriptionStart);
  const year = new Date(subscriptionStart).getFullYear();

  return (
    <CommonsStats neutral300Color={neutral300Color}>
      <DealStats oswaldFont={oswaldFont} text="Launching soon">{`Q${quarter} ${year}`}</DealStats>
    </CommonsStats>
  );
};
