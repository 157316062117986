export enum SellOrderType {
  Immediate = 'immediate',
  Bulk = 'bulk',
}

export type SellOrderFormValues = {
  shareClass?: string;
  quantity?: string;
  type?: SellOrderType;
  understandResponsibilityOfTaxRequirements?: boolean;
  termsAndCondition?: boolean;
};

export type TradeSharesStateType = {
  sellOrder: {
    loading: boolean;
    error?: string;
  };
  transferOrder: {
    loading: boolean;
    error?: string;
  };
};

export type TransferOrderFormValues = {
  planId?: string;
  quantity?: number;
  termsAndCondition?: boolean;
  transferTo?: string;
  brokerId?: string;
  brokerEmail?: string;
};

const initialState: TradeSharesStateType = {
  sellOrder: {
    loading: false,
    error: undefined,
  },
  transferOrder: {
    loading: false,
    error: undefined,
  },
};

export default initialState;
