import styled from 'styled-components';

import * as Colors from 'shared-parts/constants/colors';
import * as Fonts from 'shared-parts/constants/fonts';

export default styled.button`
  -webkit-appearance: none;
  -moz-appearance: none;
  background: ${({
    disabled,
    disabledColor = Colors.neutral800,
    activeColor = Colors.alternative,
  }) => (disabled ? disabledColor : activeColor)};
  border: 1px solid transparent;
  border-radius: 4px;
  box-shadow: none;
  color: ${({ textColor = '#FFF' }) => textColor};
  cursor: ${({ disabled }) => (disabled ? 'auto' : 'pointer')};
  font-weight: bold;
  line-height: 14px;
  outline: 0;
  padding: 12px;
  height: 50px;
  min-width: 120px;
  font-size: 14px;
  font-family: ${Fonts.mainFont};

  &:hover,
  &:focus {
    box-shadow: 0 3px 6px ${Colors.shadowColor};
    ${({ disabled, hoverBorderColor }) =>
      !disabled && hoverBorderColor && `border: 1px solid ${hoverBorderColor}`};
    color: ${({ disabled, hoverTextColor }) => !disabled && hoverTextColor && hoverTextColor};
    ${({ disabled, showHoverBoxShadow }) =>
      disabled || !showHoverBoxShadow ? '' : 'box-shadow: 0 3px 6px 0 rgba(0, 0, 0, 0.2);'}
  }
  &:visited,
  &:active {
    border: 1px solid ${({ activeColor, disabled }) => (disabled ? Colors.neutral800 : activeColor)};
  }
`;
