import { push } from 'connected-react-router';

import { call, put, takeEvery } from 'redux-saga/effects';

import {
  getShareOptionError,
  getShareOptionsDisplayStateError,
  getShareOptionsDisplayStateSuccess,
  getShareOptionsError,
  getShareOptionsSuccess,
  getShareOptionSuccess,
} from 'app-state/actions/share-options';
import {
  GET_SHARE_OPTION,
  GET_SHARE_OPTIONS,
  GET_SHARE_OPTIONS_DISPLAY_STATE,
} from 'app-state/constants/share-options';

import API from 'constants/api';
import Routes from 'constants/routes';
import request from 'shared-parts/helpers/request';

function* getShareOptions({ params: { page = 1, perPage = 10 } }) {
  try {
    const { data } = yield call(request, API.Investment.shareOptions(page, perPage), 'GET');

    const { pagination } = data.meta;
    if (pagination.totalPages > 0 && pagination.page > pagination.totalPages) {
      return yield getShareOptions({ params: { page: 1, perPage } });
    }

    yield put(getShareOptionsSuccess(data));
    yield put(push(Routes.Holdings.ShareOptions(`/${page}`, `/${perPage}`)));
  } catch (e) {
    yield put(getShareOptionsError(e));
  }
}

function* getShareOptionsWatcher() {
  yield takeEvery(GET_SHARE_OPTIONS, getShareOptions);
}

function* getShareOption({ companyUuid }) {
  try {
    const { data } = yield call(request, API.Investment.shareOption(companyUuid), 'GET');
    yield put(getShareOptionSuccess(data));
  } catch (e) {
    yield put(getShareOptionError(e));
  }
}

function* getShareOptionWatcher() {
  yield takeEvery(GET_SHARE_OPTION, getShareOption);
}

function* getShareOptionsDisplayState() {
  try {
    const {
      data: { result },
    } = yield call(request, API.Investment.haveShareOptions(), 'GET');

    yield put(getShareOptionsDisplayStateSuccess(result));
  } catch (e) {
    yield put(getShareOptionsDisplayStateError(e));
  }
}

function* getShareOptionsDisplayStateWatcher() {
  yield takeEvery(GET_SHARE_OPTIONS_DISPLAY_STATE, getShareOptionsDisplayState);
}

export {
  getShareOptions,
  getShareOptionsWatcher,
  getShareOption,
  getShareOptionWatcher,
  getShareOptionsDisplayState,
  getShareOptionsDisplayStateWatcher,
};
