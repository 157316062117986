import type { FC } from 'react';

import { neutral700 } from 'shared-parts/constants/colors';

type CloseIconProps = {
  width?: number;
  height?: number;
  fillColor?: string;
};

const CloseIcon: FC<React.PropsWithChildren<CloseIconProps>> = ({
  width = 18,
  height = 18,
  fillColor = neutral700,
}) => (
  <svg xmlns="http://www.w3.org/2000/svg" width={width} height={height} viewBox="0 0 19.414 19.414">
    <path
      id="Union_10"
      data-name="Union 10"
      d="M9,9,0,18,9,9,0,0,9,9l9-9L9,9l9,9Z"
      transform="translate(0.707 0.707)"
      fill="none"
      stroke={fillColor}
      strokeLinecap="round"
      strokeWidth="2"
    />
  </svg>
);

export default CloseIcon;
