import styled from 'styled-components';

import { getTenantConfig } from 'helpers/get-tenant-config';

const {
  custom: { authScreen },
} = getTenantConfig();

export default styled.div`
  font-family: 'Nunito Sans', sans-serif;
  text-align: ${authScreen.textAlign ?? 'center'};
  width: 320px;
`;
