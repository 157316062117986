import styled from 'styled-components';

import * as Colors from 'shared-parts/constants/colors';

export const HeaderBannerOuter = styled.div`
  color: ${Colors.primary};
  padding: 8px 19px;
  position: sticky;
  top: 0em;
  width: 100%;
  background: rgba(243, 244, 245);
  z-index: 1001;
`;

export const HeaderBannerInner = styled.div`
  max-width: 1100px;
  margin: 0 auto;
  display: flex;
  align-items: center;
`;

export const BannerLabel = styled.div`
  width: 100%;
  text-align: center;
  margin-left: 10px;
  font-size: 14px;
`;

export const CloseButton = styled.span`
  cursor: pointer;
  user-select: none;
  margin-left: auto;
  margin-right: 10px;
`;
