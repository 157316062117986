import { createContext, PropsWithChildren, useContext, useEffect, useState } from 'react';

import { ConsoleApiName } from '@datadog/browser-core';
import { datadogLogs } from '@datadog/browser-logs';
import { datadogRum } from '@datadog/browser-rum';

import { useEnvVar } from '@frontend/config';
import { useCookieConsent } from '@frontend/consent';

// eslint-disable-next-line @nx/enforce-module-boundaries
import pjson from '../../../../package.json';

const LoggerContext = createContext<typeof datadogLogs>(datadogLogs);
LoggerContext.displayName = 'LoggerContext';

export const LoggerProvider = <TUser extends Record<string, any>>({
  appName,
  children,
  user,
  getId,
}: PropsWithChildren<{
  appName: string;
  user?: TUser;
  getId: (user: TUser) => string;
}>) => {
  const { ANALYTICS } = useCookieConsent();

  const [dataDogRUMInitialized, setDataDogRUMInitialized] = useState(false);
  const [dataDogLogsInitialized, setDataDogLogsInitialized] = useState(false);

  const {
    DD_RUM_APPLICATION_ID,
    DD_RUM_CLIENT_TOKEN,
    DD_RUM_SAMPLE_RATE,
    DD_RUM_SESSION_REPLAY_SAMPLE_RATE,
    DD_SERVICE_NAME,
    DD_ENV,
    GLOBACAP_ENVIRONMENT,
    DD_LOGS_CLIENT_TOKEN,
    DD_FORWARD_ERRORS,
    DD_FORWARD_LOGS,
    DD_LOGS_SAMPLE_RATE,
    DO_NOT_REQUIRE_COOKIE_CONSENT,
  } = useEnvVar([
    'DD_RUM_APPLICATION_ID',
    'DD_RUM_CLIENT_TOKEN',
    'DD_RUM_SAMPLE_RATE',
    'DD_RUM_SESSION_REPLAY_SAMPLE_RATE',
    'DD_SERVICE_NAME',
    'DD_ENV',
    'GLOBACAP_ENVIRONMENT',
    'DD_LOGS_CLIENT_TOKEN',
    'DD_FORWARD_ERRORS',
    'DD_FORWARD_LOGS',
    'DD_LOGS_SAMPLE_RATE',
    'DO_NOT_REQUIRE_COOKIE_CONSENT',
  ]);

  const userId = user && getId(user);

  // Once DataDog is initialized it will be enabled for the remainder of the session
  useEffect(() => {
    const analyticsCookiesAccepted = ANALYTICS === 'ACCEPT';

    if (
      DD_RUM_CLIENT_TOKEN &&
      DD_RUM_APPLICATION_ID &&
      (analyticsCookiesAccepted || DO_NOT_REQUIRE_COOKIE_CONSENT)
    ) {
      if (dataDogRUMInitialized) {
        // Avoid initialization of DD RUM more than once
        return;
      }

      datadogRum.init({
        applicationId: DD_RUM_APPLICATION_ID as string,
        clientToken: DD_RUM_CLIENT_TOKEN as string,
        site: 'datadoghq.eu',
        sampleRate: Number(DD_RUM_SAMPLE_RATE) || 100,
        sessionReplaySampleRate: Number(DD_RUM_SESSION_REPLAY_SAMPLE_RATE) || 100,
        trackInteractions: true,
        trackResources: true,
        trackLongTasks: true,
        trackFrustrations: true,
        allowedTracingUrls: [`${window.location.origin}/api`],

        service: (DD_SERVICE_NAME as string) || appName,
        env: (DD_ENV as string) || (GLOBACAP_ENVIRONMENT as string) || 'local',
        version: pjson.version,
      });

      setDataDogRUMInitialized(true);
    }

    if (DD_LOGS_CLIENT_TOKEN && (analyticsCookiesAccepted || DO_NOT_REQUIRE_COOKIE_CONSENT)) {
      if (dataDogLogsInitialized) {
        // Avoid initialization of DD Logs more than once
        return;
      }

      datadogLogs.init({
        clientToken: DD_LOGS_CLIENT_TOKEN as string,
        site: 'datadoghq.eu',
        forwardErrorsToLogs: DD_FORWARD_ERRORS !== 'false',
        forwardConsoleLogs: ((DD_FORWARD_LOGS as string)?.split(',') as ConsoleApiName[]) || 'all',
        sampleRate: Number(DD_LOGS_SAMPLE_RATE) || 100,

        service: (DD_SERVICE_NAME as string) || appName,
        env: (DD_ENV as string) || (GLOBACAP_ENVIRONMENT as string) || 'local',
        version: pjson.version,
      });

      setDataDogLogsInitialized(true);
    }
  }, [ANALYTICS]);

  useEffect(() => {
    if (user != null && getId(user) != null) {
      datadogLogs.setGlobalContextProperty('usr.id', getId(user));
      datadogLogs.setGlobalContextProperty('usr.email', user.email);
      datadogLogs.setGlobalContextProperty('usr.name', `${user.firstName} ${user.lastName}`);

      datadogRum.setUser({
        ...user,
        'usr.name': `${user.firstName} ${user.lastName}`,
        authToken: undefined, // Make sure we strip this out! Sensitive info.
      });

      datadogRum.startSessionReplayRecording();
    } else {
      datadogLogs.removeGlobalContextProperty('usr.id');
      datadogLogs.removeGlobalContextProperty('usr.email');
      datadogLogs.removeGlobalContextProperty('usr.name');

      datadogRum.clearUser();

      datadogRum.stopSessionReplayRecording();
    }
  }, [userId]);

  return <LoggerContext.Provider value={datadogLogs}>{children}</LoggerContext.Provider>;
};

export const useLoggerContext = (): any => useContext(LoggerContext);
