const STATUSES: {
  [key: string]: { status?: string; text: string };
} = {
  sellInitiated: { status: 'sell_initiated', text: 'Sell initiated' },
  buyerConfirmation: { status: 'buyer_confirmation', text: 'Buyer confirmation' },
  fundsTransfer: { status: 'funds_transfer', text: 'Transfer of funds' },
  issuerAuth: { status: 'issuer_auth', text: 'Issuer authorisation' },
  stfPending: { status: 'stf_pending', text: 'Issuer authorisation' },
  stfGenerated: { status: 'stf_generated', text: 'Issuer authorisation' },
  hmrc: { status: 'hmrc', text: 'Sent to HMRC' },
  hmrcPending: { status: 'hmrc_pending', text: 'Sent to HMRC' },
  hmrcStamped: { status: 'hmrc_stamped', text: 'Sent to HMRC' },
  completed: { status: 'completed', text: 'Transaction completed' },
  canceled: { status: 'canceled', text: 'Cancelled' },
  rejected: { status: 'rejected', text: 'Rejected' },
  placed: { status: 'placed', text: 'Transfer funds' },
  partiallyFilled: { status: 'partially_filled', text: 'Partially filled' },
  cancelled: { status: 'cancelled', text: 'Cancelled' },
  filled: { status: 'filled', text: 'Filled' },
  unknown: { text: 'Unknown' },
};

export default STATUSES;
