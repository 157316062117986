import { CSSProperties, FC } from 'react';

import styled from 'styled-components';

import PoweredByImage from 'images/powered-by-globacap.svg';
import { FooterLinks } from 'shared/footer/footer';

const OuterContainer = styled.div<Pick<React.CSSProperties, 'backgroundColor'>>`
  background-color: #fff;
  display: flex;
  flex-direction: column;
  min-height: 100vh;
`;

const InnerContainer = styled.div`
  align-items: center;
  display: flex;
  flex-direction: column;
  justify-content: center;
  flex-grow: 1;
`;

const FooterContainer = styled.div`
  position: relative;
  width: 100%;
`;

const PoweredByImgContainer = styled.div`
  width: 100%;
  text-align: center;
`;

const PoweredByImg = styled.img`
  padding: 35px 0px;
  max-width: 149px;
`;

const AuthContainer: FC<React.PropsWithChildren<Pick<CSSProperties, 'backgroundColor'>>> = ({
  children,
}) => (
  <OuterContainer>
    <InnerContainer>
      {children}
      <PoweredByImgContainer>
        <PoweredByImg src={PoweredByImage} alt="Powered by Globacap" />
      </PoweredByImgContainer>
    </InnerContainer>
    <FooterContainer>
      <FooterLinks withPaddings={false} isAuth />
    </FooterContainer>
  </OuterContainer>
);

export default AuthContainer;
