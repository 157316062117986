import styled from 'styled-components';

import { alternative, neutral200, neutral500 } from 'shared-parts/constants/colors';

import ButtonBase from './button-base.styled';

export type IconButtonContainerProps = {
  color?: string;
  iconColor?: string;
  backgroundColor?: string;
};

export const IconButtonContainer = styled(ButtonBase)<IconButtonContainerProps>`
  display: inline-flex;
  align-items: center;
  justify-content: center;
  padding: 12px 20px;
  border: none;
  cursor: ${({ disabled }) => (disabled ? 'auto' : 'pointer')};
  font-size: 14px;
  border-radius: 4px;
  height: 40px;
  color: ${props => {
    if (props.disabled) {
      return neutral500;
    }
    if (props.color) {
      return props.color;
    }

    return 'inherit';
  }};
  background-color: ${props => {
    if (props.backgroundColor) {
      return props.backgroundColor;
    }

    return neutral200;
  }};

  svg,
  path {
    margin-right: 5px;
    height: 16px;
    width: 16px;
    fill: ${props => props.iconColor || alternative};
  }
`;
