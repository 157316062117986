import { NavLink } from 'react-router-dom';

import styled, { css } from 'styled-components';

import { getTenantConfig } from 'helpers/get-tenant-config';
import * as Colors from 'shared-parts/constants/colors';
import { Screens } from 'shared-parts/constants/screens';

const {
  custom: {
    userMenu: { inactiveMenuItemTextColor },
  },
} = getTenantConfig();

const Wrapper = styled.div`
  position: relative;

  nav {
    float: right;
    padding: 0;

    @media only screen and (min-width: ${Screens.md}) {
      float: none;
    }

    .navbar-toggler {
      border: 0;
      box-shadow: none;
      outline: 0;
      padding: 0;
      position: absolute;
      right: 0;
      top: 12px;

      @media only screen and (min-width: ${Screens.md}) {
        display: block;
      }

      @media only screen and (min-width: ${Screens.xl}) {
        display: none;
      }

      .navbar-toggler-icon {
        color: white;
        width: 35px;
      }
    }

    .navbar-collapse {
      display: none !important;
      margin-top: 50px;

      @media only screen and (min-width: ${Screens.xl}) {
        display: flex !important;
      }

      @media only screen and (min-width: ${Screens.md}) {
        margin-top: 0;
      }
    }
  }

  .navbar-nav {
    display: block;
    text-align: right;
    width: 100%;
  }
`;

const ModalWrapper = styled.div`
  height: 100vh;
  width: 100vw;
  position: fixed;
  top: 0;
  left: 0;
  background: rgba(0, 0, 0, 0.5);
  z-index: 1000;

  @media only screen and (min-width: ${Screens.xl}) {
    display: none;
  }
`;

const Modal = styled.div`
  position: fixed;
  top: 0;
  height: 100vh;
  background: ${Colors.white};
  z-index: 10000;
  width: 87vw;
  transition:
    opacity 0.3s linear,
    right 0.3s ease-out;
  right: -100vw;
  overflow-y: auto;
  -webkit-overflow-scrolling: touch;

  @media only screen and (max-width: ${Screens.xl}) {
    ${({ isOpen }) => `right: ${isOpen ? 0 : '-100vw'};`}
  }

  @media only screen and (max-width: ${Screens.md}) {
    max-width: 500px;
    transform: translateX(50%);
    ${({ isOpen }) => `right: ${isOpen ? '50%' : '-100vw'};`}
  }
`;

const ModalHeader = styled.div`
  border-bottom: 1px solid ${Colors.neutral500};
  padding: 15px 36px 15px 15px;
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
  margin-bottom: 153px;
`;

const NavigationItem = styled.div`
  position: relative;
  display: inline-block;

  @media only screen and (max-width: ${Screens.xl}) {
    display: block;
    border-bottom: 1px solid ${Colors.neutral300};
    list-style: none;
    margin: 0 30px;
    padding: 12.5px 0;
    text-align: center;

    :last-child {
      margin-bottom: 80px;
    }
    :first-child {
      border-top: 1px solid ${Colors.neutral300};
    }
  }
`;

const MenuItem = css`
  text-decoration: none;
  text-transform: uppercase;

  @media only screen and (min-width: ${Screens.xl}) {
    box-sizing: content-box;
    color: ${inactiveMenuItemTextColor ?? Colors.white};
    display: flex;
    align-items: center;
    font-size: 14px;
    height: 59px;
    line-height: 14px;
    margin: 0 16px;
    padding-bottom: 3px;

    &.active,
    &:hover {
      padding-bottom: 0;
      text-decoration: none;
      color: ${Colors.secondary};
      border-bottom: 3px solid ${Colors.secondary};
    }
    &.active {
      font-weight: 700;
    }
  }

  @media only screen and (max-width: ${Screens.xl}) {
    color: ${Colors.primary};
    font-size: 30px;
    line-height: 41px;

    :hover {
      color: ${Colors.primary};
      text-decoration: none;
    }
  }
`;

const MenuButton = styled.button`
  background-color: transparent;
  border: none;
  cursor: pointer;

  :focus {
    outline: none;
  }

  ${MenuItem}
`;

const MenuLink = styled(NavLink)`
  ${MenuItem} {
    ${({ active }) =>
      active &&
      `
      padding-bottom: 0;
      text-decoration: none;
      color: ${Colors.secondary};
      border-bottom: 3px solid ${Colors.secondary};
      font-weight: 700;
    `}
  }
`;

const CloseButton = styled.div`
  position: relative;
  cursor: pointer;
  top: 1px;
`;

// eslint-disable-next-line import/no-unused-modules
export {
  Wrapper,
  ModalWrapper,
  Modal,
  ModalHeader,
  NavigationItem,
  MenuLink,
  MenuItem,
  MenuButton,
  CloseButton,
};
