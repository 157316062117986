import sanitize from 'shared-parts/helpers/html/sanitize';

import { TaglineText } from './tagline.styled';

const Tagline = ({ tagline = {}, isImageExpanded, forceDisplayTagline }) =>
  tagline.content || forceDisplayTagline ? (
    <TaglineText
      dangerouslySetInnerHTML={{ __html: sanitize(tagline.content) }}
      isImageExpanded={isImageExpanded}
    />
  ) : null;

export default Tagline;
