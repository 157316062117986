import * as constants from 'app-state/constants';

import getReducerState from './get-reducer-state';

export const initialCountriesState = getReducerState({ data: [] });

export const countriesReducer = (state = initialCountriesState, action) => {
  switch (action.type) {
    case constants.GET_COUNTRIES_SUCCESS:
      return { loading: false, data: action.data, error: null };
    case constants.GET_COUNTRIES_ERROR:
      return { loading: false, data: [], error: action.error };
    default:
      return state;
  }
};
