import styled from 'styled-components';

import * as Colors from 'shared-parts/constants/colors';
import * as Fonts from 'shared-parts/constants/fonts';
import FormInvalidIcon from 'shared-parts/images/form-invalid.svg';
import FormValidIcon from 'shared-parts/images/form-valid.svg';

const padding = 21;
const lineHeight = 18;
const Container = styled.div`
  background: ${({ isValueValid, touched, hideValidationIcon }) =>
    touched && !hideValidationIcon
      ? `url(${
          isValueValid ? FormValidIcon : FormInvalidIcon
        }) no-repeat scroll calc(100% - 20px) 14px`
      : ''};
  background-size: 20px 20px;
  position: relative;
`;

const getRightPadding = touched => (touched ? '45px' : '21px');

const StyledTextArea = styled.textarea`
  border: ${({ isValueValid, touched }) =>
    (!isValueValid && touched && `1px solid ${Colors.error}`) || `1px solid ${Colors.neutral500}`};
  border-radius: 6px;
  background: ${({ backgroundColor = 'transparent' }) => backgroundColor};
  box-sizing: border-box;
  -moz-box-sizing: border-box;
  -ms-box-sizing: border-box;
  -webkit-box-sizing: border-box;
  -khtml-box-sizing: border-box;
  color: ${({ textColor = Colors.neutral800 }) => textColor};
  display: inline-block;
  font-family: ${Fonts.mainFont};
  font-size: 16px;
  padding: ${padding}px;
  padding-right: ${({ touched }) => getRightPadding(touched)};
  width: 100%;
  line-height: ${lineHeight}px;
  height: ${({ defaultVisibleLines }) => `${2 + defaultVisibleLines * lineHeight + padding * 2}px`};
  resize: ${({ resize }) => resize};
  -webkit-appearance: none;
  :focus-within {
    ${({ isValueValid }) => isValueValid && `border: 1px solid ${Colors.secondary};`}
  }

  &:-webkit-autofill,
  &:-webkit-autofill:hover,
  &:-webkit-autofill:focus {
    box-shadow: 0 0 0px 1000px ${Colors.success100} inset !important;
    -webkit-box-shadow: 0 0 0px 1000px ${Colors.success100} inset !important;
  }

  &:disabled {
    background: ${Colors.neutral500};
    border-radius: 6px;
    height: 48px;
    left: 0;
    width: 100%;

    ::-webkit-input-placeholder {
      color: ${Colors.neutral800};
    }

    &::-moz-placeholder {
      color: ${Colors.neutral800};
    }

    &:-ms-input-placeholder {
      color: ${Colors.neutral800};
    }
  }

  &::-webkit-input-placeholder {
    color: ${Colors.neutral500};
    display: inline-block;
    font-family: ${Fonts.mainFont};
    font-size: 16px;
  }

  &::-moz-placeholder {
    color: ${Colors.neutral500};
    display: inline-block;
    font-family: ${Fonts.mainFont};
    font-size: 16px;
  }

  &:-ms-input-placeholder {
    color: ${Colors.neutral500};
    display: inline-block;
    font-family: ${Fonts.mainFont};
    font-size: 16px;
  }

  ${({ disableAutocomplete }) =>
    disableAutocomplete &&
    `
    &::-webkit-contacts-auto-fill-button, 
    &::-webkit-credentials-auto-fill-button {
      visibility: hidden;
      pointer-events: none;
      position: absolute;
      right: 0;
    }
  `}
`;

export { Container, StyledTextArea };
