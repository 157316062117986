import React, { createContext, FC, useContext } from 'react';

import { useRequest } from '@frontend/http';

import { ApiPlatformSetting, AssetClasses, PlatformSettings } from './types';
import { convertToApiResponseToPlatformSettings } from './utilities';

const platformSettingApi = `/api/v1/platform-settings`;

export type PlatformSettingsContext = PlatformSettings;

export const PlatformSettingsContext = createContext<PlatformSettingsContext | null>(null);

PlatformSettingsContext.displayName = 'PlatformSettingsContext';

export const PlatformSettingsProvider: FC<
  React.PropsWithChildren<{ loader: React.ReactElement; children: unknown }>
> = ({ loader, children }) => {
  const { data, error } = useRequest<ApiPlatformSetting[]>(platformSettingApi);

  if (error) {
    // Let the error propagate to the top level error boundary
    throw new Error('Unable to load platform settings');
  }

  const platformSettings = convertToApiResponseToPlatformSettings(data);
  if (!platformSettings) {
    return loader;
  }

  return (
    <PlatformSettingsContext.Provider value={{ ...platformSettings }}>
      {children}
    </PlatformSettingsContext.Provider>
  );
};

export const usePlatformSettings = (): PlatformSettingsContext => {
  const context = useContext(PlatformSettingsContext);

  if (!context) {
    throw new Error('Platform settings not loaded');
  }

  return context;
};

export const useEnabledAssetClasses = (): AssetClasses => {
  const { assetClasses } = usePlatformSettings();
  if (!assetClasses) {
    return ['equity', 'debt', 'fund'];
  }
  return assetClasses.value;
};

export const useAreEquityAssetsEnabled = (): boolean => {
  const assetClasses = useEnabledAssetClasses();
  return assetClasses.includes('equity');
};

export const useAreDebtAssetsEnabled = (): boolean => {
  const assetClasses = useEnabledAssetClasses();
  return assetClasses.includes('debt');
};

export const useAreFundAssetsEnabled = (): boolean => {
  const assetClasses = useEnabledAssetClasses();
  return assetClasses.includes('fund');
};

export const useIsPlacementEnabled = (): boolean => {
  const { placementEnabled, assetClasses } = usePlatformSettings();
  if (!placementEnabled) {
    return true;
  }
  if (assetClasses.value.length === 0) {
    // No asset classes, no placements.
    return false;
  }
  return placementEnabled.value;
};

export const useIsRegisterEnabled = (): boolean => {
  const { registerEnabled, assetClasses } = usePlatformSettings();
  if (!registerEnabled) {
    return true;
  }
  if (assetClasses.value.length === 0) {
    // No asset classes, no register.
    return false;
  }
  return registerEnabled.value;
};

export const useIsLiquidityEnabled = (): boolean => {
  const { liquidityEnabled, assetClasses } = usePlatformSettings();
  if (!liquidityEnabled) {
    return true;
  }
  if (!assetClasses.value.includes('equity')) {
    // Liquidity only supports equity.
    return false;
  }
  return liquidityEnabled.value;
};
