import * as Colors from 'shared-parts/constants/colors';

const CircleCheckIcon = (width = 70, height = 70, fillColor = Colors.success) => (
  <svg xmlns="http://www.w3.org/2000/svg" width={width} height={height} viewBox="0 0 150 150">
    <path
      fill={fillColor}
      d="M 75,150 A 75,75 0 1 1 150,75 75.221471,75.221471 0 0 1 75,150 Z M 75,13.9264 A 61.073574,61.073574 0 1 0 136.0736,74.9962 61.047297,61.047297 0 0 0 75,13.9264 Z"
    />
    <path
      fill={fillColor}
      d="M 84.6434,38.033 67.5188,91.6066 63.765,78.75 H 48.75 l 10.1802,33.2132 H 76.0736 L 99.1066,38.033 Z"
    />
  </svg>
);

export default CircleCheckIcon;
