import styled from 'styled-components';

import { Button } from 'shared-parts/components/button';
import { accent, neutral800, primary } from 'shared-parts/constants/colors';
import { oswaldFont } from 'shared-parts/constants/fonts';

export const Modal = styled.div`
  border: none;
  max-width: 572px;
  padding: 39px 141px 50px 141px;
  text-align: center;

  &-close-icon {
    cursor: pointer;
    position: absolute;
    right: 20px;
    top: 20px;
    width: 25px;
  }
`;

export const Heading1 = styled.h1`
  color: ${primary};
  font-size: 34px;
  font-family: ${oswaldFont};
  font-weight: 200;
  margin: 15px 0 11px 0;
`;

export const Paragraph = styled.p`
  color: ${neutral800};
  font-size: 16px;
  margin-bottom: 26px;
`;

export const OkButton = styled(Button)`
  &&& {
    background: ${accent} !important;
    font-family: ${oswaldFont};
    font-weight: 100;
    width: 110px;
  }
`;
