import type {
  IndicationOfInterestItem,
  InvestorAnchorStatus,
} from 'app-state/indication-of-interest/types';
import type { RootState } from 'app-state/store';
import type { BaseState } from 'app-state/types';

export const getIndicationOfInterest = (state: RootState): BaseState => state.indicationOfInterest;
export const getInvestorAnchorStatus = (state: RootState): InvestorAnchorStatus =>
  state.investorAnchorStatus;
export const getIndicationOfInterestItem = (
  state: RootState,
): BaseState & {
  data: IndicationOfInterestItem | null;
} => state.indicationOfInterestItem;
