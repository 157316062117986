import { FC } from 'react';
import { connect } from 'react-redux';

import { Form, Formik } from 'formik';
import { Dispatch } from 'redux';
import * as Yup from 'yup';

import { useEnvVar } from '@frontend/config';

import { hideModal } from 'app-state/actions';

import { ExclamationMark } from 'svg-icons';
import { Link } from 'shared';
import { AlertDialog, DialogActions } from 'shared/dialogs';
import { ActionButton, SecondaryActionButton } from 'shared-parts/components/button/action-buttons';
import Checkbox from 'shared-parts/components/checkbox';
import { ModalTitle } from 'shared-parts/components/modal/shared.styled';
import * as Colors from 'shared-parts/constants/colors';

type PlaidUserAgreementDialogProps = ReturnType<typeof mapDispatchToProps> & {
  onAgreement: () => void;
};

const PlaidUserAgreementDialog: FC<React.PropsWithChildren<PlaidUserAgreementDialogProps>> = ({
  close,
  onAgreement,
}) => {
  const { PLATFORM_NAME, CUSTODIAN_NAME = 'Globacap Limited' } = useEnvVar([
    'PLATFORM_NAME',
    'CUSTODIAN_NAME',
  ]);

  return (
    <AlertDialog modalWidth={600}>
      <div>{ExclamationMark('40', '40', Colors.error)}</div>
      <ModalTitle>Initiate Withdrawal</ModalTitle>

      {/* 
          Show an additional message for non-globacap platforms to let them
          know that we are acting on behalf of the platform 
      */}
      {PLATFORM_NAME && !PLATFORM_NAME.toString().includes('Globacap') && (
        <p>
          {CUSTODIAN_NAME} provide regulated custody services on behalf of {PLATFORM_NAME}.
        </p>
      )}

      <p>
        By clicking &lsquo;Proceed&lsquo;, you permit Plaid to securely initiate a payment to your
        bank account from {CUSTODIAN_NAME}.
      </p>

      <Formik
        initialValues={{
          termsAccepted: false,
        }}
        validationSchema={Yup.object({
          termsAccepted: Yup.bool().oneOf([true], 'The terms and conditions must be accepted.'),
        })}
        onSubmit={() => {
          onAgreement();
        }}
      >
        {({ isValid, dirty }) => (
          <Form>
            <Checkbox
              name="termsAccepted"
              text={
                <>
                  {"I agree to Plaid's "}

                  <Link
                    url="https://plaid.com/legal/#end-user-services-agreement-uk"
                    rel="noopener noreferrer"
                    target="_blank"
                  >
                    End User Services Agreement
                  </Link>

                  {' & '}

                  <Link
                    url="https://plaid.com/legal/#end-user-privacy-policy"
                    rel="noopener noreferrer"
                    target="_blank"
                  >
                    Privacy Policy
                  </Link>
                </>
              }
              fontSize="14px"
              thinText
              labelColor={Colors.neutral800}
            />
            <DialogActions>
              <SecondaryActionButton type="button" onClick={close}>
                Cancel
              </SecondaryActionButton>
              <ActionButton disabled={!isValid || !dirty} type="submit">
                Proceed
              </ActionButton>
            </DialogActions>
          </Form>
        )}
      </Formik>
    </AlertDialog>
  );
};

const mapDispatchToProps = (dispatch: Dispatch) => ({
  close: () => dispatch(hideModal()),
});

export default connect(null, mapDispatchToProps)(PlaidUserAgreementDialog);
