export const PLACE_INDICATION_OF_INTEREST = 'PLACE_INDICATION_OF_INTEREST';
export const PLACE_INDICATION_OF_INTEREST_SUCCESS = 'PLACE_INDICATION_OF_INTEREST_SUCCESS';
export const PLACE_INDICATION_OF_INTEREST_ERROR = 'PLACE_INDICATION_OF_INTEREST_ERROR';

export const GET_INVESTOR_ANCHOR_STATUS = 'GET_INVESTOR_ANCHOR_STATUS';
export const GET_INVESTOR_ANCHOR_STATUS_SUCCESS = 'GET_INVESTOR_ANCHOR_STATUS_SUCCESS';
export const GET_INVESTOR_ANCHOR_STATUS_ERROR = 'GET_INVESTOR_ANCHOR_STATUS_ERROR';
export const RESET_INVESTOR_ANCHOR_STATUS = 'RESET_INVESTOR_ANCHOR_STATUS';

export const ADJUST_INDICATION_OF_INTEREST = 'ADJUST_INDICATION_OF_INTEREST';
export const ADJUST_INDICATION_OF_INTEREST_SUCCESS = 'ADJUST_INDICATION_OF_INTEREST_SUCCESS';
export const ADJUST_INDICATION_OF_INTEREST_ERROR = 'ADJUST_INDICATION_OF_INTEREST_ERROR';

export const CANCEL_INDICATION_OF_INTEREST = 'CANCEL_INDICATION_OF_INTEREST';
export const CANCEL_INDICATION_OF_INTEREST_SUCCESS = 'CANCEL_INDICATION_OF_INTEREST_SUCCESS';
export const CANCEL_INDICATION_OF_INTEREST_ERROR = 'CANCEL_INDICATION_OF_INTEREST_ERROR';

export const GET_INDICATION_OF_INTEREST = 'GET_INDICATION_OF_INTEREST';
export const GET_INDICATION_OF_INTEREST_SUCCESS = 'GET_INDICATION_OF_INTEREST_SUCCESS';
export const GET_INDICATION_OF_INTEREST_ERROR = 'GET_INDICATION_OF_INTEREST_ERROR';
export const RESET_INDICATION_OF_INTEREST = 'RESET_INDICATION_OF_INTEREST';
