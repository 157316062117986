import { Fragment, PropsWithChildren, useCallback, useEffect, useState } from 'react';

import Error from 'shared-parts/components/error';
import SearchIcon from 'shared-parts/components/svg-icons/search-icon';

import { ClearButton, Container, Input, Label, SearchButton } from './search-input.styled';
import type { SearchInputProps } from './types';

const SearchInput = ({
  label = null,
  value,
  placeholder = 'Start typing',
  initialValue = '',
  disabled,
  onChange,
  onSearch,
  onClear,
  register: updateValue,
  className,
  isClearVisible,
  children,
  icon: Icon = SearchIcon,
  error,
  ...rest
}: PropsWithChildren<SearchInputProps>): JSX.Element => {
  const [searchValue, setSearchValue] = useState<string>(initialValue);

  useEffect(() => {
    if (searchValue !== value && value !== undefined) setSearchValue(value);
  }, [value]);

  useEffect(() => {
    if (updateValue) {
      updateValue(initialValue);
    }
  }, []);

  const doSearch = () => {
    if (onSearch) {
      onSearch(searchValue);
    }
  };

  const onKeyDown = useCallback(
    ({ keyCode }: any) => {
      if (keyCode === 13) {
        doSearch();
      }
    },
    [onSearch, searchValue],
  );

  const clearSearch = useCallback(() => {
    setSearchValue('');

    if (updateValue) {
      updateValue('');
    }

    if (onClear) {
      onClear();
    }
  }, [onClear]);

  const onSearchValueChange = useCallback(
    (e: any) => {
      const { value: targetValue } = e.target;

      setSearchValue(targetValue);

      if (updateValue) {
        updateValue(targetValue);
      }

      if (onChange) {
        onChange(e);
      }
    },
    [onChange, updateValue, setSearchValue],
  );

  return (
    <Fragment>
      {label && <Label htmlFor="searchName">{label}</Label>}
      <Container className={className}>
        <Input
          type="text"
          name="searchName"
          data-e2e="search-name"
          autoComplete="off"
          placeholder={placeholder}
          value={searchValue}
          disabled={disabled}
          onChange={onSearchValueChange}
          onKeyDown={onKeyDown}
          {...rest}
        />
        <SearchButton disabled={disabled || !onSearch} onClick={doSearch}>
          {Icon()}
        </SearchButton>
        {error && <Error message={error} data-e2e="error-search-name" />}
        {children}
      </Container>
      {isClearVisible && searchValue && (
        <ClearButton onClick={clearSearch} disabled={disabled}>
          Clear
        </ClearButton>
      )}
    </Fragment>
  );
};

export default SearchInput;
