import { createContext, FC, useContext } from 'react';

import { EnvVar, getEnvVar } from '@frontend/config';

import { EnvironmentTypes, FeatureFlag, IAppConfig } from './types';

const isEnabled = (feature: EnvVar): boolean =>
  (typeof feature === 'string' && feature.toLowerCase() === 'true') ||
  (typeof feature === 'boolean' && feature);

const getEnabledFeatureFlags = () =>
  Object.values(FeatureFlag).filter(featureFlagKey => {
    const { config } = window as Window;
    const configValue = config[featureFlagKey];
    return isEnabled(configValue);
  });

const initialContext: IAppConfig = {
  featureFlags: getEnabledFeatureFlags(),
  environment: (window as Window).config.GLOBACAP_ENVIRONMENT as EnvironmentTypes,
  sendbirdId: getEnvVar('SENDBIRD_APP_ID') as string,
};

const AppConfigContext = createContext<IAppConfig>(initialContext);
AppConfigContext.displayName = 'AppConfigContext';

export const AppConfigProvider: FC<React.PropsWithChildren<unknown>> = ({ children }) => (
  <AppConfigContext.Provider value={initialContext}>{children}</AppConfigContext.Provider>
);

export const useAppConfig = (): IAppConfig => useContext(AppConfigContext);
export const useFeatureFlag = (flag: FeatureFlag): boolean => {
  const featureFlags = getEnabledFeatureFlags();
  return featureFlags.includes(flag);
};
