import * as constants from 'app-state/constants';

export const changeOnboardingState = newState => ({
  type: constants.CHANGE_ONBOARDING_STATE,
  newState,
});

export const savePersonalData = (params, resolve, reject) => ({
  type: constants.SAVE_PERSONAL_DATA,
  params,
  resolve,
  reject,
});
export const savePersonalDataSuccess = data => ({
  type: constants.SAVE_PERSONAL_DATA_SUCCESS,
  data,
});
export const savePersonalDataError = error => ({ type: constants.SAVE_PERSONAL_DATA_ERROR, error });

export const saveInvestorType = (formData, redirectUrl) => ({
  type: constants.SAVE_INVESTOR_TYPE,
  formData,
  redirectUrl,
});
export const saveInvestorTypeSuccess = data => ({
  type: constants.SAVE_INVESTOR_TYPE_SUCCESS,
  data,
});
export const saveInvestorTypeError = error => ({ type: constants.SAVE_INVESTOR_TYPE_ERROR, error });

export const saveInvestorCountry = (countryCode, isFirstStep) => ({
  type: constants.SAVE_INVESTOR_COUNTRY,
  countryCode,
  isFirstStep,
});

export const getIndividualInvestorTypes = countryCode => ({
  type: constants.GET_INDIVIDUAL_INVESTOR_TYPES,
  countryCode,
});
export const getIndividualInvestorTypesSuccess = data => ({
  type: constants.GET_INDIVIDUAL_INVESTOR_TYPES_SUCCESS,
  data,
});
export const getIndividualInvestorTypesError = error => ({
  type: constants.GET_INDIVIDUAL_INVESTOR_TYPES_ERROR,
  error,
});

export const getSupportedCountries = () => ({ type: constants.GET_SUPPORTED_COUNTRIES });
export const getSupportedCountriesSuccess = supportedCountries => ({
  type: constants.GET_SUPPORTED_COUNTRIES_SUCCESS,
  supportedCountries,
});
export const getSupportedCountriesError = error => ({
  type: constants.GET_SUPPORTED_COUNTRIES_ERROR,
  error,
});

export const getUnsupportedCountries = () => ({ type: constants.GET_UNSUPPORTED_COUNTRIES });
export const getUnsupportedCountriesSuccess = unsupportedCountries => ({
  type: constants.GET_UNSUPPORTED_COUNTRIES_SUCCESS,
  unsupportedCountries,
});
export const getUnsupportedCountriesError = error => ({
  type: constants.GET_UNSUPPORTED_COUNTRIES_ERROR,
  error,
});
