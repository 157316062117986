import { DialogContent as MuiDialogContent, dialogTitleClasses, styled } from '@mui/material';

export const DialogContent = styled(MuiDialogContent)(({ theme }) => ({
  ...theme.typography.body1,
  padding: theme.spacing(4, 6, 0),

  // Reset the nullification of padding when this child is next to a title
  // This guy https://github.com/mui/material-ui/blob/c6c0b463405be88a843e3a152f6c975c55d81c5f/packages/mui-material/src/DialogContent/DialogContent.js#L47C39-L47C42
  [`.${dialogTitleClasses.root} + &`]: {
    paddingTop: theme.spacing(4),
  },
}));
