import { generateTotalRow } from '../../helpers';
import { TableRow, totalRowStyles } from '../table.styled';

import renderCell from './cell';

const TotalRow = ({ data, keys, totalRowData, fullWidth }) => {
  if (!totalRowData || !data.length) return null;

  const cells = generateTotalRow({ keys, totalRowData, fullWidth });

  return (
    <TableRow rowStyles={totalRowStyles}>
      {cells.map(renderCell({ isHTMLAllowed: false }))}
    </TableRow>
  );
};

export default TotalRow;
