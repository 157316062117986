import SuccessModal from 'shared-parts/components/modal/success-modal';

import {
  ADJUST_SUCCESS_MODAL_TEXT,
  ADJUST_SUCCESS_MODAL_TITLE,
  MAIN_BUTTON_TITLE,
  SUCCESS_MODAL_BUTTON_TEXT,
  SUCCESS_MODAL_TEXT,
  SUCCESS_MODAL_TITLE,
} from './constants';
import Modal from './modal';

export const FORM_MODAL_OPTIONS = {
  header: MAIN_BUTTON_TITLE,
  showHeader: true,
  closable: true,
  component: Modal,
};

export const SUCCESS_MODAL_OPTIONS = {
  closable: true,
  title: SUCCESS_MODAL_TITLE,
  additionalText: SUCCESS_MODAL_TEXT,
  buttonText: SUCCESS_MODAL_BUTTON_TEXT,
  modalWidth: 600,
  iconSize: 40,
  messageMaxWidth: '300px',
  component: SuccessModal,
};

export const ADJUST_SUCCESS_MODAL_OPTIONS = {
  closable: true,
  title: ADJUST_SUCCESS_MODAL_TITLE,
  additionalText: ADJUST_SUCCESS_MODAL_TEXT,
  buttonText: SUCCESS_MODAL_BUTTON_TEXT,
  modalWidth: 600,
  iconSize: 40,
  messageMaxWidth: '300px',
  component: SuccessModal,
};
