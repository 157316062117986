import { push } from 'connected-react-router';

import { call, put, takeEvery } from 'redux-saga/effects';

import * as actions from 'app-state/actions';
import * as constants from 'app-state/constants';

import API from 'constants/api';
import Routes from 'constants/routes';
import { request } from 'shared-parts/helpers';

export function* fetchCompany({ companyUuid }) {
  try {
    const response = yield call(request, API.Companies.company(companyUuid));

    yield put(actions.fetchCompanySuccess(response.data));
    return yield response;
  } catch (e) {
    yield put(actions.fetchCompanyError(e));

    if (e.status === 403) {
      yield put(push(Routes.Root()));
    }
  }
}

export function* fetchCompanyWatcher() {
  yield takeEvery(constants.FETCH_COMPANY, fetchCompany);
}

export function* fetchCompanies() {
  try {
    const { data } = yield call(request, API.Companies.list());

    yield put(actions.fetchCompaniesSuccess(data));
  } catch (e) {
    yield put(actions.fetchCompaniesError(e));
  }
}

export function* fetchCompaniesWatcher() {
  yield takeEvery(constants.FETCH_COMPANIES, fetchCompanies);
}

export function* fetchCompanyGroups() {
  try {
    const { data } = yield call(request, API.Investors.companyGroups());

    yield put(actions.fetchCompanyGroupsSuccess(data.items));
  } catch (e) {
    yield put(actions.fetchCompanyGroupsError(e));
  }
}

export function* fetchCompanyGroupsWatcher() {
  yield takeEvery(constants.FETCH_COMPANY_GROUPS, fetchCompanyGroups);
}

function* getCompanySummary({ companyIssuerId }) {
  try {
    const { data } = yield call(request, API.CompanySummary.Get(companyIssuerId), 'GET');

    yield put(actions.getCompanySummarySuccess(data));
  } catch (e) {
    yield put(actions.getCompanySummaryError(e));
  }
}

export function* getCompanySummaryWatcher() {
  yield takeEvery(constants.GET_COMPANY_SUMMARY, getCompanySummary);
}
