import * as constants from 'app-state/constants';

export const fetchNavigation = (companyUuid, modelUuid) => ({
  type: constants.FETCH_NAVIGATION,
  companyUuid,
  modelUuid,
});
export const fetchNavigationSuccess = navigationList => ({
  type: constants.FETCH_NAVIGATION_SUCCESS,
  navigationList,
});
export const fetchNavigationError = error => ({
  type: constants.FETCH_NAVIGATION_ERROR,
  error,
});
export const resetNavigation = () => ({ type: constants.RESET_NAVIGATION });

export const resetSubnavigation = () => ({ type: constants.RESET_SUB_NAVIGATION });
export const fetchSubNavigation = navigationItemId => ({
  type: constants.FETCH_SUB_NAVIGATION,
  navigationItemId,
});
export const fetchSubNavigationSuccess = items => ({
  type: constants.FETCH_SUB_NAVIGATION_SUCCESS,
  items,
});
export const fetchSubNavigationError = error => ({
  type: constants.FETCH_SUB_NAVIGATION_ERROR,
  error,
});
