import { useDispatch } from 'react-redux';

import { placeIndicationOfInterest } from 'app-state/indication-of-interest/actions';
import { getIndicationOfInterest } from 'app-state/indication-of-interest/selectors';
import type { IndicationOfInterest } from 'app-state/indication-of-interest/types';
import { getDeal } from 'app-state/selectors/deal';

import useTypedSelector from 'hooks/use-typed-selector';

import PlaceForm from './place-form/wrapper';
import { FORM_MODAL_HEADER } from './constants';
import { ModalContainer, Section, Title } from './styled';

const Modal = (): JSX.Element => {
  const { data } = useTypedSelector(getDeal);
  const dispatch = useDispatch();
  const { loading } = useTypedSelector(getIndicationOfInterest);

  const handleFormSubmit = (values: IndicationOfInterest) => {
    dispatch(
      placeIndicationOfInterest({
        ...values,
        issuanceId: data.id,
      }),
    );
  };

  return (
    <ModalContainer>
      <Section>
        <Title>{FORM_MODAL_HEADER}</Title>
      </Section>
      <PlaceForm
        currency={data?.baseCurrency}
        handleFormSubmit={handleFormSubmit}
        isLoading={loading}
      />
    </ModalContainer>
  );
};

export default Modal;
