import { connect } from 'react-redux';

import { useLoggerContext } from '@frontend/analytics';
import { useEnvVar } from '@frontend/config';

import { hideModal } from 'app-state/actions';

import { ErrorOutline } from 'svg-icons';
import MailLink from 'shared/mail-link';
import { ErrorWrapper } from 'shared-parts/constants';

import {
  ButtonContainer,
  CloseButton,
  ModalIconContainer,
  ModalText,
  ModalTitle,
  ModalWrapper,
  SubmitButton,
} from './modal-failed.styled';

const smtpSupportEmail = useEnvVar('SMTP_SUPPORT_EMAIL');

export const errorMessage = emailBody => (
  <ErrorWrapper>
    Please try again. If the issue persists please contact
    <MailLink mail={smtpSupportEmail} text={smtpSupportEmail} body={emailBody} /> via email.
  </ErrorWrapper>
);

const ModalFailed = ({ close, modalWidth }) => {
  const loggerContext = useLoggerContext();
  const sessionId = loggerContext?.getInternalContext()?.session_id;
  const emailBody = sessionId
    ? `Session ID : ${sessionId} %0D%0A Please do not delete the above details`
    : '';
  return (
    <ModalWrapper modalWidth={modalWidth}>
      <ModalIconContainer>{ErrorOutline()}</ModalIconContainer>
      <ModalTitle>Something Went Wrong</ModalTitle>
      <ModalText>{errorMessage(emailBody)}</ModalText>
      <ButtonContainer>
        <CloseButton onClick={close}>Close</CloseButton>
        <MailLink
          mail={smtpSupportEmail}
          body={emailBody}
          text={<SubmitButton>Report</SubmitButton>}
        />
      </ButtonContainer>
    </ModalWrapper>
  );
};

const mapDispatchToProps = dispatch => ({
  close: () => dispatch(hideModal()),
});

ModalFailed.defaultProps = {
  modalWidth: '580',
  close: Function,
};

export default connect(null, mapDispatchToProps)(ModalFailed);
