import * as constants from './constants';

export const fetchInvestorCompanies = () => ({ type: constants.FETCH_INVESTOR_COMPANIES });
export const fetchInvestorCompaniesSuccess = data => ({
  type: constants.FETCH_INVESTOR_COMPANIES_SUCCESS,
  data,
});
export const fetchInvestorCompaniesError = error => ({
  type: constants.FETCH_INVESTOR_COMPANIES_ERROR,
  error,
});
export const resetInvestorCompanies = () => ({ type: constants.RESET_INVESTOR_COMPANIES });

export const updateInvestorCompaniesPermission = data => ({
  type: constants.UPDATE_INVESTOR_COMPANIES_PERMISSION,
  data,
});
export const updateInvestorCompaniesPermissionSuccess = () => ({
  type: constants.UPDATE_INVESTOR_COMPANIES_PERMISSION_SUCCESS,
});
export const updateInvestorCompaniesPermissionError = error => ({
  type: constants.UPDATE_INVESTOR_COMPANIES_PERMISSION_ERROR,
  error,
});
