import {
  accordionClasses,
  AccordionSummary as MuiAccordionSummary,
  AccordionSummaryProps,
  styled,
} from '@mui/material';

export const AccordionSummary = styled((props: AccordionSummaryProps) => (
  <MuiAccordionSummary aria-controls="panel-content" {...props} />
))(({ theme }) => ({
  padding: '0',
  display: 'flex',
  alignItems: 'flex-start',

  [`& .${accordionClasses.expanded}`]: {
    transform: 'none !important',
    translate: 'unset !important',
  },

  '&.MuiButtonBase-root': {
    '.MuiAccordionSummary-content': {
      margin: '0',
    },
  },

  '& .MuiAccordionSummary-expandIconWrapper': {
    color: theme.palette.neutral[600],
    fontFamily: theme.typography.fontFamily,
    fontStyle: 'normal',
    fontWeight: theme.typography.button.fontWeight,

    '& .MuiBox-root': {
      justifyContent: 'center',
      display: 'flex',
    },
  },
}));
