import { forwardRef, PropsWithChildren } from 'react';

import { Paper, PaperProps } from '@mui/material';
import { styled } from '@mui/material/styles';

export type StatListProps = PropsWithChildren<PaperProps>;

type StatListOwnerState = StatListProps;

const StatListRoot = styled(Paper, {
  name: 'MuiStatList',
  slot: 'root',
})<{ ownerState: StatListOwnerState }>(({ theme }) => ({
  padding: theme.spacing(6, 8),
  display: 'inline-flex',
  flexWrap: 'wrap',
  gap: theme.spacing(6),
}));

export const StatList = forwardRef<HTMLDivElement, StatListProps>((props, ref) => {
  const { children, ...rest } = props;

  return (
    <StatListRoot ref={ref} ownerState={props} {...rest}>
      {children}
    </StatListRoot>
  );
});
