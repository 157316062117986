import { cellStyles } from './box-table.styled';
import type { ItemProps } from './index';

const formatDealTableData = (header: ItemProps[] = [], data: ItemProps[][]) => {
  const tableData = data.map(row => ({
    cells: row.map((cell: ItemProps) => ({ text: cell, styles: () => cellStyles })),
  }));

  return header.length
    ? [{ cells: header.map(({ text }) => ({ text, styles: () => cellStyles })) }, ...tableData]
    : tableData;
};

export { formatDealTableData }; // eslint-disable-line
