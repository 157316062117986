import { ButtonHTMLAttributes, ElementType, FC } from 'react';

import { IconButtonContainer, IconButtonContainerProps } from './icon-button.styled';

export interface IconButtonProps
  extends Omit<ButtonHTMLAttributes<HTMLButtonElement>, 'color'>,
    IconButtonContainerProps {
  StartIcon?: ElementType;
  EndIcon?: ElementType;
}

export const IconButton: FC<React.PropsWithChildren<IconButtonProps>> = ({
  children,
  StartIcon,
  EndIcon,
  ...rest
}) => (
  <IconButtonContainer {...rest}>
    {StartIcon && <StartIcon />}
    {children}
    {EndIcon && <EndIcon />}
  </IconButtonContainer>
);
