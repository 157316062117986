import {
  DialogTitle as MuiDialogTitle,
  DialogTitleProps as MuiDialogTitleProps,
  styled,
} from '@mui/material';

export type DialogTitleProps = MuiDialogTitleProps;

export const DialogTitle = styled(MuiDialogTitle)(({ theme }) => ({
  padding: theme.spacing(0, 6),
}));
