export const SELECT_INVESTOR_TYPE = 'SELECT_INVESTOR_TYPE';
export const SETUP_SUMSUB_CONFIG = 'SETUP_SUMSUB_CONFIG';
export const SETUP_SUMSUB_CONFIG_SUCCESS = 'SETUP_SUMSUB_CONFIG_SUCCESS';
export const SETUP_SUMSUB_CONFIG_ERROR = 'SETUP_SUMSUB_CONFIG_ERROR';
export const RESET_SUMSUB_CONFIG = 'RESET_SUMSUB_CONFIG';
export const ACCEPT_TERMS_AND_CONDITIONS = 'ACCEPT_TERMS_AND_CONDITIONS';
export const ACCEPT_TERMS_AND_CONDITIONS_ERROR = 'ACCEPT_TERMS_AND_CONDITIONS_ERROR';

export const SAVE_APPLICANT_ID = 'SAVE_APPLICANT_ID';
export const SAVE_APPLICANT_ID_ERROR = 'SAVE_APPLICANT_ID_ERROR';

export const COMPLETE_KYC = 'COMPLETE_KYC';
export const COMPLETE_KYC_ERROR = 'COMPLETE_KYC_ERROR';

export const CUSTOM_VERIFY = 'CUSTOM_VERIFY';
