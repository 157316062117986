import { FC, ReactNode, useEffect, useState } from 'react';

import { Box, SelectChangeEvent, SelectProps, Typography, useTheme } from '@mui/material';

import { ChevronDown, ChevronUp } from '../../icons';

import { MenuItem } from './components';
import { StyledSelect } from './select.styled';
import { SelectComponentOption } from './types';

// MUI offers Select (equivalent to native select) and Autocomplete (equivalent to native input with datalist)
// this is a wrapper for Select
// https://mui.com/material-ui/react-select/
// https://mui.com/material-ui/react-autocomplete/#customized-hook

interface SelectComponentProps extends Omit<SelectProps, 'defaultValue' | 'children'> {
  options: SelectComponentOption[];
  defaultValue?: string[];
  /**
   * Exposes a render function to allow for custom rendering of the options when the Select is open
   */
  renderItem?: (option: SelectComponentOption, selected: boolean) => ReactNode;
  renderTopSection?: () => ReactNode;
  renderBottomSection?: () => ReactNode;
}

/**
 * SelectComponent: A wrapper for MUI Select
 *
 * https://mui.com/material-ui/react-select/
 */
const SelectComponent: FC<SelectComponentProps> = ({
  options,
  renderItem,
  renderTopSection,
  renderBottomSection,
  ...selectProps
}: SelectComponentProps) => {
  const theme = useTheme();
  const [selectedOptions, setSelectedOptions] = useState<SelectComponentOption['value'][]>(
    typeof selectProps.defaultValue === 'string'
      ? [String(selectProps.defaultValue)]
      : selectProps.defaultValue || [],
  );

  // allow both controlled and uncontrolled use of the Open state
  const [isOpen, setIsOpen] = useState<boolean>(!!selectProps.open);
  useEffect(() => {
    if (selectProps.open !== undefined) setIsOpen(selectProps.open);
  }, [selectProps.open]);

  /**
   * A default handleChange function for Select
   * an onChange function passed to the component will run after this.
   *
   * `selectedOptions` must be an array of strings - we enforce that here.
   */
  const handleChange = (event: SelectChangeEvent<unknown>) => {
    const {
      target: { value },
    } = event;
    if (typeof value === 'string') {
      setSelectedOptions(value.split(','));
    } else if (
      Array.isArray(value) &&
      value.every(item => typeof item === 'string' || typeof item === 'number')
    ) {
      setSelectedOptions(value);
    }
  };

  /**
   * A default renderValue function for Select
   */
  const renderValue = (value: unknown): React.ReactNode => {
    if (
      Array.isArray(value) &&
      value.every(item => typeof item === 'string' || typeof item === 'number')
    ) {
      if (value.join().length === 0 && selectProps.placeholder) {
        return <Typography className="placeholder">{selectProps.placeholder}</Typography>;
      }
      return value
        .map(val => options.find((option: SelectComponentOption) => option.value === val)?.label)
        .join(', ');
    }
    return null;
  };

  useEffect(() => {
    /**
     * Modify selectedOptions when selectProps.value changes
     * i.e. a parent component is controlling the value
     * (We use this for SelectAll)
     * @returns {string[]} - array of selected options
     */
    const formatSelectedOptions = () => {
      if (typeof selectProps.value === 'string') {
        return [selectProps.value];
      }
      if (Array.isArray(selectProps.value)) {
        return selectProps.value;
      }
      return [];
    };
    if (selectProps.value) {
      setSelectedOptions(formatSelectedOptions());
    }
  }, [selectProps.value]);

  return (
    <StyledSelect
      {...selectProps}
      displayEmpty
      IconComponent={() => (
        <Box
          sx={{
            pointerEvents: 'none',
            position: 'absolute',
            right: '20px',
            color: theme.palette.neutral[400],
          }}
        >
          {isOpen ? <ChevronUp width={13} /> : <ChevronDown width={13} />}
        </Box>
      )}
      value={selectedOptions}
      onChange={(e, node) => {
        handleChange(e);
        selectProps.onChange?.(e, node);
      }}
      open={isOpen}
      onOpen={e => {
        setIsOpen(true);
        selectProps.onOpen?.(e);
      }}
      onClose={e => {
        setIsOpen(false);
        selectProps.onClose?.(e);
      }}
      renderValue={selectProps.renderValue || renderValue}
    >
      {renderTopSection && (
        <Box position="sticky" top="0px" zIndex="1" bgcolor={theme.palette.neutral[0]}>
          {renderTopSection()}
        </Box>
      )}

      {options?.map((option: SelectComponentOption) => (
        <MenuItem key={option.value} value={option.value} disabled={option.disabled}>
          {renderItem
            ? renderItem(option, selectedOptions.indexOf(option.value) > -1)
            : option.label}
        </MenuItem>
      ))}

      {renderBottomSection && (
        <Box position="sticky" bottom="0px" zIndex="1" bgcolor={theme.palette.neutral[0]}>
          {renderBottomSection()}
        </Box>
      )}
    </StyledSelect>
  );
};

export { SelectComponent as Select, SelectComponentProps as SelectProps };
