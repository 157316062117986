import type { FC } from 'react';

import { styled, SvgIcon } from '@mui/material';
import MuiRadio, { RadioProps as MuiRadioProps } from '@mui/material/Radio';

export type RadioProps = MuiRadioProps;

const IconContainer: FC<React.PropsWithChildren<unknown>> = ({ children }) => (
  <SvgIcon viewBox="0 0 20 20" fontSize="inherit">
    {children}
  </SvgIcon>
);

export const Radio = styled((props: RadioProps) => (
  <MuiRadio
    icon={
      <IconContainer>
        <rect x="2" y="2" width="16" height="16" rx="8" />
      </IconContainer>
    }
    checkedIcon={
      <IconContainer>
        <rect x="2" y="2" width="16" height="16" rx="8" />
        <circle cx="10" cy="10" r="3" />
      </IconContainer>
    }
    {...props}
  />
))(({ theme }) => {
  const {
    neutral,
    common: { white },
  } = theme.palette;

  return {
    fontSize: '20px',
    padding: `${theme.spacing(1)} ${theme.spacing(2)}`,

    '& + span': {
      fontStyle: 'normal',
    },

    '&:hover': {
      backgroundColor: 'transparent',
    },

    '&:not(.Mui-disabled)': {
      rect: {
        fill: white,
      },

      '& + span': {
        color: neutral[600],
      },

      '&.Mui-checked': {
        stroke: neutral[700],

        '& circle': {
          color: neutral[700],
        },

        '& + span': {
          fontWeight: 700,
        },
      },

      '&:not(.Mui-checked)': {
        stroke: neutral[300],

        '& + span': {
          fontWeight: theme.typography.fontWeightMedium,
        },
      },
    },

    '&.Mui-disabled': {
      color: neutral[0],
      stroke: neutral[200],

      '&.Mui-checked': {
        '& circle': {
          color: neutral[200],
        },
        '& + span': {
          fontWeight: 700,
          color: neutral[300],
        },
      },

      '&:not(.Mui-checked)': {
        '& + span': {
          fontWeight: theme.typography.fontWeightMedium,
          color: neutral[300],
        },
      },
    },
  };
});
