import styled from 'styled-components';

import { Screens } from 'shared-parts/constants/screens';
import * as Colors from 'shared-parts/constants/white/colors';
import { neutral800 } from 'shared-parts/constants/white/colors';

export const HeaderContainer = styled.section`
  position: relative;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;

  @media (max-width: ${Screens.sm}) {
    flex-direction: column;
  }

  margin: 0 auto 19px;
  padding: 43px 30px;
  border: 1px solid ${Colors.neutral400};
  border-radius: 8px;
  background-color: ${Colors.white};
  box-shadow: 0px 3px 6px ${Colors.shadowColor};
  border-bottom: 1px solid ${Colors.neutral400};
`;

export const AgentTag = styled.span`
  position: absolute;
  border-radius: 0 0 5px 5px;
  background-color: ${Colors.primary};
  color: ${Colors.primaryContrastText};
  font: normal normal normal 14px/24px Arial;
  top: 0;
  left: 30px;
  padding: 5px 16px;
`;

export const LogoAgentName = styled.div`
  flex: 0.375;
  min-height: 66px;
  padding-bottom: 3px;
  padding-top: 3px;
  width: 100%;
  border-right: 1px solid ${Colors.neutral500};
  margin-right: 15px;
  padding-right: 15px;
  position: relative;
  @media (max-width: ${Screens.sm}) {
    margin: 0 10px 15px 10px;
    padding: 15px 0;
    width: 100%;
    border: none;
    :after {
      content: '';
      position: absolute;
      left: 50%;
      transform: translateX(-50%);
      bottom: 0;
      height: 1px;
      width: 90%;
      border-bottom: 1px solid ${Colors.neutral500};
    }
  }

  display: flex;
  align-items: center;
  justify-content: flex-start;
  h1 {
    // override quill styles
    color: ${Colors.primary};
    font-size: 25px;
    font-weight: 300;
    line-height: 25px;
    text-transform: uppercase;
    margin: 0 !important;
    @media (max-width: ${Screens.xl}) {
      font-size: 22px !important;
    }
    @media (max-width: ${Screens.md}) {
      font-size: 18px !important;
    }
  }
`;
export const LogoContainer = styled.div`
  padding: 2px;
  border-radius: 50%;
  border: 1px solid ${Colors.white};
  background: ${Colors.white};
  filter: drop-shadow(1px 3px 3px #00000040);
  overflow: hidden;
  max-width: 65px;
  max-height: 65px;
  min-width: 65px;
  min-height: 65px;
  margin-right: 15px;

  img {
    object-fit: cover;
  }
`;
export const AgentName = styled.div`
  div {
    display: flex;
    align-items: flex-end;
    h1 {
      margin: 0;
    }
  }
`;

export const InfoGrid = styled.div`
  flex: 0.625;
  display: flex;
  justify-content: space-between;

  @media (max-width: ${Screens.lg}) {
    flex-wrap: wrap;
  }
  @media (min-width: ${Screens.xl}) {
    justify-content: space-around;
  }
  @media (max-width: ${Screens.sm}) {
    flex: 1;
  }
`;

export const Cell = styled.div`
  :not(:last-child) {
    margin-right: 20px;
  }
`;

export const CellTitle = styled.label`
  text-transform: capitalize;
  color: ${neutral800};
  white-space: nowrap;
  font: normal normal normal 14px/24px Arial;
`;

export const CellInfo = styled.p`
  color: ${neutral800};
  white-space: normal;
  font: normal normal bold 24px/28px Arial;
  @media (max-width: ${Screens.xl}) {
    font: normal normal bold 20px/28px Arial;
  }
  @media (max-width: ${Screens.md}) {
    font: normal normal bold 16px/28px Arial;
  }
`;
