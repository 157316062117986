import 'styles/application.sass'; // should be above imported components to prevent styles overriding

import { createRoot } from 'react-dom/client';
import { sessionService } from 'redux-react-session';

import { ThemeProvider } from 'styled-components';
import DefaultTheme from 'theme';

import { initializeOpenFeature, OpenFeatureProvider } from '@frontend/config';
import { LicenseInfo, ThemeProvider as MuiThemeProvider } from '@frontend/design-system';

import store from 'app-state/store';

import { theme } from './branding';
import GlobalStyle from './global-style';
import Router from './router';

if (typeof window.config.DATA_GRID_LICENSE_KEY === 'string') {
  LicenseInfo.setLicenseKey(window.config.DATA_GRID_LICENSE_KEY);
}

initializeOpenFeature();

function renderWebsiteContent(): void {
  const app = document.getElementById('content');

  if (!app) {
    throw new Error('Could not find content root.');
  }

  const root = createRoot(app);
  root.render(
    <ThemeProvider theme={DefaultTheme}>
      <MuiThemeProvider theme={theme}>
        <OpenFeatureProvider>
          <GlobalStyle />
          <Router />
        </OpenFeatureProvider>
      </MuiThemeProvider>
    </ThemeProvider>,
  );
}

sessionService.initSessionService(store).then(renderWebsiteContent);
