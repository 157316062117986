export default {
  Root: () => '/',
  Login: () => '/login',
  SignUp: () => '/sign-up',
  ResetPassword: (token = ':token') => `/reset-password/${token}`,
  ForgotPassword: () => '/forgot-password',
  CreatePassword: (token = ':token') => `/create-password/${token}`,
  Account: {
    Root: () => '/account',
    Details: () => '/account/details',
    Preferences: () => '/account/preferences',
    Chat: () => '/account/chat',
    AgentPermissions: () => '/account/agent-permissions',
    Security: () => '/account/security',
    Notifications: (page = '/:page?', perPage = '/:perPage?') =>
      `/account/notifications${page}${perPage}`,
    Notification: (communicationId = '/:communicationId') =>
      `/account/notifications${communicationId}`,
  },
  Transactions: {
    Root: () => '/transactions',
    Orders: () => '/transactions/orders',
    Order: (orderId = ':orderId', tab) => {
      // Quick hacks for setting the right tab to display on orders page
      if (!tab) {
        return `/transactions/orders/${orderId}`;
      }

      return `/transactions/orders/${orderId}?tab=${tab}`;
    },
    SecondaryTransactions: () => '/transactions/secondary',
    SecondaryTransaction: (transactionUuid = ':transactionUuid') =>
      `/transactions/secondary/${transactionUuid}`,
    Cash: (fundsType = '/:fundsType?', page = '/:page?', perPage = '/:perPage?') =>
      `/transactions/wallet${fundsType}${page}${perPage}`,
    LiquidityOrder: (typeOfOrder = ':typeOfOrder?', uuid = ':uuid?') =>
      `/transactions/liquidity-orders/${typeOfOrder}/${uuid}`,
    LiquidityOrders: () => `/transactions/liquidity-orders/`,
  },
  Deals: () => '/deals',
  Deal: (root => ({
    Root: () => root,
    Page: (
      permalink = ':permalink',
      navigationURL = '/:navigationURL?',
      subNavigationURL = '/:subNavigationURL?',
    ) => `${root}${permalink}${navigationURL}${subNavigationURL}`,
  }))('/deals/'),
  LiquidityRounds: () => '/liquidity',
  LiquidityRound: () => `/liquidity/:uuid/:pageId?`,
  Holdings: {
    Root: () => '/holdings',
    Investments: (companyId = null, shareClasses = []) => {
      const query =
        companyId && shareClasses.length > 0
          ? `?companyId=${companyId}&shareClasses=${shareClasses.join(',')}`
          : ``;
      return `/holdings/investments${query}`;
    },
    ShareOptions: (page = '/:page?', perPage = '/:perPage?') =>
      `/holdings/share-options${page}${perPage}`,
    ShareOption: (grantId = ':grantId') => `/holdings/share-options/${grantId}`,
    Cash: () => '/holdings/cash',
    EmployeeSharePlan: () => '/holdings/employee-share-plan',
    EmployeeSharePlanDetails: (planId = ':planId') => `/holdings/employee-share-plan/${planId}`,
    Overview: () => '/holdings/overview',
    Dashboard: () => '/holdings/dashboard',
    Transactions: () => '/holdings/transactions',
  },
  ConfirmEmail: (emailToken = ':emailToken') => `/confirm-email/${emailToken}`,
  Onboarding: {
    Root: () => '/onboarding',
    SelectInvestorType: () => '/onboarding/select-type',
    AmlVerification: () => '/onboarding/aml-verification',
    TermsAndConditions: () => '/onboarding/terms-and-conditions',
    InvestmentPreferences: () => '/onboarding/preferences',
    Individual: {
      AccountInformationFirstPart: () => '/onboarding/individual/account-information-first-part',
      AccountInformationSecondPart: () => '/onboarding/individual/account-information-second-part',
      SuitabilityTest: () => '/onboarding/individual/suitability-test',
    },
    Institutional: {
      Root: () => '/onboarding/institutional',
      AccountInformation: () => '/onboarding/institutional/account-information',
      AgentPermissions: () => '/onboarding/institutional/agent-permissions',
      Completed: () => '/onboarding/institutional/completed',
      AccountInformationPreview: () => '/onboarding/institutional/account-information-preview',
      UserAgreements: () => '/onboarding/institutional/user-agreements',
    },
  },
  NotFound: () => '/not-found',
};
