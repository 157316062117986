import { ReactNode } from 'react';

import { Typography } from '@mui/material';

export const StepOptionalLabel = ({ children }: { children: ReactNode }) => {
  return (
    <Typography variant="body2" component="span">
      {children}
    </Typography>
  );
};
