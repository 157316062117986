// 'en' (USA) is the default locale for dayjs
// we have imported 'en-gb' (UK) for default platform.
// add more locales as needed by importing them here
import 'dayjs/locale/en';

import dayjs from 'dayjs';
import enGbLocale from 'dayjs/locale/en-gb';
import customParseFormat from 'dayjs/plugin/customParseFormat';
import localizedFormat from 'dayjs/plugin/localizedFormat';
import timezone from 'dayjs/plugin/timezone';
import updateLocale from 'dayjs/plugin/updateLocale';
import utc from 'dayjs/plugin/utc';

import { getEnvVar } from '@frontend/config';

dayjs.extend(timezone);
dayjs.extend(utc);
dayjs.extend(updateLocale);
dayjs.extend(localizedFormat);
dayjs.extend(customParseFormat);

dayjs.updateLocale('en-gb', {
  ...enGbLocale,
  formats: {
    ...enGbLocale.formats,
    L: 'DD-MM-YYYY', // use hyphens to resemble ISO 8601 (ordered hierarchy)
  },
});

dayjs.tz.setDefault(dayjs.tz.guess() || (getEnvVar('DEFAULT_TIMEZONE', 'Europe/London') as string));

// `dayjs.locale(myLocale)` fails (but doesn't throw) if myLocale has not been imported
// so we fall back to env's DEFAULT_LOCALE and failing that 'en-gb' if the locale is not there.
// order of precedence:
// navigator.language -> base language -> DEFAULT_LOCALE env var -> 'en-gb'
// e.g. 'de-de' -> 'de' -> undefined -> 'en-gb'
dayjs.locale(getEnvVar('DEFAULT_LOCALE', 'en-gb') as string);
dayjs.locale(navigator.language.split(/[-_]/)[0]); // fall back to base language
dayjs.locale(navigator.language.toLowerCase());

// dayjs is a commonjs module.
// if you want something re-exported from it, you must explicitly add it here.
export default dayjs;
export type { Dayjs } from 'dayjs';
export { formatDate, LocalisedDateFormat, LDF } from './helpers';
export { default as useTimezones } from './use-timezones';
