const FIELD_NAMES = {
  REFERENCE: 'reference',
  ACCOUNT_NUMBER: 'accountNumber',
  ACCOUNT_NAME: 'accountName',
  SWIFT_OR_BIC: 'swiftBic',
  IBAN: 'iban',
  ABA_NUMBER: 'abaNumber',
  BSB_ACCOUNT_NUMBER: 'bsbAccountNumber',
  SORT_CODE: 'sortCode',
  AMOUNT: 'amount',
  WALLETS_DEPOSIT: 'walletsDeposit',
};

export const BANK_FIELD_LABELS = {
  [FIELD_NAMES.REFERENCE]: 'USE THIS REFERENCE',
  [FIELD_NAMES.ACCOUNT_NUMBER]: 'ACCOUNT NUMBER',
  [FIELD_NAMES.ACCOUNT_NAME]: 'ACCOUNT NAME',
  [FIELD_NAMES.SWIFT_OR_BIC]: 'SWIFT/BIC',
  [FIELD_NAMES.IBAN]: 'IBAN',
  [FIELD_NAMES.ABA_NUMBER]: 'ABA NUMBER',
  [FIELD_NAMES.BSB_ACCOUNT_NUMBER]: 'BSB NUMBER',
  [FIELD_NAMES.SORT_CODE]: 'SORT CODE',
};

export const AMOUNT_DEFAULT_FIELD_PROPS = {
  field: 'AMOUNT TO TRANSFER',
  name: FIELD_NAMES.AMOUNT,
};

export const WALLET_DEPOSIT_DEFAULT_FIELD_PROPS = {
  name: FIELD_NAMES.WALLETS_DEPOSIT,
};

export const ORDERED_FIELDS = ['agentBank', 'currency', 'name', 'country', 'address'];

export const FIELD_LABELS = {
  ...BANK_FIELD_LABELS,
  [AMOUNT_DEFAULT_FIELD_PROPS.name]: AMOUNT_DEFAULT_FIELD_PROPS.field,
  [WALLET_DEPOSIT_DEFAULT_FIELD_PROPS.name]: 'Wallet',
};
