import { forwardRef, useLayoutEffect, useRef, useState } from 'react';
import { isMobile } from 'react-device-detect';

import { NanoPop } from 'nanopop';
import PropTypes from 'prop-types';

import { white } from 'shared-parts/constants/colors';

import getUpdatedArrowPosition from './helpers';
import { AppearingPart, Arrow, TooltipWrapper } from './tooltip.styled';

const Content = forwardRef(
  (
    { content: Component, changeTooltipVisibility, arrowPosition, arrowColor, hideArrow },
    reference,
  ) => (
    <AppearingPart ref={reference}>
      {!hideArrow && <Arrow arrowPosition={arrowPosition} arrowColor={arrowColor} />}
      <Component changeTooltipVisibility={changeTooltipVisibility} />
    </AppearingPart>
  ),
);

const Tooltip = ({
  className,
  children: Attachable,
  initiallyVisible,
  content,
  position,
  margin,
  arrowColor,
  hideArrow = false,
  autohide,
  hidingDelay = 0,
}) => {
  const [visible, changeTooltipVisibility] = useState(initiallyVisible);
  const [arrowPosition, setArrowPosition] = useState({});
  const [hidingTimeout, setHidingTimeout] = useState(null);
  const contentRef = useRef();
  const attachableRef = useRef();

  const updateArrowPosition = nanopopPosition => {
    const nextArrowPosition = getUpdatedArrowPosition(contentRef, nanopopPosition, margin);

    setArrowPosition(nextArrowPosition);
  };

  const createNanopop = () => {
    const nanopop = new NanoPop(attachableRef.current, contentRef.current, {
      position,
      margin,
      container: document.getElementById('content').getBoundingClientRect(),
      forceApplyOnFailure: true,
    });

    const nanopopPosition = nanopop.update();
    updateArrowPosition(nanopopPosition);
  };

  useLayoutEffect(() => {
    if (visible) {
      createNanopop();
      window.addEventListener('scroll', createNanopop);

      return () => window.removeEventListener('scroll', createNanopop);
    }
  }, [visible]);

  const hideTooltip = () => {
    if (visible) {
      if (hidingDelay) {
        const id = setTimeout(() => {
          changeTooltipVisibility(false);
          setHidingTimeout(null);
        }, hidingDelay);
        setHidingTimeout(id);
      } else {
        changeTooltipVisibility(false);
      }
    }
  };

  const showTooltip = () => {
    if (!visible) {
      changeTooltipVisibility(true);
    }
    if (hidingTimeout) {
      clearTimeout(hidingTimeout);
      setHidingTimeout(null);
    }
  };

  const toggleTooltipVisibility = () => {
    changeTooltipVisibility(previous => !previous);
  };

  const visibilityTriggers = isMobile
    ? {
        onClick: showTooltip,
      }
    : {
        onClick: toggleTooltipVisibility,
        onMouseEnter: showTooltip,
        onFocus: showTooltip,
        onBlur: hideTooltip,
        onPointerLeave: hideTooltip,
      };

  const disableAutohide = !autohide && visibilityTriggers;

  return (
    <TooltipWrapper className={className} onClickOutside={hideTooltip} {...disableAutohide}>
      <div ref={attachableRef}>
        <Attachable
          changeTooltipVisibility={changeTooltipVisibility}
          toggleTooltipVisibility={toggleTooltipVisibility}
        />
      </div>
      {visible && (
        <Content
          ref={contentRef}
          hideArrow={hideArrow}
          arrowColor={arrowColor}
          arrowPosition={arrowPosition}
          content={content}
          changeTooltipVisibility={changeTooltipVisibility}
        />
      )}
    </TooltipWrapper>
  );
};

Tooltip.defaultProps = {
  className: '',
  position: 'bottom-middle',
  initiallyVisible: false,
  margin: 15,
  arrowColor: white,
  autohide: true,
};

Tooltip.propTypes = {
  content: PropTypes.func.isRequired,
  children: PropTypes.func.isRequired,
  position: PropTypes.string,
  initiallyVisible: PropTypes.bool,
  className: PropTypes.string,
  margin: PropTypes.number,
  arrowColor: PropTypes.string,
  autohide: PropTypes.bool,
  hidingDelay: PropTypes.number,
};

export default Tooltip;
