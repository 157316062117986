import { FC } from 'react';

import { Box, BoxProps, formControlClasses, styled } from '@mui/material';

const FormRowRoot = styled(Box, {
  name: 'FormRow',
  slot: 'Root',
  overridesResolver: (_, styles) => [styles.root],
})(({ theme }) => ({
  display: 'flex',
  flexDirection: 'row',
  alignItems: 'flex-end',
  gap: theme.spacing(2),

  // Invalidate any paddings that form controls would have
  [`> .${formControlClasses.root}~.${formControlClasses.root}`]: {
    marginTop: 0,
  },
}));

export const FormRow: FC<BoxProps> = ({ children, ...props }) => {
  return <FormRowRoot {...props}>{children}</FormRowRoot>;
};
