import styled from 'styled-components';

import * as Colors from 'shared-parts/constants/colors';

const MailLinkWrapper = styled.a<{
  color?: string;
  hoverColor?: string;
}>`
  color: ${({ color }) => color || Colors.secondary};
  margin-left: 5px;
  &:hover {
    color: ${({ hoverColor }) => hoverColor || Colors.accent};
  }
`;

export { MailLinkWrapper };
