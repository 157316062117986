import { Fragment, useEffect, useRef } from 'react';

import { Field } from 'formik';

import { uploadFile } from 'shared-parts/helpers/file-management';

const FileInput = ({ field, form, className, accept, disabled = false, formDataParams = {} }) => {
  const onChange = uploadFile({
    onLoad: data => form.setFieldValue(field.name, data),
    validate: false,
    formDataParams,
  });
  const inputRef = useRef();

  useEffect(() => {
    if (!field.value && inputRef.current && inputRef.current.value) {
      inputRef.current.value = null;
    }
  }, [field.value]);

  return (
    <Fragment>
      <input
        type="file"
        accept={accept}
        ref={inputRef}
        className={className}
        name={field.name}
        onBlur={field.onBlur}
        onChange={onChange}
        disabled={disabled}
        data-e2e={field.name}
      />
    </Fragment>
  );
};

export default props => <Field {...props} component={FileInput} />;
