import { push } from 'connected-react-router';

import { hideModal, showModal } from 'app-state/actions';
import store from 'app-state/store';

import api from 'constants/api';
import Routes from 'constants/routes';
import { MailLink } from 'shared';
import ConfirmModal from 'shared-parts/components/modal/confirm-modal';
import SuccessModal from 'shared-parts/components/modal/success-modal';
import ErrorModal from 'shared-parts/components/modal-failed';
import request from 'shared-parts/helpers/request';

interface ErrorResponse {
  readonly status?: number;
  readonly response?: {
    readonly errorCode?: number;
    readonly errorMessage?: string;
  };
}

export const isRestrictedDeal = ({ status, response }: ErrorResponse): boolean => {
  return status === 403 && response?.errorCode === 2000;
};

export const handleRestriction = (permalink: string) => {
  const { dispatch } = store;
  dispatch(
    showModal({
      hideModal: () => {
        dispatch(hideModal());
        dispatch(push(Routes.Root()));
      },
      closable: false,
      showHeader: false,
      isConfirmDanger: false,
      component: ConfirmModal,
      title: `Access Restricted`,
      children: (
        <>
          You do not have permission to view this deal. Please contact the company who invited you
          to this deal or contact <MailLink /> for further inquiry.
        </>
      ),
      additionalText1: 'You can also remove this deal from your account',
      cancelButtonText: 'Go back',
      confirmButtonText: 'Remove',
      confirmAction: async () => {
        dispatch(hideModal());
        try {
          await request(api.removeDeal(permalink), 'DELETE');
          dispatch(
            showModal({
              hideModal: () => dispatch(hideModal()),
              closable: false,
              showHeader: false,
              component: SuccessModal,
              title: `Deal removed`,
              additionalText: `Deal has been removed from your account`,
            }),
          );
          dispatch(push(Routes.Root()));
        } catch {
          dispatch(
            showModal({
              closable: true,
              showHeader: false,
              component: ErrorModal,
            }),
          );
          dispatch(push(Routes.Root()));
        }
      },
    }),
  );
};
