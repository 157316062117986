import { ComponentType, useEffect, useState } from 'react';

import MiniSearch, { SearchResult } from 'minisearch';

import { DEFAULT_SEARCH_CONFIGURATION } from '../constants';
import type { FulltextSearchOpts, FulltextSearchProps } from '../types';

const defaultSearchFormat = (data: string[]) =>
  data.map(text => ({
    text,
    value: text,
  }));

const defaultSearchNormalize = (data: SearchResult[]): string[] => data.map(({ id }) => id);

const withFulltextSearch =
  (Component: ComponentType<React.PropsWithChildren<{ value?: string }>>) =>
  ({
    optionsKey,
    value,
    formatSearchOptions = defaultSearchFormat,
    normalizeSearchOptions = defaultSearchNormalize,
    searchConfiguration,
    ...restProps
  }: FulltextSearchProps & FulltextSearchOpts): JSX.Element => {
    const options = restProps[optionsKey];
    const [filteredOptions, setFilteredOptions] = useState<string[]>([]);
    const searchInstance = new MiniSearch({
      ...DEFAULT_SEARCH_CONFIGURATION,
      ...(Boolean(searchConfiguration) && searchConfiguration),
    });

    useEffect(() => {
      const formattedDocuments = formatSearchOptions(options);

      if (value && options.length) {
        searchInstance.addAll(formattedDocuments);

        const filteredResult: SearchResult[] = searchInstance.search(value);

        if (filteredResult && Array.isArray(filteredResult)) {
          const normalizedValues = normalizeSearchOptions(filteredResult);

          setFilteredOptions(normalizedValues);
        }
      } else {
        setFilteredOptions([]);
      }
    }, [options, value]);

    const parameters = {
      [optionsKey]: filteredOptions,
    };

    return <Component {...restProps} {...parameters} value={value} />;
  };

export default withFulltextSearch;
