import { FC } from 'react';

type Props = {
  width?: number;
  height?: number;
  color?: string;
  className?: string;
};

const ArrowRight: FC<React.PropsWithChildren<Props>> = ({
  width = 20,
  height = 20,
  color = '#C9CCD8',
  className,
}) => (
  <svg
    width={width}
    height={height}
    className={className}
    xmlns="http://www.w3.org/2000/svg"
    version="1.1"
    viewBox="0 0 150 150"
    preserveAspectRatio="xMidYMid meet"
  >
    <g id="full">
      <path
        fill={color}
        d="m 122.63422,75.262249 a 20.343419,20.343367 0 0 1 -5.859,14.647 L 62.272219,143.82725 a 19.825636,19.825585 0 0 1 -28.751,0 19.825636,19.825585 0 0 1 0,-28.724 l 39.856,-39.855001 -39.856,-39.869 a 20.725009,20.724956 0 0 1 29.309,-29.3099997 L 116.77522,59.987249 a 26.066283,26.066216 0 0 1 5.859,15.247 z"
      />
    </g>
  </svg>
);

export default ArrowRight;
