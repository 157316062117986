export enum FeatureFlag {
  LIQUIDITY_ROUND_FEATURE = 'LIQUIDITY_ROUND_FEATURE',
  SENDBIRD_ENABLED = 'SENDBIRD_ENABLED',
  HIDE_SIGNUP_LINK = 'HIDE_SIGNUP_LINK',
  NOTIFICATIONS_TAB_VISIBLE = 'NOTIFICATIONS_TAB_VISIBLE',
  PLACEMENT_DISABLED = 'PLACEMENT_DISABLED',
  INDIVIDUAL_INVESTOR_SIGNUP_ONLY = 'INDIVIDUAL_INVESTOR_SIGNUP_ONLY',
  HIDE_WALLET = 'HIDE_WALLET',
  HIDE_CASH_BALANCE = 'HIDE_CASH_BALANCE',
  HIDE_TRANSACTIONS = 'HIDE_TRANSACTIONS',
  DISABLE_TRANSACTIONS = 'DISABLE_TRANSACTIONS',
  ENABLE_SPV = 'ENABLE_SPV',
  ENABLED_LIQUIDITY_SIGNATURE_DOCUMENTS = 'ENABLED_LIQUIDITY_SIGNATURE_DOCUMENTS',
  SHOW_LINKED_INVESTMENT_COMMUNICATIONS = 'SHOW_LINKED_INVESTMENT_COMMUNICATIONS',
  CUSTOM_KYC_KYB = 'CUSTOM_KYC_KYB',
  NEW_USER_REGISTRATION_FLOW_ENABLED = 'NEW_USER_REGISTRATION_FLOW_ENABLED',
  IS_GFI_POWERED_BY_GLOBACAP_IMAGE_HIDDEN = 'IS_GFI_POWERED_BY_GLOBACAP_IMAGE_HIDDEN',
  ENABLED_MANUAL_DEAL_ISSUER = 'ENABLED_MANUAL_DEAL_ISSUER',
  ENABLED_KYB_ADDRESS_CHECK = 'ENABLED_KYB_ADDRESS_CHECK',
  ENABLED_INVESTMENT_PREFERENCES_REDIRECT = 'ENABLED_INVESTMENT_PREFERENCES_REDIRECT',
  ENABLED_ACCOUNT_PERMISSIONS = 'ENABLED_ACCOUNT_PERMISSIONS',
  DISABLED_VALUATION_FIELD = 'DISABLED_VALUATION_FIELD',
  DISABLE_SIGN_UP = 'DISABLE_SIGN_UP',
  DISABLED_KYB = 'DISABLED_KYB',
  DISABLED_CAP_TABLE_MANAGEMENT = 'DISABLED_CAP_TABLE_MANAGEMENT',
  ENABLE_WITHDRAW = 'ENABLE_WITHDRAW',
  DISABLE_SHARE_CERTIFICATE_DOWNLOAD = 'DISABLE_SHARE_CERTIFICATE_DOWNLOAD',
  ENABLE_EVENT_BASED_SCHEDULING = 'ENABLE_EVENT_BASED_SCHEDULING',
  ENABLE_EMPLOYEE_SHARE_PLAN = 'ENABLE_EMPLOYEE_SHARE_PLAN',
  USER_ALERTS_REFETCH_ENABLED = 'USER_ALERTS_REFETCH_ENABLED',
  DATAROOM_ENABLED = 'DATAROOM_ENABLED',
  ENABLE_DEMO_ORDER_FIELDS = 'ENABLE_DEMO_ORDER_FIELDS',
  USE_MUIMOUS = 'USE_MUIMOUS',
  DISABLE_UPDATING_USER_EMAIL = 'DISABLE_UPDATING_USER_EMAIL',
  USE_ACCOUNT_PERMISSIONS_GROUPS = 'USE_ACCOUNT_PERMISSIONS_GROUPS',
  ENABLED_ACCOUNT_INFORMATION_PREVIEW_REDIRECT = 'ENABLED_ACCOUNT_INFORMATION_PREVIEW_REDIRECT',
  USE_ALTERNATIVE_KYB_TEXT = 'USE_ALTERNATIVE_KYB_TEXT',
  DISABLE_FOOTER_LOGO = 'DISABLE_FOOTER_LOGO',
  ENABLE_CHEVRONS = 'ENABLE_CHEVRONS',
  DISABLE_FOOTER_LINKS = 'DISABLE_FOOTER_LINKS',
  DISABLE_FOOTER_RISK_LINK = 'DISABLE_FOOTER_RISK_LINK',
  ENABLE_FOOTER_TENANT_WEBSITE_LINK = 'ENABLE_FOOTER_TENANT_WEBSITE_LINK',
  OAUTH2_LOGIN_ENABLE = 'OAUTH2_LOGIN_ENABLE',
}

export enum EnvironmentTypes {
  LOCAL = 'local',
  DEVELOPMENT = 'development',
  PREPROD = 'preproduction',
  PROD = 'production',
}

export interface IAppConfig {
  featureFlags: FeatureFlag[];
  environment: EnvironmentTypes;
  sendbirdId: string;
}
