export const FETCH_WALLETS = 'FETCH_WALLETS';
export const FETCH_WALLETS_SUCCESS = 'FETCH_WALLETS_SUCCESS';
export const FETCH_WALLETS_ERROR = 'FETCH_WALLETS_ERROR';
export const RESET_WALLETS = 'RESET_WALLETS';

export const CREATE_WALLETS_DEPOSIT = 'CREATE_WALLETS_DEPOSIT';
export const CREATE_WALLETS_DEPOSIT_SUCCESS = 'CREATE_WALLETS_DEPOSIT_SUCCESS';
export const CREATE_WALLETS_DEPOSIT_ERROR = 'CREATE_WALLETS_DEPOSIT_ERROR';

export const CREATE_WALLETS_WITHDRAWAL = 'CREATE_WALLETS_WITHDRAWAL';
export const CREATE_WALLETS_WITHDRAWAL_SUCCESS = 'CREATE_WALLETS_WITHDRAWAL_SUCCESS';
export const CREATE_WALLETS_WITHDRAWAL_ERROR = 'CREATE_WALLETS_WITHDRAWAL_ERROR';

export const FETCH_WALLETS_PAGES_DATA = 'FETCH_WALLETS_PAGES_DATA';
export const FETCH_WALLETS_PAGES_DATA_SUCCESS = 'FETCH_WALLETS_PAGES_DATA_SUCCESS';
export const FETCH_WALLETS_PAGES_DATA_ERROR = 'FETCH_WALLETS_PAGES_DATA_ERROR';
export const RESET_WALLETS_PAGES_DATA = 'RESET_WALLETS_PAGES_DATA';

export const DOWNLOAD_SHAREHOLDER_CERT = 'DOWNLOAD_SHAREHOLDER_CERT';
export const DOWNLOAD_SHAREHOLDER_CERT_SUCCESS = 'DOWNLOAD_SHAREHOLDER_CERT_SUCCESS';
export const DOWNLOAD_SHAREHOLDER_CERT_ERROR = 'DOWNLOAD_SHAREHOLDER_CERT_ERROR';
export const RESET_SHAREHOLDER_CERT = 'RESET_SHAREHOLDER_CERT';
