import { useQueryClient } from '@tanstack/react-query';
import { useCallback, useEffect, useState } from 'react';

import { RequestDocument, Variables } from 'graphql-request';

import { useHttpDefaultOptions } from './clients/request-client-provider';
import { downloadBlob } from './utils/download-blob';
import { GraphQLExportMutationVariables } from './use-graphql-export-options';
import { useRequest } from './use-request';
import { useSubmit } from './use-submit';

type GraphQLTemplate = { query: RequestDocument; variables?: Variables };

type JobState = 'Queued' | 'Processing' | 'Completed' | 'Failed';
type JobStatus = { id: string; status: JobState };

export type ExportStatus =
  | 'Idle'
  | 'Creating'
  | 'Queued'
  | 'Processing'
  | 'Downloading'
  | 'Failed'
  | 'Completed';

export type UseGraphQLExportOptions = {
  url?: string;
  interval?: number;
  onExporting?: () => void;
  onComplete?: () => void;
  onError?: (error: unknown) => void;
};

const useExportRequest = (url: string, { query, variables }: GraphQLTemplate) => {
  const queryClient = useQueryClient();

  const requestExport = useSubmit<GraphQLExportMutationVariables & GraphQLTemplate, JobStatus>(
    `${url}/jobs`,
    'POST',
  );

  const submitRequest = useCallback(
    async (options?: GraphQLExportMutationVariables) => {
      if (typeof url !== 'string') {
        throw new Error('A string must be provided to URL.');
      }

      if (requestExport.data?.id) {
        await queryClient.cancelQueries({ queryKey: [`${url}/jobs/${requestExport.data.id}`] });
      }

      return requestExport.submit({ query, variables, ...options }, { snakeCaseParams: false });
    },
    [url, query, requestExport, queryClient, variables],
  );

  return { ...requestExport, submit: submitRequest };
};

export const useGraphQLExport = (
  query: RequestDocument,
  variables?: Variables,
  options?: UseGraphQLExportOptions,
) => {
  const { export: defaultOptions } = useHttpDefaultOptions();
  const { url, interval, onError, onExporting, onComplete } = { ...defaultOptions, ...options };

  if (!url) {
    throw new Error('No URL was provided to the exporter.');
  }

  const {
    data: exportRequest,
    isLoading: exportRequesting,
    error: exportError,
    submit: createExportRequest,
  } = useExportRequest(url, {
    query,
    variables,
  });

  // Keep track of document generation
  const { data: exportStatus, error: pollError } = useRequest<JobStatus>(
    `${url}/jobs/${exportRequest?.id}`,
    {
      enabled: exportRequest?.id != null,
      refetchInterval(data, q) {
        if (q.state.error) {
          return false;
        }

        const pollStatuses: JobState[] = ['Queued', 'Processing'];
        if (data?.status && pollStatuses.includes(data?.status)) {
          return interval || 3000;
        }

        return false;
      },
    },
  );

  // Keep an internal status to monitor the whole process
  const [status, setStatus] = useState<ExportStatus>('Idle');
  useEffect(() => {
    if (exportRequesting) {
      setStatus('Creating');
    } else if (exportRequest?.status === 'Queued') {
      setStatus('Queued');
    }
  }, [exportRequest, exportRequesting]);

  useEffect(() => {
    if (exportStatus?.status === 'Processing') {
      setStatus('Processing');
    } else if (exportStatus?.status === 'Completed') {
      setStatus('Downloading');
    } else if (exportStatus?.status === 'Failed') {
      setStatus('Failed');
    }
  }, [exportStatus]);

  // Handle download of result
  const { data: exportFile, error: downloadError } = useRequest<Blob>(
    `${url}/jobs/${exportRequest?.id}/file`,
    {
      enabled: exportStatus?.status === 'Completed',
    },
    { to: 'blob' },
  );

  useEffect(() => {
    if (exportFile) {
      downloadBlob(exportFile, 'data.csv');
      setStatus('Completed');
    }
  }, [exportFile]);

  const exporting = !(['Idle', 'Failed', 'Completed'] as ExportStatus[]).includes(status);

  const error =
    exportError ||
    pollError ||
    downloadError ||
    (exportStatus?.status === 'Failed' && 'Job Failed') ||
    null;

  // Wire up the event handlers
  useEffect(() => {
    if (exporting) {
      onExporting?.();
    }
  }, [exporting, onExporting]);

  useEffect(() => {
    if (error) {
      setStatus('Failed');
      onError?.(error);
    }
  }, [error, onError]);

  useEffect(() => {
    if (status === 'Completed') {
      onComplete?.();
    }
  }, [status, onComplete]);

  return {
    export: createExportRequest,
    status,
    isExporting: exporting,
    file: exportFile,
    error,
  };
};
