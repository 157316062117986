import he from 'he';

export const encodeHtml = (html: string | null | undefined): string => {
  return he.encode(html || '', {
    useNamedReferences: true,
  });
};

export const decodeHtml = (html: string | null | undefined): string => {
  return he.decode(html || '');
};
