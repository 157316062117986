// This is used for more than just time conversion, so leave it in.
const formatNumber = number => {
  const formatted = Math.abs(Number(number));

  if (formatted < 10) {
    return `0${formatted}`;
  }

  return formatted;
};

/** @deprecated Use dayjs for formatting and localisation */
const make24HoursFormat = (hours, isAM) => {
  const formatted = Number(hours);

  if (isAM) {
    return formatted === 12 ? '00' : formatNumber(formatted);
  }

  if (formatted === 12) {
    return formatted;
  }

  const twentyFourHoursFormat = formatted + 12;

  return twentyFourHoursFormat > 24
    ? formatNumber(twentyFourHoursFormat - 24)
    : twentyFourHoursFormat; // eslint-disable-line
};

/** @deprecated Use dayjs for formatting and localisation */
const convertToISO8601Date = (date, { offset } = {}) => {
  // for example '15-11-2019 10:47AM'
  if (!date) {
    return '';
  }

  const [fullDate, time = '00:00AM'] = date.split(' ');
  const [day, month, year] = fullDate.split('-');
  const isAM = time.slice(-2) === 'AM';
  const [hours, minutes] = time.slice(-7, -2).split(':');
  const formattedHours = make24HoursFormat(hours, isAM);

  const timezoneOffset =
    new Date(+year, month - 1, +day, +hours, +minutes).getTimezoneOffset() * -1;
  const hoursOffset = offset || formatNumber(Math.floor(timezoneOffset / 60));
  const minutesOffset = formatNumber(timezoneOffset % 60);
  const offsetSign = timezoneOffset < 0 ? '-' : '+';

  return `${year}-${month}-${day}T${formattedHours}:${minutes}:00.000${offsetSign}${hoursOffset}:${minutesOffset}`;
};

/** @deprecated Use dayjs for formatting and localisation */
const convertFromISO8601Date = date => {
  // for example '2020-11-15T19:53:00.000+03:00'
  if (!date) {
    return '';
  }

  const formatted = new Date(date);
  const day = formatNumber(formatted.getDate());
  const month = formatNumber(formatted.getMonth() + 1);
  const year = formatted.getFullYear();
  const hours = formatted.getHours();
  const twelveHoursFormat = formatNumber(hours % 12);
  const formattedHours = Number(twelveHoursFormat) === 0 ? '12' : twelveHoursFormat;
  const minutes = formatNumber(formatted.getMinutes());
  const ampm = hours >= 12 ? 'PM' : 'AM';

  return `${day}-${month}-${year} ${formattedHours}:${minutes}${ampm}`;
};

export { formatNumber, make24HoursFormat, convertToISO8601Date, convertFromISO8601Date };
