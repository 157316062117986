import {
  CHANGE_PASSWORD,
  FORGOT_PASSWORD,
  LOGIN,
  LOGIN_ERROR,
  LOGIN_SUCCESS,
  LOGOUT,
  LOGOUT_ERROR,
  LOGOUT_SUCCESS,
  OAUTH2_LOGIN,
  OAUTH2_LOGOUT,
  RESET_PASSWORD,
  RESET_SIGN_UP,
  SAVE_USER_DATA,
  SIGN_UP,
  SIGN_UP_ERROR,
  SIGN_UP_SUCCESS,
  TWO_FACTOR_AUTH_CONFIRM,
  TWO_FACTOR_AUTH_DISABLE,
  TWO_FACTOR_AUTH_GET_CODE,
  TWO_FACTOR_AUTH_GET_CODE_CLEAR,
  TWO_FACTOR_AUTH_GET_CODE_ERROR,
  TWO_FACTOR_AUTH_GET_CODE_SUCCESS,
} from 'app-state/constants';

export const logout = (isSessionExpired, shouldRedirect) => ({
  type: LOGOUT,
  isSessionExpired,
  shouldRedirect,
});
export const logoutSuccess = () => ({ type: LOGOUT_SUCCESS });
export const logoutError = error => ({ type: LOGOUT_ERROR, error });

export const signUp = params => ({ type: SIGN_UP, ...params });
export const signUpSuccess = data => ({ type: SIGN_UP_SUCCESS, data });
export const signUpError = error => ({ type: SIGN_UP_ERROR, error });
export const resetSignUp = () => ({ type: RESET_SIGN_UP });

export const login = params => ({ type: LOGIN, ...params });
export const loginSuccess = data => ({ type: LOGIN_SUCCESS, data });
export const loginError = error => ({ type: LOGIN_ERROR, error });
export const oauth2Login = params => ({ type: OAUTH2_LOGIN, ...params });
export const oauth2Logout = params => ({ type: OAUTH2_LOGOUT, ...params });

export const saveUserData = (user, redirectTo) => ({ type: SAVE_USER_DATA, user, redirectTo });

export const resetPassword = ({
  formData,
  token,
  setSubmitting,
  setErrors,
  isEmailVisible,
  setIsTwoFactorAuth,
  isAgentPermissionsEnabled,
}) => ({
  type: RESET_PASSWORD,
  formData,
  token,
  setSubmitting,
  setErrors,
  isEmailVisible,
  setIsTwoFactorAuth,
  isAgentPermissionsEnabled,
});

export const forgotPassword = (form, showEmailSentText, setSubmitting, setErrors) => ({
  type: FORGOT_PASSWORD,
  form,
  showEmailSentText,
  setSubmitting,
  setErrors,
});

export const changePassword = (form, handleSuccess, setSubmitting, setErrors) => ({
  type: CHANGE_PASSWORD,
  form,
  handleSuccess,
  setSubmitting,
  setErrors,
});

export const twoFactorAuthGetCode = () => ({ type: TWO_FACTOR_AUTH_GET_CODE });

export const twoFactorAuthGetCodeSuccess = data => ({
  type: TWO_FACTOR_AUTH_GET_CODE_SUCCESS,
  data,
});

export const twoFactorAuthGetCodeError = error => ({
  type: TWO_FACTOR_AUTH_GET_CODE_ERROR,
  error,
});

export const twoFactorAuthCodeClear = () => ({ type: TWO_FACTOR_AUTH_GET_CODE_CLEAR });

export const twoFactorAuthConfirm = (
  data,
  { setSubmitting, setErrors },
  showTwoFactorAuthBackupCodesModal,
) => ({
  type: TWO_FACTOR_AUTH_CONFIRM,
  data,
  setSubmitting,
  setErrors,
  showTwoFactorAuthBackupCodesModal,
});

export const twoFactorAuthDisable = ({ setSubmitting, setErrors }) => ({
  type: TWO_FACTOR_AUTH_DISABLE,
  setSubmitting,
  setErrors,
});
