import { useEffect } from 'react';

import { getLoaderSelector } from 'app-state/loader/selectors';

import useTypedSelector from 'hooks/use-typed-selector';

import { Icon, Message, Spinner, SpinnerContainer } from './loader.styled';
import type { LoaderProps } from './loader.types';

const Loader = ({
  isLocal = false,
  globalSpinnerSize = 140,
  localSpinnerSize = 40,
  alwaysVisible = false,
  className,
  zIndex,
  onUnmount,
}: LoaderProps): null | JSX.Element => {
  useEffect(() => onUnmount, []);

  const {
    message = '',
    showLoader = false,
    whiteBackground = false,
    showIcon = false,
    isSuccess = false,
    isDone = false,
  } = useTypedSelector(getLoaderSelector);

  return alwaysVisible || isLocal || showLoader ? (
    <SpinnerContainer
      className={className}
      whiteBackground={whiteBackground}
      isLocal={isLocal}
      zIndex={zIndex}
      data-e2e="spinner-container"
    >
      <Message data-e2e="spinner-message">
        <Icon showIcon={showIcon} isSuccess={isSuccess} />
        {message}
      </Message>
      <Spinner
        globalSpinnerSize={globalSpinnerSize}
        localSpinnerSize={localSpinnerSize}
        isLocal={isLocal}
        withMessage={Boolean(message)}
        isDone={isDone}
      />
    </SpinnerContainer>
  ) : null;
};

export default Loader;
