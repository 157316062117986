import Big from 'big.js';

export const minimumSupportedOrderAmount = (unitTruncateScale: number, pricePerToken: string) => {
  const smallestSupportedUnit = new Big(10).pow(unitTruncateScale > 0 ? -unitTruncateScale : 0);
  const minimumOrderAmountForFractionalToken = new Big(pricePerToken)
    .mul(smallestSupportedUnit)
    .toNumber();

  return minimumOrderAmountForFractionalToken;
};
