import styled from 'styled-components';

import { useEnvVar } from '@frontend/config';

import { getTenantConfig } from 'helpers/get-tenant-config';
import Link from 'shared-parts/components/link';

const {
  custom: {
    authLogo: { width, height, margin, marginBottom, marginRight, paddingBottom, secondaryLogo },
  },
} = getTenantConfig();

const LogoWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
`;

const SecondaryLogo = styled.img`
  ${secondaryLogo?.width && `width: ${secondaryLogo?.width};`}
  ${secondaryLogo?.height && `height: ${secondaryLogo?.height};`}
`;

const Logo = styled.img`
  ${width && `width: ${width};`}
  ${height && `height: ${height};`}
  ${margin && `margin: ${margin};`}
  ${marginBottom && `margin-bottom: ${marginBottom};`}
  ${marginRight && `margin-right: ${marginRight};`}
  ${paddingBottom && `padding-bottom: ${paddingBottom};`}
`;

const AuthLogo = () => {
  const url = useEnvVar('TENANT_WEBSITE_URL', 'https://globacap.com');
  const platformName = useEnvVar('PLATFORM_NAME', 'Globacap');

  if (secondaryLogo) {
    return (
      <LogoWrapper>
        <Logo src="/logos/main.svg" alt={`${platformName} logo`} />
        <SecondaryLogo src={secondaryLogo.url} alt={`${platformName} logo`} />
      </LogoWrapper>
    );
  }

  return (
    <Link url={url} title="" target="_blank" rel="noopener noreferrer">
      <Logo src="/logos/main.svg" alt={`${platformName} logo`} />
    </Link>
  );
};

export default AuthLogo;
