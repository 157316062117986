import { formatDealTableData } from './box-table.helpers';
import { WrappedTable } from './box-table.styled';

export interface ItemProps {
  text: string;
  styles: string;
}

export interface HeaderProps {
  header: ItemProps[];
  data: ItemProps[][];
}

export default ({ header, data }: HeaderProps): JSX.Element => (
  <WrappedTable isHTMLAllowed data={formatDealTableData(header, data)} loading={false} />
);

export { WrappedTable };
