import * as constants from 'app-state/constants';

export const fetchInvestmentsData = () => ({ type: constants.FETCH_INVESTMENTS_DATA });
export const fetchInvestmentsDataSuccess = data => ({
  type: constants.FETCH_INVESTMENTS_DATA_SUCCESS,
  data,
});
export const fetchInvestmentsDataError = error => ({
  type: constants.FETCH_INVESTMENTS_DATA_ERROR,
  error,
});
export const resetInvestmentsData = () => ({ type: constants.RESET_INVESTMENTS_DATA });

export const fetchSecondaryInvestmentsData = params => ({
  type: constants.FETCH_SECONDARY_INVESTMENTS_DATA,
  params: { ...params },
});
export const fetchSecondaryInvestmentsDataSuccess = data => ({
  type: constants.FETCH_SECONDARY_INVESTMENTS_DATA_SUCCESS,
  data,
});
export const fetchSecondaryInvestmentsDataError = error => ({
  type: constants.FETCH_SECONDARY_INVESTMENTS_DATA_ERROR,
  error,
});
export const resetSecondaryInvestmentsData = () => ({
  type: constants.RESET_SECONDARY_INVESTMENTS_DATA,
});

export const fetchSecondaryInvestmentData = uuid => ({
  type: constants.FETCH_SECONDARY_INVESTMENT_DATA,
  uuid,
});
export const updateSecondaryInvestmentData = data => ({
  type: constants.UPDATE_SECONDARY_INVESTMENT_DATA,
  data,
});
export const fetchSecondaryInvestmentDataSuccess = data => ({
  type: constants.FETCH_SECONDARY_INVESTMENT_DATA_SUCCESS,
  data,
});
export const fetchSecondaryInvestmentDataError = error => ({
  type: constants.FETCH_SECONDARY_INVESTMENT_DATA_ERROR,
  error,
});

export const sellInvestments = params => ({ type: constants.SELL_INVESTMENTS, params });
export const sellInvestmentsSuccess = data => ({ type: constants.SELL_INVESTMENTS_SUCCESS, data });
export const sellInvestmentsError = errors => ({ type: constants.SELL_INVESTMENTS_ERROR, errors });

export const getSellInvestmentsInfo = params => ({ type: constants.SELL_INVESTMENTS_INFO, params });
export const getSellInvestmentsInfoSuccess = data => ({
  type: constants.SELL_INVESTMENTS_INFO_SUCCESS,
  data,
});
export const getSellInvestmentsInfoError = errors => ({
  type: constants.SELL_INVESTMENTS_INFO_ERROR,
  errors,
});

export const resetSellInvestmentsForm = () => ({ type: constants.RESET_SELL_INVESTMENTS_FORM });

export const cancelSecondaryInvestment = uuid => ({
  type: constants.CANCEL_SECONDARY_INVESTMENT,
  uuid,
});

export const confirmSecondaryInvestment = uuid => ({
  type: constants.CONFIRM_SECONDARY_INVESTMENT,
  uuid,
});

export const rejectSecondaryInvestment = (uuid, rejectionReason) => ({
  type: constants.REJECT_SECONDARY_INVESTMENT,
  uuid,
  rejectionReason,
});
