import { createRef } from 'react';
import { toast } from 'react-toastify';

import Toaster from 'shared-parts/components/toaster';
import * as Colors from 'shared-parts/constants/colors';

import {
  Button,
  Container,
  Input,
  StyledTooltip as Tooltip,
  ToolTipMessage,
} from './copy-field.styled';

const CopyField = ({ removeSpaces = false, className, value, name, field, bank }) => {
  const input = createRef();
  const renderTooltip = message => <ToolTipMessage>{message}</ToolTipMessage>;

  const onCopyClick = () => {
    const initialInputValue = input.current.value;

    if (field === 'ACCOUNT NAME') {
      input.current.value = value;
    }

    if (removeSpaces) {
      input.current.value = initialInputValue.split(' ').join('');
    }

    navigator.clipboard.writeText(value);

    input.current.value = initialInputValue;
    toast.success('Copied');
  };

  const resetValue = e => e.preventDefault();
  const accountName = value;
  const lastSubstring = accountName?.substring(accountName?.lastIndexOf(' ') + 1);
  const inputForAccountName =
    accountName?.length > 25
      ? `${accountName.slice(0, 25 - lastSubstring.length)}...${lastSubstring}`
      : accountName;

  return (
    <Container className={className}>
      {field === 'ACCOUNT NAME' && accountName?.length > 25 ? (
        <Tooltip
          content={() => renderTooltip(bank.accountName)}
          autohide={false}
          arrowColor={Colors.black}
        >
          {() => (
            <Input
              type="text"
              ref={input}
              value={inputForAccountName}
              name={name}
              onChange={resetValue}
            />
          )}
        </Tooltip>
      ) : (
        <Input type="text" ref={input} value={value} name={name} onChange={resetValue} />
      )}
      <Button onClick={onCopyClick} tabIndex={0} role="button" type="button">
        Copy
      </Button>
      <Toaster />
    </Container>
  );
};

export default CopyField;
