const generateTotalRow = ({ keys, totalRowData, fullWidth }) =>
  fullWidth
    ? [{ text: totalRowData, colspan: keys.length }]
    : keys.map(({ field, transform, styles = '' }) => {
        const fieldData = totalRowData[field];

        if (!fieldData) return { text: ' ', styles };

        return {
          text: transform ? transform(fieldData) : fieldData,
          styles,
        };
      });

export default generateTotalRow;
