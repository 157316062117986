import { forwardRef, Fragment } from 'react';

import { ProgressLine, TableRow } from '../table.styled';

import renderCell from './cell';

const Row = forwardRef(
  (
    {
      i,
      item,
      cells,
      onRowClick,
      onGroupClick,
      rowsStyles,
      expandedItemsIds,
      rowScrollData,
      renderCustomCell,
      renderExpandedRow,
      isHTMLAllowed,
    },
    rowRef,
  ) => {
    const clickable = !item.isRowClickDisabled && (item.groupExpander || onRowClick);
    const rowClickHandler = () => {
      if (clickable) {
        if (item.groupExpander) {
          onGroupClick(item);
        } else {
          onRowClick(item);
        }
      }
    };
    const rowStyles = rowsStyles ? rowsStyles[item.id] : '';
    const expandedRow = expandedItemsIds.includes(item.id);

    return (
      <Fragment>
        <TableRow
          ref={item.id === rowScrollData ? rowRef : null}
          isGroup={item.isGroup}
          onClick={rowClickHandler}
          clickable={clickable}
          rowStyles={rowStyles}
          expandedRow={expandedRow}
        >
          {cells.map(
            renderCell({
              rowIndex: i,
              renderCustomCell,
              isHTMLAllowed,
            }),
          )}
        </TableRow>
        {item.loading && <ProgressLine fixedHeader />}
        {renderExpandedRow && renderExpandedRow(item)}
      </Fragment>
    );
  },
);

const renderRow = (
  cells = [],
  i,
  item = {},
  rowRef,
  {
    onRowClick = undefined,
    onGroupClick = undefined,
    rowsStyles = null,
    expandedItemsIds = [],
    rowScrollData = null,
    renderCustomCell = () => null,
    renderExpandedRow = null,
    isHTMLAllowed = false,
  },
) => (
  <Row
    i={i}
    item={item}
    ref={rowRef}
    cells={cells}
    isHTMLAllowed={isHTMLAllowed}
    key={`${i}${new Date().getTime()}`}
    onRowClick={onRowClick}
    rowsStyles={rowsStyles}
    onGroupClick={onGroupClick}
    rowScrollData={rowScrollData}
    expandedItemsIds={expandedItemsIds}
    renderCustomCell={renderCustomCell}
    renderExpandedRow={renderExpandedRow}
  />
);

export default renderRow;
