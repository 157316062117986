export const FETCH_COMPANY = 'FETCH_COMPANY';
export const FETCH_COMPANY_SUCCESS = 'FETCH_COMPANY_SUCCESS';
export const FETCH_COMPANY_ERROR = 'FETCH_COMPANY_ERROR';
export const RESET_COMPANY = 'RESET_COMPANY';

export const FETCH_COMPANIES = 'FETCH_COMPANIES';
export const FETCH_COMPANIES_SUCCESS = 'FETCH_COMPANIES_SUCCESS';
export const FETCH_COMPANIES_ERROR = 'FETCH_COMPANIES_ERROR';
export const RESET_COMPANIES = 'RESET_COMPANIES';

export const FETCH_COMPANY_GROUPS = 'FETCH_COMPANY_GROUPS';
export const FETCH_COMPANY_GROUPS_SUCCESS = 'FETCH_COMPANY_GROUPS_SUCCESS';
export const FETCH_COMPANY_GROUPS_ERROR = 'FETCH_COMPANY_GROUPS_ERROR';
export const RESET_COMPANY_GROUPS = 'RESET_COMPANY_GROUPS';

export const GET_COMPANY_SUMMARY = 'GET_COMPANY_SUMMARY';
export const GET_COMPANY_SUMMARY_SUCCESS = 'GET_COMPANY_SUMMARY_SUCCESS';
export const GET_COMPANY_SUMMARY_ERROR = 'GET_COMPANY_SUMMARY_ERROR';
