import { createContext, FC, PropsWithChildren, useContext, useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';

import { PostHog, posthog, PostHogConfig } from 'posthog-js';

import { useEnvVar } from '@frontend/config';
import { useCookieConsent } from '@frontend/consent';

import type { IndividualInvestor, InstitutionInvestor, User } from './types';

const ProductAnalyticsContext = createContext<{
  posthog: PostHog | null | undefined;
}>({
  posthog,
});
ProductAnalyticsContext.displayName = 'ProductAnalyticsContext';

export const ProductAnalyticsProvider: FC<PropsWithChildren<{ user?: User }>> = ({
  children,
  user,
}) => {
  const [postHogInitialized, setPostHogInitialized] = useState(false);
  const [userUuidTrackedByAnalytics, setUserTrackedByAnalytics] = useState<string | null>(null);

  const { PUBLIC_POSTHOG_KEY, PUBLIC_POSTHOG_HOST, DO_NOT_REQUIRE_COOKIE_CONSENT } = useEnvVar([
    'PUBLIC_POSTHOG_KEY',
    'PUBLIC_POSTHOG_HOST',
    'DO_NOT_REQUIRE_COOKIE_CONSENT',
  ]);

  const requireCookieConsent = !DO_NOT_REQUIRE_COOKIE_CONSENT;

  const postHogEnabled = Boolean(PUBLIC_POSTHOG_KEY?.toString() && PUBLIC_POSTHOG_HOST?.toString());

  const { ANALYTICS } = useCookieConsent();
  const analyticsCookiesAccepted = ANALYTICS === 'ACCEPT';

  const location = useLocation();
  useEffect(() => {
    if (!postHogInitialized) {
      return;
    }

    // For more info see: https://posthog.com/tutorials/single-page-app-pageviews
    posthog?.capture('$pageview');
  }, [postHogInitialized, location]);

  useEffect(() => {
    if (!postHogEnabled) {
      // PostHog not configured / enabled - Do Nothing
      return;
    }

    if (postHogInitialized) {
      // PostHog already initialized - Don't re init it sends additional events
      return;
    }

    if (!analyticsCookiesAccepted && requireCookieConsent) {
      // User has not accepted cookies DO NOT init posthog
      return;
    }

    const postHogOptions: Partial<PostHogConfig> = {
      // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
      api_host: PUBLIC_POSTHOG_HOST!.toString(),
      cross_subdomain_cookie: false,
    };

    // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
    posthog.init(PUBLIC_POSTHOG_KEY!.toString(), postHogOptions);
    setPostHogInitialized(true);
  }, [ANALYTICS]);

  const currentUserUuid = user?.uuid;
  useEffect(() => {
    if (!postHogInitialized) {
      return;
    }
    if (userUuidTrackedByAnalytics === currentUserUuid) {
      // Do nothing user hasn't changed
      return;
    }

    if (userUuidTrackedByAnalytics === null && user && currentUserUuid) {
      let investor: Partial<IndividualInvestor | InstitutionInvestor | null> = null;
      if (user?.investorType === 'IndividualInvestor') {
        const originalIndividualInvestor = user?.investor as IndividualInvestor;

        const individualInvestor: Partial<IndividualInvestor> = {
          onboardingStatus: originalIndividualInvestor?.onboardingStatus,
          onboardingPhase: originalIndividualInvestor?.onboardingPhase,
          individualInvestorType: originalIndividualInvestor?.individualInvestorType,
          verified: originalIndividualInvestor?.verified,
        };

        investor = individualInvestor;
      } else if (user?.investorType === 'InstitutionalInvestor') {
        const originalInstitutionInvestor = user?.investor as InstitutionInvestor;

        const institutionInvestor: Partial<InstitutionInvestor> = {
          onboardingStatus: originalInstitutionInvestor?.onboardingStatus,
          onboardingPhase: originalInstitutionInvestor?.onboardingPhase,
          accredited: originalInstitutionInvestor?.accredited,
          institutionalInvestorType: originalInstitutionInvestor?.institutionalInvestorType,
          verified: originalInstitutionInvestor?.verified,
          regulated: originalInstitutionInvestor?.regulated,
        };

        investor = institutionInvestor;
      }

      const trackedUserProperties = {
        uuid: user.uuid,
        id: user.id,
        email: user.email,
        newEmail: user.newEmail,
        createdAt: user.createdAt,
        updatedAt: user.updatedAt,
        loginStatus: user.loginStatus,
        twoFactorAuthEnabled: user.twoFactorAuthEnabled,
        investorType: user.investorType,
        regulated: user.regulated,
        investor,
      };

      // Identify sends an event, so you want may want to limit how often you call it
      posthog.identify(user.uuid, trackedUserProperties);
      setUserTrackedByAnalytics(user?.uuid);
    }

    if (userUuidTrackedByAnalytics && !currentUserUuid) {
      // https://posthog.com/docs/libraries/js#reset-after-logout
      // If you expect multiple users to use one device,
      // you should always call posthog.reset(true) on logout
      // this way you avoid the risk of them being identified as one user based on the device ID.
      // https://posthog.com/questions/how-do-identify-and-reset-work-together
      posthog?.reset(true);
      setUserTrackedByAnalytics(null);
    }
  }, [postHogInitialized, user?.uuid]);

  return (
    <ProductAnalyticsContext.Provider value={{ posthog }}>
      {children}
    </ProductAnalyticsContext.Provider>
  );
};

export const useProductAnalyticsContext = (): {
  posthog: PostHog | null | undefined;
} => useContext(ProductAnalyticsContext);
