import produce from 'immer';
import { AnyAction } from 'redux';

import * as constants from './trade-shares.constants';
import initialState, { TradeSharesStateType } from './trade-shares.state';

export const tradeSharesReducer = (state = initialState, action: AnyAction): TradeSharesStateType =>
  produce(state, draft => {
    switch (action.type) {
      case constants.SEND_SELL_ORDER.REQUEST:
        draft.sellOrder = {
          ...initialState.sellOrder,
          loading: true,
        };
        break;
      case constants.SEND_SELL_ORDER.SUCCESS:
        draft.sellOrder.loading = false;
        break;
      case constants.SEND_SELL_ORDER.FAILURE:
        draft.sellOrder.loading = false;
        draft.sellOrder.error = action.error;
        break;
      case constants.SEND_TRANSFER_ORDER.REQUEST:
        draft.transferOrder = {
          ...initialState.transferOrder,
          loading: true,
        };
        break;
      case constants.SEND_TRANSFER_ORDER.SUCCESS:
        draft.transferOrder.loading = false;
        break;
      case constants.SEND_TRANSFER_ORDER.FAILURE:
        draft.transferOrder.loading = false;
        draft.transferOrder.error = action.error;
        break;

      default:
        break;
    }
  });
