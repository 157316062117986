// TODO: THIS IS ALREADY A THING AGAIN IN LODASH (AGAIN)
// https://lodash.com/docs/4.17.15#range
export const getRange = (from: number, to: number) => {
  const result = [];

  for (let i = from; i <= to; i++) {
    result.push(i);
  }

  return [...result];
};
