import * as Colors from 'shared-parts/constants/colors';

const Clock = ({ width = 20, height = 20, color = Colors.systemRed }) => (
  <svg xmlns="http://www.w3.org/2000/svg" width={width} height={height} viewBox="0 0 20 20">
    <g fill="none" transform="translate(-270 -1068)">
      <path fill="#FAFAFA" d="M0 0h1680v2427H0z" />
      <path
        fill={color}
        d="M282.94 1076l-4.4 4.42-1.6-1.6a.754.754 0 0 0-1.06 0c-.29.29-.29.77 0 1.06L278 1082c.29.29.77.29 1.06 0l4.94-4.94c.29-.29.29-.77 0-1.06a.754.754 0 0 0-1.06 0zm6.24-5.1l-3.08-2.56c-.42-.35-1.05-.3-1.41.13-.35.42-.29 1.05.13 1.41l3.07 2.56c.42.35 1.05.3 1.41-.13a1 1 0 0 0-.12-1.41zm-17.08 1.54l3.07-2.56c.43-.36.49-.99.13-1.41a.988.988 0 0 0-1.4-.13l-3.08 2.56a1 1 0 0 0-.12 1.41c.35.43.98.48 1.4.13zm7.9-2.55a9 9 0 1 0 .001 18.002 9 9 0 0 0-.001-18.001zm0 16c-3.86 0-7-3.14-7-7s3.14-7 7-7 7 3.14 7 7-3.14 7-7 7z"
      />
    </g>
  </svg>
);

export default Clock;
