const getUrlParameter = url => parameter => {
  if (window.URLSearchParams) {
    // IE 11 doesn't support URLSearchParams method
    const params = new window.URLSearchParams(window.location.search);

    return params.get(parameter);
  }

  const formatted = parameter.replace(/[[\]]/g, '\\$&');
  const regex = new RegExp(`[?&]${formatted}(=([^&#]*)|&|#|$)`);
  const results = regex.exec(url);
  if (!results) return null;
  if (!results[2]) return '';
  return decodeURIComponent(results[2].replace(/\+/g, ' '));
};

const getURLParameters = (params, url = window.location.href) => params.map(getUrlParameter(url));

const setURLParameters = (searchString, newParams) => {
  const params = new URLSearchParams(searchString);
  Object.entries(newParams).forEach(([param, value]) =>
    value || typeof value === 'number' ? params.set(param, value) : params.delete(param),
  );

  return params.toString();
};

const URLSearchStringToObject = searchString =>
  Object.fromEntries(new URLSearchParams(searchString));

const orderParamToSortingObject = order => {
  const asc = order[0] !== '-';
  const param = asc ? order : order.slice(1);
  return { param, asc };
};

export { getURLParameters, setURLParameters, URLSearchStringToObject, orderParamToSortingObject };
