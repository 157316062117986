import {
  GET_ISSUERS,
  GET_ISSUERS_ERROR,
  GET_ISSUERS_SUCCESS,
  GET_SHAREHOLDING_ISSUERS,
  GET_SHAREHOLDING_ISSUERS_ERROR,
  GET_SHAREHOLDING_ISSUERS_SUCCESS,
} from 'app-state/constants/issuers';

type IssuersState = {
  data?: any[];
  errors: {
    status: number;
    response: { details: string }[];
  } | null;
  loading: boolean;
};

type IssuersAction = {
  type: string;
  payload: {
    data?: any[];
    error?: any;
  };
};

export const defaultIssuersState: IssuersState = { data: [], errors: null, loading: false };

// eslint-disable-next-line max-len
export const issuersReducer = (
  state = defaultIssuersState,
  action: IssuersAction,
): IssuersState => {
  switch (action.type) {
    case GET_ISSUERS:
      return { ...state, loading: true };
    case GET_ISSUERS_SUCCESS:
      return { ...state, data: action.payload.data, loading: false };
    case GET_ISSUERS_ERROR:
      return { ...state, errors: action.payload.error, loading: false };
    default:
      return state;
  }
};

type ShareholdingIssuersState = {
  data?: { issuerId: string; name: string }[];
  errors: {
    status: number;
    response: { details: string }[];
  } | null;
  loading: boolean;
};

type ShareholdingIssuersAction = {
  type: string;
  payload: {
    data?: any[];
    error?: any;
  };
};

const defaultShareholdingIssuersState: ShareholdingIssuersState = {
  data: [],
  errors: null,
  loading: false,
};

export const shareholdingIssuersReducer = (
  state = defaultShareholdingIssuersState,
  action: ShareholdingIssuersAction,
): ShareholdingIssuersState => {
  switch (action.type) {
    case GET_SHAREHOLDING_ISSUERS:
      return { ...state, loading: true };
    case GET_SHAREHOLDING_ISSUERS_SUCCESS:
      return { ...state, data: action.payload.data, loading: false };
    case GET_SHAREHOLDING_ISSUERS_ERROR:
      return { ...state, errors: action.payload.error, loading: false };
    default:
      return state;
  }
};
