import styled from 'styled-components';

import { FormikButton } from 'shared-parts/components/button';
import { alternative, neutral800, primary } from 'shared-parts/constants/colors';
import { mainFont, oswaldFont } from 'shared-parts/constants/fonts';

export const ModalWrapper = styled.div`
  width: 580px;
  max-width: 90vw;
`;

export const ConfirmButton = styled(FormikButton)`
  height: 40px;
  margin-left: 15px;
  background: ${alternative};
  font-size: 14px;
`;

export const ButtonsWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 32px auto 36px;
  font-family: ${mainFont};
`;

export const CautionIconWrapper = styled.div`
  display: flex;
  justify-content: center;
  margin: 46px 0 25px;
`;

export const ModalTitle = styled.p`
  font-size: 35px;
  font-weight: 200;
  color: ${primary};
  text-align: center;
  margin-bottom: 12px;
  font-family: ${oswaldFont};
`;

export const ModalText = styled.div`
  color: ${neutral800};
  margin: 25px;
  text-align: center;
  font-family: ${mainFont};
`;
