import * as constants from 'app-state/constants';

export const fetchDeal = params => ({ type: constants.FETCH_DEAL, ...params });
export const fetchDealSuccess = data => ({ type: constants.FETCH_DEAL_SUCCESS, data });
export const fetchDealError = error => ({ type: constants.FETCH_DEAL_ERROR, error });
export const resetDeal = () => ({ type: constants.RESET_DEAL });

export const fetchDealDocuments = payload => ({ type: constants.FETCH_DEAL_DOCUMENTS, payload });
export const fetchDealDocumentsSuccess = payload => ({
  type: constants.FETCH_DEAL_DOCUMENTS_SUCCESS,
  payload,
});
export const resetDealDocuments = () => ({ type: constants.RESET_DEAL_DOCUMENTS });

export const agreeNDADealDocument = payload => ({
  type: constants.AGREE_NDA_DEAL_DOCUMENT,
  payload,
});
export const updateAgreedNDADealDocument = payload => ({
  type: constants.UPDATE_AGREED_NDA_DEAL_DOCUMENT,
  payload,
});

export const requestDealNDAESignature = payload => ({
  type: constants.REQUEST_DEAL_NDA_E_SIGNATURE,
  payload,
});

export const showOrderDetailsForm = modalOptions => ({
  type: constants.SHOW_ORDER_DETAILS_FORM,
  modalOptions,
});
export const showOrderDetailsFormError = error => ({
  type: constants.SHOW_ORDER_DETAILS_FORM_ERROR,
  error,
});

export const fetchDealPagesData = params => ({ type: constants.FETCH_DEAL_PAGES_DATA, ...params });
export const fetchDealPagesDataSuccess = () => ({ type: constants.FETCH_DEAL_PAGES_DATA_SUCCESS });
export const fetchDealPagesDataError = error => ({
  type: constants.FETCH_DEAL_PAGES_DATA_ERROR,
  error,
});

export const fetchPage = (id, restricted) => ({ type: constants.FETCH_PAGE, id, restricted });
export const updatePage = () => ({ type: constants.UPDATE_PAGE });
export const fetchPageSuccess = data => ({ type: constants.FETCH_PAGE_SUCCESS, data });
export const fetchPageError = error => ({ type: constants.FETCH_PAGE_ERROR, error });
export const fetchPageRestricted = () => ({ type: constants.FETCH_PAGE_RESTRICTED });
export const resetPage = () => ({ type: constants.RESET_PAGE });

export const showRiskBanner = () => ({ type: constants.SHOW_RISK_BANNER });
export const hideRiskBanner = () => ({ type: constants.HIDE_RISK_BANNER });

export const fetchIssuance = (companyUuid, modelUuid) => ({
  type: constants.FETCH_ISSUANCE,
  companyUuid,
  modelUuid,
});
export const fetchIssuanceSuccess = issuance => ({
  type: constants.FETCH_ISSUANCE_SUCCESS,
  issuance,
});
export const fetchIssuanceError = error => ({ type: constants.FETCH_ISSUANCE_ERROR, error });
