import { Box, styled } from '@mui/material';

export const WidgetContent = styled(Box, { label: 'WidgetContent' })(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  // alignItems: 'center',
  paddingRight: theme.spacing(4),

  marginTop: 'auto',
  marginBottom: 'auto',
}));
