import { AnyAction } from 'redux';
import { call, put, takeLatest } from 'redux-saga/effects';

import { showModal } from 'app-state/actions';
import { ResponseGenerator } from 'app-state/types';

import API from 'constants/api';
import { request } from 'helpers';
import { ModalFailed } from 'shared-parts/components';

import * as constants from './share-plan-holders.constants';

function* getSharePlanHolder(action: AnyAction) {
  try {
    const response: ResponseGenerator = yield call(
      request,
      `${API.ShareIncentivePlans.Prefix()}${API.ShareIncentivePlans.GetSharePlanHolderById(
        action.payload.id,
      )}`,
      'GET',
      null,
      { snakeCaseParams: false },
    );

    yield put({
      type: constants.GET_SHARE_PLAN_HOLDER.SUCCESS,
      data: response.data,
    });
  } catch (e: any) {
    yield put({
      type: constants.GET_SHARE_PLAN_HOLDER.FAILURE,
      error: e.message,
    });
    yield put(
      showModal({
        component: ModalFailed,
        closable: true,
        showHeader: false,
      }),
    );
  }
}

function* getSharePlanHoldersWatcher() {
  yield takeLatest(constants.GET_SHARE_PLAN_HOLDER.REQUEST, getSharePlanHolder);
}

export { getSharePlanHoldersWatcher, getSharePlanHolder };
