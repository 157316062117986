import { GridApi, GridFilterItem, GridFilterModel } from '@mui/x-data-grid-premium';
import set from 'lodash/set';

import type { GraphQLFilter } from '@frontend/http';

import { GridQueryColDef } from './use-gql-datagrid';

type StringOps = 'contains' | 'equals' | 'startsWith' | 'endsWith' | 'isAnyOf';
type NumericOps = '=' | '!=' | '>' | '>=' | '<' | '<=' | 'isAnyOf';
type DateOps = 'is' | 'not' | 'after' | 'onOrAfter' | 'before' | 'onOrBefore';
type BooleanOps = 'is';
type SingleSelectOps = 'is' | 'not' | 'isAnyOf';
type CommonOps = 'isEmpty' | 'isNotEmpty';

type Operations = StringOps | NumericOps | DateOps | CommonOps | BooleanOps | SingleSelectOps;

const valuelessOperators: (Operations | string)[] = ['isEmpty', 'isNotEmpty'];

type GraphQLOperators =
  | 'eq'
  | 'ne'
  | 'like'
  | 'in'
  | 'nin'
  | 'ilike'
  | 'overlap'
  | 'contains'
  | 'contained'
  | 'gt'
  | 'gte'
  | 'lt'
  | 'lte';

export const toGraphQLOperator = (op: Operations | string): GraphQLOperators => {
  switch (op) {
    // String
    case 'contains':
    case 'startsWith':
    case 'endsWith':
      return 'ilike';

    case 'equals':
    case '=':
    case 'is':
    case 'isEmpty':
      return 'eq';

    case 'isNotEmpty':
    case 'not':
      return 'ne';

    case 'isAnyOf':
      return 'in';

    // Numeric
    case '>':
      return 'gt';
    case '>=':
      return 'gte';
    case '<':
      return 'lt';
    case '<=':
      return 'lte';
    case '!=':
      return 'ne';

    // Date
    case 'after':
      return 'gt';
    case 'before':
      return 'lt';
    case 'onOrAfter':
      return 'gte';
    case 'onOrBefore':
      return 'lte';
    default: {
      throw new Error(`Unexpected operator: ${op}`);
    }
  }
};

export const toGraphQLValue = (op: Operations | string, value: unknown) => {
  switch (op) {
    // String
    case 'contains':
      return `%${value}%`;
    case 'startsWith':
      return `${value}%`;
    case 'endsWith':
      return `%${value}`;
    case 'isEmpty':
    case 'isNotEmpty':
      return null;

    // Numeric
    case '=':
    case '>':
    case '>=':
    case '<':
    case '<=':
    case '!=':
      return Number(value);

    default:
      return value;
  }
};

export type FilterTransformer = (operator: Operations | string, value: any) => GraphQLFilter | null;

// TODO: This is probably not exhaustive but is most of the common filters
// that will appear in day-to-day. May need further maintenance.
// But for as of today, it is enough
// TODO: Maybe use api ref to get more details about column/metadata
// and move options to column-based/definition based?
export const toGraphQLFilter = (
  model: GridFilterModel | undefined,
  gridApi: GridApi,
  options?: {
    additionalFilters?: GraphQLFilter[];
    flattenQueries?: boolean;
  },
) => {
  // We want to ignore empty filters like "foo = ''"
  // But some allow a valueless-field e.g. is not empty
  const isActiveFilter = (item: GridFilterItem) => {
    if (valuelessOperators.includes(item.operator)) {
      return true;
    }

    if (Array.isArray(item.value)) {
      return item.value.length > 0;
    }

    return item.value != null && item.value !== '';
  };

  let filterSet = (model ? model.items : ([] as GridFilterItem[]))
    .filter(item => isActiveFilter(item))
    .map(item => {
      // Check if this filter should be transformed

      // Get the transformer via col def or options
      const col = gridApi.getColumn?.(item.field) as unknown as GridQueryColDef;
      const transformer = col?.filterTransformer;

      if (transformer) {
        // We support returning null to 'passthrough'
        // Not great but it works (probably)
        const transformed = transformer(item.operator, item.value);

        if (transformed !== null) {
          return transformed;
        }
      }

      const obj = {};

      set(obj, item.field, {
        [toGraphQLOperator(item.operator)]: toGraphQLValue(item.operator, item.value),
      });

      return obj;
    });

  if (options?.additionalFilters?.length) {
    // Additional filters should go on each OR query so that it gets applied
    // to each individual or. e.g. if the additional query is status = active,
    // then the resulting filter is
    // (a = b AND status = active) OR (c = d AND status = active)
    // which 99% of the time is what we want.
    if (model?.logicOperator === 'or') {
      filterSet.forEach(filter => {
        options.additionalFilters?.forEach(additional => {
          Object.assign(filter, additional);
        });
      });
    } else {
      filterSet = filterSet.concat(options.additionalFilters);
    }
  }

  if (filterSet.length === 0) {
    return {};
  }

  if (filterSet.length === 1) {
    return filterSet[0];
  }

  // THIS IS PURELY FOR SECURITIES OVERVIEW AS THE GQL BACKEND IS WEIRD
  // AND DONE MANUALLY.
  if (model?.logicOperator !== 'or' && options?.flattenQueries) {
    return filterSet.reduce((obj, curr) => {
      Object.assign(obj, curr);
      return obj;
    }, {});
  }

  return {
    [model?.logicOperator || 'and']: filterSet,
  };
};
