import { call, put, takeEvery } from 'redux-saga/effects';

import * as actions from 'app-state/actions';
import * as constants from 'app-state/constants';

import API from 'constants/api';
import { request } from 'shared-parts/helpers';

function* getCurrencyRate({ currencies, calcContext, setFieldTouched }) {
  try {
    const { currencyFrom, currencyTo } = currencies;
    const currentCurrencyPair = `${currencyFrom}${currencyTo}`;

    if (currencyFrom !== currencyTo) {
      const { data } = yield call(
        request,
        API.Investment.exchangeRate(currencyFrom, currencyTo, calcContext),
      );

      yield put(actions.getCurrencyRateSuccess(currentCurrencyPair, data.exchangeRate));

      if (setFieldTouched) {
        setFieldTouched('intendedAmount', true);
      }
    } else {
      yield put(actions.getCurrencyRateSuccess(currentCurrencyPair, 1));
    }
  } catch (e) {
    yield put(actions.getCurrencyRateError(e));

    if (e.status === 401) {
      yield put(actions.hideModal());
    }
  }
}

function* getCurrencyRateWatcher() {
  yield takeEvery(constants.GET_CURRENCY_RATE, getCurrencyRate);
}

export { getCurrencyRate, getCurrencyRateWatcher };
