import {
  CREATE_BONUS_CONTRIBUTION_ORDER,
  CREATE_BONUS_CONTRIBUTION_ORDER_ERROR,
  CREATE_BONUS_CONTRIBUTION_ORDER_SUCCESS,
  CREATE_ORDER,
  CREATE_ORDER_ERROR,
  CREATE_ORDER_SUCCESS,
} from 'app-state/constants/order';

import { ActionWithPayload } from './types';

export const createOrder: ActionWithPayload = payload => ({
  type: CREATE_ORDER,
  payload,
});

export const createOrderSuccess: ActionWithPayload = payload => ({
  type: CREATE_ORDER_SUCCESS,
  payload,
});

export const createOrderError: ActionWithPayload = payload => ({
  type: CREATE_ORDER_ERROR,
  payload,
});

export const createBonusContributionOrder: ActionWithPayload = payload => ({
  type: CREATE_BONUS_CONTRIBUTION_ORDER,
  payload,
});

export const createBonusContributionOrderSuccess: ActionWithPayload = payload => ({
  type: CREATE_BONUS_CONTRIBUTION_ORDER_SUCCESS,
  payload,
});

export const createBonusContributionOrderError: ActionWithPayload = payload => ({
  type: CREATE_BONUS_CONTRIBUTION_ORDER_ERROR,
  payload,
});
