import styled from 'styled-components';

import { Screens } from 'shared-parts/constants';

const SumSubContainer = styled.div`
  padding-top: 100px;
`;

const CancelButton = styled.button`
  width: 24px;
  height: 24px;
  display: block;
  position: absolute;
  right: 0;
  padding: 0;
  background: transparent;
  border: none;
  cursor: pointer;
  right: 50px;
  top: 30px;

  @media (max-width: ${Screens.md}) {
    top: 25px;
    right: 21px;
  }
`;

const CancelIcon = styled.img`
  max-width: 100%;
`;

export { SumSubContainer, CancelButton, CancelIcon };
