import React, { ReactNode } from 'react';

import SomethingWentWrong from './something-went-wrong';

class ErrorBoundary extends React.Component<{ children: ReactNode }> {
  state = { error: '' };

  static getDerivedStateFromError(error: { stack?: string }): { error: string } {
    return { error: error.toString() };
  }

  // componentDidCatch(error: Error, errorInfo: React.ErrorInfo) {
  // You can also log the error to an error reporting service
  // logErrorToMyService(error, errorInfo);
  // }

  render(): JSX.Element | React.ReactNode {
    const { error } = this.state;

    if (error) {
      return <SomethingWentWrong machineCode={error} />;
    }

    return this.props.children;
  }
}

export default ErrorBoundary;
