import { Currency } from '@frontend/utils';

export enum IssuanceStatus {
  COMPLETED = 'completed',
  LIVE = 'live',
  PENDING = 'pending',
  PRELAUNCH = 'prelaunch',
  CLOSED = 'closed',
}

export type InstrumentType = 'equity' | 'debt' | 'fund';

export enum DealType {
  Debt = 'debt',
  Equity = 'equity',
  Fund = 'fund',
  Alternative = 'alternative_investment_vehicle',
}

export enum DealSubType {
  ClosedEnd = 'closed_end',
  Fund = 'fund',
}

export enum SecurityType {
  Shares = 'share',
  ConvertibleNote = 'convertible note',
  NonConvertibleCorporateLoan = 'non convertible corporate loan',
  Safe = 'SAFE',
  DigitalBond = 'digital bond',
  Investment = 'investment',
  Token = 'token',
}

enum NdaProcess {
  TICKBOX = 'tick_box',
  STANDARD_SIGNATURE = 'standard_signature',
  E_SIGNATURE = 'e_signature',
}

export enum DocumentType {
  TERM_SHEET = 'TermSheetDocument',
  NDA = 'NdaDocument',
}

export interface Document {
  agentName: string;
  createdAt: Date;
  editUrl?: string;
  fileSize: number;
  id: number;
  isTemplate?: boolean;
  name: string;
  ndaProcess?: NdaProcess;
  timezone: string;
  token: string;
  type: DocumentType;
  updatedAt: string;
  uploadedBy: string;
  url: string;
}

export interface BankAccount {
  currency: string;
  bankUuid?: string;
}

interface WatermarkConfiguration {
  customText?: string;
  includeUserFullName: boolean;
  includeTimestamp: boolean;
}

interface Envelopes {
  createdAt: string;
  documentType: string;
  signedBy: string[];
  status: string;
  updatedAt: string;
}

export interface Deal {
  allowedCurrencies: string[];
  availableTokens: string;
  baseCurrency: Currency;
  company: {
    countryCode: string;
    description: string;
    equity: string | null;
    id: number;
    incorporationDate: string | null;
    incorporationId: string | null;
    name: string;
    uuid: string;
  };
  envelopes: Envelopes[];
  createdAt: string;
  dealType: DealType;
  securitySubtype?: DealSubType;
  description: string | null;
  equityPercentage: string;
  id: number;
  images: {
    background: {
      cover: string;
      small: string;
    };
    logo: {
      cover: string;
      small: string;
    };
  };
  minInvestableUnits: number;
  maxInvestableUnits: number;
  name: string;
  ownershipUnit: string;
  permalink: string;
  pricePerToken: string;
  raisedAmount: string;
  securityName: string;
  softTargetAmount: string;
  status: IssuanceStatus;
  subscriptionEnd: string;
  autoCancelOrders: string | boolean;
  autoApproveOrders: string | boolean;
  allAnchorInvestors: boolean;
  spaRequired: string | boolean;
  subscriptionStart: string;
  ordersMustBeFilledAt: string;
  targetAmount: number;
  totalTokensEmited: number;
  totalTokensSold: string;
  unitTruncateScale: number;
  preLaunchRaisedAmount: number;
  investmentAmount: number;
  indicativeSharePrice: number;
  preMoneyValuation: number;
  decimalPoints: number;
  sharePrecision: string;
  private: boolean;
  timezone: string;
  timezoneOffsetNumber: number;
  uuid: string;
  agentPermissions: unknown[];
  drawdownThreshold: string;
  dealDetail: {
    issuerName: string;
  };

  fundName?: string;
  equalisationInterestPc?: string;
  paymentMethod?: string;
  bankAccounts: BankAccount[];
  documents?: Document[];
  ndaRequired: boolean;
  ndaProcess?: NdaProcess;
  watermarkEnabled: boolean;
  watermarkConfiguration?: WatermarkConfiguration;
  investmentMinimumThreshold: string;
  investmentMaximumThreshold: string;
  published: boolean;
}

export type DealSummary = {
  closeDate: string;
  headlineTargetAmount: string;
  launchDate: string;
  maximumSubscriptionAmount: string;
  name: string;
  status: IssuanceStatus;
  type: string;
  valueFunded: string;
  baseCurrency: string;
  currency: string;
  dealType: DealType;
  securitySubtype: DealSubType;
  uuid: string;
};

export interface ClosedEndFundDeal extends Deal {
  dealType: DealType.Fund;
  dealSubType: DealSubType.Fund | DealSubType.ClosedEnd;
  closings: {
    name: string;
    iteration: number;
    active: boolean;
    startingAt: string;
    closingAt: string;
    targetAmount: string;
  }[];
}

export interface NomineeFundDeal extends Deal {
  dealType: DealType.Alternative;
}

export const isClosedEndFundDeal = (deal: Deal | DealSummary): deal is ClosedEndFundDeal => {
  return deal.dealType === DealType.Fund && deal.securitySubtype === DealSubType.ClosedEnd;
};

export const isNomineeFundDeal = (deal: Deal | DealSummary): deal is NomineeFundDeal => {
  return deal.dealType === DealType.Alternative;
};
