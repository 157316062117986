import humps from 'humps';

import { ApiPlatformSetting, PlatformSettings } from './types';

export const convertToApiResponseToPlatformSettings = (data: ApiPlatformSetting[] | undefined) => {
  return data?.reduce((acc, current) => {
    return {
      ...acc,
      [humps.camelize(current.name)]: {
        value: current.value,
        config: {
          isCurrentlyRegulated: current.isCurrentlyRegulated,
          choices: current.choices,
          settingType: current.settingType,
        },
      },
    };
  }, {} as PlatformSettings);
};
