import { FC } from 'react';
import { connect } from 'react-redux';

import { Form, Formik } from 'formik';
import { Dispatch } from 'redux';
import styled from 'styled-components';
import * as Yup from 'yup';

import { showModal } from 'app-state/actions';

import CountrySelect from 'modules/onboard/individual/components/country-select';
import { isPlaidSupportedCountry } from 'modules/plaid/utils';
import { Currency } from 'modules/wallet-balance/models';
import { CircleInfoIcon } from 'svg-icons';
import { Dialog, DialogActions } from 'shared/dialogs';
import { FormControl, FormLabel } from 'shared/forms';
import MailLink from 'shared/mail-link';
import { ActionButton, SecondaryActionButton } from 'shared-parts/components/button/action-buttons';
import InfoModal from 'shared-parts/components/modal/info-modal';
import Tooltip from 'shared-parts/components/tooltip';
import { Colors, Fonts } from 'shared-parts/constants';

import PlaidUserAgreementDialog from '../../plaid/plaid-user-agreement-dialog';
import ShowPlaidWindowDialog from '../../plaid/show-plaid-window-dialog';

import CreateWithdrawDialog from './create-withdraw-dialog';

const UnsupportedCurrencyText = styled.div`
  text-align: center;
`;

const TooltipIcon = styled.span`
  margin: 0px 8px;
`;

const TooltipMessage = styled.div`
  background-color: ${Colors.alternative};
  color: ${Colors.alternativeContrastText};
  text-align: center;
  border-radius: 6px;
  padding: 5px 0;
  font-weight: 300;
  font-size: 14px;
  font-family: ${Fonts.mainFont};
`;

type WithdrawDialogProps = ReturnType<typeof mapDispatchToProps> & {
  availableAmount: number;
  currency: Currency;
  supportedCountries: string[];
};

type WithdrawRequest = {
  country: string;
};

const initialValues: WithdrawRequest = {
  country: 'GB',
};

const InitialiseWithdrawDialog: FC<React.PropsWithChildren<WithdrawDialogProps>> = ({
  currency,
  supportedCountries = [],
  cancelWithdrawal,
  invalidWithdrawal,
  initiateWithdrawal,
}) => {
  if (currency !== 'GBP') {
    return (
      <Dialog>
        <UnsupportedCurrencyText>
          If you would like to withdraw {currency} from your wallet please contact
          <MailLink text="support" />.
        </UnsupportedCurrencyText>
      </Dialog>
    );
  }

  return (
    <Formik
      initialValues={initialValues}
      validationSchema={Yup.object({
        country: Yup.string().min(1).required('Required.'),
      })}
      onSubmit={values => {
        if (isPlaidSupportedCountry(values.country)) {
          initiateWithdrawal();
        } else {
          invalidWithdrawal();
        }
      }}
    >
      {({ isValid }) => (
        <Dialog>
          <Form>
            <FormControl>
              <FormLabel htmlFor="country">
                Which country is your bank account in?
                <Tooltip
                  content={() => (
                    <TooltipMessage>
                      The bank account that you’d like your withdrawn funds deposited into
                    </TooltipMessage>
                  )}
                  hideArrow
                  position="right-end"
                  autohide={false}
                >
                  {() => (
                    <TooltipIcon>
                      <CircleInfoIcon />
                    </TooltipIcon>
                  )}
                </Tooltip>
              </FormLabel>
              <CountrySelect
                id="country"
                name="country"
                isIndividual
                supportedCountries={supportedCountries}
                unsupportedCountries={[]}
                value={undefined}
                notSupportedCountry={false}
                isFirstAccountInfoPart={false}
              />
            </FormControl>

            <DialogActions>
              <SecondaryActionButton type="button" onClick={cancelWithdrawal}>
                Cancel
              </SecondaryActionButton>
              <ActionButton disabled={!isValid} type="submit">
                Withdraw
              </ActionButton>
            </DialogActions>
          </Form>
        </Dialog>
      )}
    </Formik>
  );
};

const mapDispatchToProps = (dispatch: Dispatch) => ({
  cancelWithdrawal: () =>
    dispatch(
      showModal({
        closable: true,
        component: InfoModal,
        title: 'Withdrawal Cancelled',
        additionalText: 'No funds will be withdrawn from your cash balance.',
        buttonText: 'OK',
        modalWidth: 600,
        iconSize: 40,
      }),
    ),
  initiateWithdrawal: () =>
    dispatch(
      showModal({
        closable: true,
        component: PlaidUserAgreementDialog,
        onAgreement: () =>
          dispatch(
            showModal({
              closable: true,
              component: ShowPlaidWindowDialog,
              onReady: () =>
                dispatch(
                  showModal({
                    closable: true,
                    component: CreateWithdrawDialog,
                    showHeader: true,
                    header: 'WITHDRAW FUNDS',
                  }),
                ),
            }),
          ),
      }),
    ),
  invalidWithdrawal: () =>
    dispatch(
      showModal({
        closable: true,
        component: InfoModal,
        title: 'Please contact support',
        icon: null,
        additionalText: (
          <>
            If you would like to withdraw from your wallet to a non-UK or Channel Islands bank
            account please contact
            <MailLink text="support" />.
          </>
        ),
      }),
    ),
});

export default connect(null, mapDispatchToProps)(InitialiseWithdrawDialog);
