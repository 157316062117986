// TODO Could replace with this

// let number2 = 1234.56789; // floating point example
// number2.toLocaleString('en-US', {maximumFractionDigits:2}); // "1,234.57"

export const addCommasToNumber = (number: string) => {
  const intPart = parseInt(number, 10);
  const indexOfFractionalPart = number.toString().indexOf('.');
  const fractionalPart =
    indexOfFractionalPart > 0 ? number.toString().slice(indexOfFractionalPart) : '';
  const intPartWithCommas = intPart.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',');

  return `${intPartWithCommas}${fractionalPart}`;
};
