import * as constants from 'app-state/constants';

export const getShareOptionsDisplayState = params => ({
  type: constants.GET_SHARE_OPTIONS_DISPLAY_STATE,
  params,
});
export const getShareOptionsDisplayStateSuccess = active => ({
  type: constants.GET_SHARE_OPTIONS_DISPLAY_STATE_SUCCESS,
  active,
});
export const getShareOptionsDisplayStateError = error => ({
  type: constants.GET_SHARE_OPTIONS_DISPLAY_STATE_ERROR,
  error,
});

export const getShareOptions = params => ({ type: constants.GET_SHARE_OPTIONS, params });
export const getShareOptionsSuccess = data => ({ type: constants.GET_SHARE_OPTIONS_SUCCESS, data });
export const getShareOptionsError = error => ({ type: constants.GET_SHARE_OPTIONS_ERROR, error });
export const clearShareOptions = () => ({ type: constants.CLEAR_SHARE_OPTIONS });

export const getShareOption = (companyUuid, params) => ({
  type: constants.GET_SHARE_OPTION,
  companyUuid,
  params,
});
export const getShareOptionSuccess = data => ({ type: constants.GET_SHARE_OPTION_SUCCESS, data });
export const getShareOptionError = error => ({ type: constants.GET_SHARE_OPTION_ERROR, error });
export const clearShareOption = () => ({ type: constants.CLEAR_SHARE_OPTION });
