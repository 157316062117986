import styled from 'styled-components';

import * as Colors from 'shared-parts/constants/colors';

const MemberPhotoBlock = styled.div`
  text-align: left;
  margin-bottom: 10px;

  img {
    max-width: 100%;

    &.small {
      height: 75px;
      width: 75px;
    }
  }
`;

const MemberTextBlock = styled.div`
  color: ${Colors.neutral800};
  margin-bottom: 0;
`;

export { MemberPhotoBlock, MemberTextBlock };
