export type SharePlanHoldersStateType = {
  sharePlanHolder: {
    loading: boolean;
    error?: string;
    data: { bonusAvailable: boolean };
  };
};

const initialState: SharePlanHoldersStateType = {
  sharePlanHolder: {
    loading: false,
    error: undefined,
    data: { bonusAvailable: false },
  },
};

export default initialState;
