import { Dispatch, SetStateAction, useEffect, useRef, useState } from 'react';

type UseIntersect = (props: {
  root?: Element | null;
  rootMargin?: string;
  threshold?: number;
}) => [Dispatch<SetStateAction<Element | null>>, IntersectionObserverEntry | null];

const useIntersect: UseIntersect = ({ root = null, rootMargin, threshold = 0 }) => {
  const [entry, updateEntry] = useState<IntersectionObserverEntry | null>(null);
  const [node, setNode] = useState<Element | null>(null);

  const observer = useRef(
    new window.IntersectionObserver(([newEntry]) => updateEntry(newEntry), {
      root,
      rootMargin,
      threshold,
    }),
  );

  useEffect(() => {
    const { current: currentObserver } = observer;
    currentObserver.disconnect();

    if (node) currentObserver.observe(node);

    return () => currentObserver.disconnect();
  }, [node, root]);

  return [setNode, entry];
};

export default useIntersect;
