import type { FC, MouseEvent } from 'react';
import { NavLink } from 'react-router-dom';

type Props = {
  url: string;
  title: string;
  isMailTo: boolean;
  id?: string;
  target?: string;
  onClick?: (e?: MouseEvent<HTMLAnchorElement>) => void;
  rel?: string;
};

const NavLinkOrAnchor: FC<React.PropsWithChildren<Props>> = ({
  id,
  target,
  isMailTo,
  title,
  onClick,
  rel,
  children,
  url,
}) => {
  if (target === '_blank' || isMailTo) {
    return (
      <a href={url} title={title} onClick={onClick} target={target} rel={rel}>
        {children}
      </a>
    );
  }

  return (
    <NavLink to={url} id={id} title={title} onClick={onClick} target={target} rel={rel}>
      {children}
    </NavLink>
  );
};

export default NavLinkOrAnchor;
