import { Component } from 'react';

import allCountries from 'shared-parts/constants/all-countries';

import { Loqate, Manual } from './components';

class Address extends Component {
  state = {
    isManual: this.props.isManual,
    loqateAddress: {},
    typedAddress: '',
    loqateAutocomplete: '',
  };

  control = {};

  componentDidMount() {
    window.pca.onq = []; // clear queue is a must
    window.pca.on('options', this.setPcaOptions);

    if (window.pca.load) {
      window.pca.on('load', this.addLoqateListener);
      window.pca.on('ready', window.pca.load);
    }

    if (this.props.country && !window.pca.load) {
      this.switchToManualInput();
    }
  }

  componentDidUpdate(prevProps) {
    if (prevProps.country !== this.props.country) {
      this.resetAddress(prevProps.country);
    }
    if (this.props.isManual && prevProps.isManual !== this.props.isManual) {
      this.switchToManualInput();
    }
  }

  getTypedAddress(addressArray) {
    return addressArray.filter(f => f).join(', ');
  }

  getCountryAlpha3Code = () =>
    !this.props.country
      ? ''
      : allCountries.find(country => country.countryCode === this.props.country).alpha3Code;

  setPcaOptions = (type, id, options) => {
    options.search.countries = this.getCountryAlpha3Code(); // eslint-disable-line
  };

  setCountry = () => {
    if (this.control.setCountry) {
      this.control.setCountry(this.getCountryAlpha3Code());
    }
  };

  setupControl = control => {
    if (this.props.disableAutocomplete) {
      this.setState({ loqateAutocomplete: 'none' });
    }
    try {
      this.control = control;
      this.setCountry();
      this.control.listen('populate', this.populateCallback);
      this.control.listen('noresults', this.handleNoResultsFoundError);
      this.control.listen('select', this.resetLoqateError);
      this.control.listen('search', this.resetLoqateError);
    } catch (e) {
      console.error('pca.Address is not available.', e); // very rare case
      this.removeAddress();
    }
  };

  setLoqateError = error => this.props.setFieldError('address', error);

  resetLoqateError = () => this.setLoqateError(''); // prevent error blinking on pca action

  handleNoResultsFoundError = () =>
    this.setLoqateError('Please try again or enter address manually.');

  addLoqateListener = (type, key, control) => {
    if (!this.state.isManual) {
      this.setupControl(control);
    }
  };

  switchToManualInput = () => {
    if (this.props.setManualFormValues) {
      const { Line1, Line2, City, PostalCode, ProvinceName } = this.state.loqateAddress;

      const manualFormValues = {
        addressFirstLine: Line1,
        line2: Line2,
        postCode: PostalCode,
        city: City,
        state: ProvinceName,
      };

      this.props.setManualFormValues({
        ...this.props.formValues,
        ...manualFormValues,
      });
    }

    if (this.props.onMethodChange) {
      this.props.onMethodChange(true);
    }

    if (this.props.onAddressChange) {
      this.props.onAddressChange({}, this.props.setFieldValue);
    }

    this.setState({ isManual: true });
  };

  removeAddress = () => {
    this.setState(
      {
        loqateAddress: {},
        typedAddress: '',
      },
      this.switchToManualInput,
    );
  };

  populateCallback = loqateAddress => {
    const { Line1, Line2, Line3, Line4, Line5, City, CountryIso2, PostalCode, ProvinceName } =
      loqateAddress;
    const invalidAddress = !Line1 || !City || !PostalCode;
    const error = invalidAddress ? 'Invalid address' : undefined;

    if (Line1 && this.props.onAddressChange) {
      this.props.onAddressChange(
        {
          line1: Line1,
          line2: Line2,
          city: City,
          state: ProvinceName,
          postCode: PostalCode,
          countryCode: CountryIso2,
        },
        this.props.setFieldValue,
      );
    }

    this.setLoqateError(error);

    this.props.setFieldTouched('address', true); // required to show invalid address error in case selected address country is different from country in select
    this.setState({
      loqateAddress,
      typedAddress: this.getTypedAddress([Line1, Line2, Line3, Line4, Line5, City, PostalCode]),
    });
  };

  resetAddress = () => {
    this.props.setFieldTouched('address', false); // removes address validation on new country props
    this.setState(
      {
        loqateAddress: {},
        typedAddress: '',
      },
      this.setCountry,
    );
  };

  render() {
    if (this.state.isManual) {
      return (
        <Manual
          {...this.props.manualAddressParams}
          disableAutocomplete={this.props.disableAutocomplete}
          {...this.state}
        />
      );
    }

    return (
      <Loqate
        {...this.props.loqateParams}
        {...this.state}
        disableAutocomplete={this.props.disableAutocomplete}
        countryIsNotSelected={!this.props.country}
        switchToManualInput={this.switchToManualInput}
      />
    );
  }
}

export default Address;
