import { FC, forwardRef, useCallback, useMemo } from 'react';
import CurrencyInput, { CurrencyInputProps } from 'react-currency-input-field';

import { useFormControl } from '@mui/material';

import { Input, InputProps } from './input';

// This is to make sure we dont include the size prop from currency input so that we can
// overwrite it later with the MUI one.
const NumberInputBase = forwardRef<HTMLInputElement, Omit<CurrencyInputProps, 'size'>>(
  (props, ref) => <CurrencyInput {...props} ref={ref} />,
);

export type NumberInputProps = Omit<
  CurrencyInputProps,
  'intlConfig' | 'customInput' | 'prefix' | 'suffix' | 'disableAbbreviations' | 'size'
> &
  InputProps & {
    /**
     * The locale to use when displaying the number. By default, this will use the
     * locale defined by the user's operating system. This can be overridden with a
     * locale id, e.g. de-DE.
     *
     * For example:
     *
     * en-GB would display 123456 as 1,234,567
     *
     * de-DE would display 123456 as 1.234.567
     *
     */
    locale?: string;
  };

const defaultOptions: Omit<CurrencyInputProps, 'size'> = {
  disableAbbreviations: true,
  allowNegativeValue: false,
};

// Turns out this is a very hard problem to solve.
// See: https://technology.blog.gov.uk/2020/02/24/why-the-gov-uk-design-system-team-changed-the-input-type-for-numbers/
// MUI are considering their own number input: https://github.com/mui/material-ui/issues/19154

/**
 * We use react-currency-input-field as a base here.
 * For more information, please see https://github.com/cchanxzy/react-currency-input-field
 */
export const NumberInput: FC<React.PropsWithChildren<NumberInputProps>> = ({
  prefix,
  suffix,
  locale,
  ...props
}) => {
  // We want to strip out any currency related stuff. We use memo as it needs to be
  // the same reference to prevent unnecessary updates and weird behaviour
  const intlConfig = useMemo<CurrencyInputProps['intlConfig']>(
    () => (locale ? { locale } : undefined),
    [locale],
  );

  // CurrencyInput has its own disabled state, we want to re-override it with our formcontrol
  // TODO: Do we need to pass more than just disabled in?
  const formControl = useFormControl();
  const disabled = formControl?.disabled;

  // This gets around the fact that CurrencyInput swallows up prefix/suffix props
  // which our Input needs to display adornments! No idea why the lint complains but it works
  // eslint-disable-next-line react-hooks/exhaustive-deps
  const ProxiedInput = useCallback(
    forwardRef((proxiedProps, ref) => (
      <Input {...proxiedProps} prefix={prefix} suffix={suffix} inputRef={ref} />
    )),
    [prefix, suffix],
  );

  return (
    <NumberInputBase
      customInput={ProxiedInput}
      intlConfig={intlConfig}
      disabled={disabled}
      {...defaultOptions}
      {...props}
    />
  );
};
