import {
  GET_CONTRIBUTION,
  GET_CONTRIBUTION_ERROR,
  GET_CONTRIBUTION_SUCCESS,
  UPDATE_CONTRIBUTION,
  UPDATE_CONTRIBUTION_ERROR,
  UPDATE_CONTRIBUTION_SUCCESS,
} from 'app-state/constants/contribution';

import { ActionWithPayload } from './types';

export const getContribution: ActionWithPayload = payload => ({
  type: GET_CONTRIBUTION,
  payload,
});

export const getContributionSuccess: ActionWithPayload = payload => ({
  type: GET_CONTRIBUTION_SUCCESS,
  payload,
});

export const getContributionError: ActionWithPayload = payload => ({
  type: GET_CONTRIBUTION_ERROR,
  payload,
});

export const updateContribution: ActionWithPayload = payload => ({
  type: UPDATE_CONTRIBUTION,
  payload,
});

export const updateContributionSuccess: ActionWithPayload = payload => ({
  type: UPDATE_CONTRIBUTION_SUCCESS,
  payload,
});

export const updateContributionError: ActionWithPayload = payload => ({
  type: UPDATE_CONTRIBUTION_ERROR,
  payload,
});
