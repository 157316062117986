import { useMutation, UseMutationOptions } from '@tanstack/react-query';
import { useCallback } from 'react';

import { useRequestClient } from './clients/providers/http-request-client';
import { RequestOptions, RequestResult } from './clients/request';
import { Params, replaceParams } from './utils/replace-params';

type Submit<TBody> = {
  payload?: TBody;
  options?: Partial<RequestOptions> & {
    params?: Params;
  };
};
export type SubmitOptions<TResult, TBody> = UseMutationOptions<
  RequestResult<TResult>,
  unknown,
  Submit<TBody>
>;

export const useSubmit = <TBody = any, TResult = any, TError = unknown>(
  url: string,
  method: 'POST' | 'PUT' | 'DELETE' | 'PATCH' | 'GET' = 'POST',
  opts?: SubmitOptions<TResult, TBody>,
) => {
  const request = useRequestClient();

  const { mutate, mutateAsync, ...mutation } = useMutation<
    RequestResult<TResult>,
    TError,
    Submit<TBody>
  >({
    ...opts,
    mutationFn: ({ payload, options: { params, ...requestOptions } = {} }: Submit<TBody>) => {
      const requestUrl = params ? replaceParams(url, params) : url;
      return request<TResult>(requestUrl, method, payload, requestOptions);
    },
  });

  const submit = useCallback(
    (
      payload?: TBody,
      options?: Partial<RequestOptions> & {
        params?: Params;
      },
      submitOptions?: SubmitOptions<TResult, TBody>,
    ) => {
      mutate({ payload, options }, submitOptions);
    },
    [mutate],
  );

  const submitAsync = useCallback(
    async (
      payload?: TBody,
      options?: Partial<RequestOptions> & {
        params?: Params;
      },
      submitOptions?: SubmitOptions<TResult, TBody>,
    ) => {
      await mutateAsync({ payload, options }, submitOptions);
    },
    [mutateAsync],
  );

  // Map the weird http status code thing
  const data = mutation.data?.data;

  return { ...mutation, data, submit, submitAsync };
};
