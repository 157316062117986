import * as constants from 'app-state/constants';

export const getCommunications = params => ({ type: constants.GET_COMMUNICATIONS, params });
export const getCommunicationsSuccess = data => ({
  type: constants.GET_COMMUNICATIONS_SUCCESS,
  data,
});
export const getCommunicationsError = () => ({ type: constants.GET_COMMUNICATIONS_ERROR });

export const clearCommunications = () => ({ type: constants.CLEAR_COMMUNICATIONS });

export const getCommunication = ({ communicationUuid }) => ({
  type: constants.GET_COMMUNICATION,
  communicationUuid,
});
export const getCommunicationSuccess = data => ({
  type: constants.GET_COMMUNICATION_SUCCESS,
  data,
});
export const getCommunicationError = () => ({ type: constants.GET_COMMUNICATION_ERROR });
export const clearCommunication = () => ({ type: constants.CLEAR_COMMUNICATION });

export const addCommunicationResponse = ({ communicationUuid, responseValue }) => ({
  type: constants.ADD_COMMUNICATION_RESPONSE,
  communicationUuid,
  responseValue,
});
export const addCommunicationResponseError = () => ({
  type: constants.ADD_COMMUNICATION_RESPONSE_ERROR,
});
