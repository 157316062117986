import { connect } from 'react-redux';

import { hideModal } from 'app-state/actions';

import { FinishIcon } from 'svg-icons';

import {
  ModalIconContainer,
  ModalText,
  ModalTitle,
  ModalWrapper,
  SubmitButton,
} from './modal-response-success.styled';

const ModalResponseSuccess = ({ close }) => (
  <ModalWrapper>
    <ModalIconContainer>{FinishIcon()}</ModalIconContainer>
    <ModalTitle>RESPONSE SENT</ModalTitle>
    <ModalText>Your response has been sent to the issuer</ModalText>
    <SubmitButton onClick={close}>OK</SubmitButton>
  </ModalWrapper>
);

const mapDispatchToProps = dispatch => ({
  close: () => dispatch(hideModal()),
});

export default connect(null, mapDispatchToProps)(ModalResponseSuccess);
