import {
  GET_SHARE_INCENTIVE_PLAN,
  GET_SHARE_INCENTIVE_PLAN_ERROR,
  GET_SHARE_INCENTIVE_PLAN_SUCCESS,
  GET_SHARE_INCENTIVE_PLANS,
  GET_SHARE_INCENTIVE_PLANS_ERROR,
  GET_SHARE_INCENTIVE_PLANS_SUCCESS,
} from 'app-state/constants/share-incentive-plan';

type ShareIncentivePlansState = {
  data?: any[];
  errors: {
    status: number;
    response: { details: string }[];
  } | null;
  loading: boolean;
};

type ShareIncentivePlansAction = {
  type: string;
  payload: {
    data: any[];
    error?: any;
  };
};

export const defaultShareIncentivePlansState: ShareIncentivePlansState = {
  data: [],
  errors: null,
  loading: false,
};

type ShareIncentivePlanState = {
  data?: any;
  errors: {
    status: number;
    response: { details: string }[];
  } | null;
  loading: boolean;
};

type ShareIncentivePlanAction = {
  type: string;
  payload: {
    data: any;
    error?: any;
  };
};

export const defaultShareIncentivePlanState: ShareIncentivePlanState = {
  data: {},
  errors: null,
  loading: false,
};

export const shareIncentivePlansReducer = (
  state = defaultShareIncentivePlansState,
  action: ShareIncentivePlansAction,
): ShareIncentivePlansState => {
  switch (action.type) {
    case GET_SHARE_INCENTIVE_PLANS:
      return { ...state, loading: true };
    case GET_SHARE_INCENTIVE_PLANS_SUCCESS:
      return { ...state, data: action.payload.data, loading: false };
    case GET_SHARE_INCENTIVE_PLANS_ERROR:
      return { ...state, errors: action.payload.error, loading: false };
    default:
      return state;
  }
};

export const shareIncentivePlanReducer = (
  state = defaultShareIncentivePlanState,
  action: ShareIncentivePlanAction,
): ShareIncentivePlanState => {
  switch (action.type) {
    case GET_SHARE_INCENTIVE_PLAN:
      return { ...state, loading: true };
    case GET_SHARE_INCENTIVE_PLAN_SUCCESS:
      return { ...state, data: action.payload.data, loading: false };
    case GET_SHARE_INCENTIVE_PLAN_ERROR:
      return { ...state, errors: action.payload.error, loading: false };
    default:
      return state;
  }
};
