import { Deal } from '@frontend/opportunities';
import { Currency } from '@frontend/utils';

import { AdjustmentHistoryItem } from './adjustment';

export type OrderFees = {
  type: string;
  calculation: string;
  amount: number;
  due: number;
  paid: number;
  outstanding: number;
};

export enum OrderStatus {
  PLACED = 'placed',
  PARTIALLY_FILLED = 'partially_filled',
  FILLED = 'filled',
  PLACED_AGREEMENT = 'placed_agreement',
  SIGNED_AGREEMENT = 'signed_agreement',
  COMPLETED = 'completed',
  SECURITIES_ALLOCATED = 'securities_allocated',
  CANCELLED = 'cancelled',
  ADJUSTED = 'adjusted',
  APPROVED = 'approved',
  AWAITING_CLOSING = 'awaiting_closing',
}

export enum OrderType {
  ORDER = 'Order',
  IOI = 'IndicationOfInterest',
  DRAWDOWN_ORDER = 'DrawdownOrder',
  UPFRONT_PAYMENT_ORDER = 'UpfrontPaymentOrder',
  CLOSED_END_FUND = 'ClosedEndFund',
}

export enum ReviewStatus {
  PENDING = 'pending',
  APPROVED = 'approved',
  CANCELLED = 'cancelled',
}

export type Order = {
  baseCurrencyAmount: string;
  cancellable: boolean;
  cancellationReason?: any;
  closingName: string;
  conversionRate: string;
  createdAt: string;
  currency: Currency;
  deal: Deal;
  equalisationInterest: string;
  fees: OrderFees[];
  filledAmount: string;
  id: string;
  intendedAmount: string;
  intendedNumberOfTokens: string;
  investorName: string;
  investorUserName: string;
  latestOrderAdjustment: AdjustmentHistoryItem & {
    adjustedTokens: string;
    fees: OrderFees[];
    totalDue: string;
    totalFeesDue: string;
  };
  openAmount: string;
  paymentMethod: string;
  pricePerToken: string;
  reviewStatus: ReviewStatus;
  status: OrderStatus;
  timezone: string;
  totalDue: string;
  totalFeesDue: string;
  type: OrderType;
  uuid: string;
  workflowState: OrderWorkflowState;
};

/**
 * At the moment, these are statuses applied to orders that belong to an investment fund (workflow 1)
 * - In Progress: the subscription has been submitted through IP
 * - Subscription Review: after placing the subscription, the investor has signed the generated docusign envelope
 * - Approved: the fund manager is happy with the order and the envelope and decided to approve it
 * - Countersign Required: the fund manager actioned the closing. The subscription remains here until the fund manager countersigns the docusign envelope
 * - Awaiting Closing: the fund manager has actioned the closing and the envelope is fully signed.
 * - Closed: the fund manager has finalised the closing in MC. End of the cycle.
 * - Cancelled
 */
export enum OrderWorkflowState {
  InProgress = 'in_progress',
  Completed = 'completed',
  SubscriptionReview = 'subscription_review',
  ReIssued = 're_issued',
  CountersignRequired = 'countersign_required',
  Approved = 'approved',
  AwaitingClosing = 'awaiting_closing',
  Cancelled = 'cancelled',
}
