import { FC } from 'react';

import {
  ToggleButton as MuiToggleButton,
  ToggleButtonGroup as MuiToggleButtonGroup,
  ToggleButtonGroupProps as MuiToggleButtonGroupProps,
  ToggleButtonProps as MuiToggleButtonProps,
} from '@mui/material';

export type ToggleButtonGroupProps = MuiToggleButtonGroupProps;
export const ToggleButtonGroup: FC<ToggleButtonGroupProps> = props => {
  return (
    <MuiToggleButtonGroup
      sx={[
        {
          '& .MuiToggleButton-root.Mui-disabled': {
            borderColor: 'grey.300',
            backgroundColor: 'grey.100',
            color: 'grey.500',

            '&.Mui-selected': {
              backgroundColor: 'grey.200',
              color: 'grey.700',
            },
          },
        },
      ]}
      {...props}
    />
  );
};

export type ToggleButtonProps = MuiToggleButtonProps;

const getSizeModifier = (size: ToggleButtonProps['size']): number => {
  switch (size) {
    case 'small':
      return 0.5;
    case 'large':
      return 1.5;
    default:
      return 1;
  }
};

export const ToggleButton: FC<ToggleButtonProps> = props => {
  const sizeModifier = getSizeModifier(props.size);

  return (
    <MuiToggleButton
      {...props}
      sx={[
        {
          paddingX: 3 * sizeModifier,
          paddingY: 1 * sizeModifier,
        },
        ...(Array.isArray(props.sx) ? props.sx : [props.sx]),
      ]}
    />
  );
};
