import styled from 'styled-components';

import { Screens } from 'shared-parts/constants/screens';

const getPercentageSize = size => `${8.333333333333334 * size}%`;
const Column = styled.div`
  ${({
    xxl,
    xl,
    lg,
    md,
    sm,
    xs, // down to zero width
  }) => `
    padding: 0 15px;
    flex: 0 0 100%;
    max-width: 100%;

    ${
      xs &&
      `
      flex: 0 0 ${getPercentageSize(xs)};
      max-width: ${getPercentageSize(xs)};
    `
    }

    @media(min-width: ${Screens.sm}) {
      flex: 0 0 ${getPercentageSize(sm)};
      max-width: ${getPercentageSize(sm)};
    }
  
    @media(min-width: ${Screens.md}) {
      flex: 0 0 ${getPercentageSize(md)};
      max-width: ${getPercentageSize(md)};
    }
    
    @media(min-width: ${Screens.lg}) {
      flex: 0 0 ${getPercentageSize(lg)};
      max-width: ${getPercentageSize(lg)};
    }
    
    @media(min-width: ${Screens.xl}) {
      flex: 0 0 ${getPercentageSize(xl)};
      max-width: ${getPercentageSize(xl)};
    }
    
    @media(min-width: ${Screens.xxl}) {
      flex: 0 0 ${getPercentageSize(xxl)};
      max-width: ${getPercentageSize(xxl)};
    }
  `}
`;

export default Column;
