import styled from 'styled-components';

import CopyField from 'shared/copy-field';
import * as Colors from 'shared-parts/constants/colors';
import { neutral200, white } from 'shared-parts/constants/colors';
import * as Fonts from 'shared-parts/constants/fonts';
import { Screens } from 'shared-parts/constants/screens';

const OrderTransferTopMessage = styled.p`
  font-size: 14px;
  color: ${Colors.primary};
`;

const OrderTransferTopBank = styled.div`
  margin: 0 auto;
  font-weight: 600;
  color: ${Colors.primary};

  > *:not(:last-child) {
    margin-bottom: 36px;
  }
`;

const OrderTransferGeneral = styled.dl`
  line-height: 1.5em;
`;

const OrderTransferGeneralRow = styled.dt`
  font-weight: initial;
`;

const OrderTransferSpecific = styled.div`
  min-width: 500px;
  max-width: 610px;
  margin: 0 auto;
  flex: 0 1 375px;

  > *:not(:last-child) {
    margin-bottom: 12px;
  }

  @media only screen and (max-width: ${Screens.md}) {
    flex-wrap: wrap;
  }
`;

const OrderTransfer = styled.div`
  text-align: center;
  font-family: ${Fonts.mainFont};
  margin: 12px;
  font-size: 14px;
`;

export const OrderTransferBankDetails = styled.div`
  background-color: ${neutral200};
  border-radius: 8px;
  padding: 60px;

  @media only screen and (max-width: ${Screens.lg}) {
    padding: 30px;
  }

  @media only screen and (max-width: ${Screens.sm}) {
    padding: 20px;
  }

  > *:not(:last-child) {
    margin-bottom: 36px;
  }

  p,
  dl {
    padding: 0;
    margin: 0;
  }
`;

const OrderTransferProp = styled.div`
  position: relative;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;

  @media only screen and (max-width: ${Screens.md}) {
    flex-wrap: wrap;
  }
`;

const OrderTransferLabel = styled.div`
  flex: 0 1 175px;
  margin: 0 5px;
  text-align: right;

  @media only screen and (max-width: ${Screens.md}) {
    text-align: center;
  }
`;

const OrderTransferCopyField = styled(CopyField)`
  margin: 0 5px;
  flex: 0 1 375px;

  input {
    background-color: ${white};
  }
`;

const OrderTransferNotion = styled.p`
  font-size: 14px;
  color: ${Colors.neutral800};
  margin: 0;
  padding: 28px;
  max-width: 800px;
  margin: 0 auto;
`;

export {
  OrderTransfer,
  OrderTransferTopMessage,
  OrderTransferTopBank,
  OrderTransferGeneral,
  OrderTransferGeneralRow,
  OrderTransferSpecific,
  OrderTransferProp,
  OrderTransferLabel,
  OrderTransferCopyField,
  OrderTransferNotion,
};
