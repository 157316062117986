import styled from 'styled-components';

import * as Colors from 'shared-parts/constants/colors';
import FormInvalidIcon from 'shared-parts/images/form-invalid.svg';
import FormValidIcon from 'shared-parts/images/form-valid.svg';

const Container = styled.div`
  background: ${({ isValueValid, touched, showPasswordIconPosition = '300px' }) =>
    touched
      ? `url(${
          isValueValid ? FormValidIcon : FormInvalidIcon
        }) no-repeat scroll ${showPasswordIconPosition} 14px`
      : ''};
  background-size: 20px 20px;
  border-bottom: ${({ isValueValid, touched, accentColorUntouched = Colors.neutral500 }) =>
    touched
      ? `1px solid ${isValueValid ? Colors.secondary : Colors.error}`
      : `1px solid ${accentColorUntouched}`};
  margin-top: 18px;
  padding: ${({ password }) => `10px ${password ? '120px' : '45px'} 10px 0`};
  position: relative;

  input {
    box-shadow: 0 0 0 30px ${({ backgroundColor }) => backgroundColor || ''} inset !important;
  }
  input:-webkit-autofill {
    -webkit-text-fill-color: ${({ textColor }) => textColor || ''} !important;
  }
`;

const Input = styled.input`
  background: transparent;
  border: none;
  color: ${({ textColor }) => textColor || ''};
  font-size: 16px;
  width: 100%;

  ::placeholder {
    color: ${({ placeholderColor }) => placeholderColor || ''};
  }
`;

const ShowPassword = styled.span`
  color: ${({ color }) => color || ''};
  cursor: pointer;
  font-size: 12px;
  font-weight: ${({ fontWeight = 400 }) => fontWeight};
  position: absolute;
  right: ${({ touched }) => (touched ? '27px' : '0')};
  top: 15px;
  user-select: none;
`;

export { Container, Input, ShowPassword };
