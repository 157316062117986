import styled from 'styled-components';

import Select from 'shared-parts/components/select';
import * as Colors from 'shared-parts/constants/colors';
import { Screens } from 'shared-parts/constants/screens';
import NextPageIcon from 'shared-parts/images/next-page.svg';
import NextPageActiveIcon from 'shared-parts/images/next-page-active.svg';
import NextPageRolloverIcon from 'shared-parts/images/next-page-rollover.svg';
import PrevPageIcon from 'shared-parts/images/prev-page.svg';
import PrevPageActiveIcon from 'shared-parts/images/prev-page-active.svg';
import PrevPageRolloverIcon from 'shared-parts/images/prev-page-rollover.svg';

const PaginationContainer = styled.div`
  align-items: center;
  display: flex;
  justify-content: flex-end;

  @media (max-width: ${Screens.xs}) {
    justify-content: center;
  }
`;

const CurrentPage = styled.div`
  align-items: center;
  background-color: ${Colors.white};
  display: flex;
  height: 36px;
  justify-content: center;
  margin: 5px;
  width: 36px;
  border: 1px solid ${({ disabled }) => (disabled ? Colors.neutral300 : Colors.neutral800)};
`;

const PaginationControl = styled.button`
  border: none;
  height: 36px;
  margin-left: 10px;
  width: 36px;
`;

const PrevControl = styled(PaginationControl)`
  ${({ disabled }) =>
    disabled
      ? `background: transparent url(${PrevPageIcon}) no-repeat;`
      : `cursor: pointer;
     background: url(${PrevPageActiveIcon}) no-repeat;
     :hover {
       background: url(${PrevPageRolloverIcon}) no-repeat;
     }
 `}
`;

const NextControl = styled(PaginationControl)`
  ${({ disabled }) =>
    disabled
      ? `background: transparent url(${NextPageIcon}) no-repeat;`
      : `cursor: pointer;
     background: url(${NextPageActiveIcon}) no-repeat;
     :hover {
       background: url(${NextPageRolloverIcon}) no-repeat;
     }
 `}
`;

const PaginationOptionSelect = styled(props => <Select {...props} />)`
  margin: 8px 0 0 10px;
  width: 95px;
`;

export { PaginationContainer, CurrentPage, PaginationOptionSelect, PrevControl, NextControl };
