import { Fragment, useCallback, useRef, useState } from 'react';
import { connect } from 'react-redux';

import * as actions from 'app-state/actions';
import { getRowScrollData } from 'app-state/selectors';

import { DATA_E2E_ATTRIBUTE_KEY } from 'shared-parts/constants/internal';

import { generateEmptyRow, generateTableData, getSortedData } from '../helpers/index';

import Header from './components/header';
import IntersectBox from './components/intersect-box';
import renderRow from './components/row';
import TotalRow from './components/total-row';
import { ProgressLine, TableBody, TableContainer, TableWrapper, Title } from './table.styled';

export const getTableControls = (props, stateControls) => {
  const {
    data,
    keys,
    onSort,
    loading,
    fixedHeader,
    onGroupClick,
    onInfiniteScroll,
    noRecordsMessage,
    resetRowScrollData,
    setGroupSortingData,
  } = props;
  const { setTableMaxHeight, tableRef, refTableHeader, sortingData, setSortingData } =
    stateControls;
  const adjustTableHeight = element => {
    if (element) {
      const { top } = element.getBoundingClientRect();
      setTableMaxHeight(fixedHeader ? `calc(100vh - ${top}px)` : '100%');
    }
  };
  const scrollToRow = rowNode => {
    if (![rowNode, tableRef.current].includes(null)) {
      if (fixedHeader && tableRef.current.scrollTo) {
        tableRef.current.scrollTo({
          top: rowNode.offsetTop - refTableHeader.current.offsetHeight,
          behavior: 'smooth',
        });
        resetRowScrollData();
      } else if (window.scrollTo) {
        window.scrollTo({
          top: rowNode.offsetTop,
          behavior: 'smooth',
        });
        resetRowScrollData();
      }
    }
  };
  const renderData = (tableData, rowRef) =>
    tableData.map(({ cells, item }, i) => renderRow(cells, i, item, rowRef, props));

  const renderTableData = rowRef => {
    if (data.length) {
      if (keys.length) {
        // !props.onSort condition states that we don't use BE sorting
        const sortedData = !onSort && sortingData ? getSortedData(data, sortingData) : data;

        return (
          <Fragment>
            {renderData(generateTableData(sortedData, keys), rowRef)}
            {onInfiniteScroll && !loading && <IntersectBox onInfiniteScroll={onInfiniteScroll} />}
          </Fragment>
        );
      }
      return renderData(data, rowRef);
    }

    if (loading) return null;

    return renderRow(generateEmptyRow(keys, noRecordsMessage).cells, 0, {}, rowRef, props);
  };
  const updateSortingData = sorting => () => {
    const sortingChanged = (sortingData || {}).param === sorting.param;
    const asc = sortingChanged ? !sortingData.asc : Boolean(sortingData) || sorting.asc;
    const updatedSortingData = { ...sorting, asc };

    if (onSort) {
      onSort(updatedSortingData || sorting);
    }
    if (onGroupClick) setGroupSortingData(updatedSortingData);
    setSortingData(updatedSortingData);
  };

  return { updateSortingData, renderTableData, adjustTableHeight, scrollToRow };
};

const Table = props => {
  const {
    data,
    keys,
    header,
    loading,
    className,
    fixedHeader,
    totalRowData,
    onInfiniteScroll,
    isScrollbarVisible,
    createTableControls,
    initialSorting,
    fullWidthTotal,
    title,
  } = props;
  const dataE2EAttribute = props[DATA_E2E_ATTRIBUTE_KEY] || 'table';
  const [sortingData, setSortingData] = useState(initialSorting);
  const [tableMaxHeight, setTableMaxHeight] = useState('100%');
  const tableRef = useRef(null);
  const refTableHeader = useRef(null);
  const stateControls = {
    setTableMaxHeight,
    tableRef,
    refTableHeader,
    sortingData,
    setSortingData,
  };
  const tableControls = createTableControls(props, stateControls);
  const rowRef = useCallback(tableControls.scrollToRow, []);
  const tableContainerRef = useCallback(tableControls.adjustTableHeight, []);

  return (
    <TableWrapper
      ref={tableRef}
      className={className}
      maxHeight={tableMaxHeight}
      isScrollbarVisible={isScrollbarVisible}
      data-e2e="table-wrapper"
    >
      <TableContainer ref={tableContainerRef} data-e2e={dataE2EAttribute}>
        {title && <Title>{title}</Title>}
        <Header
          ref={refTableHeader}
          header={header}
          loading={loading}
          sortingData={sortingData}
          fixedHeader={fixedHeader}
          updateSortingData={tableControls.updateSortingData}
          title={title}
        />
        <TableBody>
          {(onInfiniteScroll || !loading) && tableControls.renderTableData(rowRef)}
          <TotalRow
            data={data}
            keys={keys}
            totalRowData={totalRowData}
            fullWidth={fullWidthTotal}
          />
          {loading && <ProgressLine fixedHeader={fixedHeader} />}
        </TableBody>
      </TableContainer>
    </TableWrapper>
  );
};

Table.defaultProps = {
  isRowClickDisabled: false,
  onRowClick: undefined,
  data: [],
  keys: [],
  header: [],
  expandedItemsIds: [],
  loading: true,
  scrollToTableRow: undefined,
  resetScrollToTableRow: Function,
  createTableControls: getTableControls,
};

const mapStateToProps = state => ({
  rowScrollData: getRowScrollData(state),
});

const mapDispatchToProps = dispatch => ({
  resetRowScrollData: () => dispatch(actions.resetRowScrollData()),
});

export default connect(mapStateToProps, mapDispatchToProps)(Table);
