import { AnyAction } from 'redux';
import { call, put, takeLatest } from 'redux-saga/effects';

import { showModal } from 'app-state/actions';

import API from 'constants/api';
import { request } from 'helpers';
import { ModalFailed } from 'shared-parts/components';

import * as constants from './trade-shares.constants';

function* sendSellOrderRequest(action: AnyAction) {
  try {
    yield call(
      request,
      `${API.ShareIncentivePlans.Prefix()}${API.ShareIncentivePlans.tradeShares.sendSellOrder()}`,
      'POST',
      {
        ...action.payload.data,
      },
      { snakeCaseParams: false },
    );

    yield put({
      type: constants.SEND_SELL_ORDER.SUCCESS,
    });

    action.payload.onSuccess();
  } catch (e: any) {
    yield put({
      type: constants.SEND_SELL_ORDER.FAILURE,
      error: e.message,
    });
    yield put(
      showModal({
        component: ModalFailed,
        closable: true,
        showHeader: false,
      }),
    );
  }
}

function* sendTransferOrderRequest(action: AnyAction) {
  try {
    yield call(
      request,
      `${API.ShareIncentivePlans.Prefix()}${API.ShareIncentivePlans.tradeShares.sendTransferOrder()}`,
      'POST',
      {
        ...action.payload.data,
      },
      { snakeCaseParams: false },
    );

    yield put({
      type: constants.SEND_TRANSFER_ORDER.SUCCESS,
    });

    action.payload.onSuccess();
  } catch (e: any) {
    yield put({
      type: constants.SEND_TRANSFER_ORDER.FAILURE,
      error: e.message,
    });
    yield put(
      showModal({
        component: ModalFailed,
        closable: true,
        showHeader: false,
      }),
    );
  }
}

function* sendSellOrderRequestWatcher() {
  yield takeLatest(constants.SEND_SELL_ORDER.REQUEST, sendSellOrderRequest);
}

function* sendTransferOrderRequestWatcher() {
  yield takeLatest(constants.SEND_TRANSFER_ORDER.REQUEST, sendTransferOrderRequest);
}

export { sendSellOrderRequestWatcher, sendTransferOrderRequestWatcher };
