export const SAVE_ACCOUNT_INFO = 'SAVE_ACCOUNT_INFO';
export const SAVE_ACCOUNT_INFO_SUCCESS = 'SAVE_ACCOUNT_INFO_SUCCESS';
export const SAVE_ACCOUNT_INFO_ERROR = 'SAVE_ACCOUNT_INFO_ERROR';

export const UPDATE_ONBOARDING_INVESTMENT_PREFERENCES = 'UPDATE_ONBOARDING_INVESTMENT_PREFERENCES';

export const FETCH_INVESTMENT_PREFERENCES = 'FETCH_INVESTMENT_PREFERENCES';
export const FETCH_INVESTMENT_PREFERENCES_SUCCESS = 'FETCH_INVESTMENT_PREFERENCES_SUCCESS';
export const FETCH_INVESTMENT_PREFERENCES_ERROR = 'FETCH_INVESTMENT_PREFERENCES_ERROR';
export const RESET_INVESTMENT_PREFERENCES = 'RESET_INVESTMENT_PREFERENCES';

export const UPDATE_INVESTMENT_PREFERENCES = 'UPDATE_INVESTMENT_PREFERENCES';
