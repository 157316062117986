import * as constants from 'app-state/constants';

export const defaultSuitabilityTestQuestions = [];

export const suitabilityTestQuestionsReducer = (
  state = defaultSuitabilityTestQuestions,
  action,
) => {
  switch (action.type) {
    case constants.FETCH_SUITABILITY_TEST_QUESTIONS_SUCCESS:
      return action.questions.map(question => ({
        ...question,
        answers: Object.keys(question.answers).map(key => ({
          answer: key,
          text: question.answers[key],
        })),
      }));
    case constants.FETCH_SUITABILITY_TEST_QUESTIONS_ERROR:
      return defaultSuitabilityTestQuestions;
    default:
      return state;
  }
};

export const defaultSuitabilityTestResults = { passed: false, answers: [], loading: true };

export const suitabilityTestResultReducer = (state = defaultSuitabilityTestResults, action) => {
  switch (action.type) {
    case constants.SUITABILITY_TEST_FAILED:
    case constants.SUITABILITY_TEST_PASSED:
      return { ...action.result, loaded: false };
    case constants.SUITABILITY_TEST_SUBMIT:
    case constants.RESET_SUITABILITY_TEST_RESULT:
      return defaultSuitabilityTestResults;
    default:
      return state;
  }
};

export const suitabilityTestAttemptsErrorReducer = (state = '', action) => {
  switch (action.type) {
    case constants.SET_SUITABILITY_TEST_ATTEMPTS_ERROR:
      return action.error;
    case constants.RESET_SUITABILITY_TEST_ATTEMPTS_ERROR:
      return '';
    default:
      return state;
  }
};
