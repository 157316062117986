import Linkify from 'linkify-react';
import { useEffect, useState } from 'react';

import { useEnvVar } from '@frontend/config';
import { Alert } from '@frontend/design-system';
import { useRequest } from '@frontend/http';

import api from 'constants/api';
import { FeatureFlag, useFeatureFlag } from 'providers';

interface UserAlert {
  severity: 'info' | 'warning' | 'error' | 'success';
  message: string;
}

type UserAlerts = UserAlert[];

export default function UserAlerts() {
  const [persistentStatus, setPersistentStatus] = useState<'error' | 'success' | 'loading'>(
    'loading',
  );

  const refetchEnabled = useFeatureFlag(FeatureFlag.USER_ALERTS_REFETCH_ENABLED);
  const refetchInterval = useEnvVar('USER_ALERTS_REFETCH_INTERVAL', 60 * 1000);
  const smtpSupportEmail = useEnvVar('SMTP_SUPPORT_EMAIL');

  const { status, data } = useRequest<UserAlerts>(
    api.GetUserAlerts(),
    {
      refetchInterval: refetchEnabled ? refetchInterval : false,
      retry: 3,
    },
    {
      requestConfigOverrides: {
        onError: () => undefined,
      },
    },
  );

  useEffect(() => {
    // When a refetch occurs, the status changes to "loading" and any error is cleared.
    // We want to display the error until the fetch actually succeeds, so once the first
    // load has completed (whether success or error), we ignore any subsequent status
    // changes back to "loading".
    if (['success', 'error'].includes(status)) {
      setPersistentStatus(status);
    }
  }, [status]);

  if (persistentStatus === 'loading') {
    return null;
  }
  if (persistentStatus === 'error' || !data) {
    return (
      <Alert severity="error" square style={{ justifyContent: 'center', width: '100%' }}>
        <Linkify>
          There is a problem communicating with the server. Please contact {smtpSupportEmail} if the
          problem persists.
        </Linkify>
      </Alert>
    );
  }

  return (
    <>
      {data.map(item => (
        <Alert severity={item.severity} square style={{ justifyContent: 'center', width: '100%' }}>
          <Linkify>{item.message}</Linkify>
        </Alert>
      ))}
    </>
  );
}
