import { ExpandedRow } from './expanded-row.styled';

const renderExpandedRow =
  ({ children, keys = [], data = [] }, expandableTableControls) =>
  item => {
    const { expandedItemsIds, lastExpandedRow, expandRow, closeRow } = expandableTableControls;
    const ExpandedRowComponent = children;

    return data.length && expandedItemsIds.includes(item.id) ? (
      <tr>
        <ExpandedRow colSpan={keys.length}>
          <ExpandedRowComponent
            item={item}
            lastExpandedRow={lastExpandedRow}
            expandControls={{ expandRow, closeRow }}
          />
        </ExpandedRow>
      </tr>
    ) : null;
  };

export default renderExpandedRow;
