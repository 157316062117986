import * as constants from 'app-state/constants';

export const rowScrollDataReducer = (state = null, action) => {
  // eslint-disable-line
  switch (action.type) {
    case constants.SET_ROW_SCROLL_DATA:
      return action.id;
    case constants.RESET_ROW_SCROLL_DATA:
      return null;
    default:
      return state;
  }
};
