import { GridFilterInputMultipleSingleSelect, GridFilterOperator } from '@mui/x-data-grid-premium';

function isObject<TObject = Record<PropertyKey, any>>(value: unknown): value is TObject {
  return typeof value === 'object' && value !== null;
}

const parseObjectValue = (value: unknown) => {
  if (value == null || !isObject<{ value: unknown }>(value)) {
    return value;
  }
  return value.value;
};

export const hasAllOperator = {
  value: 'hasAll',
  label: 'has all',
  getApplyFilterFn: filterItem => {
    if (!Array.isArray(filterItem.value) || filterItem.value.length === 0) {
      return null;
    }
    const filterItemValues = filterItem.value.map(parseObjectValue);
    return (value): boolean => filterItemValues.includes(parseObjectValue(value));
  },
  InputComponent: GridFilterInputMultipleSingleSelect,
} as GridFilterOperator;
