import { createSelector } from 'reselect';

export const getInvestor = state => state.session.user.investor || {};
export const getIndividualInvestorTypes = state => state.individualInvestorTypes;
export const getSupportedIndividualInvestorTypes = createSelector(
  getIndividualInvestorTypes,
  individualInvestorTypes => {
    const individualInvestorTypesData = individualInvestorTypes.data || [];

    return {
      ...individualInvestorTypes,
      data: individualInvestorTypesData.filter(investorType => investorType.supported),
    };
  },
);
export const getOnboardingState = state => state.onboardingState;
export const getSupportedCountries = state => state.supportedCountries.countries;
export const getUnsupportedCountries = state => state.unsupportedCountries.countries;
