import { FC } from 'react';

import { Box, LinearProgress, LinearProgressProps, styled, Typography } from '@mui/material';

interface ProgressBarProps extends LinearProgressProps {
  completeStepCount: number;
  totalStepCount: number;
  label?: string;
  displayCountAndLabel?: boolean;
}

const ProgressBar: FC<ProgressBarProps> = ({
  completeStepCount,
  totalStepCount,
  label = 'complete',
  displayCountAndLabel = true,
  ...ProgressBarProps
}) => {
  return (
    <Box sx={{ display: 'flex', alignItems: 'center' }}>
      <Box sx={{ flex: 1, mr: '12px' }}>
        <LinearProgress
          variant="determinate"
          value={(completeStepCount / totalStepCount) * 100}
          {...ProgressBarProps}
        />
      </Box>
      {displayCountAndLabel && (
        <Box sx={{ flex: 1 }}>
          <Label>{`${completeStepCount}/${totalStepCount}${label ? ` ${label}` : ''}`}</Label>
        </Box>
      )}
    </Box>
  );
};

const Label = styled(Typography)(({ theme }) => ({
  fontStyle: 'normal',
  fontWeight: 500,
  lineHeight: '24px',
  color: theme.palette.neutral[500],
}));

const StyledProgressBar = styled(ProgressBar)(({ theme }) => ({
  height: '8px',
  borderRadius: '4px',
  '&': {
    backgroundColor: theme.palette.neutral[100],
  },
  '& .MuiLinearProgress-barColorPrimary': {
    backgroundColor: theme.palette.success.main,
    borderRadius: '4px',
  },
}));

export { StyledProgressBar as ProgressBar, ProgressBarProps };
