import _ from 'lodash';
import { DefaultTheme } from 'styled-components';

import { getTenantConfig } from 'helpers/get-tenant-config';

const { styledComponentsTheme: tenantTheme } = getTenantConfig();

const defaultTheme: DefaultTheme = {
  positioning: {
    layout: 'center',
  },
};

const theme = _.merge({}, defaultTheme, tenantTheme ?? {});

export default theme;
