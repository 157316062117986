import { call, put, takeEvery } from 'redux-saga/effects';

import { getDividendOrdersError, getDividendOrdersSuccess } from 'app-state/actions/sip-orders';
import { GET_DIVIDEND_ORDERS } from 'app-state/constants/sip-orders';

import API from 'constants/api';
import request from 'shared-parts/helpers/request';

function* getDividendOrders({ payload }: any) {
  try {
    const { page, sortBy, sortOrder, limit, paginate } = payload;
    const { data } = yield call(
      request,
      `${API.ShareIncentivePlans.Prefix()}${API.ShareIncentivePlans.DividendOrders(
        page,
        limit,
        sortBy,
        sortOrder,
      )}`,
      'GET',
    );
    yield put(getDividendOrdersSuccess({ data, paginate }));
  } catch (error) {
    yield put(getDividendOrdersError({ error }));
  }
}

function* getDividendOrdersWatcher() {
  yield takeEvery(GET_DIVIDEND_ORDERS, getDividendOrders);
}

export { getDividendOrdersWatcher };
