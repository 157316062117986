export const CHANGE_ONBOARDING_STATE = 'CHANGE_ONBOARDING_STATE';

export const SAVE_PERSONAL_DATA = 'SAVE_PERSONAL_DATA';
export const SAVE_PERSONAL_DATA_SUCCESS = 'SAVE_PERSONAL_DATA_SUCCESS';
export const SAVE_PERSONAL_DATA_ERROR = 'SAVE_PERSONAL_DATA_ERROR';

export const SAVE_INVESTOR_TYPE = 'SAVE_INVESTOR_TYPE';
export const SAVE_INVESTOR_TYPE_SUCCESS = 'SAVE_INVESTOR_TYPE_SUCCESS';
export const SAVE_INVESTOR_TYPE_ERROR = 'SAVE_INVESTOR_TYPE_ERROR';

export const SAVE_INVESTOR_COUNTRY = 'SAVE_INVESTOR_COUNTRY';

export const GET_INDIVIDUAL_INVESTOR_TYPES = 'GET_INDIVIDUAL_INVESTOR_TYPES';
export const GET_INDIVIDUAL_INVESTOR_TYPES_SUCCESS = 'GET_INDIVIDUAL_INVESTOR_TYPES_SUCCESS';
export const GET_INDIVIDUAL_INVESTOR_TYPES_ERROR = 'GET_INDIVIDUAL_INVESTOR_TYPES_ERROR';

export const GET_SUPPORTED_COUNTRIES = 'GET_SUPPORTED_COUNTRIES';
export const GET_SUPPORTED_COUNTRIES_SUCCESS = 'GET_SUPPORTED_COUNTRIES_SUCCESS';
export const GET_SUPPORTED_COUNTRIES_ERROR = 'GET_SUPPORTED_COUNTRIES_ERROR';

export const GET_UNSUPPORTED_COUNTRIES = 'GET_UNSUPPORTED_COUNTRIES';
export const GET_UNSUPPORTED_COUNTRIES_SUCCESS = 'GET_UNSUPPORTED_COUNTRIES_SUCCESS';
export const GET_UNSUPPORTED_COUNTRIES_ERROR = 'GET_UNSUPPORTED_COUNTRIES_ERROR';
