import { replace } from 'connected-react-router';
import { useCallback } from 'react';
import { useDispatch } from 'react-redux';
import { useLocation } from 'react-router-dom';

import { setURLParameters, URLSearchStringToObject } from 'shared-parts/helpers/url-parameters';

export default () => {
  const dispatch = useDispatch();
  const { pathname, search: searchString } = useLocation();
  const search = URLSearchStringToObject(searchString);

  const replaceURLParams = useCallback(
    newParams => {
      const newSearchString = setURLParameters(searchString, newParams);
      dispatch(replace(`${pathname}?${newSearchString}`));
    },
    [searchString, pathname, dispatch],
  );

  return {
    search,
    searchString,
    replaceURLParams,
  };
};
