import { FIELD_LABELS } from '../constants';

// eslint-disable-next-line import/prefer-default-export
export const handleCopyToClipboard = e => {
  e.preventDefault();

  let result = '';
  const formData = new FormData(e.target);

  // eslint-disable-next-line no-unused-vars,no-restricted-syntax
  for (const [key, value] of formData.entries()) {
    result += `${FIELD_LABELS[key]}: ${value}\n`;
  }

  navigator.clipboard.writeText(result);
};
