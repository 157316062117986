import { call, put, select, takeEvery, takeLatest } from 'redux-saga/effects';

import { resendEmailConfirmationError, saveUserData } from 'app-state/actions';
import * as constants from 'app-state/constants';
import * as selectors from 'app-state/selectors';

import API from 'constants/api';
import { request } from 'helpers';

import { saveUserDataSaga } from './authentication';

function* getCurrentUser(action) {
  const { data: currentUserData } = yield call(request, API.currentUser(), 'GET', null, {
    shouldRedirectOnLogout: action?.shouldRedirectOnLogout,
  });
  yield call(saveUserDataSaga, saveUserData(currentUserData));

  return yield currentUserData;
}

function* getCurrentUserWatcher() {
  yield takeLatest(constants.GET_CURRENT_USER, getCurrentUser);
}

function* updateCurrentUser({ formData, form, handleSuccess }) {
  try {
    const { data } = yield call(request, API.currentUser(), 'PUT', formData);
    form.setSubmitting(false);
    yield call(saveUserDataSaga, saveUserData(data));
    yield form.resetForm();
    handleSuccess();
  } catch (e) {
    form.setSubmitting(false);
    if (e.response.details) form.setErrors(e.response.details);
  }
}

function* updateCurrentUserWatcher() {
  yield takeEvery(constants.UPDATE_CURRENT_USER, updateCurrentUser);
}

function* resendEmailConfirmation({ userId }) {
  try {
    yield call(request, API.resendEmail(), 'POST', { id: userId });
    const user = yield select(selectors.getUser);
    yield call(saveUserDataSaga, saveUserData({ ...user, isEmailConfirmationSent: true }));
  } catch (e) {
    yield put(resendEmailConfirmationError(e));
  }
}

function* resendEmailConfirmationWatcher() {
  yield takeEvery(constants.RESEND_EMAIL_CONFIRMATION, resendEmailConfirmation);
}

export {
  getCurrentUser,
  getCurrentUserWatcher,
  updateCurrentUser,
  updateCurrentUserWatcher,
  resendEmailConfirmation,
  resendEmailConfirmationWatcher,
};
