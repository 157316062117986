import * as constants from 'app-state/constants';

export const fetchSuitabilityTestQuestions = () => ({
  type: constants.FETCH_SUITABILITY_TEST_QUESTIONS,
});
export const fetchSuitabilityTestQuestionsSuccess = questions => ({
  type: constants.FETCH_SUITABILITY_TEST_QUESTIONS_SUCCESS,
  questions,
});
export const fetchSuitabilityTestQuestionsError = error => ({
  type: constants.FETCH_SUITABILITY_TEST_QUESTIONS_ERROR,
  error,
});

export const suitabilityTestSubmit = results => ({
  type: constants.SUITABILITY_TEST_SUBMIT,
  results,
});
export const suitabilityTestFailed = result => ({
  type: constants.SUITABILITY_TEST_FAILED,
  result,
});
export const suitabilityTestPassed = result => ({
  type: constants.SUITABILITY_TEST_PASSED,
  result,
});
export const resetSuitabilityTestResult = () => ({ type: constants.RESET_SUITABILITY_TEST_RESULT });

export const setSuitabilityTestAttemptsError = error => ({
  type: constants.SET_SUITABILITY_TEST_ATTEMPTS_ERROR,
  error,
});
export const resetSuitabilityTestAttemptsError = () => ({
  type: constants.RESET_SUITABILITY_TEST_ATTEMPTS_ERROR,
});
