import React, { useEffect } from 'react';
import { useDispatch } from 'react-redux';

import { useEnvVar } from '@frontend/config';
import { Box, Button, Typography } from '@frontend/design-system';
import {
  FileDownload as FileDownloadIcon,
  OpenInNew as OpenInNewIcon,
} from '@frontend/design-system/icons';
import { useSubmit } from '@frontend/http';

import { hideLoader, showLoader } from 'app-state/loader/actions';

import api from 'constants/api';

import { FileAnchor } from './box-file.styled';

interface BoxFileProps {
  title: string;
  url: string;
  fileUploadUuid: string;
}

export default ({ title, url, fileUploadUuid }: BoxFileProps): JSX.Element => {
  const { submit: downloadFile, isLoading } = useSubmit<unknown, Blob>(
    api.GetDataRoomFile(fileUploadUuid),
    'POST',
  );

  const previewFile = (blob: Blob) => {
    const blobUrl = URL.createObjectURL(blob);
    window.open(blobUrl, '_blank');
  };

  const saveFile = (blob: Blob) => {
    const a = window.URL.createObjectURL(blob);
    const link = document.createElement('a');
    link.href = a;
    link.download = title;
    link.click();
  };

  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(isLoading ? showLoader() : hideLoader());
    return () => {
      dispatch(hideLoader());
    };
  }, [isLoading]);

  const isPdf = new URL(url).pathname?.split('.').pop() === 'pdf';

  if (fileUploadUuid && useEnvVar('DATAROOM_ENABLED')) {
    return (
      <Box display="flex" alignItems="center" justifyContent="space-between">
        <Typography variant="label">{title}</Typography>
        <Box>
          {isPdf && (
            <Button
              variant="text"
              startIcon={<OpenInNewIcon />}
              onClick={() =>
                downloadFile(
                  {},
                  { to: 'blob' },
                  {
                    onSuccess(blob) {
                      previewFile(blob.data);
                    },
                  },
                )
              }
            >
              Preview
            </Button>
          )}
          <Button
            variant="text"
            startIcon={<FileDownloadIcon />}
            onClick={() =>
              downloadFile(
                {},
                { to: 'blob' },
                {
                  onSuccess(blob) {
                    saveFile(blob.data);
                  },
                },
              )
            }
          >
            Download
          </Button>
        </Box>
      </Box>
    );
  }
  return (
    <FileAnchor target="_blank" rel="noopener noreferrer" href={url} download>
      {title}
    </FileAnchor>
  );
};
