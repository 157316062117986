import { useState } from 'react';
import { useLocation } from 'react-router-dom';

import styled from 'styled-components';

import { checkPathSuitability } from '@frontend/utils';

import Routes from 'constants/routes';
import { FeatureFlag, useFeatureFlag } from 'providers/app-config';
import useMediaQuery from 'providers/device/use-media-query';
import { Bubble } from 'shared';
import XIcon from 'shared-parts/components/svg-icons/x-icon';
import * as Colors from 'shared-parts/constants/colors';
import { Screens } from 'shared-parts/constants/screens';

import { getMenuItems, SIGN_OUT } from '../constants';
import {
  CloseButton,
  MenuLink,
  Modal,
  ModalHeader,
  ModalWrapper,
  NavigationItem,
  Wrapper,
} from '../styled';

import NavMenuToggle from './nav-menu-toggle';

const NavbarLinkList = styled.ul`
  margin: 0;
`;

const MobileNavigationMenu = ({ isOpen, onClose, children }) => (
  <Modal aria-hidden={!isOpen} id="mobile-nav-menu" isOpen={isOpen}>
    <ModalHeader>
      <CloseButton onClick={onClose}>{XIcon(15, 15, Colors.primary)}</CloseButton>
    </ModalHeader>
    {children}
  </Modal>
);

// const AuthenticatedUserMenu = ({ user, handleLogout }) => {
//   const { skipOnboarding } = useLiquidity();
const UserHeader = ({ user, handleLogout }) => {
  const [isMobileNavMenuOpen, setIsMobileNavMenuOpen] = useState(false);
  const { pathname } = useLocation();
  const isSendbirdEnabled = useFeatureFlag(FeatureFlag.SENDBIRD_ENABLED);
  const isNotificationTabEnabled = useFeatureFlag(FeatureFlag.NOTIFICATIONS_TAB_VISIBLE);
  const isLiquidityEnabled = useFeatureFlag(FeatureFlag.LIQUIDITY_ROUND_FEATURE);
  const isDealsTabEnabled = !useFeatureFlag(FeatureFlag.PLACEMENT_DISABLED);
  const isTransactionsTabEnabled = !useFeatureFlag(FeatureFlag.HIDE_TRANSACTIONS);
  const isCapTableManagementDisabled = useFeatureFlag(FeatureFlag.DISABLED_CAP_TABLE_MANAGEMENT);

  const isMobile = useMediaQuery(`(max-width: ${Screens.xl})`);

  const toggleMobileMenu = () => {
    setIsMobileNavMenuOpen(!isMobileNavMenuOpen);
  };

  const renderItem = ({
    name,
    id,
    exact,
    to = '',
    onClick,
    bubbleContent,
    as: MenuItem = MenuLink,
  }) => {
    const active =
      checkPathSuitability({ path: to, exact }, pathname) ||
      (to === Routes.Deals() && pathname === Routes.LiquidityRounds());

    return (
      <NavigationItem key={name} onClick={() => (isMobile ? toggleMobileMenu() : undefined)}>
        <MenuItem id={id} exact={exact} to={to} onClick={onClick} active={active ? 1 : 0}>
          {name}
        </MenuItem>
        {bubbleContent ? <Bubble content={bubbleContent} center /> : ''}
      </NavigationItem>
    );
  };

  const renderMenuItemsIfOnboardingCompleted = () => {
    const { investor, unviewedCommunicationsCount, unreadSendbirdMessagesCount } = user || {};
    const { onboardingStatus, onboardingPhase } = investor || {};
    const phases = ['type_step', 'upload_document_step'];

    const menuItems = getMenuItems(
      handleLogout,
      isNotificationTabEnabled ? unviewedCommunicationsCount : 0,
      isSendbirdEnabled ? unreadSendbirdMessagesCount : 0,
      isLiquidityEnabled,
      isDealsTabEnabled,
      isTransactionsTabEnabled,
      isCapTableManagementDisabled,
    );

    const correctStatus = ['in_progress', 'completed'].includes(onboardingStatus);

    if (correctStatus || phases.includes(onboardingPhase)) {
      return <span>{menuItems.map(renderItem)}</span>;
    }

    return renderItem(menuItems.find(item => item.name === SIGN_OUT));
  };

  return (
    <Wrapper data-e2e="top-menu">
      {isMobile ? (
        <nav>
          <NavMenuToggle aria-expanded={isMobileNavMenuOpen} onClick={toggleMobileMenu} />

          <MobileNavigationMenu isOpen={isMobileNavMenuOpen} onClose={toggleMobileMenu}>
            {renderMenuItemsIfOnboardingCompleted()}
          </MobileNavigationMenu>

          {isMobileNavMenuOpen && <ModalWrapper />}
        </nav>
      ) : (
        <nav>
          <NavbarLinkList>{renderMenuItemsIfOnboardingCompleted()}</NavbarLinkList>
        </nav>
      )}
    </Wrapper>
  );
};

export default UserHeader;
