const twentyFourHours = 86400000;

const namedMonths = [
  'January',
  'February',
  'March',
  'April',
  'May',
  'June',
  'July',
  'August',
  'September',
  'October',
  'November',
  'December',
];

const daysOfWeek = {
  Sun: 'Su',
  Mon: 'Mo',
  Tue: 'Tu',
  Wed: 'We',
  Thu: 'Th',
  Fri: 'Fr',
  Sat: 'Sa',
};

/** @deprecated use formatDate(date, LDF.DATE_NUMERIC) -> 01/02/2024 */
const SHORT_MONTH_DATE_FORMAT = 'dd-mm-yyyy';
/** @deprecated use formatDate(date, \`${LDF.DATE_TEXTUAL_FULL} ${LDF.TIME}\`) -> 01/02/2024 10:02 */
const SHORT_MONTH_DATE_FORMAT_WITH_TIME = 'dd-mm-yyyy hh:mm';
/** @deprecated use formatDate(date, LDF.DATE_TEXTUAL) (or textual long) -> 24th Feb 2024 (or February 24th 2024) */
const DEFAULT_DATE_FORMAT = 'dd-mmm-yyyy';
/** @deprecated use formatDate(date, \`${LDF.DATE_TEXTUAL} ${LDF.TIME}\`) -> 12 Feb 2024 20:30 */
const DEFAULT_DATE_FORMAT_WITH_TIME = 'dd-mmm-yyyy hh:mm';
/** @deprecated use formatDate(date, LDF.TIME_SECONDS) -> 16:20:03 */
const DEFAULT_TIME_FORMAT = 'hh:mm:ss';

export {
  twentyFourHours,
  namedMonths,
  daysOfWeek,
  SHORT_MONTH_DATE_FORMAT,
  SHORT_MONTH_DATE_FORMAT_WITH_TIME,
  DEFAULT_DATE_FORMAT,
  DEFAULT_DATE_FORMAT_WITH_TIME,
  DEFAULT_TIME_FORMAT,
};
