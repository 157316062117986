import styled from 'styled-components';

import * as Colors from 'shared-parts/constants/colors';
import { Screens } from 'shared-parts/constants/screens';

const LocationSectionWrapper = styled.div`
  background: ${Colors.white};
  border: 1px solid ${Colors.neutral500};
  border-radius: 9px;
  margin-bottom: 25px;
  padding: 45px 40px;

  @media (max-width: ${Screens.md}) {
    padding: 40px 25px;
  }
`;

const LocationItem = styled.div`
  position: relative;
  border-top: 1px solid ${Colors.neutral500};
  padding: 10px 0 10px 50px;

  &:last-child {
    border-bottom: 1px solid ${Colors.neutral500};
  }

  @media (max-width: ${Screens.md}) {
    padding-left: 40px;
  }
`;

const Icon = styled.img`
  position: absolute;
  left: 0;
  top: 50%;
  transform: translateY(-50%);
  height: 25px;
  width: 25px;
`;

const ItemName = styled.span`
  color: ${Colors.neutral800};
  display: inline-block;
  font-size: 14px;
  font-weight: 700;
  line-height: 14px;
  vertical-align: middle;
  width: 100%;
  word-wrap: break-word;
`;

export { LocationSectionWrapper, LocationItem, Icon, ItemName };
