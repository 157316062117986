import { getEmptyRowStyles } from '../regular-table/table.styled';

const generateEmptyRow = (keys, message = 'No records', retryAction = null) => {
  const activeActionsCells = keys
    .filter(key => key.activeForEmptyRow)
    .map(({ text, action, styles }) => ({
      text,
      action,
      styles: () => `${styles ? styles() : ''}`,
    }));

  return {
    cells: [
      {
        text: message,
        colspan: keys.length - activeActionsCells.length,
        action: retryAction,
        styles: () => getEmptyRowStyles(retryAction),
      },
      ...activeActionsCells,
    ],
  };
};

export default generateEmptyRow;
