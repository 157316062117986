import { FC } from 'react';

import dayjs from '@frontend/datetime';
import { Divider, Stack, Typography, useTheme } from '@frontend/design-system';
import { pluralize } from '@frontend/utils';

import { Deal } from './deal-tile.types';

export interface AlternativeInvestmentVehicleFooterProps {
  deal: Deal;
}

const AlternativeInvestmentVehicleFooter: FC<
  React.PropsWithChildren<AlternativeInvestmentVehicleFooterProps>
> = ({ deal }) => {
  const { neutral } = useTheme().palette;

  const { subscriptionEnd, requireCloseDate, subscriptionEndDaysVisible } = deal;

  const currentDate = new Date(new Date().toDateString()).getTime();
  const closingDate = new Date(new Date(subscriptionEnd).toDateString()).getTime();
  const daysToGo =
    currentDate >= closingDate
      ? 0
      : Math.ceil(Math.abs(closingDate - currentDate) / (1000 * 60 * 60 * 24));

  return (
    <Stack borderTop="1px solid" borderColor={neutral[100]} pt="12px" gap="8px" textAlign="center">
      <Typography textAlign="center" color={neutral[800]}>
        Estimated closing date
      </Typography>
      <Typography textAlign="center" color={neutral[500]}>
        {subscriptionEnd ? dayjs(subscriptionEnd).format('L') : ''}
      </Typography>
      {!!daysToGo && subscriptionEndDaysVisible && (requireCloseDate ?? true) && (
        <Typography textAlign="center" color={neutral[800]}>
          {`${pluralize('Day', daysToGo <= 0 ? 0 : daysToGo)} To Go`}
        </Typography>
      )}
      <Divider style={{ width: '60%', margin: 'auto' }} />
      <Typography color={neutral[500]}>Investment held by Globacap Nominees Limited</Typography>
    </Stack>
  );
};

export default AlternativeInvestmentVehicleFooter;
