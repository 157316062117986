import { createContext, FC, useContext, useEffect, useState } from 'react';

import { debounce } from '@frontend/utils';

import { Screens } from 'shared-parts/constants';

import { IDevice } from './types';

const LARGE_SCREEN_WIDTH = Number(Screens.xxl.substring(0, Screens.xxl.length - 2));
const isMobileScreen = () => window.innerWidth < LARGE_SCREEN_WIDTH;

const initialContext: IDevice = {
  isMobile: false,
};

const DeviceContext = createContext<IDevice>(initialContext);
DeviceContext.displayName = 'DeviceContext';

export const DeviceProvider: FC<React.PropsWithChildren<unknown>> = ({ children }) => {
  const [isMobile, setIsMobile] = useState<boolean>(isMobileScreen());

  useEffect(() => {
    const onResize = debounce(() => setIsMobile(isMobileScreen()), 250);
    window.addEventListener('resize', onResize);
    return () => {
      window.removeEventListener('resize', onResize);
    };
  }, []);
  return <DeviceContext.Provider value={{ isMobile }}>{children}</DeviceContext.Provider>;
};

export const useDevice = (): IDevice => useContext(DeviceContext);
