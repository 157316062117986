type Colors = {
  primary: string;
  primaryContrastText: string;
  accent: string;
  accentContrastText: string;
  highlight: string;
  highlightContrastText: string;
  secondary: string;
  secondary100: string;
  secondaryContrastText: string;
  alternative600: string;
  alternative: string;
  alternative100: string;
  alternativeContrastText: string;
  neutral900: string;
  neutral800: string;
  neutral700: string;
  neutral500: string;
  neutral400: string;
  neutral300: string;
  neutral200: string;
  neutral100: string;
  black: string;
  white: string;
  systemRed: string;
  systemRedBg: string;
  systemGreen: string;
  systemGreenBg: string;
  systemOrange: string;
  systemYellow: string;
  systemCyan: string;
  systemPink: string;
  systemPurple: string;
  systemPurpleBg: string;
  success: string;
  success100: string;
  warning: string;
  error: string;
  error100: string;
  shadowColor: string;
};

type Fonts = {
  mainFont: string;
  oswaldFont: string;
};

type AuthLogoSecondaryLogoCustomization = {
  url?: string;
  width?: string | null;
  height?: string | null;
};

type AuthLogoCustomization = {
  width?: string | null;
  height?: string | null;
  margin?: string | null;
  marginBottom?: string | null;
  marginRight?: string | null;
  paddingBottom?: string | null;
  secondaryLogo?: AuthLogoSecondaryLogoCustomization;
};

type NavigationLogoCustomization = {
  margin?: string | null;
  height?: string | null;
  minWidth?: string | null;
  width?: string | null;
  marginTop?: string | null;
};

type FooterLogoCustomization = {
  padding?: string | null;
  width?: string | null;
  marginTop?: string | null;
  margin?: string | null;
  height?: string | null;
  mediumScreenOverride?: boolean;
};

type TumblerCustomization = {
  width?: string | null;
};

type HeaderSecondaryLogoCustomization = {
  url?: string;
};

type HeaderCustomization = {
  backgroundColor?: string | null;
  hamburgerFill?: string | null;
  secondaryLogo?: HeaderSecondaryLogoCustomization;
};

type UserMenuCustomization = {
  inactiveMenuItemTextColor?: string | null;
};

type TogglerCustomization = {
  backgroundColor?: string | null;
  textColor?: string | null;
};

type DealTileCustomization = {
  closedForInvestmentBlockBackgroundColor?: string | null;
};

type DealsCustomization = {
  tabsBackgroundColor?: string | null;
  activeTabTextColor?: string | null;
  inactiveTabTextColor?: string | null;
  emailIconColor?: string | null;
};

type AuthScreenCustomization = {
  textAlign?: string | null;
  containerPadding?: string | null;
  style?: 'alternative1' | 'alternative2' | null;
  signUpWarnings?: string[] | null;
  height?: string | null;
  width?: string | null;
  titleMarginTop?: string | null;
  titleMarginBottom?: string | null;
};

type OrderFormCustomization = {
  submitButtonTitle?: string | null;
};

type FooterCustomization = {
  navbarAlignItems?: string | null;
  footerMainBlockBackgroundColor?: string | null;
  footerWrapperHeight?: string | null;
  footerMainBlockDisablePadding?: boolean | null;
};

type Customizations = {
  authLogo: AuthLogoCustomization;
  navigationLogo: NavigationLogoCustomization;
  footerLogo: FooterLogoCustomization;
  tumbler: TumblerCustomization;
  header: HeaderCustomization;
  userMenu: UserMenuCustomization;
  toggler: TogglerCustomization;
  dealTile: DealTileCustomization;
  deals: DealsCustomization;
  authScreen: AuthScreenCustomization;
  orderForm: OrderFormCustomization;
  footer: FooterCustomization;
};

type DisclaimerBlock = {
  bullet?: string;
  template: string;
};

type DisclaimerBlocks = DisclaimerBlock[];

type Disclaimer = {
  bullets: boolean;
  blocks: DisclaimerBlocks;
  checkbox: string;
  enforced: boolean;
  text: string;
};

export type TenantConfig = {
  colors: Colors;
  fonts: Fonts;
  custom: Customizations;
  theme: any;
  styledComponentsTheme: any;
  disclaimer: Disclaimer;
};

export function getTenantConfig(): TenantConfig {
  return (window as Window).tenant as TenantConfig;
}
