import Routes from 'constants/routes';
import { getURLParameters } from 'shared-parts/helpers';

export default (isAgentPermissionsEnabled, onboardingStatus, onboardingPhase, preferenceSet) => {
  const accountPageIsPreferred =
    ['in_progress', 'completed'].includes(onboardingStatus) ||
    ['type_step', 'upload_document_step', 'account_information_step'].includes(onboardingPhase);

  if (accountPageIsPreferred) {
    if (isAgentPermissionsEnabled && !preferenceSet) {
      return Routes.Account.AgentPermissions();
    }
    return getURLParameters(['actionPath'])[0] || Routes.Root();
  }
};
