import { createSelector } from 'reselect';

import { IssuanceStatus } from '@frontend/opportunities';

const OPEN_STATUSES = [IssuanceStatus.LIVE, IssuanceStatus.PRELAUNCH];
const CLOSED_STATUSES = [IssuanceStatus.COMPLETED, IssuanceStatus.CLOSED];

export const getDealsSelector = state => state.deals.data;

export const getOpenDealsSelector = createSelector(getDealsSelector, state =>
  state.filter(deal => OPEN_STATUSES.includes(deal.status)),
);
export const getFutureDealsSelector = createSelector(getDealsSelector, state =>
  state.filter(deal => deal.status === IssuanceStatus.PENDING),
);
export const getClosedDealsSelector = createSelector(getDealsSelector, state =>
  state.filter(deal => CLOSED_STATUSES.includes(deal.status)),
);

export const getDealsLoadingSelector = state => state.deals.loading;
