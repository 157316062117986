import getApiParamsString from 'shared-parts/helpers/get-api-params-string';

export default {
  deals: () => '/api/v1/deals/tiles/',
  deal: (permalink, actionToken) =>
    `/api/v1/deals/${permalink}${actionToken ? `?action_token=${actionToken}` : ''}`,
  removeDeal: permalink => `/api/v1/investors/investment/deals/${permalink}/invites`,
  currentUser: () => '/api/v1/investors/current-user',
  signUp: () => '/api/v1/investors/sign-up.json',
  signUpInvited: () => '/api/v1/investors/sign-up/invited',
  login: () => '/api/v1/investors/login.json',
  loginInvited: () => '/api/v1/investors/login/invited',
  logout: () => '/api/v1/investors/logout',
  PlatformSettings: () => '/api/v1/platform-settings',
  resetPassword: () => '/api/v1/investors/reset-password.json',
  forgotPassword: () => '/api/v1/investors/forgot-password.json',
  TwoFactorAuthentication: () => '/api/v1/investor/tfa',
  changePassword: () => '/api/v1/investors/edit-password',
  changeEmail: () => '/api/v1/investors/change-email',
  changeLoginState: () => '/api/v1/investors/change-login-status',
  GetCurrencies: currencyFor => `/api/v1/currencies?currency_for=${currencyFor}`,
  GetCountries: () => '/api/v1/countries',
  confirmEmail: (emailToken = 'emailToken') => `/api/v1/investors/${emailToken}/confirm-email`,
  resendEmail: () => '/api/v1/investors/resend-confirmation-email',
  isAnchorUser: (permalink = 'permalink') => `/api/v1/deals/${permalink}/is-anchor`,
  LiquidityRound: (uuid = 'uuid') => `/api/liquidity/liquidity-rounds/${uuid}`,
  CompanyByIssuerId: (issuerId = 'issuerId') => `/api/v1/issuers/${issuerId}`,
  GetDataRoomFile: (uuid = 'uuid') => `/api/data-room/files/${uuid}/download`,
  GetUserAlerts: () => '/api/v1/user-alerts/investor',
  Investment: {
    exchangeRate: (
      baseCurrency = 'baseCurrency',
      toCurrency = 'toCurrency',
      calcContext = 'calcContext',
    ) =>
      `/api/v1/investors/investment/exchange-rate?base_currency=${baseCurrency}&to_currency=${toCurrency}&calc_context=${calcContext}`,
    bankAccount: (currency = 'currency', issuanceId) =>
      `/api/v1/investors/investment/bank-accounts?currency=${currency}${
        issuanceId ? `&issuance_id=${issuanceId}` : ``
      }`,
    wallets: () => '/api/v1/investors/investment/wallets',
    walletDeposit: () => '/api/v1/investors/investment/wallet-deposit',
    orders: params =>
      `/api/v1/investors/investment/orders${params ? getApiParamsString(params) : ''}`,
    order: (id = 'id') => `/api/v1/investors/investment/orders/${id}`,
    addOrder: () => '/api/v1/investors/investment/orders',
    addFundOrder: () => '/api/v1/investors/investment/orders/subscribe',
    dealDocuments: (dealUuid = 'dealUuid') =>
      `/api/v1/investors/investment/deals/${dealUuid}/documents`,
    addLiqudityOrder: () => '/api/v1/investors/investments/issue',
    agreeNDADealDocument: (dealUuid = 'dealUuid', documentId = 'documentId') =>
      `/api/v1/investors/investment/deals/${dealUuid}/documents/${documentId}/agree`,
    DealNDAESignatureRequest: (dealUuid = 'dealUuid', documentId = 'documentId') =>
      `/api/v1/investors/investment/deals/${dealUuid}/documents/${documentId}/signature_requests`,
    investments: () => '/api/v1/investors/investments',
    convertibleNotes: () => '/api/v1/investors/convertible-notes',
    secondary: ({ page = '1', perPage = '10', order = 'created_at', state = '' }) =>
      `/api/v1/investors/investments/secondary?page=${page}&per_page=${perPage}${
        state && `&state=${state}`
      }&order=${order}`,
    secondaryInvestment: (uuid = 'uuid') => `/api/v1/investors/investments/secondary/${uuid}`,
    sellInvestments: () => '/api/v1/investors/investments/sell',
    getSellInvestmentsInfo: () => '/api/v1/investors/investments/sell/info',
    cancelSecondaryInvestment: (uuid = 'uuid') =>
      `/api/v1/investors/investments/secondary/${uuid}/cancel`,
    confirmSecondaryInvestment: (uuid = 'uuid') =>
      `/api/v1/investors/investments/secondary/${uuid}/confirm`,
    rejectSecondaryInvestment: (uuid = 'uuid') =>
      `/api/v1/investors/investments/secondary/${uuid}/reject`,
    transactions: search => {
      return `/api/v1/investors/funds_movement_events?${search.toString()}`;
    },
    equityTransactions: props =>
      `/api/v1/investors/investments/transaction-list${getApiParamsString(props, ['issuer'])}`,
    equityIssuers: () => '/api/v1/investors/investments/transaction-list/issuers',
    shareholdingIssuers: () => '/api/v1/investors/investments/conversation/issuers',
    ShareCertificate: {
      Request: (
        companyId = 'companyId',
        equityId = 'equityId',
        shareholderId = 'shareholderId',
        shareClass = 'shareClass',
      ) =>
        `/api/v1/investors/investments/${companyId}/certificate${getApiParamsString(
          { shareholderId, shareClass, equityId },
          ['shareClass'],
        )}`,
      Status: (documentUuid = ':documentUuid') =>
        `/api/v1/investors/investments/shareholder-certificate/${documentUuid}/status`,
      Download: (documentUuid = ':documentUuid') =>
        `/api/v1/investors/investments/shareholder-certificate/${documentUuid}/download`,
    },
    securityCertificate: (bondOrLoanId = 'bondOrLoanId', issuerId = 'issuerId') =>
      `/api/v1/investors/investments/${issuerId}/security-certificate${getApiParamsString({
        bondOrLoanId,
      })}`,
    communication: (communicationUuid = 'communicationUuid') =>
      `/api/v1/investors/investment_communications/${communicationUuid}`,
    communications: (page = 'page', perPage = 'perPage') =>
      `/api/v1/investors/investment_communications?page=${page}&per_page=${perPage}`,
    investmentCommunications: (queryParams = '') =>
      `/api/v1/investors/investment_communications/retrieve_by${queryParams}`,
    communicationsResponse: (communicationUuid = 'communicationUuid') =>
      `/api/v1/investors/investment_communications/${communicationUuid}/respond`,
    shareOptions: (page = 'page', perPage = 'perPage', order = '-granted_at') =>
      `/api/v1/investors/share_option_grants?page=${page}&per_page=${perPage}&order=${order}`,
    haveShareOptions: () => '/api/v1/investors/share_option_grants/have-share-options',
    shareOption: (grantId = 'grantId') => `/api/v1/investors/share_option_grants/${grantId}`,
    portfolio: () => '/api/v1/investors/investments/portfolio',
    placeIndicationOfInterest: () => '/api/v1/investors/investment/indication-of-interest',
    adjustIndicationOfInterest: (id = 'id') =>
      `/api/v1/investors/investment/indication-of-interest/${id}/adjustments`,
    cancelIndicationOfInterest: (id = 'id') =>
      `/api/v1/investors/investment/indication-of-interest/${id}/cancel`,
    getIndicationOfInterest: (id = 'id') =>
      `/api/v1/investors/investment/indication-of-interest/${id}`,
    getLiquidityRounds: (companyId = 'companyId') =>
      `/api/v1/investor/liquidity/liquidity-rounds?company_id=${companyId}`,
    adjustOrder: orderUuid => `/api/v1/investors/investment/orders/${orderUuid}/update`,
    orderAdjustmentHistory: orderUuid => `/api/v1/orders/${orderUuid}/adjustment-history`,
  },
  Onboarding: {
    selectInvestorType: () => '/api/v1/investors/onboarding/select-type',
    supportedCountries: () => '/api/v1/investors/onboarding/supported-countries',
    unsupportedCountries: () => '/api/v1/investors/onboarding/unsupported-countries',
    acceptTermsAndConditions: () => '/api/v1/investors/onboarding/accept-terms-and-conditions',
    verifyApplicant: () => '/api/v1/investors/kyc_identification/applicant-request',
    saveApplicationId: (uuid = 'uuid') =>
      `/api/v1/investors/kyc_identification/applicant-request/${uuid}`,
    completeKyc: (uuid = 'uuid') =>
      `/api/v1/investors/kyc_identification/applicant-request/${uuid}/complete`,
    Individual: {
      quizQuestions: (quizName = 'quizName') =>
        `/api/v1/investors/onboarding/individual/quiz-questions?name=${quizName}`,
      submitQuiz: () => '/api/v1/investors/onboarding/individual/quiz-answers',
      personalInformation: () => '/api/v1/investors/onboarding/individual/personal-information',
      residenceCountry: () => '/api/v1/investors/onboarding/individual/residence-country',
      investorType: () => '/api/v1/investors/onboarding/individual/investor-type',
      supportedInvestorTypes: (countryCode = 'countryCode') =>
        `/api/v1/investors/onboarding/individual/supported-investor-types?country_code=${countryCode}`,
      investmentPreferences: () => '/api/v1/investors/onboarding/individual/investment-preference',
    },
    Institution: {
      accountInformation: () => '/api/v1/investors/onboarding/institutional/account-information',
      investmentPreferences: () =>
        '/api/v1/investors/onboarding/institutional/investment-preference',
      accreditationStatus: () => '/api/v1/investors/onboarding/institutional/accreditation-status',
      supportedInvestorTypes: (countryCode = 'countryCode') =>
        `/api/v1/investors/onboarding/institutional/supported-investor-types?country_code=${countryCode}`,
    },
  },
  Companies: {
    list: () => '/api/v1/companies',
    company: (companyUuid = 'companyUuid') => `/api/v1/companies/${companyUuid}`,
    issuance: (companyUuid = 'companyUuid', modelUuid = 'modelUuid') =>
      `/api/v1/companies/${companyUuid}/issuance/${modelUuid}`,
  },
  Investors: {
    companies: () => '/api/v1/investors/companies',
    companiesPermission: () => '/api/v1/investors/companies/permission',
    companyGroups: () => '/api/v1/investors/agents',
  },
  Navigation: {
    retrieve: (modelUuid = 'modelUuid') => `/api/v1/navigation-items.json?model_uuid=${modelUuid}`,
    retrieveMine: () => '/api/v1/navigation-items.json',
    retrieveLiquidityRound: modelUuid =>
      `/api/v1/navigation-items/liquidity?model_uuid=${modelUuid}`,
  },
  SubNavigation: {
    retrieve: (id = 'id') => `/api/v1/navigation-items/${id}.json`,
  },
  Pages: {
    retrieve: (id = 'id') => `/api/v1/navigation-items/${id}/page.json`,
    retrieveLiquidity: id => `/api/v1/navigation-items/liquidity/navigation-items/${id}/page`,
  },
  CMS: {
    textBox: (id = 'id') => `/api/v1/content-management/text-boxes/${id}`,
    sectionBoxPosition: (id = 'id') => `/api/v1/content-management/section-boxes/${id}/position`,
  },
  Activity: {
    logs: () => '/api/v1/investors/activity_logs_event',
  },
  Preferences: {
    preferences: () => '/api/v1/investors/preferences',
  },
  Moneybox: {
    getToken: investorId =>
      `http://localhost:1337/v1/plaid/create_link_token?investorId=${investorId}`,
  },
  LiquidityService: {
    LiquidityRounds: () => `/api/liquidity/investor/liquidity-rounds/`,
    LiquidityRound: uuid => `/api/liquidity/investor/liquidity-rounds/${uuid}`,
    LiquidityRoundRedeem: () => '/api/liquidity/investor/liquidity-rounds/invite-token/redeem',
    LiquidityRoundUnauth: uuid => `/api/liquidity/liquidity-rounds/${uuid}`,
    TransactionFee: (uuid = 'uuid') =>
      `/api/liquidity/investor/liquidity-rounds/${uuid}/fees/transaction`,
    PlaceSharesOrder: (uuid = 'uuid', orderType = 'buy') =>
      `/api/liquidity/investor/liquidity-rounds/${uuid}/${orderType}`,
    LiquidityRoundAvailableShares: uuid =>
      `/api/liquidity/investor/liquidity-rounds/${uuid}/get-available-shares`,
    RetrieveLiquidityOrders: (orderType, sortBy, sortOrder) =>
      `/api/liquidity/investor/liquidity-rounds/orders/${orderType}?sortAttribute=${sortBy}&sortOrder=${sortOrder}`,
    CancelOrder: (roundUuid, orderUuid) =>
      `/api/liquidity/investor/liquidity-rounds/${roundUuid}/orders/${orderUuid}/cancel`,
    GetOrderSignatures: (roundUuid, orderUuid) =>
      `/api/liquidity/investor/liquidity-rounds/${roundUuid}/orders/${orderUuid}/signatures`,
    GetPaymentDetails: (roundUuid, orderUuid) =>
      `/api/liquidity/investor/liquidity-rounds/${roundUuid}/orders/${orderUuid}/payment-details`,
  },
  CompanySummary: {
    Get: (companyIssuerId = 'companyIssuerId') =>
      `/api/v1/investors/company_summary/${companyIssuerId}`,
  },
  DownloadCSV: {
    CreateCSVJob: () => '/jobs',
    CheckCSVJobStatus: id => `/jobs/${id}`,
    DownloadCSVfile: id => `/jobs/${id}/file`,
  },
  ShareIncentivePlans: {
    Prefix: () => `${window.config.SHARE_OPTION_SERVICES_URL}`,
    GetContributions: () => `/contributions`,
    GetShareIncentivePlanById: (planId = 'planId') => `/plans/${planId}`,
    Contribution: (uuid = 'uuid') => `/contributions/${uuid}`,
    DividendOrders: (page, limit, sortBy, sortOrder) =>
      `/orders/purchases/dividend?limit=${limit}&page=${page}${sortBy ? `&sortBy=${sortBy}` : ''}${
        sortOrder ? `&sortOrder=${sortOrder}` : ''
      }`,
    GetSharePlanHolderById: (holderId = 'holderId') => `/holders/${holderId}`,
    CreatePurchaseOrder: () => `/orders/purchases`,
    CreateBonusContributionOrder: () => `orders/bonuses`,
    tradeShares: {
      sendSellOrder: () => `/orders/sells`,
      sendTransferOrder: () => `/orders/transfers`,
    },
  },
  InvestmentMs: {
    investor: {
      GetAgreements: companyId => `/api/investment/investor/agreements/${companyId}`,
    },
    UserAgreement: {
      Accept: uuid => `/api/investment/agreements/${uuid}/accept`,
    },
  },
};
