const LockFilled = ({ color = '#00BBD2', width = 36, height = 47.25 }) => (
  <svg xmlns="http://www.w3.org/2000/svg" width={width} height={height} viewBox="0 0 36 47.25">
    <path
      fill={color}
      d="M35.5,16.75H33.25v-4.5a11.25,11.25,0,0,0-22.5,0v4.5H8.5A4.513,4.513,0,0,0,4,21.25v22.5a4.513,4.513,0,0,0,4.5,4.5h27a4.513,4.513,0,0,0,4.5-4.5V21.25A4.513,4.513,0,0,0,35.5,16.75ZM22,37a4.5,4.5,0,1,1,4.5-4.5A4.513,4.513,0,0,1,22,37Zm6.975-20.25H15.025v-4.5a6.975,6.975,0,0,1,13.95,0Z"
      transform="translate(-4 -1)"
    />
  </svg>
);

export default LockFilled;
