import { FC } from 'react';

interface ChevronIconProps {
  width?: number;
  height?: number;
  fillColor?: string;
  rotation?: number;
}

/**
 * Points upwards by default i.e. `^`. Use rotation prop to rotate.
 */
const ChevronIcon: FC<ChevronIconProps> = ({
  width = 14,
  height = 10,
  fillColor = 'currentColor',
  rotation = undefined,
}) => (
  <svg
    width={width}
    height={height}
    viewBox="0 0 14 10"
    fill="none"
    transform={rotation ? `rotate(${String(rotation)})` : undefined}
  >
    <path
      d="M 12.355 0.671 L 7 6.026 L 1.645 0.671 L 0 2.328 L 7 9.328 L 14 2.328 L 12.355 0.671 Z"
      fill={fillColor}
    />
  </svg>
);

const ChevronDown = (args: ChevronIconProps) => <ChevronIcon {...args} />;
const ChevronLeft = (args: ChevronIconProps) => (
  <ChevronIcon rotation={(args.rotation || 0) + 90} {...args} />
);
const ChevronUp = (args: ChevronIconProps) => (
  <ChevronIcon rotation={(args.rotation || 0) + 180} {...args} />
);
const ChevronRight = (args: ChevronIconProps) => (
  <ChevronIcon rotation={(args.rotation || 0) + 270} {...args} />
);

export { ChevronDown, ChevronLeft, ChevronUp, ChevronRight };

export default ChevronIcon;
