import { AnyAction } from 'redux';

import * as constants from 'app-state/constants';

import defaultState from './get-reducer-state';

export const dividendOrdersInitialState = defaultState({
  data: {
    data: [],
    total: 0,
    page: 1,
    limit: 10,
  },
  loading: false,
  error: '',
});

export const dividendOrdersReducer = (state = dividendOrdersInitialState, action: AnyAction) => {
  switch (action.type) {
    case constants.GET_DIVIDEND_ORDERS:
      return {
        data: action?.payload.paginate ? state.data : dividendOrdersInitialState.data,
        error: '',
        loading: true,
      };
    case constants.GET_DIVIDEND_ORDERS_SUCCESS:
      return {
        ...state,
        data: {
          ...action.payload.data,
          data: (state.data as { data: [] }).data.concat(action.payload.data.data),
        },
        loading: false,
        error: '',
      };
    case constants.GET_DIVIDEND_ORDERS_ERROR:
      return {
        ...state,
        dividendOrders: dividendOrdersInitialState.data,
        loading: false,
        error: action.payload.error,
      };
    default:
      return state;
  }
};
