interface Deal {
  company: {
    name: string;
  };
  dealDetail: {
    issuerName: string;
  };
}

const getIssuerName = ({ company: { name }, dealDetail }: Deal, manualDealIssuer: boolean) =>
  manualDealIssuer ? dealDetail?.issuerName : name;

export default getIssuerName;
