import { CheckMarkIcon, XIcon } from 'svg-icons';
import { Colors } from 'shared-parts/constants';

import { Tumbler, TumblerButton, TumblerLabel } from './tumbler.styled';

export default ({
  turnedOn,
  turnedOnText = 'EDIT ON',
  turnedOffText = 'EDIT OFF',
  handleClick,
  disabled = false,
}) => (
  <Tumbler turnedOn={turnedOn} onClick={disabled ? undefined : handleClick} disabled={disabled}>
    <TumblerButton turnedOn={turnedOn} disabled={disabled}>
      {turnedOn ? CheckMarkIcon() : XIcon(7, 7, Colors.systemRed)}
    </TumblerButton>
    <TumblerLabel turnedOn={turnedOn}>{turnedOn ? turnedOnText : turnedOffText}</TumblerLabel>
  </Tumbler>
);
