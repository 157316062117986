import type { MouseEvent } from 'react';

import styled from 'styled-components';

import * as Colors from 'shared-parts/constants/colors';
import * as Fonts from 'shared-parts/constants/fonts';

type Props = {
  onClick?: (e?: MouseEvent<HTMLButtonElement>) => void;
  disabled?: boolean;
  isDark?: boolean;
  isSuccess?: boolean;
  danger?: boolean;
};

const getButtonColor: (props: Props) => string = ({ danger, isDark, isSuccess }) => {
  if (danger) return Colors.error;
  if (isDark) return Colors.primary;
  if (isSuccess) return Colors.success;

  return Colors.alternative;
};

const StandardButton = styled.button<Props>`
  -webkit-appearance: none;
  -moz-appearance: none;
  border-radius: 6px;
  border: 0;
  box-shadow: none;
  cursor: pointer;
  font-weight: bold;
  line-height: 14px;
  outline: 0;
  padding: 8px 18px;
  height: 40px;
  min-width: 120px;
  font-size: 14px;
  font-family: ${Fonts.mainFont};
  color: ${Colors.white};
  background: ${getButtonColor};

  ${({ isDark }) =>
    !isDark &&
    `
    :hover {
      box-shadow: 0 3px 6px 0 ${Colors.neutral500};
    }
  `}

  ${({ disabled }) =>
    disabled &&
    `
    cursor: auto;
    background: ${Colors.neutral800};
    color: ${Colors.white};
    &:hover {
      box-shadow: none;
    }
  `}
`;

export default StandardButton;
