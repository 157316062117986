import * as constants from 'app-state/constants';

export const defaultLoaderState = {
  message: '',
  showLoader: false,
  whiteBackground: false,
  showIcon: false,
  isSuccess: false,
  isDone: false,
};

export const loaderReducer = (state = defaultLoaderState, action) => {
  // eslint-disable-line
  switch (action.type) {
    case constants.SHOW_LOADER:
      return { ...state, ...action.params, showLoader: true };
    case constants.HIDE_LOADER:
      return defaultLoaderState;
    default:
      return state;
  }
};
