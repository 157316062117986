import produce from 'immer';
import { AnyAction } from 'redux';

import * as constants from './download-csv.constants';
import initialState, { DownloadCSVStateType, JobStatus } from './download-csv.state';

export const downloadCSVReducer = (state = initialState, action: AnyAction): DownloadCSVStateType =>
  produce(state, draft => {
    switch (action.type) {
      case constants.sendDownloadRequest.REQUEST:
        draft.job = {
          ...initialState.job,
          loading: true,
        };
        break;
      case constants.sendDownloadRequest.SUCCESS:
        draft.job.data = action.data;
        break;
      case constants.checkJobProgress.FAILURE:
      case constants.sendDownloadRequest.FAILURE:
        draft.job.data.status = JobStatus.Failed;
        draft.job.loading = false;
        draft.job.error = action.error;
        break;
      case constants.checkJobProgress.SUCCESS:
        draft.job.data = action.data;
        draft.job.loading = false;
        break;
      default:
        break;
    }
  });
