import { useEffect, useState } from 'react';

declare global {
  interface Window {
    Osano?: {
      cm: {
        addEventListener: (event: string, callback: (payload: OsanoConsent) => void) => void;
        removeEventListener: (event: string, callback: (payload: OsanoConsent) => void) => void;
      };
    };
  }
}

export interface OsanoConsent {
  ANALYTICS: 'ACCEPT' | 'DENY';
  ESSENTIAL: 'ACCEPT' | 'DENY';
  MARKETING: 'ACCEPT' | 'DENY';
  OPT_OUT: 'ACCEPT' | 'DENY';
  PERSONALIZATION: 'ACCEPT' | 'DENY';
  STORAGE: 'ACCEPT' | 'DENY';
}

export const useCookieConsent = (): OsanoConsent => {
  const [cookiesConsent, setCookiesConsent] = useState<OsanoConsent>({
    ANALYTICS: 'DENY',
    ESSENTIAL: 'DENY',
    MARKETING: 'DENY',
    OPT_OUT: 'DENY',
    PERSONALIZATION: 'DENY',
    STORAGE: 'DENY',
  });

  useEffect(() => {
    const handleConsent = (saved: OsanoConsent) => setCookiesConsent(saved);

    if (window.Osano && window.Osano.cm) {
      window.Osano.cm.addEventListener('osano-cm-consent-saved', handleConsent);
    }

    return () => {
      if (window.Osano && window.Osano.cm) {
        window.Osano.cm.removeEventListener('osano-cm-consent-saved', handleConsent);
      }
    };
  }, []);

  return cookiesConsent;
};
