import { call, delay, put, race, select, take, takeEvery } from 'redux-saga/effects';

import * as actions from 'app-state/actions';
import * as constants from 'app-state/constants';
import * as selectors from 'app-state/selectors';

import InitialiseWithdrawDialog from 'modules/wallet-balance/dialogs/initialise-withdraw-dialog';
import API from 'constants/api';
import { downloadBlob } from 'helpers';
import TransferDetails from 'shared/transfer-details';
import { ModalFailed } from 'shared-parts/components';
import { request } from 'shared-parts/helpers';

function* fetchTheWallets() {
  try {
    const { data } = yield call(request, API.Investment.wallets());

    yield put(actions.fetchWalletsSuccess(data));
    return yield data;
  } catch (e) {
    yield put(actions.fetchWalletsError(e));
  }
}

function* fetchWalletsWatcher() {
  yield takeEvery(constants.FETCH_WALLETS, fetchTheWallets);
}

function* fetchWalletsPagesData() {
  try {
    yield call(fetchTheWallets, actions.fetchWallets());
  } catch (e) {
    yield put(actions.fetchWalletsPagesDataError(e));
  }
}

function* fetchWalletsPagesDataWatcher() {
  yield takeEvery(constants.FETCH_WALLETS_PAGES_DATA, fetchWalletsPagesData);
}

function* resetWalletsPagesData() {
  try {
    yield put(actions.resetInvestmentsData());
    yield put(actions.resetWallets());
    yield put(actions.resetOrders());
  } catch (e) {
    yield put(actions.fetchWalletsPagesDataError(e));
  }
}

function* resetWalletsPagesDataWatcher() {
  yield takeEvery(constants.RESET_WALLETS_PAGES_DATA, resetWalletsPagesData);
}

function* showDepositModal(walletsDeposit, bank, currency) {
  yield put(
    actions.showModal({
      showHeader: true,
      purpleCloseButton: true,
      closable: true,
      isCopyAllEnabled: true,
      component: TransferDetails,
      walletsDeposit,
      bank,
      hideGeneralNotion: true,
      message: `Transfer to the following account to top up your ${currency} wallet`,
    }),
  );
}

function* showWithdrawModal(currency, availableAmount) {
  yield put(
    actions.showModal({
      showHeader: true,
      header: 'WITHDRAW FUNDS',
      closable: true,
      component: InitialiseWithdrawDialog,
      modalWidth: 800,
      hideGeneralNotion: true,
      currency,
      availableAmount,
    }),
  );
}

function* getBankAccountRequest({ currency }) {
  try {
    const { data } = yield call(request, API.Investment.bankAccount(currency));
    yield put(actions.getBankAccountSuccess({ data, currency }));
    return yield data;
  } catch (e) {
    yield put(actions.getBankAccountError(e));
  }
}

function* getBankAccountWatcher() {
  yield takeEvery(constants.GET_BANK_ACCOUNT, getBankAccountRequest);
}

function* createWalletDeposit({ currency, showModal }) {
  try {
    const walletsDeposits = yield select(selectors.getWalletsDeposits);
    const bank = yield select(selectors.getBankAccount);
    const walletDeposit = walletsDeposits[currency];
    const bankData = bank[currency]
      ? bank[currency]
      : yield call(getBankAccountRequest, actions.getBankAccount(currency));

    if (walletDeposit.address && showModal) {
      return yield showDepositModal(walletDeposit, bankData, currency);
    }

    const { data } = yield call(request, API.Investment.walletDeposit(), 'POST', { currency });

    yield put(actions.createWalletDepositSuccess(data));

    if (showModal) {
      yield showDepositModal(data, bankData, currency);
    }
  } catch (e) {
    yield put(actions.createWalletDepositError(e));
  }
}

function* createWalletDepositWatcher() {
  yield takeEvery(constants.CREATE_WALLETS_DEPOSIT, createWalletDeposit);
}

function* createWalletWithdrawal({ currency, availableAmount, showModal }) {
  try {
    if (showModal) {
      yield showWithdrawModal(currency, Number(availableAmount));
    }
  } catch (e) {
    yield put(actions.createWalletDepositError(e));
  }
}

function* createWalletWithdrawalWatcher() {
  yield takeEvery(constants.CREATE_WALLETS_WITHDRAWAL, createWalletWithdrawal);
}

const showDownloadFailedModalAction = ({ showModal }) =>
  showModal({
    closable: true,
    showHeader: false,
    component: ModalFailed,
    title: 'CERTIFICATE DOWNLOAD FAILED',
    text: 'Your certificate failed to download. Please try again',
  });

function* pollShareholderCert(documentUuid) {
  try {
    while (true) {
      const { data } = yield call(request, API.Investment.ShareCertificate.Status(documentUuid));

      if (data.status === 'document_ready') {
        return data;
      }

      yield delay(2000);
    }
  } catch (e) {
    yield put({ type: 'CANCEL_SHAREHOLDER_POLL' });
  }
}

function* startPollShareholderCert(documentUuid) {
  try {
    return yield race({
      task: call(pollShareholderCert, documentUuid),
      timeout: delay(1000 * 30),
      cancel: take('CANCEL_SHAREHOLDER_POLL'),
    });
  } catch (error) {
    console.error('An error occurred polling shareholder cert.', error);
  }
}

function* downloadShareholderCert({ payload: { equityId, companyId, shareholderId, shareClass } }) {
  try {
    yield put(actions.showLoader());

    const { data } = yield call(
      request,
      API.Investment.ShareCertificate.Request(companyId, equityId, shareholderId, shareClass),
      'GET',
    );

    const result = yield call(startPollShareholderCert, data.documentUuid);

    if (result.task) {
      const { data: certificateBlob } = yield call(
        request,
        API.Investment.ShareCertificate.Download(data.documentUuid),
        'GET',
        null,
        {
          contentType: 'application/pdf',
          to: 'blob',
        },
      );

      downloadBlob(certificateBlob, 'certificate.pdf');
    } else if (result.cancelled) {
      throw new Error('An error occurred while downloading share certificate.');
    } else {
      throw new Error('The request timed out. Please try again later.');
    }
  } catch (e) {
    console.error(e);
    yield put(showDownloadFailedModalAction(actions));
  } finally {
    yield put(actions.hideLoader());
  }
}

function* downloadShareholderCertWatcher() {
  yield takeEvery(constants.DOWNLOAD_SHAREHOLDER_CERT, downloadShareholderCert);
}

export {
  fetchTheWallets,
  fetchWalletsWatcher,
  fetchWalletsPagesData,
  fetchWalletsPagesDataWatcher,
  getBankAccountRequest,
  getBankAccountWatcher,
  showDepositModal,
  createWalletDeposit,
  createWalletDepositWatcher,
  createWalletWithdrawal,
  createWalletWithdrawalWatcher,
  downloadShareholderCert,
  downloadShareholderCertWatcher,
  resetWalletsPagesDataWatcher,
};
