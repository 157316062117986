import useIntersect from 'shared-parts/helpers/useIntersect';

import { IntersectTableRow } from '../table.styled';

const IntersectBox = ({ onInfiniteScroll, threshold = 0.01, rootMargin = '100px 0px 0px 0px' }) => {
  const [ref, entry] = useIntersect({ threshold, rootMargin });

  if (entry && entry.intersectionRatio > 0.01) onInfiniteScroll();

  return <IntersectTableRow ref={ref} />;
};

export default IntersectBox;
