import { push } from 'connected-react-router';

import { FormikHelpers } from 'formik';

import { updateCurrentUser } from 'app-state/actions/current-user';
import { customVerify } from 'app-state/actions/onboarding';
import { hideModal, showModal } from 'app-state/actions/shared';
import store from 'app-state/store';

import AddressForm from 'modules/account-details/components/get-started-handler/address-form/update-address-form-wrapper';
import { AddressFormValues, User } from 'modules/account-details/components/types';
import routes from 'constants/routes';
import { FeatureFlag, useFeatureFlag } from 'providers';

const handleGetStarted = (user: User, isOfflineVerificationEnabled: boolean) => (): void => {
  const enableKybAddressCheck = useFeatureFlag(FeatureFlag.ENABLED_KYB_ADDRESS_CHECK);
  if (isOfflineVerificationEnabled) {
    store.dispatch(customVerify());
  } else if (enableKybAddressCheck && !user.investor.address.addressLine1) {
    const handleSubmit = (values: AddressFormValues, form: FormikHelpers<AddressFormValues>) => {
      const handleSuccess = isOfflineVerificationEnabled
        ? () => store.dispatch(customVerify())
        : () => {
            store.dispatch(hideModal());
            store.dispatch(push(routes.Onboarding.AmlVerification()));
          };

      const params = {
        ...values,
        addressLine1: values.addressFirstLine,
        addressLine2: values.line2,
      };
      store.dispatch(updateCurrentUser(params, form, handleSuccess));
    };
    store.dispatch(
      showModal({
        component: AddressForm,
        handleSubmit,
        user,
        hideModal: () => store.dispatch(hideModal()),
      }),
    );
  } else {
    store.dispatch(push(routes.Onboarding.AmlVerification()));
  }
};

export default handleGetStarted;
