import * as Colors from 'shared-parts/constants/colors';

const FilterListIcon = ({ color = Colors.neutral500, onClick, className }) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="16"
    height="10.667"
    viewBox="0 0 16 10.667"
    className={className}
    onClick={onClick}
  >
    <path
      fill={color}
      d="M9.222,16.667h3.556V14.889H9.222ZM3,6V7.778H19V6Zm2.667,6.222H16.333V10.444H5.667Z"
      transform="translate(-3 -6)"
    />
  </svg>
);

export default FilterListIcon;
