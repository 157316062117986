import styled from 'styled-components';

const Container = styled.div`
  text-align: center;
  cursor: pointer;
  margin-bottom: 30px;
`;

const Image = styled.img<{ isSmall?: boolean }>`
  max-width: 100%;
  ${({ isSmall }) =>
    isSmall
      ? `
    height: 75px
    width: 75px
  `
      : ''}
`;

export { Container, Image };
