import { forwardRef } from 'react';

import {
  alertClasses,
  FormControl as BaseFormControl,
  formControlClasses,
  FormControlProps as BaseFormControlProps,
  styled,
} from '@mui/material';

type FormControlProps = Omit<BaseFormControlProps, 'required'> & { optional?: boolean };

const FormControlRoot = styled(BaseFormControl)<FormControlProps>(({ theme }) => ({
  display: 'block',

  // This will add spacing to any form controls in a tree, but NOT the first sibling
  // and NOT any other elements. This lets us have a list of form controls
  // and all except the first will have spacing top, but lets us add extra
  // elements without adding spacing to those too
  [`&.${formControlClasses.root}~.${formControlClasses.root}`]: {
    marginTop: theme.spacing(8),
  },

  // It would be good to do this for all children but we'd also have to ignore
  // the input elements themselves, and we can't guarantee where they'll be,
  // so we play it safe with extra elements we know will need some margin
  [`& > .${alertClasses.root}:not(:first-child)`]: {
    marginTop: theme.spacing(2),
  },
}));

export const FormControl = forwardRef<HTMLDivElement, FormControlProps>(
  ({ optional, ...props }, ref) => {
    return <FormControlRoot ref={ref} {...props} required={!optional} />;
  },
);
