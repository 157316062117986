import { useCallback } from 'react';

import request from 'shared-parts/helpers/request';

import { PLAID_LINK_TOKEN_STORAGE_KEY } from '../constants';

import { CachedFetchState, useCachedFetch } from './use-cached-fetch';

export type LinkToken = { expiration: string; linkToken: string };
export type FetchPlaidLinkTokenState = Omit<CachedFetchState, 'value'> & {
  token: CachedFetchState['value'];
};

export function usePlaidLinkToken(): FetchPlaidLinkTokenState {
  const fetchLinkToken = useCallback(() => request('/api/v1/money_box/link_token', 'POST'), []);

  const { value, ...rest } = useCachedFetch<LinkToken>(
    PLAID_LINK_TOKEN_STORAGE_KEY,
    token => token.expiration,
    token => token.linkToken,
    fetchLinkToken,
  );

  return { token: value, ...rest };
}
