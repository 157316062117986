import {
  Accordion as MuiAccordion,
  AccordionProps as MuiAccordionProps,
  styled,
} from '@mui/material';

export interface AccordionProps extends MuiAccordionProps {
  bordered?: boolean;
}

export const Accordion = styled((props: AccordionProps) => {
  return <MuiAccordion disableGutters elevation={0} {...props} />;
})(({ theme, expanded, bordered = true }) => ({
  '&.MuiAccordion-root': {
    borderRadius: '9px',
    ...(expanded
      ? {
          border: `1px solid ${theme.palette.highlight.main}`,
        }
      : { border: bordered ? `1.5px solid ${theme.palette.neutral[100]}` : 'none' }),
    padding: '28px 48px 36px 40px',
    position: 'relative',

    '&:before': {
      display: 'none',
    },

    '&:hover': {
      ...(!expanded
        ? {
            border: { border: bordered ? `1px solid ${theme.palette.highlight.main}` : 'none' },
            boxShadow: {
              border: bordered
                ? '0px 8px 16px 0px rgba(96, 97, 112, 0.16), 0px 2px 4px 0px rgba(40, 41, 61, 0.04)'
                : 'none',
            },
          }
        : {}),
    },
  },
}));
