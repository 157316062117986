import { Fragment } from 'react';

import { FieldArray, FieldArrayRenderProps, FormikProps } from 'formik';

import { CAPITAL_FIELDS, FORM_FIELDS } from 'app-state/indication-of-interest/enums';
import type {
  Capital,
  FormFieldsProps,
  IndicationOfInterest,
} from 'app-state/indication-of-interest/types';

import ValuationField from 'shared/indication-of-interest/place-form/valuation-field';
import { formatCurrencySign } from 'shared-parts/helpers/format-number';

import {
  ADD_INTEREST_BUTTON_TEXT,
  getDefaultCapitalItem,
  INTENDED_AMOUNT_FIELD_LABEL,
  SPECIAL_CONDITIONS_FIELD_LABEL,
  SUBMIT_BUTTON_TEXT,
} from '../constants';
import {
  AddButtonContainer,
  AddIcon,
  AmountContainer,
  AmountInput,
  AmountLabel,
  ArrayContainer,
  ButtonContainer,
  ConditionsInput,
  CurrencyInput,
  CurrencySign,
  Label,
  RemoveIcon,
  Section,
  StyledForm,
  SubmitButton,
  TextareaSection,
} from '../styled';

const renderCapitalItems =
  (currency: string, disableValuationField: boolean) =>
  ({ form: { values }, name, push, remove }: FieldArrayRenderProps) =>
    values[name].map((item: Capital, index: number) => {
      // todo - revert when batch FORM_FIELDS.ITEMS create is allowed
      const isLastItem = false; // (index + 1) === values[name].length;
      const isOnlyItem = values[name].length === 1;
      const fieldPrefix = `${name}[${index}]`;
      const limit = 10;
      const isAddButtonDisabled = index + 1 === limit;

      const addItem = () => {
        push({ ...getDefaultCapitalItem(disableValuationField) });
      };

      const removeItem = () => {
        remove(index);
      };

      return (
        <Fragment key={index}>
          <ArrayContainer>
            {!disableValuationField && (
              <ValuationField fieldPrefix={fieldPrefix} currency={currency} />
            )}
            <AmountContainer>
              <AmountLabel htmlFor={`${fieldPrefix}${CAPITAL_FIELDS.INTENDED_AMOUNT}`}>
                {INTENDED_AMOUNT_FIELD_LABEL}
              </AmountLabel>
              <CurrencyInput>
                <CurrencySign>{formatCurrencySign(currency)}</CurrencySign>
                <AmountInput
                  name={`${fieldPrefix}${CAPITAL_FIELDS.INTENDED_AMOUNT}`}
                  isAmount
                  dpAmount={2}
                />
              </CurrencyInput>
            </AmountContainer>
            {!isOnlyItem && <RemoveIcon onClick={removeItem} />}
          </ArrayContainer>
          {isLastItem && (
            <AddButtonContainer disabled={isAddButtonDisabled} onClick={addItem}>
              <AddIcon />
              <span>{ADD_INTEREST_BUTTON_TEXT}</span>
            </AddButtonContainer>
          )}
        </Fragment>
      );
    });

const renderForm =
  ({ currency, isLoading, disableValuationField }: FormFieldsProps) =>
  ({ isValid }: FormikProps<IndicationOfInterest>) => (
    // todo - revert when PLAPI-1614 is resolved
    // eslint-disable-next-line max-len
    // const label = values[FORM_FIELDS.TERM_SHEET]?.fileData?.get('file')?.name ?? DEFAULT_FILE_INPUT_TEXT;

    <StyledForm>
      <Section>
        <FieldArray
          name={FORM_FIELDS.ITEMS}
          render={renderCapitalItems(currency, disableValuationField)}
        />
      </Section>
      {/* todo - revert when PLAPI-1614 is resolved */}
      {/* <FileSection> */}
      {/*  <Label htmlFor={FORM_FIELDS.TERM_SHEET}>{TERM_SHEET_FIELD_LABEL}:</Label> */}
      {/*  <FileLabel>{label}</FileLabel> */}
      {/*  <DownloadButtonContainer> */}
      {/*    <DownloadButton> */}
      {/*      <StyledFileInput name={FORM_FIELDS.TERM_SHEET} accept=".pdf" /> */}
      {/*      {DownloadArrowIcon({ fill: Colors.green })} */}
      {/*      {UPLOAD_BUTTON_TEXT} */}
      {/*    </DownloadButton> */}
      {/*  </DownloadButtonContainer> */}
      {/* </FileSection> */}
      <TextareaSection>
        <Label htmlFor={FORM_FIELDS.SPECIAL_CONDITIONS}>{SPECIAL_CONDITIONS_FIELD_LABEL}:</Label>
        <ConditionsInput
          name={FORM_FIELDS.SPECIAL_CONDITIONS}
          defaultVisibleLines="6"
          resize="none"
          disableAutocomplete
        />
      </TextareaSection>
      <ButtonContainer>
        <SubmitButton type="submit" disabled={isLoading || !isValid}>
          {SUBMIT_BUTTON_TEXT}
        </SubmitButton>
      </ButtonContainer>
    </StyledForm>
  );
export default renderForm;
