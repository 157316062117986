import { buttonClasses, DialogActions as MuiDialogActions, styled } from '@mui/material';

export const DialogActions = styled(MuiDialogActions)(({ theme }) => ({
  padding: theme.spacing(0, 6),

  '&:last-child': {
    paddingTop: theme.spacing(6),
  },

  [theme.breakpoints.down('sm')]: {
    flexDirection: 'column-reverse',
    gap: theme.spacing(3),

    '&:last-child': {
      paddingTop: theme.spacing(5),
    },

    [`& .${buttonClasses.root}`]: {
      width: '100%',
      marginLeft: 0,
    },
  },
}));
