import { FC } from 'react';

import { PlatformSettingsContext } from './platform-settings';
import { ApiPlatformSetting } from './types';
import { convertToApiResponseToPlatformSettings } from './utilities';

const API_PLATFORM_SETTINGS = [
  {
    name: 'invitation_risk_message',
    value: true,
    settingType: 'boolean',
    isCurrentlyRegulated: false,
  },
  {
    name: 'regulatory_environment',
    value: 'FCA',
    settingType: 'choice',
    choices: ['FINRA', 'FCA', 'None'],
    isCurrentlyRegulated: false,
  },
  {
    name: 'allow_manual_investor_classification',
    value: true,
    settingType: 'boolean',
    isCurrentlyRegulated: false,
  },
  {
    name: 'restrict_deals_to_verified_investors',
    value: true,
    settingType: 'boolean',
    isCurrentlyRegulated: false,
  },
  {
    name: 'asset_classes',
    value: ['equity', 'debt', 'fund'],
    settingType: 'multiple_choice',
    isCurrentlyRegulated: false,
  },
  {
    name: 'placement_enabled',
    value: true,
    settingType: 'boolean',
    isCurrentlyRegulated: false,
  },
  {
    name: 'register_enabled',
    value: true,
    settingType: 'boolean',
    isCurrentlyRegulated: false,
  },
  {
    name: 'liquidity_enabled',
    value: true,
    settingType: 'boolean',
    isCurrentlyRegulated: false,
  },
  {
    name: 'important_information_block_content',
    value: 'Important Information loading',
    settingType: 'string',
    isCurrentlyRegulated: false,
  },
] as ApiPlatformSetting[];

const PLATFORM_SETTINGS = convertToApiResponseToPlatformSettings(API_PLATFORM_SETTINGS);

const context = {
  ...PLATFORM_SETTINGS,
} as PlatformSettingsContext;

export const StubPlatformSettingsProvider: FC<React.PropsWithChildren<unknown>> = ({
  children,
}) => {
  return (
    <PlatformSettingsContext.Provider value={context}>{children}</PlatformSettingsContext.Provider>
  );
};
