import produce from 'immer';
import { AnyAction } from 'redux';

import * as constants from './share-plan-holders.constants';
import initialState, { SharePlanHoldersStateType } from './share-plan-holders.state';

export const sharePlanHoldersReducer = (
  state = initialState,
  action: AnyAction,
): SharePlanHoldersStateType =>
  produce(state, draft => {
    switch (action.type) {
      case constants.GET_SHARE_PLAN_HOLDER.REQUEST:
        draft.sharePlanHolder = {
          ...initialState.sharePlanHolder,
          loading: true,
        };
        break;
      case constants.GET_SHARE_PLAN_HOLDER.SUCCESS:
        draft.sharePlanHolder.loading = false;
        draft.sharePlanHolder.data = action.data;
        break;
      case constants.GET_SHARE_PLAN_HOLDER.FAILURE:
        draft.sharePlanHolder.loading = false;
        draft.sharePlanHolder.error = action.error;
        break;

      default:
        break;
    }
  });
