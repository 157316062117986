import { Box, BoxProps, styled } from '@mui/material';

export type WidgetProps = BoxProps & {
  /**
   * The variant to use.
   * @default 'regular'
   */
  variant?: 'regular' | 'dense';
};

export const Widget = styled(Box, { label: 'WidgetRoot' })<WidgetProps>(({ theme, variant }) => ({
  border: `1px solid ${theme.palette.neutral[100]}`,
  borderRadius: theme.shape.borderRadius,
  color: theme.palette.neutral[600],
  display: 'flex',
  justifyContent: 'space-between',
  padding: theme.spacing(7, 10),
  minWidth: '300px',

  ...(variant === 'dense' && {
    padding: theme.spacing(7),
  }),
}));
