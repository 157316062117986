import Bubble from 'shared-parts/components/bubble';
import { dashed } from 'shared-parts/helpers/format-string';

import { Tab, TabsContainer, TabWrapper } from './tabs.styled';

const Tabs = ({
  items,
  selected,
  onClick,
  className = '',
  getTabStyles = () => {},
  TabComponent = Tab,
}) => (
  <TabsContainer className={className}>
    {items.map(tab => {
      const isTabSelected = selected === tab.key;

      return (
        <TabWrapper key={tab.key}>
          <TabComponent
            selected={isTabSelected}
            disabled={tab.disabled}
            onClick={tab.disabled || !onClick ? () => {} : () => onClick(tab)}
            tabStyles={getTabStyles ? () => getTabStyles(isTabSelected) : ''}
            data-e2e={`${dashed(tab.name)}-tab`}
          >
            {tab.name}
          </TabComponent>
          {tab.bubbleContent ? <Bubble content={tab.bubbleContent} /> : ''}
        </TabWrapper>
      );
    })}
  </TabsContainer>
);

export default Tabs;
