import { useState } from 'react';
import ClickOutside from 'react-click-outside';

import {
  MultiActionOption,
  MultiActionsArrow,
  MultiActionsArrowButton,
  MultiActionsContainer,
  MultiActionsSelect,
} from './multi-actions.styled';

const MultiActions = ({ multiActions }) => {
  const [isSelectOpen, setIsSelectOpen] = useState(false);

  return multiActions.length ? (
    <MultiActionsContainer>
      <ClickOutside onClickOutside={() => setIsSelectOpen(false)}>
        <MultiActionsArrowButton onClick={() => setIsSelectOpen(!isSelectOpen)}>
          <MultiActionsArrow isSelectOpen={isSelectOpen} />
        </MultiActionsArrowButton>
        {isSelectOpen && (
          <MultiActionsSelect>
            {multiActions.map(({ text, action, isActionDisabled }) => (
              <MultiActionOption key={text} isActionDisabled={isActionDisabled} onClick={action}>
                {text}
              </MultiActionOption>
            ))}
          </MultiActionsSelect>
        )}
      </ClickOutside>
    </MultiActionsContainer>
  ) : null;
};

export default MultiActions;
