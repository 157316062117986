import { ReactNode } from 'react';

import { Box, BoxProps, styled, Typography } from '@mui/material';

export interface WidgetStatusProps extends BoxProps {
  Icon?: ReactNode;
}

export const WidgetStatus = styled(
  (props: WidgetStatusProps) => {
    const { Icon, children, ...rest } = props;

    return (
      <Box {...rest}>
        {Icon}
        <Typography>{children}</Typography>
      </Box>
    );
  },
  { label: 'WidgetStatus' },
)(({ theme }) => ({
  alignItems: 'center',
  display: 'flex',
  gap: theme.spacing(2),

  ':not(:only-child)': {
    marginTop: theme.spacing(11),
  },

  '& .MuiTypography-root': {
    fontWeight: 600,
  },
}));
